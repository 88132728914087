import { useCallback, useMemo } from 'react';
import DateTimeInput from '__components/Inputs/DateTimeInput';
import { DateTimeContainer } from './styled';
import { InputProps } from './types';

type DateTimeProps = Omit<InputProps, 'placeholder'> & {
  portal?: boolean;
  utc?: boolean;
};

const toUTCDateString = (d: Date) => {
  const leadingZero = (num: number) => {
    return num < 10 ? `0${num}` : num;
  };

  const year = d.getUTCFullYear();
  const month = d.getUTCMonth() + 1;
  const date = d.getDate();
  const hours = d.getUTCHours();
  const minutes = d.getUTCMinutes();
  const time = `${leadingZero(hours)}:${leadingZero(minutes)}`;

  return `${year}-${leadingZero(month)}-${leadingZero(date)} ${time}`;
};

const fromUTC = (date?: string) => {
  if (date) {
    const d = new Date(date);
    const utc = new Date(
      Date.UTC(
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        d.getHours(),
        d.getMinutes()
      )
    );
    return utc.toISOString();
  }
};

export const DateTime = ({
  step,
  value: valueProp,
  error,
  portal = false,
  utc = false,
  width = 280,
  onChange,
}: DateTimeProps) => {
  const handleChange = useCallback(
    (val: Date) => {
      if (utc) {
        onChange(step, toUTCDateString(val));
      } else {
        onChange(step, val.toISOString());
      }
    },
    [step, utc, onChange]
  );

  const value = useMemo(() => {
    if (utc) {
      return fromUTC(valueProp);
    }
    return valueProp;
  }, [utc, valueProp]);

  return (
    <DateTimeContainer width={width}>
      <DateTimeInput
        portal={portal}
        value={value}
        error={error}
        onChange={handleChange}
      />
    </DateTimeContainer>
  );
};
