import { useState, useEffect, useRef, useMemo } from 'react';
import LongText from 'components/Inputs/LongText/Masked';
import { getMaskedValue, useUrlFromValue } from 'components/Inputs/helpers';
import Overlay from 'react-bootstrap2/Overlay';
import { colorsButton } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import EditableText from 'components/Kizen/EditableText';
import IconButton from 'components/Kizen/IconButton';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import {
  ViewWrapper,
  TextareaWrapper,
  LongTextWrapper,
  LinkButtonWrapper,
  LongTextValueWrapper,
} from './styles';
import { basePopperConfig } from '../helpers';
import { getDataQAForInput } from '../../helpers';
import { useTranslation } from 'react-i18next';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import Validation from 'components/Inputs/Validation';
import { getOriginalError } from 'services/AxiosService';
import { useFlashTransition } from 'hooks/useFlashState';
import { Button } from '@kizen/kds/Button';

const newlinesToSpaces = (str) => str && str.replace(/\n|\r\n|\r/g, ' ');

export default function LongTextInline({
  value = '',
  onSubmit,
  autoFocus = false,
  onAutoFocus = null,
  emptyPlaceholder = '—',
  readOnly = false,
  mergeFields = false,
  enableResize = false,
  tooltipLabel = '',
  popperConfig,
  contentWidth,
  'data-field-type': qaType,
  'data-field-id': qaId,
  field,
  object,
  shouldFocusNext,
  displayMaskedValue,
  ...others
}) {
  const maskedValueEnabled = displayMaskedValue && field?.meta?.isMasked;
  const maskedValue = maskedValueEnabled ? getMaskedValue(value) : value;
  const fieldId = useMemo(() => `${field?.id}-${object?.id}`, [field, object]);
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [stagedValue, setStagedValue] = useState(maskedValue ?? '');
  useEffect(() => {
    setStagedValue(value);
  }, [value, editing]);

  const overlayTarget = useRef(null);
  const focusSelectionRef = useRef(null);
  const { assignFieldHandle, getKeyListenersProps } = useKeyBoardContext();

  const onAutoFocusRef = useRef();
  onAutoFocusRef.current = onAutoFocus;

  useEffect(() => {
    if (autoFocus && !assignFieldHandle) {
      setEditing(true);
      if (onAutoFocusRef.current) onAutoFocusRef.current(overlayTarget.current);
    }
  }, [autoFocus, assignFieldHandle]);

  const [isError, setIsError] = useState(false);

  const [errorMessage, showError, flashErrorMessage, cancelFlash] =
    useFlashTransition();

  const handleSubmit = async ({ clickAway } = {}) => {
    if (value !== stagedValue) {
      try {
        setSubmitting(true);
        await onSubmit(stagedValue);
        setIsError(false);
        setEditing(false);
      } catch (error) {
        if (clickAway && isError) {
          // When clicking away after two failures, bail on editing
          setIsError(false);
          setEditing(false);
          cancelFlash();
          return;
        }
        setIsError(true);
        const orig = getOriginalError(error);
        const message = orig.fields?.[0]?.value?.[0];

        if (message) {
          setTimeout(() => flashErrorMessage(message));
        }
      } finally {
        setSubmitting(false);
      }
    } else {
      setEditing(false);
    }
  };

  const handleSubmitOnHide = () => {
    handleSubmit({ clickAway: true });
  };

  const [tooltipProps, tooltip] = useTruncationTooltip({
    label: displayMaskedValue ? value : undefined,
    tooltipLabel: !displayMaskedValue ? tooltipLabel : value,
    popperConfig,
  });
  const inputRef = useRef(null);
  const textareaElem = useRef(null);

  assignFieldHandle(fieldId, {
    customFocus: () => {
      setEditing(true);
      return overlayTarget.current;
    },
    disabled: readOnly,
    shouldFocusNext: shouldFocusNext,
  });

  const [menuOpen, setMenuOpen] = useState(false);

  const { urlFromValue, urlFromValueLinkProps, linkButtonProps } =
    useUrlFromValue(value, !maskedValueEnabled);

  return (
    <ViewWrapper
      ref={overlayTarget}
      {...others}
      shrink={!!urlFromValue}
      readOnly={readOnly}
    >
      {readOnly ? (
        <TextEllipsisWithTooltip
          {...others}
          tooltipLabel={tooltipLabel}
          showLabel={displayMaskedValue}
          label={value}
          popperConfig={popperConfig}
          {...urlFromValueLinkProps}
        >
          {maskedValue}
        </TextEllipsisWithTooltip>
      ) : (
        <>
          <LongTextValueWrapper>
            <EditableText
              ref={inputRef}
              className="ViewText"
              value={
                newlinesToSpaces(submitting ? stagedValue : maskedValue) ||
                emptyPlaceholder
              }
              disabled={submitting || editing}
              onClick={(ev) => {
                focusSelectionRef.current = value
                  ? ev.currentTarget.selectionStart
                  : 0;
                setEditing(true);
              }}
              {...tooltipProps}
            />
            {urlFromValue && !editing ? (
              <LinkButtonWrapper>
                <Button {...linkButtonProps} />
              </LinkButtonWrapper>
            ) : null}
          </LongTextValueWrapper>
          {(!editing || submitting) && tooltip}
          <Overlay
            transition={false}
            target={overlayTarget.current}
            show={editing && !submitting}
            placement="bottom-start"
            rootClose
            rootCloseDisabled={menuOpen}
            onHide={handleSubmitOnHide}
            popperConfig={basePopperConfig}
          >
            <TextareaWrapper
              width={contentWidth || overlayTarget.current?.offsetWidth}
              enableResize={enableResize}
            >
              <LongTextWrapper>
                <LongText
                  ref={textareaElem}
                  disabled={submitting}
                  value={stagedValue}
                  onChange={setStagedValue}
                  mergeFields={mergeFields}
                  inModal
                  enableResize={enableResize}
                  setMenuOpen={setMenuOpen}
                  autoFocus={autoFocus}
                  setShown={setEditing}
                  handleSubmit={handleSubmit}
                  selectionStart={focusSelectionRef.current || 0}
                  {...getDataQAForInput(qaId, qaType)}
                  {...getKeyListenersProps?.(fieldId)}
                />
              </LongTextWrapper>
              <IconButton
                sizing="dense"
                title={t('Save')}
                disabled={submitting}
                color={
                  submitting
                    ? colorsButton.iconGray.default
                    : colorsButton.iconGray
                }
                onClick={handleSubmit}
              >
                <Icon icon="check" />
              </IconButton>
            </TextareaWrapper>
          </Overlay>
        </>
      )}
      {editing && !submitting ? (
        <Validation
          target={textareaElem.current?.closest('div')}
          showMessage={showError}
          message={errorMessage}
        />
      ) : null}
    </ViewWrapper>
  );
}
