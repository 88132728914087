import { useCallback, useEffect, useMemo, useRef } from 'react';
import { CategorizedFields, getFieldsOptions, getFieldsValue } from './helpers';
import {
  FieldLight,
  GenericOption,
  SmartConnectorDataSeed,
} from '__pages/SmartConnectors/types';
import { isContact } from 'utility/fieldHelpers';
import { isDefaultEmailField } from '__checks/fields';
import { isEntityNameField } from '__checks/fields';

type UseCustomObjectFieldsProps = {
  dataSeed: SmartConnectorDataSeed | undefined;
  categorizedFields: CategorizedFields;
  fields: FieldLight[];
  onChange: (fields: SmartConnectorDataSeed['fields']) => void;
};

export const useCustomObjectFields = ({
  dataSeed,
  categorizedFields,
  fields,
  onChange,
}: UseCustomObjectFieldsProps) => {
  const isContactObject = isContact(dataSeed?.custom_object);

  const identifierField = useMemo(() => {
    return fields.find((field) =>
      isContactObject ? isDefaultEmailField(field) : isEntityNameField(field)
    )!;
  }, [isContactObject, fields]);

  const hasFieldsSelected = Boolean(dataSeed?.fields?.length);
  const isNew = useRef(dataSeed?.id.startsWith('new'));

  useEffect(() => {
    if (isNew.current && !hasFieldsSelected && identifierField) {
      onChange([identifierField]);
    }
    isNew.current = !identifierField;
  }, [identifierField, hasFieldsSelected, onChange]);

  const value = useMemo(() => getFieldsValue(dataSeed), [dataSeed]);

  const options = useMemo(
    () => getFieldsOptions(categorizedFields),
    [categorizedFields]
  );

  const fieldsByIds = useMemo(() => {
    return fields.reduce<Record<string, FieldLight>>((acc, field) => {
      acc[field.id] = field;
      return acc;
    }, {});
  }, [fields]);

  const handleChange = useCallback(
    (selected: GenericOption[]) => {
      onChange(selected.map(({ value }) => fieldsByIds[value]));
    },
    [fieldsByIds, onChange]
  );

  return {
    onChange: handleChange,
    value,
    options,
  };
};
