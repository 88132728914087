import { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { flushSync } from 'react-dom';

import { PageSizing } from 'components/Layout/PageContentWidth';
import {
  clearChosenObject,
  getCustomObjects,
  setChosenObject,
  getCategories,
  selectEditableObjects,
} from 'store/customObjectsSettingsPage/customObjectsSettings.redux';
import { setError } from 'store/errors';
import { BuilderLoader } from 'components/Builders/CustomFieldsBuilder';
import { useFieldFromCategories } from '../useFieldFromCategories';
import { useFieldEditingModal } from '../useFieldEditingModal';
import ObjectSelect from './ObjectSelect';
import CategoriesLayout from './CategoriesLayout';

const StyledPageSizing = styled(PageSizing)`
  position: relative;
`;

const CustomObjectsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const objectsLoaded = useRef(false);
  const { id, field_id } = useParams();
  const [editingFieldId, { onOpenEditFieldOptions, onCloseEditFieldOptions }] =
    useFieldEditingModal(field_id);
  const objects = useSelector(selectEditableObjects);
  const loadingObjects = useSelector(
    (state) => state.customObjectsSettingsPage.loadingObjects
  );
  const chosenObject = useSelector(
    (s) => s.customObjectsSettingsPage.chosenObject
  );
  const categories = useSelector((s) => s.customObjectsSettingsPage.categories);
  const fieldFromUrl = useFieldFromCategories(editingFieldId, categories);

  const isLoading = (id && !chosenObject) || (field_id && !categories);
  useEffect(() => {
    if (!loadingObjects && id) {
      // user does not have edit access for the object specified in the URL
      if (!objects.some((obj) => id === obj.id)) {
        dispatch(clearChosenObject());
        dispatch(setError({ code: 404 }));
      }
    }
  }, [dispatch, loadingObjects, id, objects, history]);

  useEffect(() => {
    if (!objectsLoaded.current) {
      dispatch(getCustomObjects({ id }));
      objectsLoaded.current = true;
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (chosenObject && location.pathname.endsWith('custom-objects')) {
      history.replace(`${location.pathname}/${chosenObject.id}`);
    }
  }, [chosenObject, location, history]);

  const handleChooseObject = useCallback(
    (customObject) => {
      flushSync(() => {
        dispatch(setChosenObject(customObject));
        dispatch(getCategories(customObject));
      });

      if (location.pathname.endsWith('custom-objects')) {
        history.push(`${location.pathname}/${customObject.id}`);
      } else {
        const parts = location.pathname.split('/');
        parts.pop();
        history.push(`${parts.join('/')}/${customObject.id}`);
      }
    },
    [dispatch, history, location.pathname]
  );

  const handleGetCategories = useCallback(() => {
    dispatch(getCategories(chosenObject));
  }, [dispatch, chosenObject]);

  return (
    <StyledPageSizing>
      <ObjectSelect
        data-qa="custom-object-search"
        options={objects}
        handleChange={handleChooseObject}
        chosenObject={chosenObject}
        loadItems={loadingObjects}
      />
      {chosenObject && isLoading && <BuilderLoader loading />}
      {chosenObject && !isLoading && (
        <CategoriesLayout
          categories={categories}
          model={chosenObject}
          field={fieldFromUrl}
          handleGetCategories={handleGetCategories}
          onOpenEditFieldOptions={onOpenEditFieldOptions}
          onCloseEditFieldOptions={onCloseEditFieldOptions}
        />
      )}
    </StyledPageSizing>
  );
};

export default CustomObjectsPage;
