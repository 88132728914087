import {
  convertBuilderJson,
  withRootNode,
  createDefaultTextStyle,
} from '@kizen/mjml';
import { reassignIds } from '@kizen/page-builder/utils/id';
import { camelToSnakeCaseKeys } from 'services/helpers';
import { needsConversion } from 'ts-filters/utils';
import { SENDER_TYPE } from './components';

export const cleanCraftJsContent = (craftJsContent) => {
  const { kzn_filters, ...rest } = craftJsContent ?? {};
  return rest;
};

const createFilterPayload = (filter) => {
  if (needsConversion(filter.filter)) {
    return {
      ...filter.filter,
      query: filter.filter.query.map((q) => {
        return {
          ...q,
          filters: q.filters.map((filter) => {
            return camelToSnakeCaseKeys(filter.values);
          }),
        };
      }),
    };
  }
  return filter.filter;
};

/**
 * We're reassigning all image ids here for the dynamic images feature
 * The class names added to the generated email HTML contain the craft
 * node ids. CSS classes cannot have underscores and the default nanoid
 * id generator was including underscores.
 */
export const getCraftJsonWithSafeDynamicImageIds = (craftjson) => {
  return reassignIds(craftjson, 'Image');
};

const getFiltersPayloadFromJson = (craftjson) => {
  const keys = {
    in_group: 'in_group_ids',
    not_in_group: 'not_in_group_ids',
    custom_filter: 'custom_filters',
  };
  return Object.values(craftjson).reduce((acc, { type, custom }) => {
    if (type.resolvedName === 'Image' && Array.isArray(custom.dynamicImages)) {
      for (const node of custom.dynamicImages) {
        const key = keys[node.custom.filter.type];
        acc[node.props.filterId] = {
          [key]:
            node.custom.filter.type === 'custom_filter'
              ? createFilterPayload(node.custom.filter)
              : node.custom.filter.groups.map((maybeGroup) => {
                  return typeof maybeGroup === 'string'
                    ? maybeGroup
                    : maybeGroup?.id;
                }),
        };
      }
    }
    if (type.resolvedName === 'Text' && Array.isArray(custom.dynamicText)) {
      for (const node of custom.dynamicText) {
        const key = keys[node.custom.filter.type];
        acc[node.props.filterId] = {
          [key]:
            node.custom.filter.type === 'custom_filter'
              ? node.custom.filter.filter
              : node.custom.filter.groups,
        };
      }
    }
    return acc;
  }, {});
};

export const getMjmlJson = (
  editor,
  { processDynamicImages, processDynamicText } = {}
) => {
  const craftJsContent = getCraftJsonWithSafeDynamicImageIds(
    editor?.query?.getSerializedNodes()
  );
  const [body, { headAttributes, headChildren }] = convertBuilderJson(
    craftJsContent,
    {
      processDynamicImages,
      processDynamicText,
    }
  );
  const [textStyle, textAttr] = createDefaultTextStyle();
  return withRootNode(body, {
    headAttributes: headAttributes.concat(textAttr),
    headChildren: headChildren.concat(textStyle),
  });
};

export const buildResponse = async (
  editor,
  mjml2html,
  {
    senderType,
    sendFrom,
    customName,
    subjectLine,
    internalName,
    selectedSummary,
    integratedInbox,
  }
) => {
  const craftJsContent = getCraftJsonWithSafeDynamicImageIds(
    editor?.query?.getSerializedNodes()
  );
  const [body, { headAttributes, headChildren }] =
    convertBuilderJson(craftJsContent);
  const [textStyle, textAttr] = createDefaultTextStyle();
  const { html } = await mjml2html(
    withRootNode(body, {
      headAttributes: headAttributes.concat(textAttr),
      headChildren: headChildren.concat(textStyle),
    })
  );

  return {
    craftJsContent,
    kznFilters: getFiltersPayloadFromJson(craftJsContent),
    htmlContent: html,
    ...selectedSummary,
    sender: {
      type: senderType,
      id:
        senderType === SENDER_TYPE.SPECIFIC_INTEGRATED_INBOX
          ? integratedInbox?.value || integratedInbox?.id
          : sendFrom,
      customName: customName.length ? customName.trim() : null,
    },
    subject: subjectLine.trim(),
    name: internalName.trim() || subjectLine.trim(),
  };
};

export const getExternalSendFrom = (sender, t) =>
  sender?.label || sender?.email || t('[Deleted]');
