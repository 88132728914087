import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import FieldService from 'services/FieldService';
import { setToast } from 'store/customObjectsRecordsPage/actions';
import UploadDataSection from 'components/Modals/UploadCSVData/sections/UploadData';
import MatchRecordsSection from 'components/Modals/UploadCSVData/sections/MatchRecords';
import FinalizeUploadSection from 'components/Modals/UploadCSVData/sections/FinalizeUpload';
import {
  StyledGenericWizardModalBody,
  StyledUploadDataSection,
  StyledFinalizeUploadSection,
  StyledMatchRecordsSection,
} from 'components/Modals/UploadCSVData/sections/styles';
import {
  getConflictResolution,
  getInitialFinalizeUploadData,
  NO_UPLOAD,
  changeCSVColumnType,
  getFieldsForUpload,
} from 'components/Modals/UploadCSVData/utilities';
import { FIELD_TYPES } from 'utility/constants';
import { toastVariant } from 'components/ToastProvider';
import BasicModalWithConfirmation from 'components/Modals/presets/BasicModalWithConfirmation';
import { StyledLoader } from './styles';
import { useDynamicTagOptionsForCSVTemplate } from 'components/DownloadButton/useDynamicTagOptionsForCSVTemplate';
import {
  isEntityNameField,
  isEntityValueField,
  isPercentageChanceToCloseField,
} from 'checks/fields';
import { fetchErrorByKey, getErrorMessage } from 'hooks/useErrors';
import { useFlashTransition } from 'hooks/useFlashState';

const defaultValues = {
  file: [],
  nameFieldId: '',
  firstFieldName: '',
  filteredFields: [],
};

const requiredFields = (entityName, t, name) => [
  {
    id: 'nameFieldId',
    name: 'name',
    fieldType: 'name',
    title: name ?? `${entityName} ${t('Name')}`,
  },
];

// probably backend should not return fields if they are disabled (not available for an object)
const filterNotAvailableFields = (model, el) => {
  return (
    el.name !== 'name' &&
    el.fieldType !== FIELD_TYPES.Files.type &&
    !(isEntityValueField(el) && !model?.trackEntityValue) &&
    !(
      isPercentageChanceToCloseField(el) &&
      !model?.pipeline?.includePercentageToClose
    )
  );
};

// We want to compare column headers case-insensitve
// and without trailing or leading whitespace
const cleanValueForComparison = (val) => {
  return val ? val.toLowerCase().trim() : val;
};

const UploadRecordsModal = ({
  entityName,
  onHide,
  categorizedFields,
  model,
  ...props
}) => {
  const { t } = useTranslation();

  const [newEntities, setNewEntities] = useState({});
  const [values, setValues] = useState(defaultValues);
  const [CSVOptions, setCSVOptions] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [finalizeUploadData, setFinalizeUploadData] = useState([]);
  const [nameFieldError, setNameFieldError] = useState(null);
  const requiredFieldsWrapperRef = useRef(null);
  const dispatch = useDispatch();
  const [
    nameFieldErrorFieldMessage,
    ,
    flashNameFieldErrorMessage,
    cancelFlash,
  ] = useFlashTransition({
    stay: 3000,
  });

  const categories = useMemo(
    () =>
      categorizedFields.reduce((acc, c) => {
        acc[c.id] = { name: c.name };
        return acc;
      }, {}),
    [categorizedFields]
  );
  const allFields = useMemo(
    () => categorizedFields.flatMap((c) => c.fields),
    [categorizedFields]
  );

  const { dynamicTagsWithOptions, loadingDynamictagsOptions } =
    useDynamicTagOptionsForCSVTemplate(allFields, model);

  const orderedFields = useMemo(() => {
    const [defaultFields, customFields] = getFieldsForUpload(
      allFields,
      dynamicTagsWithOptions
    );
    return [...defaultFields, ...customFields];
  }, [allFields, dynamicTagsWithOptions]);

  const handleChangeFile = (file) => {
    setValues({ ...values, file });
  };

  useEffect(() => {
    if (orderedFields.length > 0) {
      const fieldName = orderedFields.find(isEntityNameField);
      setValues((prev) => ({
        ...prev,
        nameFieldId: fieldName.id,
        firstFieldName: fieldName.displayName,
        // name is required field
        // we do not support upload files from csv
        // hide not available fields
        filteredFields: orderedFields.filter((el) =>
          filterNotAvailableFields(model, el)
        ),
        categories: categories,
      }));
    }
  }, [orderedFields, categories, t, model]);

  useEffect(() => {
    if (!newEntities[values.nameFieldId]) {
      setNewEntities(
        values.filteredFields.reduce(
          (acc, curr) => {
            acc[curr.id] = {
              csvColumn: '',
              conflictResolution: '',
            };
            return acc;
          },
          {
            [values.nameFieldId]: {
              csvColumn: '',
              conflictResolution: '',
            },
          }
        )
      );
    }
  }, [values.filteredFields, newEntities, values.nameFieldId]);

  const handleSetCSVData = useCallback(
    (data) => {
      // check empty data from csv
      if (data && data.length > 0) {
        const options = data[0].map((item, index) => ({
          label: item,
          value: index.toString(),
        }));
        options.unshift({
          label: t('Don’t Upload'),
          value: NO_UPLOAD,
        });
        setCSVOptions(options);
        const newEntitiesAfteUploadData = values.filteredFields.reduce(
          (acc, curr) => {
            const index = data[0].findIndex(
              (el) =>
                cleanValueForComparison(el) ===
                cleanValueForComparison(curr.canonicalDisplayName)
            );
            acc[curr.id] = {
              csvColumn: index < 0 ? NO_UPLOAD : index.toString(),
              conflictResolution: getConflictResolution(curr.fieldType, curr),
            };
            return acc;
          },
          {}
        );
        const nameFieldIndex = data[0].findIndex(
          (el) =>
            cleanValueForComparison(el) ===
            cleanValueForComparison(values.firstFieldName)
        );
        const newEntitiesData = {
          ...newEntities,
          ...newEntitiesAfteUploadData,
          [values.nameFieldId]: {
            csvColumn: `${nameFieldIndex}`,
            conflictResolution: '',
          },
        };
        setNewEntities(newEntitiesData);
        const slicedSCVData =
          data.length > 5 ? data.slice(1, 6) : data.slice(1);
        setFinalizeUploadData(
          slicedSCVData.map((el, i) => ({
            id: i,
            ...getInitialFinalizeUploadData(el, newEntitiesData),
          }))
        );
        setCsvData(slicedSCVData);
        return;
      }
      setCSVOptions([]);
      setCsvData([]);
      setFinalizeUploadData([]);
      setNewEntities({});
    },
    [
      newEntities,
      values.filteredFields,
      values.nameFieldId,
      values.firstFieldName,
      t,
    ]
  );

  const handleChangeMatchRecords = useCallback(
    (data, element) => {
      if (nameFieldError && values?.nameFieldId === element && data) {
        setNameFieldError(null);
        cancelFlash();
      }
      setNewEntities((prev) => ({
        ...prev,
        [element]: {
          ...prev[element],
          csvColumn: data.value,
        },
      }));
      setFinalizeUploadData((prev) =>
        prev.map((curr, i) => ({
          ...curr,
          [element]: csvData[i][+data.value],
        }))
      );
    },
    [csvData, nameFieldError, cancelFlash, values]
  );

  const createPayload = async () => {
    const copyNewEntities = { ...newEntities };
    delete copyNewEntities[values.nameFieldId];
    const body = {
      s3_objectId: values.file[0].id,
      nameColumn: +newEntities[values.nameFieldId].csvColumn,
      fieldMapper: changeCSVColumnType(copyNewEntities),
    };
    try {
      await FieldService.customObjectUploader(model, body);
      dispatch(
        setToast({
          variant: toastVariant.SUCCESS,
          message: t(
            'Your upload has been successfully started. You will be emailed once the upload is complete.'
          ),
        })
      );
      onHide();
    } catch (err) {
      const nameColumnError = fetchErrorByKey(err, 'name_column');
      const nameColumnErrorMessage =
        nameColumnError && getErrorMessage(nameColumnError);
      if (nameColumnErrorMessage) {
        requiredFieldsWrapperRef?.current.scrollIntoView({
          behavior: 'smooth',
        });
        setNameFieldError(nameColumnErrorMessage);
        flashNameFieldErrorMessage(nameColumnErrorMessage);
      }
      dispatch(
        setToast({
          variant: toastVariant.FAILURE,
          message: t('The records have not been uploaded.'),
        })
      );
    }
  };

  const isValidSecondStep = useMemo(
    () =>
      values.file.length > 0 && values.file[0].created && CSVOptions.length > 0,
    [values.file, CSVOptions]
  );

  const isValidLastStep = useMemo(
    () => isValidSecondStep && newEntities[values.nameFieldId]?.csvColumn >= 0,
    [newEntities, values.nameFieldId, isValidSecondStep]
  );

  useEffect(() => {
    if (!isValidLastStep && requiredFieldsWrapperRef.current) {
      requiredFieldsWrapperRef.current.scrollIntoView({
        behavior: 'smooth',
      });
      const message = t('This field is required.');
      setNameFieldError(message);
      flashNameFieldErrorMessage(message);
    }
  }, [flashNameFieldErrorMessage, isValidLastStep, t]);

  const nameField = useMemo(
    () => orderedFields.find((el) => el.id === values.nameFieldId),
    [orderedFields, values.nameFieldId]
  );

  return (
    <BasicModalWithConfirmation
      dirty={!!values.file?.length}
      onHide={onHide}
      size="large"
      heading={t('Upload Custom Object Records', { name: entityName })}
      leftBtn={
        <Button variant="text" color="red" onClick={onHide}>
          {t('Cancel')}
        </Button>
      }
      buttonText={t('Upload')}
      disabled={!isValidLastStep}
      onConfirm={createPayload}
      {...props}
    >
      <StyledGenericWizardModalBody>
        <StyledUploadDataSection header={t('Upload Your Data')} isActive>
          <StyledLoader
            loading={!isValidSecondStep && loadingDynamictagsOptions}
          >
            <UploadDataSection
              fields={orderedFields}
              files={values.file}
              onChange={handleChangeFile}
              setCSVData={handleSetCSVData}
              customObject={model}
            />
          </StyledLoader>
        </StyledUploadDataSection>
        <StyledMatchRecordsSection
          header={t('Match Records')}
          isActive={isValidSecondStep}
        >
          {isValidSecondStep && (
            <div ref={requiredFieldsWrapperRef}>
              <MatchRecordsSection
                setNewEntities={setNewEntities}
                requiredFields={requiredFields(
                  entityName,
                  t,
                  nameField?.displayName
                )}
                newEntities={newEntities}
                CSVOptions={CSVOptions}
                values={values}
                onChange={handleSetCSVData}
                fields={orderedFields}
                csvData={csvData}
                setFinalizeUploadData={setFinalizeUploadData}
                handleChangeMatchRecords={handleChangeMatchRecords}
                errorFieldId="nameFieldId"
                error={nameFieldError}
                errorMessage={nameFieldErrorFieldMessage}
                hasSkipBlanksOption
              />
            </div>
          )}
        </StyledMatchRecordsSection>
        <StyledFinalizeUploadSection
          header={t('Finalize Upload')}
          isActive={isValidLastStep}
        >
          {isValidLastStep && (
            <FinalizeUploadSection
              fields={orderedFields}
              finalizeUploadData={finalizeUploadData}
            />
          )}
        </StyledFinalizeUploadSection>
      </StyledGenericWizardModalBody>
    </BasicModalWithConfirmation>
  );
};

export default UploadRecordsModal;
