import { useCallback, useState } from 'react';
import useModal from '../useModal';

export const PROVIDERS = {
  GOOGLE: 'google',
  OUTLOOK: 'microsoft',
};

const basePath = import.meta.env.VITE_API_BASE_PATH;

const googleUrl = (businessId, employeeId, useCurrentTeamMember, fallback) => {
  if (useCurrentTeamMember) {
    return `${basePath}/team/google/login?business_id=${businessId}&v2=true`;
  }
  return `${basePath}/team/google/login?business_id=${businessId}&employee_id=${employeeId}&v2=true&fallback=${fallback}`;
};

const microsoftUrl = (
  businessId,
  employeeId,
  useCurrentTeamMember,
  fallback
) => {
  if (useCurrentTeamMember) {
    return `${basePath}/team/microsoft/login?business_id=${businessId}&v2=true`;
  }
  return `${basePath}/team/microsoft/login?business_id=${businessId}&employee_id=${employeeId}&v2=true&fallback=${fallback}`;
};

const useThirdPartyAuth = ({
  businessId,
  useCurrentTeamMember,
  ads,
  provider: providerDefault = null,
  teamMember: teamMemberDefault = null,
  header,
  providerReadOnly: providerReadOnlyDefault = false,
  callback,
  fallback = 'privacy_settings',
}) => {
  const [teamMember, setTeamMember] = useState(() => teamMemberDefault);
  const [provider, setProvider] = useState(() => providerDefault);
  const [providerReadOnly, setProviderReadOnly] = useState(
    () => providerReadOnlyDefault
  );

  const canSubmit =
    Boolean(provider) && Boolean(teamMember || useCurrentTeamMember || ads);

  const [addIntegratedEmailModalProps, addIntegratedEmailModalTriggerProps] =
    useModal({
      handleSubmit: () => {
        if (callback) {
          callback(provider, teamMember);
        } else {
          if (provider === PROVIDERS.GOOGLE) {
            window.open(
              googleUrl(
                businessId,
                teamMember?.value,
                useCurrentTeamMember,
                fallback
              ),
              '_blank',
              'noopener,noreferrer'
            );
          } else if (provider === PROVIDERS.OUTLOOK) {
            window.open(
              microsoftUrl(
                businessId,
                teamMember?.value,
                useCurrentTeamMember,
                fallback
              ),
              '_blank',
              'noopener,noreferrer'
            );
          }
        }
      },
    });

  const handleTriggerClick = useCallback(() => {
    setProviderReadOnly(false);
    setTeamMember(null);
    setProvider(null);
    addIntegratedEmailModalTriggerProps.onClick();
  }, [addIntegratedEmailModalTriggerProps]);

  return {
    triggerProps: {
      ...addIntegratedEmailModalTriggerProps,
      onClick: handleTriggerClick,
    },
    modalProps: {
      ...addIntegratedEmailModalProps,
      canSubmit,
      provider,
      teamMember,
      setTeamMember,
      setProvider,
      providerReadOnly,
      ads,
      header,
      useCurrentTeamMember,
    },
    setProvider,
    setProviderReadOnly,
    openModal: addIntegratedEmailModalTriggerProps.onClick,
  };
};

export default useThirdPartyAuth;
