import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '__components/Inputs/Select';
import ClearButton from '__components/Inputs/Select/ClearButton';
import type {
  Folder,
  FolderPayload,
} from 'store/automationsPage/automations.redux';
import { TopMenuButton } from '__components/Kizen/Menu';
import { useSelectEnter } from '__components/Inputs/Select/hooks';
import useModal from '__components/Modals/useModal';
import { ModalContentContainer, StyledBasicModal } from './styles';
import TextInput from '__components/Inputs/TextInput';
import { useFolderOptions } from './hooks/useFolderOptions';
import { getErrorMessage } from '__hooks/useErrors';
import { getOriginalError } from '__services/AxiosService';
import { useToast } from '__components/ToastProvider';
import { toastVariant } from '__components/ToastProvider';
import { AxiosError } from 'axios';
import { useFolderNameLimit } from './hooks/useFolderNameLimit';

type FolderSelectProps = {
  chosenFolder: Folder | null;
  folders: Folder[];
  validParentFolders: Folder[];
  onChange:
    | Dispatch<SetStateAction<Folder | null>>
    | ((folder: Folder | null) => void);
  label?: string;
  placeholder?: string;
  onAddFolder?: (data: {
    name: string;
    parentFolderId: string | null;
  }) => Promise<Folder>;
  error?: string;
  currentDirectory?: Folder | null;
};

type AddModalProps = {
  show: boolean;
  validParentFolders: Folder[];
  onHide(): void;
  onConfirm(data: FolderPayload): Promise<void>;
  inputValue: string;
  preselectedParentFolder: Folder | null;
};

const AddModal = ({
  show,
  onConfirm,
  onHide,
  inputValue,
  validParentFolders,
  preselectedParentFolder,
}: AddModalProps) => {
  const { t } = useTranslation();
  const { inputRef, name, handleChangeName } = useFolderNameLimit(inputValue);
  const [parentFolder, setParentFolder] = useState<Folder | null>(() => {
    if (!preselectedParentFolder || !preselectedParentFolder.parentFolderId) {
      return null;
    }
    return (
      validParentFolders.find((x) => x.id === preselectedParentFolder.id) ??
      null
    );
  });

  const handleConfirm = async () => {
    await onConfirm({ name, parentFolderId: parentFolder?.id ?? null });
  };

  const handleChange = (v: { value: Folder } | null) => {
    setParentFolder(v && v.value);
  };

  const { value, options } = useFolderOptions(validParentFolders, parentFolder);

  return (
    <StyledBasicModal
      show={show}
      heading={t('Add Folder')}
      disabled={!name}
      onHide={onHide}
      onConfirm={handleConfirm}
      forceFocus={false}
    >
      <ModalContentContainer>
        <TextInput
          inputRef={inputRef}
          label={t('Folder Name')}
          placeholder={t('Enter folder name')}
          value={name}
          onChange={handleChangeName}
        />
        <Select
          label={t('Parent Folder')}
          placeholder={t('Leave blank for root')}
          menuLeftButton={<ClearButton />}
          value={value}
          options={options}
          onChange={handleChange}
          inModal
        />
      </ModalContentContainer>
    </StyledBasicModal>
  );
};

export const FolderSelect = ({
  chosenFolder,
  folders,
  validParentFolders,
  onChange,
  onAddFolder,
  label,
  placeholder,
  error,
  currentDirectory,
}: FolderSelectProps) => {
  const { t } = useTranslation();
  const [showToast] = useToast();

  label = label ?? t('Folder');
  placeholder = placeholder ?? t('Leave blank for root');

  const [inputValue, setInputValue] = useState('');

  const { value, options } = useFolderOptions(folders, chosenFolder);

  const [addModalProps, , { show: showAddModal }] = useModal({
    handleSubmit: async (data: FolderPayload) => {
      const newFolder = await onAddFolder?.(data);

      if (newFolder) {
        onChange(newFolder);
      }
    },
    handleHide: () => {
      setInputValue('');
    },
    handleError: (error: AxiosError) => {
      showToast({
        message:
          getErrorMessage(getOriginalError(error)) || t('Error saving folder'),
        variant: toastVariant.FAILURE,
      });
    },
  });

  const handleChange = (v: { value: Folder } | null) => {
    onChange(v && v.value);
  };

  const selectEnterProps = useSelectEnter({
    handlePressEnter: showAddModal,
    search: inputValue,
    options,
    uncontrolledSearch: true,
  });

  return (
    <>
      <Select
        label={label}
        placeholder={placeholder}
        menuLeftButton={<ClearButton />}
        menuTopButton={
          onAddFolder ? (
            <TopMenuButton onClick={showAddModal}>
              {t('Add Folder')}
            </TopMenuButton>
          ) : undefined
        }
        value={value}
        options={options}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={setInputValue}
        {...selectEnterProps}
        inModal
        validate={
          error && {
            message: error,
            showMessage: true,
          }
        }
      />
      <AddModal
        {...addModalProps}
        inputValue={inputValue}
        preselectedParentFolder={currentDirectory}
        validParentFolders={validParentFolders}
      />
    </>
  );
};
