import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import {
  NotesContext,
  useNotesContext,
} from 'components/Charts/ScheduledActivities/notesContext';
import Loader from 'components/Kizen/Loader';
import { TRow } from 'components/Kizen/Table';
import ActivityService from 'services/ActivityService';
import CustomObjectsService from 'services/CustomObjectsService';
import TeamMemberService from 'services/TeamMemberService';
import { isMobile, useWindowSize } from 'app/spacing';
import useModal from 'components/Modals/useModal';
import {
  mapFetchUrls,
  mapContactColumn,
  mapRelatedColumns,
  makeDefaultAssignment,
  getOrderingParam,
  fetchConfig,
  whatForPayload,
  getQueryKeyWithoutCriteria,
} from './helpers';
import { ConfirmationModal } from 'components/Charts/ScheduledActivities/ConfirmationModal';
import DashletError from 'pages/Dashboard/components/DashletError';
import { getNowTimezoneAdjusted } from 'app/timezone';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import ListRow from './ListRow';
import { CompleteActivityModal } from 'components/Modals/ActivityCompletionModal/ActivityCompletionModal';
import CollapsibleRow from './CollapsibleRow';
import NotesPanel from './NotesPanel';
import { createColumns, SCHEDULED_ACTIVITIES_ACTIONS } from './Table';
import LazyLoadTable from 'components/Kizen/LazyLoad/LazyLoadTable';
import LazyLoadList from 'components/Kizen/LazyLoad/LazyLoadList';
import {
  useDefaultActivityRelationshipsPrefetch,
  useDefaultActivityRelationshipsQuery,
} from 'queries/models/activites';
import {
  ConfirmNoteEdits,
  ConfirmNoteEditsWithMentions,
} from 'components/Charts/ScheduledActivities/ScheduleActivityModal';
import { useModalControl } from 'hooks/useModalControl';
import { ScheduleActivityModal } from './ScheduleActivityModal';
import { DATE_FILTER_ALL_TIME_INDEX } from 'pages/Dashboard/hooks/useDashboardConfig';
import {
  LOG_ACTIVITY_SUCCESS,
  SCHEDULE_ACTIVITY_FAIL,
  SCHEDULE_ACTIVITY_LOAD_FAIL,
  SCHEDULE_ACTIVITY_SUCCESS,
} from 'pages/Common/Actions/activityHelpers';
import { toastVariant, useToast } from 'components/ToastProvider';
import { parseMentions } from 'components/Inputs/NotesRichTextEditor';
import {
  useQueries,
  useMutation,
  useQuery,
  useInfiniteQuery,
  useQueryClient,
} from 'react-query';
import { CUSTOM_OBJECTS, PAGE_CONFIGS, ACTIVITIES } from 'queries/query-keys';
import {
  Divider,
  StyledKizenTypography,
  SwitchReversedLabel,
  Wrapper,
  ActivityCountHeader,
  ActivityCountHeaderForModal,
  SearchPillWrapper,
} from './styled';
import {
  CONFIRMATION_TYPES,
  MIN_COLUMN_WIDTH,
  DEFAULT_PAGE_SIZE,
  defaultColumnSettings,
  INVALID_SORT_ERROR_MESSAGE,
} from './constants';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';
import { useErrorByKey } from 'hooks/useErrors';
import { collapseNumber } from 'utility/numbers';
import { getBusinessTimeZone } from 'store/authentication/selectors';
import { getOriginalError } from 'services/AxiosService';
import { useVirtualizer } from '@tanstack/react-virtual';
import { GradientRightRow } from 'components/Kizen/LazyLoad/helpers';
import { useUnregisteredDashletEnabled } from 'pages/Dashboard/context/dataManager/getters';
import { SearchPill } from '@kizen/kds/SearchPill';

const ScheduledActivities = ({
  DashletAction,
  DashletHeaderText,
  DashletMenuContents,
  teamFilter,
  dateFilter,
  disableFilterToggle,
  dashlet,
  columnConfig,
  updateColumnConfig,
  canEdit,
  dashletSearch,
  onInfiniteQueryLoading,
  handleBustCache,
  showMyActivities: showMyActivitiesDefault = true,
  dashletDataLoading: isDashletLoading = false,
  isDashletUpdating = false,
  // record page props
  inModal = false,
  currentEntity,
  currentObject,
  whatFor = {},
  onUpdateSources,
  handleNoteUpdated,
  fieldStateDirty = false,
  logActivityActions,
  updateData,
  scheduledActivityId = null,
  loadingActivities = false,
  fetchActivities: fetchRecordPageActivities = null,
  onOverrideShowDashletMenu,
  showModal = true,
  blockMetadata,
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const queryClient = useQueryClient();
  // Makes the relationship data ready to go when needed
  useDefaultActivityRelationshipsPrefetch();

  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const { width } = useWindowSize();
  const mobileDisplay = isMobile(width);
  const [showMyActivities, setShowMyActivites] = useState(
    showMyActivitiesDefault
  );
  const [completeActivity, setCompleteActivity] = useState(null);
  const [loggingActivity, setLoggingActivity] = useState(false);
  const [showNotesId, setShowNotesId] = useState(scheduledActivityId);
  const [editActivity, setEditActivity] = useState(null);
  const [predefinedOptions, setPredefinedOptions] = useState({});

  const notesValue = useNotesContext();
  const [editorKey, setEditorKey] = useState(() => Date.now());
  const [isSchedulingActivity, setSchedulingActivity] = useState(false);
  const [scrollEnds, setScrollEnds] = useState({});
  const [disableAutoOpen, setDisableAutoOpen] = useState(false);
  const [fetchActivityError, setFetchActivityError] = useState('');
  const [observedWidth, setObservedWidth] = useState(null);
  const wrapperRef = useRef(null);
  const refetchDataInModalRef = useRef(true);
  const [inModalSearch, setInModalSearch] = useState('');
  // This is an annoying hack to deal with the scrollbar shifting the results as you
  // search. TODO: once the scrollbar component is reworked, this should be removed.
  const noScrollBars = scrollEnds.scrolledTop && scrollEnds.scrolledBottom;

  const [
    isConfirmModalOpen,
    { showModal: showConfirmationModal, hideModal: hideConfirmationModal },
  ] = useModalControl();
  const confirmationPropsRef = useRef({});
  const handleToggleShowMyActivities = () => {
    setShowMyActivites((prev) => !prev);
  };
  const businessTimezone = useSelector(getBusinessTimeZone);
  const access = useSelector((s) => s.authentication.access);
  const contactAccess = access?.sections?.contacts_section?.view ?? false;

  const { data: rawColumnSettings, isLoading: isRawColumnSettingsLoading } =
    useQuery(PAGE_CONFIGS.SCHEDULED_ACTIVITIES, fetchConfig, {
      enabled: !columnConfig,
    });

  const columnWidthMap =
    dashlet?.config?.feExtraInfo?.scheduledActivitiesConfig?.columns.reduce(
      (acc, column) => {
        acc[column.id] = column.width;
        return acc;
      },
      {}
    );

  const [columnWidths, setColumnWidths] = useState(columnWidthMap ?? {});
  const [sortOrder, setSortOrder] = useState(
    rawColumnSettings?.sort ?? columnConfig?.sort
  );
  const savedColumns = useMemo(() => {
    if (inModal) {
      if (blockMetadata?.columns) {
        return blockMetadata.columns;
      }
      return rawColumnSettings?.columns?.length > 0
        ? rawColumnSettings?.columns
        : defaultColumnSettings;
    }

    const dashletConfigColumns =
      dashlet?.config?.feExtraInfo?.scheduledActivitiesConfig?.columns;

    return dashletConfigColumns?.length > 0
      ? dashletConfigColumns
      : defaultColumnSettings;
  }, [
    inModal,
    blockMetadata,
    rawColumnSettings,
    dashlet?.config?.feExtraInfo?.scheduledActivitiesConfig?.columns,
  ]);

  useEffect(() => {
    if (rawColumnSettings) {
      const rawColumnWidthMap = rawColumnSettings?.columns?.reduce(
        (acc, column) => {
          acc[column.id] = column.width;
          return acc;
        },
        {}
      );
      setSortOrder(rawColumnSettings.sort);
      setColumnWidths(rawColumnWidthMap);
    }
  }, [rawColumnSettings, setColumnWidths]);

  const [
    isConfirmActivityModalOpen,
    {
      showModal: showConfirmActivityModal,
      hideModal: hideConfirmActivityModal,
    },
  ] = useModalControl();

  const defaultActivityRelationshipsQuery =
    useDefaultActivityRelationshipsQuery();
  const {
    fetchUrlList = EMPTY_OBJECT,
    contactSortBy = { clientVal: 'client' },
    relatedObjects = null,
  } = useMemo(() => {
    if (defaultActivityRelationshipsQuery.isLoading) {
      return {};
    }
    const doa = defaultActivityRelationshipsQuery.data;
    return {
      fetchUrlList: mapFetchUrls(doa),
      contactSortBy: mapContactColumn(doa),
      relatedObjects: mapRelatedColumns(doa),
    };
  }, [
    defaultActivityRelationshipsQuery.data,
    defaultActivityRelationshipsQuery.isLoading,
  ]);

  const updatePageConfig = useCallback(
    async (val) => {
      const rmLocalizeFromDefaultColumns = val?.columns.map((el) => {
        const defaultItem = defaultColumnSettings.find((e) => e.id === el.id);
        if (!defaultItem) return el;
        return { ...el, label: el.label ? el.label : defaultItem.label };
      });
      if (updateColumnConfig) {
        updateColumnConfig({
          ...val,
          columns: rmLocalizeFromDefaultColumns,
        });
      } else {
        await TeamMemberService.setActivitiesListPageConfig({
          ...val,
          columns: rmLocalizeFromDefaultColumns,
        });
      }
    },
    [updateColumnConfig]
  );

  const fetchDashletActivities = useCallback(
    async (params, teamFilters, roleFilters) => {
      try {
        const response = await ActivityService.v2GetScheduledActivities(
          { params },
          teamFilters,
          roleFilters,
          dashlet?.id,
          {
            skipErrorBoundary: true,
          }
        );
        notesValue.reset();
        return response;
      } catch (error) {
        return {
          error: error?.response,
        };
      }
    },
    [notesValue, dashlet]
  );

  const fetchActivities = useMemo(() => {
    if (fetchRecordPageActivities) {
      return fetchRecordPageActivities;
    }
    return fetchDashletActivities;
  }, [fetchRecordPageActivities, fetchDashletActivities]);

  const teamMemberFilter = useMemo(() => {
    return teamFilter?.teamMembers ?? [];
  }, [teamFilter]);

  const roleFilter = useMemo(() => {
    return teamFilter?.roles ?? [];
  }, [teamFilter]);

  const calculatedDateFilter = useMemo(() => {
    if (dateFilter && dateFilter.selectedIndex !== DATE_FILTER_ALL_TIME_INDEX) {
      return {
        fromDate: dateFilter.start,
        toDate: dateFilter.end,
      };
    }
    return {};
  }, [dateFilter]);

  const criteria = useMemo(() => {
    return {
      assignedToMe: !disableFilterToggle ? showMyActivities : undefined, // was mine , should be assignedToMe
      completed: false,
      ordering: getOrderingParam(sortOrder ?? {}),
      pageSize: DEFAULT_PAGE_SIZE,
      search: inModal ? inModalSearch : dashletSearch,
      ...whatForPayload(whatFor),
      ...calculatedDateFilter,
    };
  }, [
    inModal,
    inModalSearch,
    whatFor,
    showMyActivities,
    sortOrder,
    dashletSearch,
    calculatedDateFilter,
    disableFilterToggle,
  ]);

  const queryKey = useMemo(() => {
    return ACTIVITIES.ACTIVITY_TABLE(
      dashlet?.id,
      dashlet?.config?.activityIds,
      criteria,
      teamMemberFilter,
      roleFilter,
      dashlet?.config?.filters,
      sortOrder
    );
  }, [
    dashlet?.id,
    dashlet?.config?.activityIds,
    dashlet?.config?.filters,
    criteria,
    teamMemberFilter,
    roleFilter,
    sortOrder,
  ]);

  const inifiniteQuery = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      onInfiniteQueryLoading?.(true);
      const { results, next, count, error } = await fetchActivities(
        {
          ...criteria,
          page: pageParam,
        },
        teamMemberFilter,
        roleFilter
      );

      if (error) {
        throw error;
      }

      return { results, next, count };
    },
    onSuccess: () => {
      onInfiniteQueryLoading?.(false);
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.next
        ? new URL(lastPage.next).searchParams.get('page')
        : undefined;
    },
    getPreviousPageParam: (previousPage) => {
      return previousPage?.prev
        ? new URL(previousPage.prev).searchParams.get('page')
        : undefined;
    },
    retry: false,
    useErrorBoundary: false,
    staleTime: Infinity,
    keepPreviousData: false,
    enabled:
      Boolean(queryKey) && !isDashletUpdating && !isRawColumnSettingsLoading,
  });

  const {
    hasPreviousPage,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    data: tableData,
    error,
    isFetching: fetching,
  } = inifiniteQuery;

  const errorValues = Object.values(error?.data.errors ?? {});
  const filterErrors = useErrorByKey(error, 'filters');
  const invalidSortError = errorValues?.some(
    (error) =>
      typeof error === 'string' && error?.includes(INVALID_SORT_ERROR_MESSAGE)
  );

  if (invalidSortError) {
    setSortOrder({
      column: 'due_datetime',
      direction: 'asc',
    });
    updatePageConfig({
      columns: savedColumns,
      sort: {
        column: 'due_datetime',
        direction: 'asc',
      },
    });
  }
  if (fetchActivityError === '' && errorValues.length > 0) {
    setFetchActivityError(errorValues[0]);
  }

  const { activities, activityPageById } = useMemo(() => {
    return (
      tableData?.pages?.reduce(
        (acc, page, pageIndex) => {
          acc.activities.push(...(page?.results ?? []));
          page?.results?.forEach((activity, resultsIndex) => {
            acc.activityPageById[activity.id] = { pageIndex, resultsIndex };
          });
          return acc;
        },
        { activities: [], activityPageById: {} }
      ) ?? { activities: [], activityPageById: {} }
    );
  }, [tableData]);

  const activityCount = useMemo(
    () => tableData?.pages?.[0]?.count ?? null,
    [tableData]
  );

  const currentActivity = useMemo(() => {
    return showNotesId
      ? activities?.find(({ id }) => id === showNotesId) ?? null
      : null;
  }, [showNotesId, activities]);

  const [currentNote, setCurrentNote] = useState(currentActivity?.note);

  if (activities && activities.length) {
    if (!showNotesId && !disableAutoOpen && inModal) {
      setShowNotesId(activities[0].id);
      notesValue.setCurrentId(activities[0].id);
      notesValue.setIsDirty(false);
      notesValue.setOriginalNote(activities[0].note);
    }
  }

  const { mutate: mutateActivity, isLoading: updateActivityLoading } =
    useMutation({
      mutationFn: async (activity) =>
        await ActivityService.v2UpdateScheduledActivities(activity),
      onError: ({ payload }) => {
        showToast({
          message:
            payload?.response?.data?.message ||
            t(
              'The Activity Notes were not saved. Please try again or contact Kizen support.'
            ),
          variant: toastVariant.FAILURE,
        });
      },
      onSettled: async (updatedActivities) => {
        const updatedActivity = updatedActivities[0];
        await queryClient.cancelQueries({ queryKey: queryKey });
        const { pages: previousPages, pageParams } =
          queryClient.getQueryData(queryKey);

        const updatedPages = structuredClone(previousPages);
        const { pageIndex, resultsIndex } =
          activityPageById[updatedActivity.id];
        if (updatedPages[pageIndex] && updatedPages[pageIndex].results) {
          updatedPages[pageIndex].results[resultsIndex] = {
            ...updatedActivity,
            associatedFields:
              updatedPages[pageIndex].results[resultsIndex].associatedFields,
          };
        }

        queryClient.setQueryData(queryKey, {
          pageParams,
          pages: updatedPages,
        });

        return { pageParams, pages: updatedPages };
      },
    });

  const updateActivityNotes = useCallback(
    (needToNotify = true) => {
      handleNoteUpdated?.(needToNotify);
    },
    [handleNoteUpdated]
  );

  const { mutate: deleteActivity } = useMutation({
    mutationFn: async () =>
      await ActivityService.v2DeleteScheduledActivities(showDeletionModal),
    onError: ({ payload }) => {
      showToast({
        message: payload?.response?.data?.message || SCHEDULE_ACTIVITY_FAIL(t),
        variant: toastVariant.FAILURE,
      });
    },
    onMutate: async () => {
      onUpdateSources?.();
      notesValue.reset();
    },
    onSettled: async () => {
      await queryClient.cancelQueries({ queryKey: queryKey });
      queryClient.invalidateQueries(ACTIVITIES.CUSTOM_SCHEDULED());
      const { pages: previousPages, pageParams } =
        queryClient.getQueryData(queryKey);

      const updatedPages = structuredClone(previousPages);
      const { pageIndex, resultsIndex } = activityPageById[showDeletionModal];
      if (updatedPages[pageIndex] && updatedPages[pageIndex].results) {
        updatedPages[pageIndex].results.splice(resultsIndex, 1);
        updatedPages[0].count -= 1;
      }

      queryClient.setQueryData(queryKey, {
        pageParams,
        pages: updatedPages,
      });

      setShowDeletionModal(false);
      return { pageParams, pages: updatedPages };
    },
  });

  const { mutateAsync: logActivity } = useMutation({
    mutationFn: async ({ activityObjectId, payload }) => {
      const result = await ActivityService.v2CompleteActivity({
        activityObjectId,
        payload,
      });

      return result;
    },
    onSuccess: () => {
      onUpdateSources?.();
      updateData?.();
      showToast({
        message: LOG_ACTIVITY_SUCCESS(t),
        variant: toastVariant.SUCCESS,
      });
      setCompleteActivity(null);
      setLoggingActivity(false);
      setPredefinedOptions({});
      queryClient.invalidateQueries(getQueryKeyWithoutCriteria(queryKey));
    },
  });

  const { mutate: createScheduleActivity } = useMutation({
    mutationFn: async (activityInfo) =>
      await ActivityService.v2CreateScheduledActivities(activityInfo),
    onSuccess: () => {
      onUpdateSources?.();
      showToast({
        message: SCHEDULE_ACTIVITY_SUCCESS(t),
        variant: toastVariant.SUCCESS,
      });
      setSchedulingActivity(false);
      setEditorKey(Date.now());
      handleNoteUpdated?.(false);
      queryClient.invalidateQueries(getQueryKeyWithoutCriteria(queryKey));
    },
  });

  const { mutate: updateScheduleActivity } = useMutation({
    mutationFn: async (activityInfo, opts) =>
      await ActivityService.v2UpdateScheduledActivities(activityInfo, opts),
    onSuccess: () => {
      onUpdateSources?.();
      showToast({
        message: SCHEDULE_ACTIVITY_SUCCESS(t),
        variant: toastVariant.SUCCESS,
      });
      setSchedulingActivity(false);
      setEditorKey(Date.now());
      handleNoteUpdated?.(false);
      queryClient.invalidateQueries(getQueryKeyWithoutCriteria(queryKey));
    },
  });

  const handleLogActivityError = (error) => {
    const orig = getOriginalError(error);
    showToast({
      message: orig?.message || SCHEDULE_ACTIVITY_LOAD_FAIL(t),
      variant: toastVariant.FAILURE,
    });
    setLoggingActivity(false);
  };

  const [completeModalProps, , completeModal] = useModal({
    handleSubmit: logActivity,
    handleError: handleLogActivityError,
  });

  const [addModalProps, , addModal] = useModal({
    handleSubmit: async (activityInfo, opts = {}) => {
      if (activityInfo.id) {
        updateScheduleActivity(activityInfo, opts);
      } else {
        createScheduleActivity(activityInfo, opts);
      }
    },
    handleHide: () => {
      setEditActivity(null);
    },
    handleError: (error) => {
      const orig = getOriginalError(error);
      if (orig?.errors?.activity_object_id) {
        showToast({
          message: t('Scheduled activity not found (completed or deleted).'),
          variant: toastVariant.FAILURE,
        });
      } else {
        showToast({
          message: orig.message || SCHEDULE_ACTIVITY_LOAD_FAIL(t),
          variant: toastVariant.FAILURE,
        });
      }
      setSchedulingActivity(false);
    },
  });

  const savedColumnIdsRef = useRef(savedColumns.map(({ id }) => id));

  useEffect(() => {
    const newColumnIds = savedColumns.map(({ id }) => id);
    if (!isEqual(savedColumnIdsRef.current, newColumnIds)) {
      savedColumnIdsRef.current = newColumnIds;
      queryClient.invalidateQueries(getQueryKeyWithoutCriteria(queryKey));
      queryClient.invalidateQueries(PAGE_CONFIGS.SCHEDULED_ACTIVITIES);
    }
  }, [queryClient, savedColumns, queryKey]);

  const openAddModal = useCallback(
    (e) => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }
      addModal.show();
      setPredefinedOptions({});
    },
    [addModal, setPredefinedOptions]
  );

  const handleCancelNoteEdit = useCallback(() => {
    // find the index and put the notes back
    updateActivityNotes(
      activities.findIndex(({ id }) => id === notesValue.currentId),
      notesValue.originalNote
    );
    setCurrentNote(notesValue.originalNote);

    // notes are not dirty
    notesValue.setIsDirty(false);
    const { nextShowNotesId, isNotes } = confirmationPropsRef.current;

    // if it was
    if (isNotes) {
      notesValue.setOriginalNote(null);
      notesValue.setCurrentId(nextShowNotesId);
      setShowNotesId(nextShowNotesId);
    }

    hideConfirmationModal();
  }, [hideConfirmationModal, activities, notesValue, updateActivityNotes]);

  const showEditModal = useCallback(
    (activity, openAddModal, note) => {
      setEditActivity({
        ...activity,
        note:
          activity.id === currentActivity?.id && note ? note : activity.note,
        mentions: parseMentions(activity.note).map((m) => ({
          id: m,
        })),
      });
      openAddModal();
    },
    [currentActivity]
  );

  const showCompleteModal = useCallback(
    (activity, note) => {
      setDisableAutoOpen(true);
      const defaultAssignment = makeDefaultAssignment(
        activity.associatedEntities,
        fetchUrlList
      );
      setCompleteActivity({
        activity: {
          ...activity,
          note:
            activity.id === currentActivity?.id && note ? note : activity.note,
        },
        predefinedOptions: { defaultAssignment },
      });
      if (fieldStateDirty) {
        showConfirmActivityModal();
      } else {
        completeModal.show();
      }
    },
    [
      completeModal,
      fieldStateDirty,
      fetchUrlList,
      showConfirmActivityModal,
      currentActivity,
    ]
  );

  const handleSelectAction = useCallback(
    (option, activity) => {
      const nextShowNotesId = activity.id === showNotesId ? null : activity.id;

      const handleShowConfirmationModal = (onContinue) => {
        const hasMentions = parseMentions(currentNote).length > 0;
        showConfirmationModal();
        confirmationPropsRef.current = {
          isNotes: true,
          nextShowNotesId,
          type: hasMentions
            ? CONFIRMATION_TYPES.SAVE_NOTIFY
            : CONFIRMATION_TYPES.SAVE,
          onContinue,
        };
      };

      switch (option.value) {
        case SCHEDULED_ACTIVITIES_ACTIONS.Complete.value: {
          if (notesValue.isDirty) {
            handleShowConfirmationModal((note) =>
              showCompleteModal(activity, note)
            );
          } else {
            showCompleteModal(activity);
          }
          break;
        }
        case SCHEDULED_ACTIVITIES_ACTIONS.ViewNotes.value:
        case SCHEDULED_ACTIVITIES_ACTIONS.HideNotes.value: {
          setDisableAutoOpen(true);
          if (showNotesId === null) {
            // opening with none open
            notesValue.setCurrentId(activity.id);
            notesValue.setIsDirty(false);
            notesValue.setOriginalNote(activity.note);
            setShowNotesId(nextShowNotesId);
          } else {
            // opening with another open or closing, both need to check dirty
            if (notesValue.isDirty) {
              handleShowConfirmationModal();
            } else {
              notesValue.setIsDirty(false);
              notesValue.setOriginalNote(
                nextShowNotesId ? activity.note : null
              );
              notesValue.setCurrentId(nextShowNotesId);
              setShowNotesId(nextShowNotesId);
            }
          }

          break;
        }
        case SCHEDULED_ACTIVITIES_ACTIONS.Edit.value: {
          if (notesValue.isDirty) {
            handleShowConfirmationModal((note) =>
              showEditModal(activity, openAddModal, note)
            );
          } else {
            showEditModal(activity, openAddModal);
          }
          break;
        }
        default: {
          // delete option
          setShowDeletionModal(activity.id);
        }
      }
    },
    [
      showNotesId,
      openAddModal,
      showEditModal,
      currentNote,
      notesValue,
      showConfirmationModal,
      showCompleteModal,
    ]
  );

  const columnAssociatedObjectFieldIds = useMemo(() => {
    return savedColumns
      .filter(({ fieldId }) => fieldId)
      .map(({ fieldId, objectId }) => ({
        fieldId,
        objectId,
      }));
  }, [savedColumns]);

  const objectQueries = useQueries(
    columnAssociatedObjectFieldIds.map(({ objectId }) => {
      return {
        queryKey: CUSTOM_OBJECTS.DETAILS(objectId),
        queryFn: () => CustomObjectsService.getCustomObjectDetails(objectId),
        enabled: Boolean(objectId),
      };
    })
  );

  const isCustomObjectsLoading = objectQueries.some((q) => q.isLoading);

  const objectsById = useMemo(() => {
    const result = {};
    for (const query of objectQueries) {
      if (query.data) {
        result[query.data.id] = {
          ...query.data,
          fieldsById: query.data.fields.reduce((acc, field) => {
            acc[field.id] = field;
            return acc;
          }, {}),
        };
      }
    }
    return result;
  }, [objectQueries]);

  const filteredColumnSettings = useMemo(() => {
    const columnFilter = ({ fieldId, objectId }) =>
      !fieldId || objectsById?.[objectId]?.fieldsById?.[fieldId];
    const activeColumns = savedColumns.filter(columnFilter).map((column) => {
      return {
        ...column,
        width: columnWidths[column.id],
      };
    });

    return {
      columns: activeColumns,
      sort: sortOrder,
    };
  }, [savedColumns, sortOrder, objectsById, columnWidths]);

  const columns = useMemo(() => {
    if (mobileDisplay) {
      return [];
    }

    const getColumnWidth = (id) => {
      if (id) {
        return columnWidths[id] > MIN_COLUMN_WIDTH
          ? columnWidths[id]
          : MIN_COLUMN_WIDTH;
      }
      return MIN_COLUMN_WIDTH;
    };

    const handleResizeColumnWidth = ({ id, width: newWidth }) => {
      if (id) {
        const newColumnWidths = {
          ...columnWidths,
          [id]: newWidth,
        };
        setColumnWidths(newColumnWidths);
      }
    };

    const now = getNowTimezoneAdjusted(businessTimezone);

    return createColumns(
      {
        now,
        showMyActivities,
        showNotesId,
        onSelectAction: handleSelectAction,
        onUpdateActivity: async (activity, patch) => {
          mutateActivity({ id: activity.id, ...patch });
        },
        columnSettings: filteredColumnSettings,
        objectsById,
        onResizeColumnWidth: handleResizeColumnWidth,
        onResizeStop: () => {
          updatePageConfig({
            ...filteredColumnSettings,
            sort: filteredColumnSettings.sort,
          });
        },
        getColumnWidth,
        contactSortBy,
        timezone: businessTimezone,
        noScrollBars,
        virtualized: true,
        canEdit,
        showColumnEditorIcon: !inModal,
      },
      t
    );
  }, [
    inModal,
    mobileDisplay,
    showMyActivities,
    filteredColumnSettings,
    objectsById,
    mutateActivity,
    contactSortBy,
    businessTimezone,
    handleSelectAction,
    showNotesId,
    updatePageConfig,
    canEdit,
    noScrollBars,
    columnWidths,
    t,
  ]);

  const headData = useMemo(
    () => ({
      meta: {
        sort: filteredColumnSettings.sort,
        onSort: async (column, direction) => {
          setSortOrder({
            column,
            direction,
          });
          await updatePageConfig({
            ...filteredColumnSettings,
            sort: { column, direction },
          });
        },
        onClickColumns: onOverrideShowDashletMenu,
      },
    }),
    [filteredColumnSettings, updatePageConfig, onOverrideShowDashletMenu]
  );

  const now = getNowTimezoneAdjusted(businessTimezone);

  const handleUpdateNote = useCallback(
    ({ notifyMentioned = false } = {}) => {
      mutateActivity({
        id: currentActivity.id,
        note: currentNote,
        mentions: parseMentions(currentNote),
        notifyMentioned,
      });
      notesValue.setIsDirty(false);
      const { nextShowNotesId } = confirmationPropsRef.current;
      notesValue.setOriginalNote(null);
      notesValue.setCurrentId(nextShowNotesId);
      setShowNotesId(nextShowNotesId);
    },
    [currentNote, currentActivity, notesValue, mutateActivity]
  );

  const handleSubmitContactData = async () => {
    await logActivityActions?.save();
    await hideConfirmActivityModal();
    setTimeout(() => {
      completeModal.show();
      // TOAST_DELAY
    }, 1500);
  };

  const handleDiscardChanges = async () => {
    await updateData();
    hideConfirmActivityModal();
    completeModal.show();
  };

  const tableBodyRef = useRef(null);
  const virtualScrollerRef = useRef(null);

  const activitiesWithHoles = useMemo(() => {
    const result = [];

    for (const activity of activities) {
      result.push(activity, null);
    }

    return result;
  }, [activities]);

  const desktopShowNotesIndex = useMemo(() => {
    return (
      (activitiesWithHoles?.findIndex((elem) => elem?.id === showNotesId) ??
        -2) + 1
    );
  }, [activitiesWithHoles, showNotesId]);

  const mobileShowNotesIndex = useMemo(() => {
    return activities?.findIndex((elem) => elem?.id === showNotesId) ?? -1;
  }, [activities, showNotesId]);

  const estimateDesktopSizeFromIndex = (index) => {
    if (index % 2 === 0) {
      return 40; // Height of the activity row
    }

    if (index === desktopShowNotesIndex) {
      return 216; // Height of the notes row when expanded
    }

    return 0; // Collapsed notes row
  };

  const desktopVirtualizer = useVirtualizer({
    count: (activities?.length ?? 0) * 2,
    estimateSize: estimateDesktopSizeFromIndex,
    getScrollElement: () => virtualScrollerRef.current,
    overscan: 7,
  });

  const estimateMobileSizeFromIndex = (index) => {
    if (index === mobileShowNotesIndex) {
      if (contactAccess) {
        return 340; // Height of the notes row when expanded with client card
      } else {
        return 350; // height of notes row when expanded without client card
      }
    }

    return 108; // Height of the activity row
  };

  const mobileVirtualizer = useVirtualizer({
    count: activities?.length ?? 0,
    estimateSize: estimateMobileSizeFromIndex,
    getScrollElement: () => virtualScrollerRef.current,
    overscan: 7,
  });

  useEffect(() => {
    if (mobileDisplay) {
      mobileVirtualizer.measure();
    } else {
      desktopVirtualizer.measure();
    }
  }, [
    desktopShowNotesIndex,
    mobileShowNotesIndex,
    desktopVirtualizer,
    mobileVirtualizer,
    mobileDisplay,
  ]);

  const loading =
    (fetching ||
      isRawColumnSettingsLoading ||
      loadingActivities ||
      isCustomObjectsLoading ||
      isDashletLoading) &&
    !isFetchingNextPage;

  const totalSize = mobileDisplay
    ? mobileVirtualizer.getTotalSize()
    : desktopVirtualizer.getTotalSize();

  const setTableStyles = useCallback(() => {
    tableBodyRef.current?.setAttribute(
      'style',
      `height: ${totalSize}px; width: 100%; position: relative;`
    );
  }, [totalSize]);

  const observer = useMemo(() => {
    return new ResizeObserver((entries) => {
      if (entries[0]) {
        setObservedWidth(entries[0].contentRect.width);
      }
    });
  }, []);

  // refetch data each time modal opens
  if (inModal && showModal && refetchDataInModalRef.current) {
    refetchDataInModalRef.current = false;
    queryClient.invalidateQueries(queryKey);
  } else if (inModal && !showModal) {
    refetchDataInModalRef.current = true;
  }

  useEffect(() => {
    const currentElement = wrapperRef.current;
    if ((inModal && !loading && showModal) || !inModal) {
      if (currentElement) {
        observer.observe(currentElement);
      }
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [
    observer,
    wrapperRef,
    setObservedWidth,
    dashlet,
    activities,
    showModal,
    loading,
    inModal,
  ]);

  useEffect(() => {
    setTableStyles();
  }, [setTableStyles, relatedObjects, savedColumns, activities, loading]);

  if (
    !(savedColumns && relatedObjects) ||
    (inModal && loading && !inModalSearch)
  ) {
    return <Loader loading paddingTop={inModal ? '-10px' : undefined} />;
  }

  if (fetchActivityError || filterErrors) {
    return <DashletError onRefresh={handleBustCache} />;
  }

  return (
    <>
      <ConfirmationModal
        show={isConfirmActivityModalOpen}
        onConfirm={handleSubmitContactData}
        onHide={hideConfirmActivityModal}
        additionalButtonText={t('Discard Changes')}
        additionalButtonColor="red"
        onAdditionalConfirm={handleDiscardChanges}
        actionBtnColor="green"
        buttonText={t('Save')}
      >
        {t(
          'The record has unsaved changes. Would you like to save them or discard them before logging your activity?'
        )}
      </ConfirmationModal>
      {isConfirmModalOpen &&
        confirmationPropsRef.current.type === CONFIRMATION_TYPES.SAVE && (
          <ConfirmNoteEdits
            onCancel={hideConfirmationModal}
            onDiscard={() => {
              setCurrentNote(notesValue.originalNote);
              handleCancelNoteEdit();
              confirmationPropsRef.current?.onContinue?.(
                notesValue.originalNote
              );
              confirmationPropsRef.current = {};
            }}
            onSave={() => {
              hideConfirmationModal();
              handleUpdateNote();
              confirmationPropsRef.current?.onContinue?.(currentNote);
              confirmationPropsRef.current = {};
            }}
          />
        )}
      {isConfirmModalOpen &&
        confirmationPropsRef.current.type ===
          CONFIRMATION_TYPES.SAVE_NOTIFY && (
          <ConfirmNoteEditsWithMentions
            onCancel={hideConfirmationModal}
            onDiscard={() => {
              setCurrentNote(notesValue.originalNote);
              handleCancelNoteEdit();
              confirmationPropsRef.current?.onContinue?.(
                notesValue.originalNote
              );
              confirmationPropsRef.current = {};
            }}
            onSave={() => {
              hideConfirmationModal();
              handleUpdateNote();
              confirmationPropsRef.current?.onContinue?.(currentNote);
              confirmationPropsRef.current = {};
            }}
            onSaveAndNotify={() => {
              hideConfirmationModal();
              handleUpdateNote({ notifyMentioned: true });
              confirmationPropsRef.current?.onContinue?.(currentNote);
              confirmationPropsRef.current = {};
            }}
          />
        )}
      <ActivityCountHeaderForModal show={inModal && !mobileDisplay}>
        {inModal && !mobileDisplay && Number.isInteger(activityCount)
          ? `${activityCount} ${
              (activityCount > 1) | (activityCount === 0)
                ? 'Activities'
                : 'Activity'
            }`
          : null}
      </ActivityCountHeaderForModal>
      {inModal ? (
        <SearchPillWrapper mobileView={mobileDisplay}>
          <SearchPill
            boundaryClassName="modal-body"
            placeholder={t('Search')}
            loading={!(savedColumns && relatedObjects) || (inModal && loading)}
            onChange={setInModalSearch}
            value={inModalSearch}
          />
        </SearchPillWrapper>
      ) : null}
      <Wrapper
        ref={wrapperRef}
        className={inModal ? 'wrapper' : 'no-drag'}
        mobileView={mobileDisplay}
        loading={fetching}
        inModal={inModal}
      >
        {access.scheduled_activities.associated_records.edit &&
          Boolean(DashletAction) && (
            <DashletAction>
              <StyledKizenTypography
                as="button"
                weight="bold"
                size="buttonLabel"
                textTransform="uppercase"
                onClick={openAddModal}
                to=""
                className="no-drag"
              >
                {`+ ${t('Add Activity')}`}
              </StyledKizenTypography>
            </DashletAction>
          )}
        {Boolean(DashletMenuContents) && (
          <DashletMenuContents>
            {access.scheduled_activities.all_records.view &&
              !disableFilterToggle && (
                <>
                  <SwitchReversedLabel
                    checked={showMyActivities}
                    onChange={handleToggleShowMyActivities}
                    label={t('Only Show My Activities')}
                    textPlacement="left"
                  />
                  <Divider />
                </>
              )}
          </DashletMenuContents>
        )}
        {Boolean(DashletHeaderText) && !mobileDisplay ? (
          <DashletHeaderText>
            {activityCount !== null ? (
              <ActivityCountHeader
                data-qa="activity-count-header"
                mobileView={mobileDisplay}
              >{`${collapseNumber(activityCount)} ${
                (activityCount > 1) | (activityCount === 0)
                  ? 'Activities'
                  : 'Activity'
              }`}</ActivityCountHeader>
            ) : null}
          </DashletHeaderText>
        ) : null}
        {mobileDisplay ? (
          <>
            {activityCount !== null ? (
              <ActivityCountHeader
                data-qa="activity-count-header"
                mobileView={mobileDisplay}
                inModal={inModal}
              >{`${collapseNumber(activityCount)} ${
                (activityCount > 1) | (activityCount === 0)
                  ? 'Activities'
                  : 'Activity'
              }`}</ActivityCountHeader>
            ) : null}
            <LazyLoadList
              data={activities}
              loading={loading}
              inModal={inModal}
              fetchingNextPage={isFetchingNextPage}
              onScrollThreshold={() => {
                if (hasNextPage) {
                  fetchNextPage();
                }
              }}
              loaderSize={DEFAULT_PAGE_SIZE}
              scrollContainerRef={virtualScrollerRef}
              className={inModal ? 'in-modal' : 'dashlet'}
            >
              <div
                ref={(ref) => {
                  tableBodyRef.current = ref;
                  setTableStyles();
                }}
              >
                {activities?.length
                  ? mobileVirtualizer.getVirtualItems().map((virtualItem) => {
                      const activity = activities[virtualItem.index];

                      return (
                        <div
                          data-activity-id={activity.id}
                          key={`${activity.id}-${virtualItem.key}-mobile`}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: `${virtualItem.size}px`,
                            transform: `translateY(${virtualItem.start}px)`,
                          }}
                        >
                          <NotesContext.Provider value={notesValue}>
                            <ListRow
                              showNotes={showNotesId === activity.id}
                              // The notes WYSIWYG editor isn't designed to have the 'value' change externally,
                              // so to get around this we force it to re-render when the editor key is explicitly changed
                              editorKey={editorKey}
                              {...activity}
                              activity={activity}
                              updateActivityNotes={updateActivityNotes}
                              updateNoteLoading={updateActivityLoading}
                              activityPageById={activityPageById}
                              scheduledActivityQueryKey={queryKey}
                              key={activity.id}
                              now={now}
                              index={virtualItem.index}
                              timezone={businessTimezone}
                              onSelectAction={handleSelectAction}
                              onUpdateActivity={mutateActivity}
                            />
                          </NotesContext.Provider>
                        </div>
                      );
                    })
                  : null}
              </div>
            </LazyLoadList>
          </>
        ) : (
          <LazyLoadTable
            data={activities}
            loading={loading}
            fetchingNextPage={isFetchingNextPage}
            columns={columns}
            headData={headData}
            onScrollThreshold={() => {
              if (hasNextPage) {
                fetchNextPage();
              }
            }}
            noLoaderPadding={hasPreviousPage}
            loaderSize={DEFAULT_PAGE_SIZE}
            inModal={inModal}
            onChangeScrollEnds={setScrollEnds}
            tableBodyRef={tableBodyRef}
            virtualScrollerRef={virtualScrollerRef}
            virtualized
            className={inModal ? 'in-modal' : 'dashlet'}
          >
            {/* Just a spacer when there are no results */}
            {(!activities || !activities.length) && <TRow />}
            {activitiesWithHoles?.length
              ? desktopVirtualizer.getVirtualItems().map((virtualItem, i) => {
                  let item = activitiesWithHoles[virtualItem.index];
                  let isNotes = false;

                  if (!item) {
                    // If we're looking at an empty slot, it represents the "notes" row for the
                    // immediately previous activity
                    item = activitiesWithHoles[virtualItem.index - 1];
                    isNotes = true;
                  }

                  const { employee, role, ...rest } = item;
                  const activity = {
                    employee: employee,
                    role: role,
                    ...rest,
                  };

                  const virtialItemStyles = {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: `${virtualItem.size}px`,
                    transform: `translateY(${virtualItem.start}px)`,
                  };

                  const notesVisible = showNotesId === activity.id;

                  if (isNotes) {
                    return (
                      <CollapsibleRow
                        className="collapsible"
                        totalCols={columns.length}
                        in={notesVisible}
                        hideScrollbar
                        inModal={inModal}
                        key={`${activity.id}-${virtualItem.key}-notes`}
                        style={virtialItemStyles}
                        endAdornment={
                          <td
                            width={80}
                            style={{
                              height: `${virtualItem.size}px`,
                              position: 'sticky',
                              right: 0,
                            }}
                          >
                            <div>
                              <GradientRightRow fadeIn />
                            </div>
                          </td>
                        }
                      >
                        {notesVisible ? (
                          <NotesContext.Provider value={notesValue}>
                            <NotesPanel
                              index={i}
                              activity={activity}
                              contactAccess={contactAccess}
                              onNotesChange={updateActivityNotes}
                              updateNoteLoading={updateActivityLoading}
                              scheduledActivityQueryKey={queryKey}
                              activityPageById={activityPageById}
                              inModal={inModal}
                              setCurrentNote={setCurrentNote}
                              // The notes WYSIWYG editor isn't designed to have the 'value' change externally,
                              // so to get around this we force it to re-render when the editor key is explicitly changed
                              key={editorKey}
                              containerWidth={observedWidth}
                            />
                          </NotesContext.Provider>
                        ) : null}
                      </CollapsibleRow>
                    );
                  }

                  return (
                    <TRow
                      key={`${activity.id}-${virtualItem.key}-data`}
                      className={`virtual ${notesVisible ? 'selected' : ''}`}
                      columns={columns}
                      data={activity}
                      style={virtialItemStyles}
                    />
                  );
                })
              : ''}
          </LazyLoadTable>
        )}
        <ConfirmDeletionModal
          show={showDeletionModal}
          onConfirm={deleteActivity}
          onHide={() => setShowDeletionModal(false)}
        >
          {t('This will permanently delete the scheduled activity.')}
        </ConfirmDeletionModal>
        {completeModalProps.show ? (
          <CompleteActivityModal
            {...completeActivity}
            {...completeModalProps}
            disabled={loggingActivity}
            currentEntity={currentEntity}
            currentObject={currentObject}
          />
        ) : null}
        {addModalProps.show && (
          <ScheduleActivityModal
            activity={editActivity}
            predefinedOptions={predefinedOptions}
            {...addModalProps}
            disabled={isSchedulingActivity}
          />
        )}
      </Wrapper>
    </>
  );
};

export default function ScheduledActivitiesWithEnablement({
  dashlet,
  ...props
}) {
  const isEnabled = useUnregisteredDashletEnabled(dashlet?.id);

  if (dashlet?.id && !isEnabled) {
    return <Loader loading />;
  }

  return <ScheduledActivities {...props} dashlet={dashlet} />;
}
