import { useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Collapse from 'react-bootstrap/Collapse';
import { shadowLight } from 'app/colors';
import BigTableLayout, {
  useBigTableLayout,
} from 'components/Layout/BigTableLayout';
import BigTable from 'components/Tables/Big';
import { TRow } from 'components/Kizen/Table';
import TablePagination from 'components/Tables/Big/TablePagination';
import PageToolbar, {
  PageMainSelector,
  PageSearchInput,
  PageToolbarFiltersButton,
  PageToolbarSection,
  PageToolbarTitle,
} from 'components/Layout/PageToolbar';
import { getAutomationsColumns } from './columns';
import { useSelector } from 'react-redux';
import {
  selectHasFilterError,
  selectAutomationsIsFiltering,
  selectAutomationRowData,
  selectCurrentDirectory,
} from 'store/automationsPage/automations.redux';
import { useClearFilter } from 'hooks/useClearFilter';
import { useFilterErrorEffect } from 'hooks/useFilterErrorEffect';
import { AutomationFilterDropdown } from './AutomationFilterDropdown';
import ButtonWithDropdown from 'components/Button/WithDropdown';

const StyledButtonWithDropdown = styled(ButtonWithDropdown)`
  button:first-child {
    max-width: 161px;
    overflow: hidden;
  }

  .ButtonMenu__menu {
    max-width: max-content;
  }
`;

const StyledCollapse = styled(Collapse)`
  ${shadowLight}
`;

const scrollToTop = () => {
  if (window.scrollTo) {
    // If there's browser support
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  } else {
    window.scrollTop = 0;
  }
};

const DesktopLayout = ({
  groupId = null,
  groups,
  handleChangeGroup,
  handleChangeSearch,
  search = null,
  pageNumber,
  pageSize,
  handleChangePageNumber,
  handleChangePageSize,
  handleChangeDirectory,
  sort,
  handleChangeSort,
  handleUpdateAutomation,
  handleSelectAction,
  handleClickNewAutomation,
  handleClickNewFolder,
  openMenu,
  toggleMenu,
  filterInfo,
  applyFiltersAction,
  saveGroupAction,
  deleteGroupAction,
  setFilterName,
  clearFilter,
  addFilterRowHandler,
  errorsReturned,
  ...others
}) => {
  const { t } = useTranslation();
  const isFiltering = useSelector(selectAutomationsIsFiltering);
  const hasFilterError = useSelector(selectHasFilterError);
  const rowData = useSelector(selectAutomationRowData);
  const currentDirectory = useSelector(selectCurrentDirectory);
  const automationsCount = useSelector(
    (s) => s.automationPage.automationsCount
  );

  const buttonMenuOptions = useMemo(() => {
    return [
      {
        value: 'new_automation',
        label: t('New Automation'),
      },
      {
        value: 'new_folder',
        label: t('New Folder'),
      },
    ];
  }, [t]);

  useFilterErrorEffect({ toggleMenu, hasFilterError });

  const { filterDropdownRef, scrollTransitioned, ...bigTablePageProps } =
    useBigTableLayout();
  const { scrolledToTable } = bigTablePageProps;

  const toggle = () => {
    if (scrolledToTable) {
      scrollToTop();
      toggleMenu(true);
    } else {
      toggleMenu((ps) => !ps);
    }
  };

  const automationsHeadData = useMemo(
    () => ({
      meta: {
        sort,
        onSort: (column, direction) => handleChangeSort({ column, direction }),
      },
    }),
    [sort, handleChangeSort]
  );

  const automationsColumns = useMemo(
    () =>
      getAutomationsColumns({
        currentDirectory,
        onUpdateAutomation: handleUpdateAutomation,
        onSelectAction: handleSelectAction,
        onChangeDirectory: handleChangeDirectory,
        t,
      }),
    [
      handleUpdateAutomation,
      handleSelectAction,
      handleChangeDirectory,
      currentDirectory,
      t,
    ]
  );

  const handleClearFilter = useClearFilter(clearFilter, toggleMenu);

  const handleButtonMenuChange = (opt) => {
    if (opt.value === 'new_automation') {
      handleClickNewAutomation();
    } else if (opt.value === 'new_folder') {
      handleClickNewFolder();
    }
  };

  return (
    <BigTableLayout
      filtersOpen={openMenu}
      toolbar={
        <PageToolbar>
          <PageToolbarSection>
            <PageMainSelector
              value={groupId || 'none'}
              options={groups}
              onChange={handleChangeGroup}
            />
            <PageToolbarFiltersButton
              badge={filterInfo.numberOfFilters}
              showBackground={openMenu && !scrollTransitioned}
              onClick={toggle}
              errors={hasFilterError}
            />
            <PageSearchInput
              placeholder={t('Find Automations')}
              value={search || ''}
              onChange={handleChangeSearch}
              loading={isFiltering}
              isClearable
            />
          </PageToolbarSection>
          <PageToolbarTitle
            count={automationsCount}
            single={t('Automation')}
            plural={t('Automations')}
          />
          <PageToolbarSection>
            <StyledButtonWithDropdown
              onChange={handleButtonMenuChange}
              onClick={handleClickNewAutomation}
              options={buttonMenuOptions}
            >
              {t('New Automation')}
            </StyledButtonWithDropdown>
          </PageToolbarSection>
        </PageToolbar>
      }
      filters={
        <StyledCollapse mountOnEnter in={openMenu}>
          <AutomationFilterDropdown
            ref={filterDropdownRef}
            key={filterInfo.key}
            filterData={filterInfo.config}
            groupId={groupId}
            numberOfFilters={filterInfo.numberOfFilters}
            applyFiltersAction={applyFiltersAction}
            saveGroupAction={saveGroupAction}
            deleteGroupAction={deleteGroupAction}
            filterName={filterInfo.name}
            setFilterName={setFilterName}
            clearFilter={handleClearFilter}
            errorsReturned={errorsReturned}
          />
        </StyledCollapse>
      }
      pagination={
        <TablePagination
          page={pageNumber}
          perPage={pageSize}
          totalCount={automationsCount}
          onChangePage={handleChangePageNumber}
          onChangePerPage={handleChangePageSize}
        />
      }
      {...bigTablePageProps}
      {...others}
    >
      <BigTable
        data-qa="automation-list"
        scrolledToTable={scrolledToTable}
        columns={automationsColumns}
        head={
          <TRow head columns={automationsColumns} data={automationsHeadData} />
        }
        staleData={isFiltering}
      >
        {rowData.map((item) => {
          return (
            <TRow key={item.id} columns={automationsColumns} data={item} />
          );
        })}
      </BigTable>
    </BigTableLayout>
  );
};

export default DesktopLayout;
