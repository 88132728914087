import { useCallback, useEffect, useMemo } from 'react';
import { deferExecution } from 'utility/defer';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useQueryClient } from 'react-query';
import { getDashboardQuery } from 'queries/dashboard';
import { useHistory } from 'react-router-dom';
import { snakeToCamelCaseKeys } from 'services/helpers';
import { DASHBOARD } from 'queries/query-keys';
import { RECORD_LIST_CONFIG_KEYS } from 'services/TeamMemberService';
import { toastVariant, useToast } from 'components/ToastProvider';
import { useTranslation } from 'react-i18next';

// This hook handles managing chart groups and the page config,
// but works with redux instead of react-query so that it can be integrated
// with pages which have existing page config and redux state.
const useReduxGroupConfig = (
  {
    selector,
    updatePageConfig,
    getData,
    dashboards,
    getChartRoute,
    chartId,
    skipRouter,
    objectId,
  },
  enabled = true
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [showToast] = useToast();

  const page = useSelector(selector);
  const { pageConfig: config, pageReady } = page;
  const modelId = page?.model?.id;

  const updateConfigKey = useCallback(
    (key, value) => {
      dispatch(
        updatePageConfig({
          [key]: value,
        })
      );
      dispatch(
        getData({
          updatePageConfig: true,
          updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.CHARTS,
        })
      );
    },
    [dispatch, getData, updatePageConfig]
  );

  const setCurrentDashboardId = useCallback(
    (value) => {
      return updateConfigKey('currentDashboard', value);
    },
    [updateConfigKey]
  );

  const currentDashboardKey = useMemo(() => {
    if (!config?.currentDashboard) {
      return dashboards?.[0]?.id;
    }
    return config?.currentDashboard;
  }, [config, dashboards]);

  const handleFetchDashboard = useCallback(
    () => getDashboardQuery(currentDashboardKey),
    [currentDashboardKey]
  );

  const onNavigationNeeded = useCallback(
    (history, id) => {
      deferExecution(() => history.replace(getChartRoute(id)));
    },
    [getChartRoute]
  );

  const processConfigAfterHide = useCallback(
    (id, error) => {
      if (currentDashboardKey === id || error) {
        const nextDashboards = dashboards.filter(
          (d) => d.id !== id && !d.hidden
        );
        setCurrentDashboardId(nextDashboards?.[0]?.id);
        if (nextDashboards.length > 0) {
          onNavigationNeeded(history, nextDashboards[0].id);
        }
      }
      if (error) {
        showToast({
          message: t(
            'The chart group you tried to view is not accessible to you. Contact your administrator if you think this is in error'
          ),
          variant: toastVariant.FAILURE,
        });
      }
    },
    [
      currentDashboardKey,
      setCurrentDashboardId,
      dashboards,
      showToast,
      history,
      onNavigationNeeded,
      t,
    ]
  );

  const dashboardQueryKey = useMemo(() => {
    return [...DASHBOARD.DASHBOARD, currentDashboardKey, objectId];
  }, [currentDashboardKey, objectId]);

  const {
    data: dashboardData,
    isLoading: dashboardLoading,
    refetch: refetchDashboard,
  } = useQuery(dashboardQueryKey, handleFetchDashboard, {
    enabled: !!(currentDashboardKey && enabled),
    onSettled: (result) => {
      // The query will return { error: true, response: AxiosError } rather than throw an error if the user
      // doesn't have permission for the current dashboard.
      if (result?.error) {
        processConfigAfterHide(currentDashboardKey, result.error);
      }
    },
  });

  useEffect(() => {
    if (
      dashboardData &&
      !dashboardLoading &&
      !chartId &&
      !skipRouter &&
      pageReady
    ) {
      onNavigationNeeded(history, dashboardData.id);
    }
  }, [
    dashboardData,
    dashboardLoading,
    chartId,
    history,
    skipRouter,
    onNavigationNeeded,
    pageReady,
  ]);

  const isDashboardHidden = dashboardData?.hidden;
  const isDashboardError = dashboardData?.error === true;

  const currentDashboard = useMemo(() => {
    if (isDashboardHidden || isDashboardError) {
      return null;
    }
    return snakeToCamelCaseKeys(dashboardData);
  }, [isDashboardHidden, isDashboardError, dashboardData]);

  const setPersistentDashboardState = useCallback(
    (value) => {
      return updateConfigKey('dashboardState', value);
    },
    [updateConfigKey]
  );

  const currentDashboardPermission = useMemo(() => {
    if (currentDashboard) {
      return currentDashboard.employeeAccess;
    }
    return dashboardLoading ? 'View' : 'None';
  }, [dashboardLoading, currentDashboard]);

  const processConfigAfterCreate = useCallback(
    (id) => {
      setCurrentDashboardId(id);
      queryClient.invalidateQueries(DASHBOARD.DASHBOARDS);
    },
    [setCurrentDashboardId, queryClient]
  );

  const processConfigAfterDelete = useCallback(
    (id) => {
      const currentMap = config.filtersByDashboard ?? {};
      const payload = {
        ...config,
        filtersByDashboard: {
          ...currentMap,
          [id]: undefined,
        },
      };

      if (currentDashboardKey === id) {
        const nextDashboards = dashboards.filter(
          (d) => d.id !== id && !d.hidden
        );
        payload.currentDashboard = nextDashboards?.[0]?.id;
      }
      dispatch(updatePageConfig(payload));
      dispatch(
        getData({
          updatePageConfig: true,
          updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.CHARTS,
        })
      );
      queryClient.invalidateQueries(DASHBOARD.DASHBOARDS);
    },
    [
      config,
      currentDashboardKey,
      dispatch,
      dashboards,
      queryClient,
      getData,
      updatePageConfig,
    ]
  );

  const processConfigAfterShow = useCallback(
    (id) => {
      if (!config.currentDashboard) {
        setCurrentDashboardId(id);
      }
    },
    [config, setCurrentDashboardId]
  );

  const processConfigAfterUpdate = useCallback(
    (id, updatePayload) => {
      if (typeof updatePayload.hidden === 'boolean') {
        if (updatePayload.hidden) {
          processConfigAfterHide(id);
        } else {
          processConfigAfterShow(id);
        }
      }
      queryClient.invalidateQueries([...DASHBOARD.DASHBOARD, id]);
      queryClient.invalidateQueries(DASHBOARD.DASHBOARDS);
    },
    [processConfigAfterHide, processConfigAfterShow, queryClient]
  );

  // Handle the initial use-case where the route is loaded without a dashboard ID
  useEffect(() => {
    if (
      Boolean(chartId) &&
      !config?.currentDashboard &&
      config?.currentDashboard !== chartId &&
      pageReady
    ) {
      setCurrentDashboardId(chartId);
    }
  }, [chartId, config?.currentDashboard, setCurrentDashboardId, pageReady]);

  // Handles the use-case where a route is loaded with a different dashboard ID than what's currently
  // loaded
  useEffect(() => {
    if (
      Boolean(chartId) &&
      Boolean(config?.currentDashboard) &&
      config?.currentDashboard !== chartId &&
      pageReady &&
      modelId === objectId // This check makes sure we don't have a problem when using the back button
    ) {
      setCurrentDashboardId(chartId);
    }
  }, [chartId, config, setCurrentDashboardId, pageReady, modelId, objectId]);

  return {
    setCurrentDashboardId,
    refetchDashboard,
    processConfigAfterCreate,
    processConfigAfterUpdate,
    processConfigAfterDelete,
    loading: dashboardLoading,
    currentDashboard,
    dashboardQueryKey,
    currentDashboardPermission,
    config,
    setPersistentDashboardState,
    modelId,
  };
};

export default useReduxGroupConfig;
