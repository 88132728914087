import { isEmpty, orderBy } from 'lodash';
import {
  changeLanguageInStorage,
  PSEUDO_LANGUAGE,
  getCurrentLanguageFromStorage,
} from 'i18n-config';
import { invalidate } from 'queries/invalidate';
import AxiosService from './AxiosService';
import {
  camelToSnakeCaseKeys,
  snakeToCamelCaseKeys,
  camelToSnakeCase,
} from './helpers';

const teamMemberForApi = ({
  firstName,
  lastName,
  mobilePhone,
  annualSalesGoal,
  permissionGroups,
  startDate,
  toolbarTemplate,
  trackAnnualSalesGoal,
  ...others
}) => ({
  ...others,
  first_name: firstName,
  last_name: lastName,
  mobile_phone: mobilePhone,
  annual_sales_goal: annualSalesGoal,
  permission_groups: permissionGroups,
  start_date: startDate,
  toolbar_template_id: toolbarTemplate?.value || null,
  track_annual_sales_goal: trackAnnualSalesGoal,
});

const roleForApp = (role) => ({
  ...role,
  permissionGroups: role.permission_groups,
  defaultForNewUsers: role.default_for_new_users,
});

const roleForApi = (role) => ({
  ...role,
  permission_groups: role.permissionGroups,
  default_for_new_users: role.defaultForNewUsers,
});

const settingsConfigForApp = (config) => ({
  ...config,
  columnOrder: config.column_order,
  recordsPerPage: config.records_per_page,
});

const settingsConfigForApi = (config) => ({
  ...config,
  column_order: config.columnOrder,
  records_per_page: config.recordsPerPage,
});

const permissionGroupForApp = ({ summary, contacts_section, ...others }) => {
  const {
    all_records,
    associated_records,
    default_fields,
    default_for_new_field,
    custom_fields,
    ...bulkActions
  } = contacts_section || {};
  return {
    ...snakeToCamelCaseKeys(others),
    contactsSection: contacts_section && {
      defaultFields: default_fields, // Do not transform these because they are dynamically keyed off field.name
      ...snakeToCamelCaseKeys({
        all_records,
        associated_records,
        default_for_new_field,
        custom_fields,
        ...bulkActions,
      }),
    },
    summary: {
      none: summary.nb_none,
      view: summary.nb_view,
      edit: summary.nb_edit,
      remove: summary.nb_remove,
    },
  };
};

const getOptionsListV2 = (element) => ({
  label: element.display_name,
  value: element.id,
});

const getOptionsList = (element) => ({
  label: element.full_name || element.name,
  value: element.id,
});

export const teamMemberForApp = (teamMember) => ({
  ...teamMember,
  firstName: teamMember.first_name,
  lastName: teamMember.last_name,
  fullName: teamMember.full_name,
  displayName: teamMember.display_name,
  mobilePhone: teamMember.mobile_phone,
  annualSalesGoal: teamMember.annual_sales_goal,
  permissionGroups: teamMember.permission_groups,
  startDate: teamMember.start_date,
});

export const RECORD_LIST_CONFIG_KEYS = {
  COLUMNS: 'columnConfig',
  CHARTS: 'chartConfig',
  LAYOUT: 'recordListPageConfig',
  QUICK_FILTERS: 'quickFilterConfig',
  DETAIL_PAGE: 'recordDetailLayout',
};

export const RECORD_LIST_PAGE_CONFIG_STRUCTURE = {
  [RECORD_LIST_CONFIG_KEYS.COLUMNS]: ['columns', 'columnOrder'],
  [RECORD_LIST_CONFIG_KEYS.CHARTS]: ['dashboardState', 'currentDashboard'],
  [RECORD_LIST_CONFIG_KEYS.LAYOUT]: [
    'filterObject',
    'group',
    'page',
    'queryFilter',
    'quickFilters',
    'search',
    'size',
    'sort',
    'viewVariant',
  ],
  [RECORD_LIST_CONFIG_KEYS.QUICK_FILTERS]: [
    'quickFilteringFacets',
    'quickFilterSettings',
  ],
  [RECORD_LIST_CONFIG_KEYS.DETAIL_PAGE]: ['selectedLayout'],
};

export const collectRecordListPageConfig = (config = {}, configKey) => {
  const result =
    configKey && RECORD_LIST_PAGE_CONFIG_STRUCTURE[configKey]
      ? Object.entries(config).reduce((collect, [key, value]) => {
          if (RECORD_LIST_PAGE_CONFIG_STRUCTURE[configKey].includes(key)) {
            collect[key] = value;
          }
          return collect;
        }, {})
      : config;

  return result;
};

class TeamMemberService {
  constructor() {
    if (!TeamMemberService.instance) {
      TeamMemberService.instance = this;
    }
    return TeamMemberService.instance;
  }

  getTeamMemberList = async (
    { search, ordering, page_size, page, isVerified, detail } = {},
    options
  ) => {
    const { data } = await AxiosService.get('/team', {
      params: {
        search,
        page_size,
        page,
        ordering,
        is_verified: isVerified,
        detail,
      },
      ...options,
    });

    return Array.isArray(data)
      ? data.map(teamMemberForApp)
      : snakeToCamelCaseKeys(data);
  };

  getTeamMemberTypeaheadNoMapping = async (
    { search, ordering, page, page_size, is_verified } = {},
    options
  ) => {
    const { data } = await AxiosService.get('/team/typeahead', {
      params: {
        search,
        page_size,
        page,
        ordering,
        is_verified,
      },
      ...options,
    });

    return data;
  };

  getTeamMemberTypeahead = async (
    { search, ordering, page, page_size, is_verified } = {},
    options
  ) => {
    const { data } = await AxiosService.get('/team/typeahead', {
      params: {
        search,
        page_size,
        page,
        ordering,
        is_verified,
      },
      ...options,
    });

    return Array.isArray(data)
      ? data.map(teamMemberForApp)
      : snakeToCamelCaseKeys(data);
  };

  getTeamMemberData = async () => {
    const { data } = await AxiosService.get('/team/mine?v2=true');
    // set preferred language if he was settled before
    // otherwise language will be set by browser
    if (data.language) {
      changeLanguageInStorage(
        getCurrentLanguageFromStorage() === PSEUDO_LANGUAGE
          ? PSEUDO_LANGUAGE
          : data.language
      );
      return data;
    }

    return data;
  };

  updateTeamMemberData = (teamMember) => {
    return AxiosService.put('/team/mine?v2=true', teamMember);
  };

  resetPassword = (passwordData) => {
    return AxiosService.post('/reset-password', {
      current_password: passwordData.current_password,
      password: passwordData.password,
    });
  };

  getExternalAccount = async ({ id } = {}) => {
    const { data } = await AxiosService.get(`/external-account/${id}`);
    return data;
  };

  getExternalAccounts = async ({ ordering, search, include_sharing } = {}) => {
    ordering = camelToSnakeCase(ordering);
    const { data } = await AxiosService.get('/external-account', {
      params: { ordering, search, include_sharing },
    });
    return data;
  };

  getExternalAccountsCC = async ({
    ordering,
    search,
    include_sharing,
  } = {}) => {
    return snakeToCamelCaseKeys(
      await this.getExternalAccounts({ ordering, search, include_sharing })
    );
  };

  getAllExternalAccounts = async ({
    ordering,
    search,
    include_sharing,
  } = {}) => {
    ordering = camelToSnakeCase(ordering);
    const { data } = await AxiosService.get('/external-account/all', {
      params: { ordering, search, include_sharing },
    });
    return data;
  };

  getAllExternalAccountsCC = async ({
    ordering,
    search,
    include_sharing,
  } = {}) => {
    return snakeToCamelCaseKeys(
      await this.getAllExternalAccounts({ ordering, search, include_sharing })
    );
  };

  deleteExternalAccount = async (id, options) => {
    const { data } = await AxiosService.delete(
      `/external-account/${id}`,
      options
    );
    return data;
  };

  updateExternalAccount = async (id, options) => {
    const { data } = await AxiosService.patch(
      `/external-account/${id}`,
      {
        ...options,
      },
      { skipErrorBoundary: true }
    );

    return data;
  };

  createApiKey = () => AxiosService.post('/api-key');

  getApiKeys = async (params, config) => {
    const { data } = await AxiosService.get(
      '/api-key',
      { params: camelToSnakeCaseKeys(params) },
      camelToSnakeCaseKeys(config)
    );
    return data;
  };

  getApiKey = async (id) => {
    const { data } = await AxiosService.get(`/api-key/${id}`);
    return data;
  };

  deleteApiKey = (id) => AxiosService.delete(`/api-key/${id}`);

  getTeamMemberOptionsList = async (cb) => {
    const { data } = await AxiosService.get('/team');
    return cb && typeof cb === 'function'
      ? cb(data)
      : data.map(getOptionsListV2);
  };

  getRolesOptionsList = async () => {
    const { data } = await AxiosService.get('/role');
    return data.map(getOptionsList);
  };

  getRoles = async ({ ordering } = {}, options) => {
    const { data } = await AxiosService.get('/role', {
      params: { ordering },
      ...options,
    });
    return data.map(roleForApp);
  };

  getRolesTypeahead = async (
    { search, ordering, page, page_size } = {},
    options
  ) => {
    const { data } = await AxiosService.get('/role', {
      params: {
        search,
        page_size,
        page,
        ordering,
      },
      ...options,
    });

    return Array.isArray(data)
      ? data.map(roleForApp)
      : snakeToCamelCaseKeys(data);
  };

  getPermissionGroups = async (
    { ordering, includeSummary = false } = {},
    options
  ) => {
    const { data } = await AxiosService.get('/permission-group', {
      params: { ordering, include_summary: includeSummary },
      ...options,
    });
    if (ordering === 'summary' || ordering === '-summary') {
      // The API doesn't support sorting by this field, so we do it here!
      const direction = ordering === 'summary' ? 'asc' : 'desc';
      return orderBy(
        data.map(permissionGroupForApp),
        ['summary.remove', 'summary.edit', 'summary.view', 'summary.none'],
        [direction, direction, direction, direction]
      );
    }

    return data.map(permissionGroupForApp);
  };

  getPermissionGroupsEnabledSection = async ({
    annotateMembership = true,
    annotateObjectSections = [],
  }) => {
    const { data } = await AxiosService.get('/permission-group', {
      params: {
        annotate_membership: annotateMembership,
        annotate_object_sections: annotateObjectSections.join(','),
      },
    });
    return data.map(snakeToCamelCaseKeys);
  };

  getPermissionGroup = async (id, { useLegacyConverter = false } = {}) => {
    const { data } = await AxiosService.get(`/permission-group/${id}`);
    return useLegacyConverter ? permissionGroupForApp(data) : data;
  };

  getPermissionsMetadata = async () => {
    const { data } = await AxiosService.get('/permissions/meta-data');
    return data;
  };

  resendVerification = async (id) => {
    const { data } = await AxiosService.post(`/team/${id}/resend_activation`);
    return data;
  };

  add = async (member) => {
    const { data } = await AxiosService.post('/team', teamMemberForApi(member));
    return data;
  };

  update = async ({ id, ...others }, member) => {
    const { data } = await AxiosService.put(
      `/team/${id}`,
      teamMemberForApi({
        firstName: others.firstName, // This is a required field for patches
        ...member,
      })
    );
    return data;
  };

  get = async (id, config) => {
    const { data } = await AxiosService.get(`/team/${id}`, config);
    return snakeToCamelCaseKeys(data);
  };

  delete = async (id) => {
    invalidate.FILTERS.ALL();
    const { data } = await AxiosService.delete(`/team/${id}`);
    return data;
  };

  addRole = async (role) => {
    const { data } = await AxiosService.post('/role', roleForApi(role));
    return data;
  };

  updateRole = async ({ id }, role) => {
    const { data } = await AxiosService.put(`/role/${id}`, roleForApi(role));
    return data;
  };

  duplicateRole = async (id) => {
    const { data } = await AxiosService.post(`/role/${id}/duplicate`);
    return data;
  };

  deleteRole = async (id) => {
    const { data } = await AxiosService.delete(`/role/${id}`);
    return data;
  };

  createPermissionGroup = async (pg) => {
    const { data } = await AxiosService.post(
      `/permission-group`,
      camelToSnakeCaseKeys(pg)
    );
    return data;
  };

  updatePermissionGroup = async (id, pg, config) => {
    const { data } = await AxiosService.patch(
      `/permission-group/${id}`,
      pg,
      config
    );
    return data;
  };
  extendPermissionGroup = async (pg, config) => {
    const { data } = await AxiosService.post(
      `/permission-group/extend-permissions`,
      camelToSnakeCaseKeys(pg),
      config
    );
    return data;
  };

  duplicatePermissionGroup = async (id) => {
    const { data } = await AxiosService.post(
      `/permission-group/${id}/duplicate`
    );
    return data;
  };

  deletePermissionGroup = async (id) => {
    const { data } = await AxiosService.delete(`/permission-group/${id}`);
    return data;
  };

  getPageConfig = async (key, defaultConfig) => {
    const { data } = await AxiosService.get(
      `/employee/mine/configs/page/${key}`
    );
    const settings = data.config;
    // When user first loads a list / table page, empty configuration is received from the APIsrc/services/TeamMemberService.js
    // In that case we return default configuration
    if (!settings || isEmpty(settings)) {
      return defaultConfig ?? { size: 50 };
    }
    return settingsConfigForApp(settings);
  };

  getPluginConfig = async (key, config) => {
    const { data } = await AxiosService.get(
      `/employee/mine/configs/plugins/${key}`,
      config
    );

    return data;
  };

  updatePluginConfig = async (key, payload, config) => {
    const { data } = await AxiosService.post(
      `/employee/mine/configs/plugins/${key}`,
      payload,
      config
    );
    return data;
  };

  deletePageConfig = (key, config) => {
    return AxiosService.delete(`/employee/mine/configs/page/${key}`, config);
  };

  deleteSubPageConfig = (key, config) => {
    return AxiosService.delete(`/employee/mine/configs/${key}`, config);
  };

  setPageConfig = async (key, config) => {
    const { data } = await AxiosService.put(
      `/employee/mine/configs/page/${key}`,
      {
        config: settingsConfigForApi(config),
      }
    );

    return settingsConfigForApp(data.config || config);
  };

  getBlockedEmails = async () => {
    const { data } = await AxiosService.get(`/business/privacy`);
    return snakeToCamelCaseKeys(data);
  };

  setBlockedEmails = async ({ blockOutgoingEmailsFrom, ...payload }) => {
    const { data } = await AxiosService.put(
      `/business/privacy`,
      camelToSnakeCaseKeys({
        ...payload,
        blockOutgoingEmailsFrom: blockOutgoingEmailsFrom.map(({ id }) => id),
      })
    );
    return snakeToCamelCaseKeys(data);
  };

  prepActivitiesListPageConfig = (openedActivitiesListPageConfig) => {
    const defaults = {
      columns: [],
      sort: {
        column: 'due_datetime',
        direction: 'asc',
      },
    };
    return snakeToCamelCaseKeys({
      ...defaults,
      ...openedActivitiesListPageConfig,
    });
  };

  setActivitiesListPageConfig = async (payload) => {
    const { data } = await AxiosService.put(
      `/employee/mine/configs/page/open_activities_list`,
      {
        config: camelToSnakeCaseKeys(payload),
      }
    );
    return this.prepActivitiesListPageConfig(data?.config);
  };

  getActivitiesListPageConfig = async () => {
    const { data } = await AxiosService.get(
      `/employee/mine/configs/page/open_activities_list`
    );
    return this.prepActivitiesListPageConfig(data?.config);
  };

  getCustomObjectRecordsListPageConfig = async (objectId, defaultConfig) => {
    const { data } = await AxiosService.get(
      `/employee/mine/configs/custom-objects/${objectId}`
    );
    const {
      [RECORD_LIST_CONFIG_KEYS.LAYOUT]: recordListPageConfig,
      [RECORD_LIST_CONFIG_KEYS.COLUMNS]: columnConfig,
      [RECORD_LIST_CONFIG_KEYS.CHARTS]: chartConfig,
      [RECORD_LIST_CONFIG_KEYS.QUICK_FILTERS]: quickFilterConfig,
      [RECORD_LIST_CONFIG_KEYS.DETAIL_PAGE]: detailPageConfig,
    } = snakeToCamelCaseKeys(data);
    let [columns, columnOrder] = (columnConfig?.columnOrder || []).reduce(
      (acc, columnId) => {
        if (columnConfig?.columns?.[columnId]?.visible) {
          acc[0][columnId] = columnConfig.columns[columnId];
          acc[1].push(columnId);
        }
        return acc;
      },
      [{}, []]
    );
    if (!columnOrder.length) {
      columns = defaultConfig?.columns || {};
      columnOrder = defaultConfig?.columnOrder || [];
    }
    return {
      ...defaultConfig,
      ...recordListPageConfig,
      ...columnConfig,
      ...chartConfig,
      ...quickFilterConfig,
      ...detailPageConfig,
      columns,
      columnOrder,
    };
  };

  updateCustomObjectRecordsListPageConfig = async (objectId, config = {}) => {
    const {
      columns,
      columnOrder,
      dashboardState,
      currentDashboard,
      quickFilterSettings,
      quickFilteringFacets,
      selectedLayout,
      ...restConfig
    } = config;
    const payload = {
      ...(Object.keys(restConfig).length
        ? {
            [RECORD_LIST_CONFIG_KEYS.LAYOUT]: restConfig,
          }
        : {}),
      ...(columns || columnOrder
        ? {
            [RECORD_LIST_CONFIG_KEYS.COLUMNS]: { columns, columnOrder },
          }
        : {}),
      ...(dashboardState || currentDashboard
        ? {
            [RECORD_LIST_CONFIG_KEYS.CHARTS]: {
              currentDashboard,
              dashboardState,
            },
          }
        : {}),
      ...(quickFilterSettings || quickFilteringFacets
        ? {
            [RECORD_LIST_CONFIG_KEYS.QUICK_FILTERS]: {
              quickFilteringFacets,
              quickFilterSettings,
            },
          }
        : {}),
      ...(selectedLayout
        ? {
            [RECORD_LIST_CONFIG_KEYS.DETAIL_PAGE]: { selectedLayout },
          }
        : {}),
    };

    const { data } = await AxiosService.post(
      `/employee/mine/configs/custom-objects/${objectId}`,
      camelToSnakeCaseKeys(payload)
    );

    const {
      [RECORD_LIST_CONFIG_KEYS.LAYOUT]: recordListPageConfig,
      [RECORD_LIST_CONFIG_KEYS.COLUMNS]: columnConfig,
      [RECORD_LIST_CONFIG_KEYS.CHARTS]: chartConfig,
      [RECORD_LIST_CONFIG_KEYS.QUICK_FILTERS]: quickFilterConfig,
      [RECORD_LIST_CONFIG_KEYS.DETAIL_PAGE]: detailPageConfig,
    } = snakeToCamelCaseKeys(data);

    return {
      ...recordListPageConfig,
      ...columnConfig,
      ...chartConfig,
      ...quickFilterConfig,
      ...detailPageConfig,
    };
  };

  getCustomObjectRecordPageConfig = async () => {
    const { data } = await AxiosService.get(
      `/employee/mine/configs/custom-object-detail`
    );
    return snakeToCamelCaseKeys(data);
  };

  updateCustomObjectRecordPageConfig = async (config) => {
    const { data } = await AxiosService.post(
      `/employee/mine/configs/custom-object-detail`,
      camelToSnakeCaseKeys(config)
    );
    return snakeToCamelCaseKeys(data);
  };

  getOAuthAccessTokens = async (params, config) => {
    const { data } = await AxiosService.get(
      `/oauth2/access-tokens`,
      { params: camelToSnakeCaseKeys(params) },
      camelToSnakeCaseKeys(config)
    );
    return snakeToCamelCaseKeys(data);
  };

  deleteOAuthAccessTokens = async (id) => {
    const { data } = await AxiosService.delete(`/oauth2/access-tokens/${id}`, {
      skipErrorBoundary: true,
    });
    return snakeToCamelCaseKeys(data);
  };

  exportTeam = async (body, params, config) => {
    const { data } = await AxiosService.post(
      `/team/export`,
      camelToSnakeCaseKeys(body),
      {
        ...config,
        params: camelToSnakeCaseKeys(params),
      }
    );
    return snakeToCamelCaseKeys(data);
  };

  teamUploader = async (payload) => {
    const { data } = await AxiosService.post(
      `/team/uploader`,
      camelToSnakeCaseKeys(payload)
    );
    return data;
  };
}

const instance = new TeamMemberService();
Object.freeze(instance);

export default instance;
