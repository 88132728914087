import { useCallback, useContext, useEffect, useMemo } from 'react';
import { PrimaryObjectUploadContext } from '../../context';
import { useFlashTransition } from '__hooks/useFlashState';
import { LoadVariable } from '__pages/SmartConnectors/types';

const errorsOrder: Partial<Record<keyof LoadVariable, number>> = {
  name: 0,
  data_type: 1,
};

export const useVariableErrors = () => {
  const {
    loadId,
    stepData,
    errors: { loads = [] },
    setErrors,
  } = useContext(PrimaryObjectUploadContext);

  const [message, showMessage, flashErrorMessage, cancelFlash] =
    useFlashTransition();

  const loadIndex = useMemo(() => {
    return (stepData.loads || []).findIndex(
      (load) => (loadId === 'new' && !load.id) || load.id === loadId
    );
  }, [stepData.loads, loadId]);

  const { execution_variable } = loads[loadIndex] || {};

  const hasError = Boolean(
    execution_variable && Object.values(execution_variable).length
  );

  const errorKey = useMemo<keyof LoadVariable | null>(() => {
    return execution_variable && hasError
      ? (Object.keys(execution_variable) as (keyof LoadVariable)[]).sort(
          (a, b) => errorsOrder[a]! - errorsOrder[b]!
        )[0]
      : null;
  }, [execution_variable, hasError]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (errorKey && execution_variable?.[errorKey]) {
      timeout = setTimeout(() =>
        flashErrorMessage(execution_variable[errorKey])
      );
    }
    return () => clearTimeout(timeout);
  }, [flashErrorMessage, errorKey, execution_variable]);

  const clearError = useCallback(
    (prop: keyof LoadVariable) => {
      if (hasError) {
        if (showMessage && errorKey === prop) {
          cancelFlash();
        }

        setErrors((prev) => ({
          ...prev,
          loads: (prev.loads || []).map((load, i) => {
            if (i === loadIndex) {
              return {
                ...load,
                execution_variable: {
                  ...load.execution_variable,
                  [prop]: undefined,
                },
              };
            }
            return load;
          }),
        }));
      }
    },
    [showMessage, cancelFlash, hasError, setErrors, errorKey, loadIndex]
  );

  const validate = useMemo(
    () => ({ showMessage, message }),
    [showMessage, message]
  );

  return {
    hasError,
    errorKey,
    validate,
    clearError,
  };
};
