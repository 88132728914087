import { useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { runScript } from '../run';
import { getBusinessClientObject } from 'store/authentication/selectors';
import { WorkerContextArgs } from '../types';
import { useHistory } from 'react-router-dom';
import { useToast } from '__components/ToastProvider';
import {
  DataAdornment,
  PluginContext,
} from 'ts-components/Plugins/PluginContext';

interface UseRecordDetailCustomScriptProps {
  entityId?: string;
  objectId?: string;
  onError: WorkerContextArgs['onError'];
  scriptUIRef?: WorkerContextArgs['scriptUIRef'];
}

export const useRecordDetailCustomScript = ({
  entityId,
  objectId,
  onError,
  scriptUIRef,
}: UseRecordDetailCustomScriptProps) => {
  const user = useSelector((s: any) => s.authentication.user);
  const business = useSelector((s: any) => s.authentication.chosenBusiness);
  const clientObject = useSelector(getBusinessClientObject);
  const history = useHistory();
  const [showToast, , clearToasts] = useToast();
  const { terminators, sessionData, setSessionData } =
    useContext(PluginContext);

  const [pending, setPending] = useState(false);
  const [runnerState, setRunnerState] = useState({ indicator: 'none' });

  const executeScript = useCallback(
    (
      scriptBody: string,
      args?: Record<string, unknown>,
      executionPlugin?: DataAdornment
    ) => {
      let stringArgs = '';
      try {
        stringArgs = JSON.stringify(args);
      } catch (e) {
        onError?.({ message: 'Arguments passed to the script are invalid' });
      }
      return runScript({
        user,
        business,
        onError,
        setLoadingState: setPending,
        scriptBody,
        clientObject,
        scriptUIRef,
        onStateChange: (state) =>
          setRunnerState((s) => {
            return {
              ...s,
              ...state,
            };
          }),
        workerName: 'recordDetail',
        args: stringArgs,
        context: {
          entityId,
          objectId,
        },
        history,
        onShowToast: showToast,
        onClearToasts: clearToasts,
        terminators,
        executionPlugin,
        sessionData,
        setSessionData,
      });
    },
    [
      user,
      business,
      entityId,
      objectId,
      onError,
      clientObject,
      scriptUIRef,
      history,
      showToast,
      clearToasts,
      terminators,
      sessionData,
      setSessionData,
    ]
  );

  return [
    executeScript,
    {
      ...runnerState,
      pending,
    },
  ];
};
