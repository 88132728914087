import { forwardRef, useCallback, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { format, isMatch, parseISO, parse } from 'date-fns2';
import { useClickAway } from 'react-use';
import { borderRadii, scrollbarCss, useWindowSize } from 'app/spacing';
import { grayScale } from 'app/colors';
import DatePicker from 'components/Kizen/DatePicker';
import StylePassthrough from 'components/Kizen/StylePassthrough';
import DatePickerTextInput from 'components/Inputs/TextInput/presets/DatePicker';
import useField from 'hooks/useField';
import { getDataQAForInput } from 'components/Inputs/helpers';
import IconAdornment from '../Adornments/IconAdornment';
import {
  MaybeInputControl,
  getNonInputControlProps,
  hasInputControl,
} from '../InputControl';
import Select from '../Select';
import {
  Menu as CustomMenu,
  Control as CustomControl,
} from '../Select/customize';
import { applyRef } from '../props';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { PortaledMenuWrapper } from '../portalLayout';

export const DATE_DISPLAY_FORMAT = 'MM/DD/YYYY';
export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_EMPTY_FORMAT = '--/--/--';
export const DATE_TIME_FORMAT = 'YYYY-MM-DDThh:mm';
export const DATETIME_DISPLAY_FORMAT = 'MM/DD/YYYY h:mm a';

export const MIN_DATE_LENGTH = 10;

const ISO_FORMAT = 'yyyy-MM-dd';
const UI_FORMAT = 'MM/dd/yyyy';

const correctStringFormat = (value) => {
  // if the user types in a date in the format MM/dd/yyyy, we need to convert it to yyyy-MM-dd
  if (isMatch(value, UI_FORMAT) || isMatch(value, ISO_FORMAT)) {
    const fmt = isMatch(value, ISO_FORMAT) ? ISO_FORMAT : UI_FORMAT;
    const result = parse(value, fmt, new Date());
    return format(result, ISO_FORMAT);
  }

  return value;
};

const dateOnlyString = (value) =>
  typeof value === 'string'
    ? correctStringFormat(value)
    : format(value, ISO_FORMAT);

export const getDateOption = (value) => {
  // we always use UTC for DateInput. Agreement with BE developers

  if (
    value &&
    typeof value === 'object' &&
    'date' in value &&
    'value' in value &&
    'label' in value
  ) {
    // It's already a Select-style option
    return value;
  }

  // gaurd against bad dates
  if (isNaN(Date.parse(value))) {
    return {
      date: null,
      value: '',
      label: '',
    };
  }

  // get a string in the yyyy-mm-dd format, from either a date object or a string
  const date = parseISO(dateOnlyString(value));

  return {
    date,
    value: date.toISOString(),
    label: format(date, UI_FORMAT),
  };
};

export const Menu = styled(CustomMenu)`
  width: fit-content;
  overflow: hidden;
  && {
    border-top-left-radius: ${borderRadii.small};
    border-top-right-radius: ${borderRadii.small};
    border-bottom-left-radius: ${borderRadii.small};
    border-bottom-right-radius: ${borderRadii.small};
    border: 1px solid ${grayScale.medium};
    ${({ selectProps: { menuInline, rightPosition } }) => {
      if (rightPosition && menuInline) {
        return css`
          float: right;
        `;
      }
      if (rightPosition && !menuInline) {
        return css`
          right: 0;
        `;
      }
      return '';
    }}

    ${({ placement }) =>
      placement === 'top' &&
      css`
        margin-bottom: -1px;
      `}
    ${({ placement }) =>
      placement !== 'top' &&
      css`
        margin-top: -1px;
      `}
  }
  .rs-calendar {
    width: 270px;
    .rs-calendar-header {
      max-width: 100%;
    }
    // TODO These styles below may need to eventually be ported into the base date picker.
    // Just trying to not break other areas of the app for now :)
    padding-top: 7px;
    .rs-calendar-header {
      .rs-calendar-header-month-toolbar {
        padding: 0 10px; // 5px of incidental padding next to the left/right buttons
      }
    }
    .rs-calendar-view {
      padding: 5px 8px 5px;
    }
    .rs-calendar-table-cell {
      padding-bottom: 0;
    }
    .rs-calendar-table-cell-content {
      height: 30px;
      width: 30px;
    }
    .rs-calendar-month-dropdown-row-wrapper {
      ${scrollbarCss}
    }
  }
`;

const ControlStyles = styled(StylePassthrough)`
  ${({ selectProps: { variant } }) =>
    variant !== 'underline' &&
    css`
      && {
        border-top-left-radius: ${borderRadii.small};
        border-top-right-radius: ${borderRadii.small};
        border-bottom-left-radius: ${borderRadii.small};
        border-bottom-right-radius: ${borderRadii.small};
      }
    `}
`;

const Control = (props) => (
  <ControlStyles {...props}>
    <CustomControl {...props} isFocused={false} />
  </ControlStyles>
);

export const MenuList = ({ getValue, setValue }) => {
  const [value] = [].concat(getValue() || []);
  return (
    <DatePicker
      value={value && value.date}
      onSelect={(date) => setValue(getDateOption(date), 'set-value')}
      showClearButton={false}
      showApplyButton={false}
    />
  );
};

export const TextInputWrapper = styled.div`
  & input {
    width: 100%;
  }
`;

const DateInput = forwardRef((props, ref) => {
  const {
    onChange = null,
    variant = 'underline',
    value = null,
    className,
    isDateTime = false,
    'data-field-type': qaFieldType,
    'data-field-id': qaFieldId,
    error,
    clickNotify = null,
    fullWidth = false,
    portal,
    placeholder = DATE_DISPLAY_FORMAT,
    label,
  } = props;
  const changeRef = useRef(false);

  // we use this to keep track of changes and set changeRef.current to true
  const changeHandler = useCallback(
    (val) => {
      onChange(val);
      changeRef.current = true;
    },
    [onChange]
  );

  const [rightPosition, setRightPosition] = useState(false);
  const [formattedValue, setFormattedValue] = useField(
    () => getDateOption(value),
    [value]
  );
  const [showMenu, _setShowMenu] = useState(false);
  const [initValue, setInitValue] = useState(value || new Date());
  const containerRef = useRef();

  // textInputMode indicates that a user has typed in a date rather than selecting one from
  // the calendar. If the date was typed, there's a bit of fa different path on blur.
  const [textInputMode, setTextInputMode] = useState(false);

  const setShowMenu = useCallback(
    (val) => {
      if (clickNotify) clickNotify();
      _setShowMenu(val);
    },
    [_setShowMenu, clickNotify]
  );

  const windowSize = useWindowSize();
  const measuredRef = useCallback(
    (stateManager) => {
      if (stateManager && windowSize && !fullWidth) {
        const parent = document.body.getBoundingClientRect();
        const child = stateManager.getBoundingClientRect();
        setRightPosition(child.left - parent.left > parent.right - child.right);
      }
      applyRef(ref, stateManager);
      applyRef(containerRef, stateManager);
    },
    [ref, windowSize, fullWidth]
  );

  const handleInputChange = (v) => {
    setShowMenu(false);
    setFormattedValue((prev) => ({
      ...prev,
      label: v,
    }));
    setTextInputMode(true);
  };

  const handleOnBlur = () => {
    const isValidDate =
      formattedValue?.label.length === MIN_DATE_LENGTH &&
      isMatch(formattedValue?.label, UI_FORMAT);

    setShowMenu(false);

    if (!formattedValue?.value && !textInputMode) {
      return;
    }

    if (!isValidDate) {
      setFormattedValue(getDateOption(initValue));
      return;
    }

    setInitValue(formattedValue.label);
    setFormattedValue(getDateOption(formattedValue.label));

    // if no changes have happened, don't call the change handler
    if (changeRef.current) {
      changeHandler(new Date(formattedValue.label));
    }
    setTextInputMode(false);
  };

  const handleDateBlur = () => {
    if (textInputMode) {
      changeRef.current = true;
      handleOnBlur();
    }
  };

  // handle click away a tick later so that the date blur fires first
  const handleClickAway = () => {
    if (showMenu) {
      setTimeout(() => {
        handleOnBlur();
      }, 1);
    }
  };

  useClickAway(containerRef, handleClickAway);

  const outlineVariant = variant === 'outline';
  const selectProps = getNonInputControlProps(props);
  const isShowMenu = selectProps.menuOnly || showMenu;
  const customError = outlineVariant ? error : false;

  const {
    value: _,
    'data-field-type': _1,
    'data-field-id': _2,
    placeholder: _3,
    ...forInputControl
  } = props;

  const selectRef = useRef(null);

  const { closeOnClearClick } = useCustomSelectMenuProps(
    selectRef,
    selectProps
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Escape' && isShowMenu) {
        e.stopPropagation();
        setShowMenu(false);
      } else if ((e.key === 'Enter' || e.key === 'Tab') && isShowMenu) {
        // These events need to be manually handled, because we can't use the blur event.
        // A blur could mean the user switched from typing to mouse input, and we can't
        // close the menu in that case.
        setShowMenu(false);
      }
    },
    [isShowMenu, setShowMenu]
  );

  // Our normal portal menu is icompatible with the date picker,
  // so it's overridden here in a different way
  const PortaledMenu = (props) => {
    const rect = containerRef.current.getBoundingClientRect();

    const component = (
      <PortaledMenuWrapper
        top={rect.top + rect.height + window.scrollY}
        left={rect.left}
        data-field-type-menu-body="date"
      >
        <Menu {...props} />
      </PortaledMenuWrapper>
    );

    return ReactDOM.createPortal(component, document.body);
  };

  return (
    <MaybeInputControl forInput {...forInputControl}>
      <TextInputWrapper ref={measuredRef}>
        {!selectProps.menuOnly && (
          <DatePickerTextInput
            ref={ref}
            {...selectProps}
            value={formattedValue && formattedValue.label}
            onChange={handleInputChange}
            onBlur={handleDateBlur}
            onClick={() => setShowMenu(true)}
            placeholder={placeholder || label}
            variant={variant}
            error={customError}
            disabled={props.disabled}
            onKeyDown={handleKeyDown}
            {...getDataQAForInput(qaFieldId, qaFieldType)}
            portal={false} // Portaling is handled in this component above, and shouldn't be passed down
          />
        )}
        {isShowMenu && (
          <Select
            ref={selectRef}
            isSearchable={false}
            menuInline={false}
            endAdornment={<IconAdornment icon="calendar" />}
            {...selectProps}
            handleClose={() => setShowMenu(false)}
            handleClickApply={(e) => {
              selectProps.onBlur?.(e, true);
            }}
            isDateTime={isDateTime}
            rightPosition={rightPosition}
            className={!hasInputControl(props) && className}
            components={{
              Control,
              Menu: portal ? PortaledMenu : Menu,
              MenuList,
              ...selectProps.components,
            }}
            value={formattedValue}
            menuOnly
            closeOnClearClick={closeOnClearClick}
            onChange={(opt) => {
              setFormattedValue(getDateOption(opt));
              (closeOnClearClick !== false || opt) && setShowMenu(false);
              setInitValue(opt && opt.label);
              if (props.onChange) {
                changeHandler(opt && opt.date);
              }
            }}
            captureMenuScroll={false}
            portal={false} // Portaling is handled in this component above, and shouldn't be passed down
          />
        )}
      </TextInputWrapper>
    </MaybeInputControl>
  );
});

DateInput.displayName = 'DateInput';

export default DateInput;
