import MultiColumnBuilder from 'components/MultiColumnBuilder';
import useBasicBuilder from 'components/MultiColumnBuilder/useBasicBuilder';
import { useTranslation } from 'react-i18next';
import BaseOption from 'components/MultiColumnBuilder/components/BaseOption';
import { KizenTypography, fontWeights } from 'app/typography';
import { useCallback, useEffect, useMemo } from 'react';
import Icon from 'components/Kizen/Icon';
import { StyledDragItem } from 'components/MultiColumnBuilder/components/BaseOption/styles';
import {
  BuilderWrapper,
  EditableField,
  LeftColumnWrapper,
  PivotDropPlaceholder,
  TextWrapper,
} from '../styles';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { NoItemsWrapper } from 'components/MultiColumnBuilder/components/BaseOption/styles';
import { dateFieldTypeFilter } from 'utility/constants';
import { DATAPOINT_FREQUENCY_REPONSES } from 'components/Wizards/Dashlet/types';
const LEFT_COLUMN_ID = 'available-columns';
const RIGHT_COLUMN_ID = 'active-columns';
const selfDisabledDropZones = [LEFT_COLUMN_ID];

const getFalse = () => false;

export const PivotTableBuilder = ({
  initialLeftColumnItems = [],
  initialRightColumnItems = [],
  deletedItems = [],
  deletedItemsOrder = [],
  onChange,
  onDateFrequencyChange,
  rowDateFrequencies,
  columnDateFrequency,
  lockedFields = [],
  skipSorting = false,
  rightHeaderText,
  leftHeaderText,
  disabledDropZones = [],
  disabledDropZoneText,
  rollBackDrop = false,
  renderRightEmpty,
  onChangeCustomLabel,
  customLabels,
  allowRenaming = false,
  grouped = false,
  showCategoryOnSearch = true,
  testId,
  ref,
  minHeight,
}) => {
  const { t } = useTranslation();

  const defaultRightColumnItems = useMemo(() => {
    if (initialRightColumnItems.length) {
      return initialRightColumnItems;
    }
    return [];
  }, [initialRightColumnItems]);

  const itemsOrdering = useMemo(
    () => (initialLeftColumnItems || []).map(({ id }) => id),
    [initialLeftColumnItems]
  );

  const rightItemsMap = useMemo(() => {
    return defaultRightColumnItems.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [defaultRightColumnItems]);

  const leftItemsMap = useMemo(() => {
    return initialLeftColumnItems.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [initialLeftColumnItems]);

  const filteredInitialLeftColumnItems = useMemo(() => {
    return initialLeftColumnItems
      .filter(({ id }) => {
        return !rightItemsMap[id];
      })
      .sort(({ label: labelA }, { label: labelB }) => {
        return skipSorting ? 0 : labelA.localeCompare(labelB);
      });
  }, [initialLeftColumnItems, rightItemsMap, skipSorting]);

  const combinedRightItems = useMemo(() => {
    const maxLength = Math.max(deletedItemsOrder.length, deletedItems.length);
    const value = Array.from({ length: maxLength }, (_, index) => {
      if (deletedItemsOrder[index] === undefined) {
        return deletedItems[index];
      } else {
        return deletedItemsOrder[index];
      }
    }).filter((item) => item !== undefined);
    return value;
  }, [deletedItems, deletedItemsOrder]);

  const props = useBasicBuilder({
    initialLeftColumnItems: filteredInitialLeftColumnItems,
    initialRightColumnItems: combinedRightItems?.map((item) => {
      const disable = lockedFields.includes(item?.id);
      return {
        ...item,
        restricted: disable,
        disabled: disable,
        isLockedToColumn: disable,
      };
    }),
    leftColumnId: LEFT_COLUMN_ID,
    rightColumnId: RIGHT_COLUMN_ID,
    showCategoryOnSearch,
    selfDisabledDropZones: [...disabledDropZones, ...selfDisabledDropZones],
    itemsOrdering,
    rollBackDrop,
  });

  useEffect(() => {
    const isItemRemoved =
      initialLeftColumnItems.length -
        props?.leftColumnProps?.options?.length !==
      2;
    onChange?.(props.rightColumnItems, isItemRemoved);
  }, [
    onChange,
    props.rightColumnItems,
    initialLeftColumnItems.length,
    props?.leftColumnProps?.options?.length,
  ]);

  const additionalActions = useCallback(
    ({ swapItemColumn, index, element, section }) => {
      const showDelete = section === 'active-columns';
      return !element?.disabled &&
        swapItemColumn &&
        !element.restricted &&
        showDelete ? (
        <div>
          <Icon
            className="DeleteAction"
            icon="delete"
            onClick={() => {
              swapItemColumn(index, section);
            }}
          />
        </div>
      ) : null;
    },
    []
  );

  const dateDropdownOptions = useMemo(
    () => [
      {
        value: DATAPOINT_FREQUENCY_REPONSES.WEEKLY,
        label: t('Weekly'),
      },
      {
        value: DATAPOINT_FREQUENCY_REPONSES.MONTHLY,
        label: t('Monthly'),
      },
      {
        value: DATAPOINT_FREQUENCY_REPONSES.QUARTERLY,
        label: t('Quarterly'),
      },
      {
        value: DATAPOINT_FREQUENCY_REPONSES.YEARLY,
        label: t('Yearly'),
      },
    ],
    [t]
  );

  return (
    <BuilderWrapper minHeight={minHeight} ref={ref}>
      <MultiColumnBuilder
        searchable
        testId={testId}
        {...props}
        weight={fontWeights.regular}
        leftColumnProps={{
          ...props.leftColumnProps,
          isGroupedItem: grouped,
          showCategoryOnSearch,
        }}
        leftHeaderText={leftHeaderText ?? t('Available Columns (Drag to Add)')}
        rightHeaderText={rightHeaderText ?? t('Active Table Columns')}
        searchPlaceholder={t('Find Options')}
        onChangeSearchTerm={props.setOptionsSearchTerm}
        disabledDropZoneText={disabledDropZoneText}
        rollBackDrop={rollBackDrop}
        minHeight={minHeight}
        renderRightEmpty={renderRightEmpty}
        renderLeftEmpty={({ dropZone, id }) => {
          if (dropZone?.sectionId === id) {
            return (
              <PivotDropPlaceholder isChildDragging={false}>
                <StyledDragItem className={`MenuItemWrapper-Child-empty`}>
                  <KizenTypography>{t('Drop to Remove')}</KizenTypography>
                </StyledDragItem>
              </PivotDropPlaceholder>
            );
          }
          return (
            <LeftColumnWrapper>
              <NoItemsWrapper>
                <KizenTypography>{t('No Options Found')}</KizenTypography>
              </NoItemsWrapper>
            </LeftColumnWrapper>
          );
        }}
        renderOption={({ element, section, ...providedProps }) => {
          const isDateField = dateFieldTypeFilter({
            fieldType: leftItemsMap[element.id]?.fieldType,
          });

          const isSecondRow =
            combinedRightItems.findIndex(
              ({ id } = {}) => leftItemsMap[element.id]?.fieldId === id
            ) === 1 ||
            deletedItems.find(
              ({ id } = {}, index) => id === element?.id && index === 1
            );

          const isDeletedField = deletedItems.find(
            ({ id } = {}) => id === element?.id
          );

          const showDateField = section === 'active-columns' && isDateField;
          let dateFrequency = '';
          if (columnDateFrequency) {
            dateFrequency = columnDateFrequency.value
              ? columnDateFrequency.value
              : columnDateFrequency;
          }
          if (rowDateFrequencies) {
            dateFrequency = rowDateFrequencies[element?.id];
          }
          const subRow = section === 'active-columns' && isSecondRow;
          return (
            // eslint-disable-next-line react/no-unknown-property
            <div {...element} section={section}>
              <BaseOption
                data-qa={element?.label}
                iconVisible={getFalse}
                iconEditable={getFalse}
                colorEditable={getFalse}
                element={element}
                section={section}
                additionalActions={additionalActions}
                {...providedProps}
                showDropdown={showDateField}
                dropdownOptions={dateDropdownOptions}
                onDropdownChange={onDateFrequencyChange}
                dropdownValue={dateFrequency}
                defaultDropdownValue={{ id: element?.id, value: 'month' }}
                subRow={subRow}
                deletedField={isDeletedField}
              >
                {({ element, section }) => {
                  const isDateField = dateFieldTypeFilter({
                    fieldType: leftItemsMap[element.id]?.fieldType,
                  });
                  const canEdit =
                    section === 'active-columns' && allowRenaming && !subRow;
                  return (
                    <TextWrapper showDropdown={isDateField}>
                      {canEdit && !isDeletedField ? (
                        <EditableField
                          value={
                            customLabels?.[element.id] !== undefined
                              ? customLabels?.[element.id]
                              : element.label
                          }
                          onChange={(v) => onChangeCustomLabel(element.id, v)}
                          placeholder={t('Enter Display Name')}
                          onBlur={(e) => {
                            if (!e.target.value) {
                              onChangeCustomLabel(element.id, null);
                            }
                          }}
                        />
                      ) : (
                        <TextEllipsisWithTooltip>
                          {element.label}
                        </TextEllipsisWithTooltip>
                      )}
                    </TextWrapper>
                  );
                }}
              </BaseOption>
            </div>
          );
        }}
      />
    </BuilderWrapper>
  );
};
