import { DROPDOWN_SHOULD_LOAD } from 'utility/constants';
import { invalidate } from 'queries/invalidate';
import { get as _get, set as _set } from 'lodash';
import AxiosService from './AxiosService';
import {
  FORCE_ALL_RECORDS_SIZE,
  snakeToCamelCaseKeys,
  camelToSnakeCaseKeys,
  namedToOption,
  snakeToCamelCase,
} from './helpers';

export const AUTOMATION_STATE = {
  PAUSED: 'paused',
  ACTIVE: 'active',
  COMPLETED: 'completed',
  FAILED: 'failed',
  PAUSED_BY_AUTOMATION: 'paused_by_automation',
  CANCELLED: 'cancelled',
  PAUSED_BY_FAILURE: 'paused_by_failure',
};

export const AUTOMATION_ACTION = {
  CANCEL: 'cancel',
  PAUSE: 'pause',
  RESUME: 'resume',
  SKIP_STEP_AND_RESUME: 'skip_and_resume',
  DOWNLOAD: 'download',
};

export const EMAIL_TYPES = [
  'send_email',
  'notify_member_via_email',
  'send_related_contact_email',
];

const EMAIL_PATHS = {
  action_send_email: '',
  action_notify_member_via_email: '',
  action_send_related_contact_email: '.email',
};

const nestedPath = (actionType) => `${actionType}${EMAIL_PATHS[actionType]}`;
const nestedCamelPath = (actionType) =>
  `${snakeToCamelCase(actionType)}${EMAIL_PATHS[actionType]}`;

// which options can be started
export const automationsToOptions = (automations) =>
  automations.map(namedToOption);

export const getStartableAutomations = (automations) =>
  automations && automations !== DROPDOWN_SHOULD_LOAD
    ? automationsToOptions(automations)
    : automations;

export const getAutomationObjectName = ({ customObject }) => {
  return (customObject && customObject.objectName) || '';
};

const prepareAutomationForUI = (data) => {
  const getter = (el, actionType) =>
    _get(el, `${nestedPath(actionType)}.craft_json`, '');

  const setter = (el, actionType, val) =>
    _set(el, `${nestedCamelPath(actionType)}.craftJson`, val);
  return {
    ...snakeToCamelCaseKeys(data),
    // craft_json - should not be converted with snakeToCamelCaseKeys or camelToSnakeCaseKeys;
    // AVOID any changes for craft_json!!!
    steps: data.steps.map((el) => {
      const camelified = snakeToCamelCaseKeys(el);
      if (EMAIL_TYPES.includes(el.step_type)) {
        const actionType = `action_${el.step_type}`;
        const craftJson = getter(el, actionType);
        setter(camelified, actionType, craftJson);
      }
      return camelified;
    }),
  };
};

class Automation2Service {
  constructor() {
    if (!Automation2Service.instance) {
      Automation2Service.instance = this;
    }
    return Automation2Service.instance;
  }

  // Same endpoint as typeahead, but less specialized and paginated
  search = async ({
    search,
    page = {},
    ordering,
    criteria,
    groupId,
    folderId,
    includeSubfolders = true,
    includeEmptyFolders = true,
  }) => {
    const params = {
      search,
      ordering,
      group_id: groupId,
      page: page.number || 1,
      page_size: page.size || 50,
      folder_id: folderId,
      include_subfolders: includeSubfolders,
      include_empty_folders: includeEmptyFolders,
    };

    const { data } = await AxiosService.post(
      '/automation2/automations/search',
      criteria,
      {
        params,
      }
    );

    return {
      ...data,
      currentFolderId: data.current_folder_id,
      subfolders: snakeToCamelCaseKeys(data.subfolders),
      results: snakeToCamelCaseKeys(data.results),
    };
  };

  create = async (automation) => {
    const { data } = await AxiosService.post(
      '/automation2/automations',
      camelToSnakeCaseKeys(automation)
    );
    return snakeToCamelCaseKeys(data);
  };

  getById = async (id) => {
    const { data } = await AxiosService.get(`/automation2/automations/${id}`);
    return prepareAutomationForUI(data);
  };

  update = async ({ id, ...rest }) => {
    const { data } = await AxiosService.patch(
      `/automation2/automations/${id}`,
      camelToSnakeCaseKeys(rest)
    );
    return prepareAutomationForUI(data);
  };

  updateFull = async ({ id, ...rest }) => {
    const payload = {
      ...camelToSnakeCaseKeys(rest),
      // craft_json - should not be converted with snakeToCamelCaseKeys or camelToSnakeCaseKeys;
      // No craft_json in email anymore
      steps: rest.steps.map((el) => camelToSnakeCaseKeys(el)),
    };

    const { data } = await AxiosService.put(
      `/automation2/automations/${id}`,
      payload
    );
    return prepareAutomationForUI(data);
  };

  delete = async (id) => {
    const { data } = await AxiosService.delete(
      `/automation2/automations/${id}`
    );
    return snakeToCamelCaseKeys(data);
  };

  getGroups = async (options) => {
    const { data } = await AxiosService.get(
      '/automation2/automation-group',
      options
    );
    return data.results;
  };

  duplicate = async (id) => {
    const { data } = await AxiosService.post(
      `/automation2/automations/${id}/duplicate`
    );

    return prepareAutomationForUI(data);
  };

  playAutomation = async ({ automationId }) => {
    const { data } = await AxiosService.post(
      `/automation2/automation-execution/${automationId}/play`
    );
    invalidate.TIMELINE.ALL();
    return snakeToCamelCaseKeys(data);
  };

  pauseAutomation = async ({ automationId }) => {
    const { data } = await AxiosService.post(
      `/automation2/automation-execution/${automationId}/pause`
    );
    invalidate.TIMELINE.ALL();
    return snakeToCamelCaseKeys(data);
  };

  cancelAutomation = async ({ automationId }) => {
    const { data } = await AxiosService.post(
      `/automation2/automation-execution/${automationId}/cancel`
    );

    invalidate.TIMELINE.ALL();
    return snakeToCamelCaseKeys(data);
  };

  skipStepAndResumeAutomation = async (automationId, body, options) => {
    const { data } = await AxiosService.post(
      `/automation2/automation-execution/${automationId}/skip-and-resume`,
      body,
      options
    );
    return data;
  };

  downloadAutomationExecutionDetails = async (automationId, options) => {
    const { data } = await AxiosService.get(
      `/automation2/automation-execution/${automationId}/export-audit`,
      options
    );
    return data;
  };

  createGroup = async (body, options) => {
    const { data } = await AxiosService.post(
      '/automation2/automation-group',
      body,
      options
    );

    return snakeToCamelCaseKeys(data);
  };

  updateGroup = async (body, id, options) => {
    const { data } = await AxiosService.put(
      `/automation2/automation-group/${id}`,
      body,
      options
    );
    return snakeToCamelCaseKeys(data);
  };

  deleteGroup = async (id) => {
    await AxiosService.delete(`/automation2/automation-group/${id}`);
  };

  // objectIds is either clientId or recordId
  start = async ({ automationId, ...objectIds }, options) => {
    const { data } = await AxiosService.post(
      `/automation2/automations/${automationId}/start`,
      camelToSnakeCaseKeys({ ...objectIds }),
      options
    );
    invalidate.TIMELINE.ALL();
    return snakeToCamelCaseKeys(data);
  };

  getAutomationsByObjectId = async (
    { objectId, active },
    toOptions = false,
    skipErrorBoundary = false
  ) => {
    const { data } = await AxiosService.get('/automation2/automations', {
      params: {
        custom_object_id: objectId || null,
        page_size: FORCE_ALL_RECORDS_SIZE, // Despite the endpoint being paginated, we'd like to fetch all automations at once
        active,
      },
      skipErrorBoundary,
    });
    if (toOptions) {
      return snakeToCamelCaseKeys(data.results).map(namedToOption);
    }
    return snakeToCamelCaseKeys(data.results);
  };

  getAutomationsTypeahead = async (params, options) => {
    const { data } = await AxiosService.get('/automation2/automations', {
      params,
      ...options,
    });
    return snakeToCamelCaseKeys(data);
  };

  getAutomationFullList = async (params) => {
    const getData = async (getDataParams) => {
      const data = await this.getAutomationsTypeahead(getDataParams);
      return data.next
        ? [
            ...data.results,
            ...(await getData({
              ...getDataParams,
              page: getDataParams.page + 1,
            })),
          ]
        : data.results;
    };

    return await getData({ ...params, page: 1 });
  };

  getAutomationExecutions = async ({ entityId, ...params }) => {
    const {
      data: { results, count },
    } = await AxiosService.get(`records/${entityId}/automation-executions`, {
      params: camelToSnakeCaseKeys(params),
    });
    return { results: snakeToCamelCaseKeys(results), count };
  };

  getAutomationHistory = async (automationId, params) => {
    const { data } = await AxiosService.get(
      `/automation2/automations/${automationId}/modification-history`,
      {
        params: camelToSnakeCaseKeys(params),
      }
    );

    return snakeToCamelCaseKeys(data);
  };
}

const instance = new Automation2Service();
Object.freeze(instance);

export default instance;
