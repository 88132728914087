import styled from '@emotion/styled';
import css from '@emotion/css';
import { colorsButton, grayScale } from 'app/colors';
import { gutters, layers } from 'app/spacing';
import { linkCss } from 'app/typography';
import DragItem from 'components/DragAndDropLayout/DragItem';
import ColorPickerInput from 'components/Kizen/ColorPickerInput';
import Icon from 'components/Kizen/Icon';
import { DragItemWrapper } from '../OptionParent/styles';
import Select from 'components/Inputs/Select';
import Switch from 'components/Kizen/Switch';

export const StyledDragItem = styled(DragItem)`
  position: relative;
  z-index: ${layers.content(0, 4)};
  ${({ subRow }) =>
    subRow
      ? css`
          margin-left: 20px;
          width: calc(100% - 20px);
        `
      : ''}
`;

export const NoItemsWrapper = styled.div`
  margin-left: 20px;
`;

export const StyledIcon = styled(Icon)`
  color: ${grayScale.dark};
  width: 16px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &&& {
    cursor: unset;
  }

  && svg {
    /* DragItem (the parent) has some styling that can't be overridden without !important */
    color: ${grayScale.dark} !important;
  }

  &&&&:hover svg {
    color: ${grayScale.dark};
  }

  && svg {
    height: 12px;
  }
`;

export const StyledColorPicker = styled(ColorPickerInput)`
  & .styled-swatch {
    width: 20px;
    height: 20px;
  }
`;

export const DropdownWrapper = styled.div`
  width: 100%;
  padding-right: ${gutters.spacing(3)}px;
`;

export const SwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

export const StyledSwitch = styled(Switch)`
  ${({ rightAlign }) =>
    rightAlign
      ? css`
          margin-right: 0px;
        `
      : ''}
`;

export const StyledSelect = styled(Select)`
  width: 170px;
`;

export const WidthContainer = styled.div`
  margin-left: auto;
  display: flex;
  column-gap: ${gutters.spacing(2)}px;
  align-items: center;

  & i {
    cursor: pointer;
  }

  & i:not(:last-child) {
    margin-right: 5px;
  }

  & .DeleteAction svg {
    width: 14px;
  }
`;

export const ChildrenWrapper = styled.div`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: ${gutters.spacing(2)}px;
  width: 100%;
  z-index: 0;
  padding-left: 20px;
`;

export const DropPlaceholder = styled(DragItemWrapper)`
  & > div > div {
    border: 1px dashed ${colorsButton.blue.default};
    padding-top: 14px;
    padding-bottom: 14px;
    ${({ over }) =>
      over
        ? css`
            border-style: solid;
          `
        : ''}
  }

  & p {
    ${linkCss()}
  }

  & i {
    display: none;
  }
`;
