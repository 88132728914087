import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colorsText, dropdownColors, grayScale } from 'app/colors';
import { textCss, truncateCss } from 'app/typography';

export default styled.li`
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  ${truncateCss};
  ${textCss()}
  padding-left: 12px;
  padding-right: 12px;
  ${({ focused }) =>
    focused &&
    css`
      background-color: ${dropdownColors.hover};
    `}
  ${({ selected }) =>
    selected
      ? css`
          color: ${colorsText.medium};
        `
      : css`
          &:hover {
            background-color: ${dropdownColors.hover};
          }
        `}
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: auto;
      color: ${grayScale.mediumDark};
      &:hover {
        background: none;
      }
    `}
`;
