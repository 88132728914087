import React, { forwardRef, useState, useRef } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useEditor, useNode } from '@craftjs/core';
import { colorsPrimary } from '@kizen/page-builder/internal/app/colors';
import {
  dashedBorderCss,
  gutters,
  layers,
} from '@kizen/page-builder/internal/app/spacing';
import IconButton from '@kizen/page-builder/internal/components/Kizen/IconButton';
import Icon, {
  IconSizing,
} from '@kizen/page-builder/internal/components/Kizen/Icon';
import Tooltip from '@kizen/page-builder/internal/components/Kizen/Tooltip';
import { grayScale } from '@kizen/page-builder/internal/app/colors';
import KizenTypography from '@kizen/page-builder/internal/app/kizentypo';
import ConfirmDeletionModal from '@kizen/page-builder/internal/components/Modals/presets/ConfirmDeletion';
import BasicModal from '@kizen/page-builder/internal/components/Modals/presets/BasicModal';
import { deleteNode, duplicateNode } from './actions';
import { useBuilderContext, useSetActiveNode } from '../BuilderContext';
import { canNodeBeDeleted, getChildren, isNodeSubmitButton } from '../utils';
import { DEFAULT_MAX_WIDTH } from '../settings';

const alignSelf = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

export const CONTROL_HEIGHT = gutters.spacing(3);
export const pixelValue = (value, fallback = undefined) => {
  const numValue = Number(value);
  return isNaN(numValue) ? fallback : `${numValue}px`;
};

const tooltipPopperConfig = {
  modifiers: {
    preventOverflow: { enabled: false },
  },
};

const DASH_LENGTHS = {
  Section: 5,
  Row: 4,
  Content: 3,
};

const Container = styled.div`
  position: relative;
  align-content: flex-start;
`;

export const ControlsWrapper = styled.div`
  display: flex;
  ${({ width = '100%' }) => `width: ${width};`}
  ${(props) => css`
    justify-content: ${alignSelf[props.alignment || 'center']};
    & > div {
      width: 100%;
      ${!props.fullWidth
        ? `max-width: ${props.maxWidth ? props.maxWidth : DEFAULT_MAX_WIDTH}px;`
        : ''}

      margin-top: ${pixelValue(props.containerMarginTop)};
      margin-right: ${pixelValue(props.containerMarginRight)};
      margin-bottom: ${pixelValue(props.containerMarginBottom)};
      margin-left: ${pixelValue(props.containerMarginLeft)};
    }
  `};
`;

const ControlsContainer = styled.div`
  z-index: ${({ modalLayer }) => layers.modals(modalLayer, 10)};
  position: absolute;
  top: 0;
  left: 0;
`;

export const Outline = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    ${({ show, gridLines, dashLength, color }) =>
      (gridLines || show) &&
      dashedBorderCss({ color, dashLength: !show && dashLength })}
  }
`;

const RightControlsContainer = styled(ControlsContainer)`
  left: unset;
  right: 0;
`;

const RightColumnControls = styled.div`
  display: flex;
  height: 15px;
  padding: 0 2px;
`;

const AddRemoveColumnControlsContainer = styled(RightColumnControls)`
  button {
    width: 16px;

    i:first-child {
      margin-right: 1px;
    }
  }
  background-color: ${({ removeColumn }) =>
    removeColumn ? colorsPrimary.green.dark : colorsPrimary.orange.dark};
`;

const CarouselControlsContainer = styled(RightColumnControls)`
  width: 38px;
  justify-content: space-around;
  background-color: ${colorsPrimary.green.dark};
`;

export const AddRemoveColumnControl = ({ removeColumn = false, onClick }) => {
  const { t } = useTranslation();
  const tooltipLabel = removeColumn ? t('Remove Column') : t('Add Column');

  return (
    <AddRemoveColumnControlsContainer removeColumn={removeColumn}>
      <OverlayTrigger
        popperConfig={tooltipPopperConfig}
        overlay={<Tooltip label={tooltipLabel} />}
      >
        <IconButton
          title={tooltipLabel}
          sizing="dense"
          color={grayScale.white}
          onClick={onClick}
        >
          <IconSizing size="5px">
            <Icon icon={removeColumn ? 'minus' : 'plus'} />
          </IconSizing>
          <IconSizing size="9px">
            <Icon icon="table-columns" color={grayScale.white} />
          </IconSizing>
        </IconButton>
      </OverlayTrigger>
    </AddRemoveColumnControlsContainer>
  );
};

export const CarouselControl = ({
  isActive,
  onPreviousClick,
  onNextClick,
  onPlayClick,
}) => {
  return (
    <CarouselControlsContainer>
      <IconButton
        sizing="dense"
        color={grayScale.white}
        onClick={onPreviousClick}
      >
        <IconSizing size="9px">
          <Icon icon="chevron-left" />
        </IconSizing>
      </IconButton>
      <IconButton sizing="dense" color={grayScale.white} onClick={onPlayClick}>
        <IconSizing size="9px">
          <Icon icon={isActive ? 'pause' : 'play'} />
        </IconSizing>
      </IconButton>
      <IconButton sizing="dense" color={grayScale.white} onClick={onNextClick}>
        <IconSizing size="9px">
          <Icon icon="chevron-right" />
        </IconSizing>
      </IconButton>
    </CarouselControlsContainer>
  );
};

const Controls = styled.div`
  display: flex;
  z-index: ${({ modalLayer }) => layers.modals(modalLayer)};

  > div {
    height: ${CONTROL_HEIGHT}px;
    background-color: ${({ color }) => color};
    display: flex;
  }

  > div:first-child {
    justify-content: center;
    align-items: center;
    min-width: 60px;
    padding: 0 ${gutters.spacing(1)}px;
    margin-right: 1px;
    ${({ draggable }) =>
      draggable &&
      css`
        cursor: move;
      `}
  }
  > div:last-child {
    align-items: stretch;
    button {
      width: 16px;
    }
  }
`;

const ControlLabel = styled(KizenTypography)`
  && {
    color: ${({ color }) => color};
    font-family: unset;
  }
`;

const getUndeletableChildren = (isContactForm, id, query) => {
  const [childIds] = getChildren(id, query);
  return childIds
    .filter((i) => !canNodeBeDeleted(query.node(i).get(), isContactForm))
    .map((i) => query.node(i).get());
};

const Control = forwardRef(
  (
    {
      label,
      show,
      color,
      onSetActive,
      canDelete = true,
      canClone = true,
      mayHaveChildren = true,
      draggable = true,
      containerBorderWidth = 0,
      rightControl = null,
      customControls = null,
      width,
      children,
      ...other
    },
    ref
  ) => {
    const { t } = useTranslation();
    const {
      fullWidth,
      activeNode,
      gridLinesActive,
      modalLayer,
      isContactForm,
      onNodeDelete,
      onNodeDuplicate,
      clearContentSettingsTray,
    } = useBuilderContext();
    const setActiveNode = useSetActiveNode();
    const [controlHovered, setControlHovered] = useState(false);
    const controlHoveredRef = useRef(false);
    const [showDeletionModal, setShowDeletionModal] = useState(false);
    const [showBasicModal, setShowBasicModal] = useState(false);
    const [cannotDeleteMessage, setCannotDeleteMessage] = useState('');
    const { query, actions, hoveredId } = useEditor((state) => ({
      hoveredId: [...state.events.hovered.values()][0],
    }));
    const {
      id,
      parent,
      isDeletable,
      connectors: { drag },
    } = useNode((node) => ({
      id: node.id,
      parent: node.data.parent,
      isDeletable: canNodeBeDeleted(node, isContactForm),
    }));

    // When the controls are hovered, craft will register the hoveredId as the parent node. We track that extra
    // state to override the `show` prop. We also keep track of the prior renders controlHovered state to avoid
    // a flicker of the controls when the hoveredId is not current when hovering over the controls and then back
    // to the node.
    const isControlActive =
      (controlHoveredRef.current && parent === hoveredId) ||
      show ||
      controlHovered;
    controlHoveredRef.current = controlHovered;

    const closeBasicModal = (ev) => {
      ev?.stopPropagation();
      setShowBasicModal(false);
    };

    const handleDeleteClick = (ev) => {
      ev?.stopPropagation(); // ensure this click does not change the tray by selecting the parent element
      const undeletableChildren = getUndeletableChildren(
        isContactForm,
        id,
        query
      );
      if (undeletableChildren.length) {
        const nodeDisplayName = isNodeSubmitButton(undeletableChildren[0])
          ? 'The submit button'
          : undeletableChildren[0]?.data?.props.field?.displayName;
        setCannotDeleteMessage(
          `${nodeDisplayName} is a required field. Please move it outside of this component and retry deletion.`
        );
        setShowBasicModal(true);
      } else {
        setShowDeletionModal(true);
      }
    };

    const handleSetActiveNode = (event) => {
      event.stopPropagation();
      setActiveNode();
      onSetActive?.();
    };

    return (
      <ControlsWrapper fullWidth={fullWidth} width={width} {...other} ref={ref}>
        <Container>
          <span onClick={handleSetActiveNode}>
            <Outline
              gridLines={gridLinesActive}
              show={isControlActive}
              color={color}
              dashLength={DASH_LENGTHS[label]}
            >
              {children}
            </Outline>
          </span>
          {isControlActive && (
            <>
              <ControlsContainer
                modalLayer={modalLayer}
                onMouseEnter={() => setControlHovered(true)}
                onMouseLeave={() => setControlHovered(false)}
              >
                <Controls
                  draggable={draggable}
                  color={color}
                  modalLayer={modalLayer}
                >
                  <div
                    ref={draggable ? drag : null}
                    onClick={handleSetActiveNode}
                  >
                    <ControlLabel
                      as="span"
                      size="small"
                      weight="bold"
                      color={grayScale.white}
                    >
                      {label}
                    </ControlLabel>
                  </div>
                  <div>
                    {canDelete && isDeletable && (
                      <OverlayTrigger
                        popperConfig={tooltipPopperConfig}
                        overlay={<Tooltip label={t('Delete')} />}
                      >
                        <IconButton
                          sizing="dense"
                          title={t('Delete')}
                          color={grayScale.white}
                          onClick={handleDeleteClick}
                        >
                          <IconSizing size="9px">
                            <Icon icon="delete" />
                          </IconSizing>
                        </IconButton>
                      </OverlayTrigger>
                    )}
                    {canClone && (
                      <OverlayTrigger
                        popperConfig={tooltipPopperConfig}
                        overlay={<Tooltip label={t('Clone')} />}
                      >
                        <IconButton
                          sizing="dense"
                          title={t('Clone')}
                          color={grayScale.white}
                          onClick={() => {
                            duplicateNode(id, {
                              isContactForm,
                              query,
                              actions,
                              contextDuplicateNode: onNodeDuplicate,
                            });
                          }}
                        >
                          <IconSizing size="9px">
                            <Icon icon="copy" />
                          </IconSizing>
                        </IconButton>
                      </OverlayTrigger>
                    )}
                    {customControls}
                  </div>
                </Controls>
              </ControlsContainer>
              {rightControl && (
                <RightControlsContainer>{rightControl}</RightControlsContainer>
              )}
            </>
          )}
        </Container>
        {showDeletionModal && (
          <ConfirmDeletionModal
            show={showDeletionModal}
            onHide={() => setShowDeletionModal(false)}
            onConfirm={(ev) => {
              ev.stopPropagation();
              if (id === activeNode?.id) {
                clearContentSettingsTray();
              }
              deleteNode(id, {
                actions,
                query,
                contextDeleteNode: onNodeDelete,
              });
            }}
          >
            <KizenTypography>
              This will permanently delete the {label}
              {mayHaveChildren ? ' and its contents' : ''}.
            </KizenTypography>
          </ConfirmDeletionModal>
        )}
        {showBasicModal && (
          <BasicModal
            show={showBasicModal}
            onHide={closeBasicModal}
            onConfirm={closeBasicModal}
            buttonText={t('Close')}
            heading={t('Cannot Delete Component')}
            typeOfContent="text"
            actionBtnColor="blue"
            leftBtn={null}
          >
            <KizenTypography>{cannotDeleteMessage}</KizenTypography>
          </BasicModal>
        )}
      </ControlsWrapper>
    );
  }
);

Control.displayName = 'Control';

Control.propTypes = {
  label: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  mayHaveChildren: PropTypes.bool,
  draggable: PropTypes.bool,
  canDelete: PropTypes.bool,
  canClone: PropTypes.bool,
  customControls: PropTypes.elementType,
  rightControl: PropTypes.elementType,
};

export default Control;
