import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageSizing } from '__components/Layout/PageContentWidth';
import { PrimaryObjectUploadContext } from '../../context';
import { StyledLoader } from '__pages/SmartConnectors/SmartConnector/steps/styles';
import { IconWithTooltip } from '__pages/Common/components/LinkWithIconTooltip/IconWithTooltip';
import {
  StyledAddRuleButton,
  StyledButtonsInputControl,
  StyledButtonsRow,
  StyledCategorySelect,
  StyledCategorySelectWrapper,
  StyledMappingCard,
  StyledMappingRules,
  StyledMappingStepHeader,
} from './styles';
import Subsection from '__components/Modals/Common/Subsection';
import ButtonGroup from '__components/Inputs/ButtonGroup';
import { ButtonGroupImageButton } from '__components/Inputs/ButtonGroup/Button';
import {
  MAPPING_VIEW_OPTIONS,
  MappingViewOption,
  getConflictResolutionByFieldType,
  getMappingViewOptions,
} from './helpers';
import { MappingRule } from '__pages/SmartConnectors/types';
import { MappingRow } from './MappingRow';
import {
  CONFLICT_RESOLUTIONS,
  PRIMARY_OBJECT_UPLOAD_STEPS,
} from '__pages/SmartConnectors/constants';
import { FieldRow } from './FieldRow';
import { Titles } from './Titles';
import {
  OnChangeMappingRuleHandler,
  OnDeleteMappingRuleHandler,
} from './useMappingSettings';
import { RulesByFieldId, useCustomObjectFields } from './useCustomObjectFields';

export const MappingStep = () => {
  const { t } = useTranslation();
  const {
    isNew,
    smartConnector,
    customObject,
    loadId,
    stepData,
    setStepData,
    setErrors,
    setIsValid,
    loading,
    metaData,
    errors,
  } = useContext(PrimaryObjectUploadContext);

  const isStepValid =
    !isNew || Boolean(stepData.loads?.find(({ id }) => id === loadId));

  useEffect(() => {
    setIsValid(true);
  }, [setIsValid, isStepValid]);

  useEffect(() => {
    if (
      !smartConnector.flow.loads?.find(({ id }) => id === loadId)
        ?.field_mapping_rules?.length
    ) {
      setStepData((prev) => ({
        ...prev,
        loads: (prev.loads || []).map((load) => {
          if (load.id === loadId) {
            const fieldForCreate = customObject?.fields?.find(
              ({ name }) => name === 'name' || name === 'email'
            );

            const conflictResolutionMeta = getConflictResolutionByFieldType(
              metaData,
              fieldForCreate
            );

            if (fieldForCreate?.id) {
              return {
                ...load,
                field_mapping_rules: [
                  {
                    field: fieldForCreate.id,
                    variables: [],
                    conflict_resolution: conflictResolutionMeta.default,
                    can_create_field_options: false,
                    display_order: 0,
                  },
                ],
              };
            }
          }
          return load;
        }),
      }));
    }
  }, [smartConnector, setStepData, customObject, metaData, loadId]);

  const [mappingRules, rulesByFieldId, loadIndex] = useMemo(() => {
    const loadIndex =
      stepData.loads?.findIndex(({ id }) => id === loadId) ?? -1;

    const load = stepData.loads?.[loadIndex];

    const mappingRules = load?.field_mapping_rules || [];
    const rulesByFieldId = mappingRules.reduce((acc, rule, i) => {
      if (rule.field) {
        acc[rule.field] = {
          index: i,
          rule,
        };
      }
      return acc;
    }, {} as RulesByFieldId);
    return [mappingRules, rulesByFieldId, loadIndex];
  }, [stepData.loads, loadId]);

  const {
    fieldsById,
    fieldsByCategory,
    fieldForCreate,
    fieldOptions,
    categoryOptions,
  } = useCustomObjectFields(customObject!, rulesByFieldId);

  const [view, setView] = useState<MappingViewOption>(
    MAPPING_VIEW_OPTIONS.mapped_fields
  );
  const onChangeView = ({ value }: { value: MappingViewOption }) => {
    setView(value);
  };

  const hasErrors = useMemo(() => {
    const mappingRuleErrors = errors.loads?.[loadIndex]?.field_mapping_rules;
    return Boolean(
      mappingRuleErrors?.length &&
        mappingRuleErrors.some((error) => Object.keys(error || {}).length)
    );
  }, [errors.loads, loadIndex]);

  useEffect(() => {
    if (hasErrors) {
      setView(MAPPING_VIEW_OPTIONS.mapped_fields);
    }
  }, [hasErrors]);

  const [category, setCategory] = useState<string>(categoryOptions[0]?.value);
  const onChangeCategpry = ({ value }: { value: string }) => {
    setCategory(value);
  };

  const onChangeMappingRule: OnChangeMappingRuleHandler = ({
    field,
    prop,
    value,
  }) => {
    setStepData((prev) => ({
      ...prev,
      loads: (prev.loads || []).map((load) => {
        if (load.id === loadId) {
          return {
            ...load,
            field_mapping_rules: (load.field_mapping_rules || []).map(
              (rule) => {
                if (rule.field === field) {
                  const patch: Partial<MappingRule> = { [prop]: value };
                  if (prop === 'field') {
                    patch.variables = [];
                    patch.can_create_field_options = false;
                  }
                  if (prop === 'variables' && rule.field) {
                    const field = fieldsById[rule.field];
                    patch.conflict_resolution =
                      Array.isArray(value) && value.length > 1
                        ? CONFLICT_RESOLUTIONS.only_add_options
                        : getConflictResolutionByFieldType(metaData, field)
                            .default;
                  }
                  return { ...rule, ...patch };
                }
                return rule;
              }
            ),
          };
        }
        return load;
      }),
    }));

    return value;
  };

  const onDeleteMappingRule: OnDeleteMappingRuleHandler = (field, index) => {
    let loadIndex = -1;
    setStepData((prev) => ({
      ...prev,
      loads: (prev.loads || []).map((load, i) => {
        if (load.id === loadId) {
          loadIndex = i;
          return {
            ...load,
            field_mapping_rules: (load.field_mapping_rules || []).filter(
              (rule) => rule.field !== field
            ),
          };
        }
        return load;
      }),
    }));
    setErrors((prev) => ({
      ...prev,
      loads: (prev.loads || []).map((load, i) => {
        if (i === loadIndex) {
          return {
            ...load,
            field_mapping_rules: (load.field_mapping_rules || []).filter(
              (_, i) => index !== i
            ),
          };
        }
        return load;
      }),
    }));
  };

  const onAddMappingRule = (init?: Partial<MappingRule>) => {
    setStepData((prev) => ({
      ...prev,
      loads: (prev.loads || []).map((load) => {
        if (load.id === loadId) {
          return {
            ...load,
            field_mapping_rules: [
              ...(load.field_mapping_rules || []),
              {
                field: `new_${Date.now()}`,
                variables: [],
                conflict_resolution: CONFLICT_RESOLUTIONS.only_update_blank,
                can_create_field_options: false,
                display_order: 0,
                ...init,
              },
            ],
          };
        }
        return load;
      }),
    }));
  };

  return (
    <PageSizing>
      <StyledMappingCard
        data-qa={`${PRIMARY_OBJECT_UPLOAD_STEPS.mapping}-step`}
      >
        <StyledMappingStepHeader type="subheader">
          {t('Set Up Variable to Field Mapping')}
        </StyledMappingStepHeader>
        <StyledLoader loading={loading}>
          <Subsection
            title={
              <>
                {t('Required Field(s) for Creating a New Record')}{' '}
                <IconWithTooltip
                  label={t(
                    'Since at least one matching setting in the previous step can potentially create a new record in this object, this field is required to be mapped to a variable. This mapping will only execute on new record creation, and will not otherwise update the unique identifier on existing records (this can be done in the next section).'
                  )}
                />
              </>
            }
          >
            <StyledMappingRules>
              <Titles />
              {mappingRules?.length ? (
                <FieldRow
                  index={0}
                  field={fieldForCreate}
                  rule={mappingRules[0]}
                  isCreateField
                  onChange={onChangeMappingRule}
                  onAdd={onAddMappingRule}
                />
              ) : null}
            </StyledMappingRules>
          </Subsection>
          <Subsection title={t('Field(s) for Updating')}>
            <StyledButtonsRow>
              <StyledButtonsInputControl
                label={t('Choose View')}
                margin
                fullWidth
              >
                <ButtonGroup
                  value={view}
                  options={getMappingViewOptions(t)}
                  onChange={onChangeView}
                  columns={2}
                  mode="wizard"
                  button={<ButtonGroupImageButton />}
                  gutter={'20px'}
                />
              </StyledButtonsInputControl>
              <StyledCategorySelectWrapper>
                {view === MAPPING_VIEW_OPTIONS.fields_by_category ? (
                  <StyledCategorySelect
                    label={t('Choose Category')}
                    value={category}
                    onChange={onChangeCategpry}
                    options={categoryOptions}
                  />
                ) : null}
              </StyledCategorySelectWrapper>
            </StyledButtonsRow>
            <StyledMappingRules>
              {view === MAPPING_VIEW_OPTIONS.fields_by_category ? (
                <>
                  <Titles forUpdate />
                  {fieldsByCategory[category]?.map((field) => {
                    return (
                      <FieldRow
                        index={rulesByFieldId[field.id]?.index}
                        key={field.id}
                        field={field}
                        rule={rulesByFieldId[field.id]?.rule || {}}
                        onChange={onChangeMappingRule}
                        onAdd={onAddMappingRule}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  {mappingRules.slice(1).map((rule, i) => {
                    return (
                      <MappingRow
                        index={i + 1}
                        key={rule.field}
                        field={fieldsById[rule.field || '']}
                        rule={rule}
                        onDelete={onDeleteMappingRule}
                        onChange={onChangeMappingRule}
                        fieldOptions={fieldOptions}
                      />
                    );
                  })}
                  <StyledAddRuleButton
                    variant="text"
                    onClick={() => onAddMappingRule()}
                  >
                    {t('+ Add Mapping Rule')}
                  </StyledAddRuleButton>
                </>
              )}
            </StyledMappingRules>
          </Subsection>
        </StyledLoader>
      </StyledMappingCard>
    </PageSizing>
  );
};
