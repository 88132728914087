import { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { BasicRichTextEditor } from 'components/WYSIWYG';
import { TextSpan } from 'app/typography';
import {
  ConnectEmailAlert,
  StyledSelect,
  StyledInput,
  StyledLabel,
  CenteredLoader,
} from './styles';
import { useExternalAccountsCC } from 'queries/models/teamMember';

const EMPTY_ARRAY = [];
const ERROR_STATE_STATUSES = [1, 4];

const accountToOption = (acct) => ({
  value: acct.id,
  label: acct.teamMember?.name
    ? `${acct.teamMember.name} (${acct.email})`
    : acct.email,
});

export const EmailTemplater = forwardRef(
  (
    {
      inbox = null,
      subject = '',
      body = '',
      onChange,
      enableAI,
      disabled,
      ...others
    },
    ref
  ) => {
    const { t } = useTranslation();

    const { data: accounts = EMPTY_ARRAY, isLoading: loading } =
      useExternalAccountsCC();

    const accountOptions = accounts
      .filter(({ emailStatus }) => !ERROR_STATE_STATUSES.includes(emailStatus))
      .map((acct) => accountToOption(acct));

    useEffect(() => {
      if (accountOptions.length && !inbox) {
        onChange('inbox', accountOptions[0]);
      }
    }, [onChange, accountOptions, inbox]);

    if (loading) {
      return <CenteredLoader loading />;
    }

    return (
      <>
        {(!accounts.length || !accountOptions.length) && (
          <ConnectEmailAlert>
            {t('Please')}{' '}
            <a target="_blank" href="/account">
              {t('connect at least 1 inbox')}
            </a>{' '}
            {t('first in order to use this feature.')}
          </ConnectEmailAlert>
        )}
        <StyledSelect
          fullWidth
          // Prevent label appearing disabled when field is disabled
          label={
            <TextSpan weight="bold" style={{ fontSize: 'inherit' }}>
              {t('From (Connected Inbox)')}
            </TextSpan>
          }
          placeholder={t('Choose Connected Inbox')}
          value={inbox}
          options={accountOptions}
          onChange={(val) => onChange('inbox', val)}
          disabled={!accountOptions.length}
        />
        <StyledInput
          fullWidth
          placeholder={t('Enter Subject Line')}
          label={t('Subject Line')}
          value={subject}
          onChange={(val) => onChange('subject', val)}
          disabled={disabled}
        />
        <StyledLabel>{t('Enter Message')}</StyledLabel>
        <BasicRichTextEditor
          enableLinks
          enableAI={enableAI}
          textLinkOptions={[
            {
              label: t('Contact Record Link'),
              href: '{{ contact.link_url }}',
            },
          ]}
          ref={ref}
          initialValue={body}
          onChange={({ editor }) => onChange('body', editor.getHTML())}
          disabled={disabled}
          {...others}
        />
      </>
    );
  }
);
