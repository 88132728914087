import { useTranslation } from 'react-i18next';
import StepCard from '__pages/AutomationEngine/Engine/Steps/StepCard';
import { useContext } from 'react';
import { FlowBuilderContext } from '../../context';
import { FlowStep } from '../../types';
import { AdditionalVariable } from '__pages/SmartConnectors/types';
import { TextEllipsisWithTooltip } from '__components/Kizen/Table';
import { colorsButton } from '__app/colors';

type StepProps = {
  step: FlowStep & { details: { additional_variables: AdditionalVariable[] } };
  color: string;
  title: string;
};

export default function InitializeAdditionalVariablesCard({
  step,
  color,
  title,
  ...others
}: StepProps) {
  const { t } = useTranslation();
  const { dropStep, flowErrors } = useContext(FlowBuilderContext);
  const { additional_variables } = step.details;

  const stepErrors = flowErrors.flow.additional_variables || [];
  const hasErrors = stepErrors.some(
    (errors) => Object.values(errors || {}).length
  );

  return (
    <StepCard
      {...others}
      panel={null}
      color={hasErrors ? colorsButton.red.hover : color}
      borderColor={hasErrors ? colorsButton.red.hover : null}
      showMovingPlaceholder={false}
      title={title}
      onSelectMenu={() => {
        dropStep({ step, ...(others as any) });
      }}
      editOnly
    >
      <TextEllipsisWithTooltip>
        {t('Set Values for {{amount}} Variable(s)', {
          amount: additional_variables?.length || 0,
        })}
      </TextEllipsisWithTooltip>
    </StepCard>
  );
}
