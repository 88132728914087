import { useCallback, useState } from 'react';
import { usePreserveCaretPosition } from '__hooks/usePreserveCaretPosition';

const MAX_FOLDER_NAME_LENGTH = 200;

export const useFolderNameLimit = (
  initialName: string,
  limit = MAX_FOLDER_NAME_LENGTH
) => {
  const [name, setName] = useState(initialName);

  const handleChangeName = useCallback(
    (val: string) => {
      setName(val.substring(0, limit + 1));
    },
    [limit]
  );

  const inputRef = usePreserveCaretPosition(name, true);

  return { inputRef, name, handleChangeName };
};
