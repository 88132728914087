import { invalidate } from 'queries/invalidate';
import AxiosService, {
  DefaultAxiosRetryConfig,
  getOriginalError,
} from './AxiosService';
import {
  camelToSnakeCaseKeys,
  FORCE_ALL_RECORDS_SIZE,
  snakeToCamelCaseKeys,
  fieldForApp,
  categoryForApp,
} from './helpers';
import {
  monitoringExceptionHelper,
  monitoringMessageHelper,
} from 'sentry/helpers';

export const filterDataMatches = {
  isMe: ':"is_me"|"isMe":true|"isMyRecordsGroup":true',
  timezoneRelated:
    '"condition":"entered_stage"|"condition":"left_stage"|"isTimeZoneRelatedGroup":true',
};

export const checkDataExistsInFilter = (
  { query } = {},
  match = filterDataMatches.isMe
) => {
  try {
    return query?.some(({ filters, query }) => {
      if (!filters && query) {
        return checkDataExistsInFilter({ query }, match);
      }
      return filters.some((filter) => {
        // filter.values - if `values` key exists - it is a full filter object otherwise it is just an object from a group. we should search only in the filter object and not in metadata
        const search = filter?.values || filter;
        const isMatch = JSON.stringify(search).match(match);
        return !!isMatch;
      });
    });
  } catch {
    // some old filter groups can have wrong structure. they are to be cleared out, but for now we need to handle error quietly
    monitoringMessageHelper(
      'checkDataExistsInFilter: filters is an object but should be array'
    );
    return false;
  }
};

class CustomObjectsService {
  constructor() {
    if (!CustomObjectsService.instance) {
      CustomObjectsService.instance = this;
    }
    return CustomObjectsService.instance;
  }

  objectEndpoint = 'custom-objects';

  clientEndpoint = 'client';

  getCustomObjectList = async (
    {
      search = '',
      page = 1,
      size,
      ordering = 'objectName',
      defaultOnActivities = undefined,
      customOnly = true,
    },
    options
  ) => {
    const params = {
      search,
      page: page || 1,
      page_size: size || 50,
      ordering,
      default_on_activities: defaultOnActivities,
      custom_only: customOnly,
    };

    try {
      const { data } = await AxiosService.get(`/${this.objectEndpoint}`, {
        params,
        ...options,
      });

      return snakeToCamelCaseKeys(data);
    } catch (error) {
      const orig = getOriginalError(error);
      if (orig.detail) {
        throw new Error(orig.detail);
      }
      throw error;
    }
  };

  getCustomObjectListLight = async () => {
    try {
      const { data } = await AxiosService.get(
        `/${this.objectEndpoint}/settings-search`
      );

      return snakeToCamelCaseKeys(data);
    } catch (error) {
      const orig = getOriginalError(error);
      if (orig.detail) {
        throw new Error(orig.detail);
      }
      throw error;
    }
  };

  getAllowedRelations = async () => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/settings-search`
    );
    return snakeToCamelCaseKeys(data);
  };

  updateCustomObject = async ({ customObject, updatedCustomObjectValues }) => {
    const { id: objectId, objectType } = customObject;
    const { data } = await AxiosService.patch(
      `/${this.objectEndpoint}/${objectId}`,
      {
        object_type: objectType,
        ...camelToSnakeCaseKeys(updatedCustomObjectValues),
        name: undefined, // name is not allowed to be updated
      }
    );

    invalidate.ACTIVITIES.DEFAULT_RELATIONSHIPS();
    return snakeToCamelCaseKeys(data);
  };

  createCustomObject = async (payload) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}`,
      camelToSnakeCaseKeys(payload)
    );

    invalidate.ACTIVITIES.DEFAULT_RELATIONSHIPS();
    invalidate.FILTERS.ALL();
    return snakeToCamelCaseKeys(data);
  };

  exportFields = async (objectId) => {
    await AxiosService.post(
      `/${this.objectEndpoint}/${objectId}/export/fields`
    );
  };

  deleteCustomObject = async ({ id }) => {
    const result = await AxiosService.delete(`/${this.objectEndpoint}/${id}`);

    invalidate.ACTIVITIES.DEFAULT_RELATIONSHIPS();
    invalidate.FORMS.FORM_RELATED_OBJECT_FIELDS(id);
    invalidate.FILTERS.ALL();
    return result;
  };

  deleteStage = async ({ objectId, id, newStageId }) => {
    await AxiosService.post(
      `/pipelines/${objectId}/stages/remove-stage`,
      camelToSnakeCaseKeys({ id, newStageId })
    );
  };

  // these are the "new" endpoints with matching names in the PipelineService

  // will bypass permissions - only used on the settings page -> permission group modal
  getObjects = async () => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/settings-search`,
      {
        params: { page_size: FORCE_ALL_RECORDS_SIZE },
      }
    );
    return data
      .filter((obj) => obj.name !== 'client_client')
      .map(snakeToCamelCaseKeys);
  };

  getObjectCategories = async (
    objectId,
    { transformToCamelCase = true } = {}
  ) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${objectId}/categories`
    );
    return transformToCamelCase ? snakeToCamelCaseKeys(data) : data;
  };

  createObjectCategory = async (objectId, payload) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${objectId}/categories`,
      camelToSnakeCaseKeys(payload)
    );
    return categoryForApp(data);
  };

  patchObjectCategory = async (objectId, id, payload) => {
    const { data } = await AxiosService.patch(
      `/${this.objectEndpoint}/${objectId}/categories/${id}`,
      camelToSnakeCaseKeys(payload)
    );
    return categoryForApp(data);
  };

  deleteObjectCategory = async (objectId, id) => {
    const { data } = await AxiosService.delete(
      `/${this.objectEndpoint}/${objectId}/categories/${id}`
    );
    return data;
  };

  createObjectField = async (objectId, payload, options) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${objectId}/fields`,
      camelToSnakeCaseKeys(payload),
      { ...options, 'axios-retry': DefaultAxiosRetryConfig }
    );
    invalidate.FORMS.FORM_RELATED_OBJECT_FIELDS(objectId);
    return fieldForApp(data);
  };

  getObjectFields = async (objectId) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${objectId}/fields`
    );
    return fieldForApp(data);
  };

  getObjectFieldsWithoutRestrictions = async (
    objectId,
    { transformToCamelCase = true } = {}
  ) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${objectId}/fields/settings-search`
    );
    return transformToCamelCase ? fieldForApp(data) : data;
  };

  getObjectField = async (id, objectId) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${objectId}/fields/${id}`
    );
    return fieldForApp(data);
  };

  patchObjectField = async (objectId, id, payload, options) => {
    const { data } = await AxiosService.patch(
      `/${this.objectEndpoint}/${objectId}/fields/${id}`,
      camelToSnakeCaseKeys(payload),
      options
    );
    invalidate.FORMS.FORM_RELATED_OBJECT_FIELDS(objectId);
    return fieldForApp(data);
  };

  deleteObjectField = async (objectId, id) => {
    const { data } = await AxiosService.delete(
      `/${this.objectEndpoint}/${objectId}/fields/${id}`
    );
    invalidate.FORMS.FORM_RELATED_OBJECT_FIELDS(objectId);
    return data;
  };

  updateObjectStyles = async (objectId, categories) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${objectId}/update-styles`,
      camelToSnakeCaseKeys({
        categories: categories.map(({ id, name, fields }) => ({
          id,
          name,
          fields: fields.map((field) => ({
            id: field.id,
            isHidden: field.isHidden,
            // Must ensure cols are set
            meta: { ...field.meta, cols: field.meta.cols || 1 },
          })),
        })),
      })
    );
    invalidate.FIELDS.CUSTOM_OBJECTS('standard', objectId);
    return snakeToCamelCaseKeys(data);
  };

  getContactFields = async () => {
    const { data } = await AxiosService.get(`${this.clientEndpoint}/fields`);
    return snakeToCamelCaseKeys(data);
  };

  getContactCategories = async () => {
    const { data } = await AxiosService.get(
      `/${this.clientEndpoint}/categories`
    );
    return snakeToCamelCaseKeys(data);
  };

  getCustomObjectDetails = async (id, options) => {
    const { data } = await AxiosService.get(
      `${this.objectEndpoint}/${id}/detail`,
      options
    );
    return snakeToCamelCaseKeys(data);
  };

  getCustomModelFields = async (
    id,
    { transformToCamelCase = true, ...options } = {}
  ) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${id}/fields/search`,
      options
    );
    return transformToCamelCase ? snakeToCamelCaseKeys(data) : data;
  };

  getCategorizedModelFields = async (
    id,
    { settingsSearch = false, transformToCamelCase = true } = {}
  ) => {
    const [fields, categories] = await Promise.all([
      settingsSearch
        ? this.getObjectFieldsWithoutRestrictions(id, { transformToCamelCase })
        : this.getCustomModelFields(id, { transformToCamelCase }),
      this.getObjectCategories(id, { transformToCamelCase }),
    ]);

    const fieldsByCategories = fields.reduce((acc, field) => {
      acc[field.category] = (acc[field.category] || []).concat(field);
      return acc;
    }, {});

    return {
      categorizedFields: categories.map((category) => {
        return {
          ...category,
          fields: fieldsByCategories[category.id] || [],
        };
      }),
      fields,
      categories,
    };
  };

  getCustomObjectRecord = async ({ id, objectId }, params) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${objectId}/entity-records/${id}`,
      params
    );
    return snakeToCamelCaseKeys(data);
  };

  getContactRecord = async (id, params) => {
    const { data } = await AxiosService.get(
      `/${this.clientEndpoint}/${id}`,
      params
    );
    return snakeToCamelCaseKeys(data);
  };

  createDynamicTag = async ({ fieldId, name, objectId }, options) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${objectId}/fields/${fieldId}/options`,
      {
        name,
      },
      options
    );
    return data;
  };

  deleteDynamicTag = async ({ fieldId, tagId, objectId }, options) => {
    await AxiosService.delete(
      `/${this.objectEndpoint}/${objectId}/fields/${fieldId}/options/${tagId}`,
      options
    );
  };

  updateDynamicTag = async ({ fieldId, tagId, objectId, name }, options) => {
    const { data } = await AxiosService.patch(
      `/${this.objectEndpoint}/${objectId}/fields/${fieldId}/options/${tagId}`,
      {
        name,
      },
      options
    );
    return data;
  };

  replaceDynamicTag = async (
    origTagId,
    replacementTagId,
    fieldId,
    objectId
  ) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${objectId}/fields/${fieldId}/options/${origTagId}/replace`,
      camelToSnakeCaseKeys({ optionId: replacementTagId })
    );
    return data;
  };

  getDynamicTagsOptions = async (fieldId, objectId, page, search, options) => {
    const { data } = await AxiosService.get(
      `/${
        this.objectEndpoint
      }/${objectId}/fields/${fieldId}/tags?ordering=name&page=${page}&page_size=20&search=${encodeURIComponent(
        search
      )}`,
      options
    );
    return data;
  };

  getDynamicTagsOptionsOrdered = async (
    fieldId,
    objectId,
    page,
    search,
    ordering = 'name',
    options
  ) => {
    const { data } = await AxiosService.get(
      `/${
        this.objectEndpoint
      }/${objectId}/fields/${fieldId}/options?ordering=${ordering}&page=${page}&page_size=20&search=${encodeURIComponent(
        search
      )}&include_entity_count=true`,
      options
    );
    return data;
  };

  getGroups = async (options) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/custom-objects-group`,
      options
    );
    return data.results;
  };

  getRecordsGroups = async (objectId) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${objectId}/filter-groups`,
      { skipErrorBoundary: true }
    );
    return snakeToCamelCaseKeys(data).map((group) => ({
      ...group,
      isMyRecordsGroup: checkDataExistsInFilter(group.config),
      isTimeZoneRelatedGroup: checkDataExistsInFilter(
        group.config,
        filterDataMatches.timezoneRelated
      ),
    }));
  };

  getRecordsGroupsOptions = async (fieldId) => {
    const data = await this.getRecordsGroups(fieldId);

    return data
      .map(({ id, name, isMyRecordsGroup, isTimeZoneRelatedGroup }) => ({
        value: id,
        label: name,
        isMyRecordsGroup,
        isTimeZoneRelatedGroup,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  getAssociatedTeamMembers = async ({
    objectId,
    entityId,
    ordering,
    page_size,
    page,
  }) => {
    const {
      data: { results, count },
    } = await AxiosService.get(
      `/${this.objectEndpoint}/${objectId}/entity-records/${entityId}/team-associations`,
      {
        params: { page_size, ordering, page },
      }
    );
    return { values: snakeToCamelCaseKeys(results), count };
  };

  createAssociatedTeamMembers = async ({ objectId, entityId, payload }) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${objectId}/entity-records/${entityId}/team-associations`,
      payload
    );
    return snakeToCamelCaseKeys(data);
  };

  updateAssociatedTeamMember = async (
    { customObjectId, entityId, associationId, payload },
    options
  ) => {
    const { data } = await AxiosService.patch(
      `/${this.objectEndpoint}/${customObjectId}/entity-records/${entityId}/team-associations/${associationId}`,
      payload,
      options
    );
    return snakeToCamelCaseKeys(data);
  };

  getObjectRelatedPipelines = async (
    id,
    search,
    objectId,
    skipErrorBoundary = false
  ) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${objectId}/entity-records/${id}/related-pipelines`,
      {
        params: { search },
        skipErrorBoundary,
      }
    );
    return snakeToCamelCaseKeys(data);
  };

  getDisplayNames = async (body, options) => {
    const { data } = await AxiosService.post(
      `/records/display-names`,
      camelToSnakeCaseKeys(body),
      options
    );
    return snakeToCamelCaseKeys(data);
  };

  getFieldOptionsWithFacets = async (
    objectId,
    fieldId,
    body,
    params,
    options
  ) => {
    const { data } = await AxiosService.post(
      `/records/${objectId}/field-options/${fieldId}`,
      camelToSnakeCaseKeys(body),
      { ...options, params: camelToSnakeCaseKeys(params) }
    );
    return snakeToCamelCaseKeys(data);
  };

  // TODO (keegandonley): Improve/consolidate this once we settle the API changes for storing the config
  getRecordLayout = async (objectId) => {
    const { data } = await AxiosService.get(
      `/${this.objectEndpoint}/${objectId}`
    );

    return data.meta.record_detail_layout;
  };

  listRecordLayouts = async (objectId) => {
    try {
      const { data } = await AxiosService.get(
        `/${this.objectEndpoint}/${objectId}/layouts`,
        {
          skipErrorBoundary: true,
        }
      );
      return data.results;
    } catch (ex) {
      monitoringExceptionHelper(ex);
      return [];
    }
  };

  createRecordLayout = async (objectId, payload) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${objectId}/layouts`,
      payload
    );

    return data;
  };

  updateRecordLayout = async (objectId, layoutId, payload) => {
    const { data } = await AxiosService.patch(
      `/${this.objectEndpoint}/${objectId}/layouts/${layoutId}`,
      payload,
      {
        skipErrorBoundary: true,
      }
    );

    return data;
  };

  bulkUpdateLayouts = async (objectId, payload) => {
    const { data } = await AxiosService.post(
      `/${this.objectEndpoint}/${objectId}/layouts/bulk-update`,
      payload,
      {
        skipErrorBoundary: true,
      }
    );

    return data;
  };

  deleteLayout = async (objectId, layoutId) => {
    const { data } = await AxiosService.delete(
      `/${this.objectEndpoint}/${objectId}/layouts/${layoutId}`,
      {
        skipErrorBoundary: true,
      }
    );

    return data;
  };
}

const instance = new CustomObjectsService();
Object.freeze(instance);

export default instance;
