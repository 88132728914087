import { createSlice, createSelector } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

import { STAGE_STATUSES } from 'utility/constants';
import { initialStore } from '../initialStore';
import i18nInstance from 'i18n-config';
import { VIEW_VARIANTS } from 'components/ViewVariant/types';
import { isPipelineObject } from 'components/Modals/utilities';
import { getFieldsForUI } from './saga';
import { setQuickFiltersUpToDate } from 'store/utilities';

import { isFilterSet, isFilterSetArray } from '@kizen/filters/filter-sets';

import { v4 as uuidv4 } from 'uuid';
import { EMPLOYEE_ACCESS } from 'components/AccessRequests/utils';
import { RECORD_LIST_PAGE_CONFIG_STRUCTURE } from 'services/TeamMemberService';
import { isStageField } from 'checks/fields';

const getGroupName = (groups, config) => {
  const group = groups.find((e) => e.id === config.group);
  return group ? group.name : '';
};

export const SUMMARY_VALUES = {
  noSummary: 'no_summary',
  numberOfRecords: 'number_of_records',
  totalValue: 'total_value',
};

export const getMetricValue = ({ item, settings }) => {
  if (settings.summaryMetric === SUMMARY_VALUES.noSummary) {
    return 0;
  }
  if (settings.summaryMetric === SUMMARY_VALUES.numberOfRecords) {
    return 1;
  }
  if (settings.summaryMetric === SUMMARY_VALUES.totalValue) {
    return item.entityValue ? Number((+item.entityValue).toFixed(2)) : 0;
  }

  const itemField = item.fields.find(
    ({ field }) => field === settings.summaryMetric
  );
  return (itemField?.value?.amount ?? itemField?.value) || 0;
};

export const getSecondaryMetricValue = ({ item, settings }) => {
  if (settings.secondarySummaryMetric === SUMMARY_VALUES.noSummary) {
    return 0;
  }
  if (settings.secondarySummaryMetric === SUMMARY_VALUES.numberOfRecords) {
    return 1;
  }
  if (settings.secondarySummaryMetric === SUMMARY_VALUES.totalValue) {
    return item.entityValue ? Number((+item.entityValue).toFixed(2)) : 0;
  }

  const itemField = item.fields.find(
    ({ field }) => field === settings.secondarySummaryMetric
  );
  return (itemField?.value?.amount ?? itemField?.value) || 0;
};

const getIndicesReducer = (id) => (acc, curr, idx) => {
  if (acc[0] >= 0 && acc[1] >= 0) {
    return acc;
  }
  const { cards } = curr;
  const card = cards.findIndex((c) => c.id === id);
  if (card >= 0) {
    return [idx, card];
  }

  return acc;
};

const performPartialUpdateOfBoard = (
  newStages,
  state,
  id,
  stageId,
  recordMatchesFilters
) => {
  //as we do only partial update, we still need the summary values to be updated
  state.boardData.stages.forEach((stage) => {
    stage.summary = (
      newStages.find(({ id }) => stage.id === id) || stage
    ).summary;
  });
  const [existingOuterIndex, existingInnerIndex] =
    state.boardData.stages.reduce(getIndicesReducer(id), [-1, -1]);

  const [newOuterIndex, newInnerIndex] = newStages.reduce(
    getIndicesReducer(id),
    [-1, -1]
  );

  if (
    existingInnerIndex >= 0 &&
    existingOuterIndex >= 0 &&
    newInnerIndex >= 0 &&
    newOuterIndex >= 0
  ) {
    state.boardData.stages[existingOuterIndex].cards[existingInnerIndex] =
      newStages[newOuterIndex].cards[newInnerIndex];
  }
  if (!recordMatchesFilters) {
    // the card should not be in the board - it's hidden by filters
    let stageIndex = state.boardData.stages.findIndex((s) => s.id === stageId);

    if (stageIndex === -1) {
      // did not find it, lets try and find in all stages
      stageIndex = state.boardData.stages.findIndex((stage) =>
        stage.cards.some((c) => c.id === id)
      );
    }

    // found it remove it
    if (stageIndex > 0) {
      state.boardData.stages[stageIndex].cards = state.boardData.stages[
        stageIndex
      ].cards.filter((card) => card.id !== id);
    }
  }
};

export const defaultRecordsConfig = {
  columnOrder: null,
  columns: {}, // id -> { width, visible }
  recordsPerPage: 50,
  search: '',
  sort: 'name',
  viewVariant: VIEW_VARIANTS.TABLE,
};

const rmNegativeValues = (object) => {
  return Object.keys(object).reduce(
    (acc, el) => (object[el] ? { ...acc, [el]: object[el] } : acc),
    {}
  );
};

const generatePageConfig = (fromApi, fromState) => {
  const defaultPageConfigWhichMustBe = {
    columnOrder: null,
    columns: {}, // id -> { width, visible }
    search: '',
    sort: 'name',
    group: null,
    page: 1,
    size: 50,
    filterObject: {},
    quickFilters: {},
    queryFilter: null,
    viewVariant: VIEW_VARIANTS.TABLE,
    currentDashboard: '',
    dashboardState: {},
    quickFilterSettings: [],
    quickFilteringFacets: false,
  };

  return Object.keys(defaultPageConfigWhichMustBe).reduce((acc, el) => {
    const compare = (property) => {
      const propertyWhichShouldBeAlwaysFromBackEnd = Object.values(
        RECORD_LIST_PAGE_CONFIG_STRUCTURE
      ).flat();

      const highestPriorityFromAPI = [
        'columnOrder',
        'columns',
        'quickFilters',
        'quickFilterSettings',
        'quickFilteringFacets',
      ];

      if (fromApi && highestPriorityFromAPI.includes(property)) {
        return fromApi[property] || defaultPageConfigWhichMustBe[property];
      }

      // highest priority from url string
      if (fromState && fromState[property]) return fromState[property];

      // check extra fields
      if (
        propertyWhichShouldBeAlwaysFromBackEnd.includes(property) &&
        fromApi
      ) {
        return fromApi[property] || defaultPageConfigWhichMustBe[property];
      }

      return defaultPageConfigWhichMustBe[property];
    };

    return { ...acc, [el]: compare(el) };
  }, {});
};

const getBoardSettingStructure = (boardSettings, fields) => ({
  ...boardSettings,
  fields: boardSettings.fields.length
    ? boardSettings.fields.reduce((acc, item) => {
        const field = fields.find((f) => f.id === item);

        if (field) {
          return [
            ...acc,
            {
              value: field.id,
              label: field.displayName,
              field,
            },
          ];
        }

        return acc;
      }, [])
    : [],
  summaryMetric: boardSettings.summaryMetric.name
    ? boardSettings.summaryMetric.id
    : SUMMARY_VALUES.noSummary,
  secondarySummaryMetric: boardSettings.secondarySummaryMetric.name
    ? boardSettings.secondarySummaryMetric.id
    : SUMMARY_VALUES.noSummary,
});

// Slices
const defaultState = initialStore.recordsPage;

export const recordsPageSlice = createSlice({
  name: 'recordsPage',
  initialState: defaultState,
  reducers: {
    buildPage: (state, { payload }) => {
      const { page } = payload;
      state.isFetching = true;
      state.pageReady = false;
      state.pageConfig = {
        ...initialStore.recordsPage.pageConfig,
        ...rmNegativeValues(page),
      };
    },
    resetPage: () => {
      return defaultState;
    },
    buildPageComplete: (state, { payload }) => {
      const { pageResponse, model, categorizedFields, fields, groupsResponse } =
        payload;
      const groups = [
        { value: 'none', label: i18nInstance.t('All Records') },
      ].concat(
        groupsResponse
          .map((el) => ({ ...el, value: el.id, label: el.name }))
          .sort((a, b) => a.label.localeCompare(b.label))
      );
      const pageConfig = generatePageConfig(pageResponse, state.pageConfig);

      const stageField = fields.find(isStageField);

      state.allFields = fields;
      state.fields = fields.filter((f) => !f.isHidden);
      // TODO: removed once backend add this field to the endpoint;
      state.model = { ...model, objectClass: 'custom_objects' };
      state.categorizedFields = categorizedFields;
      state.pageConfig = {
        ...pageConfig,
        quickFilters: setQuickFiltersUpToDate(
          state.model,
          pageConfig.quickFilterSettings,
          pageConfig.quickFilters
        ),
      };
      state.groups = groups;
      state.stages = (stageField?.options || []).map((element) => ({
        label: element.name,
        value: element.id,
      }));
      state.filters.name = getGroupName(groups, pageConfig);
    },
    buildPageFinish: (state) => {
      state.isFetching = false;
      state.pageReady = true;
      state.filterGroupReason = null;
      state.needToLoadCounts = true;
    },
    buildPageFail: (state) => {
      state.isFetching = false;
    },
    getFullListOfCustomObjectsSuccess: (state, { payload }) => {
      state.fullListOfCustomObjects = payload;
    },
    getRecordsStart: (state, { payload }) => {
      if (payload?.triggerViewLoading) {
        state.isLoadingView = true;
      }
      state.isFetchingRecords = true;
      state.isFiltering = true;
    },
    getRecordsFinish: (state) => {
      state.isFiltering = false;
      state.initialFetching = false;
    },
    getBoardDataFinish: (state) => {
      state.initialFetching = false;
    },
    setIsAllRecordsGroup: (state, { payload }) => {
      // needs to spread the whole object because the page is memoizing the `state.filters` and passing the result as a prop
      state.filters = {
        ...state.filters,
        // this flag (not in initial state) is needed to set the key prop of ClientFilterDropdown to
        // reset the filter UI when selecting the All Contacts group. We set a UUID rather than true
        // because we use this value as the key.
        isAllRecordsGroup: payload && uuidv4(),
      };
    },
    setSavedFilter: (state, { payload: { config, and, modelId } }) => {
      state.filters.and = and;
      state.filters.config = config;
      state.filters.numberOfFilters = isFilterSetArray(config)
        ? config.flatMap((q) => q.filters).length
        : isFilterSet(config)
          ? config.filters.length
          : 0;
      state.filters.loading = false;
      state.filters.key = uuidv4();
      state.filters.modelId = modelId;
    },
    getRecordsSuccess: (state, { payload }) => {
      const { records, recordsCount, refreshPage, errors, fetchedCriteria } =
        payload;

      state.isFetchingRecords = false;
      state.isLoadingView = false;
      if (refreshPage) {
        // This is a very different situation: we simply want to refresh stale information that is currently in state.
        // We wont be updating the result set, we will just update data for individual records that we already have.
        const recordsById = records.reduce((collect, c) => {
          collect[c.id] = c;
          return collect;
        }, {});
        state.records.forEach((c) => {
          if (recordsById[c.id]) Object.assign(c, recordsById[c.id]);
        });
        return state;
      }

      state.filters.errors = errors;

      let { checked } = state.selection;

      if (['group', 'all', 'search'].includes(state.selection.value)) {
        state.records = records.map((el) => ({
          ...el,
          checked: !state.selection.subtractIds.includes(el.id),
        }));
        state.recordsCount = recordsCount;
        state.recordsCountAndNew = recordsCount;
        return state;
      }

      const withCheckedProperty = records.map((el) => ({
        ...el,
        checked: state.selection.checkedIds.includes(el.id),
      }));
      // on pagination we change if something was selected on prev page
      if (checked) {
        checked = !withCheckedProperty.find((el) => !el.checked);
      }

      state.records = withCheckedProperty;
      state.recordsCount = recordsCount;
      state.recordsCountAndNew = recordsCount;
      state.selection = {
        ...state.selection,
        checked,
      };

      state.fetchedCriteria = fetchedCriteria;

      return state;
    },
    getRecordsFail: (state) => {
      state.isFetchingRecords = false;
      state.isLoadingView = false;
    },
    updatePageConfig: (state, { payload }) => {
      if (payload.group) {
        const group = state.groups.find((g) => g.id === payload.group);

        //  const config = newFilterConfig(payload, state.groups);
        //  const numberOfFilters = config.uiConfig.query.reduce(
        //    filterCountReducer,
        //    0
        //  );
        //  const name = getGroupName(state.groups, payload);
        state.pageConfig = {
          ...state.pageConfig,
          ...payload,
          filterObject: group.config,
        };
        //  state.filters = {
        //    ...state.filters,
        //    config: config.uiConfig,
        //    numberOfFilters,
        //    name,
        //    errors: [],
        //    errorDictionary: [],
        //    currentErrors: [],
        //  };
      } else if (payload.group === null) {
        state.pageConfig = { ...state.pageConfig, ...payload };
        state.pageConfig.filterObject = {};
        state.filters = initialStore.recordsPage.filters;
      } else {
        state.pageConfig = { ...state.pageConfig, ...payload };
      }
    },
    clearFilterSearch: (state) => {
      state.pageConfig.search = '';
    },
    setCheckedRecord: (state, { payload }) => {
      const { id } = payload;
      const record = state.records.find((el) => el.id === id);
      if (record) {
        const records = state.records.map((el) =>
          el.id === id ? { ...el, checked: !el.checked } : el
        );

        const wasIncluded = state.selection.checkedIds.includes(id);

        const checkedCount =
          state.selection.checkedCount + (!record.checked === true ? 1 : -1);

        const checkedIds = wasIncluded
          ? state.selection.checkedIds.filter((el) => el !== id)
          : state.selection.checkedIds.concat(id);

        let subtractIds = [];
        if (['group', 'all', 'search'].includes(state.selection.value)) {
          subtractIds =
            wasIncluded || !state.selection.subtractIds.includes(id)
              ? state.selection.subtractIds.concat(id)
              : state.selection.subtractIds.filter((el) => el !== id);
        }

        state.records = records;
        state.selection = {
          ...state.selection,
          checkedCount,
          checkedIds: uniq(checkedIds),
          subtractIds: uniq(subtractIds),
        };
      }
    },
    setCheckedSelection: (state, { payload }) => {
      const { value } = payload;
      const checked = value !== 'individual';
      const records = state.records.map((el) => {
        return { ...el, checked };
      });

      let checkedCount;
      let checkedIds = [];
      const subtractIds = [];
      let isDefaultCase = false;

      switch (value) {
        case 'page':
          checkedCount = !checked ? 0 : records.length;
          checkedIds =
            checkedCount === 0 ? [] : state.records.map((el) => el.id);
          break;
        case 'search':
          checkedCount = !checked ? 0 : state.recordsCountAndNew; // must include any new entities in selection
          checkedIds =
            checkedCount === 0 ? [] : state.records.map((el) => el.id);
          // check for any of theese id's being in the ignore list and remove them
          if (state.selection.subtractIds.length && checkedIds.length) {
            const subtractIds = state.selection.subtractIds.filter(
              (id) => !checkedIds.includes(id)
            );
            state.selection = {
              ...state.selection,
              subtractIds,
            };
          }

          break;
        case 'group': {
          const count = state.pageConfig.group
            ? state.groupCount
            : state.allCount;
          checkedCount = !checked ? 0 : count;
          checkedIds =
            checkedCount === 0 ? [] : state.records.map((el) => el.id);
          break;
        }
        case 'all':
          checkedCount = !checked ? 0 : state.allCount;
          checkedIds =
            checkedCount === 0 ? [] : state.records.map((el) => el.id);
          break;
        default:
          isDefaultCase = true;
          state.selection = {
            ...initialStore.recordsPage.selection,
          };
          state.records = state.records.map((el) => ({
            ...el,
            checked: false,
          }));
          break;
      }

      if (!isDefaultCase) {
        state.selection = {
          ...state.selection,
          value,
          checked,
          checkedCount,
          checkedIds,
          subtractIds,
        };
        state.records = records;
      }
    },
    updateColumnWidth: (state, action) => {
      const { payload } = action;
      state.pageConfig = {
        ...state.pageConfig,
        columns: {
          ...state.pageConfig.columns,
          [payload.id]: {
            ...state.pageConfig.columns[payload.id],
            ...payload,
          },
        },
      };
    },

    setGroup: (state, { payload }) => {
      state.filters.name = payload.name;
      state.pageConfig.group = payload.id;
      state.filters.errors = null;
      state.pageConfig.queryFilter = null; // must clear query filter when switching groups
    },

    setPageConfig: (state, { payload }) => {
      if (!payload) return state;

      const { settings, updatePageConfigKey, ...restPayload } = payload;
      if (settings) {
        const columnOrder = settings.map(({ id }) => id);
        const columns = settings.reduce((acc, el) => {
          const elementFromRedux = state.pageConfig.columns[el.id];
          return { ...acc, [el.id]: { ...elementFromRedux, ...el } };
        }, {});
        state.pageConfig = {
          ...state.pageConfig,
          columnOrder,
          columns,
        };
      } else {
        state.pageConfig = { ...state.pageConfig, ...restPayload };
      }
      return state;
    },

    addNewRecord: (state, { payload }) => {
      const { id, record } = payload;
      state.allCount += 1;
      state.recordsCountAndNew += 1; // Specifically for the table header
      if (state.pageConfig.viewVariant === VIEW_VARIANTS.TABLE) {
        const shouldBeChecked = ['group', 'all', 'search'].includes(
          state.selection.value
        );

        state.records = [
          { ...record, checked: shouldBeChecked },
          ...state.records.filter((el) => el.id !== id),
        ];
        state.selection = {
          ...state.selection,
          ...(shouldBeChecked
            ? {
                checkedCount: state.selection.checkedCount + 1,
                checkedIds: [...state.selection.checkedIds, id],
              }
            : { subtractIds: [...state.selection.subtractIds, id] }),
        };
      }

      if (
        state.pageConfig.viewVariant === VIEW_VARIANTS.BOARD &&
        isPipelineObject(state.model)
      ) {
        state.boardData.stages = state.boardData.stages.map((stage) => {
          if (record.stage.value === stage.id && !stage.hasMorePages) {
            return {
              ...stage,
              cards: [
                ...stage.cards,
                {
                  ...record,
                  isSelected: false,
                },
              ],
            };
          }

          return stage;
        });
      }
    },
    archiveRecordSuccess: (state, { payload }) => {
      const { id } = payload;
      const wasIncluded = state.selection.checkedIds.includes(id);
      if (wasIncluded) {
        const checkedIds = state.selection.checkedIds.filter((el) => el !== id);
        const checkedCount = state.selection.checkedCount - 1;
        state.selection = {
          ...state.selection,
          checkedCount,
          checked: checkedCount > 0,
          checkedIds: uniq(checkedIds),
        };
      }
    },
    updateRecord: (state, { payload }) => {
      const { record, patch, stageId } = payload;
      state.records = state.records.map((el) => {
        if (el.id === record.id) {
          if (patch.fields && stageId) {
            const targetField = record.fields.find(
              (item) => patch.fields[0].id === item?.field
            );
            if (!targetField) return { ...el, ...record };
            const hasFieldInRecord = el.fields.some(
              ({ field }) => field === targetField.field
            );
            return {
              ...el,
              ...record,
              fields: hasFieldInRecord
                ? el.fields.map((item) => {
                    if (targetField.field === item.field) {
                      return targetField;
                    }
                    return item;
                  })
                : [...el.fields, targetField],
            };
          }
          return { ...el, ...record };
        }
        return el;
      });

      const getUpdateData = () => {
        if (patch.ownerId) {
          return { owner: record.owner };
        }

        return patch;
      };

      state.boardData.stages = state.boardData.stages.map((stage) => {
        if (stage.id === record.stage.value) {
          return {
            ...stage,
            cards: stage.cards.map((card) => {
              if (card.id === record.id) {
                if (patch.fields) {
                  return {
                    ...card,
                    fields: record.fields,
                  };
                }
                return {
                  ...card,
                  ...getUpdateData(),
                };
              }
              return card;
            }),
          };
        }
        return stage;
      });
    },
    setToast: (state, { payload }) => {
      const { variant, message } = payload;
      state.toastData = {
        variant,
        message,
      };
    },
    cleanToast: (state) => {
      state.toastData = null;
    },
    setLeadSourceAdditionalData: (state, { payload }) => {
      state.leadSourceData = payload;
    },
    setLeadSourceDataByKey: (state, { payload }) => {
      const { key, data } = payload;
      state.leadSourceData[key] = data;
    },
    getBoardDataStart: (state, { payload }) => {
      if (payload?.triggerViewLoading) {
        state.isLoadingView = true;
      }
      state.isFetchingRecords = true;
      state.isFiltering = true;
    },
    getBoardDataSuccess: (state, { payload }) => {
      const { boardData, boardSettings, recordsCount, errors } = payload;
      state.isFetchingRecords = false;
      state.isFiltering = false;
      state.isLoadingView = false;
      state.boardData.stages = boardData;
      state.records.forEach((record) => {
        if (record.stage?.value) {
          const stage = boardData.find(({ id }) => id === record.stage.value);
          if (stage) {
            record.actualCloseDate = stage.cards.find(
              ({ id }) => id === record.id
            )?.actualCloseDate;
          }
        }
      });
      state.boardData.settings = getBoardSettingStructure(
        boardSettings,
        state.fields
      );
      state.recordsCount = recordsCount;
      state.recordsCountAndNew = recordsCount;

      state.filters.errors = errors;
    },
    getRecordsInStageSuccess: (state, { payload }) => {
      const { stage } = payload;

      state.boardData.stages = state.boardData.stages.map((item) => {
        if (item.id === stage.id) {
          // prevent the same cards getting added twice
          const filteredCards = item.cards.reduce((acc, card) => {
            return stage.cards.find(({ id }) => id === card.id)
              ? acc
              : [...acc, card];
          }, []);
          return {
            ...item,
            ...stage,
            cards: [
              ...filteredCards,
              ...stage.cards.map((el) => ({
                ...el,
                isSelected: item.isChecked,
              })),
            ],
          };
        }

        return item;
      });
    },
    setStageSelection: (state, { payload }) => {
      const { selection, stageId } = payload;
      state.boardData.stages = state.boardData.stages.map((stage) => {
        if (stage.id === stageId) {
          return {
            ...stage,
            isChecked: selection,
            cards: stage.cards.map((card) => ({
              ...card,
              isSelected: selection,
            })),
          };
        }

        return stage;
      });
    },
    setSelectionCard: (state, { payload }) => {
      const { selection, cardId, stageId } = payload;
      state.boardData.stages = state.boardData.stages.map((stage) => {
        if (stage.id === stageId) {
          return {
            ...stage,
            isChecked: !selection && stage.isChecked ? false : stage.isChecked,
            cards: stage.cards.map((card) => {
              if (card.id === cardId) {
                return {
                  ...card,
                  isSelected: selection,
                };
              }
              return card;
            }),
          };
        }
        return stage;
      });
    },
    removeGroupSuccess: (state, { payload }) => {
      const { id } = payload;
      state.groups = state.groups.filter((el) => el.id !== id);
      state.filters = initialStore.recordsPage.filters;
      state.pageConfig = {
        ...state.pageConfig,
        group: null,
        page: 1,
        filterObject: {},
      };
      state.needToLoadCounts = true;
    },
    resetFilterGroupSuccess: (state, { payload }) => {
      state.filterGroupReason = payload.reason;
      state.pageConfig = { ...state.pageConfig, group: null };
      state.pageConfig.filterObject = {};
      state.filters = initialStore.recordsPage.filters;
    },
    createOrUpdateGroupSuccess: (state, { payload }) => {
      const { filterGroup, isPatch } = payload;
      state.pageConfig = {
        ...state.pageConfig,
        queryFilter: null,
      };
      if (isPatch) {
        state.groups = state.groups.map((el) =>
          el.id === filterGroup.id
            ? {
                ...filterGroup,
                value: filterGroup.id,
                label: filterGroup.name,
                employee_access: el.employee_access,
              }
            : el
        );
        return;
      }

      const [allOption, ...rest] = state.groups;

      const groups = rest
        .concat({
          ...filterGroup,
          value: filterGroup.id,
          label: filterGroup.name,
          employee_access: EMPLOYEE_ACCESS.OWNER,
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      state.groups = [allOption].concat(groups);
      state.pageConfig = {
        ...state.pageConfig,
        group: filterGroup.id,
        page: 1,
      };
      state.groupCount = state.recordsCount;
      state.needToLoadCounts = true;
    },

    setPageFetching: (state) => {
      state.isFetching = true;
    },

    setFilterName: (state, { payload }) => {
      const { name } = payload;
      state.filters = {
        ...state.filters,
        name,
      };
      state.pageConfig = {
        ...state.pageConfig,
        queryFilter: state.pageConfig.queryFilter
          ? {
              ...state.pageConfig.queryFilter,
              maybeName: name,
            }
          : null,
      };
    },
    clearFilterErrors: (state) => {
      state.filters.errors = state.filters.errors?.quickFiltersErrors?.length
        ? { ...state.filters.errors, parsedErrors: [] }
        : null;
    },
    setNumberOfFilters: (state, { payload: numberOfFilters }) => {
      state.filters.numberOfFilters = numberOfFilters;
    },
    resetFilterData: (state) => {
      state.pageConfig.search = '';
      state.pageConfig.group = null;
      state.pageConfig.quickFilters = {};
      if (Object.keys(state.pageConfig.filterObject).length === 0) {
        state.filters = initialStore.recordsPage.filters;
        return;
      }
      state.filters = initialStore.recordsPage.filters;
      state.pageConfig = {
        ...state.pageConfig,
        filterObject: {},
      };
    },
    updateBoardSettings: (state, { payload }) => {
      state.boardData.settings = {
        ...getBoardSettingStructure(payload, state.fields),
        summaryMetric: payload.summaryMetric,
        secondarySummaryMetric: payload.secondarySummaryMetric,
      };
    },
    moveRecordSuccess: (state, { payload }) => {
      const {
        updatedStages,
        id,
        useOldState,
        fromStageId,
        stageId,
        movedRecord,
        recordMatchesFilters, // returned from move record api so we know if we need to removed card that has been optimitacaly added
      } = payload;
      state.boardData.disabledDragCardIds =
        state.boardData.disabledDragCardIds.filter((el) => el !== id);

      // We don't want to update the entire board state when the network request succeeds, because
      // that will cause problems if additional drags have been initiated. Instead, do a partial
      // update of only the card in question
      if (!useOldState) {
        state.boardData.stages = updatedStages;
      } else {
        performPartialUpdateOfBoard(
          updatedStages,
          state,
          id,
          stageId,
          recordMatchesFilters
        );
      }

      delete state.boardData[id];
      if (fromStageId) {
        const updatedStage = updatedStages.find((item) => item.id === stageId);

        if (updatedStage.status === STAGE_STATUSES.lost)
          state.reasonsDialogData = [
            ...state.reasonsDialogData,
            { record: movedRecord, stageId },
          ];
        if (updatedStage.status === STAGE_STATUSES.disqualified)
          state.reasonsDisqualifiedDialogData = [
            ...state.reasonsDisqualifiedDialogData,
            { record: movedRecord, stageId },
          ];
      }
    },
    updateFieldOptions: (state, { payload }) => {
      const { id, newOption } = payload;
      state.fields = state.fields.map((e) =>
        e.id === id ? { ...e, options: e.options.concat(newOption) } : e
      );
    },
    updateModel: (state, { payload }) => {
      state.model = { ...payload, objectClass: 'custom_objects' };
      state.pageConfig.quickFilters = setQuickFiltersUpToDate(
        state.model,
        state.pageConfig.quickFilterSettings,
        state.pageConfig.quickFilters
      );
    },
    moveRecordStart: (state, { payload }) => {
      const { id, updatedColumns } = payload;
      state.boardData[id] = state.boardData.stages;
      if (updatedColumns) {
        state.boardData.stages = updatedColumns;
      }
      state.boardData.disabledDragCardIds = [
        ...state.boardData.disabledDragCardIds,
        id,
      ];
    },
    moveRecordFail: (state, { payload }) => {
      const { id } = payload;
      state.boardData.disabledDragCardIds =
        state.boardData.disabledDragCardIds.filter((el) => el !== id);
      state.boardData.stages = state.boardData[id];
      delete state.boardData[id];
    },
    getCountsSuccess: (state, action) => {
      const { payload } = action;
      const { allCount, groupCount } = payload;
      state.groupCount = groupCount;
      state.allCount = allCount;
      state.needToLoadCounts = false;
    },
    setCountsNeedToLoad: (state) => {
      state.needToLoadCounts = true;
    },
    setBoardDataStagesChecked: (state, action) => {
      const { payload } = action;
      const { isChecked } = payload;

      const boardData = state.boardData;

      const stages = boardData.stages.map((stage) => {
        const cards = stage.cards.map((card) => ({
          ...card,
          isSelected: isChecked,
        }));
        return {
          ...stage,
          cards,
          isChecked,
        };
      });

      state.boardData = { ...boardData, stages };
    },
    showReasonDialog: (state, { payload }) => {
      state.reasonsDialogData = [...state.reasonsDialogData, payload];
    },
    closeReasonDialog: (state) => {
      state.reasonsDialogData = [];
    },
    showReasonDisqualifiedDialog: (state, { payload }) => {
      state.reasonsDisqualifiedDialogData = [
        ...state.reasonsDisqualifiedDialogData,
        payload,
      ];
    },
    closeReasonDisqualifiedDialog: (state) => {
      state.reasonsDisqualifiedDialogData = [];
    },
    updateCommerceValue: (state, { payload }) => {
      if (
        state.pageConfig.viewVariant === VIEW_VARIANTS.BOARD &&
        isPipelineObject(state.model)
      ) {
        const { id, commerceValue, summary } = payload;
        const boardData = state.boardData;
        const stages = boardData.stages.map((stage) => {
          return stage.id === id
            ? {
                ...stage,
                commerceValue,
                ...(boardData.settings.summaryMetric ===
                  SUMMARY_VALUES.totalValue && { summary }),
              }
            : stage;
        });

        state.boardData = { ...boardData, stages };
      }
    },
    updateRecords: (state, { payload }) => {
      const { records } = payload;

      if (state.pageConfig.viewVariant === VIEW_VARIANTS.TABLE) {
        state.records = state.records.map((item) => {
          const updatedRecord = records.find((f) => f.id === item.id);

          if (updatedRecord) {
            return {
              ...item,
              fields: getFieldsForUI(updatedRecord.fields),
            };
          }
          return item;
        });
      }

      if (
        state.pageConfig.viewVariant === VIEW_VARIANTS.BOARD &&
        isPipelineObject(state.model)
      ) {
        state.boardData.stages = state.boardData.stages.map((stage) => ({
          ...stage,
          cards: stage.cards.map((card) => {
            const updatedRecord = records.find((f) => f.id === card.id);
            if (updatedRecord) {
              return {
                ...card,
                fields: getFieldsForUI(updatedRecord.fields),
              };
            }
            return card;
          }),
        }));
      }
    },
    archiveRecordFromBoard: (state, { payload }) => {
      const { recordId } = payload;

      let removedCount = 0;

      state.boardData.stages = state.boardData.stages.map((stage) => {
        const hasRemovedCard = stage.cards.some(({ id }) => id === recordId);

        if (hasRemovedCard) {
          removedCount++;
          return {
            ...stage,
            cards: stage.cards.filter(({ id }) => id !== recordId),
          };
        }

        return stage;
      });

      state.recordsCount -= removedCount;
      state.recordsCountAndNew -= removedCount;
    },
    updateBoardMetrics: (state, { payload }) => {
      const { boardStagesMetrics } = payload;

      state.boardData.stages = state.boardData.stages.map((stage) => {
        const updatedStage = boardStagesMetrics.find(
          ({ id }) => id === stage.id
        );
        return {
          ...stage,
          ...updatedStage,
        };
      });
    },
  },
});

export const { reducer } = recordsPageSlice;

export const getFullListOfCOSelector = createSelector(
  (state) => state.recordsPage.fullListOfCustomObjects,
  (fullListOfCustomObjects) => fullListOfCustomObjects
);
