import {
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useLayoutEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  GenericWizardBaseModal,
  GenericWizardModalBody,
  GenericWizardModalFooter,
  GenericWizardModalHeader,
} from 'components/GenericWizard';
import Button from 'components/Button';
import { ResizableSection } from '../common';
import subsections from './subsections';
import { createWizardState, createPayload, DEFAULT_STATE } from './utilities';
import GroupedButtonGrid from './GroupedButtonGrid';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import { useAsync } from 'react-use';
import styled from '@emotion/styled';
import { ConfirmationModal } from 'components/Charts/ScheduledActivities/ConfirmationModal';
import { useModalControl } from 'hooks/useModalControl';
import UUID from 'utility/UUID';
import { FORCE_ALL_RECORDS_SIZE } from 'services/helpers';
import CustomObjectsService from 'services/CustomObjectsService';
import { useSelector } from 'react-redux';
import FieldService from 'services/FieldService';
import { useCustomObjectWizard } from 'components/Wizards/CustomObject/CustomObjectWizardContext';
import { toastVariant, useToast } from 'components/ToastProvider';
import { BlockNamesWizard } from 'ts-components/RecordLayout/Wizard/BlockNamesWizard';
import {
  FieldSettingsWizard,
  namedToOption,
  setExcludedIncluded,
  useIntialiseFieldMeta,
} from 'ts-components/RecordLayout/Wizard/FieldSettingsWizard';

import {
  TimelineWizard,
  useIntialiseTimelineMeta,
} from 'ts-components/RecordLayout/Wizard/TimelineWizard';

import {
  TeamsActivitiesWizard,
  useIntialiseTeamActivitiesMeta,
} from 'ts-components/RecordLayout/Wizard/TeamActivitiesWizard';

import {
  useIntialiseRelatedPipelineMeta,
  useRelatedPipelineWizard,
} from 'ts-components/RecordLayout/Wizard/RelatedPipelineWizard';

import { blockInternalNames } from 'ts-components/RecordLayout/Builder/util';
import { StaticBlockBuilder } from 'ts-components/StaticBlockBuilder/StaticBlockBuilder';

import {
  RelatedContext,
  useRelatedContext,
} from './subsections/RelatedObjectFields/related-context';
import { isEqual } from 'lodash';
import { getBusinessClientObject } from 'store/authentication/selectors';
import { CustomContentWizard } from 'ts-components/RecordLayout/Wizard/CustomContentWizard';
import { Actions } from '../ActionBlockWizard/sections/Actions';
import { DisplayOptions } from '../ActionBlockWizard/sections/DisplayOptions';
import { CO_LAYOUT_ACTION_BLOCK_ACTIONS } from 'components/Wizards/CustomObject/utilities';

export const scrollOptions = {
  behavior: 'smooth',
  block: 'start',
};
const StyledButton = styled(Button)`
  padding-right: 0;
`;

const getStep = (id, layout) => {
  if (layout && id) {
    for (const row of layout) {
      for (const column of row.columns) {
        for (const item of column.items) {
          if (item.id === id) {
            return item;
          }
        }
      }
    }
  }
};

const getStepFromProps = (id, layout) => {
  const step = getStep(id, layout);

  if (step) {
    return {
      id: step.id,
      meta: step.metadata,
      type: step.type,
      visible: true,
    };
  }
};

const getDefaultActionBlock = (existing) => {
  if (existing && Object.keys(existing).length > 0) {
    return existing;
  }

  return {
    [CO_LAYOUT_ACTION_BLOCK_ACTIONS.ADD_NOTES]: true,
    [CO_LAYOUT_ACTION_BLOCK_ACTIONS.SEND_EMAILS]: true,
    [CO_LAYOUT_ACTION_BLOCK_ACTIONS.SCHEDULE_ACTIVITIES]: true,
    [CO_LAYOUT_ACTION_BLOCK_ACTIONS.LOG_ACTIVITIES]: true,
    [CO_LAYOUT_ACTION_BLOCK_ACTIONS.START_AUTOMATIONS]: true,
    [CO_LAYOUT_ACTION_BLOCK_ACTIONS.LOG_ACTIVITIES_LIST]: [],
    [CO_LAYOUT_ACTION_BLOCK_ACTIONS.SCHEDULE_ACTIVITIES_LIST]: [],
    [CO_LAYOUT_ACTION_BLOCK_ACTIONS.START_AUTOMATIONS_LIST]: [],
  };
};

const useRelatedObjectsSteps = ({
  formData,
  allowNoChildStep = true,
  tableHeaderPreviewComponent,
  showAllOptions,
  canUseRelatedPipelinesBlock,
  values,
  setValues,
  selectedObject,
  setSelectedObject,
  onLayoutComponentChange,
  step,
  isEdit,
  secondSubsection,
  disabled,
  dirtyRef,
  thirdSubsection,
  setDisabled,
  active = false,
}) => {
  const { t } = useTranslation();
  const [shortEditMode, setShortEditMode] = useState(Boolean(values));
  const [maxHeight, setMaxHeight] = useState(0);
  const settingsRef = useRef();
  const editAnchorRef = useRef();
  const actionsRef = useRef();
  const access = useSelector((s) => s.authentication.access);
  const { isContact: isWizardContactContext } = useCustomObjectWizard();
  const clientObject = useSelector(getBusinessClientObject);

  const scroll = () => {
    setTimeout(() => {
      if (settingsRef.current) {
        settingsRef.current?.scrollIntoView?.(scrollOptions);
      }
    }, 0);
  };

  useUpdateEffect(() => {
    // after first update
    if (values.type && active) {
      scroll();
    }
  }, [values.type, active]);

  useEffect(() => {
    // for edit
    if (values.type && step && active) {
      // small delay for belated components
      setTimeout(() => {
        if (editAnchorRef.current) {
          editAnchorRef.current.scrollIntoView(scrollOptions);
        }
      }, 0);
    }
  }, [values.type, step, active]);

  const cleanObjects = useMemo(
    () => (values?.objects || []).map(({ restricted, ...rest }) => rest),
    [values?.objects]
  );
  const objectRef = useRef(cleanObjects);

  useEffect(() => {
    if (!isEqual(objectRef.current, cleanObjects)) {
      if ((active && cleanObjects.length > objectRef.current?.length) || 0) {
        const newObjects = cleanObjects.filter(
          ({ id }) => !objectRef.current.map(({ id }) => id).includes(id)
        );

        if (newObjects.length) {
          const fields = newObjects.reduce((acc, object) => {
            return {
              ...acc,
              [object.id]: [],
            };
          }, {});

          setValues((prev) => ({
            ...prev,
            fields: {
              ...prev.fields,
              ...fields,
            },
          }));
          setSelectedObject(newObjects[newObjects.length - 1]);
        }
      }

      objectRef.current = cleanObjects;
    }
  }, [cleanObjects, active, setValues, setSelectedObject]);

  const { loading: loadingFields, value: mainObjectFields = [] } =
    useAsync(async () => {
      if (active) {
        return FieldService.getFields({
          for: isWizardContactContext
            ? 'contacts'
            : {
                ...formData,
                objectClass: 'custom_objects',
              },
          settingsRequest: true,
        });
      }
    }, [formData, isWizardContactContext, active]);

  const { loading, value: allObjects = [] } = useAsync(async () => {
    if (active) {
      const data = await CustomObjectsService.getCustomObjectList({
        size: FORCE_ALL_RECORDS_SIZE,
        customOnly: false,
      });
      return data.results
        .filter((object) => {
          const objectAccess =
            access.custom_objects?.custom_object_entities ?? {};
          const contactAccess =
            access.sections?.contacts_section?.view ?? false;
          if (object.id === clientObject.id) {
            return contactAccess;
          }
          return objectAccess[object.id]?.enabled;
        })
        .map(({ fieldId, objectName, ...rest }) => ({
          label: objectName,
          id: fieldId,
          sortable: false,
          ...rest,
        }))
        .sort(({ label: a }, { label: b }) => a.localeCompare(b));
    }
  }, [active]);

  const onDeletionConfirmed = useCallback(
    (id) => {
      if (selectedObject?.id === id) {
        setSelectedObject(null);
      }
    },
    [selectedObject, setSelectedObject]
  );

  useEffect(() => {
    if (values?.objects?.length === 0) {
      setSelectedObject(null);
    }
  }, [values?.objects, setSelectedObject]);

  const handleChangeShortMode = useCallback((data) => {
    setShortEditMode(false);
    setMaxHeight(data);
    setTimeout(() => {
      if (actionsRef.current) {
        actionsRef.current.scrollIntoView({
          top: 0,
          behavior: 'smooth',
        });
      }
    }, 0);
  }, []);

  return [
    !isEdit ? (
      <ResizableSection
        ref={actionsRef}
        header={t('Choose Layout Component')}
        isActive
        isShortMode={shortEditMode}
        isEdit={isEdit}
        scroll={scroll}
        maxHeight={maxHeight}
      >
        <GroupedButtonGrid
          value={values.type}
          onChange={onLayoutComponentChange}
          isShortMode={shortEditMode}
          setShortEditMode={handleChangeShortMode}
          isEdit={isEdit}
          allowNoChildStep={allowNoChildStep}
          showAllOptions={showAllOptions && !isEdit}
          canUseRelatedPipelinesBlock={canUseRelatedPipelinesBlock}
        />
      </ResizableSection>
    ) : null,
    values.type === 'related_object_fields' ? (
      <ResizableSection
        ref={settingsRef}
        header={t('Choose Objects to Display in Table')}
        isActive={!!secondSubsection}
      >
        {/* anchor for update */}
        <div className="ego-plink" ref={editAnchorRef} />
        {secondSubsection && (
          <secondSubsection.Component
            values={values}
            onChange={setValues}
            disabled={disabled}
            loading={loading || loadingFields}
            allObjects={allObjects}
            dirtyRef={dirtyRef}
            relatedObjects={formData.relatedObjects}
            headerPreviewComponent={tableHeaderPreviewComponent}
            onDeletionConfirmed={onDeletionConfirmed}
            mainObjectFields={mainObjectFields}
          />
        )}
      </ResizableSection>
    ) : null,
    thirdSubsection && values.objects?.length && !loading && !loadingFields ? (
      <ResizableSection
        ref={settingsRef}
        header={t('Modify Object/Tab Settings')}
        isActive
      >
        <thirdSubsection.Component
          {...values}
          onChange={setValues}
          disabled={disabled}
          setDisabled={setDisabled}
          dirtyRef={dirtyRef}
          mainObjectFields={mainObjectFields}
        />
      </ResizableSection>
    ) : null,
  ].filter(Boolean);
};

function LayoutComponentWizardCore({
  onHide,
  id,
  onConfirm,
  formData,
  allowNoChildStep = true,
  setDeleteConfirmation,
  saveSingleComponent,
  layout,
  tableHeaderPreviewComponent,
  showAllOptions,
  canUseRelatedPipelinesBlock,
  existing,
  existingDisplayName,
  existingInternalName,
  enableAdditionalWizards = false,
  categorizedFields = [],
  objectId,
  objectName,
  objectType,
  ...props
}) {
  const dirtyRef = useRef(false);
  const [confirmDiscardOpen, { showModal, hideModal }] = useModalControl();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [internalName, setInternalName] = useState(existingInternalName ?? '');
  const [displayName, setDisplayName] = useState(existingDisplayName ?? '');
  const [showBuilder, setShowBuilder] = useState(false);
  const [showToast] = useToast();

  const allCategoryOptions = useMemo(
    () => categorizedFields.map(namedToOption),
    [categorizedFields]
  );

  const { selectedObject, setSelectedObject } = useRelatedContext();
  const step = useMemo(() => getStepFromProps(id, layout), [id, layout]);

  const isEdit = Boolean(step);

  const [values, setValues] = useState(
    () => existing ?? createWizardState(step)
  );

  const secondSubsection = subsections[values?.type]?.second;
  const valid = secondSubsection && secondSubsection.validate(values);
  const thirdSubsection = subsections[values?.type]?.third;
  const thirdValid = thirdSubsection && thirdSubsection.validate(values);
  const blockType = values.type;

  const isOneStep = values.type && values.type !== 'related_object_fields';

  const handleHide = useCallback(() => {
    if (dirtyRef.current) {
      showModal();
    } else {
      setSelectedObject(null);
      setValues({ ...DEFAULT_STATE, id: UUID.generate() });
      dirtyRef.current = false;
      onHide();
    }
  }, [showModal, onHide, setValues, setSelectedObject]);

  const handleConfirmation = useCallback(() => {
    hideModal();
    dirtyRef.current = false;
    setSelectedObject(null);
    setValues({ ...DEFAULT_STATE, id: UUID.generate() });
    onHide();
  }, [hideModal, onHide, setValues, setSelectedObject]);

  const onLayoutComponentChange = useCallback(({ value }) => {
    setValues((prev) => ({ ...prev, type: value }));

    if (value === 'custom_content') {
      setShowBuilder(true);
    }
  }, []);

  const children = useRelatedObjectsSteps({
    formData,
    allowNoChildStep,
    tableHeaderPreviewComponent,
    showAllOptions,
    canUseRelatedPipelinesBlock,
    values,
    setValues,
    selectedObject,
    setSelectedObject,
    onLayoutComponentChange,
    step,
    isEdit,
    secondSubsection,
    disabled,
    dirtyRef,
    thirdSubsection,
    setDisabled,
    active: blockType === 'related_object_fields',
  });

  const handleChangeDisplayName = useCallback((value) => {
    setDisplayName(value);
    dirtyRef.current = true;
  }, []);

  const handleChangeInternalName = useCallback((value) => {
    setInternalName(value);
    dirtyRef.current = true;
  }, []);

  const handleChangeMetadata = useCallback(
    (meta, dirty = true) => {
      setValues((prev) => ({ ...prev, meta }));
      dirtyRef.current = dirty;
    },
    [setValues]
  );

  const fieldsMetadata = useIntialiseFieldMeta(
    values.meta,
    allCategoryOptions,
    blockType,
    !!existing
  );

  const { timelineMetadata, timelineEventTypes, timelineEventsLoading } =
    useIntialiseTimelineMeta(values.meta, blockType);

  const { relatedpipelineMetadata, pipelineOptions, relatedpipelineValid } =
    useIntialiseRelatedPipelineMeta(values.meta, blockType, formData);

  const { teamActivitiesMetadata, isTeamActivitiesMetadataLoading } =
    useIntialiseTeamActivitiesMeta(values, blockType);

  const disableSave = useMemo(() => {
    return (
      ((!valid || disabled || !thirdValid) && !isOneStep) ||
      (blockType === 'related_pipelines' && !relatedpipelineValid)
    );
  }, [valid, disabled, thirdValid, isOneStep, relatedpipelineValid, blockType]);

  const { relatedPipelineChildren, buildQueryBody } = useRelatedPipelineWizard({
    type: blockType,
    metadata: relatedpipelineMetadata,
    setMetadata: handleChangeMetadata,
    isActive: Boolean(values?.type),
    formData: formData,
    pipelineOptions: pipelineOptions,
    enableAdditionalWizards,
    disabled,
    setDisabled,
  });

  const internalNamePlaceholder = useMemo(() => {
    const plainPlaceholder = blockInternalNames[blockType]?.(t) ?? '';

    return plainPlaceholder;
  }, [t, blockType]);

  const [stagedChanges, setStagedChanges] = useState();
  const handleStageChanges = useCallback((overrideMeta, overrideName) => {
    setStagedChanges({ overrideMeta, overrideName });
  }, []);

  const handleSave = useCallback(
    (overrideMeta, overrideName) => {
      dirtyRef.current = false;
      let newValues = {
        ...values,
        meta: { ...values.meta, ...overrideMeta },
      };
      if (blockType === 'fields') {
        const { excluded, included } = setExcludedIncluded(
          allCategoryOptions,
          fieldsMetadata.chosenCategories,
          fieldsMetadata.autoInclude
        );
        newValues = {
          ...values,
          meta: { ...fieldsMetadata, excluded, included },
        };
      } else if (blockType === 'timeline') {
        newValues = {
          ...values,
          meta: { ...timelineMetadata },
        };
      } else if (blockType === 'action_block') {
        newValues = {
          ...values,
          meta: { ...values.meta },
        };
      } else if (blockType === 'team_and_activities') {
        newValues = {
          ...values,
          meta: { ...values.meta },
        };
      }
      if (blockType === 'related_pipelines') {
        const queryBody = buildQueryBody();
        newValues = {
          ...values,
          meta: { ...relatedpipelineMetadata, queryBody },
        };
      }
      if (blockType === 'related_object_fields') {
        saveSingleComponent?.(
          createPayload(newValues, step),
          internalName,
          displayName
        );
      } else {
        saveSingleComponent?.(
          newValues,
          overrideName || internalName,
          displayName
        );
      }
      setValues({ ...DEFAULT_STATE, id: UUID.generate() });
      setSelectedObject(null);
      onConfirm();
    },
    [
      values,
      blockType,
      fieldsMetadata,
      timelineMetadata,
      internalName,
      displayName,
      saveSingleComponent,
      step,
      onConfirm,
      setSelectedObject,
      allCategoryOptions,
      buildQueryBody,
      relatedpipelineMetadata,
    ]
  );

  useLayoutEffect(() => {
    if (blockType === 'custom_content' && !showBuilder && isEdit) {
      setShowBuilder(true);
    }
  }, [blockType, showBuilder, isEdit]);

  const { isContact } = useCustomObjectWizard();

  const valuesForActionBlock = useMemo(() => {
    return { meta: getDefaultActionBlock(values?.meta) };
  }, [values]);

  const setValuesForActionBlock = useCallback(
    (v) => {
      let res = v;
      if (typeof v === 'function') {
        res = v(valuesForActionBlock);
      }
      handleChangeMetadata(res.meta);
    },
    [valuesForActionBlock, handleChangeMetadata]
  );

  return (
    <GenericWizardBaseModal
      onHide={handleHide}
      size="large"
      {...props}
      data-qa="layout-component-wizard"
      fitContent={showAllOptions ? 'y' : false}
    >
      <GenericWizardModalHeader onClickClose={handleHide}>
        {isEdit &&
          `${t('Edit')} ${blockInternalNames[blockType]?.(t)}${
            existingInternalName ? ` - ${existingInternalName}` : ''
          }`}
        {!isEdit && t('Add Layout Component')}
      </GenericWizardModalHeader>
      <GenericWizardModalBody>
        {children}
        {relatedPipelineChildren}
        {enableAdditionalWizards && blockType === 'fields' ? (
          <FieldSettingsWizard
            type={blockType}
            metadata={fieldsMetadata}
            setMetadata={handleChangeMetadata}
            isActive={Boolean(values?.type)}
            allCategoryOptions={allCategoryOptions}
          />
        ) : null}
        {enableAdditionalWizards && blockType === 'team_and_activities' ? (
          <TeamsActivitiesWizard
            isActive
            setMetadata={handleChangeMetadata}
            metadata={teamActivitiesMetadata.meta}
            metadataLoading={isTeamActivitiesMetadataLoading}
            isEdit={isEdit}
          />
        ) : null}
        {enableAdditionalWizards && blockType === 'timeline' ? (
          <TimelineWizard
            type={blockType}
            metadata={timelineMetadata}
            setMetadata={handleChangeMetadata}
            isActive={Boolean(values?.type)}
            timelineEventTypes={timelineEventTypes}
            timelineEventsLoading={timelineEventsLoading}
          />
        ) : null}
        {enableAdditionalWizards && blockType === 'custom_content' ? (
          <CustomContentWizard
            type={blockType}
            metadata={timelineMetadata}
            setMetadata={handleChangeMetadata}
            isActive={Boolean(values?.type)}
            handleShowBuilder={() => setShowBuilder(true)}
          />
        ) : null}
        {/* The step needs to be a direct child, so we have one conditional per step for the action block */}
        {enableAdditionalWizards && blockType === 'action_block' ? (
          <ResizableSection
            header={t('Choose Actions to Display in Block')}
            isActive
          >
            <Actions
              values={valuesForActionBlock}
              setValues={setValuesForActionBlock}
              isContact={isContact}
            />
          </ResizableSection>
        ) : null}
        {/* The step needs to be a direct child, so we have one conditional per step for the action block */}
        {enableAdditionalWizards && blockType === 'action_block' ? (
          <ResizableSection
            header={t('Activity and Automation Display Options')}
            isActive
          >
            <DisplayOptions
              values={valuesForActionBlock}
              setValues={setValuesForActionBlock}
              isContact={isContact}
              customObjectId={formData.id}
            />
          </ResizableSection>
        ) : null}
        {enableAdditionalWizards ? (
          <BlockNamesWizard
            type={blockType}
            internalName={internalName}
            setInternalName={handleChangeInternalName}
            displayName={displayName}
            setDisplayName={handleChangeDisplayName}
            internalNamePlaceholder={internalNamePlaceholder}
            isActive
            hasChosenType={Boolean(values?.type)}
          />
        ) : null}
      </GenericWizardModalBody>
      <GenericWizardModalFooter>
        {isEdit ? (
          <StyledButton
            onClick={() => setDeleteConfirmation({ id })}
            variant="text"
            color="red"
            data-qa-action="close"
          >
            {t('Delete')}
          </StyledButton>
        ) : null}
        <Button
          onClick={handleHide}
          variant="text"
          color="blue"
          data-qa-action="close"
        >
          {t('Cancel')}
        </Button>
        <Button
          disabled={disableSave}
          data-qa-action="save"
          onClick={() => handleSave()}
        >
          {t('Save')}
        </Button>
        {showBuilder && (
          <StaticBlockBuilder
            objectId={objectId}
            objectName={objectName}
            objectType={objectType}
            modalLayer={2}
            javascriptActions={formData.browserJsActions}
            onCancel={() => {
              if (stagedChanges) {
                handleSave(
                  stagedChanges.overrideMeta,
                  stagedChanges.overrideName
                );
              }
              setStagedChanges(undefined);
              onHide();
              setShowBuilder(false);
            }}
            onSave={(data, internalName) => {
              dirtyRef.current = true;
              handleStageChanges({ blockJson: data }, internalName, false);
              showToast({
                message: t('Custom content was successfully saved'),
                variant: toastVariant.SUCCESS,
              });
            }}
            onSaveAndClose={(data, internalName) => {
              dirtyRef.current = true;
              handleSave({ blockJson: data }, internalName);
              setShowBuilder(false);
              showToast({
                message: t('Custom content was successfully saved'),
                variant: toastVariant.SUCCESS,
              });
              onHide();
            }}
            existing={existing?.blockJson}
            existingInternalName={internalName}
          />
        )}
        {confirmDiscardOpen && (
          <ConfirmationModal
            show
            heading={t('You Have Unsaved Changes')}
            buttonText={t('Discard Changes')}
            actionBtnColor="red"
            onHide={hideModal}
            onConfirm={handleConfirmation}
          >
            {t('Unsaved changes will be lost, would you like to continue?')}
          </ConfirmationModal>
        )}
      </GenericWizardModalFooter>
    </GenericWizardBaseModal>
  );
}

const LayoutComponentWizard = (props) => {
  return (
    <RelatedContext>
      <LayoutComponentWizardCore {...props} />
    </RelatedContext>
  );
};

export default LayoutComponentWizard;
