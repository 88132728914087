import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Axios from 'axios';
import { DROPDOWN_SHOULD_LOAD } from 'utility/constants';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useDebounce from 'react-use/lib/useDebounce';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { omit } from 'lodash';

import { useFlashTransition } from 'hooks/useFlashState';
import FieldService from 'services/FieldService';
import { namedToOption } from 'services/helpers';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import { multiSelectSortValue } from '../MultiSelect';
import { useInfinityScroll } from 'hooks/useInfinityScroll';
import {
  FIELDS,
  RELATION_FIELD,
  TEAM_MEMBERS,
  LEAD_SOURCES,
  TOOLBAR_TEMPLATES,
  ACTIVITIES,
  ROLES,
  AUTOMATIONS,
} from 'queries/query-keys';
import TeamMemberService from 'services/TeamMemberService';
import ActivityService from 'services/ActivityService';
import LoggableActivityService from 'services/LoggableActivityService';
import Automation2Service from 'services/Automation2Service';
import LoadingMessage from 'hooks/useTeamMemberTypeahead/components/LoadingMessage';
import useField from 'hooks/useField';
import { getBusinessClientObject } from 'store/authentication/selectors';
import { useSelector } from 'react-redux';

const RESET = Symbol('reset');

export const Entities = {
  RelatedObjects: 'relatedObjects',
  TeamMember: 'teamMember',
  Role: 'role',
  DynamicTags: 'dynamicTags',
  LeadSource: 'leadSource',
  ToolbarTemplates: 'toolbarTemplates',
  Activities: 'activities',
  ActivitiesLoggable: 'activitiesLoggable',
  FieldOptions: 'fieldOptions',
  Automations: 'automations',
  AutomationsWithFilter: 'automationsWithFilter',
};

export const useInfinityFetch = ({
  fetch,
  enabled,
  onLoaded,
  queryKey,
  params,
  keepPreviousData = true,
}) => {
  return useInfiniteQuery(
    [...queryKey, params],
    async ({ pageParam }) => {
      const data = await fetch({ page: pageParam, ...params });
      onLoaded?.();
      return data;
    },
    {
      keepPreviousData,
      getNextPageParam: (lastPage, pages) => {
        return lastPage?.next
          ? new URL(lastPage.next).searchParams.get('page')
          : undefined;
      },
      getPreviousPageParam: (previousPage) => {
        return previousPage?.prev
          ? new URL(previousPage.prev).searchParams.get('page')
          : undefined;
      },
      enabled,
    }
  );
};

export function useSelectTypeahead({
  fetch,
  alwaysOpen,
  stayOpenOnBlur = false,
  clearSearchOnBlur = true,
  // Funcs need to be referentially stable
  objectToOption = namedToOption, // Translates full entity objects to options format where value is usually an entity id
  onLoaded = null,
  onFocus,
}) {
  const [search, setSearch] = useState('');
  const [force, setForce] = useState(0);
  const cancellation = useRef(null);
  const updatedOnce = useRef(false);
  const fetchOptions = useRef();
  const { t } = useTranslation();
  const clientObject = useSelector(getBusinessClientObject);

  const [
    { loading: loadingOptions, value: options = DROPDOWN_SHOULD_LOAD },
    unstableFetchOptions,
  ] = useAsyncFn(
    async (srch) => {
      if (srch === RESET || !clientObject) {
        return undefined;
      }

      if (cancellation.current) {
        cancellation.current.cancel();
      }
      cancellation.current = Axios.CancelToken.source();
      const items = await fetch(
        { search: srch },
        { cancelToken: cancellation.current.token },
        clientObject
      );

      onLoaded?.();
      return items.map(objectToOption);
    },
    [fetch, objectToOption, clientObject]
  );

  // we don't want to trigger a search request if fetchOptions changes, just when the user changes their search
  // (or technically when we change force or alwaysOpen)
  fetchOptions.current = unstableFetchOptions;
  useDebounce(
    () => {
      if (alwaysOpen || updatedOnce.current || force) {
        fetchOptions.current(search);
      }
      // Flipped to true on first render, prevents searching on mount
      updatedOnce.current = !!clientObject;
    },
    300,
    [search, force, alwaysOpen, clientObject]
  );

  // Provide default action so consumer can call manually without specifying
  // e.g. to clear the input. action is an implementation detail of react-select
  const handleSearchChange = (newSearch, { action } = {}) => {
    if (action !== 'input-blur' && action !== 'menu-close') {
      setSearch(newSearch);
    }
    if (action === 'input-blur') {
      if (!(alwaysOpen || stayOpenOnBlur)) {
        // reset options on blur so if user then reopens the input
        // they see the loading options message, not a flash of their previously
        // loaded options, still in state, THEN the loading options message
        // No reset on menu-close as for single-selects, menu-close happens
        // on selection i.e. the input is still focused
        fetchOptions.current(RESET);
      }
      if (clearSearchOnBlur) {
        // clear the search text on blur so the prevoius value shows
        setSearch('');
      }
    }
  };

  const handleFocus = (...data) => {
    if (!(alwaysOpen || (stayOpenOnBlur && options !== DROPDOWN_SHOULD_LOAD))) {
      // force fetching of options, even if search hasn't changed,
      // skip if alwaysOpen or stayOpenOnBlur and no options, so loads on first focus only
      // to counteract resetting the options on blur (see handleSearchChange)
      setForce((f) => f + 1);
      onFocus?.(...data);
    }
  };

  const handleForceSearch = () => {
    setSearch('');
  };

  return [
    {
      inputValue: search,
      onFocus: handleFocus,
      noOptionsMessage:
        loadingOptions || !Array.isArray(options)
          ? () => t('Loading Options')
          : () => t('No Options'),
      onInputChange: handleSearchChange,
      options,
      // using typeahead we have to disable list filtering cuz
      // it makes double list filtering
      filterOption: () => true,
    },
    {
      loading: loadingOptions,
      onOptionsReady: Array.isArray(options) && !loadingOptions,
    },
    {
      forceSearch: handleForceSearch,
    },
  ];
}

export function useSelectTypeaheadWithScroll({
  enabled = true,
  fetch,
  entity = Entities.RelatedObjects,
  config = {},
  objectToOption = namedToOption, // Translates full entity objects to options format where value is usually an entity id
  onLoaded = null,
  onFocus,
  onMenuOpen,
  onMenuClose,
  selectRef,
  fieldId,
  chosenValueIds = [],
  alwaysOpen = false,
  onResultsChange,
  filterOption,
  params,
  relatedObjectId = '',
  keepPreviousData = true,
  shouldResetOptions = true,
  shouldRefetchOnMount = false,
  skipErrorBoundary,
}) {
  const {
    allowEmpty = true,
    stayOpenOnBlur = false,
    clearSearchOnBlur = true,
    preserveSearch = false,
  } = config;
  const queryParams = omit(params, ['page_size', 'ordering']);
  const [search, setSearch] = useState('');
  const [wrapperElement, setWrapperElement] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useField(alwaysOpen);
  const { t } = useTranslation();
  const [debouncedValue, setDebouncedValue] = useState('');
  const entityQueryInfo = useMemo(() => {
    switch (entity) {
      case Entities.TeamMember: {
        return {
          queryKey: [...TEAM_MEMBERS.LIST, 'typeahead'],
          fetch: (args) =>
            TeamMemberService.getTeamMemberTypeahead(
              {
                ordering: 'first_name',
                page_size: 20,
                ...args,
              },
              {
                skipErrorBoundary,
              }
            ),
        };
      }
      case Entities.Role: {
        return {
          queryKey: [...ROLES.ALL, 'typeahead'],
          fetch: (args) =>
            TeamMemberService.getRolesTypeahead(
              {
                ordering: 'name',
                page_size: 20,
                ...args,
              },
              {
                skipErrorBoundary,
              }
            ),
        };
      }
      case Entities.RelatedObjects: {
        return {
          queryKey: [
            ...RELATION_FIELD.RELATED_OBJECTS(relatedObjectId),
            fieldId,
          ],
          fetch,
        };
      }
      case Entities.DynamicTags: {
        return {
          queryKey: FIELDS.DYNAMIC_TAGS_TYPEAHEAD(fieldId),
          fetch,
        };
      }
      case Entities.LeadSource: {
        return {
          queryKey: LEAD_SOURCES.TYPE_AHEAD(fieldId),
          fetch,
        };
      }
      case Entities.ToolbarTemplates: {
        return {
          queryKey: TOOLBAR_TEMPLATES.LIST,
          fetch,
        };
      }
      case Entities.ActivitiesLoggable: {
        return {
          queryKey: ACTIVITIES.ACTIVITY(fieldId),
          fetch: (params) =>
            LoggableActivityService.getActivities({
              params: {
                ordering: 'name',
                page_size: 20,
                custom_object_id: fieldId,
                ...params,
              },
              skipErrorBoundary,
            }),
        };
      }
      case Entities.Activities: {
        return {
          queryKey: ACTIVITIES.ACTIVITY(fieldId),
          fetch: (params) =>
            ActivityService.v2GetActivities({
              params: {
                ordering: 'name',
                page_size: 20,
                custom_object_id: fieldId,
                ...params,
              },
              skipErrorBoundary,
            }),
        };
      }
      case Entities.FieldOptions: {
        return {
          queryKey: FIELDS.FACETS(fieldId),
          fetch,
        };
      }
      case Entities.Automations: {
        return {
          queryKey: AUTOMATIONS.TYPEAHEAD,
          fetch: (params) =>
            Automation2Service.getAutomationsTypeahead(
              {
                ordering: 'name',
                page_size: 20,
                ...params,
              },
              {
                skipErrorBoundary,
              }
            ),
        };
      }
      case Entities.AutomationsWithFilter: {
        return {
          queryKey: AUTOMATIONS.TYPEAHEAD_FILTERED,
          fetch: ({ ids, search, custom_object_id, active, page, ...params }) =>
            Automation2Service.search(
              {
                ordering: 'name',
                page: { size: 20, number: page ?? 1 },
                criteria: {
                  and: null,
                  query: [
                    {
                      and: true,
                      filters: [
                        search && {
                          type: 'automation2',
                          subtype: 'name',
                          condition: 'contains',
                          value: search,
                        },
                        custom_object_id && {
                          type: 'automation2',
                          subtype: 'custom_object_id',
                          condition: '=',
                          value: custom_object_id,
                        },
                        ids?.length && {
                          type: 'automation2',
                          subtype: 'automation_id',
                          condition: 'is_any_of',
                          value: ids,
                        },
                        typeof active === 'boolean' && {
                          type: 'automation2',
                          subtype: 'status',
                          condition: '=',
                          value: active ? 'active' : 'inactive',
                        },
                      ].filter(Boolean),
                    },
                  ],
                },
                ...params,
              },
              {
                skipErrorBoundary,
              }
            ),
        };
      }
      default: {
        return {};
      }
    }
  }, [entity, fetch, fieldId, relatedObjectId, skipErrorBoundary]);

  useDebounce(
    () => {
      setDebouncedValue(search);
    },
    300,
    [search]
  );

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    refetch,
    isIdle,
  } = useInfinityFetch({
    enabled: Boolean(wrapperElement),
    onLoaded,
    params: {
      ...queryParams,
      search: debouncedValue,
    },
    ...entityQueryInfo,
    keepPreviousData,
  });

  const {
    visibleItems: options,
    handleItemMounted,
    horizonList,
  } = useInfinityScroll({
    enabled,
    wrapperElement: enabled ? wrapperElement : null,
    data,
    fetchNextPage,
    hasNextPage: enabled && hasNextPage,
    isFetchingNextPage,
    objectToOption,
    chosenItems: chosenValueIds,
    defaultHeight: 32,
    itemsBuffer: 10,
  });

  // https://tanstack.com/query/v4/docs/react/guides/infinite-queries#what-if-i-want-to-manually-update-the-infinite-query
  const queryKey = useMemo(() => {
    return [
      ...entityQueryInfo.queryKey,
      {
        ...queryParams,
        search: debouncedValue,
      },
    ];
  }, [entityQueryInfo.queryKey, queryParams, debouncedValue]);

  const queryClient = useQueryClient();
  const resetOptions = useCallback(() => {
    if (options.length && shouldResetOptions) {
      // we clear all accept 1st page on unmount
      queryClient.setQueryData(queryKey, (data) => ({
        pages: (data?.pages || []).slice(0, 1),
        pageParams: (data?.pageParams || []).slice(0, 1),
      }));
    }
  }, [queryClient, queryKey, options, shouldResetOptions]);

  useEffect(() => {
    onResultsChange?.();
  }, [options, onResultsChange]);

  const [refetched, setRefetched] = useState(false);

  useEffect(() => {
    if (shouldRefetchOnMount) {
      refetch().finally(() => setRefetched(true));
    }
  }, [shouldRefetchOnMount, refetch]);

  // Provide default action so consumer can call manually without specifying
  // e.g. to clear the input. action is an implementation detail of react-select
  const handleSearchChange = (newSearch, { action } = {}) => {
    switch (entity) {
      case Entities.LeadSource:
      case Entities.Role:
      case Entities.TeamMember: {
        // We don't want to clear the search when the user selects a value
        if (action === 'set-value' && preserveSearch) {
          return;
        }
        setSearch(newSearch);
        return;
      }
      case Entities.FieldOptions:
      case Entities.ToolbarTemplates:
      case Entities.DynamicTags:
      case Entities.Activities:
      case Entities.ActivitiesLoggable:
      case Entities.RelatedObjects:
      case Entities.Automations:
      case Entities.AutomationsWithFilter: {
        if (action !== 'input-blur' && action !== 'menu-close') {
          setSearch(newSearch);
        }
        if (action === 'input-blur') {
          if (clearSearchOnBlur) {
            // clear the search text on blur so the prevoius value shows
            setSearch('');
          }
        }
        return;
      }
      default: {
        return;
      }
    }
  };

  const handleFocus = (...data) => {
    if (!(alwaysOpen || (stayOpenOnBlur && options !== DROPDOWN_SHOULD_LOAD))) {
      // force fetching of options, even if search hasn't changed,
      // skip if alwaysOpen or stayOpenOnBlur and no options, so loads on first focus only
      // to counteract resetting the options on blur (see handleSearchChange)
      onFocus?.(...data);
    }
  };

  const handleForceSearch = () => {
    setSearch('');
  };

  const handleMenuOpen = () => {
    onMenuOpen?.();
    setMenuIsOpen(true);
  };

  const handleMenuClose = () => {
    if (!alwaysOpen) {
      setWrapperElement(null);
      setMenuIsOpen(false);
    }
    onMenuClose?.();
    resetOptions();
  };

  if (menuIsOpen) {
    setTimeout(() => setWrapperElement(selectRef.current?.select?.menuListRef));
  }

  const getSelectProps = () => {
    const props = {
      inputValue: search,
      onInputChange: handleSearchChange,
      hasInfinityScroll: true,
      options:
        shouldRefetchOnMount && !refetched
          ? DROPDOWN_SHOULD_LOAD
          : options.map(({ item }) => item),
      filterOption: filterOption || (() => true),
      onMountOption: handleItemMounted,
      onMenuOpen: handleMenuOpen,
      onMenuClose: handleMenuClose,
    };

    switch (entity) {
      case Entities.FieldOptions:
      case Entities.ToolbarTemplates:
      case Entities.DynamicTags:
      case Entities.RelatedObjects:
      case Entities.Automations: {
        return {
          ...props,
          onFocus: handleFocus,
          noOptionsMessage: () => {
            if ((isLoading || isIdle) && !options.length) {
              return t('Loading Options');
            }
            return t('No Options');
          },
        };
      }
      case Entities.AutomationsWithFilter: {
        return {
          ...props,
          onFocus: handleFocus,
          loadItems:
            isFetching && (alwaysOpen || selectRef?.current?.state.menuIsOpen),
        };
      }
      case Entities.Role:
      case Entities.TeamMember: {
        return {
          ...props,
          noOptionsMessage: () => {
            if ((isLoading || isIdle) && !options.length) {
              return t('Loading Options');
            }
            if (!search && !allowEmpty) {
              return t('Begin Typing to Search');
            }
            return t('No Options');
          },
          components: {
            LoadingMessage,
            LoadingIndicator: null,
          },
          classNamePrefix: 'team-member-typeahead',
          menuTopButton: null,
          isLoading: isFetching && selectRef?.current?.state.menuIsOpen,
        };
      }
      case Entities.ActivitiesLoggable:
      case Entities.Activities: {
        return {
          ...props,
          noOptionsMessage: () => {
            if ((isLoading || isIdle) && !options.length) {
              return t('Loading Options');
            }
            return t('No Options');
          },
          isLoading: isFetching && selectRef?.current?.state.menuIsOpen,
        };
      }
      default: {
        return props;
      }
    }
  };

  return [
    getSelectProps(),
    {
      loading: isFetching && selectRef?.current?.state.menuIsOpen,
      onOptionsReady: Array.isArray(options) && !isLoading,
      allOptions: horizonList,
    },
    {
      forceSearch: handleForceSearch,
      queryKey,
      refetch,
    },
  ];
}

export function useRelationshipSelect({
  field,
  value,
  onChange,
  isMulti = false,
}) {
  const { t } = useTranslation();
  const preReleaseFeatures = usePreReleaseFeatures();
  // We memoize here so returned functions in relationship are referentially stable, making this
  // hopefully easier to compose with other hooks e.g. useSelectTypeahead, which expects input functions
  // to be stable to prevent rendering loops (generally; I'm sure will vary by context)
  const relationship = useMemo(
    () => FieldService.getRelationshipInfo(field, t, preReleaseFeatures),
    [field, t, preReleaseFeatures]
  );
  const selectedMappers = useMemo(
    () => FieldService.getSelectedRelationMappers(field),
    [field]
  );

  const handleChange = useCallback(
    (nextSelection) => {
      // translate selection back to format as originally received from the API
      // so updated value arrives back here in that (expected) format
      const nextValue = isMulti
        ? nextSelection.map(selectedMappers.toValue)
        : selectedMappers.toValue(nextSelection);
      return onChange(nextValue);
    },
    [isMulti, onChange, selectedMappers]
  );

  // return the value tagged with init: true so we can distinguish between user-initiated
  const handleInitChange = useCallback(
    (nextSelection) => {
      // translate selection back to format as originally received from the API
      // so updated value arrives back here in that (expected) format
      const nextValue = isMulti
        ? nextSelection.map(selectedMappers.toValue)
        : selectedMappers.toValue(nextSelection);
      return onChange({ init: true, nextValue });
    },
    [isMulti, onChange, selectedMappers]
  );

  const displayValue = useMemo(() => {
    if (value?.count) {
      return value;
    }
    if (isMulti && value) {
      return multiSelectSortValue(value.map(selectedMappers.toOption));
    }

    return value && selectedMappers.toOption(value);
  }, [isMulti, selectedMappers, value]);
  return [
    {
      displayValue,
      handleChange,
      handleInitChange,
      relationship,
    },
  ];
}

// Responsible for 2 things
// - Internalizes a tricky implementation detail of react-select (see event propagation)
// to hopefully simplify making Enter'ing in the search input select whichever dropdown option the user has highlighted,
// if any. This appears to be the default behavior when no onKeyDown handler is provided, but led to some
// unexpected / overwritten results when we started attaching custom logic to onKeyDown
// - Allows the consumer to do something in response to Enter'ing if the Select's current search
// term hasn't matched any results
export function useSelectEnter({
  handlePressEnter,
  loadingOptions = false,
  options,
  search,
  uncontrolledSearch = false,
}) {
  const handleKeyDown = (ev) => {
    // pressed Enter, input isn't loading options i.e. options list is confirmed
    if (
      ev.key === 'Enter' &&
      // for selects that load options over time type i.e. typeaheads, make sure options set is resolved prior
      // to the ensuing check
      !loadingOptions &&
      // search didn't yield any results i.e. search term filtered options down to empty set
      (!options ||
        !options.length ||
        // inputs whose options filtering we don't control
        // i.e. we never fetch or filter options, but rather let Select handle filtering internally on a set group of options
        (uncontrolledSearch &&
          search &&
          !options.find(
            ({ label }) =>
              label && label.toLowerCase().includes(search.toLowerCase())
          )))
    ) {
      // ev.stopPropagation forces just the keydown handler to fire, selecting item highlighted in dropdown,
      // instead of allowing the event to bubble up and be handled, with the result possibly overwritten,
      // by the base select component (I think... :) sorry, still muddy)
      ev.stopPropagation();
      if (handlePressEnter) {
        handlePressEnter();
      }
    }
  };

  return { onKeyDown: handleKeyDown };
}

export function useSelectCreate({
  existingOptions,
  validateFunc,
  checkDuplicate,
  duplicateMessage,
  addNew,
}) {
  const { t } = useTranslation();

  const [value, setValue] = useState('');
  const [message, showMessage, flashError] = useFlashTransition();

  const handleInputChange = (e) => {
    setValue(e);
  };

  const handleKeyDown = (ev) => {
    ev.stopPropagation();
    if (!value) {
      return;
    }
    // pressed Enter,
    if (ev.key === 'Enter') {
      const validated = validateFunc(value, t);
      if (validated !== null) {
        flashError(validated);
        return;
      }
      if (
        checkDuplicate &&
        (existingOptions || [])
          .map((opt) => opt.label)
          .includes(value.toLowerCase())
      ) {
        flashError(duplicateMessage || t('Values must be unique.'));
        return;
      }
      if (addNew) {
        addNew(value);
        setValue('');
      }
    }
  };

  return [
    {
      onInputChange: handleInputChange,
      onKeyDown: handleKeyDown,
      inputValue: value,
    },
    { message, showMessage, value },
  ];
}
