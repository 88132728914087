import { useTranslation } from 'react-i18next';
import ClearSelectButton from '__components/Inputs/Select/ClearButton';
import Select from '__components/Inputs/Select';
import { FilterGroup } from '@kizen/api/custom-object';
import { useMemo, useState } from 'react';
import { GenericOption } from '__pages/SmartConnectors/types';

type FilterGroupSelectProps = {
  onChange: (value: FilterGroup | null) => void;
  filterGroups?: FilterGroup[];
  filterGroup: FilterGroup | null;
  isLoading: boolean;
};

export const FilterGroupSelect = ({
  onChange,
  filterGroups,
  filterGroup,
  isLoading,
}: FilterGroupSelectProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<GenericOption | null>(
    filterGroup ? { value: filterGroup.id, label: filterGroup.name } : null
  );

  const { filterGroupOptions, filterGroupsById } = useMemo(() => {
    return {
      filterGroupOptions: (filterGroups || []).map(({ id, name }) => ({
        value: id,
        label: name,
      })),
      filterGroupsById: (filterGroups || []).reduce<
        Record<string, FilterGroup>
      >((acc, group) => {
        acc[group.id] = group;
        return acc;
      }, {}),
    };
  }, [filterGroups]);

  const handleSelect = (option: GenericOption | null) => {
    setValue(option);
    const selected = option ? filterGroupsById[option.value] : null;
    onChange(selected);
  };

  return (
    <Select
      value={isLoading ? null : value}
      onChange={handleSelect}
      label={t('Choose Group (optional)')}
      placeholder={t('Choose Group')}
      options={filterGroupOptions}
      menuLeftButton={<ClearSelectButton />}
      isLoading={isLoading}
      inModal
    />
  );
};
