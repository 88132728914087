import { createElement, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-use';
import PropTypes from 'prop-types';
import { areTreesEqual } from '@kizen/page-builder/utils/are-equal';
import FullscreenModal from 'components/Modals/Fullscreen';
import {
  ConfirmExitModal,
  PageBuilderCanvas,
  PageBuilderContainer,
  PageBuilderHeader,
  PageBuilderTray,
} from 'components/PageBuilder';
import { useToast, toastVariant } from 'components/ToastProvider';
import {
  emailCreatorElements,
  emailNodes,
  emailNodeSettings,
  emailTrayItems,
} from 'components/PageBuilder/presets';
import { usePathSetter } from 'hooks/usePathSetter';
import { useModalControl } from 'hooks/useModalControl';
import { useFlashTransition } from 'hooks/useFlashState';
import LibraryService from 'services/LibraryService';
import TeamMemberService from 'services/TeamMemberService';
import { getGenerativeAIEntitlement } from 'store/authentication/selectors';
import {
  SenderType,
  SendFrom,
  SendTestEmailButton,
  CUSTOM,
  DEFAULT,
  SENDER_TYPE,
  IntegratedInboxSenderType,
} from './components';
import {
  PageBuilderBodyHeader,
  PageBuilderContentWrapper,
  PageBuilderCanvasWrapper,
  SenderInfoContainer,
  StyledSubjectLine,
  StyledInternalName,
  StyledCustomName,
  StyledFromName,
  StyledIntegratedInbox2,
} from './styled';
import {
  buildResponse,
  cleanCraftJsContent,
  getCraftJsonWithSafeDynamicImageIds,
  getMjmlJson,
} from './utils';
import { useMjml2Html } from 'utility/email';
import { convertToTeamOption } from 'utility/TransformToSelectOptions';
import { SendFromTeamMemberField } from './components/SendFromTeamMemberField';
import FieldService from 'services/FieldService';
import { displayNameToOption } from 'services/helpers';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';
import { maybeTrackAIRequest } from 'utility/analytics';
import { Entities, useSelectTypeaheadWithScroll } from '../Inputs/Select/hooks';
import { isEqual } from 'lodash';
import { useEmailFromDomainEnabledEntitlement } from 'hooks/useEmailFromDomainEnabledEntitlement';
import { useIntegratedInbox } from 'hooks/useIntegratedInbox';
import { getInvalidLabel } from 'pages/Account/pages/Profile/Forms/EmailIntegration';

const {
  LAST_ROLE,
  TEAM_MEMBER,
  BUSINESS,
  LAST_ANY,
  TEAM_MEMBER_FIELD,
  SPECIFIC_INTEGRATED_INBOX,
} = SENDER_TYPE;

const EMPTY_ARRAY = [];

export const useTextLinkOptions = (t, isForCustomObject) =>
  useMemo(
    () => [
      {
        label: isForCustomObject
          ? t('Entity Record Link')
          : t('Contact Record Link'),
        href: `{{ ${
          isForCustomObject ? 'entity_record' : 'contact'
        }.link_url }}`,
      },
    ],
    [t, isForCustomObject]
  );

const getSelectedSummary = (
  senderType,
  teamMembers,
  rolesOptions,
  businessName,
  sendFrom,
  sendFromTeamMemberField,
  integratedInbox
) => {
  if (senderType === TEAM_MEMBER) {
    const teamMember = teamMembers.find(({ value }) => value === sendFrom);
    return {
      senderTeamMember: teamMember?.data,
    };
  } else if (senderType === LAST_ROLE) {
    return { senderRole: rolesOptions.find(({ value }) => value === sendFrom) };
  } else if (senderType === BUSINESS) {
    return { businessName };
  } else if (senderType === TEAM_MEMBER_FIELD) {
    return { senderField: sendFromTeamMemberField };
  } else if (senderType === SPECIFIC_INTEGRATED_INBOX) {
    return { externalAccount: integratedInbox };
  }
  return null;
};

const getTeamMemberValue = (
  sendFrom,
  senderTeamMember,
  defaultMember,
  sendFromOption
) => {
  if (sendFromOption) return sendFromOption;
  if (sendFrom === senderTeamMember?.id) {
    return convertToTeamOption(senderTeamMember);
  } else if (sendFrom === defaultMember.id) {
    return convertToTeamOption(defaultMember);
  }

  return sendFrom;
};

export const MessageBuilder = ({
  initialData = {},
  onConfirm,
  onCancel,
  modalLayer = 0,
  readonlySenderInfo = false,
  isTemplateUpdating = false,
  mergeFields = null,
  isForCustomObject = false,
  multipleMergeFields = null,
  useAllIntgegratedInboxes = false,
}) => {
  const canvasRef = useRef();
  const sendFromSelectFrom = useRef();
  const {
    sender,
    subject,
    name,
    craftJsContent,
    craftJson,
    senderTeamMember,
    externalAccount,
  } = initialData || {};

  const content = cleanCraftJsContent(craftJson || craftJsContent);
  const dataRef = useRef(getCraftJsonWithSafeDynamicImageIds(content)); // reassign ids here so the unsaved changes logic is accurate
  const currentEditsRef = useRef(dataRef.current);
  const chosenBusinessName = useSelector(
    ({ authentication }) => authentication?.chosenBusiness?.name ?? ''
  );
  const businessId = useSelector((s) => s.authentication.chosenBusiness.id);
  const default_integrated_inbox = useSelector(
    (s) => s.authentication.chosenBusiness.default_integrated_inbox
  );

  const emailFromDomainEnabled = useEmailFromDomainEnabledEntitlement();

  const {
    id,
    roles: [role],
    first_name,
    last_name,
    email,
    display_name,
  } = useSelector((s) => s.authentication.teamMember);

  const currentTeamMember = useMemo(
    () => ({
      id,
      firstName: first_name,
      lastName: last_name,
      email,
      displayName: display_name,
    }),
    [id, first_name, last_name, email, display_name]
  );
  const { t } = useTranslation();
  const [showToast] = useToast();
  const [senderType, setSenderType] = useState(
    sender?.type || (emailFromDomainEnabled ? TEAM_MEMBER : BUSINESS)
  );
  const [sendFrom, setSendFrom] = useState(sender?.id || id);
  const [sendFromOption, setSendFromOption] = useState();
  const [sendFromTeamMemberField, setSendFromTeamMemberField] = useState(
    initialData?.senderField?.displayName
      ? displayNameToOption(initialData.senderField)
      : initialData.senderField || null
  );
  const [fromName, setFromName] = useState(
    sender?.customName ? CUSTOM : DEFAULT
  );
  const [customName, setCustomName] = useState(sender?.customName || '');
  const [subjectLine, setSubjectLine] = useState(subject || '');
  const [internalName, setInternalName] = useState(name || '');
  const [integratedInbox, setIntegratedInbox] = useState(
    externalAccount
      ? {
          ...externalAccount,
          label: externalAccount?.label || externalAccount?.email,
          value: externalAccount?.value || externalAccount?.id,
        }
      : null
  );

  const [senderTypeWarning, setSenderTypeWarning] = useState('');
  const [integratedInboxError, setIntegratedInboxError] = useState('');
  const [subjectLineError, setSubjectLineError] = useState('');
  const [customNameError, setCustomNameError] = useState('');
  const [internalNameError, setInternalNameError] = useState('');
  const [sendFromError, setSendFromError] = useState('');
  const [sendFromFieldError, setSendFromFieldError] = useState('');
  const [isConfirmModalOpen, { showModal, hideModal }] = useModalControl();
  const showCustomName = fromName === CUSTOM;
  const mjml2html = useMjml2Html();
  const [, showErrors, flashError] = useFlashTransition();
  const handleSendFromChange = usePathSetter(setSendFrom, 'value');
  const enableAI = useSelector(getGenerativeAIEntitlement);

  const { isLoadingAccounts, accountSelectOptions, accounts } =
    useIntegratedInbox(useAllIntgegratedInboxes);

  const updateIntegratedInboxOnceRef = useRef(false);

  // because we don't have invalid with the integrated inbox
  if (!updateIntegratedInboxOnceRef.current && integratedInbox && accounts) {
    updateIntegratedInboxOnceRef.current = true;

    const option = accounts.find(
      ({ id }) => id === integratedInbox.id || id === integratedInbox.value
    );
    if (option) {
      const maybeInvalidLabel = getInvalidLabel(
        option.emailStatus,
        integratedInbox.label,
        t
      );
      setIntegratedInbox((prev) => ({ ...prev, ...maybeInvalidLabel }));
    }
  }

  const initialHeaderDataRef = useRef({
    senderType: initialData?.sender?.type,
    sendFrom: initialData?.sender?.id || id,
    fromName: initialData?.sender?.customName ? CUSTOM : DEFAULT,
    customName: initialData?.sender?.customName,
    subjectLine: initialData?.subject,
    internalName: initialData?.name,
  });

  const headerData = useMemo(
    () => ({
      senderType,
      sendFrom: [TEAM_MEMBER, TEAM_MEMBER_FIELD, LAST_ROLE].includes(senderType)
        ? sendFrom
        : initialData?.sender?.id || id,
      fromName,
      customName,
      subjectLine,
      internalName,
    }),
    [
      customName,
      senderType,
      sendFrom,
      fromName,
      subjectLine,
      internalName,
      initialData,
      id,
    ]
  );

  const textLinkOptions = useTextLinkOptions(t, isForCustomObject);
  const isNewUnsavedEmail = useMemo(
    () => !craftJsContent || Object.keys(craftJsContent).length === 0,
    [craftJsContent]
  );

  const { value: rolesOptions } = useAsync(
    TeamMemberService.getRolesOptionsList,
    []
  );

  const adminRole = useMemo(() => {
    return Array.isArray(rolesOptions)
      ? rolesOptions.find((role) => role.label === 'Admin') ?? ''
      : '';
  }, [rolesOptions]);

  const handleFromFieldChange = useCallback((option) => {
    setSendFromTeamMemberField(option);
    setSendFromFieldError('');
  }, []);

  const handleFromNameChange = useCallback(({ value }) => {
    setFromName(value);
    if (value === DEFAULT) {
      setCustomName('');
    }
  }, []);

  const handleSubjectLineChange = useCallback(
    (value) => {
      setSubjectLine(value);
      setSubjectLineError('');
      setInternalNameError('');
    },
    [setSubjectLine, setSubjectLineError]
  );

  const handleCustomNameChange = useCallback(
    (value) => {
      setCustomName(value);
      setCustomNameError('');
    },
    [setCustomName, setCustomNameError]
  );

  const handleInternalName = useCallback(
    (value) => {
      setInternalName(value);
      setInternalNameError('');
    },
    [setInternalNameError]
  );

  const handleIntegratedInboxChange = useCallback(
    (value) => {
      setIntegratedInbox(value);
      setIntegratedInboxError('');
    },
    [setIntegratedInboxError]
  );

  const handleSenderTypeChange = useCallback(
    ({ value }) => {
      let sendFrom = null;
      let sendFromOption = null;

      if (value === TEAM_MEMBER) {
        sendFrom = id;
        sendFromOption = convertToTeamOption(currentTeamMember);
      } else if (value === LAST_ROLE) {
        sendFrom = role ?? adminRole.value;
        sendFromOption =
          rolesOptions.find(({ value }) => value === role) ?? adminRole;
      } else if (value === SPECIFIC_INTEGRATED_INBOX) {
        setFromName(DEFAULT);
        setCustomName('');
      }
      setSendFromOption(sendFromOption);
      setSendFrom(sendFrom);
      setSenderType(value);
      setSenderTypeWarning('');
    },
    [id, currentTeamMember, role, adminRole, rolesOptions]
  );

  const [{ options: teamMembers, ...selectSenderProps }] =
    useSelectTypeaheadWithScroll({
      selectRef: sendFromSelectFrom,
      objectToOption: convertToTeamOption,
      entity: Entities.TeamMember,
      params: {
        is_verified: true,
      },
    });

  const { value: categorizedTeamMemberFields = [] } = useAsync(
    FieldService.getCategorizedTeamMemberFields,
    []
  );

  const isSendFromVerified = useMemo(
    // Previously, unverified emails were selectable in the Send From dropdown, so it's possilbe the
    // saved send from value on the template is not valid (and appears blank) https://kizen.atlassian.net/browse/KZN-3885
    () => (senderType === SENDER_TYPE.TEAM_MEMBER ? sendFrom ?? false : true),
    [senderType, sendFrom]
  );

  const hasErrors = useCallback(() => {
    const required = t('This field is required.');
    let error = false;

    if (subjectLine.trim().length === 0) {
      setSubjectLineError(required);
      error = true;
    }

    if (showCustomName && customName.trim().length === 0) {
      setCustomNameError(required);
      error = true;
    }
    if (!isSendFromVerified) {
      setSendFromError(required);
      error = true;
    } else if (
      senderType === SENDER_TYPE.TEAM_MEMBER_FIELD &&
      !sendFromTeamMemberField
    ) {
      setSendFromFieldError(required);
      error = true;
    } else if (senderType === SENDER_TYPE.SPECIFIC_INTEGRATED_INBOX) {
      if (!integratedInbox) {
        setIntegratedInboxError(required);
        error = true;
      } else if (integratedInbox.error) {
        setIntegratedInboxError(t('This Integrated Inbox is invalid.'));
        error = true;
      }
    }
    return error;
  }, [
    t,
    subjectLine,
    showCustomName,
    customName,
    isSendFromVerified,
    sendFromTeamMemberField,
    senderType,
    integratedInbox,
  ]);

  const handleSendTestEmail = useCallback(
    async (email) => {
      const { html } = await mjml2html(
        getMjmlJson(canvasRef.current.editor, {
          processDynamicImages: false,
          processDynamicText: false,
        })
      );
      try {
        await LibraryService.sendTestEmail(subjectLine, email, html);
        return true;
      } catch (error) {
        showToast({
          message: t(
            'Failed to send the test email. Please try again or contact Kizen Support if the problem persists.'
          ),
          variant: toastVariant.FAILURE,
        });
        return false;
      }
    },
    [subjectLine, mjml2html, showToast, t]
  );

  const handleSave = useCallback(
    async (shouldClose = false) => {
      if (isTemplateUpdating) {
        return;
      }

      const showBusinessWarning =
        !emailFromDomainEnabled &&
        !default_integrated_inbox?.id &&
        senderType === BUSINESS;

      if (showBusinessWarning) {
        setSenderTypeWarning(
          t('Warning: Business has no default inbox configured.')
        );
      }

      if (hasErrors()) {
        hideModal();
        flashError();
        return;
      }

      // delayed showing the business warning until after the error check
      if (showBusinessWarning) {
        flashError();
      }

      const teamMemberValue = getTeamMemberValue(
        sendFrom,
        senderTeamMember,
        currentTeamMember,
        sendFromOption
      );

      const selectedSummary = getSelectedSummary(
        senderType,
        [teamMemberValue],
        rolesOptions,
        chosenBusinessName,
        sendFrom,
        sendFromTeamMemberField,
        integratedInbox
      );

      const response = await buildResponse(
        canvasRef.current.editor,
        mjml2html,
        {
          senderType,
          sendFrom,
          customName,
          subjectLine,
          internalName,
          selectedSummary,
          integratedInbox,
        }
      );
      try {
        await onConfirm(
          { ...response, baseMessageId: initialData?.baseMessageId },
          shouldClose
        );
        // Don't use `response.craftJsContent` to update currentEditsRef - that object will
        // have reassigned image ids and break the is-equal check when clicking 'back'
        if (canvasRef.current?.editor) {
          currentEditsRef.current =
            canvasRef.current.editor?.query?.getSerializedNodes();
        }
        initialHeaderDataRef.current = headerData;
      } catch (err) {
        if (err.internalNameError) {
          hideModal();
          setInternalNameError(err.internalNameError);
          flashError();
        } else if (!err?.skipToast) {
          showToast({
            message: t(
              'Message Template could not be saved. Please try again or contact Kizen support.'
            ),
            variant: toastVariant.FAILURE,
          });
        }
      }
    },
    [
      isTemplateUpdating,
      hasErrors,
      senderType,
      emailFromDomainEnabled,
      default_integrated_inbox?.id,
      sendFrom,
      senderTeamMember,
      currentTeamMember,
      sendFromOption,
      rolesOptions,
      chosenBusinessName,
      sendFromTeamMemberField,
      integratedInbox,
      mjml2html,
      customName,
      subjectLine,
      internalName,
      hideModal,
      flashError,
      t,
      onConfirm,
      initialData?.baseMessageId,
      headerData,
    ]
  );

  const sendFromOptions = useMemo(() => {
    if (senderType === TEAM_MEMBER) {
      return teamMembers ?? EMPTY_ARRAY;
    }
    return senderType === LAST_ROLE ? rolesOptions : EMPTY_ARRAY;
  }, [senderType, teamMembers, rolesOptions]);

  const modalHeader = useMemo(() => {
    const saveAndClose = async () => {
      await handleSave(true);
    };
    const onGoBack = () => {
      if (
        !areTreesEqual(
          canvasRef.current?.editor?.query.getSerializedNodes(),
          currentEditsRef.current
        ) ||
        !isEqual(initialHeaderDataRef.current, headerData)
      ) {
        showModal();
      } else {
        onCancel();
      }
    };
    return (
      <PageBuilderHeader
        saving={!isConfirmModalOpen && isTemplateUpdating}
        rightActionButton={createElement(SendTestEmailButton, {
          onConfirm: handleSendTestEmail,
        })}
        onSave={() => handleSave(false)}
        onSaveAndClose={saveAndClose}
        onGoBack={onGoBack}
      />
    );
  }, [
    isConfirmModalOpen,
    isTemplateUpdating,
    handleSendTestEmail,
    handleSave,
    showModal,
    onCancel,
    headerData,
  ]);

  const handlers = useMemo(() => {
    return [
      { id: 'sender-type' },
      {
        id: 'sender-from',
      },
      {
        id: 'sender-from-name',
      },
      showCustomName
        ? {
            id: 'sender-from-custom-name',
          }
        : null,
      {
        id: 'subject-line',
      },
      {
        id: 'internal-name',
      },
      {
        id: 'integrated-inbox',
      },
    ].filter(Boolean);
  }, [showCustomName]);

  const { assignFieldHandle, getKeyListenersProps } = useKeyListeners(
    handlers,
    {},
    () => true
  );

  const onAIRequestStart = useCallback(
    (requestType) => {
      maybeTrackAIRequest('email', initialData.id, businessId, requestType);
    },
    [initialData.id, businessId]
  );

  return (
    <>
      {isConfirmModalOpen && (
        <ConfirmExitModal
          heading={
            isNewUnsavedEmail
              ? t('Your Email Is Not Saved')
              : t('Your Email Has Unsaved Changes')
          }
          discardButtonText={isNewUnsavedEmail ? t('Discard Email') : undefined}
          isSaving={isTemplateUpdating}
          onCancel={hideModal}
          onSave={() => handleSave(true)}
          onDiscard={() => {
            hideModal();
            onCancel();
          }}
        >
          {isNewUnsavedEmail
            ? t(
                "This new email has not yet been saved. If you don't save, your email will be lost."
              )
            : t('All unsaved changes will be lost unless you save your email.')}
        </ConfirmExitModal>
      )}
      <FullscreenModal show header={modalHeader} enforceFocus={false}>
        <PageBuilderContainer
          enableDynamicImages
          enableMergeFields
          enableTextLinks
          mergeFields={mergeFields}
          textLinkOptions={textLinkOptions}
          nodes={emailNodes}
          creatorElements={emailCreatorElements}
          modalLayer={modalLayer}
          multipleMergeFields={multipleMergeFields}
          {...(enableAI && {
            enableAI: true,
            onAIRequestStart,
          })}
        >
          <PageBuilderContentWrapper>
            <PageBuilderCanvasWrapper>
              <KeyBoardContext.Provider value={{ assignFieldHandle }}>
                <PageBuilderBodyHeader>
                  <SenderInfoContainer>
                    {emailFromDomainEnabled ? (
                      <>
                        <SenderType
                          disabled={readonlySenderInfo}
                          value={senderType}
                          onChange={handleSenderTypeChange}
                          businessName={chosenBusinessName}
                          modalLayer={modalLayer}
                          {...getKeyListenersProps('sender-type')}
                        />
                        {senderType === TEAM_MEMBER_FIELD &&
                        senderType !== BUSINESS ? (
                          <SendFromTeamMemberField
                            disabled={readonlySenderInfo}
                            value={sendFromTeamMemberField}
                            options={categorizedTeamMemberFields}
                            showError={showErrors}
                            errorMessage={sendFromFieldError}
                            onChange={handleFromFieldChange}
                            modalLayer={modalLayer}
                            {...getKeyListenersProps('sender-from')}
                          />
                        ) : null}
                        {![
                          LAST_ANY,
                          TEAM_MEMBER_FIELD,
                          SPECIFIC_INTEGRATED_INBOX,
                        ].includes(senderType) && senderType !== BUSINESS ? (
                          <SendFrom
                            disabled={readonlySenderInfo}
                            value={getTeamMemberValue(
                              sendFrom,
                              senderTeamMember,
                              currentTeamMember,
                              sendFromOption
                            )}
                            options={sendFromOptions}
                            showError={showErrors}
                            errorMessage={sendFromError}
                            onChange={(opt) => {
                              setSendFromOption(opt);
                              handleSendFromChange(opt);
                            }}
                            modalLayer={modalLayer}
                            {...(senderType === TEAM_MEMBER
                              ? {
                                  ref: sendFromSelectFrom,
                                  ...selectSenderProps,
                                }
                              : {})}
                            {...getKeyListenersProps('sender-from')}
                          />
                        ) : null}
                        {senderType !== SPECIFIC_INTEGRATED_INBOX ? (
                          <StyledFromName
                            value={fromName}
                            onChange={handleFromNameChange}
                            modalLayer={modalLayer}
                            {...getKeyListenersProps('sender-from-name')}
                          />
                        ) : (
                          <StyledIntegratedInbox2
                            value={integratedInbox}
                            onChange={handleIntegratedInboxChange}
                            modalLayer={modalLayer}
                            accountSelectOptions={accountSelectOptions}
                            isLoading={isLoadingAccounts}
                            showError={showErrors}
                            errorMessage={integratedInboxError}
                            {...getKeyListenersProps('integrated-inbox')}
                          />
                        )}
                        {showCustomName && (
                          <StyledCustomName
                            value={customName}
                            errorMessage={customNameError}
                            showError={showErrors}
                            onChange={handleCustomNameChange}
                            {...getKeyListenersProps('sender-from-custom-name')}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <IntegratedInboxSenderType
                          disabled={readonlySenderInfo}
                          value={senderType}
                          onChange={handleSenderTypeChange}
                          businessName={chosenBusinessName}
                          modalLayer={modalLayer}
                          showError={showErrors}
                          errorMessage={senderTypeWarning}
                          isBusinessSenderConfigured={
                            !!default_integrated_inbox?.id
                          }
                          {...getKeyListenersProps('sender-type')}
                        />

                        {senderType === SPECIFIC_INTEGRATED_INBOX ? (
                          <StyledIntegratedInbox2
                            value={integratedInbox}
                            onChange={handleIntegratedInboxChange}
                            modalLayer={modalLayer}
                            accountSelectOptions={accountSelectOptions}
                            isLoadingAccounts={isLoadingAccounts}
                            showError={showErrors}
                            errorMessage={integratedInboxError}
                            {...getKeyListenersProps('integrated-inbox')}
                          />
                        ) : null}
                      </>
                    )}

                    <StyledSubjectLine
                      value={subjectLine}
                      errorMessage={subjectLineError}
                      showError={showErrors}
                      onChange={handleSubjectLineChange}
                      {...getKeyListenersProps('subject-line')}
                    />
                    <StyledInternalName
                      value={internalName}
                      errorMessage={internalNameError}
                      showError={showErrors}
                      onChange={handleInternalName}
                      {...getKeyListenersProps('internal-name')}
                    />
                  </SenderInfoContainer>
                </PageBuilderBodyHeader>
              </KeyBoardContext.Provider>
              <PageBuilderCanvas ref={canvasRef} data={dataRef.current} />
            </PageBuilderCanvasWrapper>
            <PageBuilderTray
              emailMode
              trayItems={emailTrayItems}
              nodeSettings={emailNodeSettings}
            />
          </PageBuilderContentWrapper>
        </PageBuilderContainer>
      </FullscreenModal>
    </>
  );
};

MessageBuilder.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  modalLayer: PropTypes.number,
  readonlySenderInfo: PropTypes.bool,
  initialData: PropTypes.objectOf({
    baseMessageId: PropTypes.oneOf([PropTypes.string, null]),
    craftJsContent: PropTypes.object,
    htmlContent: PropTypes.string,
    name: PropTypes.string,
    sender: PropTypes.objectOf({
      type: PropTypes.oneOf([TEAM_MEMBER, LAST_ROLE, LAST_ROLE, BUSINESS]),
      id: PropTypes.oneOf([PropTypes.string, null]),
      customName: PropTypes.oneOf([PropTypes.string, null]),
    }),
    subject: PropTypes.string,
  }),
  onCancel: PropTypes.func,
  isTemplateUpdating: PropTypes.bool,
};
