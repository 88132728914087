import {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
  forwardRef,
} from 'react';
import { DROPDOWN_SHOULD_LOAD } from 'utility/constants';
import { createFilter } from 'react-select';
import { useTranslation } from 'react-i18next';
import BaseReactSelect from './BaseReactSelect';
import * as customizeComponents from './customize';
import { hasInputControl, MaybeInputControl } from '../InputControl';
import { applyRef } from '../props';
import Validation, { useValidation } from '../Validation';
import { useEllipses } from './useEllipses';
import { getMessageProps, getAllOptions } from './Outline';
import { layers } from 'app/spacing';
import useField from 'hooks/useField';
import { TabsButton } from './TabsButton';

const defaultFilterOption = createFilter();

const SelectUnderline = forwardRef(function SelectUnderline(props, ref) {
  const {
    value: valueProp = null,
    onChange = null,
    options: optionsProp,
    placeholder = null,
    isColored = null,
    isReadOnly = null,
    isSearchable: isSearchableProp = null,
    menuPlacement,
    disabled = false,
    error = null,
    endAdornment = null,
    menuOnly = null,
    menuIsOpen: menuIsOpenProp = null,
    menuInline: menuInlineProp = null,
    menuTopButton = null,
    menuLeftButton = null,
    menuRightButton = null,
    components: componentsProp = null,
    filterOption = defaultFilterOption,
    shrink: shrinkProp = null,
    includeGroupPlaceholder = true,
    className,
    inModal,
    errorPlacement,
    loadItems = null,
    noOptionsMessage: noOptionsMessageProp = null,
    onMenuOpen,
    onMenuClose,
    onKeyDown,
    portal = false,
    styles,
    forceValidation = false,
    showTabs,
    onTabChange,
    selectedTab: selectedTabProp,
    hasInfinityScroll,
    maxOptions,
    isLoading,
    ...others
  } = props;

  const { t } = useTranslation();
  const [nextFocusedOption, setNextFocusedOption] = useState(null);
  const [menuTopOffset, setMenuTopOffset] = useState(0);

  const [selectedTab, setSelectedTab] = useField(
    selectedTabProp ||
      ((optionsProp !== DROPDOWN_SHOULD_LOAD &&
        Array.isArray(optionsProp) &&
        optionsProp) ||
        [])[0]?.tabKey
  );
  const selectRef = useRef();
  const validationRef = useRef();
  const menuOpened = useRef(Boolean(menuIsOpenProp));
  const mergeRef = useCallback(
    (el) => {
      applyRef(selectRef, el);
      applyRef(validationRef, el);
      applyRef(ref, el);
    },
    [ref]
  );
  const handleSelectTab = useCallback(
    (tab) => {
      setSelectedTab(tab.tabKey);
      onTabChange?.(tab);
      selectRef.current.select.menuListRef.scrollTop = 0;
    },
    [onTabChange, setSelectedTab]
  );

  const [validation, validationProps] = useValidation(validationRef, props);
  const [computedMenuPlacement, setComputedMenuPlacement] = useState(
    menuPlacement !== 'auto' ? menuPlacement : null
  );

  useEffect(() => {
    if (forceValidation) {
      validation.onSubmission();
    }
  }, [forceValidation]); // eslint-disable-line react-hooks/exhaustive-deps

  const options = useMemo(() => {
    const options =
      (optionsProp !== DROPDOWN_SHOULD_LOAD &&
        Array.isArray(optionsProp) &&
        optionsProp) ||
      [];
    const currentOptions =
      showTabs && selectedTab
        ? options.find((opt) => opt.tabKey === selectedTab)?.options || options
        : options;
    const result = (
      currentOptions === DROPDOWN_SHOULD_LOAD ? [] : currentOptions
    ).map((opt, i) => {
      if (opt?.options) {
        // In order to make group headings persistent, we need a placeholder
        // option that is never filtered away. In ./customize we simply hide these items.
        return {
          ...opt,
          options: [...opt.options, { groupPlaceholder: true, value: i }],
        };
      }
      return opt;
    });
    return maxOptions ? result.slice(0, maxOptions) : result;
  }, [optionsProp, showTabs, selectedTab, maxOptions]);

  useEffect(() => {
    if (selectRef.current && nextFocusedOption && hasInfinityScroll) {
      const { select } = selectRef.current;
      const allOptions = getAllOptions(options, includeGroupPlaceholder);
      const focusedOption = allOptions.find(
        ({ value }) => value === nextFocusedOption.value
      );
      select.getNextFocusedOption = () => focusedOption;
      select.setState({
        focusedOption,
      });
    }
  }, [hasInfinityScroll, nextFocusedOption, options, includeGroupPlaceholder]);

  const value =
    typeof valueProp === 'string'
      ? options.find((opt) => opt.value === valueProp) || null
      : valueProp;
  const shrink = typeof shrinkProp === 'boolean' ? shrinkProp : true;
  let menuIsOpen;
  if (typeof menuIsOpenProp === 'boolean') {
    menuIsOpen = menuIsOpenProp;
  } else if (menuOnly) {
    menuIsOpen = true;
  } else if (isReadOnly) {
    menuIsOpen = false;
  }
  const isSearchable =
    typeof isSearchableProp === 'boolean' ? isSearchableProp : !isReadOnly;
  const menuInline =
    typeof menuInlineProp === 'boolean' ? menuInlineProp : menuOnly;

  const { value: _, placeholder: _1, ...forInputControl } = props;

  const messageProps = useMemo(
    () =>
      getMessageProps(
        noOptionsMessageProp,
        optionsProp,
        loadItems || isLoading,
        options,
        t
      ),
    [noOptionsMessageProp, optionsProp, loadItems, isLoading, options, t]
  );

  /*
    There seems to be a bug in react-select where the state (including styles) doesn't update when
    the menu changes dimensions until an action is taken (like a click or hover change). Instead,
    we store the position whenever it changes.
  */
  useEffect(() => {
    if (portal && selectRef.current?.select) {
      const sizeObserver = new ResizeObserver((val) => {
        const rect = val[0]?.target.getBoundingClientRect();
        setMenuTopOffset(
          Math.round(
            rect.top + rect.height + document.documentElement.scrollTop
          )
        );
      });

      sizeObserver.observe(selectRef.current.select.controlRef);

      return () => {
        sizeObserver.disconnect();
      };
    }
  }, [portal]);

  const handleMenuOpen = useCallback(() => {
    const rect = selectRef.current?.select.controlRef.getBoundingClientRect();
    if (rect) {
      setMenuTopOffset(
        Math.round(rect.top + rect.height + document.documentElement.scrollTop)
      );
    }
    onMenuOpen?.();
    menuOpened.current = true;
  }, [onMenuOpen]);

  const handleMenuClose = useCallback(() => {
    onMenuClose?.();
    menuOpened.current = false;
  }, [onMenuClose]);

  const handleOnKeyDown = useCallback(
    (e) => {
      onKeyDown?.(e);
      if (e.key === 'Escape' && menuOpened.current) {
        e.stopPropagation();
        menuOpened.current = false;
      }
      if (e.key === 'ArrowDown') {
        const { select } = selectRef.current;
        if (select.state.focusedOption) {
          const allOptions = getAllOptions(options, includeGroupPlaceholder);
          const focusedOptionIndex = allOptions.findIndex(
            ({ value }) => value === select.state.focusedOption.value
          );
          const nextIndex = focusedOptionIndex + 1;
          if (nextIndex === allOptions.length) {
            // To prevent jumping from the last to the first element
            e.preventDefault();
            return;
          }
          if (hasInfinityScroll) {
            const nextFocusedOption = allOptions[nextIndex];
            setNextFocusedOption(nextFocusedOption);
          }
        }
      }
      if (e.key === 'ArrowUp') {
        const { select } = selectRef.current;
        if (select.state.focusedOption) {
          const allOptions = getAllOptions(options, includeGroupPlaceholder);
          const focusedOptionIndex = allOptions.findIndex(
            ({ value }) => value === select.state.focusedOption.value
          );
          const nextIndex = focusedOptionIndex - 1;
          if (nextIndex < 0) {
            // To prevent jumping from the first to the last element
            e.preventDefault();
            return;
          }
          if (hasInfinityScroll) {
            const nextFocusedOption = allOptions[nextIndex];
            setNextFocusedOption(nextFocusedOption);
          }
        }
      }
    },
    [hasInfinityScroll, onKeyDown, options, includeGroupPlaceholder]
  );

  const ellipseComponents = useEllipses();
  return (
    <MaybeInputControl
      forInput
      variant="underline"
      {...forInputControl}
      shrink={shrink}
      isShowAsterisk={forInputControl.required}
    >
      <BaseReactSelect
        ref={mergeRef}
        aria-label={placeholder}
        selectManager={selectRef.current}
        className={!hasInputControl(props) && className}
        value={value}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        isDisabled={disabled}
        isSearchable={isSearchable}
        menuIsOpen={menuIsOpen}
        menuPlacement={menuPlacement}
        {...messageProps}
        menuPortalTarget={portal ? document.body : undefined}
        styles={{
          menuPortal: (base) => {
            return {
              ...base,
              zIndex: layers.content(9),
              top: menuTopOffset || base.top,
            };
          },
          ...styles,
        }}
        filterOption={(opt, inputValue) => {
          return (
            !filterOption ||
            filterOption(
              {
                label: opt.label,
                // also search in additional value passed to option
                extraValue: opt.data?.extraValue || '',
              },
              inputValue
            )
          );
        }}
        components={{
          ...customizeComponents,
          ...ellipseComponents,
          ...componentsProp,
        }}
        // Below are custom
        variant="underline"
        isColored={isColored}
        isReadOnly={isReadOnly}
        error={error || validation.error}
        endAdornment={endAdornment}
        menuOnly={menuOnly}
        menuInline={menuInline}
        menuTopButton={
          showTabs ? (
            <div>
              <TabsButton
                onSelectTab={handleSelectTab}
                options={optionsProp}
                selectedTab={selectedTab}
              />
              {menuTopButton}
            </div>
          ) : (
            menuTopButton
          )
        }
        menuLeftButton={menuLeftButton}
        menuRightButton={menuRightButton}
        computedMenuPlacement={computedMenuPlacement}
        setComputedMenuPlacement={setComputedMenuPlacement}
        {...others}
        forseValidation={forceValidation}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        onKeyDown={handleOnKeyDown}
      />
      <Validation
        inModal={inModal}
        errorPlacement={errorPlacement}
        {...validationProps}
      />
    </MaybeInputControl>
  );
});

export default SelectUnderline;
