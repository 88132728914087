import ToggleButton from 'components/Kizen/ToggleButton';
import { gutters } from 'app/spacing';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import KizenTypography from 'app/kizentypo';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import MultiSelect from 'components/Inputs/MultiSelect';
import {
  BoldTypography,
  DropdownContainer,
  DropdownRow,
  Header,
  InfoIcon,
  ModalContainer,
} from './styles';
import PropTypes from 'prop-types';
import { colorsButton } from 'app/colors';
import { useTooltip } from 'components/Kizen/Tooltip';
import TeamMemberService from 'services/TeamMemberService';
import { BUSINESS } from 'queries/query-keys';
import {
  Entities,
  useSelectTypeaheadWithScroll,
} from '../../Inputs/Select/hooks';
import { convertToTeamOption } from 'utility/TransformToSelectOptions';
import { DelayedLoader } from './DelayedLoader';

const getRoles = () => {
  return TeamMemberService.getRolesOptionsList();
};

/*
 * This component is a collection of inputs for generic permissions configurations, agnostic
 * of the object they're used for. Data fetching is self-contained using react-query.
 */

const defaultVisibleControls = {
  allOptions: {
    none: true,
    view: true,
    edit: true,
    admin: true,
  },
  roles: { view: true, edit: true, admin: true },
  teamMembers: {
    view: true,
    edit: true,
    admin: true,
  },
};

const PermissionSettings = ({
  title,
  infoBubble,
  onChange,
  allowRolesInMultipleGroups = true,
  allowTeamMembersInMultipleGroups = true,
  top = gutters.spacing(6),
  bottom = gutters.spacing(0, 2),
  existing = {},
  loading: externalDataLoading = false,
  exclusive = true,
  labels,
  owner,
  small = false,
  isDuplication,
  inline = false,
  visibleControls = defaultVisibleControls,
  removeTopPadding = false,
}) => {
  const { t } = useTranslation();
  const viewTeamMemberSelectRef = useRef();
  const editTeamMemberSelectRef = useRef();
  const adminTeamMemberSelectRef = useRef();
  const allTeamMembersValue =
    typeof existing?.all_team_members === 'number'
      ? existing.all_team_members
      : 0;
  const [allTeamMembersPermission, _setAllTeamMembersPermission] = useState(
    () => allTeamMembersValue
  );
  const [viewRoles, _setViewRoles] = useState([]);
  const [editRoles, _setEditRoles] = useState([]);
  const [adminRoles, _setAdminRoles] = useState([]);
  const [viewTeamMembers, _setViewTeamMembers] = useState([]);
  const [editTeamMembers, _setEditTeamMembers] = useState([]);
  const [adminTeamMembers, _setAdminTeamMembers] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const dirtyTracker = useRef(false);

  const setAllTeamMembersPermissionButton = useCallback(
    (value) => {
      dirtyTracker.current = true;
      _setAllTeamMembersPermission(value);
    },
    [_setAllTeamMembersPermission]
  );

  const setViewRolesDropdown = useCallback(
    (roles) => {
      dirtyTracker.current = true;
      _setViewRoles(roles);
    },
    [_setViewRoles]
  );

  const setEditRolesDropdown = useCallback(
    (roles) => {
      dirtyTracker.current = true;
      _setEditRoles(roles);
    },
    [_setEditRoles]
  );

  const setAdminRolesDropdown = useCallback(
    (roles) => {
      dirtyTracker.current = true;
      _setAdminRoles(roles);
    },
    [_setAdminRoles]
  );

  const setViewTeamMembersDropdown = useCallback(
    (teamMembers) => {
      dirtyTracker.current = true;
      _setViewTeamMembers(teamMembers);
    },
    [_setViewTeamMembers]
  );

  const setEditTeamMembersDropdown = useCallback(
    (teamMembers) => {
      dirtyTracker.current = true;
      _setEditTeamMembers(teamMembers);
    },
    [_setEditTeamMembers]
  );

  const setAdminTeamMembersDropdown = useCallback(
    (teamMembers) => {
      dirtyTracker.current = true;
      _setAdminTeamMembers(teamMembers);
    },
    [_setAdminTeamMembers]
  );

  useEffect(() => {
    _setAllTeamMembersPermission(allTeamMembersValue);
  }, [allTeamMembersValue]);

  const { data: roles, isLoading: rolesLoading } = useQuery(
    [...BUSINESS.ROLES],
    getRoles
  );

  const handleAnyDropdownFocus = useCallback(() => {
    setTimeout(() => {
      footerRef.current?.scrollIntoView();
    });
  }, []);

  const chosenViewTeamMembersIds = useMemo(
    () => viewTeamMembers.map(({ value }) => value),
    [viewTeamMembers]
  );

  const [viewTeamMemberSelectProps] = useSelectTypeaheadWithScroll({
    selectRef: viewTeamMemberSelectRef,
    objectToOption: convertToTeamOption,
    entity: Entities.TeamMember,
    chosenValueIds: chosenViewTeamMembersIds,
    onResultsChange: handleAnyDropdownFocus,
  });

  const chosenEditTeamMemberIds = useMemo(
    () => editTeamMembers.map(({ value }) => value),
    [editTeamMembers]
  );

  const [editTeamMemberSelectProps] = useSelectTypeaheadWithScroll({
    selectRef: editTeamMemberSelectRef,
    objectToOption: convertToTeamOption,
    entity: Entities.TeamMember,
    chosenValueIds: chosenEditTeamMemberIds,
    onResultsChange: handleAnyDropdownFocus,
  });

  const chosenAdminTeamMemberIds = useMemo(
    () => adminTeamMembers.map(({ value }) => value),
    [adminTeamMembers]
  );

  const [adminTeamMemberSelectProps] = useSelectTypeaheadWithScroll({
    selectRef: adminTeamMemberSelectRef,
    objectToOption: convertToTeamOption,
    entity: Entities.TeamMember,
    chosenValueIds: chosenAdminTeamMemberIds,
    onResultsChange: handleAnyDropdownFocus,
  });

  const {
    all_team_members: existingAll,
    roles: existingRoles,
    team_members: existingTeamMembers,
  } = existing;

  useEffect(() => {
    if (typeof all_team_members === 'number') {
      setRefreshing(true);
      _setAllTeamMembersPermission(existingAll);

      // If this value changes, we need to delay removing the loading indicator
      // because the value change is animated
      setTimeout(() => {
        setRefreshing(false);
      }, 200);
    }

    if (existingRoles) {
      const { view, edit, admin } = existingRoles;

      if (view) {
        _setViewRoles(
          view.map((v) => ({ value: v.id, label: v.display_name }))
        );
      }
      if (edit) {
        _setEditRoles(
          edit.map((v) => ({ value: v.id, label: v.display_name }))
        );
      }
      if (admin) {
        _setAdminRoles(
          admin.map((v) => ({ value: v.id, label: v.display_name }))
        );
      }
    }

    const { view = [], edit = [], admin = [] } = existingTeamMembers ?? {};

    _setViewTeamMembers(
      [
        ...view.map((v) => ({ value: v.id, label: v.display_name })),
        owner?.id &&
          !view.find(({ id }) => id === owner?.id) &&
          small && { label: owner.display_name, value: owner.id },
      ].filter(Boolean)
    );

    _setEditTeamMembers(
      [
        ...edit.map((v) => ({ value: v.id, label: v.display_name })),
        owner?.id &&
          !edit.find(({ id }) => id === owner?.id) &&
          small && { label: owner.display_name, value: owner.id },
      ].filter(Boolean)
    );

    _setAdminTeamMembers(
      [
        ...admin.map((v) => ({ value: v.id, label: v.display_name })),
        owner?.id &&
          !admin.find(({ id }) => id === owner?.id) && {
            label: owner.display_name,
            value: owner.id,
          },
      ].filter(Boolean)
    );
  }, [owner, small, existingAll, existingRoles, existingTeamMembers]);

  const footerRef = useRef(null);

  const state = useMemo(() => {
    return {
      allTeamMembersPermission,
      viewRoles: (viewRoles ?? []).filter(Boolean),
      editRoles: (editRoles ?? []).filter(Boolean),
      adminRoles: (adminRoles ?? []).filter(Boolean),
      viewTeamMembers: (viewTeamMembers ?? []).filter(Boolean),
      editTeamMembers: (editTeamMembers ?? []).filter(Boolean),
      adminTeamMembers: (adminTeamMembers ?? []).filter(Boolean),
    };
  }, [
    allTeamMembersPermission,
    viewRoles,
    editRoles,
    adminRoles,
    viewTeamMembers,
    editTeamMembers,
    adminTeamMembers,
  ]);

  useEffect(() => {
    onChange?.(state, dirtyTracker.current);
    dirtyTracker.current = false;
  }, [onChange, state]);

  const disabledRoles = useMemo(() => {
    if (allowRolesInMultipleGroups) {
      return {};
    }
    const allSelections = [...viewRoles, ...editRoles, ...adminRoles];
    return allSelections.reduce((acc, { value }) => {
      const res = { ...acc };
      res[value] = true;
      return res;
    }, {});
  }, [viewRoles, editRoles, adminRoles, allowRolesInMultipleGroups]);

  const disabledTeamMembers = useMemo(() => {
    if (allowTeamMembersInMultipleGroups) {
      return {};
    }
    const allSelections = [
      ...viewTeamMembers,
      ...editTeamMembers,
      ...adminTeamMembers,
    ];
    return allSelections.reduce((acc, { value }) => {
      const res = { ...acc };
      res[value] = true;
      return res;
    }, {});
  }, [
    viewTeamMembers,
    editTeamMembers,
    adminTeamMembers,
    allowTeamMembersInMultipleGroups,
  ]);

  const isRoleDisabled = useCallback(
    ({ value }) => {
      return !exclusive ? false : disabledRoles[value] ?? false;
    },
    [disabledRoles, exclusive]
  );

  const isTeamMemberSelected = useCallback(
    ({ value }) => {
      return !exclusive ? false : disabledTeamMembers[value] ?? false;
    },
    [disabledTeamMembers, exclusive]
  );

  const [tooltipProps, tooltip] = useTooltip({
    label:
      infoBubble ||
      t(
        'If a single team member meets the requirements for multiple sections below, they will be granted the maximum sharing setting.'
      ),
  });

  const allOptions = useMemo(() => {
    return [
      visibleControls.allOptions.none && {
        value: 0,
        label: labels?.all?.none ?? t('NONE'),
      },
      visibleControls.allOptions.view && {
        value: 1,
        label: labels?.all?.view ?? t('VIEW'),
      },
      !small &&
        visibleControls.allOptions.edit && {
          value: 2,
          label: labels?.all?.edit ?? t('EDIT'),
        },
      !small &&
        visibleControls.allOptions.admin && {
          value: 3,
          label: labels?.all?.admin ?? t('ADMIN'),
        },
    ].filter(Boolean);
  }, [visibleControls, labels?.all, t, small]);

  return (
    <>
      <ModalContainer inline={inline}>
        {/*
          We use this special loader that has a delay on removing the loading state, so we
          can wait for the button animation to finish before removing the loading state
        */}
        {inline && (externalDataLoading || refreshing) ? (
          <div className="inline-loader">
            <DelayedLoader loading initial={true} />
          </div>
        ) : !inline ? (
          <DelayedLoader
            loading={externalDataLoading || refreshing}
            initial={true}
          />
        ) : null}
        <StyledWizardField top={inline || removeTopPadding ? 0 : top} flex>
          {!inline ? (
            <Header>
              <KizenTypography type="subheader">
                {title || t('Sharing Settings')}
              </KizenTypography>
              <InfoIcon
                {...tooltipProps}
                icon="info-circle"
                color={colorsButton.iconGray.hover}
              />
              {tooltip}
            </Header>
          ) : null}
          <StyledWizardField top={inline ? 0 : gutters.spacing(3, 1)} flex>
            <BoldTypography>{t('All Team Members')}</BoldTypography>
            <StyledWizardField
              top={gutters.spacing(2, 2)}
              data-qa-field="all-team-members"
              data-qa-value={allTeamMembersPermission}
              flex
            >
              <ToggleButton
                sizing={null}
                options={allOptions}
                value={allTeamMembersPermission}
                onChange={({ value }) =>
                  setAllTeamMembersPermissionButton(value)
                }
              />
            </StyledWizardField>
          </StyledWizardField>
        </StyledWizardField>
        <StyledWizardField top={inline ? 0 : gutters.spacing(4)} flex>
          <BoldTypography>
            {t('Specific Roles')} {small ? ` (${t('View')})` : ''}
          </BoldTypography>
          <StyledWizardField top={small ? 12 : 17} flex>
            <DropdownRow>
              {visibleControls.roles.view ? (
                <DropdownContainer>
                  {!small && (
                    <KizenTypography>
                      {labels?.specific?.view ?? t('View')}
                    </KizenTypography>
                  )}
                  <StyledWizardField
                    top={small ? 0 : 12}
                    data-qa-field="view-roles"
                    data-qa-value={JSON.stringify(viewRoles)}
                    flex
                  >
                    <MultiSelect
                      options={roles ?? []}
                      isLoading={rolesLoading || externalDataLoading}
                      placeholder={t('Choose Roles')}
                      value={viewRoles}
                      onChange={setViewRolesDropdown}
                      onFocus={handleAnyDropdownFocus}
                      isOptionDisabled={isRoleDisabled}
                      classNamePrefix="PermissionSelector"
                      onInputChange={handleAnyDropdownFocus}
                    />
                  </StyledWizardField>
                </DropdownContainer>
              ) : null}
              {!small && (
                <>
                  {visibleControls.roles.edit ? (
                    <DropdownContainer>
                      <KizenTypography>
                        {labels?.specific?.edit ?? t('Edit')}
                      </KizenTypography>
                      <StyledWizardField
                        top={gutters.spacing(2, 2)}
                        data-qa-field="edit-roles"
                        data-qa-value={JSON.stringify(editRoles)}
                        flex
                      >
                        <MultiSelect
                          options={roles ?? []}
                          isLoading={rolesLoading || externalDataLoading}
                          placeholder={t('Choose Roles')}
                          value={editRoles}
                          onChange={setEditRolesDropdown}
                          onFocus={handleAnyDropdownFocus}
                          isOptionDisabled={isRoleDisabled}
                          classNamePrefix="PermissionSelector"
                          onInputChange={handleAnyDropdownFocus}
                        />
                      </StyledWizardField>
                    </DropdownContainer>
                  ) : null}
                  {visibleControls.roles.admin ? (
                    <DropdownContainer>
                      <KizenTypography>
                        {labels?.specific?.admin ?? t('Admin')}
                      </KizenTypography>
                      <StyledWizardField
                        top={gutters.spacing(2, 2)}
                        data-qa-field="admin-roles"
                        data-qa-value={JSON.stringify(adminRoles)}
                        flex
                      >
                        <MultiSelect
                          options={roles ?? []}
                          isLoading={rolesLoading || externalDataLoading}
                          placeholder={t('Choose Roles')}
                          value={adminRoles}
                          onChange={setAdminRolesDropdown}
                          onFocus={handleAnyDropdownFocus}
                          isOptionDisabled={isRoleDisabled}
                          classNamePrefix="PermissionSelector"
                          onInputChange={handleAnyDropdownFocus}
                        />
                      </StyledWizardField>
                    </DropdownContainer>
                  ) : null}
                </>
              )}
            </DropdownRow>
          </StyledWizardField>
        </StyledWizardField>
        <StyledWizardField top={inline ? 0 : gutters.spacing(4)} flex>
          <BoldTypography>
            {t('Specific Team Members')}
            {small ? ` (${t('View')})` : ''}
          </BoldTypography>
          <StyledWizardField top={small ? 12 : 17} flex>
            <DropdownRow>
              {visibleControls.teamMembers.view ? (
                <DropdownContainer>
                  {!small && (
                    <KizenTypography>
                      {labels?.specific?.view ?? t('View')}
                    </KizenTypography>
                  )}
                  <StyledWizardField
                    top={small ? 0 : 12}
                    data-qa-field="view-team-members"
                    data-qa-value={JSON.stringify(viewTeamMembers)}
                    flex
                  >
                    <MultiSelect
                      ref={viewTeamMemberSelectRef}
                      isLoading={externalDataLoading}
                      placeholder={t('Choose Team Members')}
                      value={viewTeamMembers}
                      onChange={setViewTeamMembersDropdown}
                      onFocus={handleAnyDropdownFocus}
                      isOptionDisabled={isTeamMemberSelected}
                      classNamePrefix="PermissionSelector"
                      hideRemoveIcon={({ value }) =>
                        value === owner?.id && small && !isDuplication
                      }
                      {...viewTeamMemberSelectProps}
                    />
                  </StyledWizardField>
                </DropdownContainer>
              ) : null}
              {!small && (
                <>
                  {visibleControls.teamMembers.edit ? (
                    <DropdownContainer>
                      <KizenTypography>
                        {labels?.specific?.edit ?? t('Edit')}
                      </KizenTypography>
                      <StyledWizardField
                        top={gutters.spacing(2, 2)}
                        data-qa-field="edit-team-members"
                        data-qa-value={JSON.stringify(editTeamMembers)}
                        flex
                      >
                        <MultiSelect
                          ref={editTeamMemberSelectRef}
                          isLoading={externalDataLoading}
                          placeholder={t('Choose Team Members')}
                          value={editTeamMembers}
                          onChange={setEditTeamMembersDropdown}
                          onFocus={handleAnyDropdownFocus}
                          isOptionDisabled={isTeamMemberSelected}
                          classNamePrefix="PermissionSelector"
                          hideRemoveIcon={({ value }) =>
                            value === owner?.id && small && !isDuplication
                          }
                          {...editTeamMemberSelectProps}
                        />
                      </StyledWizardField>
                    </DropdownContainer>
                  ) : null}
                  {visibleControls.teamMembers.admin ? (
                    <DropdownContainer>
                      <KizenTypography>
                        {labels?.specific?.admin ?? t('Admin')}
                      </KizenTypography>
                      <StyledWizardField
                        top={gutters.spacing(2, 2)}
                        data-qa-field="admin-team-members"
                        data-qa-value={JSON.stringify(adminTeamMembers)}
                        flex
                      >
                        <MultiSelect
                          ref={adminTeamMemberSelectRef}
                          isLoading={externalDataLoading}
                          placeholder={t('Choose Team Members')}
                          value={adminTeamMembers}
                          onChange={setAdminTeamMembersDropdown}
                          onFocus={handleAnyDropdownFocus}
                          isOptionDisabled={isTeamMemberSelected}
                          classNamePrefix="PermissionSelector"
                          hideRemoveIcon={({ value }) =>
                            !isDuplication && value === owner?.id
                          }
                          {...adminTeamMemberSelectProps}
                        />
                      </StyledWizardField>
                    </DropdownContainer>
                  ) : null}
                </>
              )}
            </DropdownRow>
          </StyledWizardField>
          <StyledWizardField ref={footerRef} top={bottom} flex />
        </StyledWizardField>
      </ModalContainer>
    </>
  );
};

PermissionSettings.propTypes = {
  onChange: PropTypes.func.isRequired,
  allowRolesInMultipleGroups: PropTypes.bool,
  allowTeamMembersInMultipleGroups: PropTypes.bool,
  top: PropTypes.number,
  bottom: PropTypes.number,
};

export default PermissionSettings;
