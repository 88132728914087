import useWidthMeasurement from 'hooks/useWidthMeasurement';
import { ItemsWrapper, VirtualItemsWrapper } from './styles';
import { VariableSizeList as List } from 'react-window';
import { InnerElement, VirtualParent } from './elements';
import Item from '../Item';
import { dropzoneIs } from 'components/DragAndDropLayout/helpers';
import {
  HorizontalDropzone,
  OverallDropzone,
} from 'components/DragAndDropLayout/Dropzone';
import OptionParent from '../OptionParent';
import { useCallback, useEffect, useRef } from 'react';

const EMPTY_ROW_HEIGHT = 54;
const FIRST_ROW_HEIGHT = 27;
const OTHER_ROW_HEIGHT = 25;
const DEFAULT_ROW_HEIGHT = 59;

const OptionsList = ({
  virtual = false,
  id,
  getFieldDropZone,
  manageAutoScroll,
  onMouseOut,
  virtualHeight,
  options,
  dropZone,
  childDropZone,
  dropFirstChild,
  draggingItem,
  editingItem,
  setDraggingItem,
  endDrag,
  getChildDropZone,
  editValue,
  setEditingItem,
  editable,
  removeItem,
  swapItemColumn,
  editOptions,
  collapseItem,
  expandItem,
  collapsedItems,
  setDropFirstChild,
  moveItemToBottom,
  onChangeScrolled,
  renderOption,
  renderEmpty,
  isGroupedItem = false,
  disabledDropZoneText,
}) => {
  const listRef = useRef();
  const { ref, width, plainRef } = useWidthMeasurement();

  const handleEndDrag = useCallback(
    (data) => {
      endDrag(data);
      // we need reset list, once item has been moved,
      // to re-calculate correct rows height
      if (listRef?.current && isGroupedItem) {
        listRef.current.resetAfterIndex(0, true);
      }
    },
    [endDrag, isGroupedItem]
  );

  useEffect(() => {
    // we need reset list, options has been changed
    // to re-calculate correct rows height
    if (listRef?.current && isGroupedItem) {
      listRef.current.resetAfterIndex(0, true);
    }
  }, [isGroupedItem, options]);

  const getItemSize = useCallback(
    (index) => {
      const nextItem = options?.[index + 1]?.isGroupedItem;
      return options?.[index]?.isGroupedItem
        ? nextItem
          ? EMPTY_ROW_HEIGHT // height for empty row
          : index === 0
            ? FIRST_ROW_HEIGHT // first row height
            : OTHER_ROW_HEIGHT // other rows height
        : DEFAULT_ROW_HEIGHT; //default row height
    },
    [options]
  );

  if (virtual) {
    return (
      <VirtualItemsWrapper
        ref={ref}
        key={id}
        onMouseMove={(ev) => {
          getFieldDropZone(id, ev);
          manageAutoScroll(id, ev, plainRef.current);
        }}
        onMouseLeave={(ev) => {
          onMouseOut?.(ev);
        }}
      >
        {dropzoneIs(dropZone, { position: 'overall', sectionId: id }) ? (
          <OverallDropzone text={disabledDropZoneText} />
        ) : null}
        {renderEmpty && options.length === 0 ? (
          renderEmpty({ id, dropZone })
        ) : (
          <List
            ref={listRef}
            height={virtualHeight}
            itemCount={options.length}
            itemSize={getItemSize}
            width={width}
            outerElementType={VirtualParent}
            innerElementType={InnerElement}
            itemKey={(index, data) => {
              return `${data.options[index].id}:${
                dropZone?.id === data.options[index].id
              }`;
            }}
            itemData={{
              options,
              dropZone,
              childDropZone,
              dropFirstChild,
              draggingItem,
              editingItem,
              setDraggingItem,
              endDrag: handleEndDrag,
              getChildDropZone,
              id,
              editValue,
              setEditingItem,
              editable,
              removeItem,
              swapItemColumn,
              editOptions,
              collapseItem,
              expandItem,
              collapsedItems,
              setDropFirstChild,
              moveItemToBottom,
              width,
              renderOption,
              renderEmpty,
            }}
            onScroll={(e) => {
              onChangeScrolled?.([e.scrollOffset === 0]);
            }}
          >
            {Item}
          </List>
        )}
      </VirtualItemsWrapper>
    );
  }

  return (
    <ItemsWrapper
      ref={ref}
      key={id}
      onMouseMove={(ev) => {
        getFieldDropZone(id, ev);
        manageAutoScroll(id, ev, plainRef.current);
      }}
      onMouseLeave={(ev) => {
        onMouseOut?.(ev);
      }}
    >
      {dropzoneIs(dropZone, { position: 'overall', sectionId: id }) ? (
        <OverallDropzone text={disabledDropZoneText} />
      ) : null}
      {renderEmpty &&
      typeof renderEmpty === 'function' &&
      options.length === 0 ? (
        renderEmpty({ id, dropZone })
      ) : (
        <>
          {dropzoneIs(dropZone, {
            sectionId: id,
            position: 'first',
          }) && !childDropZone ? (
            <HorizontalDropzone />
          ) : null}
          {options.map((option, index) => {
            return (
              <OptionParent
                key={option.id}
                dropZone={dropZone}
                option={option}
                childDropZone={childDropZone}
                dropFirstChild={dropFirstChild}
                draggingItem={draggingItem}
                editingItem={editingItem}
                setDraggingItem={setDraggingItem}
                endDrag={endDrag}
                getChildDropZone={getChildDropZone}
                id={id}
                index={index}
                editValue={editValue}
                setEditingItem={setEditingItem}
                editable={editable}
                removeItem={removeItem}
                swapItemColumn={swapItemColumn}
                editOptions={editOptions}
                collapseItem={collapseItem}
                expandItem={expandItem}
                collapsedItems={collapsedItems}
                setDropFirstChild={setDropFirstChild}
                moveItemToBottom={moveItemToBottom}
                columnWidth={width}
                renderOption={renderOption}
              />
            );
          })}
        </>
      )}
    </ItemsWrapper>
  );
};

export default OptionsList;
