import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';
import { CUSTOM_OBJECTS } from 'queries/query-keys';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import CustomObjectsService from 'services/CustomObjectsService';
import { FORCE_ALL_RECORDS_SIZE } from 'services/helpers';
import {
  DEFAULT_MY_ACCOUNT,
  DEFAULT_TOOLBAR_ITEMS,
  getTranslationKeyMapping,
  PREVIEW_FLAG_ITEM_IDS,
} from '../constants';
import useToolbarAccessFiltering from './useToolbarAccessFiltering';
import { PluginContext } from 'ts-components/Plugins/PluginContext';

const doesSearchMatch = (item, term, translatedKeys) => {
  if (!term) {
    return true;
  }

  if (item.isDynamic) {
    return true;
  }

  if (item.label.toLowerCase().includes(term.toLowerCase())) {
    return true;
  }

  const translatedLabel = translatedKeys[item.id];

  return translatedLabel?.toLowerCase().includes(term.toLowerCase()) ?? false;
};

const useLeftColumn = (rightColumnItemsMap = {}, searchTerm) => {
  const prereleaseFeatures = usePreReleaseFeatures();
  const filterToolbar = useToolbarAccessFiltering();

  const itemEnabledByFeature = useCallback(
    (item) => {
      if (prereleaseFeatures) {
        return true;
      }

      return !PREVIEW_FLAG_ITEM_IDS.includes(item.id);
    },
    [prereleaseFeatures]
  );

  const { toolbarItems } = useContext(PluginContext);

  const { defaultItemsMap, defaultItemsKeys } = useMemo(() => {
    const resDefault = DEFAULT_TOOLBAR_ITEMS.reduce((acc, curr) => {
      const children =
        curr.children?.reduce((childAcc, childCurr) => {
          return {
            ...childAcc,
            [childCurr.id]: childCurr,
          };
        }, {}) ?? {};
      return {
        ...acc,
        [curr.id]: curr,
        ...children,
      };
    }, {});

    const resMyAccount = DEFAULT_MY_ACCOUNT.children.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr,
      };
    }, {});

    const resPluginToolbarItems = toolbarItems.reduce((acc, curr) => {
      const item = {
        ...curr,
        id: `${curr.plugin_api_name}-${curr.api_name}`,
        isDefault: true,
        isLocked: false,
        type: 'item',
        widthPercent: 50,
      };

      return {
        ...acc,
        [item.id]: item,
      };
    }, {});

    const res = {
      ...resDefault,
      ...resMyAccount,
      ...resPluginToolbarItems,
    };

    return { defaultItemsMap: res, defaultItemsKeys: Object.keys(res) };
  }, [toolbarItems]);

  const leftHandDefaultItems = useMemo(() => {
    return defaultItemsKeys
      .filter((x) => !rightColumnItemsMap[x])
      .map((x) => defaultItemsMap[x])
      .filter((i) => i.type === 'item');
  }, [rightColumnItemsMap, defaultItemsKeys, defaultItemsMap]);

  const { t } = useTranslation();

  const { data: customObjects, isLoading } = useQuery(
    [...CUSTOM_OBJECTS.LIST, FORCE_ALL_RECORDS_SIZE],
    () =>
      CustomObjectsService.getCustomObjectList({
        size: FORCE_ALL_RECORDS_SIZE,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const customObjectOptions = useMemo(() => {
    return (
      customObjects?.results?.map((object) => {
        return {
          label: object.objectName,
          type: 'item',
          isDefault: false,
          isCustomObject: true,
          id: object.id,
          icon: 'bars-light',
        };
      }) ?? []
    );
  }, [customObjects]);

  const leftColumnOptions = useMemo(() => {
    const results = [
      {
        label: t('New Category (Drag to Create)'),
        isDefault: false,
        isDynamic: true,
        id: 'new_category',
        isLocked: false,
      },
      {
        label: t('New Custom URL (Drag to Create)'),
        icon: 'toolbar-external-link',
        isDefault: false,
        isDynamic: true,
        id: 'new_custom_url',
        isLocked: false,
        hasPoppedUp: false,
        hasPopup: true,
      },
      {
        label: t('Recent Records (Drag to Create)'),
        isDefault: false,
        isDynamic: true,
        id: 'new_recent_records',
        isLocked: false,
        hasPoppedUp: false,
        hasPopup: true,
        type: 'category',
        children: [],
        dynamicallyPopulated: true,
      },
      ...customObjectOptions,
    ];

    return results;
  }, [t, customObjectOptions]);

  const [leftColumnState, setLeftColumState] = useState(leftColumnOptions);

  const leftColumnStateWithDefaults = useMemo(() => {
    const results = [...leftColumnState];

    const leftColumnMap = results.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr,
      };
    }, {});

    leftHandDefaultItems.forEach((item) => {
      if (!leftColumnMap[item.id]) {
        results.push({
          ...item,
          widthPercent: 100,
        });
      }
    });

    return results;
  }, [leftColumnState, leftHandDefaultItems]);

  const itemsOrdering = useMemo(
    () => leftColumnStateWithDefaults.map(({ id }) => id),
    [leftColumnStateWithDefaults]
  );

  useEffect(() => {
    setLeftColumState(leftColumnOptions);
  }, [leftColumnOptions]);

  const filteredLeftColumnState = useMemo(() => {
    const translatedKeys = getTranslationKeyMapping(t);

    return filterToolbar(
      leftColumnStateWithDefaults.filter((s) => {
        return (
          (s.isDefault || !rightColumnItemsMap[s.id]) &&
          doesSearchMatch(s, searchTerm, translatedKeys) &&
          itemEnabledByFeature(s)
        );
      })
    );
  }, [
    leftColumnStateWithDefaults,
    rightColumnItemsMap,
    searchTerm,
    itemEnabledByFeature,
    filterToolbar,
    t,
  ]);

  return {
    items: filteredLeftColumnState,
    setItems: setLeftColumState,
    loading: isLoading,
    itemsOrdering,
  };
};

export default useLeftColumn;
