import { useTranslation } from 'react-i18next';
import { useToast, toastVariant } from 'components/ToastProvider';
import { usePrefetchQuery } from 'queries/helpers';
import { ACTIVITIES } from 'queries/query-keys';
import { useQuery } from 'react-query';
import CustomObjectsService from 'services/CustomObjectsService';
import ActivityService from 'services/ActivityService';
import LoggableActivityService from 'services/LoggableActivityService';
import { getOriginalError } from 'services/AxiosService';
import { FORCE_ALL_RECORDS_SIZE } from 'services/helpers';

export const useActivitiesQuery = (opts) => {
  return useQuery({
    queryKey: ACTIVITIES.ALL,
    queryFn: () => ActivityService.getActivities(),
    ...opts,
  });
};

export const useLoggableActivitiesQuery = (opts = {}) => {
  const { customObjectId, ...queryOpts } = opts;

  const params = {
    customObjectId,
    ordering: 'name',
    detail: 'light',
  };

  return useQuery({
    queryKey: ACTIVITIES.LOGGABLE(params),
    queryFn: () => {
      return LoggableActivityService.getActivityFullList(params, true);
    },
    ...queryOpts,
  });
};

/**
 * Access the activity default relationship data
 */
export const useDefaultActivityRelationshipsQuery = (opts) => {
  return useQuery(
    ACTIVITIES.DEFAULT_RELATIONSHIPS,
    async () => {
      const data = await CustomObjectsService.getCustomObjectList({
        size: FORCE_ALL_RECORDS_SIZE,
        defaultOnActivities: true,
        customOnly: false,
      });
      return data.results;
    },
    {
      refetchOnWindowFocus: opts?.refetchOnWindowFocus,
    }
  );
};

export const useActivityErrorToastQuery = (
  activityObjectId,
  {
    enabled,
    initialData,
    retry,
    refetchInterval,
    refetchIntervalInBackground,
    refetchOnWindowFocus = false,
  } = {},
  apiOptions,
  errorCallback
) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  return useQuery(
    ACTIVITIES.ACTIVITY_CAMEL(activityObjectId),
    async () =>
      ActivityService.v2GetActivity(
        { activityObjectId },
        {
          ...apiOptions,
          skipErrorBoundary: (error) => error.response?.status === 404,
        }
      ),
    {
      onError: (err) => {
        let error = err;
        error = ActivityService.notFoundError(error);
        if (errorCallback && typeof errorCallback === 'function') {
          errorCallback(error);
        } else {
          const orig = getOriginalError(error);
          showToast({
            message:
              orig?.message ||
              t(
                'There was an error loading the {{activityName}}. Please fix and try again.',
                {
                  activityName: t('Chosen Activity'),
                }
              ),
            variant: toastVariant.FAILURE,
          });
        }
      },
      enabled,
      initialData,
      retry,
      refetchInterval,
      refetchIntervalInBackground,
      refetchOnWindowFocus,
      useErrorBoundary: false,
    }
  );
};

/**
 * Call me near the top of a tree that may need the default activity activity relat
 */
export const useDefaultActivityRelationshipsPrefetch = () => {
  usePrefetchQuery((queryClient) =>
    queryClient.prefetchQuery(ACTIVITIES.DEFAULT_RELATIONSHIPS, async () => {
      const data = await CustomObjectsService.getCustomObjectList({
        size: FORCE_ALL_RECORDS_SIZE,
        defaultOnActivities: true,
        customOnly: false,
      });
      return data.results;
    })
  );
};
