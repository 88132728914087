import { useRef, useCallback, useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { getRecordsIsFilteringSelector } from 'store/customObjectsRecordsPage/selectors';
import { getBusinessClientObject } from 'store/authentication/selectors';
import { getIsContactsFiltering } from 'store/contactsPage/selectors';

import Collapse from 'react-bootstrap/Collapse';
import Button from 'components/Button';
import DashboardGrid from 'components/DashboardGrid';
import GroupSelector from 'components/DashboardGrid/GroupSelector';
import useDashboardGridChildren, {
  MODES,
} from 'components/DashboardGrid/hooks/useDashboardChildren';
import {
  DashboardGridContainerBox,
  DashboardGridPageContainer,
} from 'components/DashboardGrid/styles';
import { getDataQAForInput } from 'components/Inputs/helpers';
import Loader from 'components/Kizen/Loader';
import { PageContentContainer } from 'components/Layout/PageContentWidth';
import PageToolbar, {
  FilterGroupSelector,
  PageSearchInput,
  PageToolbarFiltersButton,
  PageToolbarSection,
} from 'components/Layout/PageToolbar';
import { useTranslation } from 'react-i18next';
import { FilterContainer, StyledBigListTitle, StyledContainer } from './styles';
import { useHistory, useParams } from 'react-router-dom';
import { PageToolbarTitle } from './PageToolbarTitle';
import ChartModal from './modal';
import useModal from 'components/Modals/useModal';
import ResettableDashlet from 'pages/Dashboard/components/Dashlet/Resettable';
import useDashboardInteraction from 'components/DashboardGrid/hooks/useDashboardInteraction';
import ClientFilterDropdown2 from 'pages/Common/components/ClientFilterDropdown2';
import ChartPermission from './ChartPermission';
import { AREA_RESPONSES } from 'components/Wizards/RTDV/types';
import { QuickFilters } from 'components/Filters/QuickFilters';
import { DataManagerWrapper } from 'pages/Dashboard/context/dataManager/context';
import { useResetAll } from 'pages/Dashboard/context/dataManager/getters';
import { DashboardStateWrapper } from 'pages/Dashboard/context/persistentState';
import { useClearFilter } from 'hooks/useClearFilter';
import { ErrorMessage } from 'components/ErrorMessage';
import { DashboardWarningText } from 'pages/Dashboard/styles';
import getDashboardUrl from 'utility/getDashboardUrl';
import { deferExecution } from 'utility/defer';
import { toastVariant, useToast } from 'components/ToastProvider';

const NoChartsMessage = () => {
  const { t } = useTranslation();

  return (
    <ErrorMessage
      title={t('No Charts Available')}
      text={
        <DashboardWarningText as="p">
          {t(
            'There are currently no chart groups that you have access to in this business. To get started, please click the Manage Charts Groups button at the top right of the screen'
          )}
        </DashboardWarningText>
      }
      dashboard
    />
  );
};

const ObjectCharts = ({
  mobile,
  model,
  groupId,
  filterGroup,
  groups,
  handleChangeGroup,
  filterInfo,
  hasFilterError,
  openMenu,
  search,
  onChangeSearch,
  recordsCountAndNew,
  chartGroupConfigProps,
  fullFilterObject,
  toggleMenu,
  quickFilters,
  quickFilterConfig,
  allFields,
  applyFiltersAction,
  applyQuickFilters,
  saveGroupAction,
  deleteGroupAction,
  setFilterName,
  clearFilter,
  preReleaseFeatures,
  updateFilterCount,
  errorsReturned,
  isLoadingView,
}) => {
  const objectId = model?.id;
  const isForContacts = model?.fetchUrl === 'client';
  const {
    refetchDashboard,
    currentDashboard,
    currentDashboardPermission,
    dashboardQueryKey,
    persistentDashboardState,
    setPersistentDashboardState,
    setCurrentDashboardId,
    dashboards,
  } = chartGroupConfigProps;

  const { t } = useTranslation();
  const history = useHistory();
  const [showToast] = useToast();

  const resetAll = useResetAll();

  const clientObject = useSelector(getBusinessClientObject);
  const isFiltering = useSelector(
    isForContacts ? getIsContactsFiltering : getRecordsIsFilteringSelector
  );
  const clientObjectId = clientObject?.id;

  const handleClickChartGroupSettings = useCallback(() => {
    if (isForContacts) {
      history.push('/clients/charts/settings');
    } else {
      history.push(`/custom-objects/${objectId}/charts/settings`);
    }
  }, [history, isForContacts, objectId]);

  const {
    breakpoint,
    setBreakpoint,
    editable,
    rowHeight,
    setContainerWidth,
    dashlets,
    hasEditPermission,
    resizeHandles,
    updateLayouts,
    mutateDashlet,
    forceShowUIElements,
    deleteDashlet,
    draggable,
  } = useDashboardInteraction({
    currentPermission: currentDashboardPermission,
    refetchDashboard,
    entityName: t('Chart'),
    dashboardQueryKey,
    currentDashboard,
    isLoading: false,
  });

  const [addDashletModalProps, addDashletModalTriggerProps] = useModal({
    handleSubmit: (chart) =>
      mutateDashlet({
        dashletData: chart,
        customObject: objectId,
        isUpdate: false,
      }),
  });

  const handleEditDashlet = useCallback(
    (newDashlet, oldDashlet, isTableColumnUpdate) => {
      return mutateDashlet({
        dashletData: { ...newDashlet, id: oldDashlet.id },
        isUpdate: true,
        customObject: newDashlet.homepageSelectedObjectId,
        skipOptimisticUpdate: !isTableColumnUpdate,
        layoutUpdate: isTableColumnUpdate,
      });
    },
    [mutateDashlet]
  );

  const renderChild = useCallback(
    ({ dashlet, index }) => {
      return (
        <ChartPermission
          dashlet={dashlet}
          model={model}
          ModalComponent={ChartModal}
          isDraggable={draggable && hasEditPermission}
          onDelete={deleteDashlet}
          canEdit={hasEditPermission}
          editDashlet={(newDashlet, _payload, isTableColumnUpdate) =>
            handleEditDashlet(newDashlet, dashlet, isTableColumnUpdate)
          }
        >
          <ResettableDashlet
            deleteDashlet={deleteDashlet}
            currentDashboard={currentDashboard}
            dashlet={dashlet}
            onDeleteDashlet={deleteDashlet}
            isDraggable={draggable && hasEditPermission}
            editDashlet={(newDashlet, _payload, isTableColumnUpdate) =>
              handleEditDashlet(newDashlet, dashlet, isTableColumnUpdate)
            }
            duplicateDashlet={(dashletData) => {
              return mutateDashlet({
                dashletData: { ...dashletData, id: dashlet.id },
                isDuplicate: true,
              });
            }}
            canEdit={hasEditPermission}
            dashboardId={currentDashboard.id}
            breakpoint={breakpoint}
            mobile={mobile}
            model={model}
            ModalComponent={ChartModal}
            search={search}
            filtersForCharts={fullFilterObject}
            objectForCharts={model}
            charts
            index={index}
          />
        </ChartPermission>
      );
    },
    [
      currentDashboard,
      handleEditDashlet,
      hasEditPermission,
      breakpoint,
      mobile,
      mutateDashlet,
      deleteDashlet,
      model,
      search,
      fullFilterObject,
      draggable,
    ]
  );

  const filteredDashlets = useMemo(() => {
    return dashlets.filter((dashlet) => {
      // The table chart is special and does not render on mobile, so we
      // want to remove it from the list before we try to render any dashlets
      if (mobile && dashlet.config.reportType === AREA_RESPONSES.TABLE) {
        return false;
      }
      return true;
    });
  }, [dashlets, mobile]);

  const filteredDashboards = useMemo(
    () => dashboards.filter((d) => !d.hidden),
    [dashboards]
  );

  const hasDashboards = useMemo(() => {
    return filteredDashboards.length > 0;
  }, [filteredDashboards]);

  const children = useDashboardGridChildren({
    renderChild,
    forceShowUIElements,
    dashlets: filteredDashlets,
    hasEditPermission,
    breakpoint,
    addDashletModalTriggerProps,
    mobile,
    dateFilter: {}, // no global date filters for charts
    mode: MODES.CHARTS,
    filtersForCharts: fullFilterObject,
  });

  const toggle = () => {
    toggleMenu((ps) => !ps);
  };

  const availableIds = useMemo(() => {
    return chartGroupConfigProps?.dashboards?.map(({ id }) => id) ?? [];
  }, [chartGroupConfigProps]);

  const managedHandleChangeGroup = useCallback(
    (...args) => {
      resetAll(availableIds);
      handleChangeGroup?.(...args);
    },
    [handleChangeGroup, resetAll, availableIds]
  );

  const managedHandleChangeSearch = useCallback(
    (...args) => {
      resetAll(availableIds);
      onChangeSearch?.(...args);
    },
    [resetAll, onChangeSearch, availableIds]
  );

  const managedApplyFilters = useCallback(
    (...args) => {
      resetAll(availableIds);
      applyFiltersAction?.(...args);
    },
    [applyFiltersAction, resetAll, availableIds]
  );

  const managedSaveGroup = useCallback(
    (...args) => {
      resetAll(availableIds);
      saveGroupAction?.(...args);
    },
    [saveGroupAction, resetAll, availableIds]
  );

  const managedDeleteGroup = useCallback(
    (...args) => {
      resetAll(availableIds);
      deleteGroupAction?.(...args);
    },
    [deleteGroupAction, resetAll, availableIds]
  );

  const handleClearFilter = useClearFilter(clearFilter, toggleMenu);

  const managedClearFilter = useCallback(
    (...args) => {
      resetAll(availableIds);
      handleClearFilter?.(...args);
    },
    [handleClearFilter, resetAll, availableIds]
  );

  const managedApplyQuickFilters = useCallback(
    (...args) => {
      resetAll(availableIds);
      applyQuickFilters?.(...args);
    },
    [resetAll, applyQuickFilters, availableIds]
  );

  const previousGroupId = useRef(groupId);
  const previousSearch = useRef(search);

  const hasNoDashboards = useMemo(
    () => !isLoadingView && filteredDashboards.length === 0,
    [isLoadingView, filteredDashboards]
  );

  // Mobile behaves differently than desktop, and the filters are not
  // controlled by the data manager. We also only have filter groups available,
  // so only need to trigger a reset when the groupId or the search term changes
  useEffect(() => {
    if (
      mobile &&
      (previousGroupId.current !== groupId || previousSearch.current !== search)
    ) {
      resetAll(availableIds);
      previousGroupId.current = groupId;
      previousSearch.current = search;
    }
  }, [groupId, mobile, search, resetAll, availableIds]);

  if (!model) {
    return <Loader loading />;
  }

  if (
    currentDashboard?.type === 'generic_dashboard' ||
    currentDashboard?.type === 'homepage' ||
    (currentDashboard === null && hasDashboards) ||
    (currentDashboard?.customObject &&
      currentDashboard?.customObject !== objectId)
  ) {
    const fallBackDashboardId = filteredDashboards[0]?.id;
    if (fallBackDashboardId) {
      showToast({
        message: t(
          'The chart group you tried to view is not accessible to you. Contact your administrator if you think this is in error'
        ),
        variant: toastVariant.FAILURE,
      });
      setCurrentDashboardId(fallBackDashboardId);
    }
    const clientId =
      clientObjectId === currentDashboard?.customObject ? clientObjectId : null;
    deferExecution(() =>
      history.push(
        getDashboardUrl(
          currentDashboard,
          currentDashboard?.customObject,
          clientId
        )
      )
    );
  }

  return (
    <>
      <DashboardStateWrapper
        setPersistentDashboardState={setPersistentDashboardState}
        persistentDashboardState={persistentDashboardState}
      >
        <DashboardGridPageContainer isMobile={mobile}>
          {!mobile ? (
            <PageContentContainer>
              <PageToolbar>
                <PageToolbarSection>
                  <FilterGroupSelector
                    value={groupId || 'none'}
                    options={groups}
                    onChange={managedHandleChangeGroup}
                    title={t('Change Group')}
                    link={
                      isForContacts
                        ? '/clients/filter-groups'
                        : `/custom-objects/${objectId}/filter-groups`
                    }
                  />
                  <PageToolbarFiltersButton
                    badge={filterInfo.numberOfFilters}
                    showBackground={openMenu}
                    onClick={toggle}
                    errors={hasFilterError}
                  />
                  <PageSearchInput
                    placeholder={
                      isForContacts
                        ? `${t('Find Contacts')}`
                        : `${t('Find')} ${model.entityName} ${t('Records')}`
                    }
                    value={search}
                    onChange={managedHandleChangeSearch}
                    isClearable
                    loading={isFiltering}
                    {...getDataQAForInput('search-co-records', 'search')}
                  />
                </PageToolbarSection>
                <PageToolbarTitle
                  data-qa="header-record-count"
                  count={recordsCountAndNew}
                  single={
                    isForContacts
                      ? t('Contact')
                      : `${model.entityName} ${t('Record')}`
                  }
                  plural={
                    isForContacts
                      ? t('Contacts')
                      : `${model.entityName} ${t('Records')}`
                  }
                />
                <PageToolbarSection fixed>
                  <Button color="blue" onClick={handleClickChartGroupSettings}>
                    {t('Manage Chart Groups')}
                  </Button>
                  {hasEditPermission ? (
                    <Button {...addDashletModalTriggerProps}>
                      {t('Add Chart')}
                    </Button>
                  ) : null}
                </PageToolbarSection>
              </PageToolbar>
              <FilterContainer>
                <Collapse mountOnEnter in={openMenu}>
                  <ClientFilterDropdown2
                    key={
                      // the truthy value of isAllContactsGroup is a UUID
                      filterInfo.isAllContactsGroup || filterInfo.key
                    }
                    filterData={filterInfo.config}
                    and={filterInfo.and}
                    applyFiltersAction={managedApplyFilters}
                    saveGroupAction={managedSaveGroup}
                    deleteGroupAction={managedDeleteGroup}
                    filterName={filterInfo.name}
                    setFilterName={setFilterName}
                    updateFilterCount={updateFilterCount}
                    numberOfFilters={filterInfo.numberOfFilters}
                    groupId={groupId}
                    filterGroup={filterGroup}
                    clearFilter={managedClearFilter}
                    betaPreview={preReleaseFeatures}
                    noSticky
                    objectType={
                      isForContacts ? 'client_client' : model.objectType
                    }
                    errorsReturned={errorsReturned}
                    customObjectId={model?.id}
                  />
                </Collapse>
              </FilterContainer>
              {quickFilters ? (
                <QuickFilters
                  quickFilters={quickFilters}
                  quickFilterConfig={quickFilterConfig}
                  fullFilterObject={fullFilterObject}
                  search={search}
                  resultsCount={recordsCountAndNew}
                  model={model}
                  fields={allFields}
                  onChange={managedApplyQuickFilters}
                  errors={filterInfo?.errors?.quickFiltersErrors}
                  hasFilterErrors={filterInfo?.errors}
                />
              ) : null}
            </PageContentContainer>
          ) : (
            <>
              <StyledBigListTitle
                count={recordsCountAndNew}
                single={
                  isForContacts
                    ? t('Contact')
                    : `${model.entityName} ${t('Record')}`
                }
                plural={
                  isForContacts
                    ? t('Contacts')
                    : `${model.entityName} ${t('Records')}`
                }
              />
              <GroupSelector {...chartGroupConfigProps} mobile />
            </>
          )}
          {((!currentDashboard || currentDashboard.error) && !isLoadingView) ||
          hasNoDashboards ? (
            <NoChartsMessage />
          ) : !filterInfo?.currentErrors?.length ? (
            <Loader loading={isLoadingView}>
              <DashboardGridContainerBox>
                <StyledContainer mobile={mobile}>
                  <DashboardGrid
                    updateLayouts={updateLayouts}
                    resizeHandles={resizeHandles}
                    rowHeight={rowHeight}
                    editable={editable}
                    setBreakpoint={setBreakpoint}
                    setContainerWidth={setContainerWidth}
                  >
                    {children}
                  </DashboardGrid>
                </StyledContainer>
              </DashboardGridContainerBox>
            </Loader>
          ) : (
            false
          )}
        </DashboardGridPageContainer>
      </DashboardStateWrapper>
      <ChartModal
        {...addDashletModalProps}
        objectId={objectId}
        contacts={isForContacts}
        model={model}
      />
    </>
  );
};

const ObjectChartsWithDataManager = (props) => {
  const { chartId } = useParams();

  const objectId = props.model?.id;

  if (!chartId) {
    return <ObjectCharts {...props} />;
  }

  return (
    <DataManagerWrapper key={chartId} instanceId={chartId} parentId={objectId}>
      <ObjectCharts {...props} />
    </DataManagerWrapper>
  );
};

export default ObjectChartsWithDataManager;
