import {
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
  useEffect,
  forwardRef,
} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';

import { borderRadii, scrollbarCss } from 'app/spacing';
import { grayScale, colorsText } from 'app/colors';
import { TextSpan } from 'app/typography';
import { useLength } from 'hooks/useLength';
import InsertMergeField from 'components/Kizen/InsertMergeField';
import useField from 'hooks/useField';
import BaseInput from '../TextInput/BaseInput';
import BaseInputLayout from '../TextInput/BaseInputLayout';
import { hasInputControl, MaybeInputControl } from '../InputControl';
import ResizeWrapper from '../ResizeWrapper';
import { applyRef } from '../props';
import Validation, { useValidation } from '../Validation';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useUrlFromValue } from '../helpers';
import { Button } from '@kizen/kds/Button';
import { TextEllipsisWithTooltip } from '@kizen/page-builder/internal/components/Kizen/Table';
import { Spacer } from '@kizen/kds/Spacer';
const MERGE_HEIGHT = 39;

const OutlineTextarea = styled(BaseInput)`
  ${scrollbarCss}
  /* Makes top spacing similar to the natural vertical flow of OutlineTextInput */
  ${({ showMerge }) =>
    showMerge
      ? css`
          margin-top: 9px;
          height: calc(100% - ${MERGE_HEIGHT + 23}px);
        `
      : css`
          margin-top: 9px;
          height: calc(100% - 23px);
        `}
  ${({ fullHeight }) =>
    fullHeight
      ? css`
          height: 100%;
        `
      : ''}
  /* Account for baseline to get accurate spacing */
  margin-bottom: 10px;
  /* Account for right scrollbar width */
  padding-right: 6px;
  padding-left: 10px;
  overflow-y: scroll;
  width: 100%;
  line-height: 1.15;
  resize: none;
`;

export const OutlineLayout = styled(BaseInputLayout)`
  border-radius: ${borderRadii.small};
  ${({ fullHeight }) =>
    fullHeight
      ? css`
          height: 100%;
        `
      : ''}
  border: none;
  // no blue on hover
  ${({ disabled, focused }) =>
    !disabled &&
    !focused &&
    css`
      &:hover {
        border-color: ${grayScale.medium};
      }
    `}
`;

export const MergeColumn = styled.div`
  width: 100%;
  ${({ fullHeight, maxWidth }) => css`
    height: ${fullHeight ? '100%' : null};
    max-width: ${maxWidth || null};
  `}
  ${({ showMerge }) =>
    showMerge &&
    css`
      div.merge {
        width: 100%;
        padding: 3px 0 2px; // vertically center button
        border-bottom: 1px solid ${grayScale.medium};
        height: ${MERGE_HEIGHT}px;
      }
    `}
`;

export const CountWrapper = styled.label`
  display: flex;
  justify-content: space-between;
  margin: 12px 0 5px;
`;

export const StyledLabel = styled(TextSpan)`
  line-height: 125%;
  color: ${(props) =>
    props.disabled ? grayScale.mediumDark : colorsText.dark};
  margin-bottom: 12px;
`;

const useRunAfterUpdate = () => {
  const afterPainRef = useRef(null);
  useLayoutEffect(() => {
    if (afterPainRef.current) {
      afterPainRef.current();
      afterPainRef.current = null;
    }
  });
  const runAfterUpdate = (fn) => {
    afterPainRef.current = fn;
    return afterPainRef.current;
  };
  return runAfterUpdate;
};

const ReadOnlyText = styled(TextEllipsisWithTooltip)`
  padding: 0 6px 0px 10px;
  margin: 9px 0 10px;
  height: 32px;
`;

const OutlineLongText = forwardRef((props, ref) => {
  const {
    fieldId,
    className,
    value: valueProp = '',
    placeholder = null,
    disabled = null,
    error = null,
    onChange = null,
    onFocus = null,
    onBlur = null,
    showCountComponent = false,
    mergeFields = false,
    enableResize = true,
    setMenuOpen,
    selectionStart = null,
    setShown,
    handleSubmit,
    fullHeight,
    displayMaskedValue,
    displayUrlLink = false,
    ...others
  } = props;
  const { t } = useTranslation();
  const { assignFieldHandle } = useKeyBoardContext();

  const [focused, setFocused] = useState(false);
  const [value, setValue] = useField(valueProp);
  const [isCompact, setIsCompact] = useState(false);
  const validationRef = useRef();
  const textareaElem = useRef(null);
  const wrapperRef = useRef();
  const [, validationProps] = useValidation(validationRef, props);

  const mergeRef = useCallback(
    (el) => {
      applyRef(textareaElem, el);
      applyRef(ref, el);
    },
    [ref]
  );

  const [
    {
      maxLength,
      singleMessageCount,
      maxLengthForSingleMessage,
      countOfMessages,
      maxCountOfMessages,
      totalCount,
    },
    updateEvent,
  ] = useLength(0, Infinity);

  useEffect(() => {
    if (!valueProp && value) {
      setValue('');
    }
  }, [valueProp, value, setValue]);

  const runAfterUpdate = useRunAfterUpdate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (wrapperRef.current) {
      // InsertMergeField button incorrectly stacks below ~160px width
      // we adjust at 170 just for a little cushion
      setIsCompact(wrapperRef.current.offsetWidth <= 170);
    }
  });

  const textareaChangeEvent = useCallback(
    (text) => {
      // TODO Relocate merge field parsing seen in pages/AutomationEngine/dialogs/ActionWizard/utilities
      // for the send text action to this component if that approach becomes standard
      // for handling merge fields

      if (maxLength >= (text || '').length) {
        if (onChange) {
          onChange(text);
        }
        setValue(text);
        updateEvent(text);
      } else {
        const truncated = text.substring(0, maxLength);
        // TODO Be more polite here than cutting off any overflow
        if (onChange) {
          onChange(truncated);
        }
        setValue(truncated);
        updateEvent(truncated);
      }
    },
    [maxLength, updateEvent, onChange, setValue]
  );

  const handleSelect = useCallback(
    ([{ value: newValue }]) => {
      const selectValueDropdown = () => {
        const txtarea = textareaElem.current;
        const start = txtarea.selectionStart;
        const end = txtarea.selectionEnd;
        const sel = txtarea.value.substring(start, end);
        const finText = `${txtarea.value.substring(
          0,
          start
        )} ${newValue} ${txtarea.value.substring(end)}`;

        textareaChangeEvent(finText);
        txtarea.focus();
        runAfterUpdate(() => {
          txtarea.selectionEnd = end - sel.length + newValue.length + 1;
        });
      };

      selectValueDropdown(newValue);
    },
    [runAfterUpdate, textareaChangeEvent]
  );
  useEffect(() => {
    if (selectionStart !== null && textareaElem && textareaElem.current) {
      textareaElem.current.selectionStart = selectionStart;
      textareaElem.current.selectionEnd = selectionStart;
      textareaElem.current.focus({ preventScroll: true });
    }
  }, [textareaElem, selectionStart]);

  const {
    'data-field-type': _1,
    'data-field-id': _2,
    ...forInputControl
  } = props;

  const editRef = useRef(false);

  assignFieldHandle(fieldId, {
    customTab: () => {
      setShown?.(false);
      handleSubmit?.();
      setFocused(false);
    },
    customFocus: () => {
      if (!editRef.current) {
        textareaElem.current?.focus();
        textareaElem.current?.blur();
        setShown?.(true);
        setFocused(true);
        onFocus();
      }
      return textareaElem.current;
    },
    customEnter: (e) => {
      if (!editRef.current) {
        setFocused(false);
        setShown?.(false);
        return false;
      } else {
        setFocused(!(e.ctrlKey + e.metaKey));
        setShown?.(!(e.ctrlKey + e.metaKey));
        if (e.ctrlKey + e.metaKey) {
          handleSubmit?.();
        }
        return !(e.ctrlKey + e.metaKey);
      }
    },
    customUp: () => {
      if (!editRef.current) {
        textareaElem.current.focus();
      }
    },
    customDown: () => {
      if (!editRef.current) {
        textareaElem.current.focus();
      }
    },
    customSpace: () => {
      textareaElem.current.focus();
    },
    customInput: () => {
      if (!editRef.current) {
        textareaElem.current.focus();
        const caretPosition = textareaElem.current.value?.length || 0;
        textareaElem.current.setSelectionRange(caretPosition, caretPosition);
      }
    },
    disabled,
  });

  const { urlFromValue, urlFromValueLinkProps, linkButtonProps } =
    useUrlFromValue(value, !displayMaskedValue && displayUrlLink);

  return (
    <MaybeInputControl variant="outline" forInput {...forInputControl}>
      <OutlineLayout
        ref={validationRef}
        className={!hasInputControl(props) && className}
        focused={focused}
        disabled={disabled}
        error={error}
        fullHeight={fullHeight}
      >
        <MergeColumn
          ref={wrapperRef}
          fullHeight={fullHeight}
          showMerge={mergeFields}
          maxWidth={urlFromValue && !disabled ? 'calc(100% - 36px)' : '100%'}
        >
          <ResizeWrapper
            focused={focused}
            fullHeight={fullHeight}
            enableResize={!disabled && enableResize}
            minHeight={mergeFields ? 135 : 63}
            minWidth="230"
            addBorder={!error}
            error={!!error}
          >
            {mergeFields && (
              <div className="merge">
                <InsertMergeField
                  onChange={handleSelect}
                  mergeFields={
                    Array.isArray(mergeFields) ? mergeFields : undefined
                  }
                  inModal // stop it getting pushed up
                  setMenuOpen={setMenuOpen}
                  isCompact={isCompact}
                />
              </div>
            )}
            {urlFromValue && disabled ? (
              <ReadOnlyText {...urlFromValueLinkProps}>{value}</ReadOnlyText>
            ) : (
              <OutlineTextarea
                focused={focused}
                showMerge={mergeFields}
                ref={mergeRef}
                as="textarea"
                value={value}
                onChange={(ev) => {
                  textareaChangeEvent(ev.target.value, ev);
                }}
                placeholder={placeholder}
                disabled={disabled}
                onFocus={(ev) => {
                  setFocused(true);
                  editRef.current = true;
                  if (onFocus) onFocus(ev);
                }}
                onBlur={(ev) => {
                  setFocused(false);
                  editRef.current = false;
                  if (onBlur) onBlur(ev);
                }}
                enableResize={false}
                {...others}
              />
            )}
          </ResizeWrapper>
        </MergeColumn>
        {urlFromValue && !disabled ? (
          <>
            <Spacer size={8} />
            <Button {...linkButtonProps} />
            <Spacer size={15} />
          </>
        ) : null}
      </OutlineLayout>
      <Validation {...validationProps} />
      {showCountComponent && (
        <CountWrapper>
          <StyledLabel>
            {`${singleMessageCount}/${maxLengthForSingleMessage} ${t(
              'characters left in message'
            )} ${countOfMessages} ${t('of')} ${maxCountOfMessages}`}
          </StyledLabel>
          <StyledLabel>
            {`${totalCount} ${t('of')} ${maxLength} ${t('total characters')}`}
          </StyledLabel>
        </CountWrapper>
      )}
    </MaybeInputControl>
  );
});

OutlineLongText.displayName = 'OutlineLongText';

export default OutlineLongText;
