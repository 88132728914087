import { useAsyncFn } from 'react-use';

import PerformAction from 'services/PerformActionService';

export const BULK_ACTION_TYPES = {
  page: 'page',
  search: 'search',
  group: 'group',
  all: 'all',
  individual: 'individual',
  none: 'none',
};

export const SEND_EMAIL_IDENTIFIER = 'bulk_send_email';
export const SEND_TEXT_IDENTIFIER = 'bulk_send_text';
export const SEND_SURVEY_IDENTIFIER = 'bulk_send_survey';
export const CHANGE_TAGS_IDENTIFIER = 'bulk_change_tags';
export const MODIFY_AUTOMATION_IDENTIFIER = 'bulk_start_automation';
export const MANAGE_TEAM_IDENTIFIER = 'bulk_manage_associations';
export const MANAGE_SUBSCRIPTIONS = 'bulk_manage_subscriptions';
export const EXPORT_IDENTIFIER = 'bulk_export_to_csv';
export const ARCHIVE_IDENTIFIER = 'bulk_archive';
export const CHANGE_FIELD_IDENTIFIER = 'bulk_change_field_value';

export const CO_BULK_ACTIONS = {
  [MODIFY_AUTOMATION_IDENTIFIER]: 'start_automation',
  [CHANGE_FIELD_IDENTIFIER]: 'change_field_value',
  [MANAGE_TEAM_IDENTIFIER]: 'manage_team',
  [EXPORT_IDENTIFIER]: 'export',
  individual: 'individual',
  [ARCHIVE_IDENTIFIER]: 'archive',
};

const defaultSteps = (t) => [
  { title: t('Choose Action') },
  { title: t('Send Email') },
  { title: t('Confirm') },
];

export const getPerformActionOptions = (t, entityNamePlural = 'Contacts') => [
  {
    value: SEND_EMAIL_IDENTIFIER,
    label: t('Send Email'),
    steps: defaultSteps(t),
    titleForModal: t('Send Email to'),
  },
  // temporary disabled 'Send Text' action as to https://kizen.atlassian.net/browse/KZN-8261
  // {
  //   value: SEND_TEXT_IDENTIFIER,
  //   label: t('Send Text'),
  //   steps: [
  //     { title: t('Choose Action') },
  //     { title: t('Send Text') },
  //     { title: t('Confirm') },
  //   ],
  //   titleForModal: t('Send Text to'),
  //   isPrereleaseFeature: true,
  // },
  {
    value: CHANGE_TAGS_IDENTIFIER,
    label: t('Change Tags'),
    steps: [
      { title: t('Choose Action') },
      { title: t('Change Tags') },
      { title: t('Confirm') },
    ],
    titleForModal: t('Change Tags on'),
  },
  {
    value: CHANGE_FIELD_IDENTIFIER,
    label: t('Change Field Value'),
    steps: [
      { title: t('Choose Action') },
      { title: t('Change Field Value') },
      { title: t('Confirm') },
    ],
    titleForModal: t('Change Field Value on'),
  },
  {
    value: MODIFY_AUTOMATION_IDENTIFIER,
    label: t('Modify Automation'),
    steps: [
      { title: t('Choose Action') },
      { title: t('Modify Automation') },
      { title: t('Confirm') },
    ],
    titleForModal: t('Modify Automation on'),
  },
  {
    value: MANAGE_TEAM_IDENTIFIER,
    label: t('Manage Team'),
    steps: [
      { title: t('Choose Action') },
      { title: t('Manage Team') },
      { title: t('Confirm') },
    ],
    titleForModal: t('Manage Team on'),
  },
  {
    value: MANAGE_SUBSCRIPTIONS,
    label: t('Manage Subscriptions'),
    steps: [
      { title: t('Choose Action') },
      { title: t('Manage Subscriptions') },
      { title: t('Confirm') },
    ],
    titleForModal: t('Manage Subscription Lists on'),
  },
  {
    value: EXPORT_IDENTIFIER,
    label: t('Export to CSV'),
    steps: [
      { title: t('Choose Action') },
      { title: t('Export Options') },
      { title: t('Confirm') },
    ],
    titleForModal: t('Export'),
  },
  {
    value: ARCHIVE_IDENTIFIER,
    label: `${t('Archive')} ${entityNamePlural}`,
    steps: [{ title: t('Choose Action') }, { title: t('Confirm') }],
    titleForModal: t('Archive'),
  },
];

const getAction = (selectedAction, performActionOptions, secondaryAction) => {
  if (selectedAction?.value === 'bulk_start_automation') {
    return `${secondaryAction || 'start'}_automation`;
  }

  // we really want the selected action
  if (selectedAction?.value) {
    return selectedAction?.value;
  }

  // we now look at the available options
  if (performActionOptions?.length) {
    // we would prefer not to use email or text as they may a have a lesser count
    const preferedActions = performActionOptions.filter(
      ({ value }) => !['bulk_send_email', 'bulk_send_text'].includes(value)
    );

    return preferedActions?.length
      ? preferedActions[0].value
      : performActionOptions[0].value;
  }

  // a default
  return 'change_field_value';
};

const getCOActionPayload = (
  action,
  selection,
  records,
  recordsCount,
  groupOptions,
  groupId,
  search,
  filters,
  fieldId,
  performActionOptions,
  secondaryAction,
  automation
) => {
  const recordIds = selection.checkedIds;
  const actionIdentifier = getAction(
    action,
    performActionOptions,
    secondaryAction
  );

  const usesSecondaryAction =
    action?.value === 'bulk_start_automation' && Boolean(secondaryAction);

  const params = {
    action: usesSecondaryAction
      ? actionIdentifier
      : CO_BULK_ACTIONS[actionIdentifier],
    subtractRecordIds: selection.subtractIds,
    fieldId,
    automationId: automation?.value,
  };

  // todo add send email ?

  if (selection.value === BULK_ACTION_TYPES.page && records) {
    return { ...params, recordIds };
  }

  if (selection.value === BULK_ACTION_TYPES.group) {
    const group = groupOptions.find((el) => el.id === groupId);
    return { ...params, groupId: group ? group.id : 'all' };
  }

  if (selection.value === BULK_ACTION_TYPES.search && selection.checkedCount) {
    return {
      ...params,
      groupId: 'all',
      searchText: search || undefined,
      filters,
    };
  }

  if (
    selection.value === BULK_ACTION_TYPES.individual &&
    selection.checkedIds.length !== 0
  ) {
    return { ...params, recordIds };
  }
  return { ...params, groupId: 'all' };
};

// BE filter priority is: group > filters
export const useGetSummary = ({
  objectId,
  selection,
  records,
  recordsCount,
  groupOptions,
  groupId,
  search,
  filters,
  field,
  isContacts,
  selectedAction,
  performActionOptions,
  fieldValue,
  sendEmailStep,
  status,
  subscriptionItem,
  secondaryAction,
  automation,
}) => {
  const [{ value: summary = null, loading: isFetching }, getSummaryFn] =
    useAsyncFn(async () => {
      if (isContacts) {
        const commonParams = {
          action: getAction(
            selectedAction,
            performActionOptions,
            secondaryAction
          ),
        };

        const commonBody = {
          subtractClientIds: selection.subtractIds,
          fieldId: field?.id,
          fieldValue: fieldValue?.code,
          automationId: automation?.value,
        };

        // Handle params for subscription list selection
        const { subscriptionLists, subscriptionListOptions } = sendEmailStep;
        if (
          selectedAction?.value === SEND_EMAIL_IDENTIFIER &&
          subscriptionLists
        ) {
          if (subscriptionLists.length) {
            commonParams.subscriptionListIds = subscriptionLists;
          } else {
            // An empty selection means "all subscription lists"
            commonParams.subscriptionListIds = subscriptionListOptions.map(
              (opt) => opt.value
            );
          }
        }
        // special summary request for MANAGE_SUBSCRIPTIONS
        if (
          selectedAction?.value === MANAGE_SUBSCRIPTIONS &&
          status &&
          subscriptionItem
        ) {
          commonParams.subscriptionListIds = [subscriptionItem.value];
          commonParams.status = status;
        }

        if (selection.value === 'page' && records) {
          return PerformAction.getSummary(
            { ...commonBody, clientIds: selection.checkedIds },
            commonParams
          );
        }

        if (selection.value === 'group') {
          const group = groupOptions.find((el) => el.id === groupId);
          return PerformAction.getSummary(commonBody, {
            ...commonParams,
            groupId: group?.id,
          });
        }

        if (selection.value === 'search' && selection.checkedCount) {
          return PerformAction.getSummary(
            {
              ...commonBody,
              filters,
            },
            {
              ...commonParams,
              groupId: 'all',
              search: search || undefined,
            }
          );
        }

        if (
          selection.value === 'individual' &&
          selection.checkedIds.length !== 0
        ) {
          return PerformAction.getSummary(
            { ...commonBody, clientIds: selection.checkedIds },
            commonParams
          );
        }

        return PerformAction.getSummary(commonBody, {
          ...commonParams,
          groupId: 'all',
        });
      } else {
        return PerformAction.getSummary({
          objectId,
          ...getCOActionPayload(
            selectedAction,
            selection,
            records,
            recordsCount,
            groupOptions,
            groupId,
            search,
            filters,
            field?.id,
            performActionOptions,
            secondaryAction,
            automation
          ),
        });
      }
    }, [
      objectId,
      selection,
      records,
      recordsCount,
      groupOptions,
      groupId,
      search,
      filters,
      field?.id,
      isContacts,
      selectedAction,
      performActionOptions,
      fieldValue,
      sendEmailStep,
      status,
      subscriptionItem,
      secondaryAction,
    ]);
  return [{ summary, isFetching }, getSummaryFn];
};

export const moneyFormatter = (string) =>
  Intl.NumberFormat('en', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(string);

export const numberFormatter = (string) =>
  Intl.NumberFormat('en').format(string);
