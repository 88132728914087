import { QueryClient } from 'react-query';

/**
 * Do not use me anywhere *except*
 *
 * - In the root index.js file for <QueryClientProvider />
 * - In `./invalidate` module, abstracting invalidation helpers
 * - The integration platform WorkerManager
 *
 * When if you need access to `queryClient`, you can use `useQueryClient()` in a
 * react component.
 *
 * The single query client that we use to maintain results throughout the application
 * Read about me here: https://kizen.atlassian.net/wiki/spaces/KZN/pages/2000420865/React+Query+API+Data+Fetching
 */
export const unsafeRootQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // 1 minute:  How long mark this item as being fresh it is considered stale.
      staleTime: 60000,
      // 10 minutes: Stays in memory for this length of time.
      cacheTime: 600000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      retry: 3, // Retry all rejections 3 times
      retryOnMount: true, // Failed results will be retried if they are requested again
      useErrorBoundary: true, // Queries should fail to the EB when it rejects.
    },
    mutations: {
      retry: false, // Never retry failed mutations

      // Mutations should not arrive at EB since it would
      // clear local state that would likely lose user data
      useErrorBoundary: false,
    },
  },
});
