import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';
import { useTypeaheadWithScroll } from 'hooks/useTypeaheadWithScroll';
import { getServiceToUse } from '../helpers';
import { toastVariant, useToast } from 'components/ToastProvider';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import { TAGS_DICTIONARY } from '../constants';
import { snakeToCamelCase } from 'services/helpers';
import { useTranslation } from 'react-i18next';
import { FIELDS } from 'queries/query-keys';
import { numberOfOptionLimit } from 'components/GenericWizard/wizards/field/options/common';

export const useDynamicTagsManager = (
  field = EMPTY_OBJECT,
  model = EMPTY_OBJECT,
  activityObjectId = null,
  importedOptions = []
) => {
  const [showToast] = useToast();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const containerRef = useRef(null);

  const serviceToUse = useMemo(
    () => getServiceToUse(model, activityObjectId),
    [model, activityObjectId]
  );

  const [ordering, setOrdering] = useState('name');

  const searchTags = useCallback(
    ({ search = '', page = 1, ordering = 'name' }, options) => {
      if (!field?.id || !model?.id) return { results: [] };
      return serviceToUse.getDynamicTagsOptionsOrdered(
        field?.id,
        model?.id,
        page,
        search,
        ordering,
        options
      );
    },
    [serviceToUse, field, model]
  );

  const queryKey = useMemo(
    () => FIELDS.DYNAMIC_TAGS(field.id, model.id),
    [field.id, model.id]
  );
  const { search, setSearch, options, isLoading, isFetching, refetch } =
    useTypeaheadWithScroll({
      fetch: searchTags,
      params: { ordering },
      queryKey: queryKey,
      wrapperElement: containerRef.current,
    });

  const createTags = useCallback(async () => {
    let trimmedValues = [
      ...new Set(importedOptions.map((option) => option.name)),
    ].map((name) => ({ name }));

    if (importedOptions?.length > numberOfOptionLimit) {
      const message = t(
        'You have exceeded the maximum allowable options for this field type {{numberOfOptionLimit}}, so some options were not added.',
        { numberOfOptionLimit }
      );
      showToast({
        message,
        variant: toastVariant.FAILURE,
      });
      trimmedValues = importedOptions?.slice(
        0,
        numberOfOptionLimit - importedOptions.length
      );
    }
    try {
      await Promise.all(
        trimmedValues.map(({ name }) =>
          serviceToUse.createDynamicTag({
            fieldId: field.id,
            name,
            objectId: model.id,
          })
        )
      );
      queryClient.invalidateQueries(queryKey);
      refetch();
    } catch (error) {
      showToast({
        message: t('One or more options failed to be added.'),
        variant: toastVariant.FAILURE,
      });
      queryClient.invalidateQueries(queryKey);
      refetch();
    }
  }, [
    showToast,
    importedOptions,
    field,
    model,
    serviceToUse,
    refetch,
    queryKey,
    queryClient,
    t,
  ]);

  useEffect(() => {
    if (importedOptions && importedOptions.length > 0) {
      createTags();
    }
  }, [importedOptions, createTags, queryKey, queryClient, refetch]);

  const tags = useMemo(() => options.map(({ item }) => item), [options]);

  const headData = useMemo(
    () => ({
      meta: {
        sort: getSortValue(ordering),
        onSort: (column, direction) => {
          containerRef.current.scrollTo(0, 0);
          setOrdering(getOrderingParam({ column, direction }));
        },
      },
    }),
    [ordering]
  );

  const dictionary = useMemo(
    () =>
      (
        TAGS_DICTIONARY[snakeToCamelCase(field?.name)] ||
        TAGS_DICTIONARY.default
      ).getDictionary(t),
    [field?.name, t]
  );

  const onDeleteTag = useCallback(
    async (tag) => {
      try {
        await serviceToUse.deleteDynamicTag({
          fieldId: field.id,
          tagId: tag.id,
          objectId: model.id,
        });
      } finally {
        queryClient.invalidateQueries(queryKey);
        refetch();
      }
    },
    [field, model, serviceToUse, refetch, queryKey, queryClient]
  );

  const onCreateTag = useCallback(
    async (name) => {
      await serviceToUse.createDynamicTag({
        fieldId: field.id,
        name,
        objectId: model.id,
      });
      queryClient.invalidateQueries(queryKey);
      refetch();
    },
    [field, model, serviceToUse, refetch, queryKey, queryClient]
  );

  const onUpdateTag = useCallback(
    async (name, tag) => {
      await serviceToUse.updateDynamicTag({
        fieldId: field.id,
        name,
        tagId: tag.id,
        objectId: model.id,
      });
      queryClient.invalidateQueries(queryKey);
      refetch();
    },
    [field, model, serviceToUse, refetch, queryKey, queryClient]
  );

  const onReplaceTag = useCallback(
    async (tagId, newTagId) => {
      await serviceToUse.replaceDynamicTag(tagId, newTagId, field.id, model.id);
      queryClient.invalidateQueries(queryKey);
      refetch();
    },
    [field, model, serviceToUse, refetch, queryKey, queryClient]
  );

  return [
    {
      search,
      onChangeSearch: setSearch,
      onCreateTag,
      isLoading,
      isFetching,
      dictionary,
    },
    {
      tags,
      onDeleteTag,
      onUpdateTag,
      onReplaceTag,
      tableRef: containerRef,
      showTagLink: !activityObjectId,
      serviceToUse,
      isLoading,
      isFetching,
      headData,
      dictionary,
    },
  ];
};
