import useModal from '__components/Modals/useModal';
import { useCallback, useRef, useState } from 'react';
import { DeactivationModalProps } from '../DeactivationModal';

export type ShowDeactivationModalParams<R = any> = {
  handleSave: () => Promise<R>;
  handleDiscard?: (() => any) | null;
};

export type ShowDeactivationModalWithResultFn = (
  params: ShowDeactivationModalParams<boolean | null>
) => Promise<boolean | null>;

export const useDeactivationModal = (): {
  deactivationModalProps: DeactivationModalProps;
  showDeactivationModal: (params: ShowDeactivationModalParams) => void;
  showDeactivationModalWithResult: ShowDeactivationModalWithResultFn;
} => {
  const [saver, setSaver] = useState<(() => Promise<any>) | null>(null);
  const [discarder, setDiscarder] = useState<(() => any) | null>(null);

  const state = useRef<{
    ready: boolean;
    result: boolean | null;
    closed: boolean;
  }>({
    ready: false,
    result: false,
    closed: true,
  }).current;

  const [{ onHide, ...deactivationModalProps }, , { show }] = useModal({
    handleSubmit: async () => {
      await saver?.();
    },
    handleHide: () => {
      state.closed = true;
      setSaver(null);
      setDiscarder(null);
    },
  });

  const showDeactivationModal = useCallback(
    ({ handleSave, handleDiscard = null }: ShowDeactivationModalParams) => {
      setSaver(() => handleSave);
      setDiscarder(() => handleDiscard);
      show();
    },
    [show]
  );

  const showDeactivationModalWithResult = useCallback(
    ({
      handleSave,
      handleDiscard = null,
    }: ShowDeactivationModalParams<boolean | null>): Promise<
      boolean | null
    > => {
      state.result = null;
      state.ready = false;
      state.closed = false;

      showDeactivationModal({
        handleSave: async () => {
          state.result = await handleSave();
          state.ready = true;
        },
        handleDiscard: () => {
          state.ready = true;
          handleDiscard?.();
        },
      });

      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (state.ready || state.closed) {
            clearInterval(interval);
            resolve(state.result);
          }
        }, 10);
      });
    },
    [showDeactivationModal, state]
  );

  const defaultLeftBtnHandler = useCallback(() => {
    discarder?.();
    onHide();
  }, [discarder, onHide]);

  return {
    deactivationModalProps: {
      ...deactivationModalProps,
      onHide,
      defaultLeftBtnHandler,
    },
    showDeactivationModal,
    showDeactivationModalWithResult,
  };
};
