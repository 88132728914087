import { PRIMARY_OBJECT_UPLOAD_STEPS } from 'pages/SmartConnectors/constants';
import { PrimaryObjectUploadStep } from 'pages/SmartConnectors/types';
import { TFunction } from 'react-i18next';
import { useContext } from 'react';
import { PrimaryObjectUploadContext } from '../context';
import { MatchingStep } from './MatchingStep';
import { MappingStep } from './MappingStep';
import { PreviewStep } from './PreviewStep';
import { DeactivationModal } from '__pages/SmartConnectors/SmartConnector/DeactivationModal';

export const steps: Record<PrimaryObjectUploadStep, any> = {
  [PRIMARY_OBJECT_UPLOAD_STEPS.matching]: {
    idx: 0,
    key: PRIMARY_OBJECT_UPLOAD_STEPS.matching,
    label: (t: TFunction) => t('Unique Identifier(s) Matching'),
    component: MatchingStep,
  },
  [PRIMARY_OBJECT_UPLOAD_STEPS.mapping]: {
    idx: 1,
    key: PRIMARY_OBJECT_UPLOAD_STEPS.mapping,
    label: (t: TFunction) => t('Mapping Rules'),
    component: MappingStep,
  },
  [PRIMARY_OBJECT_UPLOAD_STEPS.preview]: {
    idx: 2,
    key: PRIMARY_OBJECT_UPLOAD_STEPS.preview,
    label: (t: TFunction) => t('Finalize & Preview'),
    component: PreviewStep,
  },
};

export const Steps = () => {
  const { step, deactivationModalProps } = useContext(
    PrimaryObjectUploadContext
  );
  const Component = steps[step].component;
  return (
    <>
      <Component />
      <DeactivationModal {...deactivationModalProps} />
    </>
  );
};
