import { SMART_CONNECTOR_STATUSES } from './constants';
import { SmartConnectorStatus } from './types';

export const getIsSmartConnectorActive = ({
  status,
}: {
  status: SmartConnectorStatus;
}) => {
  return (
    [
      SMART_CONNECTOR_STATUSES.operational,
      SMART_CONNECTOR_STATUSES.need_attention,
    ] as SmartConnectorStatus[]
  ).includes(status);
};
