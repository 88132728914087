import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import EmailAddressTextInput from '__components/Inputs/TextInput/presets/EmailAddress';

import { TopMenuButton } from '__components/Kizen/Menu';
import useThirdPartyAuth from '__components/Modals/ThirdPartyAuth/useThirdPartyAuth';
import IntegratedEmailModal from 'components/Modals/ThirdPartyAuth/modal';

import Select from '__components/Inputs/Select';

import { useEmailFromDomainEnabledEntitlement } from '__hooks/useEmailFromDomainEnabledEntitlement';

import { useExternalAccounts } from 'queries/models/teamMember';
import { getValueEmail } from 'services/helpers';
import { FIELD_TYPES } from 'utility/constants';

import { isErrorStatusCode } from 'pages/Account/pages/Profile/Forms/EmailIntegration';
import { IntegratedInboxSC } from '__hooks/useIntegratedInbox';
const EMPTY_ARRAY: any = [];

type EmailAddressProps = {
  formData: { reply_to_email?: string; default_integrated_inbox?: string };
  handleInputChange: (key: string, value: string, error: string) => void;
  validationProps: (key: string) => object;
  getKeyListenersProps: (key: string) => object;
};

const DEFAULT_SORT = '-email';

export const EmailAddress = ({
  formData,
  handleInputChange,
  validationProps,
  getKeyListenersProps,
  ...rest
}: EmailAddressProps) => {
  const { t } = useTranslation();

  // API keys data
  const chosenBusinessId = useSelector(
    ({ authentication }) =>
      !!authentication.chosenBusiness && authentication.chosenBusiness.id
  );

  const emailFromDomainEnabled = useEmailFromDomainEnabledEntitlement();

  const { data: integratedEmailExternalAccounts = EMPTY_ARRAY } =
    useExternalAccounts(
      { keepPreviousData: true, enabled: !emailFromDomainEnabled },
      {
        ordering: DEFAULT_SORT,
        include_sharing: true,
      }
    );

  const integratedEmailOptions = useMemo(
    () =>
      integratedEmailExternalAccounts
        .filter(
          ({ email_status }: IntegratedInboxSC) =>
            !isErrorStatusCode(email_status)
        )
        .map(getValueEmail),
    [integratedEmailExternalAccounts]
  );

  const { triggerProps, modalProps } = useThirdPartyAuth({
    businessId: chosenBusinessId,
    useCurrentTeamMember: false,
    fallback: 'settings_business',
  });

  if (emailFromDomainEnabled) {
    return (
      <EmailAddressTextInput
        className="spaced-input"
        label={t('Business Notification Email')}
        labelInfo={t(
          'This inbox will be used to send emails configured to send from the business default inbox.'
        )}
        labelInfoPlacement="top"
        placeholder={t('Enter Business Notification Email')}
        value={formData.reply_to_email}
        tooltip={t(
          'This inbox will be used to send emails configured to send from the business default inbox.'
        )}
        onChange={(value: any, _ev: any, err: any) =>
          handleInputChange('reply_to_email', value, err)
        }
        {...validationProps('reply_to_email')}
        required
        {...getKeyListenersProps('business-notification-email')}
      />
    );
  }
  return (
    <>
      <Select
        className="spaced-input"
        label={t('Default Email From Address')}
        labelInfo={t(
          'This inbox will be used to send emails configured to send from the business default inbox.'
        )}
        labelInfoPlacement="top"
        placeholder={t('Select Email')}
        options={integratedEmailOptions}
        value={formData.default_integrated_inbox}
        onChange={(value: any, _ev: any, err: any) =>
          handleInputChange('default_integrated_inbox', value, err)
        }
        {...validationProps('default_integrated_inbox')}
        field={{
          fieldType: FIELD_TYPES.Dropdown,
          options: integratedEmailOptions,
        }}
        menuTopButton={
          <TopMenuButton onClick={triggerProps.onClick}>
            {t('Add New Integrated Email')}
          </TopMenuButton>
        }
        menuLeftButton={null}
        menuRightButton={null}
        {...getKeyListenersProps('default-integrated-inbox')}
      />
      <IntegratedEmailModal {...modalProps} />
    </>
  );
};
