import { useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import Loader from '__components/Kizen/Loader';
import { AdditionalVariablesList } from './AdditionalVariablesList';
import {
  AdditionalVariable,
  SmartConnectorFlowErrors,
  SmartConnectorMetaData,
  SmartConnectorStatus,
} from '__pages/SmartConnectors/types';
import SmartConnectorService from '__services/SmartConnectorService';
import { defaultContext } from '__pages/SmartConnectors/SmartConnector/context';
import { FlowBuilderContext } from '../context';
import { useTranslation } from 'react-i18next';
import BasicModal from '__components/Modals/presets/BasicModal';
import { StyledNotice, StyledWrapper } from './styles';
import { AxiosError } from 'axios';
import { NODE_TYPES } from '__pages/AutomationEngine/Engine/Flow/layout';
import { getSmartConnectorVariables } from '__pages/SmartConnectors/SmartConnector/helpers';
import { isEqual } from 'lodash';
import ConfirmationModal from '__components/Modals/ConfirmationModal';
import ConfirmNavigationModal from '__components/Modals/presets/ConfirmNavigation';
import useModal from '__components/Modals/useModal';
import Button from '__components/Button';
import { SMART_CONNECTOR_STATUSES } from '__pages/SmartConnectors/constants';
import { getIsSmartConnectorActive } from '__pages/SmartConnectors/helpers';

export const AdditionalVariablesModal = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const {
    smartConnector,
    dropStep,
    setSmartConnector,
    setConnections,
    showDeactivationModalWithResult,
  } = useContext(FlowBuilderContext);

  const [data, setData] = useState<AdditionalVariable[]>([]);
  const [initialData, setInitialData] = useState<AdditionalVariable[]>([]);
  const [errors, setErrors] = useState<SmartConnectorFlowErrors>({});
  const [uniqueNames, setUniqueNames] = useState<string[]>([]);
  const [saving, setSaving] = useState(false);

  const { value: metaData = defaultContext.metaData, loading } = useAsync<
    () => Promise<SmartConnectorMetaData>
  >(async () => {
    const { data: smartConnector } =
      await SmartConnectorService.getSmartConnector(id);

    setData(smartConnector.flow?.additional_variables || []);
    setInitialData(smartConnector.flow?.additional_variables || []);
    setUniqueNames(
      getSmartConnectorVariables({
        execution_variables: smartConnector.execution_variables,
        flow: { ...smartConnector.flow, additional_variables: [] },
      }).map((v) => v.name)
    );
    const { data: metaData } =
      await SmartConnectorService.getSmartConnectorMetaData();
    return metaData;
  }, []);

  const isDirty = useMemo(() => {
    return !isEqual(data, initialData);
  }, [initialData, data]);

  const [confirmationModalProps, , { show: showConfirmationModal }] = useModal({
    handleSubmit: () => dropStep(null),
  });

  const onHide = useCallback(() => {
    if (!isDirty) {
      dropStep(null);
    } else {
      showConfirmationModal();
    }
  }, [dropStep, isDirty, showConfirmationModal]);

  const handleSave = useCallback(
    async (status: SmartConnectorStatus) => {
      try {
        setErrors({});
        setSaving(true);

        const response = await SmartConnectorService.updateSmartConnector(
          id,
          {
            flow: {
              additional_variables: data.map((v, i) => ({
                ...v,
                display_order: i,
              })),
            },
            status,
          },
          { skipErrorBoundary: true }
        );

        setSmartConnector(response.data);

        setConnections((prev) =>
          prev.map((step) =>
            step.type === NODE_TYPES.connection_intialize_additional_variables
              ? {
                  ...step,
                  details: {
                    additional_variables:
                      response.data?.flow.additional_variables || [],
                  },
                }
              : step
          )
        );
        dropStep(null);
        return true;
      } catch (error) {
        if (error instanceof AxiosError) {
          const axiosError = (
            error as AxiosError<{ flow: SmartConnectorFlowErrors }>
          ).response?.data;

          const errors: SmartConnectorFlowErrors = {};

          if (axiosError?.flow?.additional_variables) {
            errors.additional_variables = axiosError.flow.additional_variables;
          }

          setErrors(errors);
        }
        return false;
      } finally {
        setSaving(false);
      }
    },
    [id, data, setSmartConnector, setErrors, dropStep, setConnections]
  );

  const onConfirm = useCallback(() => {
    if (
      isDirty &&
      getIsSmartConnectorActive({
        status: smartConnector.status,
      })
    ) {
      return showDeactivationModalWithResult({
        handleSave: () => handleSave(SMART_CONNECTOR_STATUSES.inactive),
        handleDiscard: () => setData(initialData),
      });
    } else {
      return handleSave(smartConnector.status);
    }
  }, [
    isDirty,
    handleSave,
    showDeactivationModalWithResult,
    smartConnector.status,
    initialData,
  ]);

  const hasErrors = useMemo(
    () =>
      (errors.additional_variables || []).some(
        (err) => Object.values(err || {}).length
      ),
    [errors]
  );

  return (
    <>
      <BasicModal
        show
        className=""
        onHide={onHide}
        heading={t('Initialize Additional Variables')}
        size="large"
        onConfirm={onConfirm}
        enforceFocus={false}
        disabled={saving || hasErrors}
      >
        <Loader loading={loading}>
          <StyledNotice>
            {t(
              'Each of the variables specified below will be created and initialized to their respective values for the single contextual run of the uploading row. If any variable is marked as required and the variable is initialized to a missing or null value, the the row in the SmartConnector will not process.'
            )}
          </StyledNotice>
          <StyledWrapper>
            <AdditionalVariablesList
              data={data}
              setData={setData}
              errors={errors}
              setErrors={setErrors}
              metaData={metaData}
              uniqueNames={uniqueNames}
            />
          </StyledWrapper>
        </Loader>
      </BasicModal>
      <ConfirmationModal
        heading={t('You Have Unsaved Changes')}
        buttonText={t('Discard Changes')}
        defaultLeftBtnText={t('Save Changes')}
        actionBtnColor="red"
        leftBtn={<Button disabled={saving} />}
        defaultLeftBtnHandler={onConfirm}
        {...confirmationModalProps}
      >
        {t(
          'You have unsaved changes. Would you like to save them or discard them before continuing?'
        )}
      </ConfirmationModal>
      <ConfirmNavigationModal when={isDirty} />
    </>
  );
};
