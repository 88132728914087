import { KizenTypography } from '__app/typography';
import InputControl from '__components/Inputs/InputControl';
import { useTranslation } from 'react-i18next';
import { StyledReferenceButton, StyledReferenceDataContent } from './styles';
import { useContext } from 'react';
import { SmartConnectorContext } from '../../context';
import { ReferenceDataModal } from './ReferenceDataModal';
import useModal from '__components/Modals/useModal';
import {
  SmartConnectorDataSeedDTO,
  SmartConnectorStatus,
} from '__pages/SmartConnectors/types';
import SmartConnectorService from 'services/SmartConnectorService';
import { useToast } from '__components/ToastProvider';
import { toastVariant } from '__components/ToastProvider';
import { getIsSmartConnectorActive } from '__pages/SmartConnectors/helpers';
import {
  SMART_CONNECTOR_STATUSES,
  SMART_CONNECTOR_STEPS,
} from '__pages/SmartConnectors/constants';
import Button from '__components/Button';
import ConfirmationModal from '__components/Modals/ConfirmationModal';
import { getStepData } from '../../helpers';
import { UseScriptsProps } from '../../hooks/useScripts';

export const ReferenceData = ({
  setForceReset,
}: Pick<UseScriptsProps, 'setForceReset'>) => {
  const { t } = useTranslation();
  const {
    smartConnector,
    refetchSmartConnector,
    refreshing,
    handleSave,
    setStepData,
    isDirty,
  } = useContext(SmartConnectorContext);
  const [showToast] = useToast();
  const [referenceModalProps, , { show: showReferenceDataModal }] = useModal({
    handleSubmit: async (dataSeeds: SmartConnectorDataSeedDTO[]) => {
      const payload: {
        kizen_data_seeds: SmartConnectorDataSeedDTO[];
        status?: SmartConnectorStatus;
      } = { kizen_data_seeds: dataSeeds };

      if (getIsSmartConnectorActive(smartConnector)) {
        payload.status = SMART_CONNECTOR_STATUSES.inactive;
      }
      await SmartConnectorService.updateSmartConnector(
        smartConnector.id,
        payload,
        { skipErrorBoundary: true }
      );

      await refetchSmartConnector();
      setForceReset((prev) => prev + 1);
    },
    handleError: () => {
      showToast({
        message: t('Failed to update Kizen Reference Data'),
        variant: toastVariant.FAILURE,
      });
    },
    closeOnSubmit: false,
  });

  const [
    confirmationModalProps,
    ,
    { show: showConfirmationModal, hide: hideConfirmationModal },
  ] = useModal({
    handleSubmit: () => {
      setStepData(
        getStepData(smartConnector, SMART_CONNECTOR_STEPS.connection)
      );
      setForceReset((prev) => prev + 1);
      if (smartConnector.sql_enabled) {
        showReferenceDataModal();
      }
    },
  });

  const handleOpenReferenceDataModal = () => {
    if (isDirty) {
      showConfirmationModal();
    } else {
      showReferenceDataModal();
    }
  };

  const onConfirmSave = async () => {
    const updated = await handleSave(false, true);
    if (updated) {
      hideConfirmationModal();
      showReferenceDataModal();
    }
  };

  return (
    <>
      <InputControl
        label={t('Include Kizen Reference Data')}
        labelInfo={t(
          'Enables reference data from Kizen objects to be accessible in SQL scripts'
        )}
        labelInfoPlacement="top"
        variant="outline"
        className=""
      >
        <StyledReferenceDataContent>
          {smartConnector.kizen_data_seeds?.length ? (
            <KizenTypography>
              {t('{{amount}} Objects', {
                amount: smartConnector.kizen_data_seeds.length,
              })}
            </KizenTypography>
          ) : null}
          <StyledReferenceButton
            variant="text"
            color="blue"
            onClick={handleOpenReferenceDataModal}
          >
            {t('Configure')}
          </StyledReferenceButton>
        </StyledReferenceDataContent>
      </InputControl>
      <ReferenceDataModal {...referenceModalProps} />
      <ConfirmationModal
        heading={t('You Have Unsaved Changes')}
        buttonText={t('Discard Changes')}
        defaultLeftBtnText={t('Save Changes')}
        actionBtnColor="red"
        leftBtn={<Button disabled={refreshing} />}
        defaultLeftBtnHandler={onConfirmSave}
        {...confirmationModalProps}
      >
        {t(
          'You have unsaved changes. Would you like to save them or discard them before continuing?'
        )}
      </ConfirmationModal>
    </>
  );
};
