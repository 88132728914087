import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MyColumnsToolBar } from '../components/ToolBar';
import { CardTitle, ContentCard } from '../styles';
import { MobileFallback } from 'pages/Common/MobileFallback';
import ColumnsService from 'services/ColumnsService';
import useModal from 'components/Modals/useModal';
import { SharingAccessModal } from 'components/Modals/SharingAccessModal';
import {
  getColumnSettings,
  getColumnsPayload,
  getColumnConfigPayload,
  getPathBack,
} from '../helpers';
import { buildSharingSettingsForAPI } from 'utility/sharing';
import {
  ChooseTemplateModal,
  DEFAULT_TEMPLATE_ID,
} from 'components/Modals/ApplyTemplateModal';
import { CONFIG_KEY } from '../constants';
import { useDraggableColumns } from '../hooks/useDraggableColumns';
import { DraggableColumns } from '../components/DraggableColumns';
import { CustomizeFieldsLink } from '../components/CustomizeFieldsLink';
import useField from 'hooks/useField';
import ConfirmNavigationWithActionModal from 'components/Modals/presets/ConfirmNavigationWithAction';
import { useDirtyState } from '../hooks/useDirtyState';
import { PageSizing } from 'components/Layout/PageContentWidth';
import { useApplyTemplateByParams } from '../hooks/useApplyTemplateByParams';
import { useSharingAccessModal } from '../hooks/useSharingAccessModal';
import { toastVariant, useToast } from 'components/ToastProvider';
import { deferExecution } from 'utility/defer';
import Loader from 'components/Kizen/Loader';
import { CUSTOM_OBJECTS } from 'queries/query-keys';

export const MyColumns = ({
  onSubmitColumns,
  defaultColumnSettings,
  columnSettings: columnSettingsProp,
  groupedColumns,
  disabled,
  isMobile,
  objectId,
  model,
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();

  const history = useHistory();
  const [columnSettings, setColumnSettings] = useField(columnSettingsProp);
  const [applyingTemplate, setApplyingTemplate] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { canCustomizeFields, leftItems, rightItems, ...DnDProps } =
    useDraggableColumns(columnSettings, groupedColumns, defaultColumnSettings);

  const [initialSettings, setInitialSettings] = useState(
    getColumnsPayload(rightItems, leftItems)
  );

  const handleSubmit = async () => {
    const nextSettings = getColumnsPayload(rightItems, leftItems);
    await onSubmitColumns(nextSettings);
    setInitialSettings(nextSettings);
    deferExecution(() => {
      history.push(getPathBack(history.location.pathname, 2));
    });
  };

  const handleSave = async () => {
    const nextSettings = getColumnsPayload(rightItems, leftItems);
    await onSubmitColumns(nextSettings);
    setShowConfirmationModal(false);
    setInitialSettings(nextSettings);
  };

  const dirty = useDirtyState(initialSettings, rightItems, leftItems);

  const onSubmitSharingAccessModal = useCallback(
    async ({ name, permissions, isPrivate }) => {
      await ColumnsService.createTemplate(
        name,
        getColumnConfigPayload(getColumnsPayload(rightItems, leftItems)),
        {
          ...buildSharingSettingsForAPI(permissions).sharing_settings,
          private: isPrivate,
        },
        model?.id
      );
      showToast({
        message: t("'{{name}}' column template has been saved successfully.", {
          name,
        }),
        variant: toastVariant.SUCCESS,
      });
    },
    [rightItems, leftItems, model?.id, showToast, t]
  );

  const { sharingAccessModalProps, onShowSharingAccessModal } =
    useSharingAccessModal(onSubmitSharingAccessModal);

  const handleApplyTemplate = useCallback(
    async (id) => {
      let settingsToApply;
      setApplyingTemplate(true);
      if (id === DEFAULT_TEMPLATE_ID) {
        deferExecution(() => {
          setColumnSettings(defaultColumnSettings);
          DnDProps.onChange(
            defaultColumnSettings.filter(({ visible }) => visible)
          );
          setApplyingTemplate(false);
        });

        return;
      } else {
        const template = await ColumnsService.getTemplate(id, model?.id);
        if (template[CONFIG_KEY]?.columns) {
          settingsToApply = getColumnSettings(
            defaultColumnSettings,
            template?.[CONFIG_KEY]
          );
        }
      }

      if (settingsToApply) {
        setColumnSettings(settingsToApply);
        DnDProps.onChange(settingsToApply.filter(({ visible }) => visible));
      }
      setApplyingTemplate(false);
    },
    [defaultColumnSettings, setColumnSettings, DnDProps, model?.id]
  );

  useApplyTemplateByParams(handleApplyTemplate);

  const [chooseTemplateModalProps, { onClick: onShowChooseTemplateModal }] =
    useModal({
      handleSubmit: handleApplyTemplate,
    });

  const fetchProps = useMemo(() => [model?.id], [model?.id]);

  return (
    <PageSizing data-qa="my-columns">
      <MyColumnsToolBar
        onSave={handleSubmit}
        onSaveTemplate={onShowSharingAccessModal}
        onChooseTemplate={onShowChooseTemplateModal}
        disabled={disabled || applyingTemplate}
        disabledSave={!dirty}
        isMobile={isMobile}
      />
      <ContentCard>
        <CardTitle title={t('Edit My Columns')} />
        <MobileFallback isMobile={isMobile}>
          {applyingTemplate ? (
            <Loader loading />
          ) : (
            <DraggableColumns
              leftItems={leftItems}
              rightItems={rightItems}
              {...DnDProps}
            />
          )}
          {canCustomizeFields && !applyingTemplate ? (
            <CustomizeFieldsLink objectId={objectId} />
          ) : null}
        </MobileFallback>
      </ContentCard>
      {sharingAccessModalProps.show ? (
        <SharingAccessModal
          {...sharingAccessModalProps}
          isEditing={false}
          disabled={disabled}
          instanceName={t('Column')}
          showPrivateToggle
        />
      ) : null}
      {chooseTemplateModalProps.show ? (
        <ChooseTemplateModal
          {...chooseTemplateModalProps}
          serviceToUse={ColumnsService}
          fetchProps={fetchProps}
          disabled={disabled || applyingTemplate}
          reactQueryKey={CUSTOM_OBJECTS.COLUMN_TEMPLATES(model?.id)}
        />
      ) : null}
      <ConfirmNavigationWithActionModal
        when={dirty}
        show={showConfirmationModal}
        heading={t('Your Columns Have Unsaved Changes')}
        buttonText={t('Save')}
        actionBtnColor="green"
        additionalButtonText={t('Discard Changes')}
        additionalButtonColor="red"
        action={handleSave}
        onHide={() => setShowConfirmationModal(false)}
        onConfirm={() => {
          setShowConfirmationModal(false);
        }}
      >
        {t('All unsaved changes will be lost unless you save your columns.')}
      </ConfirmNavigationWithActionModal>
    </PageSizing>
  );
};
