import { useState, useMemo, useCallback, useRef, forwardRef } from 'react';
import ReactDOM from 'react-dom';
import { useClickAway } from 'react-use';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import ct from 'countries-and-timezones';
import { format, isMatch } from 'date-fns2';
import { KizenTypography } from 'app/typography';
import {
  getScheduledTimezoneAdjusted,
  isTimezoneEqualToLocal,
} from 'app/timezone';
import { borderRadii } from 'app/spacing';
import { colorsButton } from 'app/colors';
import StylePassthrough from 'components/Kizen/StylePassthrough';
import { buildTimeIntervals } from 'utility/datetime';
import DatePickerTextInput from 'components/Inputs/TextInput/presets/DatePicker';
import { MenuList, Menu, MIN_DATE_LENGTH } from 'components/Inputs/DateInput';
import useField from 'hooks/useField';
import { getDataQAForInput } from 'components/Inputs/helpers';
import IconAdornment from '../Adornments/IconAdornment';
import { dateTimeFormatParseString } from '../helpers';
import {
  MaybeInputControl,
  getNonInputControlProps,
  hasInputControl,
} from '../InputControl';
import Select from '../Select';
import {
  Control as CustomControl,
  Menu as CustomMenu,
  TrackingMenuList,
} from '../Select/customize';
import { TimezoneWarning } from './TimezoneWarning';
import { applyRef } from '../props';
import { useTranslation } from 'react-i18next';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { useDateTooltip } from '../hooks/useDateTooltip';
import { PortaledMenuWrapper } from '../portalLayout';

export const DATE_DISPLAY_FORMAT = 'MM/DD/YYYY';
export const DATE_DISPLAY_FORMAT_NEW = 'MM/dd/yyyy';
export const TIME_DISPLAY_FORMAT = 'h:mm a';
export const TIME_VALUE_FORMAT = 'HH:mm';
const emptyData = '—';

const split = (list, predicate) => {
  const inItems = [];
  const outItems = [];
  list.forEach((item) => {
    if (predicate(item)) {
      inItems.push(item);
    } else {
      outItems.push(item);
    }
  });
  return [inItems, outItems];
};

export const createOptionFromDatetime = (date) => ({
  value: format(date, TIME_VALUE_FORMAT),
  label: format(date, 'h:mm a'),
});

export const timeOptions = (() =>
  buildTimeIntervals(15, createOptionFromDatetime))();

export const getDateOption = (value, isDate) => {
  if (
    value &&
    typeof value === 'object' &&
    'date' in value &&
    'value' in value &&
    'label' in value
  ) {
    // It's already a Select-style option
    return value;
  }
  // we always use UTC for DateInput. Agreement with BE developers
  const date = dateTimeFormatParseString(value);
  if (!date) {
    return {
      date: null,
      value: '',
      label: '',
    };
  }
  return (
    date && {
      date,
      value: isDate ? date.toISOString() : format(date, TIME_VALUE_FORMAT),
      label: format(
        date,
        isDate ? DATE_DISPLAY_FORMAT_NEW : TIME_DISPLAY_FORMAT
      ),
    }
  );
};

const StyledKizenTypography = styled(KizenTypography)`
  margin-top: 7px;
`;

export function DateTimeReadOnly({ value, ...others }) {
  const display = useMemo(() => {
    const date = value && new Date(value);
    return (
      date &&
      `${format(date, DATE_DISPLAY_FORMAT_NEW)} at ${format(
        date,
        TIME_DISPLAY_FORMAT
      )}`
    );
  }, [value]);
  return (
    <StyledKizenTypography {...others}>
      {display || emptyData}
    </StyledKizenTypography>
  );
}

const StyledMaybeInputControl = styled(MaybeInputControl)`
  position: relative;
`;

const DateTimeLayout = styled.div`
  position: relative;
  display: flex;

  ${({ variant }) =>
    variant !== 'underline'
      ? css`
          & > div:first-of-type,
          & > div:last-of-type {
            width: calc((100% - 1px) / 2);
          }
        `
      : css`
          & > div:first-of-type {
            width: 50%;
            & input {
              flex: initial;
              width: calc(100% - 18px);
            }
          }
          & > div:last-of-type {
            width: 50%;
          }
        `}
`;

const StyledSelect = styled(Select)`
  ${({ menuInline, portal }) =>
    menuInline
      ? css`
          position: relative;
          top: ${portal ? 0 : 1}px;
          right: ${portal ? 0 : -1}px;
        `
      : css`
          position: absolute;
          left: 0;
          right: -1px;
          top: 37px;
        `}
`;

const DateControlStyles = styled(StylePassthrough)`
  ${({ selectProps: { variant } }) =>
    variant !== 'underline'
      ? css`
          && {
            border-top-left-radius: ${borderRadii.small};
            border-top-right-radius: 0;
            border-bottom-left-radius: ${borderRadii.small};
            border-bottom-right-radius: 0;
          }
        `
      : css`
          && {
            padding-right: 0;
          }

          i {
            margin-left: 8px;

            svg {
              transform: none !important;
              width: 9px !important;
            }
          }
        `}
`;

const StyledDatePickerTextInput = styled(DatePickerTextInput)`
  & input {
    width: ${({ variant }) =>
      variant === 'outline' ? 'calc(100% - 20px)' : 'unset'};
  }
`;

const DateComponent = styled.div`
  position: relative;
`;

const DateStylesWrapper = styled.div`
  margin-right: -1px;
  position: relative;
  overflow: hidden;
  ${({ layer = 1 }) =>
    typeof layer === 'number'
      ? css`
          z-index: ${layer};
          &:hover {
            z-index: ${layer + 1};
          }
        `
      : ''}
`;

const TimeStylesWrapper = styled.div`
  position: relative;
  &:hover,
  &:focus-within {
    z-index: 1;
  }
`;

const DateControl = (props) => {
  const {
    selectProps: { menuIsOpen },
  } = props;

  return (
    <DateStylesWrapper>
      <DateControlStyles {...props}>
        <CustomControl {...props} isFocused={menuIsOpen} />
      </DateControlStyles>
    </DateStylesWrapper>
  );
};

const DateWrapper = forwardRef((props, ref) => {
  const {
    measuredRef,
    onBlur,
    value,
    onChange,
    onClick,
    placeholder,
    variant,
    disabled,
    error,
    isShowMenu,
    layer,
    ...others
  } = props;

  return (
    <DateStylesWrapper layer={layer}>
      <DateControlStyles {...props}>
        <StyledDatePickerTextInput
          ref={ref}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          onClick={onClick}
          placeholder={placeholder}
          variant={variant}
          disabled={disabled}
          error={error}
          focus={false}
          isShowMenu={isShowMenu}
          {...getDataQAForInput(
            props.selectProps['data-field-id'],
            props.selectProps['data-field-type']
          )}
          {...others}
        />
      </DateControlStyles>
    </DateStylesWrapper>
  );
});

const DateMenu = styled(Menu)`
  ${({ selectProps: { menuInline } }) =>
    menuInline &&
    css`
      top: 0;
    `}
`;

const TimeControlStyles = styled(StylePassthrough)`
  ${({ selectProps: { variant, error } }) =>
    variant !== 'underline'
      ? css`
          && {
            border-top-left-radius: 0;
            border-top-right-radius: ${borderRadii.small};
            border-bottom-left-radius: 0;
          }
          &&:focus-within {
            border-color: ${error
              ? colorsButton.red.hover
              : colorsButton.blue.hover};
          }
        `
      : css`
          && {
            padding-left: 8px;
            padding-right: 0;
          }

          &&:focus-within {
            border-bottom-color: ${colorsButton.blue.hover};
          }

          div > div {
            overflow: visible;
            margin-left: 1px;
          }

          i {
            margin-left: 7px;

            svg {
              width: 10px !important;
            }
          }
        `}
`;

const TimeControl = (props) => {
  const {
    selectProps: { handleClick, menuIsOpen, error },
  } = props;

  return (
    <TimeStylesWrapper
      onClick={handleClick}
      {...getDataQAForInput(
        props.selectProps['data-field-id'],
        props.selectProps['data-field-type']
      )}
    >
      <TimeControlStyles {...props} error={error}>
        <CustomControl {...props} isFocused={menuIsOpen} />
      </TimeControlStyles>
    </TimeStylesWrapper>
  );
};

const TimeMenu = styled(CustomMenu)`
  ${({ selectProps: { variant } }) =>
    variant === 'underline'
      ? css`
          li {
            padding-left: 10px;
            padding-right: 5px;
          }
        `
      : css`
          width: calc(100% + 1px);
          left: -1px;
        `}

  ${({ selectProps: { menuInline } }) =>
    menuInline &&
    css`
      top: 0;
    `}

  ${({ selectProps: { menuInline, rightPosition } }) => {
    if (rightPosition && menuInline) {
      return css`
        float: right;
      `;
    }
    if (rightPosition && !menuInline) {
      return css`
        right: 0;
      `;
    }
    return '';
  }}
`;

const DateTimeInput = forwardRef((props, ref) => {
  const {
    fieldId,
    className,
    value = null,
    placeholderDate = 'MM/DD/YYYY',
    placeholderTime = 'HH:MM AM/PM',
    error = false,
    variant = 'outline',
    disabled = false,
    onChange = null,
    menuLeftButton = null,
    menuRightButton = null,
    isTimeSearchable = false,
    businessTimezoneForDiscrepancyWarning = '',
    clickNotify = null,
    layer,
    showDateTooltip = false,
    portal,
  } = props;
  const { assignFieldHandle } = useKeyBoardContext();
  const { t } = useTranslation();
  const containerRef = useRef();
  const date = useMemo(() => getDateOption(value, true), [value]);
  const time = useMemo(() => getDateOption(value, false), [value]);
  const changeRef = useRef(false);

  // we use this to keep track of changes and set changeRef.current to true
  const changeHandler = useCallback(
    (val) => {
      onChange(val);
      changeRef.current = true;
    },
    [onChange]
  );

  const [dateTooltipProps, dateTooltip] = useDateTooltip({
    date: showDateTooltip && date?.date,
    popperConfig: {
      modifiers: {
        offset: {
          offset: '0, -7px',
        },
      },
    },
  });

  const isMatchBusinessAndLocalTime =
    !businessTimezoneForDiscrepancyWarning ||
    isTimezoneEqualToLocal(businessTimezoneForDiscrepancyWarning);

  const outlineVariant = variant === 'outline';

  const [rightPosition, setRightPosition] = useState(false);
  const [initValue, setInitValue] = useState(value || new Date());

  // textInputMode indicates that a user has typed in a date rather than selecting one from
  // the calendar. If the date was typed, there's a bit of fa different path on blur.
  const [textInputMode, setTextInputMode] = useState(false);

  const [formattedValue, setFormattedValue] = useField(
    () => getDateOption(value, true),
    [value]
  );

  const [showDateMenu, _setShowDateMenu] = useState(false);
  const setShowDateMenu = useCallback(
    (val) => {
      if (clickNotify) clickNotify();
      _setShowDateMenu(val);
    },
    [_setShowDateMenu, clickNotify]
  );

  const selectControlRef = useRef(null);

  const measuredRef = useCallback(
    (controlRef) => {
      if (controlRef) {
        const parent = document.body.getBoundingClientRect();
        const child = controlRef
          .querySelector('input')
          ?.getBoundingClientRect() || { left: 0, right: 0 };
        setRightPosition(child.left - parent.left > parent.right - child.right);
        applyRef(ref, controlRef);
        applyRef(selectControlRef, controlRef);
        applyRef(containerRef, controlRef);
      }
    },
    [ref]
  );

  const getBusinessTime = (selectedTime) => {
    if (businessTimezoneForDiscrepancyWarning && selectedTime.value) {
      return getScheduledTimezoneAdjusted(
        selectedTime.date,
        businessTimezoneForDiscrepancyWarning
      );
    }
  };

  const handleInputChange = (v) => {
    setShowDateMenu(true);
    setFormattedValue((prev) => ({
      ...prev,
      label: v,
    }));
    setTextInputMode(true);
  };

  const handleOnBlur = () => {
    const isValidDate =
      formattedValue?.label.length === MIN_DATE_LENGTH &&
      isMatch(formattedValue?.label, 'MM/dd/yyyy');
    setShowDateMenu(false);
    if (!formattedValue?.value && !textInputMode) {
      return;
    }
    if (!isValidDate) {
      setFormattedValue(getDateOption(initValue, true));
      return;
    }
    setInitValue(formattedValue.label);
    setFormattedValue(getDateOption(formattedValue.label, true));

    // if no changes have happened, don't call the change handler
    if (changeRef.current) {
      changeHandler(
        new Date(`${formattedValue.label} ${time ? time.value : ''}`)
      );
    }
    setTextInputMode(false);
  };

  const handleDateBlur = () => {
    changeRef.current = true;
    handleOnBlur();
  };

  // handle click away a tick later so that the date blur fires first
  const handleClickAway = () => {
    if (showDateMenu) {
      setTimeout(() => {
        handleOnBlur();
      }, 1);
    }
  };

  useClickAway(containerRef, handleClickAway);

  // track menuIsOpen so we can set the toggle setting
  const setWhichMenuStatus = () =>
    !props.menuIsOpen || props.disabled ? null : 'left';
  const menuIsOpenRef = useRef({
    menuIsOpen: props.menuIsOpen,
    disabled: props.disabled,
  });
  const [whichMenu, setWhichMenu] = useState(setWhichMenuStatus());
  if (
    menuIsOpenRef.current?.menuIsOpen !== props.menuIsOpen ||
    menuIsOpenRef.current?.disabled !== props.disabled
  ) {
    menuIsOpenRef.current = {
      menuIsOpen: props.menuIsOpen,
      disabled: props.disabled,
    };
    setWhichMenu(setWhichMenuStatus());
  }

  const customError = outlineVariant ? Boolean(error) : false;
  const selectRef = useRef(null);
  const timeComponentRef = useRef();
  const focusedRef = useRef(null);
  const wrapperRef = useRef(null);

  const timeProps = {
    ...getNonInputControlProps({
      ...props,
      right: rightPosition,
      error: customError,
      validate: null,
    }),
    menuIsOpen: props.menuIsOpen && !props.disabled && whichMenu !== 'left',
    handleClick: () => {
      if (whichMenu === 'left') setWhichMenu('right');
    },
    ...getDataQAForInput(props['data-field-id'], 'datetime-time-select'),
  };

  const dateProps = useMemo(
    () => ({
      ...getNonInputControlProps({
        ...props,
        fieldId: undefined,
        right: rightPosition,
        validate: {
          ...props.validate,
          container: wrapperRef?.current,
        },
      }),
      menuIsOpen: props.menuIsOpen && !props.disabled && whichMenu !== 'right',
      ...getDataQAForInput('date-input', props['data-field-type']),
    }),
    [props, whichMenu, rightPosition]
  );

  const handleChange = (isDate) => (data) => {
    if (onChange) {
      if (isDate) {
        setFormattedValue(data);
        changeHandler(
          data ? new Date(`${data.label} ${time ? time.value : ''}`) : null
        );
      } else {
        changeHandler(
          data
            ? new Date(
                `${
                  date && date.label !== '' // if the user click time first then date.label will be empty :(
                    ? date.label
                    : format(new Date(), DATE_DISPLAY_FORMAT_NEW)
                }
              ${data.value}`
              )
            : null
        );
      }
    }
  };

  const isShowDateMenu =
    (dateProps.menuOnly && whichMenu !== 'left') || showDateMenu;

  const isShowBusinessTimezoneWarning =
    businessTimezoneForDiscrepancyWarning &&
    formattedValue?.value &&
    !isMatchBusinessAndLocalTime;

  // Create a time string looks like: `{{MMMM}} {{DD}}, {{YYYY}} {{hh}}:{{mm}} {{AM/PM}}`
  let businessTimeString = '';
  // Create a time zone string looks like: `{{Timezone}} GMT{{+/-}}{{XX:XX}}`
  let businessTimeZoneString = '';

  if (isShowBusinessTimezoneWarning) {
    const businessTime = getBusinessTime(formattedValue);
    businessTimeString = format(businessTime, 'LLLL d, y p');
    const businessTimeZone = ct.getTimezone(
      businessTimezoneForDiscrepancyWarning
    );
    businessTimeZoneString = `GMT${businessTimeZone.utcOffsetStr}`;
  }

  const { value: _, ...forInputControl } = props;

  // if the time does not fall on a 15 minute interval insert it
  const insertedTimeOptions = useMemo(() => {
    if (time?.value && !timeOptions.some(({ value }) => value === time.value)) {
      // have a valid time that doesn't match the options - add it
      const [before, after] = split(
        timeOptions,
        ({ value }) => value < time.value
      );

      return [
        ...before,
        {
          value: time.value,
          label: time.label,
        },
        ...after,
      ];
    }
    return timeOptions;
  }, [time]);
  const { closeOnClearClick } = useCustomSelectMenuProps(selectRef, dateProps);

  assignFieldHandle(fieldId, {
    customTab: (e) => {
      if (focusedRef.current === 'date' && !e.shiftKey) {
        handleOnBlur();
        timeComponentRef.current.select.focus();
        focusedRef.current = 'time';
        return true;
      }
      if (focusedRef.current === 'time' && e.shiftKey) {
        timeComponentRef.current.select?.focusedOptionRef?.click();
        selectControlRef.current?.querySelector('input')?.click();
        focusedRef.current = 'date';
        setShowDateMenu(false);
        return true;
      }
      focusedRef.current = null;
    },
    customFocus: () => {
      if (focusedRef.current === null) {
        selectControlRef.current?.querySelector('input')?.click();
        focusedRef.current = 'date';
        setShowDateMenu(false);
      }
      return selectControlRef.current?.querySelector('input');
    },
    customEnter: () => {
      if (focusedRef.current === 'date') {
        timeComponentRef.current.select.focus();
        handleOnBlur();
        focusedRef.current = 'time';
        return true;
      }
    },
    customEscape: (e) => {
      if (showDateMenu) {
        e.stopImmediatePropagation();
        setShowDateMenu(false);
      }
    },
    customUp: () => {
      if (focusedRef.current === 'date') {
        setShowDateMenu(true);
      }
    },
    customDown: () => {
      if (focusedRef.current === 'date') {
        setShowDateMenu(true);
      }
    },
    disabled,
  });

  // Our normal portal menu is icompatible with the date picker,
  // so it's overridden here in a different way
  const PortaledMenu = (props) => {
    const rect = containerRef.current.getBoundingClientRect();

    const component = (
      <PortaledMenuWrapper
        top={rect.top + rect.height + window.scrollY}
        left={rect.left}
        data-field-type-menu-body="datetime"
      >
        <DateMenu {...props} />
      </PortaledMenuWrapper>
    );

    return ReactDOM.createPortal(component, document.body);
  };

  return (
    <div ref={wrapperRef}>
      {dateTooltip}
      <StyledMaybeInputControl forInput {...forInputControl}>
        <DateTimeLayout variant={variant} {...dateTooltipProps}>
          {!outlineVariant && disabled ? (
            <DateTimeReadOnly {...props} />
          ) : (
            <>
              <DateComponent ref={measuredRef}>
                <>
                  {!dateProps.menuOnly && (
                    <DateWrapper
                      ref={selectRef}
                      {...dateProps}
                      onFocus={() => (focusedRef.current = 'date')}
                      onBlur={() => {
                        if (textInputMode) {
                          handleDateBlur();
                        }
                      }}
                      value={formattedValue && formattedValue.label}
                      onChange={handleInputChange}
                      onClick={() => {
                        if (whichMenu === 'right') setWhichMenu('left');
                        setShowDateMenu(true);
                      }}
                      placeholder={placeholderDate}
                      variant={variant}
                      disabled={disabled}
                      selectProps={dateProps}
                      error={error}
                      isShowMenu={isShowDateMenu}
                      layer={layer}
                      validateOnFocus
                    />
                  )}
                  {(isShowDateMenu || dateProps.menuIsOpen) && (
                    <StyledSelect
                      className={!hasInputControl(props) && className}
                      isSearchable={false}
                      menuInline={dateProps.menuInline}
                      endAdornment={<IconAdornment icon="calendar" />}
                      handleClose={() => setShowDateMenu(false)}
                      {...dateProps}
                      isDateTime
                      rightPosition={rightPosition}
                      placeholder={placeholderDate}
                      components={{
                        Control: DateControl,
                        Menu: portal ? PortaledMenu : DateMenu,
                        MenuList,
                        ...dateProps.components,
                      }}
                      error={customError}
                      validate={null}
                      value={formattedValue}
                      menuOnly
                      closeOnClearClick={closeOnClearClick ?? true}
                      closeMenuOnSelect={!(menuLeftButton || menuRightButton)}
                      handleClickApply={() => {
                        timeComponentRef.current.select.focus();
                        focusedRef.current = 'time';
                      }}
                      onChange={(opt) => {
                        setFormattedValue(opt);
                        (closeOnClearClick || opt) && setShowDateMenu(false);
                        changeHandler(
                          opt
                            ? new Date(`${opt.label} ${time ? time.value : ''}`)
                            : null
                        );
                        setInitValue(opt?.label);
                      }}
                      captureMenuScroll={false}
                      portal={false}
                    />
                  )}
                </>
              </DateComponent>

              {isShowBusinessTimezoneWarning ? (
                <TimezoneWarning>
                  {t(
                    'Your timezone differs from the business timezone. This will be set to'
                  )}{' '}
                  {businessTimeString} {t('in the business timezone')} (
                  {businessTimezoneForDiscrepancyWarning}{' '}
                  {businessTimeZoneString}
                  ).
                </TimezoneWarning>
              ) : null}

              <Select
                ref={timeComponentRef}
                className={!hasInputControl(props) && className}
                isSearchable={isTimeSearchable}
                endAdornment={<IconAdornment icon="clock" />}
                {...timeProps}
                onFocus={() => (focusedRef.current = 'time')}
                options={insertedTimeOptions}
                placeholder={placeholderTime}
                components={{
                  Control: TimeControl,
                  Menu: TimeMenu,
                  MenuList: TrackingMenuList,
                  ...timeProps.components,
                }}
                menuLeftButton={false}
                menuRightButton={false}
                error={customError}
                value={time && time.value}
                onChange={handleChange(false)}
                menuShouldScrollIntoView
                portal={portal}
              />
            </>
          )}
        </DateTimeLayout>
      </StyledMaybeInputControl>
    </div>
  );
});

DateTimeInput.displayName = 'DateTimeInput';

export default DateTimeInput;
