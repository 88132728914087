import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DraggableCore } from 'react-draggable';
import { useMeasure } from 'react-use';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Element, useEditor, useNode } from '@craftjs/core';
import {
  ColumnContainer,
  StyledRowContainer,
  transformColumnsProp,
  getBreakpointFlags,
  getFiveColumnElementGridArea,
} from '@kizen/page-builder/nodes/Row';
import { compose } from 'utility/compose';
import {
  colorsPrimary,
  grayScale,
} from '@kizen/page-builder/internal/app/colors';
import { layers } from '@kizen/page-builder/internal/app/spacing';
import Icon from '@kizen/page-builder/internal/components/Kizen/Icon';
import { useShowControls } from '../useShowControls';
import Control, { AddRemoveColumnControl } from '../Control';
import { useDraggingType } from '../useDraggingType';
import { useControlPadding } from '../useControlPadding';
import { Cell } from '../Cell';
import {
  useRootWidth,
  useBuilderContext,
  useIsInBuilderContext,
} from 'components/PageBuilder/BuilderContext';
import {
  useAddColumn,
  useRemoveColumn,
  useResizeDragHandlers,
} from './useResizeDragHandlers';
import { IMAGE_SIZES, UNIT } from '../../options';
import { getProportionalSizes } from '../../settings/ImageSettings/shared';

const RESIZE_HANDLE_MAX_HEIGHT = 200;
const MAX_COLUMNS = 6;

const ResizeIcon = styled(Icon)`
  position: absolute;
  top: 0;
  left: -1.5px;
  // above everything except the Controls (see ControlContainer in ../Control.js)
  z-index: ${({ modalLayer }) => layers.modals(modalLayer, 5)};
  cursor: ew-resize;
  max-height: ${RESIZE_HANDLE_MAX_HEIGHT}px;
  ${({ rowHeight }) =>
    rowHeight > RESIZE_HANDLE_MAX_HEIGHT &&
    css`
      transform: translateY(${(rowHeight - RESIZE_HANDLE_MAX_HEIGHT) / 2}px);
    `}

  &&,
  && svg {
    width: 3px;
    height: 100%;

    &:hover,
    &:active {
      line {
        stroke: ${grayScale.dark};
      }
    }
  }
  && svg line + line {
    // update the thicker line to be 1/4 of the total instead of 1/3
    transform: scale(0.75) translateY(16.5%);
  }
`;

const imageSizeResolver = (linkedNodes, query, setProp) => {
  for (const id of Object.values(linkedNodes)) {
    const cellChildren = query.node(id).get()?.data?.nodes ?? [];

    for (let i = 0; i < cellChildren.length; i++) {
      const node = query.node(cellChildren[i]).get();
      if (
        node?.data?.name === 'Image' &&
        node?.data?.props.size === IMAGE_SIZES.DYNAMIC
      ) {
        const {
          unit,
          containerWidth,
          naturalWidth,
          naturalHeight,
          maxWidth,
          width,
        } = node.data.props;
        if (unit === UNIT.PIXEL) {
          const {
            width: dynamicWidth,
            height: dynamicHeight,
            maxWidth: dynamicMaxWidth,
            maxHeight: dynamicMaxHeight,
          } = getProportionalSizes({
            width: maxWidth || width,
            proportion: naturalWidth / naturalHeight,
            containerWidth,
          });

          setProp(cellChildren[i], (p) => {
            p.width = dynamicWidth;
            p.height = dynamicHeight;
            !p.maxWidth && (p.maxWidth = dynamicMaxWidth);
            !p.maxHeight && (p.maxHeight = dynamicMaxHeight);
          });
        }
      }
    }
  }
};

const hasResizer = (index, columns, isTablet, isMobile) => {
  if (index === 0 || isMobile) return false;
  if (isTablet && (columns === 5 || columns === 6)) return false;
  return true;
};

const useBreakpointFlags = () => {
  // The applyMobileBreakpoints flag is used by the homepage builder to turn off the normal breakpoint
  // behavior when building small cards (220px, 440px, 660px). These small cards should _not_ have
  // thier columns stack when used within the desktop view. The mobile breakpoints will take effect
  // when some other container width (the dashboard screen) becomes too small.
  const { rootNode, rootWidth, applyMobileBreakpoints } = useBuilderContext();
  const { isMobile, isTablet } = getBreakpointFlags(rootNode, rootWidth);
  return useMemo(() => {
    return applyMobileBreakpoints
      ? { isMobile, isTablet }
      : { isMobile: false, isTablet: false };
  }, [isMobile, isTablet, applyMobileBreakpoints]);
};

export const Row = (props) => {
  const { t } = useTranslation();
  const { columns, children, width, ...others } = props;
  const [widths, setWidths] = useState(() => transformColumnsProp(columns));
  const { modalLayer, setResizingRowId, setActiveNode } = useBuilderContext();
  const [measureRef, { height }] = useMeasure();
  const [containerMeasureRef, { width: containerWidth }] = useMeasure();
  const [rootWidth] = useRootWidth();
  const {
    actions: { setProp, move },
    query,
    hoveredId,
  } = useEditor((state) => ({
    hoveredId: [...state.events.hovered.values()][0],
  }));
  const {
    id,
    linkedNodes,
    node,
    connectors: { connect },
  } = useNode((node) => ({
    id: node.id,
    linkedNodes: node.data.linkedNodes,
    node,
  }));
  const draggingType = useDraggingType();
  const showControls = useShowControls(id, hoveredId, query);
  const controlPadding = useControlPadding(props);
  const { isMobile, isTablet } = useBreakpointFlags();
  const addColumn = useAddColumn(setWidths);
  const removeColumnHandlers = useRemoveColumn(widths.length, setWidths);
  const dragHandlers = useResizeDragHandlers(
    columns.length,
    rootWidth,
    setWidths
  );

  const isChildContentHovered =
    hoveredId && query.node(hoveredId).ancestors().includes(id);
  const showColumnResizers = hoveredId === id || isChildContentHovered;
  const canAddRow = widths.length < MAX_COLUMNS;
  const canRemoveColumn = widths.length > 1;

  const handleDragEnd = () => {
    setResizingRowId(null);
    imageSizeResolver(linkedNodes, query, setProp);
    setProp(id, (p) => {
      p.columns = widths;
    });
  };

  const handleAddColumn = (ev) => {
    ev.stopPropagation(); // prevents the parent Section from being selected
    setActiveNode(node);
    addColumn();
    imageSizeResolver(linkedNodes, query, setProp);
  };

  const handleRemoveColumn = useMemo(() => {
    return removeColumnHandlers.map((remove, index) => {
      /**
       * For any column to the right of the removed column (that has children) we move all those
       * children left one column. Linked nodes cannot be updated, AFAIK, so this is how we keep
       * builder accurate even if there may be orphaned linked node ids that serve no purpose.
       * This means, linkedNodes cannot be the source of truth for how many columns are in a row
       * (use props.columns instead).
       */
      const moveNodes = (ev) => {
        for (const [columnId, id] of Object.entries(linkedNodes)) {
          const idx = parseInt(columnId.split('-').pop());
          const cellChildren = query.node(id).get()?.data?.nodes ?? [];

          if (idx > index && cellChildren.length) {
            for (let i = 0; i < cellChildren.length; i++) {
              move(cellChildren[i], linkedNodes[`column-${idx - 1}`], i);
            }
          }
        }

        return ev;
      };

      const setActive = (ev) => {
        ev.stopPropagation(); // prevents the Row from be deselected
        setActiveNode(node);
        return ev;
      };

      return compose(remove, moveNodes, setActive); // executed right to left
    });
  }, [removeColumnHandlers, linkedNodes, query, move, setActiveNode, node]);

  const refCb = useCallback(
    (node) => {
      connect(node);
      containerMeasureRef(node);
    },
    [connect, containerMeasureRef]
  );

  useEffect(() => {
    // We measure and set the available container width so we can support width/max-width settings in email
    if (containerWidth > 0) {
      setProp(id, (p) => {
        p.containerWidth = `${containerWidth}`;
      });
    }
  }, [id, containerWidth, setProp]);

  useEffect(() => {
    // Should always be in-sync. Also, the add/remove column buttons rely on this effect.
    setProp(id, (p) => {
      p.columns = widths;
    });
  }, [id, widths, setProp]);

  const isInBuilderContext = useIsInBuilderContext();

  return (
    <Control
      ref={refCb}
      label={t('Row')}
      show={showControls}
      color={colorsPrimary.orange.dark}
      {...others}
      rightControl={
        canAddRow ? <AddRemoveColumnControl onClick={handleAddColumn} /> : null
      }
      data-qa="page-builder-row"
    >
      <StyledRowContainer
        ref={measureRef}
        draggingType={draggingType}
        columns={widths}
        style={showControls ? controlPadding : undefined}
        isTablet={isTablet}
        isMobile={isMobile}
        onPointerLeave={handleDragEnd}
        isInBuilderContext={isInBuilderContext}
        width={width}
        {...others}
      >
        {widths.map((_, i, arr) => {
          const columnId = `column-${i + 1}`;
          return (
            <ColumnContainer
              data-qa="page-builder-content"
              key={columnId}
              gridArea={
                isTablet && arr.length === 5
                  ? getFiveColumnElementGridArea(i)
                  : null
              }
            >
              {showColumnResizers &&
                hasResizer(i, arr.length, isTablet, isMobile) && (
                  <DraggableCore
                    onStart={() => setResizingRowId(id)}
                    onDrag={dragHandlers[i]}
                    onStop={handleDragEnd}
                  >
                    <ResizeIcon
                      modalLayer={modalLayer}
                      icon="column-resize"
                      size="intrinsic"
                      preserveAspectRatio="none"
                      rowHeight={height}
                    />
                  </DraggableCore>
                )}
              <Element
                canvas
                key={linkedNodes[columnId]}
                is={Cell}
                id={columnId}
                onRemove={canRemoveColumn ? handleRemoveColumn[i] : null}
              />
            </ColumnContainer>
          );
        })}
      </StyledRowContainer>
    </Control>
  );
};

Row.craft = {
  rules: {
    canMoveIn: () => false,
  },
  defaultProps: {
    columns: [1],
  },
};
