import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { grayScale } from 'app/colors';
import { borderRadii } from 'app/spacing';
import { fontSizes } from 'app/typography';

// TODO maintain focus moving from view to edit without a clickaway in view mode
export const TextInputInlineWrapper = styled.div`
  display: flex;
  align-items: ${({ alignCenter }) => (alignCenter ? 'center' : 'flex-start')};
  // This is the size of the bottom padding on EditableText.
  // They should match so that there is no jitter switching between text and the input.
  ${({ isDateInput = false }) => {
    return isDateInput
      ? css`
          margin-bottom: -1px;
        `
      : css`
          margin-bottom: -7px;
        `;
  }}

  ${({ isDateTextInlineInput = false }) => {
    if (isDateTextInlineInput)
      return css`
        padding-top: 1px;
      `;
  }}

  margin-top: -1px;
  > div {
    display: flex !important; // Override inline style from react-input-autosize
    overflow: hidden;
  }
  && input {
    min-width: 30px;
    padding-left: 0;
    padding-right: ${({ button = true }) =>
      button ? 'calc(12px + 10px)' : '0'}; // width + margin
  }
  && input[disabled] {
    background-color: unset;
    background-image: unset;
    color: ${grayScale.mediumDark};
  }
  && > button {
    margin-top: 1px; // nudge
    margin-left: calc(-12px + -2px); // width + nudge
    width: 12px;
    svg {
      width: 12px;
      height: auto;
    }
  }
  .ViewText.DateText input {
    // min width for DateText input
    min-width: 85px;
  }
  .ViewText input:not(:hover) {
    background-image: none;
  }
  &:hover,
  tr:hover &,
  .InlineHoverable:hover & {
    .ViewText input:not([disabled]):not(:hover),
    .ViewText input:not([disabled]):not(:focus) {
      background-image: url(/images/inputUnderline.svg);
    }
  }
  .ValidationError > input:focus {
    background-image: url(/images/inputUnderline--error.svg);
  }
  .labeled_element {
    background: ${grayScale.mediumLight};
    border-radius: ${borderRadii.standard};
    padding: 2px 4px;
    text-align: center;
    height: 16px;
    margin-bottom: 5px;
    margin-right: 10px;
    width: 100%;
    input {
      font-size: ${fontSizes.small};
      width: 100%;
      text-align: center;
    }
    && > button {
      margin-top: -1px;
    }
  }

  ${({ isInherited }) =>
    isInherited
      ? css`
          & input {
            color: ${grayScale.mediumDark};
          }
        `
      : ''}
`;

export const LinkButtonWrapper = styled.div`
  margin-left: 9px;
  padding-bottom: 7px;
  flex-shrink: 0;
`;
