import { colorsPrimary, colorsSecondary } from 'app/colors';

export const DEFAULT_ITEM_IDS = {
  HOMEPAGES: 'default__homepages',
  DASHBOARD: 'default__dashboard',
  DATA: 'default__data',
  CONTACTS: 'default__contacts',
  COMPANIES: 'default__companies',
  DEALS: 'default__deals',
  MARKETING_COSTS: 'default__marketing_costs',
  CUSTOM_OBJECTS: 'default__custom_objects',
  PLATFORM: 'default__platform',
  BROADCASTS: 'default__broadcasts',
  LIBRARY: 'default__library',
  FORMS: 'default__forms',
  SURVEYS: 'default__surveys',
  ADS: 'default__ads',
  AUTOMATIONS: 'default__automations',
  SETTINGS: 'default__settings',
  MY_ACCOUNT: 'default__my_account',
  MY_PROFILE: 'default__my_profile',
  CHANGE_BUSINESS: 'default__change_business',
  ADD_BUSINESS: 'default__add_business',
  LOGOUT: 'default__logout',
  HELP: 'default__help',
  KNOWLEDGE_BASE: 'default__knowledge_base',
  CHAT_WITH_US: 'default__chat_with_us',
  ACTIVITIES: 'default__activities',
  SMART_CONNECTORS: 'default__smart_connectors',
  RECENT_RECORDS: 'default__recent_records',
  MARKETPLACE: 'default__marketplace',
};

export const DEFAULT_ICONS = {
  [DEFAULT_ITEM_IDS.HOMEPAGES]: 'house',
  [DEFAULT_ITEM_IDS.DASHBOARD]: 'chart-bar',
  [DEFAULT_ITEM_IDS.DATA]: 'database',
  [DEFAULT_ITEM_IDS.CONTACTS]: 'user',
  [DEFAULT_ITEM_IDS.CUSTOM_OBJECTS]: 'layer-group',
  [DEFAULT_ITEM_IDS.MARKETING_COSTS]: 'receipt',
  [DEFAULT_ITEM_IDS.PLATFORM]: 'browser',
  [DEFAULT_ITEM_IDS.BROADCASTS]: 'calendar-alt',
  [DEFAULT_ITEM_IDS.LIBRARY]: 'book-alt',
  [DEFAULT_ITEM_IDS.FORMS]: 'form-entity',
  [DEFAULT_ITEM_IDS.SURVEYS]: 'survey-entity',
  [DEFAULT_ITEM_IDS.ACTIVITIES]: 'clipboard-list',
  [DEFAULT_ITEM_IDS.ADS]: 'bullseye-pointer',
  [DEFAULT_ITEM_IDS.AUTOMATIONS]: 'play',
  [DEFAULT_ITEM_IDS.SETTINGS]: 'cog',
  [DEFAULT_ITEM_IDS.MY_ACCOUNT]: 'user',
  [DEFAULT_ITEM_IDS.MY_PROFILE]: 'user-circle',
  [DEFAULT_ITEM_IDS.CHANGE_BUSINESS]: 'repeat',
  [DEFAULT_ITEM_IDS.ADD_BUSINESS]: 'add',
  [DEFAULT_ITEM_IDS.LOGOUT]: 'sign-out',
  [DEFAULT_ITEM_IDS.HELP]: 'question-circle',
  [DEFAULT_ITEM_IDS.KNOWLEDGE_BASE]: 'graduation-cap',
  [DEFAULT_ITEM_IDS.CHAT_WITH_US]: 'comments',
  [DEFAULT_ITEM_IDS.SMART_CONNECTORS]: 'arrows-to-circle',
  [DEFAULT_ITEM_IDS.RECENT_RECORDS]: 'history',
  [DEFAULT_ITEM_IDS.MARKETPLACE]: 'store',
};

export const getTranslationKeyMapping = (t) => {
  return {
    [DEFAULT_ITEM_IDS.DASHBOARD]: t('Dashboard'),
    [DEFAULT_ITEM_IDS.DATA]: t('Data'),
    [DEFAULT_ITEM_IDS.CONTACTS]: t('Contacts'),
    [DEFAULT_ITEM_IDS.COMPANIES]: t('Companies'),
    [DEFAULT_ITEM_IDS.DEALS]: t('Deals'),
    [DEFAULT_ITEM_IDS.MARKETING_COSTS]: t('Marketing Costs'),
    [DEFAULT_ITEM_IDS.CUSTOM_OBJECTS]: t('Custom Objects'),
    [DEFAULT_ITEM_IDS.PLATFORM]: t('Platform'),
    [DEFAULT_ITEM_IDS.BROADCASTS]: t('Broadcasts'),
    [DEFAULT_ITEM_IDS.LIBRARY]: t('Library'),
    [DEFAULT_ITEM_IDS.FORMS]: t('Forms'),
    [DEFAULT_ITEM_IDS.SURVEYS]: t('Surveys'),
    [DEFAULT_ITEM_IDS.ADS]: t('Ad Manager'),
    [DEFAULT_ITEM_IDS.AUTOMATIONS]: t('Automations'),
    [DEFAULT_ITEM_IDS.SETTINGS]: t('Settings'),
    [DEFAULT_ITEM_IDS.MY_ACCOUNT]: t('My Account'),
    [DEFAULT_ITEM_IDS.MY_PROFILE]: t('My Profile'),
    [DEFAULT_ITEM_IDS.CHANGE_BUSINESS]: t('Change Business'),
    [DEFAULT_ITEM_IDS.ADD_BUSINESS]: t('Add Business'),
    [DEFAULT_ITEM_IDS.LOGOUT]: t('Logout'),
    [DEFAULT_ITEM_IDS.HELP]: t('Help'),
    [DEFAULT_ITEM_IDS.KNOWLEDGE_BASE]: t('Knowledge Base'),
    [DEFAULT_ITEM_IDS.CHAT_WITH_US]: t('Contact Us'),
    [DEFAULT_ITEM_IDS.ACTIVITIES]: t('Activities'),
    [DEFAULT_ITEM_IDS.SMART_CONNECTORS]: t('SmartConnectors'),
    [DEFAULT_ITEM_IDS.RECENT_RECORDS]: t('Recent Records'),
    [DEFAULT_ITEM_IDS.MARKETPLACE]: t('App Marketplace'),
  };
};

export const DYNAMIC_ITEM_IDS = {
  NEW_CATEGORY: 'new_category',
  NEW_CUSTOM_URL: 'new_custom_url',
  NEW_RECENT_RECORDS: 'new_recent_records',
};

export const PREVIEW_FLAG_ITEM_IDS = [
  DEFAULT_ITEM_IDS.ORDERS,
  DEFAULT_ITEM_IDS.PRODUCTS,
  DEFAULT_ITEM_IDS.MARKETING_COSTS,
];

export const DEFAULT_INFO = {
  id: DEFAULT_ITEM_IDS.HELP,
  icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.HELP],
  type: 'category',
  isDefault: true,
  label: 'Help',
  isLocked: true,
  isColorLocked: true,
  isTextLocked: true,
  widthPercent: 100,
  children: [
    {
      id: DEFAULT_ITEM_IDS.KNOWLEDGE_BASE,
      icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.KNOWLEDGE_BASE],
      isDefault: true,
      label: 'Knowledge Base',
      isLocked: false,
      widthPercent: 100,
      type: 'item',
    },
    {
      id: DEFAULT_ITEM_IDS.CHAT_WITH_US,
      icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.CHAT_WITH_US],
      isDefault: true,
      label: 'Chat With Us',
      isLocked: false,
      widthPercent: 100,
      type: 'item',
    },
  ],
};

export const DEFAULT_TOOLBAR_ITEMS = [
  {
    id: DEFAULT_ITEM_IDS.HOMEPAGES,
    icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.HOMEPAGES],
    isDefault: true,
    label: 'Homepage',
    isLocked: false,
    widthPercent: 100,
    type: 'item',
    color: colorsSecondary.brown.dark,
  },
  {
    id: DEFAULT_ITEM_IDS.DASHBOARD,
    icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.DASHBOARD],
    isDefault: true,
    label: 'Dashboard',
    isLocked: false,
    widthPercent: 100,
    type: 'item',
    color: colorsPrimary.blue.dark,
  },
  {
    id: DEFAULT_ITEM_IDS.DATA,
    icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.DATA],
    isDefault: true,
    label: 'Data',
    isLocked: false,
    widthPercent: 100,
    type: 'category',
    color: colorsPrimary.green.dark,
    children: [
      {
        id: DEFAULT_ITEM_IDS.CONTACTS,
        icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.CONTACTS],
        isDefault: true,
        label: 'Contacts',
        isLocked: false,
        widthPercent: 50,
        type: 'item',
      },
      {
        id: DEFAULT_ITEM_IDS.MARKETING_COSTS,
        icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.MARKETING_COSTS],
        isDefault: true,
        label: 'Marketing Costs',
        isLocked: false,
        widthPercent: 50,
        type: 'item',
      },
      {
        id: DEFAULT_ITEM_IDS.CUSTOM_OBJECTS,
        icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.CUSTOM_OBJECTS],
        isDefault: true,
        label: 'Custom Objects',
        isLocked: false,
        widthPercent: 50,
        type: 'item',
      },
      {
        id: DEFAULT_ITEM_IDS.SMART_CONNECTORS,
        icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.SMART_CONNECTORS],
        isDefault: true,
        label: 'SmartConnectors',
        isLocked: false,
        widthPercent: 50,
        type: 'item',
      },
    ],
  },
  {
    id: DEFAULT_ITEM_IDS.PLATFORM,
    icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.PLATFORM],
    isDefault: true,
    label: 'Platform',
    isLocked: false,
    widthPercent: 100,
    type: 'category',
    color: colorsPrimary.orange.dark,
    children: [
      {
        id: DEFAULT_ITEM_IDS.BROADCASTS,
        isDefault: true,
        icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.BROADCASTS],
        label: 'Broadscasts',
        isLocked: false,
        widthPercent: 50,
        type: 'item',
      },
      {
        id: DEFAULT_ITEM_IDS.LIBRARY,
        isDefault: true,
        icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.LIBRARY],
        label: 'Library',
        isLocked: false,
        widthPercent: 50,
        type: 'item',
      },
      {
        id: DEFAULT_ITEM_IDS.FORMS,
        isDefault: true,
        icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.FORMS],
        label: 'Forms',
        isLocked: false,
        widthPercent: 50,
        type: 'item',
      },
      {
        id: DEFAULT_ITEM_IDS.SURVEYS,
        isDefault: true,
        icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.SURVEYS],
        label: 'Surveys',
        isLocked: false,
        widthPercent: 50,
        type: 'item',
      },
      {
        id: DEFAULT_ITEM_IDS.ACTIVITIES,
        isDefault: true,
        icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.ACTIVITIES],
        label: 'Activities',
        isLocked: false,
        widthPercent: 50,
        type: 'item',
      },
      {
        id: DEFAULT_ITEM_IDS.ADS,
        isDefault: true,
        icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.ADS],
        label: 'Ad Manager',
        isLocked: false,
        widthPercent: 50,
        type: 'item',
      },
      {
        id: DEFAULT_ITEM_IDS.MARKETPLACE,
        isDefault: true,
        icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.MARKETPLACE],
        label: 'App Marketplace',
        isLocked: false,
        widthPercent: 50,
        type: 'item',
        color: colorsSecondary.brown.dark,
      },
    ],
  },
  {
    id: DEFAULT_ITEM_IDS.AUTOMATIONS,
    isDefault: true,
    icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.AUTOMATIONS],
    label: 'Automations',
    isLocked: false,
    widthPercent: 100,
    type: 'item',
    color: colorsPrimary.purple.dark,
  },
  {
    id: DEFAULT_ITEM_IDS.SETTINGS,
    isDefault: true,
    icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.SETTINGS],
    label: 'Settings',
    isLocked: false,
    widthPercent: 100,
    type: 'item',
    color: colorsSecondary.aqua.dark,
  },
  {
    id: DEFAULT_ITEM_IDS.RECENT_RECORDS,
    isDefault: true,
    icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.RECENT_RECORDS],
    label: 'Recent Records',
    isLocked: false,
    widthPercent: 100,
    type: 'category',
    color: colorsPrimary.blue.dark,
    dynamicallyPopulated: true,
    hasPopup: true,
    children: [],
    wasDynamic: true,
  },
  DEFAULT_INFO,
];

export const DEFAULT_TOOLBAR_ITEMS_FLAT = DEFAULT_TOOLBAR_ITEMS.reduce(
  (acc, curr) => {
    return [...acc, ...(curr.children ?? [])];
  },
  []
);

export const CHANGE_BUSINESS_ITEM = {
  id: DEFAULT_ITEM_IDS.CHANGE_BUSINESS,
  icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.CHANGE_BUSINESS],
  isDefault: true,
  label: 'Change Business',
  isLocked: true,
  widthPercent: 100,
  type: 'item',
};

export const DEFAULT_MY_ACCOUNT = {
  id: DEFAULT_ITEM_IDS.MY_ACCOUNT,
  isDefault: true,
  label: 'My Account',
  type: 'category',
  icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.MY_ACCOUNT], // this should be the user icon
  isLocked: true,
  isColorLocked: true,
  widthPercent: 100,

  children: [
    {
      id: DEFAULT_ITEM_IDS.MY_PROFILE,
      icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.MY_PROFILE],
      isDefault: true,
      label: 'My Profile',
      isLocked: true,
      widthPercent: 100,
      type: 'item',
    },
    CHANGE_BUSINESS_ITEM,
    {
      id: DEFAULT_ITEM_IDS.ADD_BUSINESS,
      icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.ADD_BUSINESS],
      isDefault: true,
      label: 'Add Business',
      isLocked: false,
      widthPercent: 100,
      type: 'item',
    },
    {
      id: DEFAULT_ITEM_IDS.LOGOUT,
      icon: DEFAULT_ICONS[DEFAULT_ITEM_IDS.LOGOUT],
      isDefault: true,
      label: 'Logout',
      isLocked: true,
      widthPercent: 100,
      type: 'item',
    },
  ],
};

export const CARD_PADDING = 1.5;
