import { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useToggle from 'react-use/lib/useToggle';
import { useTranslation } from 'react-i18next';
import { getOriginalError } from 'services/AxiosService';
import { isMobile, useWindowSize } from 'app/spacing';
import Loader from 'components/Kizen/Loader';
import { getSearchParam, setSearchParams } from 'hooks/useSearchParam';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import Automation2Service, {
  getAutomationObjectName,
} from 'services/Automation2Service';
import {
  camelToSnakeCase,
  camelToSnakeCaseKeys,
  snakeToCamelCase,
  snakeToCamelCaseKeys,
} from 'services/helpers';
import {
  buildPage,
  updatePageConfig,
  getAutomations,
  updateAutomation,
  duplicateAutomation,
  deleteAutomation,
  cleanToast,
  resetFilters,
  setFilterName,
  createOrUpdateGroup,
  removeGroup,
  changeGroupAction,
  changeDirectoryAction,
  deleteFolderAction,
  selectAllNonRootFolders,
  selectCurrentDirectory,
  selectRootFolder,
  selectValidParentFolders,
  applySearchAction,
  createFolderSuccess,
  editFolderSuccess,
  editAutomationFolderSuccess,
} from 'store/automationsPage/automations.redux';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import DesktopLayout from './DesktopLayout';
import MobileLayout from './MobileLayout';
import NewAutomationModal from './NewAutomationModal';
import { actions } from './Cells';
import { toastVariant, useToast } from 'components/ToastProvider';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { useDebounce } from 'react-use';
import { maybeTrackSearch } from 'utility/analytics';
import { DEFAULT_DELAY } from 'utility/config';
import { deferExecution } from 'utility/defer';
import { AutomationHistoryModal } from './AutomationHistoryModal';
import useModal from 'components/Modals/useModal';
import { useSyncDispatch } from 'ts-components/hooks/useSyncDispatch';
import {
  EditAutomationFolderModal,
  EditFolderModal,
  NewFolderModal,
} from './EditFolderModal';
import AxiosService from 'services/AxiosService';
import { getErrorMessage } from '__hooks/useErrors';
import {
  createAutomationFolder,
  deleteAutomationFolder,
  patchAutomation,
  updateAutomationFolder,
} from '@kizen/api/automation';

const defaultPageSettings = {
  pageNumber: 1,
  pageSize: 50,
};

const updateAutomationHandler = async ({ isAction, ...data }) => {
  try {
    return await Automation2Service.update(data);
  } catch (error) {
    const originalError = getOriginalError(error);
    if (originalError && originalError.name) {
      throw new Error(originalError.name);
    }

    if ('active' in data && !isAction) {
      throw new Error(originalError);
    }

    return null;
  }
};

const AutomationPage = ({ title }) => {
  const { t } = useTranslation();
  useSetTitleOnLoad(title(t));
  const history = useHistory();
  const dispatch = useDispatch();
  const syncDispatch = useSyncDispatch();
  const { width } = useWindowSize();
  const [deletedAutomation, setAutomationForDelete] = useState(null);
  const [historyAutomation, setHistoryAutomation] = useState(null);
  const [showAddModal, toggleShowAddModal] = useToggle(false);
  const [folderToEdit, setFolderToEdit] = useState(null);
  const [folderToDelete, setFolderToDelete] = useState(null);
  const [automationToEdit, setAutomationToEdit] = useState(null);
  const [isMenuOpen, toggleMenu] = useState(false);
  const [showToast] = useToast();

  const isFetching = useSelector((s) => s.automationPage.isFetching);
  const groups = useSelector((s) => s.automationPage.groups);
  const pageConfig = useSelector((s) => s.automationPage.pageConfig);
  const models = useSelector((s) => s.automationPage.models);
  const toastData = useSelector((s) => s.automationPage.toastData);
  const filters = useSelector((s) => s.automationPage.filters);
  const currentDirectory = useSelector(selectCurrentDirectory);
  const rootFolder = useSelector(selectRootFolder);
  const nonRootFolders = useSelector(selectAllNonRootFolders);
  const validParentFolders = useSelector(selectValidParentFolders);

  // Track debounced changes to the search term and send to google analytics
  const { search } = pageConfig;
  useDebounce(() => maybeTrackSearch(search, 'automations'), DEFAULT_DELAY, [
    search,
  ]);

  useEffect(() => {
    const sort = getSearchParam(history.location, 'sort');
    const group = getSearchParam(history.location, 'group');
    const search = getSearchParam(history.location, 'q');
    const page = getSearchParam(history.location, 'page');
    const size = getSearchParam(history.location, 'size');
    const folderId = getSearchParam(history.location, 'folder');

    syncDispatch(
      buildPage({
        t: t,
        groups: {},
        page: {
          search: search || '',
          group,
          sort,
          page: page && parseInt(page, 10),
          size: size && parseInt(size, 10),
        },
        folderId,
      })
    );
  }, [syncDispatch, history, t]);

  useEffect(() => {
    setSearchParams(history, {
      folder:
        rootFolder.id === currentDirectory.id ? null : currentDirectory.id,
    });
  }, [history, rootFolder, currentDirectory]);

  // memo functions ------------------------------------------------
  const sortValue = useMemo(
    () => getSortValue(snakeToCamelCase(pageConfig.sort)),
    [pageConfig.sort]
  );

  // filter handlers -----------------------------------------------
  const updateFilterName = useCallback(
    (name) => {
      dispatch(setFilterName({ name }));
    },
    [dispatch]
  );

  const applyFiltersAction = useCallback(
    (payload) => {
      dispatch(
        updatePageConfig({
          page: 1,
          filterObject: payload,
        })
      );
      dispatch(
        getAutomations({
          updatePageConfig: true,
        })
      );
    },
    [dispatch]
  );

  const saveGroupAction = useCallback(
    (data) => {
      dispatch(createOrUpdateGroup({ ...data, history }));
    },
    [dispatch, history]
  );

  const deleteGroupAction = useCallback(() => {
    dispatch(removeGroup({ history }));
    toggleMenu(false);
  }, [dispatch, history]);

  const clearFilter = useCallback(() => {
    dispatch(resetFilters());
    dispatch(
      getAutomations({
        updatePageConfig: true,
      })
    );
    deferExecution(() => {
      setSearchParams(history, {
        page: null,
        ...(pageConfig.group ? { group: null } : {}),
        ...(pageConfig.search ? { q: '' } : {}),
      });
    });
  }, [dispatch, history, pageConfig.group, pageConfig.search]);

  // table handlers ------------------------------------------------
  const handleChangeGroupId = useCallback(
    ({ value }) => {
      const data = value === 'none' ? null : value;
      dispatch(changeGroupAction({ id: value }));
      dispatch(getAutomations());
      deferExecution(() => {
        setSearchParams(history, {
          group: data,
          page: null,
        });
      });
    },
    [history, dispatch]
  );

  const handleChangeSearch = useCallback(
    (value) => {
      dispatch(applySearchAction({ history, search: value }));
    },
    [history, dispatch]
  );

  const handleChangePageNumber = useCallback(
    (value) => {
      dispatch(updatePageConfig({ page: value }));
      dispatch(getAutomations());
      deferExecution(() => {
        setSearchParams(history, {
          page: value,
        });
      });
    },
    [history, dispatch]
  );

  const handleChangePageSize = useCallback(
    (value) => {
      dispatch(updatePageConfig({ size: value }));
      dispatch(getAutomations());
      deferExecution(() => {
        setSearchParams(history, {
          size: value,
        });
      });
    },
    [history, dispatch]
  );

  const handleSortTable = useCallback(
    ({ column, direction }) => {
      const data = camelToSnakeCase(column);
      const value = getOrderingParam({
        column: data,
        direction,
      });

      dispatch(updatePageConfig({ sort: value }));
      dispatch(getAutomations());
      deferExecution(() => {
        setSearchParams(history, {
          sort: value,
        });
      });
    },
    [dispatch, history]
  );

  // columns handlers
  const handleUpdateAutomation = useCallback(
    async (data) => {
      const automation = await updateAutomationHandler(data);

      if (automation) {
        dispatch(
          updateAutomation({
            ...automation,
            customObjectName: getAutomationObjectName(automation),
            status: automation.active,
          })
        );
      }
    },
    [dispatch]
  );

  const handleChangeDirectory = useCallback(
    (folder) => {
      dispatch(changeDirectoryAction({ folder }));
    },
    [dispatch]
  );

  const [historyModalProps, , historyModal] = useModal({
    handleHide: () => setHistoryAutomation(null),
  });

  const handleSelectAction = useCallback(
    ({ value }, automationOrFolder) => {
      switch (value) {
        case actions.edit: {
          history.push(`/automation/${automationOrFolder.id}/`);
          break;
        }
        case actions.edit_automation_folder: {
          setAutomationToEdit(automationOrFolder);
          break;
        }
        case actions.activate: {
          handleUpdateAutomation({
            id: automationOrFolder.id,
            active: true,
            isAction: true,
            lastRevision: automationOrFolder.revision,
          });
          break;
        }
        case actions.deactivate: {
          handleUpdateAutomation({
            id: automationOrFolder.id,
            active: false,
            isAction: true,
            lastRevision: automationOrFolder.revision,
          });
          break;
        }
        case actions.duplicate: {
          dispatch(duplicateAutomation(automationOrFolder.id));
          break;
        }
        case actions.history: {
          setHistoryAutomation(automationOrFolder);
          historyModal.show();
          break;
        }
        case actions.delete: {
          setAutomationForDelete(automationOrFolder.id);
          break;
        }
        case actions.edit_folder: {
          setFolderToEdit(automationOrFolder);
          break;
        }
        case actions.delete_folder: {
          setFolderToDelete(automationOrFolder);
          break;
        }
      }
    },
    [history, dispatch, handleUpdateAutomation, historyModal]
  );

  const handleDeleteAutomation = useCallback(() => {
    dispatch(cleanToast());
    dispatch(
      deleteAutomation({
        id: deletedAutomation,
        t,
      })
    );
    setAutomationForDelete(null);
  }, [dispatch, deletedAutomation, t]);

  const handleAddFolder = useCallback(
    async ({ name, parentFolderId }) => {
      const res = await createAutomationFolder(
        AxiosService,
        {
          name,
          parent_folder_id: parentFolderId,
        },
        { skipErrorBoundary: true }
      );

      const folder = {
        automationsCount: 0,
        created: res.data.created,
        id: res.data.id,
        name: res.data.name,
        parentFolderId: res.data.parent_folder_id,
        updated: res.data.updated,
      };

      dispatch(createFolderSuccess({ folder }));

      return folder;
    },
    [dispatch]
  );

  const handleEditFolder = useCallback(
    async (folder) => {
      try {
        const { id, ...edits } = folder;

        const response = await updateAutomationFolder(
          AxiosService,
          id,
          camelToSnakeCaseKeys(edits),
          { skipErrorBoundary: true }
        );

        dispatch(
          editFolderSuccess({
            folder: snakeToCamelCaseKeys(response.data),
            edits,
          })
        );

        setFolderToEdit(null);
      } catch (error) {
        showToast({
          message:
            getErrorMessage(getOriginalError(error)) ||
            t('Error saving folder'),
          variant: toastVariant.FAILURE,
        });
      }
    },
    [dispatch, showToast, t]
  );

  const handleEditAutomationFolder = useCallback(
    async ({ folderId }) => {
      try {
        const response = await patchAutomation(
          AxiosService,
          automationToEdit.id,
          {
            folder_id: folderId,
            last_revision: automationToEdit.revision,
          },
          { skipErrorBoundary: true }
        );

        dispatch(
          editAutomationFolderSuccess({
            automation: camelToSnakeCaseKeys(response.data),
          })
        );

        setAutomationToEdit(null);
      } catch (error) {
        showToast({
          message:
            getErrorMessage(getOriginalError(error)) ||
            t('Error saving automation'),
          variant: toastVariant.FAILURE,
        });
      }
    },
    [dispatch, showToast, t, automationToEdit]
  );

  const [addFolderModalProps, , { show: showAddFolderModal }] = useModal({
    handleSubmit: handleAddFolder,
    handleError: (error) => {
      showToast({
        message:
          getErrorMessage(getOriginalError(error)) || t('Error saving folder'),
        variant: toastVariant.FAILURE,
      });
    },
  });

  useEffect(() => {
    if (toastData) {
      showToast(toastData);
      dispatch(cleanToast());
    }
  }, [dispatch, toastData, showToast]);

  if (isFetching) {
    return <Loader loading />;
  }

  return (
    <>
      {isMobile(width) ? (
        <MobileLayout
          groups={groups}
          groupId={pageConfig.group}
          handleChangeGroup={handleChangeGroupId}
          search={pageConfig.search}
          handleChangeSearch={handleChangeSearch}
          pageNumber={pageConfig.page || defaultPageSettings.pageNumber}
          pageSize={pageConfig.size || defaultPageSettings.pageSize}
          handleChangePageNumber={handleChangePageNumber}
          handleChangePageSize={handleChangePageSize}
          sort={sortValue}
          handleChangeSort={handleSortTable}
          handleSelectAction={handleSelectAction}
        />
      ) : (
        <DesktopLayout
          groups={groups}
          groupId={pageConfig.group}
          handleChangeGroup={handleChangeGroupId}
          search={pageConfig.search}
          handleChangeSearch={handleChangeSearch}
          pageNumber={pageConfig.page || defaultPageSettings.pageNumber}
          pageSize={pageConfig.size || defaultPageSettings.pageSize}
          handleChangePageNumber={handleChangePageNumber}
          handleChangePageSize={handleChangePageSize}
          sort={sortValue}
          handleChangeSort={handleSortTable}
          handleUpdateAutomation={handleUpdateAutomation}
          handleSelectAction={handleSelectAction}
          handleChangeDirectory={handleChangeDirectory}
          handleClickNewFolder={showAddFolderModal}
          handleClickNewAutomation={toggleShowAddModal}
          openMenu={isMenuOpen}
          toggleMenu={toggleMenu}
          filterInfo={filters}
          applyFiltersAction={applyFiltersAction}
          saveGroupAction={saveGroupAction}
          deleteGroupAction={deleteGroupAction}
          setFilterName={updateFilterName}
          clearFilter={clearFilter}
          errorsReturned={filters.errors}
          data-qa="automations-table"
        />
      )}
      <ConfirmDeletionModal
        show={Boolean(deletedAutomation)}
        onConfirm={handleDeleteAutomation}
        onHide={() => setAutomationForDelete(null)}
      >
        {t(
          'This will permanently delete the Automation and no more associated steps will run for any contact.'
        )}
      </ConfirmDeletionModal>
      {showAddModal && (
        <NewAutomationModal
          currentDirectory={currentDirectory}
          show
          models={models}
          folders={nonRootFolders}
          validParentFolders={validParentFolders}
          onHide={toggleShowAddModal}
          handleCreate={toggleShowAddModal}
          onAddFolder={handleAddFolder}
          rootFolderId={rootFolder.id}
        />
      )}
      {addFolderModalProps.show ? (
        <NewFolderModal
          currentDirectory={currentDirectory}
          folders={validParentFolders}
          onAddFolder={handleAddFolder}
          {...addFolderModalProps}
        />
      ) : null}
      {folderToEdit && (
        <EditFolderModal
          currentDirectory={currentDirectory}
          folder={folderToEdit}
          folders={validParentFolders}
          rootFolderId={rootFolder.id}
          onHide={() => setFolderToEdit(null)}
          onConfirm={handleEditFolder}
          onAddFolder={handleAddFolder}
        />
      )}
      {automationToEdit && (
        <EditAutomationFolderModal
          automation={automationToEdit}
          currentDirectory={currentDirectory}
          folders={nonRootFolders}
          validParentFolders={validParentFolders}
          rootFolderId={rootFolder.id}
          onHide={() => setAutomationToEdit(null)}
          onConfirm={handleEditAutomationFolder}
          onAddFolder={handleAddFolder}
        />
      )}
      {folderToDelete && (
        <ConfirmDeletionModal
          show
          actionBtnColor="red"
          onHide={() => setFolderToDelete(null)}
          onConfirm={async () => {
            try {
              await deleteAutomationFolder(AxiosService, folderToDelete.id, {
                skipErrorBoundary: true,
              });

              dispatch(deleteFolderAction({ id: folderToDelete.id }));
              setFolderToDelete(null);
            } catch (error) {
              showToast({
                message:
                  getErrorMessage(getOriginalError(error)) ||
                  t('Error deleting folder'),
                variant: toastVariant.FAILURE,
              });
            }
          }}
        >
          {t(
            'Are you sure you want to delete this folder? The automations within the folder will be moved up one folder level (they will not be deleted).'
          )}
        </ConfirmDeletionModal>
      )}
      {historyAutomation ? (
        <AutomationHistoryModal
          automation={historyAutomation}
          modalProps={historyModalProps}
        />
      ) : null}
    </>
  );
};

export default AutomationPage;
