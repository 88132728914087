import { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import useModal from 'components/Modals/useModal';
import { useFlashValidation } from 'hooks/useFlashState';
import useToggle from 'react-use/lib/useToggle';
import { getStepByKey } from './steps';
import {
  CUSTOMIZE_LAYOUT_STEP_KEY,
  RELATED_OBJECTS_STEP_KEY,
} from './constants';
import { useQueryClient } from 'react-query';
import { useWizardSave } from './useWizardSave';
import { CUSTOM_LAYOUT_TABS } from './utilities';
import { useHistory } from 'react-router-dom';

export function useWizardControl(props) {
  const {
    onHide,
    model,
    isDirty,
    routesProp,
    builderProps,
    resetField,
    isPipeline,
    setIsContactNeedsToBeAdded,
    focusStep,
    customLayoutSelectedTab,
    setCustomLayoutSelectedTab,
    setStagedLayoutChanges,
  } = props;
  const history = useHistory();
  const {
    validationFormProps: {
      validateFormState,
      setValidationState,
      fieldsRef,
      firstFieldWithErrorRef,
      resetValidationState,
    },
  } = builderProps;

  const queryClient = useQueryClient();
  const [saving, setSaving] = useToggle(false);
  const [errors, cancelFlash] = useFlashValidation();
  const [activeStep, setActiveStep] = useState(
    focusStep ||
      getStepByKey(history.location?.state?.focusStepKey) ||
      Object.values(routesProp)[0]
  );

  const goingStepRef = useRef(null);

  useEffect(() => {
    if (
      activeStep.key === CUSTOMIZE_LAYOUT_STEP_KEY &&
      customLayoutSelectedTab
    ) {
      goingStepRef.current = null;
    }
  }, [activeStep.key, customLayoutSelectedTab]);

  const isClosingModalRef = useRef(false);
  const preSave = useRef(null);

  const [confirmationModalProps, , confirmationModal] = useModal({
    handleHide: () => {
      isClosingModalRef.current = false;
    },
  });
  const [deletionModalProps, , deletionModal] = useModal();
  const [intermediateModalProps, , intermediateModal] = useModal();

  const handleClose = useCallback(
    (e) => {
      if (e?.ctrlKey) {
        return;
      }
      e.preventDefault();
      if (isDirty) {
        cancelFlash();
        isClosingModalRef.current = true;
        confirmationModal.show();
      } else {
        onHide();
      }
    },
    [cancelFlash, isDirty, confirmationModal, onHide]
  );

  const handleNext = useCallback(async () => {
    if (activeStep.key === CUSTOMIZE_LAYOUT_STEP_KEY) {
      if (customLayoutSelectedTab === CUSTOM_LAYOUT_TABS.RECORDS_LAYOUT) {
        setCustomLayoutSelectedTab(
          isPipeline
            ? CUSTOM_LAYOUT_TABS.BOARD_VIEW_LAYOUT
            : CUSTOM_LAYOUT_TABS.DEFAULT_COLUMNS
        );
        return;
      }

      if (customLayoutSelectedTab === CUSTOM_LAYOUT_TABS.BOARD_VIEW_LAYOUT) {
        setCustomLayoutSelectedTab(CUSTOM_LAYOUT_TABS.DEFAULT_COLUMNS);
        return;
      }
    }

    goingStepRef.current = null;
    const index = routesProp.findIndex((step) => step.key === activeStep.key);
    const nextStep = routesProp.slice(index + 1).find((step) => !step.disabled);

    await nextStep?.handleBeforeNavigation?.({ queryClient });

    const nextIndex = routesProp.findIndex((step) => step.key === nextStep.key);

    setActiveStep(routesProp[nextIndex]);

    goingStepRef.current = routesProp[nextIndex];

    resetValidationState();
  }, [
    activeStep.key,
    resetValidationState,
    routesProp,
    queryClient,
    setCustomLayoutSelectedTab,
    customLayoutSelectedTab,
    isPipeline,
  ]);

  const handlePrevious = useCallback(async () => {
    const index = routesProp?.find((step) => step.key === activeStep.key).idx;

    if (activeStep.key === CUSTOMIZE_LAYOUT_STEP_KEY) {
      if (customLayoutSelectedTab === CUSTOM_LAYOUT_TABS.DEFAULT_COLUMNS) {
        setCustomLayoutSelectedTab(
          isPipeline
            ? CUSTOM_LAYOUT_TABS.BOARD_VIEW_LAYOUT
            : CUSTOM_LAYOUT_TABS.RECORDS_LAYOUT
        );
        return;
      }

      if (customLayoutSelectedTab === CUSTOM_LAYOUT_TABS.BOARD_VIEW_LAYOUT) {
        setCustomLayoutSelectedTab(CUSTOM_LAYOUT_TABS.RECORDS_LAYOUT);
        return;
      }

      if (customLayoutSelectedTab === CUSTOM_LAYOUT_TABS.RECORDS_LAYOUT) {
        goingStepRef.current = routesProp[index];
      } else if (!goingStepRef.current && isPipeline) {
        setCustomLayoutSelectedTab(CUSTOM_LAYOUT_TABS.BOARD_VIEW_LAYOUT);
        return;
      }
    }

    const prevStep = routesProp
      ?.slice(0, index - (isPipeline ? 0 : 1) || 1)
      .reverse()
      .find((step) => !step?.disabled);

    await prevStep?.handleBeforeNavigation?.({ queryClient });

    if (index === 0 || !goingStepRef.current) {
      onHide();
    } else {
      const prevIndex = routesProp.findIndex(
        (step) => step.key === prevStep.key
      );

      if (prevStep.key === CUSTOMIZE_LAYOUT_STEP_KEY) {
        setCustomLayoutSelectedTab(CUSTOM_LAYOUT_TABS.DEFAULT_COLUMNS);
      }
      setActiveStep(routesProp[prevIndex]);
      goingStepRef.current = routesProp[prevIndex];
    }
    resetValidationState();
  }, [
    customLayoutSelectedTab,
    setCustomLayoutSelectedTab,
    activeStep.key,
    isPipeline,
    onHide,
    resetValidationState,
    routesProp,
    queryClient,
  ]);

  const handleGoToStep = useCallback(
    async (step) => {
      goingStepRef.current = step;

      await step?.handleBeforeNavigation?.({ queryClient });
      setActiveStep(step);
      resetValidationState();
    },
    [queryClient, resetValidationState]
  );

  const handleConfirm = useCallback(() => {
    resetField();
    setStagedLayoutChanges(undefined);
    if (activeStep.key === RELATED_OBJECTS_STEP_KEY) {
      // If we are in the related objects step, we need to reset the related objects on discard
      setIsContactNeedsToBeAdded(false);
    }

    if (goingStepRef.current) {
      handleGoToStep(goingStepRef.current);
    } else {
      if (
        activeStep.key === CUSTOMIZE_LAYOUT_STEP_KEY &&
        isPipeline &&
        customLayoutSelectedTab === CUSTOM_LAYOUT_TABS.BOARD_VIEW_LAYOUT
      ) {
        setCustomLayoutSelectedTab(CUSTOM_LAYOUT_TABS.RECORDS_LAYOUT);
      } else if (
        activeStep.key === CUSTOMIZE_LAYOUT_STEP_KEY &&
        customLayoutSelectedTab === CUSTOM_LAYOUT_TABS.DEFAULT_COLUMNS
      ) {
        setCustomLayoutSelectedTab(
          isPipeline
            ? CUSTOM_LAYOUT_TABS.BOARD_VIEW_LAYOUT
            : CUSTOM_LAYOUT_TABS.RECORDS_LAYOUT
        );
      } else {
        handlePrevious();
      }
    }

    confirmationModal.hide();
  }, [
    activeStep.key,
    handlePrevious,
    confirmationModal,
    resetField,
    setIsContactNeedsToBeAdded,
    handleGoToStep,
    customLayoutSelectedTab,
    isPipeline,
    setCustomLayoutSelectedTab,
    setStagedLayoutChanges,
  ]);

  const isLastStep = useCallback((routesProp, activeStep) => {
    const index = routesProp.findIndex((step) => step.key === activeStep.key);

    return !routesProp?.slice(index + 1).find((step) => !step.disabled)?.key;
  }, []);

  const isEditing = useMemo(() => model?.id, [model]);

  const handleStep = useCallback(
    async (step) => {
      if (step.idx !== activeStep.idx) {
        cancelFlash();

        goingStepRef.current = step;

        if (isDirty) {
          confirmationModal.show();
        } else {
          setActiveStep(step);
        }
      }
    },
    [activeStep.idx, cancelFlash, isDirty, confirmationModal]
  );

  const handleGoBack = useCallback(async () => {
    cancelFlash();

    if (isDirty) {
      const index = routesProp.findIndex((step) => step.key === activeStep.key);

      if (
        routesProp[index].key === CUSTOMIZE_LAYOUT_STEP_KEY &&
        customLayoutSelectedTab !== CUSTOM_LAYOUT_TABS.RECORDS_LAYOUT
      ) {
        goingStepRef.current = routesProp[index];
      } else {
        goingStepRef.current = routesProp[index - 1];
      }
      confirmationModal.show();
    } else {
      handlePrevious();
    }
  }, [
    activeStep.key,
    cancelFlash,
    handlePrevious,
    isDirty,
    confirmationModal,
    routesProp,
    customLayoutSelectedTab,
  ]);

  // Save handler for wizard
  const handleSave = useWizardSave({
    ...props,
    model,
    routesProp,
    builderProps,
    validateFormState,
    setValidationState,
    fieldsRef,
    firstFieldWithErrorRef,
    resetValidationState,
    preSave,
    setSaving,
    activeStep,
    isLastStep,
    handleNext,
  });

  return {
    handleClose,
    handleNext,
    handlePrevious,
    handleConfirm,
    handleSave,
    handleGoBack,
    handleDelete: deletionModal.show,
    // modal
    modalProps: {
      confirmation: {
        modalProps: {
          ...confirmationModalProps,
          isClosingModalRef,
        },
        modal: confirmationModal,
      },
      deletion: {
        modalProps: deletionModalProps,
        modal: deletionModal,
      },
      intermediate: {
        modalProps: intermediateModalProps,
        modal: intermediateModal,
      },
    },
    //props
    activeStep,
    setActiveStep: handleStep,
    errors,
    isLastStep,
    isEditing,
    saving,
    preSave,
    goingStepRef,
    handleGoToStep,
  };
}
