import { TFunction } from 'react-i18next';
import { EmailStatus } from './types';

export const ACTION_VALUES = {
  delete: 'delete',
  edit: 'edit',
  block: 'block',
  unblock: 'unblock',
};

export const getEmailStatus = (
  email_status: EmailStatus,
  t: TFunction
): string =>
  ['error_reading_updates', 'error_downloading_history', 'not_setup'].includes(
    email_status
  )
    ? t('Inactive')
    : t('Active');
