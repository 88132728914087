import { useMemo, useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getDataQAForInput } from 'components/Inputs/helpers';
import Select from 'components/Inputs/Select';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import LinkedSelection from 'components/Inputs/Select/LinkedSelection';
import {
  useRelationshipSelect,
  useSelectEnter,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { TopMenuButton } from 'components/Kizen/Menu';
import { isPipelineObject } from 'components/Modals/utilities';
import CreateEntityModalShort, {
  PIPELINE,
  CUSTOM_OBJECT,
} from 'components/Modals/CreateEntity/ShortForm';
import { useEntityCreationModal } from 'components/Modals/CreateEntity/hook';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useQueryClient } from 'react-query';
import { RELATION_FIELD } from 'queries/query-keys';

// created a new component which based on RelationshipOne for now,
// because according to the KZN-4244 we need to use our RelationshipOne + newShortModal only for Schedule/Log Activity
// could potentially replace "RelationshipOne" entirely in the future
export default function RelationshipOneV2({
  fieldId,
  field,
  label,
  value = null,
  onChange,
  className = '',
  disabled,
  disableTopMenuButton = false,
  optionsReadyCallback = null,
  onFocus,
  onMenuOpen,
  onMenuClose,
  keepPreviousData = true,
  ...others
}) {
  const { assignFieldHandle } = useKeyBoardContext();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const selectRef = useRef(null);
  const [showEntityCreateModal, setShowEntityCreateModal] = useState(false);

  const { initialValues, setInitialValues, parseTypeAhead } =
    useEntityCreationModal(field);

  const [{ displayValue: displayWithoutMeta, handleChange, relationship }] =
    useRelationshipSelect({
      value,
      onChange,
      field,
    });

  const [typeaheadProps, typeaheadState, { refetch }] =
    useSelectTypeaheadWithScroll({
      fetch: relationship.search,
      objectToOption: relationship.toOption,
      fieldId: fieldId || field.id,
      onFocus,
      onMenuOpen,
      onMenuClose,
      selectRef,
      keepPreviousData,
      relatedObjectId: field.relations[0].model,
    });

  const displayValue = useMemo(
    () =>
      displayWithoutMeta && {
        ...displayWithoutMeta,
        // See LinkedSelection definition
        meta: {
          link: relationship.link && relationship.link(value),
        },
      },
    [relationship, displayWithoutMeta, value]
  );

  const entityCreatedHandler = useCallback(
    async (newEntity) => {
      await refetch();
      // need to invalidate all the fields that are related to this object
      await queryClient.invalidateQueries({
        queryKey: [...RELATION_FIELD.RELATED_OBJECTS(field.relations[0].model)],
      });
      handleChange(relationship.toOption(newEntity));
      setInitialValues({});
    },
    [refetch, queryClient, field, handleChange, relationship, setInitialValues]
  );

  const onStartCreate = () => {
    if (typeaheadProps.inputValue) {
      parseTypeAhead(typeaheadProps.inputValue, false);
    }
    setShowEntityCreateModal(true);
    typeaheadProps.onInputChange('');
  };

  const selectEnterProps = useSelectEnter({
    handlePressEnter: onStartCreate,
    options: typeaheadProps.options,
    loadingOptions: typeaheadState.loading,
  });

  useEffect(() => {
    //  for scroll to bottom
    if (
      optionsReadyCallback &&
      typeaheadState.onOptionsReady &&
      selectRef?.current?.state.menuIsOpen
    ) {
      optionsReadyCallback();
    }
  });

  const { setMenuIsOpen, ...customSelectMenuProps } = useCustomSelectMenuProps(
    selectRef,
    others
  );
  assignFieldHandle(fieldId || field.id, {
    customFocus: () => {
      selectRef.current?.select?.focus();
      setMenuIsOpen(false);
      return selectRef.current?.select;
    },
    customEscape: () => {
      setMenuIsOpen(false);
    },
    customUp: () => {
      setMenuIsOpen(true);
    },
    customDown: () => {
      setMenuIsOpen(true);
    },
    disabled,
  });
  return (
    <>
      <Select
        ref={selectRef}
        className={className}
        disabled={disabled}
        label={label}
        value={displayValue}
        onChange={handleChange}
        placeholder={`${t('Find')} ${field.entityName}`}
        menuTopButton={
          !disableTopMenuButton && (
            <TopMenuButton onClick={onStartCreate}>
              {`${t('Add')} ${field.entityName}`}
            </TopMenuButton>
          )
        }
        menuLeftButton={<ClearSelectButton />}
        menuRightButton={<ApplySelectButton />}
        components={{
          SingleValue: LinkedSelection,
        }}
        {...selectEnterProps}
        {...others}
        {...getDataQAForInput(field.entityName, field.fieldType)}
        {...customSelectMenuProps}
        {...typeaheadProps}
        isLoading={typeaheadState.loading}
      />
      {showEntityCreateModal ? (
        <CreateEntityModalShort
          objectType={isPipelineObject(field) ? PIPELINE : CUSTOM_OBJECT}
          objectId={field.id}
          show={showEntityCreateModal}
          onCreated={entityCreatedHandler}
          onCancel={() => {
            setShowEntityCreateModal(false);
            setInitialValues({});
          }}
          initialValues={initialValues}
        />
      ) : null}
    </>
  );
}
