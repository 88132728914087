import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { grayScale, shadowLight, borderTight } from 'app/colors';
import { textCss } from 'app/typography';
import { scrollbarCss } from 'app/spacing';

export const ViewWrapper = styled.div`
  // This is the size of the bottom padding on EditableText.
  // They should match so that there is no jitter switching between text and the input.
  margin-bottom: ${({ readOnly }) => (readOnly ? 0 : -7)}px;
  .ViewText {
    margin-top: -1px;
    max-width: calc(
      100% - ${({ shrink, readOnly }) => (shrink && !readOnly ? 20 : 0)}px
    );
  }
  .ViewText input:not(:hover) {
    background-image: none;
  }
  &:hover,
  tr:hover & {
    .ViewText input:not(:hover) {
      background-image: url(/images/inputUnderline.svg);
    }
  }
  input {
    min-width: 30px;
    padding-left: 0;
  }
  &&& input[disabled] {
    background: unset;
    // A bit blunt, but we need to make sure there's no image otherwise the background repeats
    background-image: none !important;
    color: ${grayScale.mediumDark};
  }
`;

export const TextareaWrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: flex-end;
  background: ${grayScale.white};
  margin-top: -20px;
  overflow: hidden;
  ${borderTight}
  ${shadowLight}
  > textarea {
    width: 100%;
    max-width: 100%;
    outline: none;
    border: none;
    resize: none;
    ${textCss()}
    ${scrollbarCss()}
    line-height: 125%;
    padding: 10px 30px 0 11px;
  }
  && > button {
    margin: 5px 10px 10px 5px;
    width: 12px;
    svg {
      width: 12px;
      height: auto;
    }
  }

  ${({ width, enableResize }) =>
    !enableResize &&
    css`
      width: ${`${width || 250}px`};
    `};
`;

export const LongTextWrapper = styled.div`
  width: 100%;
  padding: 10px 10px 0 10px;
`;

export const LongTextValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LinkButtonWrapper = styled.div`
  margin-left: 7px;
  position: relative;
  top: -4px;
  flex-shrink: 0;
`;
