import React, { useCallback, useEffect } from 'react';
import { useMeasure } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useEditor, useNode } from '@craftjs/core';
import { SectionContainer } from '@kizen/page-builder/nodes/containers';
import { colorsPrimary } from '@kizen/page-builder/internal/app/colors';
import { useShowControls } from '../useShowControls';
import Control from '../Control';
import { Row } from '../Row';
import { useDraggingType } from '../useDraggingType';
import EmptyNode from '../Empty';
import { useControlPadding } from '../useControlPadding';
import { useIsInBuilderContext } from 'components/PageBuilder/BuilderContext';

export const Section = (props) => {
  const { t } = useTranslation();
  const { children, width: widthProp, ...others } = props;
  const { query, hoveredId, enabled } = useEditor((state) => ({
    hoveredId: [...state.events.hovered.values()][0],
    enabled: state.options.enabled,
  }));
  const {
    id,
    connectors: { connect },
    setProp,
  } = useNode((node) => ({
    id: node.id,
  }));
  const draggingType = useDraggingType();
  const showControls = useShowControls(id, hoveredId, query);
  const controlPadding = useControlPadding(props);
  const [measureRef, { width }] = useMeasure();

  const refCb = useCallback(
    (node) => {
      connect(node);
      measureRef(node);
    },
    [connect, measureRef]
  );

  useEffect(() => {
    // We measure and set the available container width so we can support width/max-width settings in email
    if (width > 0) {
      setProp((p) => {
        p.containerWidth = `${width}`;
      });
    }
  }, [width, setProp]);
  const isInBuilderContext = useIsInBuilderContext();

  return (
    <Control
      ref={refCb}
      label={t('Section')}
      show={showControls}
      color={colorsPrimary.blue.dark}
      data-qa="page-builder-section"
      {...others}
    >
      <SectionContainer
        draggingType={draggingType}
        style={showControls ? controlPadding : undefined}
        isInBuilderContext={isInBuilderContext}
        width={widthProp}
        {...others}
      >
        {children ||
          (enabled && (
            <EmptyNode dropType="row">
              {t('Drag and drop')}{' '}
              <strong>
                <u>{t('Row')}</u>
              </strong>{' '}
              {t('here')}.
            </EmptyNode>
          ))}
      </SectionContainer>
    </Control>
  );
};

Section.craft = {
  rules: {
    canMoveIn: (incomingNodes) => {
      return incomingNodes.every((node) => node.data.type === Row);
    },
  },
};
