import React from 'react';

import {
  ChooseBusiness,
  DashboardPage,
  LoginPage,
  ResetPassword,
  StyleguidePage,
  VerifyAccountPage,
  ContactsPage,
  SettingsPage,
  FreeTrialProcessPage,
  AccountPage,
  SubscriptionPage,
  FreeTrialEnded,
  ContactDetail,
  FormsPage,
  FormSettingsPage,
  AutomationPage,
  SurveysPage,
  CustomObjects,
  CustomRecordDetails,
  CustomObjectRecordsPage,
  BroadcastsPage,
  LibraryPage,
  CommentThreadUnsubscribe,
  SubscriptionManagement,
  SurveySettingsPage,
  ActivitiesPage,
  ActivitySettingsPage,
  AdManagerPage,
  PermissionsNeeded,
  SsoLogin,
  KDSCustomRecordDetails,
  CustomObjectSettings,
  SmartConnectors,
  LoginRedirect,
} from 'pages';
import NotFound from 'pages/NotFound';
import Translation from 'pages/Translation';

import paths from './paths';

import {
  AuthenticatedRedirect,
  CompleteSession,
  NonAuthenticatedRedirect,
  MultiBusinessUserSession,
  ForceLogOut,
  AuthorizedBusinessCreation,
} from './auth';
import DashboardEditPage from 'pages/Dashboard/EditPage';
import DownloadFilePage from 'pages/DownloadFile';
import Logout from 'pages/Logout';
import { editToolbarPathObject } from 'pages/Account/routes';
import ContactsChartGroupSettingsPage from 'pages/ContactsPage/pages/ChartGroupSettings';
import CustomObjectsChartGroupSettingsPage from 'pages/CustomObjects/RecordsPage/pages/ChartGroupSettings';
import HomepagesPage from 'pages/Homepages';
import HomepagesSettingsPage from 'pages/Homepages/pages/Settings';
import { ColumnsPage } from 'pages/ColumnsPage';
import { FilterGroupsPage } from 'pages/FilterGroupsPage';
import { QuickFiltersPage } from 'pages/QuickFiltersPage';
import { RecordDetailCompatRedirect } from 'pages/KDSCustomRecordDetails/CompatRedirect';
import { PluginCallbackPage } from 'pages/Plugins/Callback';
import PluginFullPage from 'pages/Plugins/FullPage';
import { SmartConnectorSettingsPage } from 'pages/SmartConnectors/SmartConnector';
import { SmartConnectorBuilder } from 'pages/SmartConnectors/SmartConnector/SmartConnectorBuilder';
import MarketplacePage from '__pages/Marketplace';

const AutomationEngine = React.lazy(() => import('pages/AutomationEngine'));

const config = {
  login: {
    title: (t) => t('Login - KIZEN'),
    component: NonAuthenticatedRedirect(LoginPage),
    exact: true,
  },
  verifyAccount: {
    title: (t) => t('Verify Account - KIZEN'),
    component: ForceLogOut(VerifyAccountPage),
    exact: true,
  },
  resetPassword: {
    title: (t) => t('Reset Password - KIZEN'),
    component: ResetPassword,
    exact: true,
  },
  chooseBusiness: {
    label: 'Change Business',
    localizedLabel: (t) => t('Change Business'),
    title: (t) => t('Choose business - KIZEN'),
    component: MultiBusinessUserSession(ChooseBusiness),
    private: true,
    hideNavigation: true,
    exact: true,
  },
  dashboardSettings: {
    label: 'Dashboard Settings',
    localizedLabel: (t) => t('Dashboard Settings'),
    component: CompleteSession(DashboardEditPage),
    private: true,
    exact: false,
    hideNavigation: true,
  },
  dashboard: {
    label: 'Dashboard',
    localizedLabel: (t) => t('Dashboard'),
    title: (t) => t('Dashboard'),
    component: CompleteSession(DashboardPage),
    private: true,
    exact: true,
  },
  contacts: {
    label: 'Contacts',
    localizedLabel: (t) => t('Contacts'),
    title: (t) => t('Contacts'),
    component: CompleteSession(ContactsPage),
    exact: true,
    linksToV1: true, // This tells the nav to link to v1 even though the route exists on both v1 and v2
  },
  contactsColumns: {
    label: 'Contacts',
    localizedLabel: (t) => t('Contacts'),
    title: (t) => t('Contacts - Columns - KIZEN'),
    component: CompleteSession(ColumnsPage),
    exact: false,
    private: true,
    hideNavigation: true,
  },
  contactsFilterGroups: {
    label: 'Contacts',
    localizedLabel: (t) => t('Contacts'),
    title: (t) => t('Contacts - Filter Groups - KIZEN'),
    component: CompleteSession(FilterGroupsPage),
    exact: false,
    private: true,
    hideNavigation: true,
  },
  contactOverview: {
    label: 'Contact Overview',
    localizedLabel: (t) => t('Contact Overview'),
    title: (t) => t('Contacts'),
    component: CompleteSession(ContactDetail),
    private: true,
    exact: true,
  },
  contactRecordDetail: {
    label: 'Record Details',
    localizedLabel: (t) => t('Record Details'),
    title: (t) => t('Record Details'),
    component: CompleteSession(KDSCustomRecordDetails),
    private: true,
    exact: true,
  },
  contactChartGroupSettings: {
    label: 'Contact Chart Group Settings',
    localizedLabel: (t) => t('Contact Chart Group Settings'),
    title: (t) => t('Contacts Chart Group Settings'),
    component: CompleteSession(ContactsChartGroupSettingsPage),
    private: true,
    exact: false,
    hideNavigation: true,
  },
  automations: {
    label: 'Automations',
    localizedLabel: (t) => t('Automations'),
    title: (t) => t('Automations'),
    component: CompleteSession(AutomationPage),
    private: true,
    exact: true,
  },
  broadcasts: {
    label: 'Broadcasts',
    localizedLabel: (t) => t('Broadcasts'),
    title: (t) => t('Broadcasts'),
    component: CompleteSession(BroadcastsPage),
    private: true,
    exact: true,
  },
  newBusiness: {
    label: 'New Business',
    localizedLabel: (t) => t('New Business'),
    title: (t) => t('New Business'),
    component: AuthenticatedRedirect(SubscriptionPage),
    private: true,
    exact: true,
  },
  forms: {
    label: 'Forms',
    localizedLabel: (t) => t('Forms'),
    title: (t) => t('Forms'),
    component: CompleteSession(FormsPage),
    exact: true,
  },
  formSettings: {
    label: 'Form Settings',
    localizedLabel: (t) => t('Form Settings'),
    title: (t) => t('Forms'),
    component: CompleteSession(FormSettingsPage),
    exact: true,
  },
  library: {
    label: 'Library',
    localizedLabel: (t) => t('Library'),
    title: (t) => t('Library'),
    component: CompleteSession(LibraryPage),
    private: true,
    exact: true,
  },
  libraryBuild: {
    label: 'Message Builder',
    localizedLabel: (t) => t('Message Builder'),
    title: (t) => t('Message Builder'),
    component: CompleteSession(LibraryPage),
    private: true,
    exact: true,
  },
  adManager: {
    label: 'Ad Manager',
    localizedLabel: (t) => t('Ad Manager'),
    title: (t) => t('Ad Manager'),
    component: CompleteSession(AdManagerPage),
    private: true,
    exact: true,
  },
  submitTicket: {
    label: 'Submit Ticket',
    localizedLabel: (t) => t('Submit Ticket'),
    external: true,
    private: true,
  },
  helpDocs: {
    label: 'Help Docs',
    localizedLabel: (t) => t('Help Docs'),
    external: true,
    private: true,
  },
  surveys: {
    label: 'Surveys',
    localizedLabel: (t) => t('Surveys'),
    title: (t) => t('Surveys'),
    component: CompleteSession(SurveysPage),
    exact: true,
  },
  surveySettings: {
    label: 'Survey Settings',
    localizedLabel: (t) => t('Survey Settings'),
    title: (t) => t('Surveys'),
    component: CompleteSession(SurveySettingsPage),
    exact: true,
  },
  settings: {
    label: 'Settings',
    localizedLabel: (t) => t('Settings'),
    title: (t) => t('Settings'),
    component: CompleteSession(SettingsPage),
    private: true,
    exact: false,
  },
  styleGuide: {
    component: AuthenticatedRedirect(StyleguidePage),
    private: true,
    exact: true,
  },
  freeTrialProcess: {
    label: 'Free Trial/Setup Process',
    localizedLabel: (t) => t('Free Trial/Setup Process'),
    title: (t) => t('Free Trial - KIZEN'),
    component: AuthorizedBusinessCreation(FreeTrialProcessPage),
    hideNavigation: true,
    private: true,
    exact: true,
  },
  customObjectRecords: {
    label: 'Custom Records',
    localizedLabel: (t) => t('Custom Records'),
    component: CompleteSession(CustomObjectRecordsPage),
    title: (t) => t('Custom Objects'),
    private: true,
    exact: true,
  },
  customObjectSettings: {
    label: 'Custom Object Settings',
    localizedLabel: (t) => t('Custom Object Settings'),
    component: CompleteSession(CustomObjectSettings),
    title: (t) => t('Custom Object Settings'),
    private: true,
    exact: true,
    hideNavigation: true,
  },
  customObjectsColumns: {
    label: 'Custom Records',
    localizedLabel: (t) => t('Custom Records'),
    title: (t) => t('Custom Objects - Columns - KIZEN'),
    component: CompleteSession(ColumnsPage),
    private: true,
    exact: true,
    hideNavigation: true,
  },
  customObjectsFilterGroups: {
    label: 'Custom Records',
    localizedLabel: (t) => t('Custom Records'),
    title: (t) => t('Custom Objects - Filter Groups - KIZEN'),
    component: CompleteSession(FilterGroupsPage),
    exact: false,
    private: true,
    hideNavigation: true,
  },
  customObjectsQuickFilters: {
    label: 'Custom Records',
    localizedLabel: (t) => t('Custom Records'),
    title: (t) => t('Quick Filters - KIZEN'),
    component: CompleteSession(QuickFiltersPage),
    exact: false,
    private: true,
    hideNavigation: true,
  },
  customOverview: {
    label: 'Objects Details',
    localizedLabel: (t) => t('Objects Details'),
    title: (t) => t('Custom Objects'),
    component: CompleteSession(CustomRecordDetails),
    private: true,
    exact: true,
  },
  recordDetail: {
    label: 'Record Details',
    localizedLabel: (t) => t('Record Details'),
    title: (t) => t('Record Details'),
    component: CompleteSession(KDSCustomRecordDetails),
    private: true,
    exact: true,
  },
  compatRecordDetail: {
    label: 'Record Details',
    localizedLabel: (t) => t('Record Details'),
    title: (t) => t('Record Details'),
    component: CompleteSession(RecordDetailCompatRedirect),
    private: true,
    exact: true,
  },
  clientOverviewKDS: {
    label: 'Objects Details (KDS)',
    localizedLabel: (t) => t('Objects Details (KDS)'),
    title: (t) => t('Custom Objects (KDS)'),
    component: CompleteSession(KDSCustomRecordDetails),
    private: true,
    exact: true,
  },
  customObjectsChartGroupSettings: {
    label: 'Custom Objects Chart Group Settings',
    localizedLabel: (t) => t('Custom Objects Chart Group Settings'),
    title: (t) => t('Custom Objects Chart Group Settings'),
    component: CompleteSession(CustomObjectsChartGroupSettingsPage),
    private: true,
    exact: false,
    hideNavigation: true,
  },
  customObjects: {
    label: 'Custom Objects',
    localizedLabel: (t) => t('Custom Objects'),
    title: (t) => t('Custom Objects'),
    component: CompleteSession(CustomObjects),
    private: true,
    exact: true,
  },
  marketingCosts: {
    label: 'Marketing Costs',
    localizedLabel: (t) => t('Marketing Costs'),
    title: (t) => t('Marketing Costs'),
    component: CompleteSession(() => null),
    private: true,
    exact: true,
  },
  account: {
    label: 'My Profile',
    localizedLabel: (t) => t('My Profile'),
    title: (t) => t('Profile'),
    component: CompleteSession(AccountPage),
    private: true,
    exact: false,
  },
  automationEngine: {
    label: 'Automation Engine',
    localizedLabel: (t) => t('Automation Engine'),
    title: (t) => t('Automations'),
    component: CompleteSession(AutomationEngine),
    private: true,
    exact: true,
  },
  notFound: {
    label: 'Page not found',
    localizedLabel: (t) => t('Page not found'),
    title: (t) => t('Page Not Found - 404'),
    component: CompleteSession(NotFound),
    private: true,
    exact: true,
  },
  translation: {
    // TODO this route should disappear after translation approach settles
    label: 'Translation',
    localizedLabel: (t) => t('Translation'),
    component: CompleteSession(Translation),
    private: true,
    exact: true,
  },
  commentThreadUnsubscribe: {
    label: 'CommentThreadUnsubscribe',
    localizedLabel: (t) => t('CommentThreadUnsubscribe'),
    title: (t) => t('Unsubscribe From Comment Thread'),
    component: CommentThreadUnsubscribe,
    exact: true,
  },
  freeTrialEnded: {
    label: 'Free Trial Expired',
    localizedLabel: (t) => t('Free Trial Expired'),
    title: (t) => t('Free Trial Ended - KIZEN'),
    component: FreeTrialEnded,
    hideNavigation: true,
    private: true,
    exact: true,
  },
  subscriptionManagement: {
    label: 'SubscriptionManagement',
    localizedLabel: (t) => t('SubscriptionManagement'),
    title: (t) => t('Manage Subscriptions'),
    component: SubscriptionManagement,
    exact: true,
    hideNavigation: true,
    private: true,
  },
  activities: {
    label: 'Activities',
    localizedLabel: (t) => t('Activities'),
    title: (t) => t('Activities'),
    component: CompleteSession(ActivitiesPage),
    exact: true,
  },
  activitySettings: {
    label: 'Activity Settings',
    localizedLabel: (t) => t('Activity Settings'),
    title: (t) => t('Activities'),
    component: CompleteSession(ActivitySettingsPage),
    exact: true,
  },
  downloadFile: {
    label: 'Your File is Ready for Download',
    localizedLabel: (t) => t('Your File is Ready for Download'),
    title: (t) => t('Your File is Ready for Download'),
    component: CompleteSession(DownloadFilePage),
    exact: true,
  },
  logout: {
    title: (t) => t('Logout'),
    component: Logout,
    hideNavigation: true,
    private: true,
    exact: true,
  },
  permissionsNeeded: {
    title: (t) => t('Logout'),
    component: PermissionsNeeded,
    hideNavigation: false,
    private: false,
    exact: true,
  },
  homepages: {
    title: (t) => t('Home Pages'),
    component: CompleteSession(HomepagesPage),
    hideNavigation: false,
    private: true,
    exact: false,
  },
  homepagesSettings: {
    title: (t) => t('Home Pages Settings'),
    component: CompleteSession(HomepagesSettingsPage),
    hideNavigation: true,
    private: true,
  },
  ssoLogin: {
    title: (t) => t('Login'),
    component: SsoLogin,
    hideNavigation: false,
    private: true,
    exact: true,
  },
  pluginCallback: {
    title: (t) => t('Callback'),
    component: PluginCallbackPage,
    hideNavigation: true,
    private: false,
    exact: true,
  },
  fullPagePlugin: {
    title: (t) => t('Plugin'),
    component: PluginFullPage,
    hideNavigation: false,
    private: true,
    exact: true,
  },
  smartConnectors: {
    title: (t) => t('SmartConnectors'),
    component: CompleteSession(SmartConnectors),
    hideNavigation: false,
    private: true,
    exact: true,
  },
  smartConnectorSettings: {
    title: (t) => t('SmartConnector Settings'),
    component: CompleteSession(SmartConnectorSettingsPage),
    hideNavigation: false,
    private: true,
    exact: true,
  },
  smartConnectorBuilder: {
    title: (t) => t('SmartConnector Builder'),
    component: CompleteSession(SmartConnectorBuilder),
    hideNavigation: false,
    private: true,
    exact: true,
  },
  loginRedirect: {
    title: (t) => t('Redirecting...'),
    component: CompleteSession(LoginRedirect),
    hideNavigation: false,
    private: true,
    exact: true,
  },
  marketplace: {
    title: (t) => t('App Marketplace'),
    component: CompleteSession(MarketplacePage),
    hideNavigation: false,
    private: true,
    exact: true,
  },
  ...editToolbarPathObject,
};

paths.forEach((path, routeId) => {
  config[routeId].path = path;
});

export const routePathsWithoutNavbar = Object.values(config)
  .filter((route) => {
    // Internal, private routes configured to hide navigation
    return !route.external && route.private && route.hideNavigation;
  })
  .map((route) => route.path);

export default config;
