import { useCallback, useMemo } from 'react';
import {
  useAllExternalAccountsCC,
  useExternalAccountsCC,
} from '__queries/models/teamMember';
import { isErrorStatusCode } from 'pages/Account/pages/Profile/Forms/EmailIntegration';
import { getValueEmail } from 'services/helpers';

const EMPTY_ARRAY: any = [];

export type IntegratedInbox = {
  created: string;
  id: string;
  email: string;
  emailStatus: string;
  provider: string;
};

type Account = Pick<IntegratedInbox, 'id' | 'email' | 'emailStatus'>;

export type IntegratedInboxSC = {
  created: string;
  id: string;
  email: string;
  email_status: string;
  provider: string;
};

type AccountSelectOptions = {
  value: string;
  label: string;
};

export const useIntegratedInbox = (
  useAllIntgegratedInboxes: boolean = false
) => {
  const {
    data: all_accounts = EMPTY_ARRAY,
    isLoading: is_loading_all_accounts,
  } = useAllExternalAccountsCC(
    { ordering: 'email' },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: useAllIntgegratedInboxes,
    }
  );
  const {
    data: your_accounts = EMPTY_ARRAY,
    isLoading: is_loading_your_accounts,
  } = useExternalAccountsCC({
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !useAllIntgegratedInboxes,
  });

  const { accounts = EMPTY_ARRAY, isLoadingAccounts } = useMemo(
    () =>
      useAllIntgegratedInboxes
        ? { accounts: all_accounts, isLoadingAccounts: is_loading_all_accounts }
        : {
            accounts: your_accounts,
            isLoadingAccounts: is_loading_your_accounts,
          },
    [
      useAllIntgegratedInboxes,
      all_accounts,
      is_loading_all_accounts,
      your_accounts,
      is_loading_your_accounts,
    ]
  );

  const accountSelectOptions: AccountSelectOptions[] = useMemo(
    () =>
      accounts
        .filter(
          ({ emailStatus }: IntegratedInbox) => !isErrorStatusCode(emailStatus)
        )
        .map((account: Account) => getValueEmail(account)),
    [accounts]
  );

  const findAccountById = useCallback(
    (id: string) => accountSelectOptions.find(({ value }) => value === id),
    [accountSelectOptions]
  );

  return {
    accounts,
    isLoadingAccounts,
    accountSelectOptions,
    findAccountById,
  };
};
