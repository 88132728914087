import {
  cloneElement,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { borderRadii } from 'app/spacing';
import { hasInputControl, MaybeInputControl } from '../InputControl';
import BaseInput from './BaseInput';
import BaseInputLayout from './BaseInputLayout';
import Validation, { useValidation } from '../Validation';
import { applyRef } from '../props';
import { FIELD_TYPES } from 'utility/constants';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useUrlFromValue } from '../helpers';
import { Button } from '@kizen/kds/Button';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { Spacer } from '@kizen/kds/Spacer';

export const OutlineInputLayout = styled(BaseInputLayout)`
  height: 36px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: ${borderRadii.small};
`;

const StyledReadOnlyValue = styled(TextEllipsisWithTooltip)`
  line-height: 34px;
  width: 100%;
`;

const OutlineTextInput = forwardRef((props, ref) => {
  // props was spread on MaybeInputControl previously, but that causes the event handlers to fire twice
  const {
    fieldId,
    onBlur,
    onChange,
    onFocus,
    onKeyPress,
    placeholder: _,
    value: _1,
    'data-field-type': _2,
    'data-field-id': _3,
    fieldType,
    inputRef,
    displayMaskedValue,
    displayUrlLink = false,
    ...inputControlProps
  } = props;
  const {
    value,
    error,
    disabled,
    startAdornment,
    endAdornment,
    placeholder,
    className,
    Input,
    inModal,
    errorPlacement,
    style, // pull out style as we don't want it passed via others into the BaseInput
    validateOnFocus = false,
    ...others
  } = props;
  const { assignFieldHandle } = useKeyBoardContext();
  const currentInput = useRef();
  const numberInputRef = useRef();
  const validationRef = useRef();
  const mergeRef = useCallback(
    (el) => {
      applyRef(validationRef, el);
      applyRef(ref, el);
    },
    [ref]
  );
  const inputMergeRef = useCallback(
    (el) => {
      applyRef(inputRef, el);
      applyRef(currentInput, el);
    },
    [inputRef]
  );
  const [focused, setFocused] = useState(false);
  const [validation, validationProps] = useValidation(validationRef, props);

  const isNumberInput =
    fieldType === FIELD_TYPES.Integer.type ||
    fieldType === FIELD_TYPES.Decimal.type ||
    fieldType === FIELD_TYPES.Money.type;

  assignFieldHandle(fieldId, {
    customFocus: () => {
      if (isNumberInput) {
        numberInputRef.current?.focus();
        return numberInputRef.current;
      } else {
        currentInput.current?.focus();
        return currentInput.current;
      }
    },
    customEnter: (e) => {
      e.preventDefault();
    },
    customEscape: () => {
      if (fieldType === FIELD_TYPES.Date.type) {
        onBlur?.();
      }
    },
    customUp: () => {
      if (fieldType === FIELD_TYPES.Date.type) {
        currentInput.current.click();
      }
    },
    customDown: () => {
      if (fieldType === FIELD_TYPES.Date.type) {
        currentInput.current.click();
      }
    },
    disabled,
    fieldType,
  });

  useEffect(() => {
    if (others.forceValidation) {
      validation.onSubmission();
    }
  }, [others.forceValidation]); // eslint-disable-line react-hooks/exhaustive-deps

  const { urlFromValue, urlFromValueLinkProps, linkButtonProps } =
    useUrlFromValue(value, !displayMaskedValue && displayUrlLink);

  return (
    <MaybeInputControl
      variant="outline"
      {...inputControlProps}
      isShowAsterisk={inputControlProps.required}
    >
      <OutlineInputLayout
        ref={mergeRef}
        className={!hasInputControl(props) && className}
        disabled={disabled}
        error={error || validation.error}
        focused={focused}
        onClick={(e) => {
          if (typeof props.onClick === 'function' && !disabled) {
            currentInput.current?.focus();
            props.onClick(e);
          }
        }}
      >
        {startAdornment &&
          cloneElement(startAdornment, {
            variant: 'outline',
            start: true,
          })}
        {disabled ? (
          <StyledReadOnlyValue ref={inputMergeRef} {...urlFromValueLinkProps}>
            {value}
          </StyledReadOnlyValue>
        ) : (
          <BaseInput
            ref={inputMergeRef}
            getInputRef={numberInputRef}
            as={Input}
            value={value}
            placeholder={placeholder}
            {...others} // spread others here since the handlers are destructed separately for the inputControlProps
            onChange={(ev) => {
              if (onChange) onChange(ev.target.value, ev);
            }}
            onFocus={(ev) => {
              setFocused(true);
              if (onFocus) onFocus(ev);
              if (validation.onBlur && validateOnFocus) validation.onBlur(ev);
            }}
            onBlur={(ev) => {
              setFocused(false);
              if (onBlur) onBlur(ev);
              if (validation.onBlur) validation.onBlur(ev);
            }}
            // eslint-disable-next-line consistent-return
            onKeyPress={(ev) => {
              if (onKeyPress) onKeyPress(ev);
              if (validation.onKeyPress) return validation.onKeyPress(ev);
            }}
            helpMeOut
            {...(isNumberInput
              ? {
                  onValueChange: (
                    { formattedValue, floatValue },
                    { event }
                  ) => {
                    if (
                      value?.toString()?.charAt(0) === '-' &&
                      floatValue >= 0
                    ) {
                      if (onChange) onChange(formattedValue, event);
                    }
                  },
                }
              : {})}
          />
        )}
        {endAdornment &&
          cloneElement(endAdornment, {
            variant: 'outline',
            end: true,
          })}
        {urlFromValue && !disabled ? (
          <>
            <Spacer size={8} />
            <Button {...linkButtonProps} />
          </>
        ) : null}
      </OutlineInputLayout>
      <Validation
        inModal={inModal}
        errorPlacement={errorPlacement}
        {...validationProps}
      />
    </MaybeInputControl>
  );
});

OutlineTextInput.displayName = 'OutlineTextInput';

export default OutlineTextInput;
