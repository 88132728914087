import { Suspense } from 'react';
import { Provider } from 'react-redux';
import store from 'store';
import App from 'app';
import { KIZEN_AUTHORIZED_KEY } from 'storage';
import { getAppRoot } from 'utility/app';
import 'i18n-config'; // initialize translations
import ToastProvider from 'components/ToastProvider';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { SentryProvider } from 'sentry';
import { unsafeRootQueryClient } from 'queries/unsafe-query-client';
import Loader from 'components/Kizen/Loader';
import structuredClone from 'core-js-pure/actual/structured-clone';
import { createRoot } from 'react-dom/client';
import { PluginContextWrapper } from 'ts-components/Plugins/PluginContext';

if (!('structuredClone' in window)) {
  window.structuredClone = structuredClone;
}

// This handler is responsible for syncing session changes — as stored
// in localStorage — across different, simultaneous browsing contexts (tabs, windows)
// E.g. if you have 2 tabs open and select a business in tab 1, this event
// is fired and handled only in tab 2, bringing tab 2's UI up to date with tab 1's
// via the serviceRehydrateStart call (updates redux to match our token, thereby updating
// places in UI that depend on auth state stored in redux)
window.addEventListener('storage', (ev) => {
  const { key } = ev;

  // checking document.hasFocus is necessary for Safari
  // https://stackoverflow.com/questions/61269238/safari-fires-storageevent-in-same-tab
  // otherwise, these storage events reload the current tab, too, which
  // would abort outgoing HTTP requests, leading to odd navigation and UI behaviors
  // (not strictly necessary for Chrome, but works consistently)
  if (key === KIZEN_AUTHORIZED_KEY && !document.hasFocus()) {
    document.location.reload();
  }
});

const container = getAppRoot();
const root = createRoot(container);

root.render(
  <QueryClientProvider client={unsafeRootQueryClient}>
    {/* Don't worry, this results in a No-Op component in production automagically */}
    <ReactQueryDevtools initialIsOpen={false} />
    <Provider store={store}>
      <SentryProvider />
      <ToastProvider>
        <PluginContextWrapper>
          <Suspense fallback={<Loader loading />}>
            <App />
          </Suspense>
        </PluginContextWrapper>
      </ToastProvider>
    </Provider>
  </QueryClientProvider>
);
