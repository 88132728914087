import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import SmartConnectorService from 'services/SmartConnectorService';
import CustomObjectsService from 'services/CustomObjectsService';
import { defaultSmartConnector } from '__pages/SmartConnectors/SmartConnector/context';
import { defaultSmartConnectorFlow } from 'pages/SmartConnectors/SmartConnector/SmartConnectorBuilder/PrimariObjectUploadWizard/context';
import { FlowBuilderContextType } from '../context';
import { defaultSmartConnectorStats } from '../constants';
import { FlowStep, FlowTrigger, iDrop } from '../types';
import {
  CustomObjectLike,
  SmartConnector,
  SmartConnectorBuilderErrors,
  SmartConnectorFlowLoadDTO,
  SmartConnectorStatus,
} from '__pages/SmartConnectors/types';
import { defaultSmartConnectorTrigger } from '__pages/AutomationEngine/steps';
import { defaultUploadStep } from '__pages/AutomationEngine/steps';
import { loadToUploadStep } from '../helpers';
import { usePreReleaseFeatures } from '__hooks/usePreReleaseFeatures';
import { useToast } from '__components/ToastProvider';
import { getOriginalError } from '__services/AxiosService';
import { toastVariant } from '__components/ToastProvider';
import { defaultConnectionGetRequestsStep } from '__pages/AutomationEngine/steps';
import { defaultConnectionStep } from '__pages/AutomationEngine/steps';
import { SMART_CONNECTOR_STATUSES } from '__pages/SmartConnectors/constants';
import { useTranslation } from 'react-i18next';
import { useSmartConnectorTitle } from '__pages/SmartConnectors/SmartConnector/hooks/useSmartConnectorTitle';
import { useDeactivationModal } from '__pages/SmartConnectors/SmartConnector/hooks/useDeactivationModal';
import { getErrorMessage } from '__hooks/useErrors';
import { getIsSmartConnectorActive } from '__pages/SmartConnectors/helpers';

export const useFlowBuilderContext = (): FlowBuilderContextType => {
  const { t } = useTranslation();
  const { id } = useParams<{
    id: string;
  }>();

  const [showToast] = useToast();

  const preRelease = usePreReleaseFeatures();

  const [placing, setPlacing] = useState(false);
  const [lastPlacingStep, setLastPlacingStep] = useState(null);
  const [interacting, setInteracting] = useState(false);
  const [isDirty, setDirty] = useState(true);
  const [smartConnector, setSmartConnector] = useState(
    defaultSmartConnector as SmartConnector
  );
  const [name, setName] = useState('');
  const [status, setStatus] = useState<SmartConnectorStatus>(
    SMART_CONNECTOR_STATUSES.setup
  );
  const [triggers, setTriggers] = useState<FlowTrigger[]>([
    defaultSmartConnectorTrigger,
  ]);
  const [connections, setConnections] = useState<FlowStep[]>([]);
  const [steps, setSteps] = useState<FlowStep[]>([]);
  const [uploads, setUploads] = useState([defaultUploadStep]);
  const [showStats, setShowStats] = useState(false);
  const [stats, _setStats] = useState(defaultSmartConnectorStats);
  const [persisting, setPersisting] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [droppedStep, setDroppedStep] = useState<iDrop | null>(null);
  const [customObjects, setCustomObjects] = useState<CustomObjectLike[]>([]);
  const [flowErrors, setFlowErrors] = useState<SmartConnectorBuilderErrors>({
    flow: {},
  });

  const {
    deactivationModalProps,
    showDeactivationModal,
    showDeactivationModalWithResult,
  } = useDeactivationModal();

  const flowStateRef = useRef({ name, status, uploads });

  /// actions

  const dropStep = useCallback((step: iDrop | null) => {
    setDroppedStep(step);
  }, []);

  const placeStep = useCallback((step: any) => {
    if (step) {
      setPlacing(true);
      setLastPlacingStep(step);
    } else {
      setPlacing(false);
    }
  }, []);

  const patchSmartConnector = useCallback(
    async (
      patch: Partial<
        | SmartConnector
        | { flow: { loads: Partial<SmartConnectorFlowLoadDTO>[] } }
      >
    ) => {
      setPersisting(true);
      try {
        const { data } = await SmartConnectorService.updateSmartConnector(
          smartConnector.id,
          patch,
          { skipErrorBoundary: true }
        );
        setSmartConnector(data);
        setFlowErrors({
          flow: {},
        });
        return true;
      } catch (err) {
        const originalError = getOriginalError(err);

        if (originalError.flow?.loads) {
          setFlowErrors((prev) => ({
            ...prev,
            flow: { ...prev.flow, loads: originalError.flow?.loads },
          }));
        }

        showToast({
          message:
            originalError.name ||
            originalError.status ||
            t('Failed to save SmartConnector'),
          variant: toastVariant.FAILURE,
        });
        setNameError(!!originalError.name);
        return false;
      } finally {
        setPersisting(false);
      }
    },
    [smartConnector.id, showToast, t]
  );

  const uploadsAreDirty = useMemo(
    () =>
      uploads.some(
        (upload, i) => upload.details.id !== smartConnector.flow.loads[i]?.id
      ),
    [uploads, smartConnector.flow.loads]
  );

  const saveFlow = useCallback(async () => {
    const payload: Pick<SmartConnector, 'name' | 'status'> & {
      flow?: { loads: Partial<SmartConnectorFlowLoadDTO>[] };
    } = { name, status };
    if (uploadsAreDirty) {
      payload.flow = {
        loads: uploads.map(({ details }, i) => ({
          id: details.id,
          custom_object: details.custom_object,
          order: i,
        })),
      };

      if (
        payload.status !== SMART_CONNECTOR_STATUSES.inactive &&
        getIsSmartConnectorActive({ status: smartConnector.status })
      ) {
        return showDeactivationModal({
          handleSave: () =>
            patchSmartConnector({
              ...payload,
              status: SMART_CONNECTOR_STATUSES.inactive,
            }),
          handleDiscard: () => {
            setName(flowStateRef.current.name);
            setStatus(flowStateRef.current.status);
            setUploads(flowStateRef.current.uploads);
          },
        });
      }
    }
    await patchSmartConnector(payload);
  }, [
    patchSmartConnector,
    name,
    status,
    uploads,
    uploadsAreDirty,
    smartConnector.status,
    showDeactivationModal,
  ]);

  // effects //////////////////////////////////////////////////////////

  useSmartConnectorTitle(smartConnector);

  useEffect(() => {
    if (droppedStep) {
      if (
        ['upload_primary_object', 'upload_related_object'].includes(
          droppedStep.step.type
        )
      ) {
        setDroppedStep(null);

        const indexTo = droppedStep.index || 0;
        const indexFrom = droppedStep.step.order;

        setUploads((prev) => {
          const next = [...prev];

          next.splice(
            indexTo > indexFrom ? indexTo - 1 : indexTo,
            0,
            next.splice(indexFrom, 1)[0]
          );

          return next.map((step, i) => ({ ...step, order: i }));
        });

        const loadErrors = flowErrors.flow.loads || [];

        if (loadErrors.length) {
          setFlowErrors((prev) => ({
            ...prev,
            flow: {
              ...prev.flow,
              loads: loadErrors.splice(
                indexTo > indexFrom ? indexTo - 1 : indexTo,
                0,
                loadErrors.splice(indexFrom, 1)[0]
              ),
            },
          }));
        }
      }
    }
  }, [droppedStep, flowErrors.flow.loads]);

  useEffect(() => {
    setName(smartConnector.name);
    setStatus(smartConnector.status);
    const loadSteps = (smartConnector.flow.loads || []).map((load, i) =>
      loadToUploadStep(smartConnector, load, i)
    );
    const nextUploads = loadSteps.length ? loadSteps : [defaultUploadStep];
    setUploads(nextUploads);
    if (smartConnector.flow.additional_variables?.length) {
      setConnections(
        [
          {
            ...defaultConnectionStep,
            details: {
              additional_variables: smartConnector.flow.additional_variables,
            },
          },
          preRelease && defaultConnectionGetRequestsStep,
        ].filter(Boolean)
      );
    }

    flowStateRef.current = {
      name: smartConnector.name,
      status: smartConnector.status,
      uploads: nextUploads,
    };
  }, [smartConnector, preRelease]);

  useEffect(() => {
    setDirty(
      name !== smartConnector.name ||
        status !== smartConnector.status ||
        uploadsAreDirty
    );
  }, [name, status, smartConnector, uploadsAreDirty]);

  const [
    { value: customObject = {}, loading: smartConnectorLoading },
    fetchSmartConnector,
  ] = useAsyncFn(
    async () => {
      const [{ data }, objects] = await Promise.all([
        SmartConnectorService.getSmartConnector(id),
        CustomObjectsService.getCustomObjectListLight(),
      ]);
      const smartConnector = {
        ...data,
        flow: data.flow || defaultSmartConnectorFlow,
      } as SmartConnector;
      setSmartConnector(smartConnector);
      setCustomObjects(objects);

      return CustomObjectsService.getCustomObjectDetails(
        smartConnector.custom_object
      );
    },
    [id],
    { loading: true }
  );

  useEffect(() => {
    fetchSmartConnector();
  }, [fetchSmartConnector]);

  const saveable = useMemo(
    () =>
      !smartConnectorLoading &&
      !persisting &&
      isDirty &&
      !getErrorMessage(flowErrors) &&
      !nameError,
    [smartConnectorLoading, persisting, isDirty, nameError, flowErrors]
  );

  return {
    preRelease: preRelease,
    smartConnector,
    customObject,
    setSmartConnector,
    refetchSmartConnector: fetchSmartConnector,
    placing,
    setPlacing,
    lastPlacingStep,
    setLastPlacingStep,
    interacting,
    setInteracting,
    isDirty, // Tracks whether there have been unpersisted changes
    setDirty,
    name,
    setName,
    status,
    setStatus,
    triggers,
    setTriggers,
    connections,
    setConnections,
    steps,
    setSteps,
    uploads,
    setUploads,
    showStats,
    setShowStats,
    stats,
    persisting,
    flowErrors,
    setFlowErrors,
    nameError,
    setNameError,
    placeStep,
    saveFlow,
    dropStep,
    droppedStep,
    smartConnectorLoading,
    saveable,
    customObjects,
    deactivationModalProps,
    showDeactivationModal,
    showDeactivationModalWithResult,
  };
};
