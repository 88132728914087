import styled from '@emotion/styled';
import { type ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { colorsButton, colorsPrimary } from 'app/colors';
import Select from '__components/Inputs/Select';
import DateTimeInputInline from '__components/Inputs/inline/DateTimeInput';
import SelectInline from '__components/Inputs/inline/Select';
import {
  TextEllipsis,
  TextEllipsisWithTooltip,
} from '__components/Kizen/Table';
import {
  SizedCell,
  StyledIcon,
  StyledFolder,
} from '__components/Kizen/Table/CellLineHeightStyles';
import ValidatedField from '__components/Kizen/ValidatedField';
import TableActionCell from 'components/Tables/ActionCell';
import {
  selectDirectoryLocation,
  type Automation,
  type Folder,
} from 'store/automationsPage/automations.redux';
import { useTooltip } from '__components/Kizen/Tooltip';
import { useSelector } from 'react-redux';

type OnUpdateAutomation = (
  data: Partial<Omit<Automation, 'revision'>> & { lastRevision: number }
) => Promise<void>;

type WithCustomObjectAccess<T extends { customObject: any }> = T & {
  customObject: T['customObject'] & {
    access: { view: boolean; edit: boolean; remove: boolean };
  };
};

type CellData = Folder | WithCustomObjectAccess<Automation>;

type CellProps = {
  column: {
    id: keyof WithCustomObjectAccess<Automation>;
    cell: ReactNode;
    headCell: ReactNode;
  };
  data: CellData & { value: any };
  head?: boolean;
};

const NoActionsCell = styled.div`
  width: 14px;
  height: 20px;
`;

const StyledAutomationFolder = styled(StyledFolder)`
  width: fit-content;
  max-width: 100%;
`;

export const columnSize = {
  spacer: '20px',
  name: '265px',
  customObjectName: '140px',
  status: '90px',
  numberActive: '72px',
  numberPaused: '79px',
  numberCompleted: '102px',
  type: '71px',
  created: '145px',
  actions: '47px',
};

export const paddingSize = {
  small: '20px',
  big: '40px',
};

export const actions = {
  edit: 'edit',
  edit_automation_folder: 'edit_automation_folder',
  deactivate: 'deactivate',
  activate: 'activate',
  duplicate: 'duplicate',
  history: 'history',
  delete: 'delete',
  edit_folder: 'edit_folder',
  delete_folder: 'delete_folder',
};

export const statusOptions = (t: (x: string) => string) => [
  {
    value: 'true',
    label: t('Active'),
    color: colorsPrimary.green.dark,
  },
  {
    value: 'false',
    label: t('Inactive'),
    color: colorsPrimary.orange.dark,
  },
];

const isAutomationData = (
  data: any
): data is WithCustomObjectAccess<Automation> => {
  return data?.folder !== undefined;
};

type AutomationFolderProps = {
  folderName: string;
  isParentFolderLink: boolean;
  onFolderClick: () => void;
};

const AutomationFolder = ({
  onFolderClick,
  folderName,
  isParentFolderLink,
}: AutomationFolderProps) => {
  const { t } = useTranslation();
  const location = useSelector(selectDirectoryLocation);

  const label = useMemo(
    () =>
      location
        .slice(1)
        .reduce<string>(
          (acc, folder) =>
            'name' in folder && folder.name ? `${acc} / ${folder.name}` : acc,
          t('Root')
        ),
    [location, t]
  );

  const [tooltipProps, tooltip] = useTooltip(
    {
      label,
    },
    () => isParentFolderLink
  );

  return (
    <SizedCell width={columnSize.name} padding={paddingSize.big}>
      <StyledAutomationFolder onClick={onFolderClick} {...tooltipProps}>
        {isParentFolderLink ? (
          <>
            {tooltip}
            <StyledIcon
              icon="level-up"
              rotation={270}
              color={colorsButton.blue}
            />
            <TextEllipsis type="link">{folderName}</TextEllipsis>
          </>
        ) : (
          <>
            <StyledIcon icon="folder" color={colorsButton.blue} />
            <TextEllipsisWithTooltip type="link">
              {folderName}
            </TextEllipsisWithTooltip>
          </>
        )}
      </StyledAutomationFolder>
    </SizedCell>
  );
};

export const NameCell = ({
  column,
  data,
  currentDirectory,
  onChangeDirectory,
  onUpdateAutomation,
  head: _h = false,
}: CellProps & {
  currentDirectory: Folder;
  onUpdateAutomation: OnUpdateAutomation;
  onChangeDirectory: (data: Folder) => void;
}) => {
  const { t } = useTranslation();

  if (!isAutomationData(data)) {
    const isParentFolderLink = currentDirectory?.parentFolderId === data.id;
    const onFolderClick = () => onChangeDirectory(data);

    return (
      <AutomationFolder
        folderName={
          isParentFolderLink && !data.parentFolderId ? t('Root') : data.name
        }
        isParentFolderLink={isParentFolderLink}
        onFolderClick={onFolderClick}
      />
    );
  }

  const value = data[column.id];

  const handleSubmit = async (v: string) => {
    if (v !== value) {
      await onUpdateAutomation({
        id: data.id,
        name: v,
        lastRevision: data.revision,
      });
    }
  };

  if (data.customObject.access.view) {
    return (
      <SizedCell width={columnSize.name} padding={paddingSize.big}>
        <ValidatedField
          type="link"
          to={`/automation/${data.id}/`}
          value={value}
          onSubmit={handleSubmit}
        />
      </SizedCell>
    );
  }

  return (
    <SizedCell width={columnSize.name} padding={paddingSize.big}>
      <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>
    </SizedCell>
  );
};

export const ObjectNameCell = ({
  column,
  data,
  head: _h = false,
}: CellProps) => {
  const value = isAutomationData(data) ? data[column.id] : null;

  return (
    <SizedCell width={columnSize.customObjectName} padding={paddingSize.big}>
      <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>
    </SizedCell>
  );
};

export const StatusCell = ({
  column,
  data,
  onUpdateAutomation,
  head: _h = false,
}: CellProps & {
  onUpdateAutomation: OnUpdateAutomation;
}) => {
  const { t } = useTranslation();

  if (!isAutomationData(data)) {
    return (
      <SizedCell width={columnSize.status} padding={paddingSize.big}>
        <TextEllipsisWithTooltip />
      </SizedCell>
    );
  }

  const value = data[column.id];
  const options = statusOptions(t);
  const option = options.find((opt) => opt.value === String(value));

  return (
    <SizedCell width={columnSize.status} padding={paddingSize.big}>
      <SelectInline
        readOnly={!data.customObject.access.view}
        onSubmit={async (opt: any) => {
          await onUpdateAutomation({
            id: data.id,
            active: opt.value === 'true',
            lastRevision: data.revision,
          });
        }}
      >
        <Select
          value={option}
          options={options}
          showClearButton={false}
          isColored
        />
      </SelectInline>
    </SizedCell>
  );
};

export const NumberActiveCell = ({
  column,
  data,
  head: _h = false,
}: CellProps) => {
  const value = isAutomationData(data) ? data[column.id] : null;

  return (
    <SizedCell width={columnSize.numberActive} padding={paddingSize.big}>
      <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>
    </SizedCell>
  );
};

export const NumberPausedCell = ({
  column,
  data,
  head: _h = false,
}: CellProps) => {
  const value = isAutomationData(data) ? data[column.id] : null;

  return (
    <SizedCell width={columnSize.numberPaused} padding={paddingSize.big}>
      <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>
    </SizedCell>
  );
};

export const NumberCompletedCell = ({
  column,
  data,
  head: _h = false,
}: CellProps) => {
  const value = isAutomationData(data) ? data[column.id] : null;

  return (
    <SizedCell width={columnSize.numberCompleted} padding={paddingSize.big}>
      <TextEllipsisWithTooltip>{value}</TextEllipsisWithTooltip>
    </SizedCell>
  );
};

export const TypeCell = ({ data, head: _h = false }: CellProps) => {
  const { t } = useTranslation();

  return (
    <SizedCell width={columnSize.type} padding={paddingSize.big}>
      <TextEllipsisWithTooltip>
        {isAutomationData(data) ? t('Automation') : t('Folder')}
      </TextEllipsisWithTooltip>
    </SizedCell>
  );
};

export const CreatedCell = ({ column, data, head: _h = false }: CellProps) => {
  if (!isAutomationData(data)) {
    return (
      <SizedCell width={columnSize.created} padding={paddingSize.big}>
        <TextEllipsisWithTooltip />
      </SizedCell>
    );
  }

  return (
    <SizedCell width={columnSize.created} padding={paddingSize.big}>
      <DateTimeInputInline readOnly value={data[column.id]} showDateTooltip />
    </SizedCell>
  );
};

export const ActionCell = ({
  data,
  currentDirectory,
  isMobile = false,
  ...rest
}: CellProps & {
  currentDirectory: Folder;
  isMobile?: boolean;
  onSelectAction(value: any, data: any): void;
}) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    if (!isAutomationData(data)) {
      const isParentFolderLink = currentDirectory.parentFolderId === data.id;

      if (isParentFolderLink) {
        return [];
      }

      return [
        { value: actions.edit_folder, label: t('Edit') },
        { value: actions.delete_folder, label: t('Delete') },
      ];
    }

    if (data.customObject.deleted || !data.customObject.access.view) {
      return [
        !isMobile && { value: actions.history, label: t('History') },
        !isMobile && { value: actions.delete, label: t('Delete') },
      ].filter(Boolean);
    }

    return [
      !isMobile && { value: actions.edit, label: t('Edit') },
      !isMobile && {
        value: actions.edit_automation_folder,
        label: t('Edit Folder'),
      },
      data.active
        ? { value: actions.deactivate, label: t('Deactivate') }
        : { value: actions.activate, label: t('Activate') },
      !isMobile && { value: actions.duplicate, label: t('Duplicate') },
      !isMobile && { value: actions.history, label: t('History') },
      !isMobile && { value: actions.delete, label: t('Delete') },
    ].filter(Boolean);
  }, [isMobile, currentDirectory, data, t]);

  if (!options.length) {
    return <NoActionsCell />;
  }

  const title = isAutomationData(data)
    ? t('Edit Automation')
    : t('Edit Folder');

  return (
    <TableActionCell
      title={title}
      menuType="absolute"
      options={options}
      data={data}
      width={columnSize.actions}
      padding={paddingSize.small}
      {...rest}
    />
  );
};

export const MobileActionCell = styled(ActionCell)`
  i {
    width: 15px;
    svg {
      width: 15px;
      height: auto;
    }
  }
`;
