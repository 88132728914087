import { forwardRef, useCallback, useContext } from 'react';
import Icon from 'components/Kizen/Icon';
import { DEFAULT_ITEM_IDS } from 'pages/Account/pages/Toolbar/constants';
import {
  Child,
  ChildrenContainer,
  IconContainer,
  StyledKDSIcon,
} from './styles';
import { NavLink } from 'react-router-dom';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { grayScale } from 'app/colors';
import { getLabel, getQAForItem, getRoute } from './util';
import { PluginContext } from 'ts-components/Plugins/PluginContext';
import { useGenericPluginCustomScript } from 'ts-components/ScriptRunner/hooks/useGenericPluginCustomScript';
import { useToast } from '__components/ToastProvider';
import { toastVariant } from '__components/ToastProvider';
import { useTranslation } from 'react-i18next';

const NavigationChildren = forwardRef(
  (
    {
      item,
      mobile,
      chosenBusinessName,
      readOnly,
      onClickLogout,
      translationKeys,
      offset,
      shouldShrinkToLeft,
    },
    forwardedRef
  ) => {
    const { toolbarItemsByFullId } = useContext(PluginContext);
    const { t } = useTranslation();

    const [showToast] = useToast();
    const [executeInline, { pending: inlinePending }] =
      useGenericPluginCustomScript({
        onError: (e) => {
          showToast({
            message: `${t('Script could not be executed')}: ${e?.message}`,
            variant: toastVariant.FAILURE,
          });
        },
      });

    const handleClickPluginScript = useCallback(
      (child) => (e) => {
        e.stopPropagation();

        if (inlinePending) {
          return false;
        }

        const dynamicItem = toolbarItemsByFullId[child.id];

        if (dynamicItem?.script) {
          executeInline(dynamicItem.script, dynamicItem.args, dynamicItem);
        }

        return false;
      },
      [executeInline, inlinePending, toolbarItemsByFullId]
    );

    return (
      <ChildrenContainer
        collapse={item.children.every((c) => c.widthPercent !== 50)}
        ref={forwardedRef}
        mobile={mobile}
        offset={offset}
        shouldShrinkToLeft={shouldShrinkToLeft}
      >
        {item.id === DEFAULT_ITEM_IDS.MY_ACCOUNT ? (
          <TextEllipsisWithTooltip
            color={grayScale.dark}
            type="small"
            as="span"
            weight="bold"
            style={{
              lineHeight: '100%',
              textTransform: 'capitalize',
              whiteSpace: 'nowrap',
            }}
          >
            {chosenBusinessName}
          </TextEllipsisWithTooltip>
        ) : null}
        {item.children.map((child) => {
          const dynamicItem = toolbarItemsByFullId[child.id];
          const childRoute = getRoute(child, dynamicItem);

          return (
            <Child
              key={child.id}
              full={child.widthPercent !== 50}
              as={
                readOnly ||
                child.id === DEFAULT_ITEM_IDS.LOGOUT ||
                (child.plugin_api_name && dynamicItem?.script)
                  ? 'div'
                  : childRoute.external
                    ? 'a'
                    : NavLink
              }
              to={childRoute.external ? null : childRoute.path}
              href={childRoute.external ? childRoute.path : null}
              exact={childRoute.exact}
              target={childRoute.target}
              rel={childRoute.rel}
              onClick={
                readOnly
                  ? undefined
                  : child.id === DEFAULT_ITEM_IDS.LOGOUT
                    ? onClickLogout
                    : child.plugin_api_name
                      ? handleClickPluginScript(child)
                      : childRoute.onClick
              }
              {...getQAForItem(child, childRoute)}
            >
              <IconContainer className={child.id}>
                {(() => {
                  const dynamicItem = toolbarItemsByFullId[child.id];

                  if (dynamicItem) {
                    return (
                      <StyledKDSIcon
                        icon={`custom-${dynamicItem.icon}`}
                        className="dynamic-child-item"
                      />
                    );
                  }

                  return typeof child.icon === 'string' ? (
                    <Icon icon={child.icon} />
                  ) : child.icon?.className ? (
                    <i className={`${child.icon.className} CustomIcon`} />
                  ) : (
                    <Icon
                      icon={child.icon?.value ?? 'bars-light'}
                      className={child.icon?.value ?? 'bars-light'}
                    />
                  );
                })()}
              </IconContainer>
              <TextEllipsisWithTooltip>
                {getLabel(child, translationKeys)}
              </TextEllipsisWithTooltip>
            </Child>
          );
        })}
      </ChildrenContainer>
    );
  }
);

export default NavigationChildren;
