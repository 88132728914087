import {
  useCallback,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { getAuthTeamMember } from 'store/authentication/selectors';
import { useTranslation } from 'react-i18next';
import { toastVariant, useToast } from '__components/ToastProvider';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { EXTERNAL_ACCOUNT_ALL, EXTERNAL_ACCOUNT } from 'queries/query-keys';

import TeamMemberService from 'services/TeamMemberService';

import { getOriginalError } from 'services/AxiosService';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';
import { useModalControl } from '__hooks/useModalControl';

import {
  IntegratedEmailType,
  OnSubmitProps,
  SharingSettingsPermissions,
  SharingSettingsPatch,
  Option,
} from '../types';

//////////////////////////// types
interface iUseSharingAccessModal {
  (useAll?: boolean): {
    sharingAccessModalProps: {
      show: boolean;
      onHide: () => void;
      onConfirm: (...args: any) => Promise<void>;
      showModal: () => void;
      errors: object;
      instanceName: string;
      isTemplate: boolean;
      showTemplateName: boolean;
      visibleControls: {
        allOptions: {
          none: boolean;
          view: boolean;
          edit: boolean;
          admin: boolean;
        };
        roles: {
          view: boolean;
          edit: boolean;
          admin: boolean;
        };
        teamMembers: {
          view: boolean;
          edit: boolean;
          admin: boolean;
        };
      };
    };
    onShowSharingAccessModal: () => void;
    editId: string | null;
    setEditId: Dispatch<SetStateAction<string | null>>;
    currentlyEdititingSharingSettings: IntegratedEmailType | null;
    setCurrentlyEdititingSharingSettings: Dispatch<
      SetStateAction<IntegratedEmailType | null>
    >;
    integratedAccountLoading: undefined | true | false;
    handleShowEdit: (id: string) => void;
  };
}

type MutationResponse = {
  response?: {
    data?: string[];
  };
};

// helpers

const OptionToId = ({ value }: Option): string => value;

const createPatch = (permissions: SharingSettingsPermissions) => {
  const patch: SharingSettingsPatch = {
    sharing_settings: {
      all_team_members: permissions.allTeamMembersPermission,
      roles: {
        view: permissions.viewRoles.map(OptionToId),
        edit: permissions.editRoles.map(OptionToId),
        admin: permissions.adminRoles.map(OptionToId),
      },
      team_members: {
        view: permissions.viewTeamMembers.map(OptionToId),
        edit: permissions.editTeamMembers.map(OptionToId),
        admin: permissions.adminTeamMembers.map(OptionToId),
      },
    },
  };
  return patch;
};

const fetchExternalAccountQuery = async (id: string) => {
  return TeamMemberService.getExternalAccount({ id });
};

const updateExternalAccountQuery = (id: string, values: any) => {
  return TeamMemberService.updateExternalAccount(id, values);
};

export const useSharingAccessModal: iUseSharingAccessModal = () => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const queryClient = useQueryClient();
  const _team_member = useSelector(getAuthTeamMember);

  const teamMember = useMemo(
    () => ({ ..._team_member, display_name: _team_member.displayName }),
    [_team_member]
  );

  const [errors, setErrors] = useState(EMPTY_OBJECT);
  const [isOpen, { showModal, hideModal }] = useModalControl();
  const [editId, setEditId] = useState<string | null>(null);
  const [
    currentlyEdititingSharingSettings,
    setCurrentlyEdititingSharingSettings,
  ] = useState<IntegratedEmailType | null>(null);

  const handleHide = useCallback(() => {
    hideModal();
    setErrors(EMPTY_OBJECT);
    setCurrentlyEdititingSharingSettings(null);
    setEditId(null);
  }, [hideModal, setCurrentlyEdititingSharingSettings, setEditId]);

  const updateIntegratedEmailMutation = useMutation(
    (result: { id: string; payload: any }) =>
      updateExternalAccountQuery(result.id, result.payload),
    {
      onSuccess: () => {
        // need to do both
        queryClient.invalidateQueries(EXTERNAL_ACCOUNT_ALL.ALL);
        queryClient.invalidateQueries(EXTERNAL_ACCOUNT.ALL);
      },
      onError: (payload: MutationResponse) => {
        const message =
          payload?.response?.data?.[0] ??
          t('External Account could not be updated.');

        showToast({
          message,
          variant: toastVariant.FAILURE,
        });
      },
    }
  );

  const onSubmitSharingAccessModal = useCallback(
    async ({ permissions }: OnSubmitProps) => {
      const patch = createPatch(permissions);
      await updateIntegratedEmailMutation.mutate({
        id: editId!,
        payload: patch,
      });
      setCurrentlyEdititingSharingSettings(null);
      setEditId(null);
    },
    [updateIntegratedEmailMutation, editId]
  );

  const onConfirm = useCallback(
    async ({ name, permissions, isPrivate }: OnSubmitProps) => {
      try {
        setErrors(EMPTY_OBJECT);
        await onSubmitSharingAccessModal({ name, permissions, isPrivate });
        hideModal();
      } catch (err) {
        let errors = getOriginalError(err) || EMPTY_OBJECT;
        if (Array.isArray(errors) && typeof errors[0] === 'string') {
          errors = { toast: errors[0] };
        }
        setErrors(errors);
      }
    },
    [onSubmitSharingAccessModal, hideModal]
  );

  const { isLoading: integratedAccountLoading, data: integratedEmailAccount } =
    useQuery(
      [...EXTERNAL_ACCOUNT_ALL.GET(editId)],
      () => fetchExternalAccountQuery(editId!),
      {
        keepPreviousData: true,
        enabled: !!editId,
      }
    );

  useEffect(() => {
    if (editId && integratedEmailAccount) {
      setCurrentlyEdititingSharingSettings(() => ({
        ...integratedEmailAccount,
        owner: teamMember,
      }));
    }
  }, [
    editId,
    integratedEmailAccount,
    setCurrentlyEdititingSharingSettings,
    teamMember,
  ]);

  const handleShowEdit = useCallback(
    (id: string) => {
      setEditId(id);
      showModal();
    },
    [showModal, setEditId]
  );

  return {
    sharingAccessModalProps: {
      show: isOpen,
      onHide: handleHide,
      onConfirm,
      showModal,
      errors,
      instanceName: t('Integrated Email'),
      isTemplate: false,
      showTemplateName: false,
      visibleControls: {
        allOptions: {
          none: true,
          view: true,
          edit: false,
          admin: true,
        },
        roles: {
          view: true,
          edit: false,
          admin: true,
        },
        teamMembers: {
          view: true,
          edit: false,
          admin: true,
        },
      },
    },
    onShowSharingAccessModal: showModal,
    editId,
    setEditId,
    currentlyEdititingSharingSettings,
    setCurrentlyEdititingSharingSettings,
    integratedAccountLoading,
    handleShowEdit,
  };
};
