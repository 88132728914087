import { useCallback, useEffect, useMemo, useState } from 'react';
import BasicModal from 'components/Modals/presets/BasicModal';
import { useTranslation } from 'react-i18next';
import { StyledWizardField } from 'components/Wizards/shared/styles';
import KizenTypography from 'app/kizentypo';
import { gutters } from 'app/spacing';
import Switch from 'components/Kizen/Switch';
import PermissionSettings from 'components/Sharing/PermissionSettings';
import TextInput from 'components/Inputs/TextInput';
import { useDynamicError } from 'hooks/useDynamicError';
import { toastVariant, useToast } from 'components/ToastProvider';

export const SharingAccessModal = ({
  isEditing,
  onConfirm,
  existing,
  errors,
  instanceName,
  jsonProp = 'configuration_json',
  disabled,
  isTemplate = true,
  showPrivateToggle = false,
  loading,
  showTemplateName = true,
  visibleControls,
  ...others
}) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const [permissions, setPermissions] = useState();
  const [templateName, setTemplateName] = useState(existing?.name ?? '');
  const [isPrivate, setIsPrivate] = useState(() => {
    if (existing?.sharing_settings?.private === undefined) {
      return true;
    }
    return existing.sharing_settings.private;
  });

  const handleConfirm = useCallback(() => {
    if (templateName || !showTemplateName) {
      onConfirm?.(
        { name: templateName, permissions, isPrivate },
        isEditing,
        existing?.id,
        existing?.[jsonProp]
      );
    }
  }, [
    templateName,
    onConfirm,
    isEditing,
    existing,
    permissions,
    isPrivate,
    jsonProp,
    showTemplateName,
  ]);

  const nameErrors = useMemo(() => errors?.name ?? [], [errors]);
  const errorProps = useDynamicError(nameErrors[0]);

  const hasConfigurationJSON = !!existing?.[jsonProp];

  const [heading, sharingInstance] = useMemo(() => {
    const sharingInstance = isTemplate
      ? t('{{instanceName}} Template', {
          instanceName: instanceName || t('Toolbar'),
        })
      : instanceName || t('Toolbar');

    const heading = isEditing
      ? t('Edit {{sharingInstance}}', {
          sharingInstance,
        })
      : hasConfigurationJSON
        ? t('Duplicate {{sharingInstance}}', {
            sharingInstance,
          })
        : t('Add {{sharingInstance}}', {
            sharingInstance,
          });

    return [heading, sharingInstance];
  }, [isEditing, isTemplate, instanceName, hasConfigurationJSON, t]);

  useEffect(() => {
    if (errors?.toast) {
      showToast({
        message: errors.toast,
        variant: toastVariant.FAILURE,
      });
    }
  }, [errors, showToast]);

  return (
    <BasicModal
      defaultLeftBtnText={t('Cancel')}
      heading={heading}
      size="medium"
      disabled={(showTemplateName && !templateName?.trim()) || disabled}
      onConfirm={handleConfirm}
      {...others}
    >
      <StyledWizardField top={0} flex>
        {showTemplateName ? (
          <>
            <KizenTypography>
              {isTemplate
                ? t('Template Name')
                : t('{{instanceName}} Name', { instanceName })}
            </KizenTypography>
            <StyledWizardField top={gutters.spacing(2, 2)} flex>
              <TextInput
                value={templateName}
                onChange={setTemplateName}
                placeholder={
                  isTemplate
                    ? t('Enter Template Name')
                    : t('Enter {{instanceName}} Name', { instanceName })
                }
                {...errorProps}
              />
            </StyledWizardField>
          </>
        ) : null}
        {showPrivateToggle && !loading && (
          <StyledWizardField top={20}>
            <Switch
              data-qa="make-private-toggle"
              checked={isPrivate}
              textPlacement="regular-top"
              label={t('Make Private')}
              tooltip={t(
                'Users will not be able to search for and request access to this {{instanceName}} unless granted View/Use access or greater.',
                { instanceName: sharingInstance }
              )}
              onClick={(ev) => {
                setIsPrivate(ev.target.checked);
              }}
            />
          </StyledWizardField>
        )}
      </StyledWizardField>
      <PermissionSettings
        labels={{
          all: {
            view: t('VIEW/USE'),
          },
          specific: {
            view: t('View/Use'),
          },
        }}
        onChange={setPermissions}
        existing={existing?.sharing_settings}
        owner={existing?.owner}
        loading={loading}
        isDuplication={!isEditing && hasConfigurationJSON}
        visibleControls={visibleControls}
        removeTopPadding={!showTemplateName && !showPrivateToggle}
      />
    </BasicModal>
  );
};
