import { useMemo } from 'react';
import type { Folder } from 'store/automationsPage/automations.redux';

type FolderOption = {
  label: string;
  value: Folder;
};

export const useFolderOptions = (
  folders: Folder[],
  chosenFolder: Folder | null
) => {
  const { options, lookup } = useMemo(() => {
    const options = folders.map((folder) => {
      return { value: folder, label: folder.name };
    });

    const lookup = options.reduce((acc, opt) => {
      acc.set(opt.value.id, opt);
      return acc;
    }, new Map());

    return { lookup, options };
  }, [folders]);

  const value: FolderOption | null = chosenFolder
    ? lookup.get(chosenFolder.id)
    : null;

  return { value, options };
};
