import styled from '@emotion/styled';
import { grayScale, colorsSecondary } from 'app/colors';
import { gutters, spaceBetweenGutters } from 'app/spacing';
import { KizenTypography, fontWeights } from 'app/typography';
import { colorsButton } from 'app/colors';
import Loader from 'components/Kizen/Loader';
import Input from 'components/Inputs/TextInput';
import Button from 'components/Button';
import GridSelector from 'components/Kizen/GridSelector';
import { StyledLabel } from 'components/Kizen/Input/styles';

export const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 18vh auto;
  width: 400px;
`;

export const StyledForm = styled.div`
  background-color: ${grayScale.white};
  border-radius: 8px;
  padding: ${gutters.standard};

  border: 1px solid ${grayScale.mediumLight};
  filter: drop-shadow(0px 18px 26px rgba(0, 0, 0, 0.03));
  .modal-radio .entity.child {
    max-height: 100px;
  }
`;

export const StyledFormHeader = styled(KizenTypography)`
  text-align: center;
`;

export const StyledFormRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  > {
    .Authform-input {
      width: ${spaceBetweenGutters({ columns: 2, gutter: '15px' })};
    }
  }
`;

export const StyledFormLoader = styled(Loader)`
  padding-bottom: 10%;
`;

export const StyledFormInput = styled(Input)`
  &.first-row {
    margin-top: 25px;
  }
  margin-top: 18px; // margin set to this weird value because font types caused mismatch with design, it should be 20px margin
  &.admin-input {
    margin-top: 0;
  }
  &.admin-input ~ .admin-input {
    margin-top: ${gutters.spacing(3)}px;
  }
`;

export const StyledNameInput = styled(StyledFormInput)`
  width: ${spaceBetweenGutters({ columns: 2, gutter: '15px' })};
`;

export const StyledFormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: ${gutters.standard};
`;

export const StyledFormButton = styled(Button)`
  margin-right: 0;
  margin-bottom: 0;
  min-width: 100px;
  margin-top: 0;
`;

export const StyledFormLinkButton = styled(Button)`
  margin: 0;
  margin-top: 12px;
  padding: 0;
  height: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.03em;
  font-weight: ${fontWeights.bold};
`;

export const StyledFormSuccess = styled(KizenTypography)`
  line-height: 125%;
  margin-top: ${gutters.standard};
`;

export const StyledFormError = styled(KizenTypography)`
  margin-top: ${gutters.standard};
  text-align: center;
  color: ${colorsSecondary.red.dark};
`;

export const StyledGridSelector = styled(GridSelector)`
  width: 80px;
  height: 80px;
  margin-bottom: 0;
  text-align: center;
  margin-right: 15px;
  padding: 10px;
  :last-of-type {
    margin-right: 0;
  }
  :hover span,
  :hover i {
    color: ${colorsButton.blue.default};
  }
  > span {
    font-size: 11px;
    margin-top: 10px;
    font-weight: 600;
    line-height: 13px;
  }
`;

export const StyledFormGridSelectorWrapper = styled.div`
  margin-top: 23px; // margin set to this weird value to match design
  display: flex;
  flex-direction: column;
`;

export const StyledFormGridSelectorOptionsWrapper = styled.div`
  display: flex;
`;

export const StyledFormOptionText = styled(KizenTypography)`
  &&& {
    margin-top: 20px;
    margin-bottom: -2px; // Align descender with bottom of box
  }
`;

const blue = colorsButton.blue.default;

export const StyledHorizontalProgressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 40px ${gutters.large} 40px;
`;

export const StyledStepNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  color: ${(props) => (props.active ? 'white' : grayScale.mediumDark)};
  background-color: ${(props) => (props.active ? blue : grayScale.medium)};
  font-size: 11px;
`;

export const StyledProgress = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StyledProgressLine = styled.div`
  width: 100%;
  border-top: 1px solid ${(props) => (props.active ? blue : grayScale.medium)};
`;

export const StyledInputLabel = styled(StyledLabel)`
  margin-top: 18px; // margin set to this weird value because font types caused mismatch with design, it should be 20px margin
  display: flex;
`;
