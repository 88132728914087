import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { paths } from 'schema';
import { curry1 } from './utils';

export const createAutomationFolder = (
  instance: AxiosInstance,
  body: paths['/api/automation2/folders']['post']['requestBody']['content']['application/json'],
  options?: AxiosRequestConfig & { skipErrorBoundary?: any }
): Promise<
  AxiosResponse<
    paths['/api/automation2/folders']['post']['responses']['201']['content']['application/json']
  >
> => {
  return instance.post('/automation2/folders', body, options);
};

export const deleteAutomationFolder = (
  instance: AxiosInstance,
  id: string,
  params?: paths['/api/automation2/folders/{id}']['delete']['parameters']['query']
): Promise<
  AxiosResponse<
    paths['/api/automation2/folders/{id}']['delete']['responses']['204']['content']['application/json']
  >
> => {
  return instance.delete(`/automation2/folders/${id}`, { params });
};

export const getAutomation = async (
  instance: AxiosInstance,
  id: string,
  options?: AxiosRequestConfig & { skipErrorBoundary?: any }
): Promise<
  AxiosResponse<
    paths['/api/automation2/automations/{automation_identifier}']['get']['responses']['200']['content']['application/json']
  >
> => {
  return instance.get(`/automation2/automations/${id}`, options);
};

export const getAutomationFolders = (
  instance: AxiosInstance
): Promise<
  AxiosResponse<
    paths['/api/automation2/folders']['get']['responses']['200']['content']['application/json']
  >
> => {
  return instance.get('/automation2/folders');
};

export const getAutomationSubfolders = (
  instance: AxiosInstance,
  id: string,
  params?: paths['/api/automation2/folders/{id}/subfolders']['get']['parameters']['query']
): Promise<
  AxiosResponse<
    paths['/api/automation2/folders/{id}/subfolders']['get']['responses']['200']['content']['application/json']
  >
> => {
  return instance.get(`/automation2/folders/${id}/subfolders`, { params });
};

export const patchAutomation = (
  instance: AxiosInstance,
  id: string,
  body: Required<
    paths['/api/automation2/automations/{automation_identifier}']['patch']
  >['requestBody']['content']['application/json'],
  options?: AxiosRequestConfig & {
    params: paths['/api/automation2/automations/{automation_identifier}']['patch']['parameters']['query'];
    skipErrorBoundary?: any;
  }
): Promise<
  AxiosResponse<
    paths['/api/automation2/automations/{automation_identifier}']['patch']['responses']['200']['content']['application/json']
  >
> => {
  return instance.patch(`/automation2/automations/${id}`, body, options);
};

export const updateAutomationFolder = (
  instance: AxiosInstance,
  id: string,
  body: Required<
    paths['/api/automation2/folders/{id}']['patch']
  >['requestBody']['content']['application/json'],
  options?: AxiosRequestConfig & { skipErrorBoundary?: any }
): Promise<
  AxiosResponse<
    paths['/api/automation2/folders/{id}']['patch']['responses']['200']['content']['application/json']
  >
> => {
  return instance.patch(`/automation2/folders/${id}`, body, options);
};

export class AutomationService {
  public createAutomationFolder;
  public deleteAutomationFolder;
  public getAutomation;
  public getAutomationSubfolders;
  public updateAutomationFolder;

  constructor(private instance: AxiosInstance) {
    this.createAutomationFolder = curry1(createAutomationFolder)(this.instance);
    this.deleteAutomationFolder = curry1(deleteAutomationFolder)(this.instance);
    this.getAutomation = curry1(getAutomation)(this.instance);
    this.getAutomationSubfolders = curry1(getAutomationSubfolders)(
      this.instance
    );
    this.updateAutomationFolder = curry1(updateAutomationFolder)(this.instance);
  }
}
