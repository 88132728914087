import KizenTypography from 'app/kizentypo';
import { PageSizing } from 'components/Layout/PageContentWidth';
import { useTranslation } from 'react-i18next';
import {
  Header,
  StyledSettingsContentCard,
  StyledButtonWrapper,
  StyledButton,
} from '../../styles';

import ConfirmNavigationWithActionModal from 'components/Modals/presets/ConfirmNavigationWithAction';
import NewURLDialog from '../../components/NewURLDialog';
import Loader from 'components/Kizen/Loader';
import useToolbarBuilder from '../../hooks/useToolbarBuilder';
import ToolbarBuilder from '../../components/Builder';
import { ChooseTemplateModal } from 'components/Modals/ApplyTemplateModal';
import { SharingAccessModal } from 'components/Modals/SharingAccessModal';
import NewRecentItemsDialog from '../../components/NewRecentItemsDialog';
import { useContext } from 'react';
import { PluginContext } from 'ts-components/Plugins/PluginContext';

const MyToolbarPage = () => {
  const { t } = useTranslation();
  const {
    newURLModalProps,
    recentItemsModalProps,
    confirmNavigationModalProps,
    navBarHeight,
    columnsLoading,
    pending,
    handleClickSaveTemplate,
    commit,
    dirty,
    builderProps,
    sharingModalProps,
    chooseTemplateModalProps,
    handleClickChooseTemplate,
    loadTemplatePending,
  } = useToolbarBuilder();

  return (
    <>
      {newURLModalProps.modalOpen && <NewURLDialog {...newURLModalProps} />}
      {recentItemsModalProps.modalOpen && (
        <NewRecentItemsDialog {...recentItemsModalProps} />
      )}
      <ConfirmNavigationWithActionModal {...confirmNavigationModalProps} />
      <PageSizing>
        <StyledSettingsContentCard navBarHeight={navBarHeight + 56}>
          {columnsLoading || loadTemplatePending ? (
            <Loader loading />
          ) : (
            <>
              <Header>
                <KizenTypography type="subheader">
                  {t('My Toolbar')}
                </KizenTypography>
                <StyledButtonWrapper>
                  <StyledButton
                    onClick={handleClickChooseTemplate}
                    disabled={pending}
                    color="blue"
                  >
                    {t('Choose Template')}
                  </StyledButton>
                  <StyledButton
                    onClick={handleClickSaveTemplate}
                    disabled={pending}
                  >
                    {t('Save New Template')}
                  </StyledButton>
                  <StyledButton onClick={commit} disabled={!dirty || pending}>
                    {t('Save')}
                  </StyledButton>
                </StyledButtonWrapper>
              </Header>
              <ToolbarBuilder {...builderProps} />
            </>
          )}
        </StyledSettingsContentCard>
      </PageSizing>
      <SharingAccessModal {...sharingModalProps} showPrivateToggle />
      {chooseTemplateModalProps.isOpen ? (
        <ChooseTemplateModal {...chooseTemplateModalProps} />
      ) : null}
    </>
  );
};

const ToolbarPageWaitForPlugins = (...props) => {
  const { isLoading } = useContext(PluginContext);

  if (isLoading) return <Loader loading />;

  return <MyToolbarPage {...props} />;
};

export default ToolbarPageWaitForPlugins;
