import { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { Route, Switch, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ActivityRulesContext } from 'ts-activities/ActivityRulesContext';
import { breakpoints, gutters, isMobile, useWindowSize } from 'app/spacing';
import KizenTypography from 'app/kizentypo';
import Loader from 'components/Kizen/Loader';
import {
  SubNavigationNavLink,
  SubNavigationNavBar,
} from 'components/Navigation/SubNavigation';
import navItems from './navigation';
import { ControlBarControl } from './ControlBar';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { ActivitySharingContext } from 'ts-activities/ActivitySharingContext';
import useCan from 'hooks/useCan';
import { PermissionsError } from 'services/errors';
import { snakeToCamelCaseKeys } from 'services/helpers';
import {
  useActivityQuery,
  useActivityFieldsQuery,
} from '__queries/models/activities-ts';

const MobileContainer = styled.div`
  padding: ${gutters.standard};
  text-align: center;
`;
const INITIAL_ERROR_MESSAGES = { name: '', redirectUrl: '', webhookUrl: '' };

const setActivityTitle = (title, { name = '' }) =>
  name ? `${name} - ${title}` : title;

export default function ActivitySettingsPage({ title }) {
  const { t } = useTranslation();

  const params = useParams();
  const [errorMessages, setErrorMessages] = useState(INITIAL_ERROR_MESSAGES);
  const [activity, setActivity] = useState({});

  const activitiesAccess = useCan({
    view: 'sections',
    for: 'activities_section',
  });

  useSetTitleOnLoad(title(t), activity, setActivityTitle);

  const { id: activityObjectId } = params;
  const { width } = useWindowSize();
  const mobileDisplay = isMobile(width, breakpoints.lg);

  const { data: activityData, isLoading: loadingActivity } = useActivityQuery(
    activityObjectId,
    {
      select: (act) => {
        return snakeToCamelCaseKeys(act);
      },
      onSuccess: (data) => {
        if (data) {
          activityNameRef.current = data.name;
        }
      },
    }
  );

  const { data: fields, isLoading: loadingFields } = useActivityFieldsQuery(
    activityObjectId,
    {
      select: (fields) => {
        const sorted = fields.sort((a, b) => {
          if (a.is_hidden && !b.is_hidden) {
            return 1;
          }
          if (b.is_hidden && !a.is_hidden) {
            return -1;
          }
          return a.order - b.order;
        });
        return snakeToCamelCaseKeys(sorted);
      },
    }
  );

  const activityNameRef = useRef(activityData?.name ?? '');

  useEffect(() => {
    if (activityData && fields) {
      setActivity({ ...activityData, fields });
    }
  }, [activityData, fields]);

  if (!activitiesAccess) {
    throw new PermissionsError();
  }

  if (mobileDisplay) {
    return (
      <MobileContainer>
        <KizenTypography>
          {t(
            'This page has not been optimized for mobile. Please revisit on a desktop to receive the best experience.'
          )}
        </KizenTypography>
      </MobileContainer>
    );
  }

  if (activity.id !== activityObjectId) {
    return <Loader loading />;
  }

  return (
    <ActivityRulesContext
      key={`${params.id}_${params.subpage}`}
      activityId={params.id}
      isActive={params.subpage === 'rules'}
      setActivity={setActivity}
    >
      <ActivitySharingContext activityId={params.id} setActivity={setActivity}>
        <SubNavigationNavBar>
          {Object.values(navItems).map((route, idx, arr) => {
            const common = {
              isLast: idx === arr.length - 1,
              numberOfLinks: arr.length,
              params,
            };
            return (
              <SubNavigationNavLink
                key={route.path}
                route={route}
                {...common}
              />
            );
          })}
        </SubNavigationNavBar>
        <ControlBarControl
          name={activityNameRef}
          setErrorMessages={setErrorMessages}
          activity={activity}
        />
        <Switch>
          {Object.values(navItems).map(
            ({ component: Component, path, exact }) =>
              Component ? (
                <Route
                  key={path}
                  path={path}
                  exact={exact !== false}
                  render={() => (
                    <Component
                      activityObjectId={activityObjectId}
                      activity={activity}
                      setActivity={setActivity}
                      errorMessages={errorMessages}
                      setErrorMessages={setErrorMessages}
                      activityNameRef={activityNameRef}
                      loading={loadingActivity || loadingFields}
                    />
                  )}
                />
              ) : null
          )}
        </Switch>
      </ActivitySharingContext>
    </ActivityRulesContext>
  );
}
