import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TriggerCard from '__pages/AutomationEngine/Engine/Steps/TriggerCard';
import { GenericOption } from '__pages/SmartConnectors/types';
import { FlowTrigger } from '../../types';
import { useHistory } from 'react-router-dom';
import { FlowBuilderContext } from '../../context';
import {
  PROPS_THAT_NEED_DEACTIVATION_ON_CHANGE,
  SMARTCONNECTORS_PATHNAME,
} from '__pages/SmartConnectors/constants';
import { colorsButton } from '__app/colors';

type TriggerStepSpreadsheetUploaded = {
  step: FlowTrigger;
  color: string;
};

export default function SpreadsheetUploadTriggerCard({
  step,
  color,
  ...others
}: TriggerStepSpreadsheetUploaded) {
  const { t } = useTranslation();
  const history = useHistory();

  const { smartConnector, flowErrors } = useContext(FlowBuilderContext);

  const hasErrors = PROPS_THAT_NEED_DEACTIVATION_ON_CHANGE.some(
    (prop) => prop in flowErrors
  );

  const menuOptions = useMemo(() => {
    return [
      {
        label: t('Edit'),
        value: 'edit',
      },
    ];
  }, [t]);

  const onSelectMenu = ({ value }: GenericOption) => {
    if (value === 'edit') {
      history.push(`${SMARTCONNECTORS_PATHNAME}/${smartConnector.id}`);
    }
  };

  return (
    <TriggerCard
      {...others}
      color={hasErrors ? colorsButton.red.hover : color}
      borderColor={hasErrors ? colorsButton.red.hover : null}
      menuOptions={menuOptions}
      onSelectMenu={onSelectMenu}
    >
      {t('Spreadsheet Upload')}
    </TriggerCard>
  );
}
