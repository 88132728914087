import { PageSizing } from '__components/Layout/PageContentWidth';
import { StyledTwoColumnsRow } from './styles';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { SmartConnectorContext } from '../../context';
import { SmartConnectorType } from 'pages/SmartConnectors/types';
import { SmartConnectorTypeButtons } from './SmartConnectorTypeButtons';
import { CustomObjectSelect } from './CustomObjectSelect';
import { NameInput } from './NameInput';
import { EmailInput } from './EmailInput';
import { StyledCard, StyledLoader, StyledStepHeader } from '../styles';
import { SMART_CONNECTOR_STEPS } from '__pages/SmartConnectors/constants';
import { DiffCheckingSettings } from './DiffCheckingSettings';

export const GeneralSettingsStep = () => {
  const { t } = useTranslation();
  const { smartConnector, stepData, setStepData, setIsValid, loading } =
    useContext(SmartConnectorContext);

  useEffect(() => {
    setIsValid(true);
  }, [setIsValid]);

  const handleChangeName = (value: string) => {
    setStepData((prev) => ({ ...prev, name: value }));
  };

  const handleChangeEmail = (value: string) => {
    setStepData((prev) => ({ ...prev, error_notification_email: value }));
  };

  const handleChangeConnectorType = ({
    value,
  }: {
    value: SmartConnectorType;
  }) => {
    // we do not support connector types other than spreadsheet
    //setStepData((prev) => ({ ...prev, connector_type: value }));
  };

  const handleChangeObject = (value: string) => {
    setStepData((prev) => ({ ...prev, custom_object: value }));
  };

  return (
    <PageSizing>
      <StyledCard data-qa={`${SMART_CONNECTOR_STEPS.general}-step`}>
        <StyledStepHeader type="subheader">
          {t('General Settings')}
        </StyledStepHeader>
        <StyledLoader loading={loading}>
          <StyledTwoColumnsRow>
            <NameInput value={stepData.name} onChange={handleChangeName} />
            <div />
          </StyledTwoColumnsRow>
          <SmartConnectorTypeButtons
            value={stepData.connector_type!}
            onChange={handleChangeConnectorType}
          />
          <StyledTwoColumnsRow>
            <CustomObjectSelect
              value={stepData.custom_object!}
              onChange={handleChangeObject}
              disabled={!!smartConnector.id}
            />
            <EmailInput
              value={stepData.error_notification_email}
              onChange={handleChangeEmail}
            />
          </StyledTwoColumnsRow>
          <DiffCheckingSettings />
        </StyledLoader>
      </StyledCard>
    </PageSizing>
  );
};
