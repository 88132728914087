import { useState, useRef, useEffect, useMemo } from 'react';
import { useDebouncedPastScrollThreshold } from 'hooks/useDebouncedWindowScroll';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  breakpoints,
  FILTER_MENU_Z_INDEX,
  layers,
  useWindowSize,
} from 'app/spacing';
import Icon from 'components/Kizen/Icon';
import BackButton from 'components/Button/BackButton';
import SelectOverlay from 'components/Inputs/SelectOverlay';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';
import useModal from 'components/Modals/useModal';
import CustomActivityActions from 'pages/Common/Actions/CustomActivityActions';
import CustomAutomationActions from 'pages/Common/Actions/CustomAutomationActions';
import { StyledSelectMenu } from 'pages/Common/Actions/styles';
import {
  ToolbarWrapper,
  Toolbar,
  ToolbarLeft,
  ToolbarRight,
  SaveButton,
  DesktopContent,
  DesktopContactContent,
  DesktopOnlyButton,
  MobileContent,
  Header,
  NameWrapper,
  Name,
  ActionsWrapper,
  ThreeDots,
  FlyingDot,
} from 'pages/Common/styles/ControlBar';
import styled from '@emotion/styled';
import ArchiveModal from 'components/Modals/ArchiveModal';
import KizenTypography from 'app/kizentypo';
import ActionBar from './ActionBar';
import { Typography } from '@kizen/kds/Typography';
import { ViewSelect } from './styles';

const DEFAULT_NAME = (t) => t('Custom Object');
const NAME_BREAKPOINT = breakpoints.md;

const StyledToolbarWrapper = styled(ToolbarWrapper)`
  z-index: ${FILTER_MENU_Z_INDEX +
  1}; // high z-index to be above the filter dropdowns on the constrained timeline
`;

export const ControlBar = ({
  routes,
  params,
  customObjectModel,
  customObject,
  onSubmit,
  onDelete,
  onStartAutomation,
  logActivityActions,
  fieldState,
  activityList,
  defaultOnActivities,
  layoutOptions,
  selectedLayout,
  setSelectedLayout,
  hideActions = false,
  canStartAutomations,
  ...props
}) => {
  const { t } = useTranslation();
  const [showLogActivity, setShowLogActivity] = useState(false);
  const [showSelectedAutomation, setShowSelectedAutomation] = useState();
  const [submitting, setSubmitting] = useState(false);

  const COAccess = customObject.access;

  const actionOptions = useMemo(
    () =>
      [
        {
          value: 'activity',
          label: t('Log Activity'),
          onClick: () => setShowLogActivity(true),
        },
        COAccess?.edit &&
          canStartAutomations && {
            value: 'automations',
            label: t('Start Automation'),
            onClick: () => setShowSelectedAutomation(true),
          },
      ].filter(Boolean),
    [t, COAccess?.edit, canStartAutomations]
  );

  const { objectId } = useParams();
  const scrolled = useDebouncedPastScrollThreshold();
  const { name: recordName, displayName: recordDisplayName } =
    customObject || {};

  const [tooltipProps, tooltip] = useTruncationTooltip();
  const { width: screenWidth } = useWindowSize();

  const [showActionMenu, setShowActionMenu] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const actionButtonRef = useRef(null);

  const [predefinedOptions, setPredefinedOptions] = useState({});
  const [, , scheduleActivityModal] = useModal();
  const [confirmDeletionModalProps, confirmDeletionModalTriggerProps] =
    useModal({
      handleSubmit: onDelete,
    });

  const handleSave = async () => {
    setSubmitting(true);
    try {
      await onSubmit();
    } finally {
      setSubmitting(false);
    }
  };

  const titleRef = useRef(null);

  useEffect(
    () => {
      if (!scrolled) {
        // makes sure the menus close if scrolled
        setShowActionMenu(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scrolled]
  );

  return (
    <>
      <StyledToolbarWrapper
        className="toolbar-wrapper"
        scrolled={scrolled}
        {...props}
      >
        <Toolbar className="toolbar" scrolled={scrolled}>
          <ToolbarLeft nameBreakpoint={NAME_BREAKPOINT}>
            <BackButton to={`/custom-objects/${objectId}`}>
              <DesktopContent as="span">{t('back')}</DesktopContent>
            </BackButton>
            {layoutOptions?.length > 1 ? (
              <div className="kds flex items-center gap-x-spacer-10 ml-[-20px]">
                <Typography>{t('View')}</Typography>
                <ViewSelect
                  options={layoutOptions}
                  onChange={setSelectedLayout}
                  value={selectedLayout}
                />
              </div>
            ) : null}
          </ToolbarLeft>
          <DesktopContactContent nameBreakpoint={NAME_BREAKPOINT}>
            <NameWrapper>
              <Name
                {...tooltipProps}
                type="header"
                data-qa="entity-display-name"
              >
                {recordDisplayName || recordName || DEFAULT_NAME(t)}
              </Name>
            </NameWrapper>
          </DesktopContactContent>
          <MobileContent>
            <Header ref={titleRef} {...tooltipProps} type="subheader">
              {recordDisplayName || recordName || DEFAULT_NAME(t)}
            </Header>
          </MobileContent>
          {!hideActions ? (
            <ToolbarRight nameBreakpoint={NAME_BREAKPOINT}>
              {COAccess.remove && (
                <DesktopOnlyButton
                  {...confirmDeletionModalTriggerProps}
                  color="red"
                >
                  {t('Archive')}
                </DesktopOnlyButton>
              )}
              {COAccess.edit && (
                <DesktopOnlyButton
                  disabled={submitting}
                  onClick={handleSave}
                  color="green"
                >
                  {t('Save')}
                </DesktopOnlyButton>
              )}
              <MobileContent>
                {actionOptions.length > 0 ? (
                  <>
                    <ThreeDots
                      className={`three-dots ${scrolled ? 'is-scrolled' : ''}`}
                      screenWidth={screenWidth}
                      ref={actionButtonRef}
                      onClick={() => setShowActionMenu(true)}
                    >
                      <Icon icon="three-dot" />
                      {actionOptions.length > 1 ? (
                        <FlyingDot screenWidth={screenWidth} className="b" />
                      ) : null}
                      <FlyingDot screenWidth={screenWidth} className="c" />
                    </ThreeDots>
                    <SelectOverlay
                      show={showActionMenu}
                      target={titleRef.current}
                      onHide={() => {
                        setShowActionMenu(false);
                      }}
                      zIndexNudge={layers.content(0, 3)}
                    >
                      <StyledSelectMenu
                        value={selectedAction || null}
                        onChange={(value) => {
                          setSelectedAction(null);
                          setShowActionMenu(false);
                          value.onClick();
                        }}
                        options={actionOptions}
                        autoFocus={false}
                        placeholder={t('Select Option')}
                        menuOnly
                      />
                    </SelectOverlay>
                  </>
                ) : null}

                {defaultOnActivities ? (
                  <CustomActivityActions
                    showLogActivity={showLogActivity}
                    target={titleRef.current}
                    customObject={customObject}
                    customObjectModel={customObjectModel || {}}
                    onSetVisible={(val) => setShowLogActivity(val)}
                    scrolled={scrolled}
                    onShowScheduleActivity={() => scheduleActivityModal.show()}
                    setPredefinedOptions={(val) => setPredefinedOptions(val)}
                    shownScrolled
                    isCustom
                    logActivityActions={logActivityActions}
                    fieldState={fieldState}
                    activityList={activityList}
                  />
                ) : null}
                <CustomAutomationActions
                  showSelectedAutomation={showSelectedAutomation}
                  target={titleRef.current}
                  customObject={customObject}
                  onSetVisible={(val) => setShowSelectedAutomation(val)}
                  scrolled={scrolled}
                  predefinedOptions={predefinedOptions}
                  shownScrolled
                  scheduleActivityModal={scheduleActivityModal}
                  onStartAutomation={onStartAutomation}
                  customObjectId={objectId}
                />
              </MobileContent>

              <MobileContent>
                <SaveButton
                  variant="text"
                  disabled={submitting}
                  onClick={handleSave}
                >
                  {t('save')}
                </SaveButton>
              </MobileContent>
            </ToolbarRight>
          ) : (
            <ToolbarRight nameBreakpoint={NAME_BREAKPOINT} />
          )}
        </Toolbar>
      </StyledToolbarWrapper>
      <MobileContent>
        <ActionsWrapper className="ActionsWrapper">
          <ActionBar
            condensed={false}
            customObject={customObject}
            customObjectModel={customObjectModel}
            scrolled={scrolled}
            onStartAutomation={onStartAutomation}
            logActivityActions={logActivityActions}
            fieldState={fieldState}
            activityList={activityList}
            defaultOnActivities={defaultOnActivities}
            customObjectId={objectId}
            canStartAutomations={canStartAutomations}
          />
        </ActionsWrapper>
      </MobileContent>
      <ArchiveModal {...confirmDeletionModalProps}>
        <KizenTypography>
          {t('This will archive the')}{' '}
          {recordDisplayName || recordName || DEFAULT_NAME(t)}{' '}
          {t(
            'record and all associated information, so that it can no longer be accessed.'
          )}
        </KizenTypography>
      </ArchiveModal>
      {tooltip}
    </>
  );
};

export default ControlBar;
