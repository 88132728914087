import {
  SmartConnectorContextType,
  SmartConnectorErrors,
  defaultContext,
  defaultSmartConnector,
} from '../context';
import { useAsync, useAsyncFn } from 'react-use';
import { useHistory, useParams } from 'react-router-dom';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  SmartConnector,
  SmartConnectorBase,
  SmartConnectorDTO,
  SmartConnectorMetaData,
  SmartConnectorStep,
} from 'pages/SmartConnectors/types';
import {
  PROPS_THAT_NEED_DEACTIVATION_ON_CHANGE,
  SMARTCONNECTORS_PATHNAME,
  SMART_CONNECTOR_STATUSES,
  SMART_CONNECTOR_STEPS,
  STEP_DATA_BY_STEP,
} from 'pages/SmartConnectors/constants';
import SmartConnectorService from 'services/SmartConnectorService';
import { AxiosError } from 'axios';
import { getSmartConnectorDTO, getStepData } from '../helpers';
import { monitoringExceptionHelper } from 'sentry/helpers';
import { isEqual } from 'lodash';
import { useSmartConnectorTitle } from './useSmartConnectorTitle';
import { useDeactivationModal } from './useDeactivationModal';
import { getIsSmartConnectorActive } from '__pages/SmartConnectors/helpers';
import { flushSync } from 'react-dom';

export const useSmartConnectorContext = (): SmartConnectorContextType => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  // state //////////////////////////////////////////////////////////

  const [smartConnector, setSmartConnector] = useState<
    SmartConnectorBase | SmartConnector
  >(defaultSmartConnector);

  const [step, setStep] = useState<SmartConnectorStep>(
    SMART_CONNECTOR_STEPS.general
  );

  const [nextStep, setNextStep] = useState<SmartConnectorStep>(
    SMART_CONNECTOR_STEPS.general
  );

  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [needsDeactivation, setNeedsDeactivation] = useState<boolean>(false);

  const [isValid, setIsValid] = useState<boolean>(true);

  const [refreshing, setRefreshing] = useState<boolean>(false);

  const [stepData, setStepData] = useState<Partial<SmartConnector>>({});

  const [errors, setErrors] = useState<SmartConnectorErrors>({});

  const { deactivationModalProps, showDeactivationModalWithResult } =
    useDeactivationModal();

  // refs //////////////////////////////////////////////////////////

  const isNew = useRef<boolean>(id === 'new');

  const smartConnectorRef = useRef(smartConnector);
  smartConnectorRef.current = smartConnector;

  const stepRef = useRef(step);
  stepRef.current = step;

  const stepDataRef = useRef(stepData);
  stepDataRef.current = stepData;

  // effects //////////////////////////////////////////////////////////

  useSmartConnectorTitle(smartConnector);

  const [{ loading }, fetchSmartConnector] = useAsyncFn(
    async () => {
      if (id === 'new') {
        return defaultSmartConnector;
      }
      const { data } = await SmartConnectorService.getSmartConnector(id);
      setSmartConnector(data);
      setStepData(getStepData(data, stepRef.current));
      return data;
    },
    [id],
    { loading: true }
  );

  const {
    value: metaData = defaultContext.metaData,
    loading: loadingMetaData,
  } = useAsync<() => Promise<SmartConnectorMetaData>>(async () => {
    const { data } = await SmartConnectorService.getSmartConnectorMetaData();
    return data;
  });

  useEffect(() => {
    fetchSmartConnector();
  }, [fetchSmartConnector]);

  useLayoutEffect(() => {
    setNextStep(step);

    //initiates stepData and resets errors for each step
    setStepData(getStepData(smartConnectorRef.current, step));
    setErrors({});
  }, [step]);

  useEffect(() => {
    const fields = STEP_DATA_BY_STEP[step];
    const isDirty = fields.some((field: keyof SmartConnectorBase) => {
      return !isEqual(smartConnector[field], stepData[field]);
    });

    const shouldDeactivate = PROPS_THAT_NEED_DEACTIVATION_ON_CHANGE.some(
      (field: keyof SmartConnectorBase) => {
        return (
          field in stepData && !isEqual(smartConnector[field], stepData[field])
        );
      }
    );
    setIsDirty(isDirty);
    setNeedsDeactivation(shouldDeactivate);
  }, [smartConnector, step, stepData]);

  // handlers //////////////////////////////////////////////////////////

  const handleGoToStep = useCallback(
    (step: SmartConnectorStep) => {
      if (isDirty) {
        setNextStep(step);
      } else {
        setStep(step);
      }
    },
    [isDirty]
  );

  const patchSmartConnector = useCallback(
    async (
      payload: SmartConnectorDTO,
      withClose: boolean,
      skipNavigation: boolean = false
    ): Promise<boolean> => {
      try {
        setRefreshing(true);
        setErrors({});
        if (id === 'new') {
          const { data } = await SmartConnectorService.createSmartConnector(
            stepDataRef.current,
            { skipErrorBoundary: true }
          );
          setSmartConnector(data);
          setIsDirty(false);

          setStep(SMART_CONNECTOR_STEPS.connection);
          setTimeout(() =>
            history.replace(`${SMARTCONNECTORS_PATHNAME}/${data.id}`)
          );
        } else {
          const { last_draft_script, sql_enabled } = stepDataRef.current;
          if (
            step === SMART_CONNECTOR_STEPS.connection &&
            sql_enabled &&
            last_draft_script?.user_script !==
              smartConnector.last_draft_script?.user_script
          ) {
            try {
              await SmartConnectorService.updateSmartConnectorScript(
                smartConnector.id,
                last_draft_script?.id,
                last_draft_script?.user_script || '',
                {
                  skipErrorBoundary: true,
                }
              );
            } catch (err) {
              // we do not want to block the save process if script update fails
              monitoringExceptionHelper(err, {
                extra: {
                  smartConnectorId: smartConnector.id,
                  lastDraftScriptId: last_draft_script?.id,
                  userScript: last_draft_script?.user_script,
                },
              });
            }
          }
          const { data } = await SmartConnectorService.updateSmartConnector(
            id,
            payload,
            { skipErrorBoundary: true }
          );
          setSmartConnector(data);
          setStepData(getStepData(data, step));
          setIsDirty(false);
          if (
            isNew.current &&
            step === SMART_CONNECTOR_STEPS.connection &&
            !skipNavigation
          ) {
            setStep(SMART_CONNECTOR_STEPS.variables);
          }

          if (withClose) {
            setTimeout(() =>
              history.push(`${SMARTCONNECTORS_PATHNAME}/${id}/builder`)
            );
          }
        }
        setErrors({});
        return true;
      } catch (error) {
        if (error instanceof AxiosError) {
          const axiosError = (error as AxiosError<SmartConnectorErrors>)
            .response?.data;

          const errors: SmartConnectorErrors = {};
          if (axiosError?.name) {
            errors.name = axiosError.name;
          }
          if (axiosError?.custom_object) {
            errors.custom_object = axiosError.custom_object;
          }
          if (axiosError?.error_notification_email) {
            errors.error_notification_email =
              axiosError.error_notification_email;
          }
          if (axiosError?.execution_variables) {
            errors.execution_variables = axiosError.execution_variables;
          }
          setErrors(errors);
        }
        return false;
      } finally {
        setRefreshing(false);
      }
    },
    [id, step, smartConnector, history]
  );

  const handleSave = useCallback(
    (
      withClose: boolean,
      skipNavigation: boolean = false
    ): Promise<boolean | null> => {
      if (
        isDirty &&
        needsDeactivation &&
        getIsSmartConnectorActive({ status: smartConnector.status })
      ) {
        return showDeactivationModalWithResult({
          handleSave: () => {
            return patchSmartConnector(
              {
                ...getSmartConnectorDTO(stepDataRef.current),
                status: SMART_CONNECTOR_STATUSES.inactive,
              },
              withClose,
              skipNavigation
            );
          },
          handleDiscard: () => {
            setSmartConnector(smartConnectorRef.current);
            setStepData(
              getStepData(smartConnectorRef.current, stepRef.current)
            );
          },
        });
      }
      return patchSmartConnector(
        getSmartConnectorDTO(stepDataRef.current),
        withClose,
        skipNavigation
      );
    },
    [
      patchSmartConnector,
      smartConnector.status,
      showDeactivationModalWithResult,
      isDirty,
      needsDeactivation,
    ]
  );

  // we have logic to update some smartConnector properties without clicking Save buttons
  // like clicking Test Execution button actually updates last_draft_script on BE,
  // so this is used to update needed parts of smartConnector and stepData in sync
  const handleUpdate = useCallback(
    async (promise: Promise<Partial<SmartConnector>>) => {
      setRefreshing(true);
      try {
        const data = await promise;
        flushSync(() =>
          setSmartConnector((prev) => {
            const nextData = { ...prev, ...data };

            setStepData((prev) => ({
              ...prev,
              ...getStepData(nextData, stepRef.current),
            }));

            return nextData;
          })
        );

        return true;
      } catch (err) {
        monitoringExceptionHelper(err);
        return false;
      } finally {
        setRefreshing(false);
      }
    },
    []
  );

  return {
    smartConnector,
    metaData,
    loading: loading || loadingMetaData,
    refreshing,
    handleGoToStep,
    step,
    setStep,
    nextStep,
    setNextStep,
    isNew: isNew.current,
    isDirty,
    setIsDirty,
    handleSave,
    stepData,
    setStepData,
    errors,
    setErrors,
    handleUpdate,
    refetchSmartConnector: fetchSmartConnector,
    isValid,
    setIsValid,
    deactivationModalProps,
    showDeactivationModalWithResult,
  };
};
