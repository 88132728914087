import { useMemo, useRef, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PAGE_CONFIGS, CUSTOM_OBJECTS } from 'queries/query-keys';
import { useQuery, useQueries } from 'react-query';
import { useDefaultActivityRelationshipsQuery } from 'queries/models/activites';
import TeamMemberService from 'services/TeamMemberService';
import CustomObjectsService from 'services/CustomObjectsService';
import { mapColumnSettings } from '__components/Charts/ScheduledActivities/helpers.js';
import { mapRelatedColumns } from '__components/Charts/ScheduledActivities/helpers';
import Loader from '__components/Kizen/Loader';
import { ResizableSection } from '__components/Wizards/CustomObject/steps/CustomLayout/dialogs/common';
import { ScheduledActivitiesColumnBuilder } from '__components/Wizards/Dashlet/sections/ValuesAndConstraints/reportTypes/ScheduledActivitiesColumnBuilder';
import { defaultColumnSettings } from '__components/Charts/ScheduledActivities/constants';

type CustomObjects = { id: string; fields: Array<{ id: string }> };

type Field = { id: string };

type ColumnSettings = {
  id: string;
  label: string;
  visible: boolean;
  disabled: boolean;
  sortable: boolean;
  fieldId: string;
  objectId: string;
  displayName: string;
};

type Metadata = {
  columns: ColumnSettings[];
  sort?: {
    columm: string;
    direction: string;
  };
};

type SetMetadataArgument = (metadata: Metadata, setDirty?: boolean) => void;

type TeamsActivitiesWizardProps = {
  setMetadata: SetMetadataArgument;
  metadata: Metadata;
  loading: boolean;
  isEdit: boolean;
};

type StateValues = {
  columns?: ColumnSettings[];
  meta?: Metadata;
};

export const useIntialiseTeamActivitiesMeta = (stateValues: StateValues) => {
  const { columns, meta } = stateValues;
  const existingData = meta ? meta.columns : columns;
  const fetchConfig = async () => {
    const res = await TeamMemberService.getActivitiesListPageConfig();

    return res;
  };

  const { data: rawColumnSettings, isLoading: isRawColumnSettingsLoading } =
    useQuery(PAGE_CONFIGS.SCHEDULED_ACTIVITIES, fetchConfig);

  return {
    isFetchingActivitiesDataLoading: isRawColumnSettingsLoading,
    teamActivitiesMetadata: {
      meta: isEmpty(existingData) ? rawColumnSettings : existingData,
    },
  };
};

export const TeamsActivitiesWizard = (props: TeamsActivitiesWizardProps) => {
  const { setMetadata, metadata, loading, isEdit } = props;
  const initialRenderRef = useRef(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (!initialRenderRef.current) {
      initialRenderRef.current = true;
    } else {
      initialRenderRef.current = false;
    }
  }, []);

  const defaultActivityRelationshipsQuery =
    useDefaultActivityRelationshipsQuery();
  const { relatedObjects = null } = useMemo(() => {
    if (defaultActivityRelationshipsQuery.isLoading) {
      return {};
    }
    const doa = defaultActivityRelationshipsQuery.data;
    return {
      relatedObjects: mapRelatedColumns(doa),
    };
  }, [
    defaultActivityRelationshipsQuery.data,
    defaultActivityRelationshipsQuery.isLoading,
  ]);

  const columnSettings = useMemo(() => {
    if (metadata) {
      const savedColumns = metadata.columns ? metadata.columns : metadata;
      return mapColumnSettings(savedColumns, relatedObjects, t);
    }
    return mapColumnSettings([], relatedObjects, t);
  }, [metadata, relatedObjects, t]);

  const columnAssociatedObjectFieldIds = useMemo(() => {
    return columnSettings.columns
      .filter(({ fieldId }: ColumnSettings) => fieldId)
      .map(({ fieldId, objectId }: ColumnSettings) => ({
        fieldId,
        objectId,
      }));
  }, [columnSettings]);

  const objectQueries = useQueries(
    columnAssociatedObjectFieldIds.map(({ objectId }) => {
      return {
        queryKey: CUSTOM_OBJECTS.DETAILS(objectId),
        queryFn: () => CustomObjectsService.getCustomObjectDetails(objectId),
        enabled: Boolean(objectId),
      };
    })
  );

  const objectsById = useMemo(() => {
    const result: { [key: string]: { fieldsById: any } } = {};

    for (const query of objectQueries) {
      if (query.data) {
        const data: CustomObjects = query.data;
        result[data.id] = {
          ...data,
          fieldsById: data.fields.reduce(
            (acc: { [key: string]: Field }, field: Field) => {
              acc[field.id] = field;
              return acc;
            },
            {}
          ),
        };
      }
    }
    return result;
  }, [objectQueries]);

  const filteredColumnSettings = useMemo(() => {
    if (isEmpty(objectsById)) {
      return columnSettings;
    }
    const columnFilter = ({ fieldId, objectId, visible }: ColumnSettings) =>
      !fieldId || objectsById[objectId]?.fieldsById[fieldId] || !visible;
    const activeColumns = columnSettings.columns.filter(columnFilter);
    return {
      ...columnSettings,
      columns: activeColumns,
    };
  }, [columnSettings, objectsById]);

  return (
    <ResizableSection header={t('Choose Activity Columns')} {...props}>
      {loading ||
      defaultActivityRelationshipsQuery.isLoading ||
      !filteredColumnSettings.columns ? (
        <Loader style={{ width: '100%', height: '200px' }} loading />
      ) : (
        <ScheduledActivitiesColumnBuilder
          scrollActvitiyBuilderIntoView={initialRenderRef.current}
          defaultColumns={defaultColumnSettings}
          columns={filteredColumnSettings.columns}
          scheduledActivitiesConfig={{}}
          displayHeader={false}
          onChange={setMetadata}
          displayDefaultColumns={!isEdit}
        />
      )}
    </ResizableSection>
  );
};
