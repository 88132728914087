import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import SelectOverlay from 'components/Inputs/SelectOverlay';
import MultiSelect from 'components/Inputs/MultiSelect';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import SelectAllButton from 'components/Inputs/MultiSelect/SelectAllButton';
import IconAdornment from 'components/Inputs/Adornments/IconAdornment';
import TimelineService from 'services/TimelineService';
import useField from 'hooks/useField';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { defaultTimelineMetadata } from 'ts-components/RecordLayout/Wizard/TimelineWizard';
import { useQuery } from 'react-query';
import { TIMELINE } from 'queries/query-keys';

export const TypeMultiSelect = ({
  onChange,
  eventType = '',
  typesCount = 0,
  trigger,
  isShowing,
  setIsShowing,
  metadata,
  ...others
}) => {
  const { t } = useTranslation();

  const { includeAll, included } = useMemo(
    () => metadata ?? defaultTimelineMetadata,
    [metadata]
  );

  const eventTypeRef = useRef(eventType);
  if (eventTypeRef.current !== eventType) {
    eventTypeRef.current = eventType;
  }

  const { data: timelineTypes, isLoading: isLoadingTimelineTypes } = useQuery(
    TIMELINE.TYPES,
    TimelineService.getTimelineTypes,
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  const { options, loadingOptions } = useMemo(() => {
    if (timelineTypes) {
      if (includeAll !== false) {
        return { options: timelineTypes, loadingOptions: false };
      }
      // start with the included values
      const includedValues = included.map((el) => el.value);

      // remove any values that are not in the included list
      const eventTypeValues = eventTypeRef.current.split(',');
      const eventTypeValuesFiltered = eventTypeValues.filter((el) =>
        includedValues.includes(el)
      );
      const newEventType = eventTypeValuesFiltered.join(',');

      if (newEventType !== eventTypeRef.current) {
        onChange({
          eventType: newEventType,
          typesCount: eventTypeValuesFiltered.length,
        });
      }

      // filter the types
      return {
        options: timelineTypes.filter((el) =>
          includedValues.includes(el.value)
        ),
        loadingOptions: false,
      };
    }
    return { options: EMPTY_ARRAY, loadingOptions: true };
  }, [timelineTypes, includeAll, included, onChange]);

  const [value, setValue] = useField(
    () =>
      eventType
        .split(',')
        .map((value) => options.find((option) => option.value === value))
        .filter(Boolean),
    [eventType, options]
  );

  const handleApply = useCallback(() => {
    onChange({
      eventType: value.map((el) => el.value).join(','),
      typesCount: value.length,
    });
    setIsShowing(false);
  }, [value, onChange, setIsShowing]);

  const handleClear = useCallback(() => {
    setValue(EMPTY_ARRAY);
  }, [setValue]);

  const groupedOptions = useMemo(() => {
    // Bucket the options by their group_key so that react-select
    // will display them in their respective groups
    const groups = options.reduce((acc, curr) => {
      const res = { ...acc };
      if (!res[curr.group_key]) {
        res[curr.group_key] = {
          label: curr.group_name,
          options: [],
        };
      }
      res[curr.group_key].options.push({
        value: curr.value,
        label: curr.label,
      });

      return res;
    }, {});

    // Ensure that the groups are in alphabetical order
    return Object.keys(groups)
      .sort()
      .map((key) => groups[key]);
  }, [options]);

  return (
    <>
      <SelectOverlay
        show={isShowing}
        target={trigger.current}
        onHide={handleApply}
        placement="bottom-end"
        popperConfig={{}}
      >
        <MultiSelect
          value={value}
          onChange={setValue}
          placeholder={t('Choose Timeline Filters')}
          menuTopButton={<SelectAllButton />}
          menuLeftButton={<ClearSelectButton onClick={handleClear} />}
          menuRightButton={<ApplySelectButton onClick={handleApply} />}
          endAdornment={<IconAdornment icon="search" />}
          loadItems={loadingOptions || isLoadingTimelineTypes}
          options={groupedOptions}
          {...others}
        />
      </SelectOverlay>
    </>
  );
};
