import { useCallback, useEffect, useMemo, useRef } from 'react';

import { PageSizing } from 'components/Layout/PageContentWidth';
import { useTranslation } from 'react-i18next';
import Switch from 'components/Kizen/Switch';
import Select from '__components/Inputs/Select';
import MultiSelect from '__components/Inputs/MultiSelect';
import { getDataQAForInput } from 'components/Inputs/helpers';
import Row from 'components/Wizards/CustomObject/components/Row';
import KizenTypography from 'app/kizentypo';
import {
  CO_OBJECT_TYPE_STANDARD,
  createWizardState,
  getToastConfig,
} from 'components/Wizards/CustomObject/utilities';
import CustomObjectsService from 'services/CustomObjectsService';
import NewItemButton from 'components/Modals/Common/NewItemButton';
import _ from 'lodash';
import { LineItem } from './LineItem';
import { orderBy } from 'lodash';
import { getObjectModelName } from 'services/FieldService';
import whichServiceToUse from 'services/utils';
import { HeadCells } from './HeadCells';
import useField from 'hooks/useField';
import {
  DEFAULT_RELATION_PROPS,
  getDefaultFields,
  relatedObjectTypeOptions,
} from './utility';
import { useToast, toastVariant } from 'components/ToastProvider';
import { useSelector } from 'react-redux';
import FieldService from 'services/FieldService';
import { useCustomObjectWizard } from 'components/Wizards/CustomObject/CustomObjectWizardContext';
import { FORCE_ALL_RECORDS_SIZE } from 'services/helpers';
import {
  PrimaryWrapper,
  ReverseWrapper,
  StyledBuilderCard,
  StyledLoader,
  StyledNotice,
  StyledSection,
  StyledSwitchControl,
  SwitchSection,
  AssociationHeader,
} from './styled';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import useFormValidation from 'hooks/useFormValidation';
import { saveStep } from 'components/Wizards/CustomObject/utilities';
import ChunkRender from 'components/ChunkRender';
import { RELATED_OBJECTS_STEP_KEY } from '../../constants';
import { getOriginalError } from 'services/AxiosService';
import { getBusinessClientObject } from 'store/authentication/selectors';
import { useAssociatedSource } from './useAssociatedSource';
import ConfirmationModal from 'components/Modals/presets/ConfirmationModal';
import { ASSOCIATION_SOURCE_TYPES } from 'components/Wizards/CustomObject/constants';
import { CUSTOM_OBJECTS } from 'queries/query-keys';
import { useQuery } from 'react-query';
import { getErrorMessage } from 'hooks/useErrors';

const relationKey = ({ relatedObject, relationType, field }, index) => {
  // There's no great key for these so we base it on their contents and index.
  // A. the contents aren't unique (e.g. for non-fully-filled-out relation), and
  // B. the index is a poor key, as it associates error message state with the
  //    index rather than the relation.
  return JSON.stringify([
    relatedObject,
    relationType,
    field?.id,
    field?.displayName,
    field?.relation?.relatedName,
    index,
  ]);
};

const getContactPrePopulatedValues = (relatedObjectId, entityName, t) => ({
  entityName: t('Contact'),
  fieldId: undefined,
  isNew: true,
  fetchUrl: 'client',
  relatedObject: relatedObjectId,
  relationType: relatedObjectTypeOptions(t)[0].value,
  isPopulatedContact: true,
  field: {
    displayName: relatedObjectTypeOptions(t)[0].name('Contact'),
    relation: {
      relatedName: relatedObjectTypeOptions(t)[0].reverseName(entityName),
      rollupTimeline: true,
      inverseRelationRollupTimeline: true,
    },
  },
});

const isContact = ({ fetchUrl }) => fetchUrl === 'client';

const updateRelatedObjectLabels = (current, updated) => ({
  ...current,
  field: {
    ...current.field,
    displayName: updated.field.relation.relatedName,
    relation: {
      ...current.field.relation,
      relatedName: updated.field.displayName,
    },
  },
});

const getAllModelOptions = (models) =>
  orderBy(
    models,
    [
      // Contacts, Companies, standard models, then pipeline models
      (m) => m.name === getObjectModelName('contacts'),
      (m) => m.name === getObjectModelName('companies'),
      (m) => m.objectType === CO_OBJECT_TYPE_STANDARD,
    ],
    ['desc', 'desc', 'desc']
  ).map((model) => ({
    value: model.id,
    label: model.objectName,
    entityName: model.entityName,
    isContact: isContact(model),
  }));

export default function RelatedObjects({
  label,
  updateStepField,
  objectContainsWorkflow,
  formData,
  isCreateMode,
  errors,
  resetField,
  setDirty,
  model,
  activeStep,
  saving,
  ...props
}) {
  const { t } = useTranslation();
  const {
    preSaveFn: setPreSaveCallBack,
    isContactNeedsToBeAdded,
    setIsContactNeedsToBeAdded,
    refetchCustomObject,
  } = useCustomObjectWizard();

  const [showToast] = useToast();
  const serviceInUse = useMemo(() => whichServiceToUse(formData), [formData]);
  const sectionRef = useRef();
  const access = useSelector((s) => s.authentication.access);
  const clientObject = useSelector(getBusinessClientObject);
  const clientObjectId = clientObject?.id;

  const {
    data: fullListOfCustomObjects = EMPTY_ARRAY,
    isLoading: fullListOfCustomObjectsLoading,
  } = useQuery({
    queryKey: [...CUSTOM_OBJECTS.LIST, FORCE_ALL_RECORDS_SIZE, 'all'],
    queryFn: async () => {
      const { results } = await CustomObjectsService.getCustomObjectList({
        size: FORCE_ALL_RECORDS_SIZE,
        customOnly: false,
      });
      return results;
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const {
    data: objectFieldsRawData = EMPTY_ARRAY,
    isLoading: objectFieldsRawLoading,
  } = useQuery({
    queryKey: CUSTOM_OBJECTS.FIELDS_UNRESTRICTED(formData?.id),
    queryFn: () =>
      serviceInUse.getObjectFieldsWithoutRestrictions(formData?.id),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: Boolean(formData?.id),
  });

  const {
    data: categorizedFields = EMPTY_ARRAY,
    isLoading: categorizedFieldsLoading,
  } = useQuery({
    queryKey: [
      ...CUSTOM_OBJECTS.FIELDS_CATEGORIZED('contacts'),
      'includeVisible',
      'settingsRequest',
    ],
    queryFn: () =>
      FieldService.getCategorizedFields({
        for: 'contacts',
        includeVisible: true,
        settingsRequest: true,
      }),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: Boolean(formData?.id),
  });

  const loading =
    fullListOfCustomObjectsLoading ||
    objectFieldsRawLoading ||
    categorizedFieldsLoading;

  const { models, objectFieldsRaw, contactCategories } = useMemo(() => {
    if (loading) {
      return {
        models: EMPTY_ARRAY,
        objectFieldsRaw: EMPTY_ARRAY,
        contactCategories: EMPTY_ARRAY,
      };
    }

    const data = fullListOfCustomObjects;
    const objectFieldsRaw = objectFieldsRawData;
    const contactCategories = categorizedFields;

    const models = data.filter((object) => {
      const objectAccess = access.custom_objects?.custom_object_entities ?? {};
      const contactAccess = access.sections?.contacts_section?.view ?? false;

      if (object.id === clientObjectId) {
        return contactAccess;
      }

      return objectAccess[object.id]?.enabled;
    });

    return { models, objectFieldsRaw, contactCategories };
  }, [
    access,
    categorizedFields,
    clientObjectId,
    fullListOfCustomObjects,
    loading,
    objectFieldsRawData,
  ]);

  const [objectFieldsToBeChanged, setObjectFields] = useField(objectFieldsRaw);
  const objectFields = objectFieldsToBeChanged.length
    ? objectFieldsToBeChanged
    : objectFieldsRaw;

  const formDataProperties = useMemo(() => {
    if (loading) {
      return {};
    }

    const relatedObjects = formData.relatedObjects.map(
      ({ fieldId, ...rest }) => {
        const field = objectFields?.find(({ id }) => fieldId === id);
        const model = models.find(({ id }) => rest.relatedObject === id) || {
          associationSource: ASSOCIATION_SOURCE_TYPES.direct,
        };

        const { associationSource } = model;

        return {
          fieldId: fieldId,
          ...rest,
          field: field ?? rest.field,
          associationSource,
        };
      },
      []
    );

    const contact = models.find(isContact);
    const defaultRelatedObjects = getContactPrePopulatedValues(
      contact?.id,
      formData.entityName,
      t
    );
    // adding contact as pre-populated primary relationships object
    const relatedContactObject = relatedObjects.find(isContact);
    if (!relatedContactObject && isContactNeedsToBeAdded) {
      relatedObjects.push({
        ...defaultRelatedObjects,
        categories: contactCategories,
      });
      updateStepField('relatedObjects', [...relatedObjects]);
    }
    return {
      ...formData,
      relatedObjects,
    };
  }, [
    loading,
    formData,
    models,
    t,
    isContactNeedsToBeAdded,
    objectFields,
    contactCategories,
    updateStepField,
  ]);

  const [values, setValues] = useField(
    () => createWizardState(formDataProperties, models),
    [formDataProperties, models]
  );

  const {
    validateFormState,
    validationProps,
    handleInputChange,
    setValidationState,
    firstFieldWithErrorRef,
  } = useFormValidation({
    setFormDirty: setDirty,
    formState: { ...values },
    setFormState: setValues,
  });

  const {
    handleChangeAssociatedSource,
    associatedSourceOptions,
    associatedSourceObjectsOptions,
    handleChangeAssociatedSourceObjects,
    associatedSourceObjectsFieldOptions,
    handleChangeAssociatedSourceFields,
    associatedWarnings,
    confirmChangeModalProps,
    associationSourceFieldsValue,
    associationSourceFieldsError,
    associatedKey,
    validateAssociationFields,
    handleAssociationFieldsErrors,
  } = useAssociatedSource({
    values,
    relatedObjects: formDataProperties.relatedObjects,
    updateStepField,
    handleInputChange,
    modelId: formData.id,
    setValidationState,
    firstFieldWithErrorRef,
  });

  const [allModelOptions, allModelsLookup] = useMemo(() => {
    const options = getAllModelOptions(models);
    return [
      options,
      options.reduce((collect, model) => {
        collect[model.value] = model;
        return collect;
      }, {}),
    ];
  }, [models]);

  const setValueHandler = useCallback(
    (data) => {
      setValues(data);
      const newObjects = data.relatedObjects.filter(({ isNew }) => isNew);
      const newObjectsReverse = data.relatedObjectsReverse.filter(
        ({ isNew }) => isNew
      );

      if (newObjects.length || newObjectsReverse.length) {
        setDirty(true);
      }
    },
    [setDirty, setValues]
  );

  const preSaveCallBack = useCallback(async () => {
    if (!validateFormState(t)) {
      return null;
    }
    if (!validateAssociationFields()) {
      return null;
    }

    try {
      const {
        entityName,
        relatedObjects,
        relatedObjectsReverse,
        associationSource,
        associationSourceFields,
      } = values;
      const allObjects = [...relatedObjects, ...relatedObjectsReverse];
      updateStepField('relatedObjects', allObjects);
      const newObjectsWithoutRelatedObject = allObjects.filter(
        ({ isNew, relatedObject }) => isNew && !relatedObject
      );

      if (newObjectsWithoutRelatedObject.length) {
        throw new Error(
          t(
            'The Custom Object was not updated. At least one of the Related Objects is missing.'
          )
        );
      }

      const categories = await serviceInUse.getObjectCategories(
        formData.id,
        isContact(formData)
      );

      const [relatedPayloads, reversePayloads] = [
        relatedObjects,
        relatedObjectsReverse,
      ].map((objects) =>
        objects.map((object) => {
          if (object.isNew && object.relatedObject) {
            const dictionary = relatedObjectTypeOptions(t).find(
              ({ value: valueId }) => valueId === object.relationType
            );

            const relatedModel = allModelsLookup[object.relatedObject];

            const payload = [
              formData.id,
              {
                model: formData.id,
                category: categories[0].id,

                displayName:
                  object.field?.displayName ||
                  dictionary.name(relatedModel.entityName),
                relation: {
                  inverseRelationRollupTimeline:
                    object.field.relation.inverseRelationRollupTimeline,
                  relatedCategory: object.categories[0].id,
                  relatedName:
                    object.field.relation?.relatedName ||
                    dictionary.reverseName(entityName),
                  relatedObject: object.relatedObject,
                  relationType: object.relationType,
                  rollupTimeline: object.field.relation.rollupTimeline,
                  rollupLeadsources: Boolean(
                    formData?.rollupRelatedLeadsources
                  ),
                },
                ...DEFAULT_RELATION_PROPS,
              },
              { skipErrorBoundary: true },
            ];
            return { id: 'create', payload };
          }
          if (
            Boolean(object.rollupLeadsources) !==
              Boolean(formData?.rollupRelatedLeadsources) ||
            !objectFields.some((field) =>
              _.isEqual(
                { displayName: field.displayName, relation: field.relation },
                {
                  displayName: object?.field?.displayName,
                  relation: object?.field?.relation,
                }
              )
            )
          ) {
            const payload = [
              formData.id,
              object.fieldId,
              {
                display_name: object.field.displayName,
                relation: {
                  ...object.field.relation,
                  // if the relation is suppressed, we need to remove the inverse_relation_suppressed
                  ...(object.field.isSuppressed
                    ? { inverse_relation_suppressed: undefined }
                    : {}),
                  // if the relation is suppressed, we need to ignore the rollup_leadsources
                  ...(object.field.relation.inverseRelationSuppressed
                    ? {}
                    : {
                        rollup_leadsources: Boolean(
                          formData?.rollupRelatedLeadsources
                        ),
                      }),
                },
              },
              { skipErrorBoundary: true },
            ];

            return { id: 'update', payload };
          }
          return {};
        })
      );

      const [relatedResponses, reverseResponses] = await Promise.all(
        [relatedPayloads, reversePayloads]
          .map((payloads) => {
            // We should have not more than 16 concurent requests at a time, so we chunk them into groups of 16.
            const chunks = payloads.reduce((collect, payload, index) => {
              const chunkIndex = Math.floor(index / 16);
              collect[chunkIndex] = collect[chunkIndex] || [];
              collect[chunkIndex].push(payload);
              return collect;
            }, []);
            const loadChunks = async (chunks) => {
              const responses = [];
              for (const chunk of chunks) {
                const chunkResponses = await Promise.all(
                  chunk.map(({ id, payload }) => {
                    switch (id) {
                      case 'create': {
                        return serviceInUse.createObjectField(...payload);
                      }
                      case 'update': {
                        return serviceInUse.patchObjectField(...payload);
                      }
                      default:
                        return null;
                    }
                  })
                );
                responses.push(...chunkResponses);
              }
              return responses;
            };
            return loadChunks(chunks);
          })
          .concat(
            saveStep(
              {
                rollupRelatedLeadsources: formData?.rollupRelatedLeadsources,
                associationSource,
                associationSourceFields: (associationSourceFields || []).map(
                  (id) => ({ id })
                ),
              },
              model,
              activeStep
            )
          )
      );

      if (relatedResponses.some(Boolean) || reverseResponses.some(Boolean)) {
        setValues({
          ...values,
          relatedObjects: relatedObjects.map((object, index) => ({
            ...object,
            isNew: false,
            fieldId: relatedResponses[index]?.id || object?.field?.id,
            field: relatedResponses[index] || object?.field,
            rollupLeadsources: Boolean(formData?.rollupRelatedLeadsources),
          })),
          relatedObjectsReverse: relatedObjectsReverse.map((object, index) => ({
            ...object,
            isNew: false,
            fieldId: reverseResponses[index]?.id || object?.field?.id,
            field: reverseResponses[index] || object?.field,
            rollupLeadsources: Boolean(formData?.rollupRelatedLeadsources),
          })),
        });

        const updatedLookup = [...relatedResponses, ...reverseResponses].reduce(
          (collect, response) => {
            if (response) {
              collect[response.id] = response;
            }
            return collect;
          },
          {}
        );

        const existingLookup = objectFields.reduce((collect, field) => {
          collect[field.id] = field;
          return collect;
        }, {});

        setObjectFields((prev) => {
          const newFields = [...relatedResponses, ...reverseResponses].reduce(
            (collect, response) => {
              if (response && !existingLookup[response.id]) {
                collect.push(response);
              }
              return collect;
            },
            []
          );
          const updatedFields = prev.map((prevField) => {
            return updatedLookup[prevField.id] || prevField;
          });

          return updatedFields.concat(newFields);
        });
      }

      setIsContactNeedsToBeAdded(false);
      setDirty(false);
      // the toast DOM-updates can be blocked by other wizard state DOM-related updates
      setTimeout(() =>
        showToast(getToastConfig(t, !model, isContact(formData)).success)
      );
    } catch (error) {
      const fallbackError = t('An error occurred');
      const backendError = getOriginalError(error);

      if (Array.isArray(backendError?.association_source_fields)) {
        return handleAssociationFieldsErrors(
          backendError.association_source_fields
        );
      }

      showToast({
        variant: toastVariant.FAILURE,
        message: t('{{backendError}} - Please re-save your changes.', {
          backendError: getErrorMessage(backendError) || fallbackError,
        }),
      });

      return null;
    }
    return !(
      isContact(formData) && activeStep.key === RELATED_OBJECTS_STEP_KEY
    );
  }, [
    updateStepField,
    validateFormState,
    t,
    validateAssociationFields,
    formData,
    activeStep,
    values,
    serviceInUse,
    model,
    setIsContactNeedsToBeAdded,
    setDirty,
    objectFields,
    allModelsLookup,
    setValues,
    setObjectFields,
    showToast,
    handleAssociationFieldsErrors,
  ]);

  useEffect(() => {
    setPreSaveCallBack(preSaveCallBack);
  }, [preSaveCallBack, setPreSaveCallBack]);

  const onConfirmDelete = useCallback(
    async (field) => {
      const { id, relation } = field;
      const relatedObject = formData.relatedObjects.find(
        (item) => item.fieldId === id
      );
      const relatedFieldId = relation.relatedField;

      // remove related fields
      const filterFunc = (item) => {
        return (
          item.fieldId !== relatedObject.fieldId &&
          item.fieldId !== relatedFieldId
        );
      };

      try {
        await serviceInUse.deleteObjectField(formData.id, id);

        setValues({
          ...values,
          relatedObjects: values.relatedObjects.filter(filterFunc),
          relatedObjectsReverse:
            values.relatedObjectsReverse.filter(filterFunc),
        });

        // update model
        await refetchCustomObject?.(model, activeStep);

        //we should update main state here
        updateStepField(
          'relatedObjects',
          formData.relatedObjects.filter(filterFunc),
          true
        );
      } catch (error) {
        showToast({
          variant: toastVariant.FAILURE,
          message: error?.message
            ? error.message
            : t('The relation could not be deleted.'),
        });
      }
    },
    [
      activeStep,
      model,
      formData,
      serviceInUse,
      setValues,
      values,
      updateStepField,
      showToast,
      refetchCustomObject,
      t,
    ]
  );

  const { relatedObjects, relatedObjectsReverse } = useMemo(() => {
    return {
      relatedObjects: values.relatedObjects,
      relatedObjectsReverse: values.relatedObjectsReverse,
    };
  }, [values.relatedObjects, values.relatedObjectsReverse]);

  const handleOnChange = useCallback(
    async (
      updated,
      index,
      key = 'relatedObjectsReverse',
      shouldUpdateCategories = false
    ) => {
      const object = models.find(({ id }) => id === updated?.relatedObject);
      let categories = [];

      if (updated?.isNew && shouldUpdateCategories) {
        categories = updated?.relatedObject
          ? updated.isContact
            ? await FieldService.getCategorizedFields({
                for: 'contacts',
                includeVisible: true,
                settingsRequest: true,
              })
            : await whichServiceToUse(object).getObjectCategories(
                updated.relatedObject
              )
          : [];
      }

      const nextValues = {
        ...values,
        [key]:
          key === 'relatedObjectsReverse'
            ? relatedObjectsReverse.map((r, i) => {
                if (i === index) {
                  return {
                    ...updated,
                    categories: shouldUpdateCategories
                      ? categories
                      : r?.categories || [],
                  };
                }
                return r;
              })
            : relatedObjects.map((r, i) => {
                if (i === index) {
                  return {
                    ...updated,
                    categories: shouldUpdateCategories
                      ? categories
                      : r?.categories || [],
                  };
                }
                return r;
              }),
      };

      if (updated.relatedObject === formData.id) {
        nextValues.relatedObjects = nextValues.relatedObjects.map(
          (currentRelation, i) => {
            if (
              i !== index &&
              currentRelation.relatedObject === formData.id &&
              updated.fieldId === currentRelation.field.relation.relatedField
            ) {
              return updateRelatedObjectLabels(currentRelation, updated);
            }
            return currentRelation;
          }
        );

        nextValues.relatedObjectsReverse = nextValues.relatedObjectsReverse.map(
          (currentRelation, i) => {
            if (
              i !== index &&
              currentRelation.relatedObject === formData.id &&
              updated.fieldId === currentRelation.field.relation.relatedField
            ) {
              return updateRelatedObjectLabels(currentRelation, updated);
            }
            return currentRelation;
          }
        );
      }

      handleInputChange(key, nextValues[key]);

      setValueHandler(nextValues);
    },
    [
      handleInputChange,
      models,
      relatedObjects,
      relatedObjectsReverse,
      setValueHandler,
      values,
      formData,
    ]
  );

  const handleClickRemove = useCallback(
    (type = 'relatedObjects', objects, values, index, relation) => {
      if (relation?.isPopulatedContact) {
        updateStepField('relatedObjects', [
          ...relatedObjects.filter(
            ({ isPopulatedContact }) => !isPopulatedContact
          ),
        ]);
        setIsContactNeedsToBeAdded(false);
      }
      setValueHandler({
        ...values,
        [type]: objects.filter((_, i) => i !== index),
      });
    },
    [
      relatedObjects,
      setValueHandler,
      updateStepField,
      setIsContactNeedsToBeAdded,
    ]
  );

  // combine validation props for associationSourceFields
  const associationSourceFieldsValidation = validationProps(
    'associationSourceFields'
  );
  const fieldsError = useMemo(() => {
    return associationSourceFieldsValidation.validate
      ? associationSourceFieldsValidation
      : {
          ...associationSourceFieldsValidation,
          validate: associationSourceFieldsError,
        };
  }, [associationSourceFieldsError, associationSourceFieldsValidation]);

  const validationOnLoad = useRef(false);
  if (
    !validationOnLoad.current &&
    !loading &&
    activeStep.key === RELATED_OBJECTS_STEP_KEY &&
    values?.associationSource === ASSOCIATION_SOURCE_TYPES.related &&
    !associationSourceFieldsValue?.length === 0
  ) {
    setTimeout(() => {
      firstFieldWithErrorRef.current = 'associationSourceObjects';
      setValidationState({
        associationSourceObjects: t('This field is required'),
        associationSourceFields: t('This field is required'),
      });
    }, 10);
    validationOnLoad.current = true;
  }
  return (
    <PageSizing>
      <StyledBuilderCard {...props} label={label}>
        <AssociationHeader>
          <SwitchSection>
            <StyledSwitchControl
              label={t('Include Related Records for Lead Sources')}
              labelInfo={t(
                'Toggle this setting on to calculate lead source metrics on all direct and related entity sources.'
              )}
            >
              <Switch
                checked={formData?.rollupRelatedLeadsources || false}
                onChange={(e) =>
                  updateStepField('rollupRelatedLeadsources', e.target.checked)
                }
                {...getDataQAForInput('records_for_lead_sources')}
              />
            </StyledSwitchControl>
          </SwitchSection>
          <div>
            <Select
              key={associatedKey}
              label={t('Where does this object get its team associations?')}
              value={associatedSourceOptions.find(
                ({ value }) => value === values?.associationSource
              )}
              onChange={handleChangeAssociatedSource}
              options={associatedSourceOptions}
              placeholder={t('Choose Associations')}
              dataQa="related-objects-associations"
              {...validationProps('associationSource')}
            />
          </div>
          <div>
            {values?.associationSource !== ASSOCIATION_SOURCE_TYPES.direct ? (
              <>
                <MultiSelect
                  label={t('Related Objects for Associations')}
                  value={values?.associationSourceObjects || []}
                  onChange={handleChangeAssociatedSourceObjects}
                  options={associatedSourceObjectsOptions}
                  placeholder={t('Choose Object(s)')}
                  dataQa="related-objects-associations-objects"
                  menuLeftButton={null}
                  menuRightButton={null}
                  margin
                  required={
                    values?.associationSource ===
                    ASSOCIATION_SOURCE_TYPES.related
                  }
                  {...validationProps('associationSourceObjects')}
                />
                <MultiSelect
                  label={t('Relationship Fields for Associations')}
                  value={associationSourceFieldsValue || []}
                  onChange={handleChangeAssociatedSourceFields}
                  options={associatedSourceObjectsFieldOptions}
                  placeholder={t('Choose Field(s)')}
                  dataQa="related-objects-associations-fields"
                  menuLeftButton={null}
                  menuRightButton={null}
                  required={
                    values?.associationSource ===
                      ASSOCIATION_SOURCE_TYPES.related ||
                    values?.associationSourceObjects?.length > 0
                  }
                  {...fieldsError}
                />
              </>
            ) : null}
          </div>
        </AssociationHeader>
        <StyledSection label={t('This Object has Primary Relationships')}>
          <StyledNotice noRows={!relatedObjects.length}>
            <KizenTypography data-qa={`no-items-notification`}>
              {t(
                'These are single select relationships between your data. For example, we recommend using a primary relationship for deals with a single point of contact.'
              )}
            </KizenTypography>
          </StyledNotice>
          {loading ? (
            <StyledLoader loading />
          ) : (
            <Row>
              {relatedObjects.length ? (
                <PrimaryWrapper>
                  <HeadCells />
                  <ChunkRender chunkSize={10}>
                    {relatedObjects.map((relation, index) => {
                      return (
                        <LineItem
                          index={index}
                          data-qa={`related-objects-line-item-${index}`}
                          key={relationKey(relation, index)}
                          value={relation}
                          allModelOptions={allModelOptions}
                          errors={errors}
                          objectFields={objectFields}
                          // Editable if this relation doesn't exist
                          editable={!formData || relation.isNew}
                          sectionRef={sectionRef}
                          relation={'relatedObjects'}
                          entityName={values.entityName}
                          allRelationTypeOptions={relatedObjectTypeOptions(
                            t
                          ).slice(0, 2)}
                          onChange={(data, shouldUpdateCategories) =>
                            handleOnChange(
                              data,
                              index,
                              'relatedObjects',
                              shouldUpdateCategories
                            )
                          }
                          onClickRemove={() =>
                            handleClickRemove(
                              'relatedObjects',
                              relatedObjects,
                              values,
                              index,
                              relation
                            )
                          }
                          onConfirmDelete={onConfirmDelete}
                          validationProps={validationProps}
                        />
                      );
                    })}
                  </ChunkRender>
                </PrimaryWrapper>
              ) : null}
              <NewItemButton
                onClick={() =>
                  setValueHandler({
                    ...values,
                    relatedObjects: [
                      ...values.relatedObjects,
                      getDefaultFields('primary', t),
                    ],
                  })
                }
                data-qa={'new-primary-row-button'}
              >
                {`+ ${t('Add Related Object')}`}
              </NewItemButton>
            </Row>
          )}
        </StyledSection>
        <StyledSection label={t('Additional Relationships')} ref={sectionRef}>
          <StyledNotice noRows={!relatedObjectsReverse.length}>
            <KizenTypography data-qa={`no-items-notification`}>
              {t(
                'These will create multi-select fields to represent multiple associations between your data. For example, we recommend using an additional relationship for deals that may have additional points of contact.'
              )}
            </KizenTypography>
          </StyledNotice>
          {loading ? (
            <StyledLoader loading />
          ) : (
            <Row>
              {relatedObjectsReverse.length ? (
                <ReverseWrapper>
                  <HeadCells />
                  <ChunkRender chunkSize={10}>
                    {relatedObjectsReverse.map((relation, index) => {
                      return (
                        <LineItem
                          data-qa={`reverse-related-objects-line-item-${index}`}
                          key={relationKey(relation, index)}
                          value={relation}
                          allModelOptions={allModelOptions}
                          onConfirmDelete={onConfirmDelete}
                          objectFields={objectFields}
                          entityName={values.entityName}
                          sectionRef={sectionRef}
                          editable={!formData || relation.isNew}
                          relation={'relatedObjectsReverse'}
                          index={index}
                          allRelationTypeOptions={relatedObjectTypeOptions(
                            t
                          ).slice(2)}
                          onChange={(data, shouldUpdateCategories) =>
                            handleOnChange(
                              data,
                              index,
                              'relatedObjectsReverse',
                              shouldUpdateCategories
                            )
                          }
                          onClickRemove={() =>
                            handleClickRemove(
                              'relatedObjectsReverse',
                              relatedObjectsReverse,
                              values,
                              index,
                              relation
                            )
                          }
                          validationProps={validationProps}
                        />
                      );
                    })}
                  </ChunkRender>
                </ReverseWrapper>
              ) : null}
            </Row>
          )}
          <NewItemButton
            onClick={() => {
              setValueHandler({
                ...values,
                relatedObjectsReverse: [
                  ...values.relatedObjectsReverse,
                  getDefaultFields('additional', t),
                ],
              });
            }}
            data-qa={'new-reverse-row-button'}
          >
            {`+ ${t('Add Related Object')}`}
          </NewItemButton>
        </StyledSection>
        <ConfirmationModal
          enforceFocus={false}
          heading={t('Please Confirm Change')}
          buttonText={t('Confirm Change')}
          {...confirmChangeModalProps}
        >
          {associatedWarnings}
        </ConfirmationModal>
      </StyledBuilderCard>
    </PageSizing>
  );
}
