import { Dispatch, SetStateAction, createContext } from 'react';

import {
  FlowStep,
  FlowTrigger,
  SmartConnectorStats,
  ActionPlacing,
  iDrop,
} from './types';

import { defaultSmartConnectorStats } from './constants';
import {
  CustomObjectLike,
  SmartConnector,
  SmartConnectorStatus,
  SmartConnectorBuilderErrors,
} from '__pages/SmartConnectors/types';
import { defaultSmartConnector } from '../../context';
import { defaultSmartConnectorTrigger } from '__pages/AutomationEngine/steps';
import { defaultUploadStep } from '__pages/AutomationEngine/steps';
import { SMART_CONNECTOR_STATUSES } from '__pages/SmartConnectors/constants';
import { DeactivationModalProps } from '../../DeactivationModal';
import { ShowDeactivationModalParams } from '../../hooks/useDeactivationModal';

export type FlowBuilderContextType = {
  preRelease: boolean;
  smartConnector: SmartConnector;
  customObject?: CustomObjectLike;
  setSmartConnector: Dispatch<SetStateAction<SmartConnector>>;
  refetchSmartConnector: () => Promise<void>;
  placing: boolean;
  setPlacing: Dispatch<SetStateAction<boolean>>;
  lastPlacingStep: FlowStep | null;
  setLastPlacingStep: Dispatch<SetStateAction<any>>;
  interacting: boolean;
  setInteracting: Dispatch<SetStateAction<boolean>>;
  isDirty: boolean;
  setDirty: Dispatch<SetStateAction<boolean>>;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  status: SmartConnectorStatus;
  setStatus: Dispatch<SetStateAction<SmartConnectorStatus>>;
  triggers: FlowTrigger[];
  setTriggers: Dispatch<SetStateAction<FlowTrigger[]>>;
  steps: FlowStep[];
  setSteps: Dispatch<SetStateAction<FlowStep[]>>;
  connections: FlowStep[];
  setConnections: Dispatch<SetStateAction<FlowStep[]>>;
  uploads: FlowStep[];
  setUploads: Dispatch<SetStateAction<FlowStep[]>>;
  showStats: boolean;
  setShowStats: Dispatch<SetStateAction<boolean>>;
  stats: SmartConnectorStats;
  persisting: boolean;
  flowErrors: SmartConnectorBuilderErrors;
  setFlowErrors: Dispatch<SetStateAction<SmartConnectorBuilderErrors>>;
  nameError: boolean;
  setNameError: Dispatch<SetStateAction<boolean>>;
  saveable: boolean;
  smartConnectorLoading: boolean;
  placeStep: (step: ActionPlacing) => void;
  saveFlow: () => void;
  dropStep: (step: iDrop | null) => void;
  droppedStep: iDrop | null;
  customObjects: CustomObjectLike[];
  deactivationModalProps: DeactivationModalProps;
  showDeactivationModal: (params: ShowDeactivationModalParams) => void;
  showDeactivationModalWithResult: (
    params: ShowDeactivationModalParams<boolean | null>
  ) => Promise<boolean | null>;
};

export const defaultFlowBuilderContextType: FlowBuilderContextType = {
  preRelease: false,
  smartConnector: defaultSmartConnector as SmartConnector,
  setSmartConnector: () => {},
  refetchSmartConnector: async () => {},
  placing: false,
  setPlacing: () => {},
  lastPlacingStep: null,
  setLastPlacingStep: () => {},
  interacting: false,
  setInteracting: () => {},
  isDirty: false,
  setDirty: () => {},
  name: '',
  setName: () => {},
  status: SMART_CONNECTOR_STATUSES.setup,
  setStatus: () => {},
  triggers: [defaultSmartConnectorTrigger],
  setTriggers: () => {},
  connections: [],
  setConnections: () => {},
  steps: [],
  setSteps: () => {},
  uploads: [defaultUploadStep],
  setUploads: () => {},
  showStats: false,
  setShowStats: () => {},
  stats: defaultSmartConnectorStats,
  persisting: false,
  flowErrors: { flow: {} },
  setFlowErrors: () => {},
  nameError: false,
  setNameError: () => {},
  saveable: false,
  smartConnectorLoading: false,
  placeStep: () => {},
  saveFlow: () => {},
  dropStep: () => {},
  droppedStep: null,
  customObjects: [],
  deactivationModalProps: {
    show: false,
    onConfirm: async () => {},
    onHide: () => {},
    defaultLeftBtnHandler: () => {},
  },
  showDeactivationModal: () => {},
  showDeactivationModalWithResult: async () => null,
};

export const FlowBuilderContext = createContext<FlowBuilderContextType>(
  defaultFlowBuilderContextType
);
