import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useFlashState from 'hooks/useFlashState';
import TextInput from 'components/Inputs/TextInput';
import BasicModal from 'components/Modals/presets/BasicModal';
import Automation2Service from 'services/Automation2Service';
import { getOriginalError } from 'services/AxiosService';
import { KeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { useKeyListeners } from 'hooks/keyboardEventHandler/useKeyListeners';
import Dropdown from 'components/Fields/FieldInput/Dropdown';
import { FIELD_TYPES } from 'utility/constants';
import { usePreserveCaretPosition } from 'hooks/usePreserveCaretPosition';
import { MAX_AUTOMATION_NAME_LENGTH } from './constants';
import { FolderSelect } from './EditFolderModal/FolderSelect';

const StyledBasicModal = styled(BasicModal)`
  .modal-body {
    overflow: unset;
  }
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 17px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledSelect = styled(Dropdown)`
  margin-bottom: 17px;
`;

const NewAutomationModal = ({
  currentDirectory,
  folders,
  models,
  onHide,
  handleCreate,
  onAddFolder,
  show,
  validParentFolders,
  ...others
}) => {
  const { t } = useTranslation();
  const [automationName, setAutomationName] = useState('');
  const [automationEmail, setAutomationEmail] = useState('');
  const [chosenModel, setModel] = useState(null);
  const [folder, setFolder] = useState(() => {
    if (!currentDirectory.parentFolderId) {
      return null;
    }
    return folders.find((x) => x.id === currentDirectory.id) ?? null;
  });
  const [errors, setErrors] = useFlashState(1500, {});
  const history = useHistory();

  useEffect(() => {
    if (show) {
      setAutomationName('');
      setAutomationEmail('');
      setModel(null);
    }
  }, [show, setErrors]);

  const handleClick = async () => {
    try {
      const data = {
        name: automationName.trim(),
        customObjectId: chosenModel,
        errorNotificationEmail: automationEmail || null,
      };

      if (folder) {
        data.folderId = folder.id;
      }

      const automation = await Automation2Service.create(data);
      history.push(`/automation/${automation.id}/`);
      handleCreate();
    } catch (error) {
      const originalError = getOriginalError(error);
      if (originalError) {
        setErrors(originalError);
      }
    }
  };
  const { assignFieldHandle, getKeyListenersProps } = useKeyListeners(
    [
      { id: 'automation' },
      {
        id: 'automation-object',
      },
      {
        id: 'automation-email',
      },
    ],
    {},
    () => true
  );

  const inputRef = usePreserveCaretPosition(automationName);

  return (
    <StyledBasicModal
      onHide={onHide}
      show={show}
      heading={t('Add Automation')}
      disabled={!automationName.trim() || !chosenModel}
      onConfirm={handleClick}
      buttonText={t('Save')}
      defaultLeftBtnText={t('Cancel')}
      {...others}
    >
      <KeyBoardContext.Provider value={{ assignFieldHandle }}>
        <StyledTextInput
          ref={(el) => inputRef(el && el.querySelector('input'))}
          label={t('Automation Name')}
          placeholder={t('Enter Name')}
          value={automationName}
          onChange={(v) =>
            setAutomationName(v.slice(0, MAX_AUTOMATION_NAME_LENGTH))
          }
          validate={
            errors.name && {
              message: errors.name,
              showMessage: true,
            }
          }
          inModal
          {...getKeyListenersProps('automation')}
        />
        <StyledSelect
          label={t('Choose Automation Object')}
          placeholder={t('Choose Object')}
          options={models}
          value={chosenModel}
          onChange={setModel}
          menuInline
          menuLeftButton={null}
          menuRightButton={null}
          field={{ fieldType: FIELD_TYPES.Dropdown, options: models }}
          {...getKeyListenersProps('automation-object')}
        />
        <StyledTextInput
          label={t('Additional Error Notification Email')}
          labelInfo={t(
            'Error emails will always be sent to the creator by default, but you may also add another email recipient (such as a distribution group)'
          )}
          labelInfoPlacement="top"
          labelInfoMaxWidth={255}
          placeholder={t('Email')}
          value={automationEmail}
          onChange={setAutomationEmail}
          inModal
          validate={
            errors.error_notification_email && {
              message: errors.error_notification_email,
              showMessage: true,
            }
          }
          {...getKeyListenersProps('automation-email')}
        />
        <FolderSelect
          chosenFolder={folder}
          folders={folders}
          validParentFolders={validParentFolders}
          onChange={setFolder}
          onAddFolder={onAddFolder}
          error={errors.folder_id}
          currentDirectory={currentDirectory}
        />
      </KeyBoardContext.Provider>
    </StyledBasicModal>
  );
};

export default NewAutomationModal;
