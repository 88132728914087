import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { gutters } from 'app/spacing';
import { TextEllipsisWithTooltip, TextEllipsis } from 'components/Kizen/Table';
import ColorLabel from 'components/Fields/ColorLabel';
import TablePagination from 'components/Tables/Big/TablePagination';
import BigListLayout, { BigListTitle } from 'components/Layout/BigListLayout';
import {
  BigListMainSelector,
  BigListSearchInput,
  BigListToolbar,
  BigListToolbarSection,
} from 'components/Layout/BigListLayout/Toolbar';
import ListCard, {
  ListCardCell,
  ListCardHeader,
  ListCardItem,
  ListCardRow,
  ListCardSortButton,
} from 'components/Layout/ListCard';
import { fontSizes } from 'app/typography';
import { MobileActionCell, statusOptions } from './Cells';
import { useSelector } from 'react-redux';
import {
  selectAutomationsIsFiltering,
  selectAutomationsWithCustomObjectAccessData,
} from 'store/automationsPage/automations.redux';

export const WrapperStatus = styled.p`
  font-size: ${fontSizes.text};
  margin-bottom: 0;
`;

const StyledBigListToolbar = styled(BigListToolbar)`
  padding-top: ${gutters.spacing(1, -3)}px;
  height: 51px;
`;

const StyledBigListTitle = styled(BigListTitle)`
  margin-top: ${gutters.spacing(4, -3)}px;
  margin-bottom: ${gutters.spacing(3, -1)}px;
`;

const MobileLayout = ({
  groupId = null,
  groups,
  handleChangeGroup,
  handleChangeSearch,
  search = null,
  pageNumber,
  pageSize,
  handleChangePageNumber,
  handleChangePageSize,
  sort,
  handleChangeSort,
  handleSelectAction,
  ...others
}) => {
  const { t } = useTranslation();
  const isFiltering = useSelector(selectAutomationsIsFiltering);
  const automations = useSelector(selectAutomationsWithCustomObjectAccessData);
  const automationsCount = useSelector(
    (s) => s.automationPage.automationsCount
  );

  return (
    <BigListLayout
      toolbar={
        <StyledBigListToolbar>
          <BigListToolbarSection>
            <BigListMainSelector
              value={groupId || 'none'}
              options={groups}
              onChange={handleChangeGroup}
            />
          </BigListToolbarSection>
        </StyledBigListToolbar>
      }
      search={
        <BigListSearchInput
          placeholder={t('Find Automations')}
          value={search}
          onChange={handleChangeSearch}
          loading={isFiltering}
        />
      }
      pagination={
        <TablePagination
          page={pageNumber}
          perPage={pageSize}
          totalCount={automationsCount}
          onChangePage={handleChangePageNumber}
          onChangePerPage={handleChangePageSize}
        />
      }
      {...others}
    >
      <StyledBigListTitle
        count={automationsCount}
        single={t('Automation')}
        plural={t('Automations')}
      />
      <ListCard>
        <ListCardHeader compactHeader action={<div style={{ width: 15 }} />}>
          <ListCardCell>
            <TextEllipsis as="span" weight="bold">
              {t('Automation Name')}
            </TextEllipsis>
            <ListCardSortButton
              sort={sort && sort.column === 'name' ? sort : null}
              onChangeSort={(direction) =>
                handleChangeSort({ column: 'name', direction })
              }
            />
          </ListCardCell>
          <ListCardCell>
            <TextEllipsis as="span" weight="bold">
              {t('Status')}
            </TextEllipsis>
            <ListCardSortButton
              sort={sort && sort.column === 'status' ? sort : null}
              onChangeSort={(direction) =>
                handleChangeSort({ column: 'status', direction })
              }
            />
          </ListCardCell>
        </ListCardHeader>
        {automations.map((item) => {
          const status = statusOptions(t).find(
            ({ value }) => value === String(item.status)
          );

          return (
            <ListCardItem
              key={item.id}
              action={
                <MobileActionCell
                  position="left"
                  menuType="relative"
                  data={item}
                  isMobile
                  onSelectAction={handleSelectAction}
                />
              }
            >
              <ListCardRow>
                <ListCardCell>
                  <TextEllipsisWithTooltip>{item.name}</TextEllipsisWithTooltip>
                </ListCardCell>
              </ListCardRow>
              <ListCardRow>
                <ListCardCell>
                  <TextEllipsisWithTooltip>
                    {item.customObjectName}
                  </TextEllipsisWithTooltip>
                </ListCardCell>
                <ListCardCell>
                  <WrapperStatus>
                    <ColorLabel color={status.color}>{status.label}</ColorLabel>
                  </WrapperStatus>
                </ListCardCell>
              </ListCardRow>
            </ListCardItem>
          );
        })}
      </ListCard>
    </BigListLayout>
  );
};

export default MobileLayout;
