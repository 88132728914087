import {
  forwardRef,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { textCss } from 'app/typography';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';
import FileInput from 'components/Inputs/FileInput';
import { DEFAULT_FIELD_NAMES, FIELD_TYPES } from 'utility/constants';
import LongTextEllipsis from './LongTextEllipsis';
import { TeamMemberBlock } from './components/TeamMemberBlock';
import { RelationshipBlock } from './components/RelationshipBlock';
import { emptyLongDash, FIELDS } from './helpers';
import { colorsButton } from 'app/colors';
import { css } from '@emotion/core';
import { StatusBlock } from './components/StatusBlock';
import { useDateTooltip } from 'components/Inputs/hooks/useDateTooltip';
import { getMaskedValue, getUrlFromString } from 'components/Inputs/helpers';
import { useTooltip } from 'components/Kizen/Tooltip';
import { EMPTY_OBJECT } from 'utility/fieldHelpers';

export const HtmlContainer = styled(LongTextEllipsis)`
  li {
    ${textCss()}
  }

  p,
  em,
  s,
  strong,
  u {
    font-size: 1em;
  }

  &.note * {
    font-family: inherit;
    font-size: inherit;
    line-height: 1.5em;
    ${(isExpandable) => {
      if (!isExpandable) {
        return css`
          height: 1.5em;
        `;
      }
    }}

    margin: 0;
    &:first-child {
      margin-top: -3px;
    }
  }
  .mention {
    color: ${colorsButton.blue.default};
    font-size: inherit;
  }

  strong {
    font-weight: revert;
  }
`;

const FileViewerContainer = styled.div`
  margin-top: -8px;

  > div {
    border-bottom: none;
  }
`;

const MaskedTextWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const NO_VALUE = '—';

const FileViewer = (props) => (
  <FileViewerContainer>
    <FileInput showVisibleIcon={false} {...props} />
  </FileViewerContainer>
);

const DateWrapper = styled(LongTextEllipsis)`
  width: fit-content;
`;

export const DateTimeComponent = ({ value, showDateTooltip, ...others }) => {
  const [dateTooltipProps, dateTooltip] = useDateTooltip({
    date: showDateTooltip && value,
  });
  return (
    <DateWrapper {...dateTooltipProps} {...others}>
      {dateTooltip}
      {FIELDS[FIELD_TYPES.DateTime.type]({ value })}
    </DateWrapper>
  );
};

export const MaskedText = ({ item, ...others }) => {
  const maskValue = item?.metaInfo?.meta?.isMasked && item.value;

  const [tooltipProps, tooltip] = useTooltip({
    label: String(item.value),
    maxWidth: 400,
    popperConfig: {
      modifiers: {
        preventOverflow: {
          enabled: false,
          rootBoundary: 'viewport',
        },
      },
    },
  });

  const maskedValue = maskValue
    ? getMaskedValue(String(item.value))
    : String(item.value || NO_VALUE);

  return (
    <MaskedTextWrapper>
      {maskValue && item.value ? tooltip : null}
      <LongTextEllipsis
        isExpandable={item.type === FIELD_TYPES.LongText.type}
        {...others}
        {...tooltipProps}
      >
        {maskedValue}
      </LongTextEllipsis>
    </MaskedTextWrapper>
  );
};

const RenderValue = ({ item = EMPTY_OBJECT, ...others }, ref) => {
  if (
    item.type === FIELD_TYPES.TeamSelector.type ||
    item.type === FIELD_TYPES.Selector.type // we need this type for timeline
  ) {
    return <TeamMemberBlock {...others} item={item} />;
  }

  if (item.type === FIELD_TYPES.Relationship.type) {
    return <RelationshipBlock item={item} ref={ref} {...others} />;
  }

  if (item.type === FIELD_TYPES.Status.type) {
    return <StatusBlock item={item} {...others} />;
  }

  if (item.type === 'html') {
    return (
      <HtmlContainer
        isExpandable
        dangerouslySetInnerHTML={{ __html: item.value }}
        {...others}
        className="note"
      />
    );
  }

  if (item.type === FIELD_TYPES.Files.type) {
    if (!item?.value?.length) {
      return <LongTextEllipsis {...others}>{emptyLongDash}</LongTextEllipsis>;
    }
    const files = item.value.map(({ name, url, value, ...rest }) => {
      return { id: value, name, url, ...rest };
    });
    return (
      <LongTextEllipsis {...others}>
        <FileViewer readonly variant="underline" files={files} />
      </LongTextEllipsis>
    );
  }

  if (item.type === FIELD_TYPES.DateTime.type) {
    return (
      <DateTimeComponent
        {...others}
        value={item?.value}
        showDateTooltip={
          item.metaInfo?.name === DEFAULT_FIELD_NAMES.actualCloseDate
        }
      />
    );
  }

  if (
    item.type === FIELD_TYPES.LongText.type ||
    item.type === FIELD_TYPES.Text.type
  ) {
    if (item.metaInfo?.meta?.isMasked) {
      return <MaskedText {...others} item={item} />;
    }
    const urlFromValue = getUrlFromString(item.value);

    if (urlFromValue) {
      return (
        <TextEllipsisWithTooltip
          {...others}
          type="anchor"
          href={urlFromValue}
          target="_blank"
          rel="noreferrer"
        >
          {item.value}
        </TextEllipsisWithTooltip>
      );
    }
  }

  return (
    <LongTextEllipsis
      isExpandable={item.type === FIELD_TYPES.LongText.type}
      {...others}
    >
      {FIELDS[item.type](item)}
    </LongTextEllipsis>
  );
};

const RenderValueForwarded = forwardRef(RenderValue);

export default function FieldsTable({
  fields = [],
  dataQA,
  isExpanded = true,
  maxItemsToRender = 100, // reduce amount of rendered items unless isExpanded === true
  lineHeight = 14,
  maxLines = 0,
}) {
  const [lineClamps, setLineClamps] = useState(fields.map(() => 'unset'));
  const sections = useRef([]);
  const fieldsToRender = useMemo(() => {
    if (isExpanded || !fields) return fields;
    let count = 0;
    return fields.reduce((collectItems, item) => {
      if (maxItemsToRender && count > maxItemsToRender) {
        return collectItems;
      }
      if (!item?.value || !Array.isArray(item.value)) {
        count++;
        return [...collectItems, item];
      }
      return [
        ...collectItems,
        {
          ...item,
          value: item.value.reduce((collect, value) => {
            count++;
            return count > maxItemsToRender ? collect : [...collect, value];
          }, []),
        },
      ];
    }, []);
  }, [fields, isExpanded, maxItemsToRender]);

  useEffect(() => {
    if (maxLines) {
      const clamps = fields.map(() => 'unset');
      if (!isExpanded) {
        let lines = 0;
        sections.current.forEach((el, i) => {
          if (!el) return;
          const sectionLines = Math.floor(el.scrollHeight / lineHeight);
          if (lines >= maxLines) {
            clamps[i] = i === 0 ? maxLines : 0;
          } else if (lines + sectionLines >= maxLines) {
            clamps[i] = maxLines - lines;
          }
          lines += sectionLines;
        });
      }

      setLineClamps(clamps);
    }
  }, [fields, maxLines, lineHeight, isExpanded]);

  return fieldsToRender.map((item, index) => {
    const dataQAProps = {
      'data-qa-field-name': dataQA,
      'data-qa-label': item.label,
      'data-qa-field-index': index,
    };
    return lineClamps[index] ? (
      <Fragment key={item.id}>
        <TextEllipsisWithTooltip weight="bold">
          {item.label}
        </TextEllipsisWithTooltip>
        <RenderValueForwarded
          item={item}
          {...dataQAProps}
          ref={(el) => (sections.current[index] = el)}
          lineClamp={lineClamps[index]}
        />
      </Fragment>
    ) : null;
  });
}
