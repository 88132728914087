import TriggerCard from 'pages/AutomationEngine/Engine/Steps/TriggerCard';
import DraggableStepCard from 'pages/AutomationEngine/Engine/Steps/DraggableStepCard';
import { RECEIVING_INBOX } from 'pages/AutomationEngine/helpers';
import { getValueOrDeleted } from '../action/action-helpers';

export const content = ({ step }) => step.description;

export const getDescription = ({ step, t }) => {
  let type;

  const { details } = step;

  if (details.receivingInbox === RECEIVING_INBOX.any) {
    type = t('Any Integrated Inbox');
  } else if (
    details.receivingInbox === RECEIVING_INBOX.selectedIntegratedInboxes
  ) {
    const emails = details.selectedIntegratedInboxes.map((inbox) =>
      getValueOrDeleted(inbox?.label, t)
    );
    type = `${t('Integrated')} ${emails.length > 1 ? t('Inboxes') : t('Inbox')}: ${emails?.length ? emails.join(', ') : t('[Deleted]')}`;
  }

  return `${t('Email Received From Contact to')} ${type}`;
};

export default function Card({ step, ...others }) {
  return step?.goalType ? (
    <DraggableStepCard {...others}>{content({ step })}</DraggableStepCard>
  ) : (
    <TriggerCard {...others}>{content({ step })}</TriggerCard>
  );
}
