import {
  FieldLight,
  GenericOption,
  SmartConnectorDataSeed,
} from '__pages/SmartConnectors/types';
import { REFERENCE_FIELD_TYPES } from './constants';

export type CategorizedFields = {
  id: string;
  label: string;
  fields: FieldLight[];
}[];

export const getFieldsValue = (
  dataSeed: SmartConnectorDataSeed | undefined
): GenericOption[] => {
  return dataSeed?.fields.length
    ? dataSeed?.fields.map<GenericOption>((field) => {
        return {
          value: field.id,
          label: `${field.display_name} (${field.name})`,
        };
      })
    : [];
};

export const getFieldsOptions = (categorizedFields: CategorizedFields) => {
  return categorizedFields.reduce<
    { label: string; options: GenericOption[] }[]
  >((acc, group) => {
    const options = group.fields.reduce<GenericOption[]>((fieldsAcc, field) => {
      if (REFERENCE_FIELD_TYPES.includes(field.field_type)) {
        return fieldsAcc.concat([
          {
            value: field.id,
            label: `${field.display_name} (${field.name})`,
          },
        ]);
      }
      return fieldsAcc;
    }, []);

    if (options.length) {
      acc.push({
        label: group.label,
        options,
      });
    }
    return acc;
  }, []);
};
