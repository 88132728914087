import {
  SizedCell,
  SortableHeadCell,
  SubActionHeadCell,
} from 'components/Kizen/Table/CellLineHeightStyles';
import {
  columnSize,
  paddingSize,
  CreatedCell,
  TypeCell,
  NumberCompletedCell,
  NumberPausedCell,
  NumberActiveCell,
  ObjectNameCell,
  StatusCell,
  NameCell,
  ActionCell,
} from './Cells';

// Note: `cell` is cloned by the table component and adds the `head`, `column`, and `data` props.
export const getAutomationsColumns = ({
  currentDirectory,
  onUpdateAutomation,
  onSelectAction,
  onChangeDirectory,
  t,
}) => [
  {
    id: 'spacer-1',
    headCell: <SizedCell width={columnSize.spacer} />,
    cell: <SizedCell width={columnSize.spacer} />,
  },
  {
    id: 'name',
    headCell: (
      <SortableHeadCell
        label={t('Automation Name')}
        width={columnSize.name}
        padding={paddingSize.big}
      />
    ),
    cell: (
      <NameCell
        currentDirectory={currentDirectory}
        onUpdateAutomation={onUpdateAutomation}
        onChangeDirectory={onChangeDirectory}
      />
    ),
  },
  {
    id: 'customObjectName',
    headCell: (
      <SortableHeadCell
        label={t('Automation Object')}
        width={columnSize.customObjectName}
        padding={paddingSize.big}
      />
    ),
    cell: <ObjectNameCell />,
  },
  {
    id: 'status',
    headCell: (
      <SortableHeadCell
        label={t('Status')}
        width={columnSize.status}
        padding={paddingSize.big}
      />
    ),
    cell: <StatusCell onUpdateAutomation={onUpdateAutomation} />,
  },
  {
    id: 'numberActive',
    headCell: (
      <SortableHeadCell
        numeric
        label={t('# Active')}
        width={columnSize.numberActive}
        padding={paddingSize.big}
      />
    ),
    cell: <NumberActiveCell />,
  },
  {
    id: 'numberPaused',
    headCell: (
      <SortableHeadCell
        numeric
        label={t('# Paused')}
        width={columnSize.numberPaused}
        padding={paddingSize.big}
      />
    ),
    cell: <NumberPausedCell />,
  },
  {
    id: 'numberCompleted',
    headCell: (
      <SortableHeadCell
        numeric
        label={t('# Completed')}
        width={columnSize.numberCompleted}
        padding={paddingSize.big}
      />
    ),
    cell: <NumberCompletedCell />,
  },
  {
    id: 'type',
    headCell: (
      <SortableHeadCell
        label={t('Type')}
        width={columnSize.type}
        padding={paddingSize.big}
      />
    ),
    cell: <TypeCell />,
  },
  {
    id: 'created',
    headCell: (
      <SortableHeadCell
        numeric
        label={t('Date Created')}
        width={columnSize.created}
        padding={paddingSize.big}
      />
    ),
    cell: <CreatedCell />,
  },
  {
    id: 'actions',
    headCell: (
      <SubActionHeadCell
        width={columnSize.actions}
        padding={paddingSize.small}
      />
    ),
    cell: (
      <ActionCell
        onSelectAction={onSelectAction}
        currentDirectory={currentDirectory}
      />
    ),
  },
];
