import { getDisplayNames } from '@kizen/api/records';
import { defFilterSetErrors, validateStep } from '@kizen/filters/validate';
import type { FilterMetadata, UrlConfig } from '@kizen/filters/types';
import { loadSteps } from '@kizen/filters/load';
import { type Filter, defFilter } from '@kizen/filters/filter';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { v4 as uuidv4 } from 'uuid';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { toastVariant, useToast } from '__components/ToastProvider';
import { invalidate } from '__queries/invalidate';
import {
  useActivityQuery,
  useActivityFieldsQuery,
} from '__queries/models/activities-ts';
import ActivityService from 'services/ActivityService';
import { camelToSnakeCaseKeys, snakeToCamelCaseKeys } from 'services/helpers';
import { fetchUrlConfigWithReactQuery } from 'ts-filters/utils';
import { useMetadata } from './useMetadata';
import {
  ActivityRulePayload,
  ActivityRulesLocationState,
  CardError,
  FieldLike,
  FieldLikeSnakeCase,
  VisibilityRule,
} from './types';
import { hasCyclicRules, visibilityRuleEq } from './utils';
import AxiosService, { getOriginalError } from 'services/AxiosService';
import { validate as validateEmail } from 'components/Inputs/TextInput/presets/EmailAddress';
import { monitoringExceptionHelper } from '../sentry/helpers';

const ctx = createContext<Ctx>({} as Ctx);

type Ctx = {
  activity: ActivityLike;
  buildVisibilityRules(): VisibilityRule[];
  conditionOps: Map<Filter, ConditionOps>;
  errors: CardError[];
  fields: FieldLike[];
  handleSave(): Promise<boolean>;
  hasEdits: boolean;
  isLoading: boolean;
  ops: ReturnType<typeof defOps>;
  rules: Rule[];
};

type ActivityRulesContextProps = {
  activityId: string;
  isActive: boolean;
  setActivity: Dispatch<SetStateAction<ActivityLike>>;
};

type ActivityLike = {
  id: string;
  fields: FieldLike[];
  visibilityRules: VisibilityRule[];
};

export type ConditionOps = ReturnType<typeof opsForFilter>;

type Rule = {
  conditions: Filter[];
  fields: string[];
  id: string;
  operation: 'any' | 'all';
  steps: any[];
};

const buildActivityRuleFilter = (
  vars: [string, string][],
  metadata: FilterMetadata
) => {
  return defFilter(metadata, metadata, { vars });
};

const opsForFilter = (
  filter: Filter,
  update: Dispatch<SetStateAction<any[]>>
) => ({
  next: (...args: Parameters<Filter['next']>) => filter.next(...args),
  set: (...args: Parameters<Filter['set']>) => filter.set(...args),
  update: () => update((prev: any) => [...prev]),
});

const defRule = (
  vars: [string, string][],
  conditionOps: Map<Filter, ConditionOps>,
  metadata: FilterMetadata,
  setRules: Dispatch<SetStateAction<Rule[]>>,
  {
    fields = [],
    filters = [],
    operation = 'any',
  }: {
    fields?: Pick<FieldLike, 'id'>[];
    filters?: Filter[];
    operation?: Rule['operation'];
  } = {}
): Rule => {
  let conditions = filters;

  if (conditions.length === 0) {
    const filter = buildActivityRuleFilter(vars, metadata);
    conditionOps.set(filter, opsForFilter(filter, setRules));
    conditions = [filter];
  } else {
    for (const cond of conditions) {
      conditionOps.set(cond, opsForFilter(cond, setRules));
    }
  }

  return {
    conditions,
    fields: fields.map((x) => x.id),
    id: uuidv4(),
    operation,
    steps: [],
  };
};

const defOps = (
  vars: [string, string][],
  conditionOps: Map<Filter, ConditionOps>,
  metadata: FilterMetadata,
  setRules: Dispatch<SetStateAction<Rule[]>>
) => {
  return {
    addRule: (field?: FieldLikeSnakeCase) => {
      if (field) {
        const filter = defFilter(metadata, metadata, {
          vars,
          init: [['field_id', field], ['condition']],
        });
        const rule = defRule(vars, conditionOps, metadata, setRules, {
          filters: [filter],
        });
        setRules((prev) => prev.concat(rule));
      } else {
        setRules((prev) =>
          prev.concat(defRule(vars, conditionOps, metadata, setRules))
        );
      }
    },
    addCondition: (index: number) => {
      const filter = buildActivityRuleFilter(vars, metadata);
      conditionOps.set(filter, opsForFilter(filter, setRules));
      setRules((prev) =>
        prev.slice(0, index).concat(
          {
            ...prev[index],
            conditions: prev[index].conditions.concat(filter),
          },
          prev.slice(index + 1)
        )
      );
    },
    deleteCondition: (rule_index: number, index: number) => {
      setRules((prev) => {
        const conditions = prev[rule_index].conditions
          .slice(0, index)
          .concat(prev[rule_index].conditions.slice(index + 1));
        return prev
          .slice(0, rule_index)
          .concat(
            { ...prev[rule_index], conditions },
            prev.slice(rule_index + 1)
          );
      });
    },
    deleteRule: (index: number) => {
      setRules((prev) => prev.slice(0, index).concat(prev.slice(index + 1)));
    },
    fieldsChanged: (index: number, ids: string[]) => {
      setRules((prev) =>
        prev
          .slice(0, index)
          .concat({ ...prev[index], fields: ids }, prev.slice(index + 1))
      );
    },
    operationChanged: (index: number, operation: Rule['operation']) => {
      setRules((prev) =>
        prev
          .slice(0, index)
          .concat({ ...prev[index], operation }, prev.slice(index + 1))
      );
    },
    reorderUp: (index: number) => {
      setRules((prev) => {
        return index === 0
          ? prev
          : [
              ...prev.slice(0, index - 1),
              prev[index],
              prev[index - 1],
              ...prev.slice(index + 1),
            ];
      });
    },
    reorderDown: (index: number) => {
      setRules((prev) => {
        return index === prev.length - 1
          ? prev
          : [
              ...prev.slice(0, index),
              prev[index + 1],
              prev[index],
              ...prev.slice(index + 2),
            ];
      });
    },
  };
};

const loadVisibilityRule = async (
  fetchUrlConfig: (config: UrlConfig) => Promise<any>,
  { rule, fields }: VisibilityRule,
  activity: ActivityLike,
  metadata: FilterMetadata,
  vars: [string, any][],
  conditionOps: Map<Filter, ConditionOps>,
  setRules: Dispatch<SetStateAction<Rule[]>>,
  hiddenFieldErrorMessage: string
) => {
  const filters = await Promise.all(
    rule.filters.map(async (filter) => {
      const steps = await loadSteps(fetchUrlConfig, filter.view_model);

      if (steps[0][1].is_hidden) {
        steps[0][1] = {
          ...steps[0][1],
          error: true,
          error_message: hiddenFieldErrorMessage,
        };
      }

      return defFilter(metadata, metadata, { init: steps, vars });
    })
  );
  const fieldIds = new Set(fields.map((field) => field.id));
  const init = {
    fields: activity.fields.filter((field: FieldLike) =>
      fieldIds.has(field.id)
    ),
    filters,
    operation: rule.and ? ('all' as const) : ('any' as const),
  };
  return defRule(vars, conditionOps, metadata, setRules, init);
};

const buildVisibilityRules = (rules: Rule[]) => {
  return rules.map((rule) => {
    return {
      fields: rule.fields.map((id) => ({ id })),
      rule: {
        and: rule.operation === 'all',
        filters: rule.conditions.map((filter) => ({
          ...filter.build<Omit<ActivityRulePayload, 'view_model'>>(),
          view_model: [...filter.save({ root_filter: false })],
        })),
      },
    };
  });
};

const useHasEdits = (
  rules: Rule[],
  isLoaded: boolean,
  existing: VisibilityRule[]
) => {
  const calculationCount = useRef(0);

  const edits = useMemo(() => {
    if (!isLoaded) {
      return null;
    }

    calculationCount.current = calculationCount.current + 1;
    return buildVisibilityRules(rules);
  }, [isLoaded, rules]);

  if (!edits) {
    return false;
  }

  if (edits.length !== existing.length) {
    return true;
  }

  return edits
    .map((x, i) => {
      const eq = visibilityRuleEq(x, existing[i], {
        // disregard fields for the initial state because a field
        // could be deleted and this should not be considered an edit
        compareFields: calculationCount.current > 1,
      });
      return eq;
    })
    .some((x) => x === false);
};

const getCardErrors = (
  rules: Rule[],
  validFields: FieldLike[],
  deletedOptions: Record<string, string[]>,
  t: TFunction
): CardError[] => {
  const validFieldIds = new Set(validFields.map((q) => q.id));

  return rules.map((rule) => {
    const fields = rule.fields.filter((q) => validFieldIds.has(q));
    const conditionErrors = defFilterSetErrors();

    for (const condition of rule.conditions) {
      const payload = condition.build();
      let stepError = null;

      for (const [step, data] of condition) {
        stepError = validateStep(
          step,
          data,
          isValidPhoneNumber,
          validateEmail.withDomain,
          t
        );

        if (!stepError) {
          const deletedIds = deletedOptions[payload.activity_field_id];

          if (step === 'value' && deletedIds) {
            const deletedSingleSelect = deletedIds.includes(data.value);
            const deletedMultiselect =
              Array.isArray(data.value) &&
              data.value.every((id) => deletedIds.includes(id));

            if (deletedSingleSelect || deletedMultiselect) {
              stepError = { value: t('Required field') };
            }
          }
        }

        if (stepError) {
          break;
        }
      }

      conditionErrors.errors.push(stepError);
    }

    return {
      conditions: conditionErrors,
      fields:
        fields.length === 0
          ? t('At least 1 field must be the target of a rule.')
          : null,
    };
  });
};

const getDeletedFieldOptions = async (
  visibilityRules: VisibilityRule[]
): Promise<Record<string, string[]>> => {
  const conditions = visibilityRules.flatMap((vr) => vr.rule.filters);

  const getNotFoundIds = async (
    condition: ActivityRulePayload
  ): Promise<[string, string[]]> => {
    const ids = condition.value;

    if (!ids || typeof ids === 'boolean') {
      return [condition.activity_field_id, []];
    }

    // We don't know if the field is a dynamic tag or not, so we query for
    // both and a deleted id is one that is not found in both buckets.
    const optionOrTagIds = Array.isArray(ids) ? ids : [ids];
    const res = await getDisplayNames(AxiosService, {
      activity_option_ids: optionOrTagIds,
      activity_tag_ids: optionOrTagIds,
      option_ids: optionOrTagIds,
    });
    const deletedIds = optionOrTagIds.reduce<string[]>((acc, id) => {
      if (
        res.data.not_found.activity_options.find((opt) => opt.id === id) &&
        res.data.not_found.activity_tags.find((opt) => opt.id === id) &&
        res.data.not_found.options.find((opt) => opt.id === id)
      ) {
        acc.push(id);
      }
      return acc;
    }, []);
    return [condition.activity_field_id, deletedIds];
  };

  const entries = await Promise.all(
    conditions.map((cond) => getNotFoundIds(cond))
  );

  return entries.reduce<Record<string, string[]>>(
    (acc, [fieldId, optionIds]) => {
      acc[fieldId] = acc[fieldId] || [];
      acc[fieldId].push(...optionIds);
      return acc;
    },
    {}
  );
};

export const useActivityRulesContext = () => useContext(ctx);

export const ActivityRulesContext = ({
  activityId,
  isActive,
  setActivity,
  children,
}: PropsWithChildren<ActivityRulesContextProps>) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const location = useLocation<ActivityRulesLocationState>();
  const {
    addRuleField,
    errorMessage,
    visibilityRules: visibilityRulesInError,
  } = location?.state ?? {};
  const metadata = useMetadata();
  const [rules, setRules] = useState<Rule[]>([]);
  const [conditionOps] = useState<Map<Filter, ConditionOps>>(new Map());
  const { data: activity, isLoading: loadingActivity } =
    useActivityQuery<ActivityLike>(activityId, {
      select: (res) => snakeToCamelCaseKeys(res),
    });
  const { data: fields, isLoading: loadingFields } = useActivityFieldsQuery<
    FieldLike[]
  >(activityId, {
    select: (res) => snakeToCamelCaseKeys(res),
  });
  const [savedVisibilityRules, setSavedVisibilityRules] = useState<
    VisibilityRule[]
  >([]);
  const [errors, setErrors] = useState<CardError[]>([]);
  const [deletedOptions, setDeletedOptions] = useState<
    Record<string, string[]>
  >({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadingRules, setLoadingRules] = useState(false);
  const loadingRulesRef = useRef(loadingRules);
  const [showToast] = useToast();
  const vars = useMemo<[string, string][]>(
    () => [['activity_id', activityId]],
    [activityId]
  );
  const ops = useMemo(
    () => defOps(vars, conditionOps, metadata, setRules),
    [vars, conditionOps, metadata]
  );
  const visibleFields = useMemo(
    () => fields?.filter(({ isHidden }: FieldLike) => !isHidden) ?? [],
    [fields]
  );
  const hasEdits = useHasEdits(rules, isLoaded, savedVisibilityRules);
  const inErrorState = Boolean(errorMessage) && Boolean(visibilityRulesInError);
  const isLoading = loadingActivity || loadingFields || loadingRules;

  const handleSave = async () => {
    const cardErrors = getCardErrors(rules, visibleFields, deletedOptions, t);

    if (cardErrors.some((x) => Boolean(x.fields) || x.conditions.hasErrors)) {
      setErrors(cardErrors);
      setTimeout(() => setErrors([]), 3000);
      return false;
    }

    const vr = buildVisibilityRules(rules);

    if (hasCyclicRules(vr)) {
      showToast({
        message: t(
          'There are multiple rules that result in a cyclical configuration. Please correct and retry.'
        ),
        variant: toastVariant.FAILURE,
      });
      return false;
    }

    try {
      const res = await ActivityService.v2UpdateActivity({
        id: activityId,
        visibilityRules: vr,
      });
      setActivity({ ...res, fields });
      setSavedVisibilityRules(vr);
      showToast({
        message: t('The activity rules have been saved successfully.'),
      });
      if (inErrorState) {
        // not using react-router's history.replace to avoid re-rendering the page
        window.history.replaceState(
          { state: {} },
          '',
          `/activities/${activityId}/rules`
        );
      }
      return true;
    } catch (error) {
      const orig = getOriginalError(error);
      showToast({
        message:
          orig?.message ||
          t('There was an error saving the activity rules. Please try again.'),
        variant: toastVariant.FAILURE,
      });
      monitoringExceptionHelper(error);
      return false;
    }
  };

  const activityMemo = useMemo(() => {
    return { ...activity!, visibilityRules: savedVisibilityRules };
  }, [activity, savedVisibilityRules]);

  const value = {
    activity: activityMemo,
    buildVisibilityRules: () => buildVisibilityRules(rules),
    conditionOps,
    errors,
    fields: visibleFields,
    handleSave,
    hasEdits,
    isLoading,
    rules,
    ops,
  };

  useEffect(() => {
    const fetchUrlConfig = (config: UrlConfig) => {
      return fetchUrlConfigWithReactQuery(queryClient, config);
    };
    const visibilityRules = activity?.visibilityRules ?? [];

    const load = async (rulesToLoad: VisibilityRule[]) => {
      try {
        setLoadingRules(true);
        loadingRulesRef.current = true;
        const vrs = camelToSnakeCaseKeys(rulesToLoad) as VisibilityRule[];
        const [loaded, deletedIds] = await Promise.all([
          Promise.all(
            vrs.map((vr) =>
              loadVisibilityRule(
                fetchUrlConfig,
                vr,
                { ...activity!, fields: fields! },
                metadata,
                vars,
                conditionOps,
                setRules,
                t('The field has been deactivated')
              )
            )
          ),
          getDeletedFieldOptions(vrs),
        ]);

        setDeletedOptions(deletedIds);
        setRules(loaded);
        setSavedVisibilityRules(camelToSnakeCaseKeys(visibilityRules));
        setIsLoaded(true);

        if (addRuleField) {
          ops.addRule(addRuleField);
        }

        const cardErrors = getCardErrors(loaded, visibleFields, deletedIds, t);

        if (
          cardErrors.some((x) => Boolean(x.fields) || x.conditions.hasErrors)
        ) {
          setTimeout(() => setErrors(cardErrors));
          setTimeout(() => setErrors([]), 3000);
        }
      } finally {
        setLoadingRules(false);
        loadingRulesRef.current = false;
      }
    };

    const rulesToLoad = visibilityRulesInError || visibilityRules;
    if (isActive && !loadingRulesRef.current && fields && rulesToLoad) {
      load(rulesToLoad);
    } else if (isActive && !rulesToLoad) {
      setIsLoaded(true);
    }
  }, [
    activity,
    isActive,
    addRuleField,
    visibilityRulesInError,
    visibleFields,
    fields,
    vars,
    conditionOps,
    metadata,
    showToast,
    ops,
    t,
    queryClient,
  ]);

  useEffect(() => {
    if (isActive && errorMessage) {
      showToast({ message: errorMessage, variant: toastVariant.FAILURE });
    }
  }, [isActive, errorMessage, showToast]);

  useEffect(() => {
    if (isActive) {
      invalidate.FILTERS.ALL();

      return () => {
        invalidate.ACTIVITIES.ACTIVITY(activityId);
      };
    }
  }, [isActive, activityId]);

  return <ctx.Provider value={value}>{children}</ctx.Provider>;
};
