import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryObjectUploadContext } from './context';
import {
  ChildCenterHeaderContainer,
  ChildHeaderContainer,
  HeaderContainer,
  StyledBackButton,
} from '../../styles';
import LoadingButton from '__components/Button/LoadingButton';
import { TextEllipsisWithTooltip } from '__components/Kizen/Table';
import {
  PRIMARY_OBJECT_UPLOAD_STEPS,
  SMARTCONNECTORS_PATHNAME,
} from '__pages/SmartConnectors/constants';
import { useToast } from '__components/ToastProvider';
import { toastVariant } from '__components/ToastProvider';

export const Header = ({ scrolledTop }: { scrolledTop: boolean }) => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const {
    smartConnector,
    isNew,
    customObject,
    isValid,
    handleSave,
    loading,
    refreshing,
    step,
  } = useContext(PrimaryObjectUploadContext);

  const showError = (message: string) => {
    showToast({
      message,
      variant: toastVariant.FAILURE,
    });
  };

  const showSuccess = (message: string) => {
    showToast({
      message,
      variant: toastVariant.SUCCESS,
    });
  };

  const handleSaveWithClose = async () => {
    const saved = await handleSave(true);
    if (saved === null) {
      return;
    }
    if (saved) {
      showSuccess(t('SmartConnector saved successfully'));
    } else {
      showError(t('Failed to save SmartConnector'));
    }
  };

  const handleSaveInPlace = async () => {
    const saved = await handleSave(false);
    if (saved === null) {
      return;
    }
    if (saved) {
      showSuccess(t('SmartConnector saved successfully'));
    } else {
      showError(t('Failed to save SmartConnector'));
    }
  };

  return (
    <HeaderContainer scrolledTop={scrolledTop}>
      <ChildHeaderContainer>
        <StyledBackButton
          to={`${SMARTCONNECTORS_PATHNAME}/${smartConnector.id}/builder`}
          variant="text"
          color="blue"
        >
          {t('Back to Builder')}
        </StyledBackButton>
      </ChildHeaderContainer>
      <ChildCenterHeaderContainer>
        <TextEllipsisWithTooltip as="h2" type="header" weight="light">
          {isNew
            ? t('Configuring {{objectName}} Upload', customObject)
            : t('Editing {{objectName}} Upload', customObject)}
        </TextEllipsisWithTooltip>
      </ChildCenterHeaderContainer>
      <ChildHeaderContainer>
        <LoadingButton
          onClick={handleSaveInPlace}
          disabled={loading || refreshing || !isValid}
          loading={refreshing}
        >
          {isNew && step !== PRIMARY_OBJECT_UPLOAD_STEPS.preview
            ? t('Save & Continue')
            : t('Save')}
        </LoadingButton>
        <LoadingButton
          onClick={handleSaveWithClose}
          disabled={loading || refreshing || !isValid}
          loading={refreshing}
        >
          {t('Save & Close')}
        </LoadingButton>
      </ChildHeaderContainer>
    </HeaderContainer>
  );
};
