import { Suspense, useEffect, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import routes, { NotFound, history, shouldHideNavbarForPath } from 'routes';
import { serviceRehydrateStart } from 'store/authentication/authenticationAction';
import {
  isPseudo,
  changeLanguageInStorage,
  PSEUDO_LANGUAGE,
} from 'i18n-config';
import ErrorBoundaryResettable from 'components/Kizen/ErrorBoundaryResettable';
import Loader from 'components/Kizen/Loader';
import { snakeToCamelCaseKeys } from 'services/helpers';
import CatchError from 'components/CatchError/CatchError';
import AxiosErrorCatcher from 'components/CatchError/AxiosErrorCatcher';
import { Spacing } from './spacing';
import { Coloring } from './colors';
import { Typing } from './typography';
import { ModalBackdrop } from './modalBackdrop';
import MonitorSessionTimeout from './monitorSessionTimeout';
import { useIframeCheck } from 'hooks/useIframeCheck';
import { PermissionsError } from 'services/errors';
import { TestingMode } from 'components/Layout/TestingMode';
import { RouteWithTitle } from 'hooks/useSetTitleOnLoad';
import PageViewWrapper from './pageViewWrapper';
import DashboardAnalytics from './DashboardAnalytics';
import { maybeIdentifyUser } from 'utility/analytics';
import { useSkipAccessFetch } from 'hooks/useSkipAccessFetch';
import { NavBarProvider } from './navBarController';
import { useQuery } from 'react-query';
import { PAGE_CONFIGS } from 'queries/query-keys';
import TeamMemberService from 'services/TeamMemberService';
import { maybeInjectPermanentItems } from 'pages/Account/pages/Toolbar/hooks/useRightColumn';
import CustomNavigation from 'components/CustomNavigation';
import CustomNavigationWrapper from 'components/CustomNavigation/Wrapper';
import { serializeToKey } from 'pages/Account/pages/Toolbar/utils';
import useToolbarAccessFiltering from 'pages/Account/pages/Toolbar/hooks/useToolbarAccessFiltering';
import { useLocation } from 'react-use';
import { StyledLoader } from './styles';
import { priorityPaths } from 'routes/paths';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { getRoute } from 'components/CustomNavigation/util';
import { DataManagerSessionProvider } from './dataManagerSession';
import '@kizen/kds/css';
import { ScrollbarInteractionManager } from '@kizen/kds/Scrollbar';
import { TranslationContextProvider } from '@kizen/kds/TranslationContext';
import { MonitorDeploymentVersion } from './MonitorDeployedVersion';
import { FloatingWindow } from 'ts-components/Plugins/FloatingWindow';
import { v4 } from 'uuid';

const getUrlForItem = (item) => {
  const route = getRoute(item);

  if (route.path === '/') {
    return null;
  }

  if (item.isCustomObject) {
    return route.path;
  }

  if (item.isDefault) {
    return route.path;
  }

  // External routes cannot be used as a landing page
  if (route.external !== false) {
    return null;
  }

  return route.path;
};

export const getFirstInternalNavItem = (navItems) => {
  if (!navItems?.length) {
    return null;
  }

  for (const item of navItems) {
    // If there are children, we want the first eligible child (if any)
    if (item.children?.length) {
      for (const child of item.children) {
        const url = getUrlForItem(child);

        if (url) {
          return url;
        }
      }
    }

    // If there are no children, attempt to get the URL for the item itself
    const url = getUrlForItem(item);
    if (url) {
      return url;
    }
  }
};

const ACCESS_ANY = 'any';
const urlsSections = {
  ads: 'adsSection',
  automations: 'automationsSection',
  broadcasts: 'broadcastsSection',
  clients: 'contactsSection',
  'custom-objects': 'customObjectsSection',
  forms: 'formsSection',
  'message-library': 'messageLibrarySection',
  'marketing-costs': 'marketingCostsSection',
  account: [
    { name: 'myProfileSection', checkKey: 'view' },
    { name: 'apiConnectionsSection', checkKey: ACCESS_ANY },
  ],
  orders: 'ordersSection',
  products: 'productsSection',
  settings: 'settingsSection',
  surveys: 'surveysSection',
};

const App = ({ rehydrate, rehydrating }) => {
  const access = useSelector((s) => s.authentication.access);
  const user = useSelector((s) => s.authentication.user);
  const business = useSelector((s) => s.authentication.chosenBusiness);
  const isIframe = useIframeCheck();
  const skipAccessFetch = useSkipAccessFetch();
  const [pageLoadTs] = useState(() => new Date().toISOString());
  const [pageLoadId] = useState(() => v4());

  const clientObjectId = business?.client_object?.id;

  useEffect(() => {
    if (!isIframe) {
      maybeIdentifyUser(user?.profile, business);
    }
  }, [user, isIframe, business]);

  // Attempt to rehydrate authentication state
  // on first rendering of our root component
  useEffect(() => {
    if (isPseudo()) changeLanguageInStorage(PSEUDO_LANGUAGE);
    // When we are in an iframe, it is implied we are embedding a form
    // and authentication is not required.
    if (!isIframe) {
      rehydrate(skipAccessFetch);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const allRoutes = routes;
  const routeKeys = Object.keys(allRoutes);
  const filteredRouteKeys = routeKeys
    .filter((key) => !allRoutes[key].external)
    // Certain paths needs to be 'first' in the list to be matched
    .sort((a, b) => {
      if (priorityPaths.includes(a)) {
        return -1;
      }
      if (priorityPaths.includes(b)) {
        return 1;
      }
      return 0;
    });

  const routeComponents = filteredRouteKeys.map((routeKey, index) => {
    const route = allRoutes[routeKey];
    if (access) {
      // Some routes can have multiple paths, so we need to check the first one
      // for permissions in that case
      const [, mainPage, subPage] = (
        typeof route.path === 'string' ? route.path : route.path[0]
      ).split('/');

      // TODO need to create another solution how to handle permission by subpage
      const page = mainPage === 'commerce' ? subPage : mainPage;

      const accessSections = snakeToCamelCaseKeys(access.sections);

      let section;

      // need to check mutiple areas
      if (Array.isArray(urlsSections[page])) {
        section = urlsSections[page].reduce((acc, area) => {
          // any works as long as we have a true
          const hasAccess = (area.checkKey = ACCESS_ANY
            ? Object.values(accessSections[area.name]).includes(true)
            : accessSections[area.name]?.view);

          return acc || hasAccess ? { view: true } : undefined;
        }, undefined);
      } else {
        section = accessSections[urlsSections[page]];
      }

      if (section) {
        return (
          <RouteWithTitle
            key={index}
            path={route.path}
            exact={route.exact !== false}
            component={
              section.view
                ? route.component
                : () => {
                    throw new PermissionsError();
                  }
            }
            title={route.title}
          />
        );
      }
    }

    return (
      <RouteWithTitle
        key={index}
        path={route.path}
        exact={route.exact !== false}
        component={route.component}
        title={route.title}
      />
    );
  });

  const filterToolbar = useToolbarAccessFiltering();
  const loc = useLocation();
  const hideNav = shouldHideNavbarForPath(loc.pathname);
  const { t } = useTranslation();

  const toolbarEnabled =
    Boolean(user?.profile?.id) &&
    Boolean(business?.id) &&
    !isIframe &&
    !rehydrating &&
    !hideNav;

  const { data: unfilteredToolbarData, isLoading: toolbarLoading } = useQuery(
    [PAGE_CONFIGS.MY_TOOLBAR, user?.profile?.id, business?.id],
    async () => {
      const res = await TeamMemberService.getPageConfig('toolbar');
      return {
        ...res,
        elements: res?.elements ?? [],
      };
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: toolbarEnabled,
    }
  );

  const toolbarData = useMemo(() => {
    if (!toolbarEnabled || toolbarLoading) {
      return undefined;
    }

    return {
      ...unfilteredToolbarData,
      elements: filterToolbar(
        maybeInjectPermanentItems(
          unfilteredToolbarData?.elements ?? [],
          unfilteredToolbarData?.modified
        )
      ),
    };
  }, [filterToolbar, unfilteredToolbarData, toolbarEnabled, toolbarLoading]);

  const customLayoutKey = useMemo(
    () => serializeToKey(toolbarData?.elements ?? []),
    [toolbarData]
  );

  return (
    <>
      <ScrollbarInteractionManager />
      <MonitorDeploymentVersion history={history} />
      <Loader loading={rehydrating && !isIframe}>
        <Router history={history}>
          <PageViewWrapper pageLoadTs={pageLoadTs} pageLoadId={pageLoadId}>
            <DashboardAnalytics />
            {/* This provides translation context for KDS package*/}
            <TranslationContextProvider t={t}>
              <NavBarProvider>
                {/*
              We need this second loader here to ensure that we don't cause a
              re-render of the outer loading component when the toolbar loads
              for the first time
            */}
                <StyledLoader loading={toolbarLoading} />
                <Spacing />
                <Coloring />
                <Typing />
                <ModalBackdrop />

                {user && business?.id && !isIframe ? (
                  <MonitorSessionTimeout
                    key={`session-monitor-${business?.id}`}
                  />
                ) : null}

                <ErrorBoundaryResettable
                  FallbackComponent={CatchError}
                  initSentry
                >
                  <DataManagerSessionProvider>
                    {/* throw errors emerged from Axios */}
                    <AxiosErrorCatcher />
                    <CustomNavigationWrapper>
                      {(expanded) => (
                        <CustomNavigation
                          layout={toolbarData?.elements ?? []}
                          expanded={expanded}
                          key={customLayoutKey}
                          loading={toolbarLoading}
                        />
                      )}
                    </CustomNavigationWrapper>
                    <Switch>
                      {/* Removes trailing slashes */}
                      <Redirect
                        from="/:url*(/+)"
                        to={`${window.location.pathname.replace(/\/+$/, '')}${
                          window.location.search
                        }`}
                      />
                      {/* Navigation to "/" should trigger a redirect to the first eligible item in the toolbar */}
                      <Route
                        path="/"
                        exact
                        component={() => {
                          // Need to wait for data to load before redirecting, when user has only one business
                          if (toolbarLoading) {
                            return <Loader loading />;
                          }
                          const firstNavItem = getFirstInternalNavItem(
                            toolbarData?.elements
                          );
                          // Check if the firstNavItem is not equal to '/choose-business'
                          // to avoid circle redirect if user doesn't have any Role
                          const redirectPath =
                            firstNavItem !== '/choose-business' &&
                            firstNavItem !== null
                              ? firstNavItem
                              : '/welcome';
                          return <Redirect to={redirectPath} />;
                        }}
                      />

                      {/* These redirects are to allow for old links using the client object ID instead of the name */}
                      {clientObjectId ? (
                        <Redirect
                          from={`/custom-objects/${clientObjectId}`}
                          to="/clients"
                          exact
                        />
                      ) : null}
                      {clientObjectId ? (
                        <Redirect
                          from={`/custom-objects/${clientObjectId}/:id/details`}
                          to={`${window.location.pathname.replace(
                            `/custom-objects/${clientObjectId}/`,
                            '/client/'
                          )}${window.location.search}`}
                          exact
                        />
                      ) : null}

                      {routeComponents}
                      <Route
                        key={filteredRouteKeys.length}
                        path="*"
                        component={NotFound}
                      />
                    </Switch>
                  </DataManagerSessionProvider>
                </ErrorBoundaryResettable>
              </NavBarProvider>
            </TranslationContextProvider>
          </PageViewWrapper>
          <FloatingWindow />
        </Router>
        <Suspense fallback={<Loader loading />}>
          <TestingMode />
        </Suspense>
      </Loader>
    </>
  );
};

App.propTypes = {
  rehydrate: PropTypes.func.isRequired,
  rehydrating: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ authentication }) => ({
  rehydrating: authentication.rehydrating,
});

const mapDispatchToProps = (dispatch) => ({
  rehydrate: (skipAccessFetch = false) =>
    dispatch(serviceRehydrateStart(skipAccessFetch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
