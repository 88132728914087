import styled from '@emotion/styled';
import { layers, borderRadii, scrollbarCss } from 'app/spacing';

import { css } from '@emotion/core';

import { grayScale, shadowLight } from 'app/colors';

import {
  Gradient as BaseGradient,
  GradientFade,
} from 'components/Kizen/ScrollFadeContainer';

const NORMAL_TABLE_HEADER_HEIGHT = 50;
const COMPACT_TABLE_HEADER_HEIGHT = 42;
const SMALL_TABLE_HEADER_HEIGHT = 28;

export const TableScrollWrapper = styled.div`
  flex: 100%;
  &::-webkit-scrollbar:horizontal {
    display: none;
  }
  &::-webkit-scrollbar:vertical {
    display: none;
  }
  ${({ isTable, kdsCompatability }) =>
    // Only when isTable is explicitly false, do we want to leave
    // the scrollbars in their initial location.
    isTable !== false
      ? css`
          margin-right  ${kdsCompatability ? '-20px' : '-8px'};

          tr td:last-child,
          tr th:last-child {
            padding-right: 8px;
          }
        `
      : ''}

  ${scrollbarCss}
  overflow-y: scroll;
  overflow-x: auto;
  &::-webkit-scrollbar-track:vertical {
    // A hack for scrollbar to appear below sticky header.
    // Much better than the alternative hacks to detach/size/scroll the header,
    // especially since we already have a custom scrollbar.
    margin-top: calc(${NORMAL_TABLE_HEADER_HEIGHT}px - 1px);
    border-top: 1px solid ${grayScale.mediumLight};
  }
  ${({ compactHeader }) =>
    // This is often used alongside "tall" card toolbars
    compactHeader &&
    css`
      &::-webkit-scrollbar-track:vertical {
        margin-top: calc(${COMPACT_TABLE_HEADER_HEIGHT}px - 1px);
      }
    `}

  ${({ smallHeader }) =>
    // This is often used alongside "small" card toolbars
    smallHeader &&
    css`
      &::-webkit-scrollbar-track:vertical {
        margin-top: calc(${SMALL_TABLE_HEADER_HEIGHT}px - 1px);
      }
    `}

  ${({ rounded }) =>
    rounded
      ? css`
          border-top-left-radius: ${borderRadii.standard};
          border-top-right-radius: ${borderRadii.standard};
        `
      : ''}
`;

export const Gradient = styled(BaseGradient)`
  z-index: ${layers.content(
    0,
    2
  )}; // Appear on top of table head cells, on top of menus
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  & table {
    width: 100%;
  }

  ${({ headerShadow, outlineBorder }) =>
    headerShadow
      ? css`
          thead {
            position: sticky;
            top: 0;
            background: white;
            ${shadowLight};
            z-index: ${layers.content(0, 1)};
            ${outlineBorder
              ? css`
                  outline: 1px solid ${grayScale.mediumLight};
                `
              : ''}
          }
          .sticky-header-with-shadow {
            ${shadowLight};
          }
        `
      : ''}
`;

export const Container = styled.div`
  position: sticky;
  top: ${({ headHeight }) => headHeight}px;
  height: ${({ contentHeight }) => contentHeight}px;
  width: 8px;
  opacity: 1;
  z-index: 2;
  overflow-y: scroll;
  ${scrollbarCss}
  @supports (-moz-appearance: none) {
    scrollbar-width: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${grayScale.medium};
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: ${grayScale.mediumDark};
  }
  &:hover::-webkit-scrollbar {
    width: 8px;
    height: 0px;
  }

  ${({ border, headHeight }) =>
    border
      ? css`
          border-top: 1px solid ${grayScale.mediumLight};
          top: ${headHeight - 1}px;
        `
      : ''}
`;

export const Child = styled.div`
  width: 4px;
  height: ${({ contentHeight }) => contentHeight}px;
`;

export const ScrollbarContentWrapperVertical = styled.div`
  position: sticky;
  overflow-y: auto;
  width: 9px;
  flex: 9px;
  ${scrollbarCss}
  @supports (-moz-appearance: none) {
    scrollbar-width: none;
  }
  ${({ zIndex }) =>
    zIndex
      ? css`
          &&& {
            z-index: ${zIndex};
          }
        `
      : null};

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${grayScale.medium};
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: ${grayScale.mediumDark};
  }
  &:hover::-webkit-scrollbar {
    width: 8px;
    height: 0px;
  }
  &::-webkit-scrollbar-track:vertical {
    // A hack for scrollbar to appear below sticky header.
    // Much better than the alternative hacks to detach/size/scroll the header,
    // especially since we already have a custom scrollbar.
    margin-top: calc(${NORMAL_TABLE_HEADER_HEIGHT}px - 1px);
    border-top: 1px solid ${grayScale.mediumLight};
  }
  ${({ noHeader }) =>
    noHeader &&
    css`
      &::-webkit-scrollbar-track:vertical {
        margin-top: 0;
      }
    `}
  ${({ smallHeader }) =>
    // This is often used alongside "small" card toolbars
    smallHeader &&
    css`
      &::-webkit-scrollbar-track:vertical {
        margin-top: calc(${SMALL_TABLE_HEADER_HEIGHT}px - 1px);
      }
    `}
  ${({ compactHeader, outlineBorder }) =>
    // This is often used alongside "tall" card toolbars
    compactHeader &&
    css`
      &::-webkit-scrollbar-track:vertical {
        margin-top: calc(
          ${COMPACT_TABLE_HEADER_HEIGHT}px - ${outlineBorder ? 0 : 1}px
        );
      }
    `}
  ${({ spaceScrollBarWithMargin, compactHeader }) =>
    spaceScrollBarWithMargin &&
    css`
      &::-webkit-scrollbar-track:vertical {
        margin-top: 0;
      }
      margin-top: calc(
        ${compactHeader
            ? COMPACT_TABLE_HEADER_HEIGHT
            : NORMAL_TABLE_HEADER_HEIGHT}px - 1px
      );
    `}
`;

export const ScrollbarContentWrapper = styled.div`
  z-index: ${({ zIndex }) => zIndex || 'inherit'};
  position: sticky;
  bottom: 0px;
  flex: 1;
  overflow-x: auto;
  min-height: 8px;
  ${scrollbarCss()}
  @supports (-moz-appearance: none) {
    scrollbar-width: none;
    min-height: 0;
  }
  &::-webkit-scrollbar-thumb {
    margin-top: 4px;
    width: 2.4px;
    border-radius: 8px;
    background-color: ${grayScale.medium};
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: ${grayScale.mediumDark};
  }
  &:hover::-webkit-scrollbar {
    height: 8px;
  }
  > div {
    // We do need some height here in order for any scrollbars to show,
    // but we don't really want to take-up any space. It seems smaller
    // than .25px doesn't take-up space, at least on chrome.
    height: 0.1px;
  }
`;
export const HorizontalScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  ${({ fitParentWidth }) => (fitParentWidth ? 'width: 100%;' : '')}
`;

export const VerticalScrollWrapper = styled.div`
  display: flex;
  flex: 100%;
  flex-direction: row;
  overflow: auto;
`;

//======================================================================
// v2 specific styles
export const TableScrollBucket = styled.div`
  display: flex;
  overflow: auto;
  flex: 1;
  position: relative;
`;

export const SafeGradientWrapper = styled.div`
  width: 50px;
  position: absolute;
  top: 0;
  ${({ position }) => css`
    ${position}: 0;
  `}
  bottom: 0;
  z-index: 1;
`;

export const SafeGradient = ({ active, position, show }) =>
  active && show ? (
    <SafeGradientWrapper position={position} className="gradientWrapper">
      <GradientFade
        color="#ffffff"
        position={position}
        fadeStart={0}
        fadeLength={50}
      />
    </SafeGradientWrapper>
  ) : null;

export const ScrollContainerBlocker = styled.div`
  position: absolute;
  border-top-right-radius: ${borderRadii.standard};
  right: 0;

  ${({
    top = 0,
    bottom = false,
    compactHeader = false,
    zIndex = 2,
    width = 8,
    opacity = 1.0,
  }) => css`
    width: ${width}px;
    ${bottom
      ? css`
          bottom: -${compactHeader ? COMPACT_TABLE_HEADER_HEIGHT - 1 : NORMAL_TABLE_HEADER_HEIGHT - 1}px;
        `
      : css`
          top: ${top}px;
        `}
    height: ${compactHeader
      ? COMPACT_TABLE_HEADER_HEIGHT - 1
      : NORMAL_TABLE_HEADER_HEIGHT - 1}px;
    z-index: ${zIndex};
    opacity: ${opacity};
  `}
`;

export const ScrollContainerBlockerWrapper = styled.div`
  position: relative;
`;

export const TableCardScrollContainerBlockerWrapper = styled.div`
  border-radius: ${borderRadii.standard};
  overflow: hidden;
`;
export const CARDTOOLBAR_HEIGHT = 43;
