import {
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  forwardRef,
} from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colorsText, grayScale } from 'app/colors';
import { fontSizes, TextSpan, UtilityLink } from 'app/typography';
import { enrichEl } from 'components/helpers';
import ListItem from 'components/Kizen/List/ListItem';
import ListSubheader from 'components/Kizen/List/ListSubheader';
import BaseMenu, { MenuList as BaseMenuList } from 'components/Kizen/Menu';
import StylePassthrough from 'components/Kizen/StylePassthrough';
import { TextEllipsis, TextEllipsisWithTooltip } from 'components/Kizen/Table';
import { components } from 'react-select';
import { Dot } from 'components/Fields/ColorLabel';
import { layers } from 'app/spacing';
import { FIELD_TYPES } from 'utility/constants';
import IconAdornment from '../Adornments/IconAdornment';
import BaseInput from '../TextInput/BaseInput';
import { OutlineInputLayout } from '../TextInput/Outline';
import { UnderlineInputLayout } from '../TextInput/Underline';
import { applyRef } from '../props';
import { ClipLoader } from 'react-spinners';

const LOADER_SIZE = 15;

export const IndicatorsContainer = forwardRef(
  ({ children, ...others }, ref) => {
    const { isLoading } = others.selectProps;

    return (
      <components.IndicatorsContainer {...others} ref={ref}>
        {isLoading ? (
          <div data-qa-icon-name="clip-loader">
            <ClipLoader
              loading
              size={LOADER_SIZE}
              color={grayScale.mediumDark}
            />
          </div>
        ) : (
          children
        )}
      </components.IndicatorsContainer>
    );
  }
);

// If we render the menu in a portal, the styles don't get inherited from the parent anymore
// and they need to be specifically applied to the menu that's in the portal
const StyledMenuPortal = styled.div`
  & .${({ classNamePrefix }) => classNamePrefix}__menu {
    border: 1px solid ${grayScale.medium};
    border-radius: 4px;
    min-width: 300px;
  }
`;

// Styling for the menu if it's rendered in a portal instead of inline. This component won't have
// an effect unless the menu is portaled, so it's safe to always pass to react-select
export const MenuPortal = ({ children, ...rest }) => {
  const classNamePrefix = rest.selectProps?.classNamePrefix ?? '';

  return (
    <components.MenuPortal {...rest}>
      <StyledMenuPortal
        classNamePrefix={classNamePrefix}
        data-field-type-menu-body={
          rest.selectProps?.fieldType || rest.selectProps?.['data-field-type']
        }
      >
        {children}
      </StyledMenuPortal>
    </components.MenuPortal>
  );
};

const controlCss = ({ menuIsOpen, placement, isReadOnly }) => css`
  min-height: 0;
  box-shadow: none;
  transition: none;
  // Using type here to skip over Dot if it is present
  > div:first-of-type {
    margin-left: -2px;
    padding: 0;
    // Maintain height when isSearchable is false and input is hidden
    line-height: ${fontSizes.text};
  }
  ${menuIsOpen &&
  placement !== 'top' &&
  css`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
  ${menuIsOpen &&
  placement === 'top' &&
  css`
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `}
  ${isReadOnly &&
  css`
    && {
      border-color: ${grayScale.medium};
    }
  `}
`;

const OutlineControlStyles = styled(OutlineInputLayout)`
  ${controlCss}
`;

const UnderlineControlStyles = styled(UnderlineInputLayout)`
  border-radius: 0;
  ${controlCss}
`;

const TextEllipsisWithTooltipUnset = styled(TextEllipsisWithTooltip)`
  // Relative fontsizing was leading to incorrectly enlarged font size
  font-size: unset;
  color: unset; // ensures the current selection is a different (lighter) color in the dropdown
`;

const RecordsCount = styled.i`
  font-size: inherit;
  color: inherit;
  padding-right: 1px; // ensure parent's text overflow does not cut content
`;

const OptionTextEllipsisWithTooltipUnset = styled(TextEllipsisWithTooltipUnset)`
  line-height: 32px;
  font-size: ${fontSizes.text};
`;

const HideControl = styled.div`
  width: 0;
  height: 0;
  overflow: hidden;

  & > div:first-child {
    width: 0;
    height: 0;
    border-width: 0;
    box-shadow: none;
  }
`;

export const Control = (props) => {
  const {
    isFocused,
    isDisabled,
    menuIsOpen,
    getValue,
    isMulti,
    selectProps: {
      isColored,
      isReadOnly,
      inputValue,
      variant,
      error,
      menuOnly,
      computedMenuPlacement,
    },
    children,
  } = props;
  if (menuOnly) {
    // For the focus to work correctly when switching with the arrow keys, we must save the control component
    return (
      <HideControl>
        <components.Control {...props} />
      </HideControl>
    );
  }
  const VariantControlStyles =
    variant === 'underline' ? UnderlineControlStyles : OutlineControlStyles;
  const [value] = [].concat(getValue() || []); // getValue() seems to be an array. Playing it safe.
  return (
    <VariantControlStyles
      as={StylePassthrough}
      focused={isFocused}
      disabled={isDisabled}
      error={error}
      menuIsOpen={menuIsOpen}
      isReadOnly={isReadOnly}
      placement={computedMenuPlacement}
    >
      <components.Control {...props}>
        {isColored && !isMulti ? (
          <Dot color={!inputValue && value && value.color} />
        ) : null}
        {children}
      </components.Control>
    </VariantControlStyles>
  );
};

export const IndicatorSeparator = () => null;

export const DropdownIndicator = (props) => {
  const {
    className,
    isDisabled,
    selectProps: { variant, endAdornment, title },
  } = props;
  if (variant === 'underline' && isDisabled) {
    return null;
  }
  return cloneElement(endAdornment || <IconAdornment icon="chevron-down" />, {
    variant,
    end: 'true',
    className,
    title,
  });
};

const InputStyles = styled(BaseInput)``;

export const Input = (props) => {
  return (
    <InputStyles as={StylePassthrough}>
      <components.Input {...props} />
    </InputStyles>
  );
};

const SingleValueStyles = styled(TextSpan)`
  max-width: 100%;
  &&.withEclipse {
    height: 14px;
  }
`;

export const SingleValue = (props) => {
  return (
    <SingleValueStyles as={StylePassthrough}>
      <components.SingleValue {...props} />
    </SingleValueStyles>
  );
};

export const SingleValueEllipse = ({ children, ...props }) => {
  return (
    <SingleValue className="withEclipse" {...props}>
      <TextEllipsisWithTooltipUnset>{children}</TextEllipsisWithTooltipUnset>
    </SingleValue>
  );
};

// This is can be used to build SingleValues that appear actionable
export const SingleValueLink = ({
  href = null,
  to = null,
  onClick = null,
  onMouseDown = null,
  as = null,
  target = null,
  rel = null,
  children,
  ...props
}) => {
  return (
    <SingleValue {...props}>
      <TextEllipsisWithTooltipUnset>
        <UtilityLink
          as={as || (!href && 'span') || undefined}
          size="inherit"
          to={to}
          href={href}
          target={target}
          rel={rel}
          onClick={onClick}
          onMouseDown={(ev) => {
            ev.stopPropagation(); // Avoid opening menu with click
            if (onMouseDown) onMouseDown(ev);
          }}
        >
          {children}
        </UtilityLink>
      </TextEllipsisWithTooltipUnset>
    </SingleValue>
  );
};

export const Placeholder = (props) => {
  const {
    isDisabled,
    selectProps: { variant },
    textColor,
  } = props;
  if (variant === 'underline' && isDisabled) {
    return (
      <TextSpan color={textColor || colorsText.dark} as={StylePassthrough}>
        <components.Placeholder {...props}>—</components.Placeholder>
      </TextSpan>
    );
  }
  return (
    <TextEllipsis color={textColor || colorsText.medium} as={StylePassthrough}>
      <components.Placeholder {...props} />
    </TextEllipsis>
  );
};

// This is can be used to build Placeholders that appear actionable
export const PlaceholderLink = ({
  href = null,
  to = null,
  onClick = null,
  as = null,
  children,
  ...props
}) => {
  return (
    <Placeholder {...props}>
      <UtilityLink
        as={as || (!href && 'span') || undefined}
        size="inherit"
        to={to}
        href={href}
        onClick={onClick}
      >
        {children}
      </UtilityLink>
    </Placeholder>
  );
};

const StyledMenu = styled(BaseMenu)`
  position: absolute;
  width: 100%;
  // Needs to open above radio buttons and checkboxes, which have z-index of 1
  ${({ zIndexAdjust }) =>
    zIndexAdjust
      ? css`
          z-index: ${zIndexAdjust};
        `
      : css`
          z-index: ${layers.content(0, 2)};
        `}

  ${({ maxHeight }) => css`
    max-height: ${maxHeight || 350}px;
  `}
  ${({ placement }) =>
    placement === 'top'
      ? css`
          bottom: 100%;
        `
      : css`
          top: 100%;
        `}
  ${({ inline }) =>
    // Do not set top/bottom (as above) when inline
    inline &&
    css`
      top: auto;
      bottom: auto;
    `}
`;

export const Menu = (props) => {
  const {
    placement: menuPlacement,
    children,
    className,
    cx,
    innerRef,
    innerProps,
    selectProps: {
      menuOnly,
      menuInline,
      menuTopButton,
      menuLeftButton,
      menuRightButton,
      menuPlacement: rawMenuPlacement,
      setComputedMenuPlacement,
      closeOnClearClick,
    },
    zIndexAdjust,
  } = props;
  useEffect(() => {
    // This is computed specifically for Menu, but we want Control to have access too
    setComputedMenuPlacement(menuPlacement);
  }, [setComputedMenuPlacement, menuPlacement]);
  const placement = menuPlacement === 'top' ? 'top' : 'bottom';

  // react-select has an issue with onClick events not being fired on touch devices
  // so we not allow touch events to bubble up
  const touchEndHandler = (event) => {
    if (closeOnClearClick === false) {
      event.stopPropagation();
    }
  };

  return (
    <StyledMenu
      // The ref is used for react-select's menu placement. We only want to respect this when explicitly set to "auto."
      ref={rawMenuPlacement === 'auto' ? innerRef : null}
      className={cx({ menu: true }, className)}
      placement={menuOnly ? null : placement}
      inline={menuInline}
      // Allow click handlers to access props, e.g. to clear value
      topButton={enrichEl(menuTopButton, { select: props })}
      leftButton={enrichEl(menuLeftButton, { select: props })}
      rightButton={enrichEl(menuRightButton, { select: props })}
      zIndexAdjust={zIndexAdjust}
      {...innerProps}
      onTouchEnd={touchEndHandler}
    >
      {children}
    </StyledMenu>
  );
};

export const MenuList = ({ children, innerRef }) => {
  return <BaseMenuList ref={innerRef}>{children}</BaseMenuList>;
};

export const Option = (props) => {
  const { isSelected, isFocused, isDisabled, data, selectProps, children } =
    props;
  const { isColored, onMountOption, inputValue, selectedTab } = selectProps;
  const itemWrapper = useRef(null);
  const needUpdateRef = useRef(false);

  useEffect(() => {
    needUpdateRef.current = false;
  }, [inputValue, selectedTab]);

  useEffect(() => {
    const wrapper = itemWrapper.current;
    if (wrapper && !needUpdateRef.current && onMountOption) {
      needUpdateRef.current = true;
      onMountOption({ id: data.value }, wrapper.getBoundingClientRect());
    }
  }, [data.value, onMountOption]);

  if (data.groupPlaceholder) {
    return null;
  }

  return (
    <ListItem
      ref={itemWrapper}
      selected={isSelected}
      focused={isFocused}
      disabled={isDisabled}
      data-qa={data?.value}
    >
      <components.Option {...props} getStyles={() => {}}>
        <OptionTextEllipsisWithTooltipUnset>
          {isColored && <Dot color={data.color} />}
          {children}
          {data.recordsCount ? (
            <RecordsCount>{data.recordsCount}</RecordsCount>
          ) : null}
        </OptionTextEllipsisWithTooltipUnset>
      </components.Option>
    </ListItem>
  );
};

export const GroupHeading = ListSubheader;

export const Group = ({ Heading, headingProps, label, children }) => (
  <>
    <Heading {...headingProps}>{label}</Heading>
    {children}
  </>
);

export const NoOptionsMessage = ({ children, ...props }) => {
  return (
    <components.NoOptionsMessage {...props}>
      <TextSpan>{children}</TextSpan>
    </components.NoOptionsMessage>
  );
};

export const LoadingMessage = NoOptionsMessage;

export const needsStyleFn = (fieldType) =>
  [
    FIELD_TYPES.Choices.type,
    FIELD_TYPES.Dropdown.type,
    FIELD_TYPES.Status.type,
    FIELD_TYPES.PhoneNumber.type,
  ].includes(fieldType);

export const applyStyles = (styles) => {
  const newStyles = Object.entries(styles).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: (provided) => ({ ...provided, ...value }),
    }),
    {}
  );
  return newStyles;
};

const floorFiveteen = (value) => Math.floor(value / 15) * 15 || '00';
const isValueValid = (value) =>
  typeof value === 'string' && value.match(/\d\d:\d\d/);

export const TrackingMenuList = ({
  children,
  getValue,
  options,
  selectProps,
  innerRef,
}) => {
  let matchValue = selectProps?.initValue || '12:00';
  const SPLITTER_CHAR = ':';
  const [value] = [].concat(getValue() || []);
  const menuRef = useRef();
  if (isValueValid(value?.value)) {
    const splitValue = value.value.split(SPLITTER_CHAR);
    splitValue[1] = floorFiveteen(splitValue[1]);
    matchValue = splitValue.join(SPLITTER_CHAR);
  }

  const mergeRef = useCallback(
    (el) => {
      applyRef(innerRef, el);
      applyRef(menuRef, el);
    },
    [innerRef]
  );

  useEffect(() => {
    const index = Math.max(
      0,
      options.findIndex((opt) => opt.value === matchValue)
    );
    const children = menuRef?.current?.childNodes;
    if (children) {
      menuRef.current.scrollTop = Math.max(0, children[index]?.offsetTop);
    }
  }, [matchValue, options]);
  return <BaseMenuList ref={mergeRef}>{children}</BaseMenuList>;
};
