import { FC } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, TFunction } from 'react-i18next';

import { TextEllipsisWithTooltip } from '__components/Kizen/Table';
import {
  SizedCell as KizenSizedCell,
  SortableHeadCell as KizenSortableHeadCell,
} from '__components/Kizen/Table/CellLineHeightStyles';

import { Text } from 'app/typography';
import styled from '@emotion/styled';

import BaseActionCell, {
  ActionHeadCell as BaseActionHeadCell,
} from '__components/Tables/ActionCell';
import { FlexDiv, StatusCell } from './styles';
import { usePreReleaseFeatures } from 'hooks/usePreReleaseFeatures';

import { EmailStatus, Provider } from './types';
import SharingSettingsCell from '__components/AccessRequests/Tables/Cells/SharingSettingsCell';
import { getIsEditable } from '__components/AccessRequests/utils';

import { getEmailStatus } from './helpers';
import { getIsDeletable } from '__components/AccessRequests/utils';

// Small alignment tweak, esp to align correctly with action button
const SizedCell = styled(KizenSizedCell)`
  > div {
    margin-top: 1px; // hack to force the th to 50px so the bottom border shows
  }
`;

// Small alignment tweak, esp to align correctly with action button
const SortableHeadCell = styled(KizenSortableHeadCell)`
  > div {
    margin-top: 1px; // hack to force the th to 50px so the bottom border shows
  }
`;

// Small alignment tweak, esp to align correctly with action button
const ActionHeadCell = styled(BaseActionHeadCell)`
  > div {
    line-height: 0;
    margin-top: 1px; // hack to force the th to 50px so the bottom border shows
  }
`;

type ActionCell = {
  data?: any;
  access: any;
  onSelectAction: any;
  menuType: string;
  title: string;
};

const ActionCell: FC<ActionCell> = ({
  data,
  access,
  onSelectAction,
  ...others
}) => {
  const { t } = useTranslation();
  const preReleaseFeatures = usePreReleaseFeatures();
  const showBlock = (blocked: boolean) =>
    preReleaseFeatures && access.privacy.edit && blocked;

  const canDelete =
    access.integratedInboxes.remove || getIsDeletable(data.employee_access);

  return (
    <BaseActionCell
      data={data}
      onSelectAction={onSelectAction}
      options={[
        canDelete && {
          value: 'delete',
          label: t('Delete'),
        },
        getIsEditable(data.employee_access) && {
          value: 'edit',
          label: t('Edit'),
        },
        showBlock(!data.blocked) && {
          value: 'block',
          label: t('Block Outgoing Emails'),
        },
        showBlock(data.blocked) && {
          value: 'unblock',
          label: t('Unblock Outgoing Emails'),
        },
      ].filter(Boolean)}
      {...others}
    />
  );
};

ActionCell.propTypes = {
  data: PropTypes.object.isRequired,
};

const columnWidths = (width: string) => ({
  width,
  maxWidth: width,
  minWidth: width,
});

const getColumnSizings = (hasActions: boolean) => ({
  leftSpacer: { width: '20px' },
  email: { ...columnWidths(hasActions ? '140px' : '192px'), padding: '40px' },
  provider: { ...columnWidths('74px'), padding: '40px' },
  email_status: {
    ...columnWidths('70px'),
    padding: hasActions ? '40px' : '0px',
  },
  sharing_stats: { ...columnWidths('140px'), padding: '40px' },
  actions: { ...columnWidths('60px'), padding: '10px' },
});

type GetColoumns = {
  onSelectAction: any;
  t: TFunction;
  access: any;
  showEdit: (id: string) => void;
};

export const getColumns = ({
  onSelectAction,
  t,
  access,
  showEdit,
}: GetColoumns) => {
  const hasActions = access.privacy.edit;
  const columnSizings = getColumnSizings(hasActions);
  return [
    {
      id: 'spacer',
      headCell: <SizedCell {...columnSizings.leftSpacer} />,
      cell: <SizedCell {...columnSizings.leftSpacer} />,
    },
    {
      id: 'email',
      headCell: (
        <SortableHeadCell
          by="email"
          label={t('Integrated Email')}
          {...columnSizings.email}
        />
      ),
      cell: <SizedCell {...columnSizings.email} />,
      format: (integratedEmail: string) => (
        <TextEllipsisWithTooltip>{integratedEmail}</TextEllipsisWithTooltip>
      ),
    },
    {
      id: 'provider',
      headCell: (
        <SortableHeadCell label={t('Provider')} {...columnSizings.provider} />
      ),
      cell: <SizedCell {...columnSizings.provider} />,
      format: (value: Provider) => (
        <Text>{value === 'google' ? t('Gmail') : t('Outlook')}</Text>
      ),
    },

    {
      id: 'email_status',
      headCell: (
        <SortableHeadCell label={t('Status')} {...columnSizings.email_status} />
      ),
      cell: <SizedCell {...columnSizings.email_status} />,
      format: (email_status: EmailStatus) => (
        <StatusCell statusCode={email_status}>
          {getEmailStatus(email_status, t)}
        </StatusCell>
      ),
    },
    {
      id: 'sharing_stats',
      headCell: (
        <SortableHeadCell
          by="sharing_stats"
          label={t('Sharing Settings')}
          {...columnSizings.sharing_stats}
        />
      ),
      cell: <SizedCell {...columnSizings.sharing_stats} />,
      format: (_x: any, data: any) => {
        return (
          <FlexDiv>
            <SharingSettingsCell
              onClickEdit={() => showEdit(data.id)}
              access={data.employee_access}
              data={data}
              hideRoles={['Edit']}
            />
          </FlexDiv>
        );
      },
    },

    hasActions && {
      id: 'actions',
      headCell: (
        <ActionHeadCell label={t('Actions')} {...columnSizings.actions} />
      ),
      cell: (
        <ActionCell
          title={t('Edit Integrated Email')}
          {...columnSizings.actions}
          onSelectAction={onSelectAction}
          access={access}
          menuType={'observable'}
        />
      ),
    },
  ].filter(Boolean);
};
