import TeamMemberService from 'services/TeamMemberService';
import { setDefaultAssignment } from 'hooks/activities/useGetActivityPredefined';
import { defaultColumnSettings } from './constants';

export const getRelatedParam = (id, fieldId) => {
  if (fieldId) {
    return `associated_custom_field_${fieldId}`;
  }
  return `associated_custom_object_${id}`;
};

export const getRelatedFieldParam = (id) => `associated_custom_field_${id}`;

export const whatForPayload = (whatFor) =>
  whatFor.type === 'Contacts'
    ? { clientId: whatFor.clientId }
    : { entityId: whatFor.entityId, objectId: whatFor.objectId };

export const mapRelatedColumns = (columns) =>
  columns
    .filter(({ objectName }) => objectName !== 'Contacts') // filter out contact as it part of the default columns
    .map(({ objectName, id, fetchUrl }, index) => ({
      id,
      label: objectName,
      order: 1000000 + index, // make sure by default the realated items end up at the end of the columns list
      visible: false,
      related: true,
      fetchUrl,
    }))
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

export const mapColumnSettings = (savedColumnSettings, relatedObjects, t) => {
  if (!savedColumnSettings || !relatedObjects) {
    const columns = defaultColumnSettings
      .map((el) => ({
        ...el,
        label: el.localizedLabel(t),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return {
      columns,
      sort: {
        column: 'due_datetime',
        direction: 'asc',
      },
    };
  }
  const collectId = (collect, { id }) => ({
    ...collect,
    [id]: true,
  });
  const savedColumnIds = savedColumnSettings?.reduce(collectId, {}) ?? {};
  const defaultColumns = defaultColumnSettings;
  const defaultColumnIds = defaultColumns.reduce(collectId, {});
  const columns = [
    ...savedColumnSettings,
    ...defaultColumns
      .filter(({ id }) => !savedColumnIds[id])
      .map((defaultColumn) => ({
        ...defaultColumn,
        visible: false,
      })),
    ...relatedObjects.filter(
      ({ id }) => !(defaultColumnIds[id] || savedColumnIds[id])
    ),
  ]
    .map((el) => {
      const defaultItem = defaultColumnSettings.find((e) => e.id === el.id);
      if (!defaultItem) {
        const ro = relatedObjects.find(({ id }) => id === el.id);
        return {
          ...el,
          ...(ro && { label: el.label ? el.label : ro.label }),
        };
      }
      return {
        ...el,
        label: el.label ? el.label : defaultItem.localizedLabel(t),
      };
    })
    .filter(({ id }) => !['dueDateBT', 'dueDateMT', 'dueDate'].includes(id));
  const { sort } = savedColumnSettings;
  return { columns, sort };
};

export const mapContactColumn = (columns) => {
  const contact = columns.find(({ objectName }) => objectName === 'Contacts'); // find the contact

  return contact ? getRelatedParam(contact.id) : 'Contacts';
};

export const mapFetchUrls = (colums) =>
  colums.reduce((acc, { id, fetchUrl }) => ({ ...acc, [id]: fetchUrl }), {});

export const makeDefaultAssignment = (associatedEntities, fetchUrlList) => {
  const defaultAssignment = associatedEntities.reduce(
    (acc, { customObjectId, entityId, displayName }) => {
      const fetchUrl = fetchUrlList[customObjectId];
      const assignment = setDefaultAssignment({
        key: customObjectId,
        value: entityId,
        label: displayName,
        modelFetchUrl: fetchUrl,
      });
      return { ...acc, ...{ ...assignment } };
    },
    {}
  );

  return defaultAssignment;
};

export const getOrderingParam = ({ column = '', direction = '' } = {}) => {
  const columnToField = {
    scheduled: 'scheduled',
    activityType: 'activity_object',
    assignedTo: 'team_member',
    deal: 'deal',
    company: 'company',
    client: '',
  };

  const defaultValue = '-due_datetime';
  const value = `${direction === 'desc' ? '-' : ''}${
    columnToField[column] || column
  }`;
  return value ? value : defaultValue;
};

export const fetchConfig = async () => {
  const res = await TeamMemberService.getActivitiesListPageConfig();

  return res;
};

export const getNextPageFromNextParams = (next) => {
  if (!next) {
    return null;
  }

  const url = new URL(next);
  const params = new URLSearchParams(url.search);
  return params.get('page');
};

export const getQueryKeyWithoutCriteria = (queryKey) => {
  const indexOfCriteraKey = queryKey.indexOf('criteria');
  return queryKey.splice(0, indexOfCriteraKey);
};
