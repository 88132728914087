import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useBreakpoint } from 'app/spacing';
import BasicTable from 'components/Tables/Basic';
import { TableRow } from 'components/Tables/Query/TableRow';
import { useInfinityScroll } from 'hooks/useInfinityScroll';
import { TRow } from 'components/Kizen/Table';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Kizen/Loader';
import { getSmallColumns } from './columns';
import { StyledLoader, StyledTableScrollContainer, TableCard } from './styles';
import {
  getColumnsFromConfig,
  HEADER_HEIGHT,
  ITEMS_BUFFER,
  ROW_HEIGHT,
} from './util';
import useInfiniteQueryForTable from './hooks/useInfiniteQueryForTable';
import useMetadataForTable from './hooks/useMetadataForTable';
import { throttle } from 'lodash';
import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';

const TableDashlet = ({
  dashlet,
  search: overallSearch,
  dashletSearch,
  model,
  contacts,
  filtersForCharts,
  onChangeColumnWidths,
  onChangeSort,
  onInfiniteQueryLoading,
  canEdit,
  persistentState,
}) => {
  const { t } = useTranslation();
  const isMobile = useBreakpoint();
  const objectId = model.id;

  const {
    fieldsById,
    fields,
    loading = true,
  } = useMetadataForTable({
    objectId,
    contacts,
  });

  const [columnWidths, setColumnWidths] = useState(
    dashlet?.config?.feExtraInfo?.columnWidths ?? {}
  );

  const throttleSetColumnWidths = useMemo(() => {
    return throttle((...args) => setColumnWidths(...args), 100);
  }, []);

  const onResizeColumnWidth = useCallback(
    ({ id, width }) => {
      throttleSetColumnWidths((prev) => ({ ...prev, [id]: width }));
    },
    [throttleSetColumnWidths]
  );

  const getColumnWidth = useCallback(
    (id) => {
      return columnWidths[id] ?? '200px';
    },
    [columnWidths]
  );

  const onResizeStop = useCallback(() => {
    onChangeColumnWidths?.(columnWidths);
  }, [columnWidths, onChangeColumnWidths]);

  const columns = useMemo(() => {
    const internalColumns = getSmallColumns({
      columns: getColumnsFromConfig(dashlet, fieldsById),
      model,
      fieldsById,
      fields,
      t,
      contacts,
      onResizeColumnWidth,
      onResizeStop,
      getColumnWidth,
      resizable: canEdit,
    });

    return internalColumns;
  }, [
    dashlet,
    model,
    fieldsById,
    t,
    contacts,
    fields,
    onResizeColumnWidth,
    onResizeStop,
    getColumnWidth,
    canEdit,
  ]);

  const [sort, setSort] = useState(persistentState);

  const initialSort = useMemo(() => {
    const firstColumn = columns[0];
    if (!firstColumn) {
      return {};
    }

    const firstField = fieldsById[firstColumn.id];
    if (!firstField) {
      return {};
    }

    return {
      column: firstColumn.id,
      direction: 'asc',
      name: firstField.name,
      isDefault: firstField.isDefault,
    };
  }, [columns, fieldsById]);

  const fieldIds = getColumnsFromConfig(dashlet, fieldsById)
    .filter((f) => f.name !== 'full_name')
    .map((f) => f.id);

  const searchWithinFieldIds = getColumnsFromConfig(dashlet, fieldsById)
    .map((f) => f.id)
    .reduce((acc, id) => {
      if (id === 'fullName') {
        const { firstName, lastName } = model?.undeletableFields ?? {};
        acc.push(firstName.id, lastName.id);
        return acc;
      }
      acc.push(id);
      return acc;
    }, []);

  const search = useMemo(() => {
    if (overallSearch && dashletSearch) {
      return `${overallSearch} ${dashletSearch}`;
    } else if (dashletSearch) {
      return dashletSearch;
    }
    return overallSearch;
  }, [overallSearch, dashletSearch]);

  const infiniteQuery = useInfiniteQueryForTable({
    objectId,
    search,
    dashlet,
    filtersForCharts,
    contacts,
    model,
    sort: sort ?? initialSort,
    fieldIds,
    searchWithinFieldIds: isMobile ? [] : searchWithinFieldIds,
    enable: fieldIds.length >= 0 && loading === false,
  });

  useEffect(() => {
    onInfiniteQueryLoading(
      infiniteQuery.isFetchingNextPage || infiniteQuery.isFetching
    );
  }, [
    infiniteQuery.isFetchingNextPage,
    infiniteQuery.isFetching,
    onInfiniteQueryLoading,
  ]);

  const wrapperRef = useRef(null);

  const tableScroll = useInfinityScroll({
    wrapperElement: wrapperRef.current,
    data: infiniteQuery.data,
    fetchNextPage: infiniteQuery.fetchNextPage,
    hasNextPage: infiniteQuery.hasNextPage,
    isFetchingNextPage: infiniteQuery.isFetchingNextPage,
    defaultHeight: ROW_HEIGHT,
    itemsBuffer: ITEMS_BUFFER,
    shouldFetchOnMount: true,
    headerHeight: HEADER_HEIGHT,
    component: 'table',
  });
  const { visibleItems, handleItemMounted } = tableScroll;

  const messageHeadData = useMemo(
    () => ({
      meta: {
        sort: sort ?? initialSort,
        onSort: (column, direction) => {
          const field = fieldsById[column];
          setSort({
            column,
            direction,
            name: field.name,
            id: field.id,
            isDefault: field.isDefault,
          });
          onChangeSort({
            column,
            direction,
            name: field.name,
            id: field.id,
            isDefault: field.isDefault,
          });
        },
      },
    }),
    [sort, fieldsById, initialSort, onChangeSort]
  );

  if (loading) {
    return <Loader loading />;
  }

  return (
    <TableCard>
      <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
        <ScrollContainerBlocker top="-1" zIndex="4" width="9" />
      </ScrollContainerBlockerWrapper>
      <StyledTableScrollContainer
        className="StyledTableScrollContainer"
        ref={wrapperRef}
        left // doesn't play well with useTableScroll
        right
        bottom
        refresh={[visibleItems]}
        isTable
      >
        <BasicTable
          className="BasicTable"
          stickyHeader
          staleData={
            !infiniteQuery.isFetchingNextPage && infiniteQuery.isFetching
          } // don't fade when fetch next page
          head={<TRow head columns={columns} data={messageHeadData} />}
          data-qa-chart="table"
          data-qa-chart-pending={infiniteQuery.isLoading}
          data-qa-dashlet-empty={visibleItems.length === 0}
        >
          {visibleItems.map(({ item }, index) => {
            return (
              <TableRow
                key={item.id}
                columns={columns}
                onMount={handleItemMounted}
                item={item}
                index={index}
                fixWidthStyle={{
                  minWidth: '75px',
                  padding: '75px',
                }}
              />
            );
          })}
        </BasicTable>
        <StyledLoader
          loading={infiniteQuery.isFetchingNextPage || infiniteQuery.isFetching}
          noPadding={true}
        />
      </StyledTableScrollContainer>
    </TableCard>
  );
};

export default TableDashlet;
