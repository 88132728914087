import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { colorsButton } from 'app/colors';
import Icon from 'components/Kizen/Icon';
import {
  ListCardCell,
  ListCardHeader,
  ListCardItem,
  ListCardRow,
  ListCardSortButton,
  ActionButtonMenu,
} from 'components/Layout/ListCard';
import { TextEllipsis } from 'components/Kizen/Table';
import { DATE_DISPLAY_FORMAT } from 'components/Inputs/DateTimeInput';
import format from 'date-fns/format';
import { SURVEY_ACTIONS } from './columns';
import Pluralize from 'pluralize';
import { getCustomObjectLinkProps } from 'pages/Common/helpers/formsAndSurveys';

const NO_VALUE = '—';

const getSubmissionsText = (numberSubmissions) => {
  return `${numberSubmissions || 0} ${Pluralize(
    'Submission',
    numberSubmissions
  )}`;
};

export const EmptySurveyListItem = ListCardItem;

export function SurveyListItem({
  survey,
  onSelectAction,
  createEntityAccess,
  ...others
}) {
  const { t } = useTranslation();
  const { relatedObject } = survey;

  const options = useMemo(() => {
    const surveyActions = SURVEY_ACTIONS(t);
    return [
      surveyActions.Preview,
      createEntityAccess[relatedObject?.id] && surveyActions.Delete,
    ].filter(Boolean);
  }, [relatedObject?.id, createEntityAccess, t]);

  return (
    <ListCardItem
      action={
        <ActionButtonMenu
          sizing="dense"
          above={survey.meta.openMenuAbove}
          color={colorsButton.iconGray}
          onChange={onSelectAction}
          options={options}
        >
          <Icon icon="three-dot" style={{ width: 15 }} />
        </ActionButtonMenu>
      }
      actionStyles={{ paddingTop: '10px' }}
      {...others}
    >
      <ListCardRow>
        <ListCardCell>
          <TextEllipsis as="span">{survey.name || NO_VALUE}</TextEllipsis>
        </ListCardCell>
        <ListCardCell>
          <TextEllipsis {...getCustomObjectLinkProps(relatedObject)}>
            {relatedObject?.objectName || NO_VALUE}
          </TextEllipsis>
        </ListCardCell>
      </ListCardRow>
      <ListCardRow>
        <ListCardCell>
          <TextEllipsis as="span">
            {getSubmissionsText(survey.numberSubmissions)}
          </TextEllipsis>
        </ListCardCell>
        <ListCardCell>
          <TextEllipsis as="span">
            {format(survey.created, DATE_DISPLAY_FORMAT) || NO_VALUE}
          </TextEllipsis>
        </ListCardCell>
      </ListCardRow>
    </ListCardItem>
  );
}

export function SurveyListHeader({
  label,
  sort = null,
  onChangeSort = null,
  ...others
}) {
  return (
    <ListCardHeader
      compactHeader
      action={<div style={{ width: 15 }} />}
      {...others}
    >
      <ListCardCell>
        <TextEllipsis as="span" weight="bold">
          {label}
        </TextEllipsis>
        <ListCardSortButton sort={sort} onChangeSort={onChangeSort} />
      </ListCardCell>
    </ListCardHeader>
  );
}
