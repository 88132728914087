import css from '@emotion/css';
import styled from '@emotion/styled';
import { PropsWithChildren, forwardRef, useMemo } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import { ButtonRadio } from 'ts-components/ButtonToggle';
import { grayScale } from 'app/colors';
import { KizenTypography } from '__app/typography';
import Button from '__components/Button';
import Icon from '__components/Kizen/Icon';
import MultiSelect from '__components/Inputs/MultiSelect';
import { CardRow } from './ts-components';
import { FilterType } from './state';
import {
  AddFilterButton,
  ObjectOverviewFilterTypeSelector,
  Filter,
  FilterOperationSelector,
  FilterRow,
  FilterType as FilterInputFilterType,
  FilterTypeSelectorContainer,
} from 'ts-components/filters';
import {
  RuleCard,
  RuleCardConditionContainer,
} from 'ts-components/filters/RuleCard';
import {
  GroupPill,
  useCanViewCustomObject,
  getGroupLink,
} from 'ts-components/filters/GroupPill';

type DynamicContentCardProps = {
  className?: string;
  customObjectId: string;
  customObjectFetchUrl: 'client' | 'pipeline' | 'standard';
  id: string;
  filters: {
    type: FilterInputFilterType;
    filter: any;
    ops: { next: any; set: any; update: any };
  }[];
  filterErrors: (Record<string, string> | null)[];
  filterType: FilterType;
  groupOptions: Option[];
  initialGroups: string[];
  isFirst: boolean;
  isLast: boolean;
  loadingGroups: boolean;
  modalLayer?: number;
  operation: 'and' | 'or';
  onAddFilter(): void;
  onDelete(): void;
  onDeleteFilter(index: number): void;
  onReorderUp(id: string): void;
  onReorderDown(id: string): void;
  onFilterTypeChange(type: FilterType): void;
  onCreateFilter(index: number, value: string): void;
  onGroupsChange(groups: Group[]): void;
  onOperationChange(value: 'and' | 'or'): void;
};

type Option = {
  label: string;
  value: Group;
};

type GroupSelectorProps = {
  customObjectId: string;
  customObjectFetchUrl: 'client' | 'standard' | 'pipeline';
  initialValues: string[] | Group[];
  options: Option[];
  type: Exclude<FilterType, 'custom_filter'>;
  onChange(groups: Group[]): void;
};

type Group = {
  config: any;
  created: string;
  id: string;
  isMyRecordsGroup: string;
  isV2: boolean;
  name: string;
};

const EMPTY_OBJECT = {};
const REORDER_ICON_WIDTH = 12;
const ROW_ONE_FLEX_GAP = 15;

const filterOptions = (
  t: TFunction
): { value: FilterType; label: string }[] => [
  { value: 'in_group', label: t('Is in Group(s)') },
  { value: 'not_in_group', label: t('Is Not in Group(s)') },
  { value: 'custom_filter', label: t('Custom Filters') },
];

export const ReorderArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const RowOne = styled(CardRow)`
  gap: ${ROW_ONE_FLEX_GAP}px;
  align-items: center;
`;

export const IndentedRow = styled(CardRow)`
  margin-left: ${REORDER_ICON_WIDTH + ROW_ONE_FLEX_GAP}px;
`;

export const ReorderIcon = styled(Icon)`
  height: 7px;
  cursor: pointer;
  color: ${grayScale.mediumDark};

  && svg {
    width: ${REORDER_ICON_WIDTH}px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: unset;
      color: ${grayScale.medium};

      &:hover {
        color: ${grayScale.medium};
      }
    `}
`;

export const ChevronUpIcon = styled(ReorderIcon)`
  transform: rotateX(180deg);
`;

export const FilterTypeRadio = styled(ButtonRadio)`
  div > div {
    max-width: 257px;
    height: 31px;
    text-transform: uppercase;
    color: ${grayScale.mediumDark};
  }
`;

export const DeleteRuleButton = styled(Button)`
  margin: 0;
  padding: 0;
  height: unset;
  line-height: unset;
  align-self: flex-start;
`;

export const CustomFiltersRow = styled(IndentedRow)`
  min-height: 92px;
  flex-direction: column;
`;

export const GroupMultiSelectContainer = styled.div`
  width: 100%;
`;

const buildInitialGroups = (init: string[] | Group[], options: Option[]) => {
  return init.length
    ? options.filter((x) => {
        return init.some((v) => {
          if (!v) return false;
          return typeof v === 'object' ? v.id === x.value.id : v === x.value.id;
        });
      })
    : [];
};

export const GroupSelector = ({
  customObjectId,
  customObjectFetchUrl,
  initialValues = [],
  options = [],
  type,
  onChange,
}: GroupSelectorProps) => {
  const { t } = useTranslation();
  const [values, setValues] = useState<Option[]>(() => {
    return buildInitialGroups(initialValues, options);
  });
  const label =
    type === 'in_group' ? t('Is In Group(s)') : t('Is Not In Group(s)');
  const canViewCustomObject = useCanViewCustomObject(customObjectId);

  const PillComponent = useMemo(() => {
    if (canViewCustomObject) {
      return ({ value, ...rest }: { value: Group }) => {
        const href = getGroupLink(
          value.id,
          customObjectFetchUrl,
          customObjectId
        );
        return <GroupPill {...rest} value={value.id} href={href} />;
      };
    }
  }, [canViewCustomObject, customObjectId, customObjectFetchUrl]);

  useEffect(() => {
    setValues(buildInitialGroups(initialValues, options));
  }, [initialValues, options]);

  return (
    <MultiSelect
      menuInline
      label={label}
      value={values}
      placeholder={t('Choose Group(s)')}
      options={options}
      PillComponent={PillComponent}
      onChange={(opts: Option[]) => {
        setValues(opts);
        onChange(opts.map((o) => o.value));
      }}
    />
  );
};

const StyledConditionContainer = styled(RuleCardConditionContainer)`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const DynamicContentCard = forwardRef<
  HTMLDivElement | null,
  PropsWithChildren<DynamicContentCardProps>
>(
  (
    {
      children,
      className,
      customObjectId,
      customObjectFetchUrl,
      filters,
      filterErrors,
      filterType,
      groupOptions,
      id,
      initialGroups,
      isFirst,
      isLast,
      loadingGroups,
      modalLayer,
      operation,
      onAddFilter,
      onDelete,
      onDeleteFilter,
      onReorderDown,
      onReorderUp,
      onFilterTypeChange,
      onCreateFilter,
      onGroupsChange,
      onOperationChange,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <RuleCard
        ref={ref}
        className={className}
        id={id}
        isFirst={isFirst}
        isLast={isLast}
        onDelete={onDelete}
        onReorderDown={onReorderDown}
        onReorderUp={onReorderUp}
        condition={
          <StyledConditionContainer>
            <KizenTypography weight="bold">{t('When')}</KizenTypography>
            <FilterTypeRadio
              variant="outline"
              gap={20}
              value={filterType}
              options={filterOptions(t)}
              onChange={onFilterTypeChange}
            />
          </StyledConditionContainer>
        }
        confirmDeleteText={t(
          'This will permanently delete the Content Rule and the associated settings.'
        )}
        {...rest}
      >
        <CustomFiltersRow verticalPadding={20}>
          {filterType !== 'custom_filter' && (
            <GroupMultiSelectContainer>
              <GroupSelector
                key={String(loadingGroups)}
                customObjectFetchUrl={customObjectFetchUrl}
                customObjectId={customObjectId}
                initialValues={initialGroups}
                options={groupOptions}
                onChange={onGroupsChange}
                type={filterType}
              />
            </GroupMultiSelectContainer>
          )}
          {filterType === 'custom_filter' && (
            <div>
              {filters.map(({ type, filter, ops }, idx, arr) => {
                const steps = filter ? [...filter] : [];

                return (
                  <FilterRow key={idx}>
                    <FilterTypeSelectorContainer
                      showDeleteIcon={arr.length > 1}
                      onDelete={() => onDeleteFilter(idx)}
                    >
                      <ObjectOverviewFilterTypeSelector
                        objectType="client_client"
                        value={type}
                        onChange={({ value }) => onCreateFilter(idx, value)}
                        error={filterErrors[idx]?.['filter-type']}
                      />
                    </FilterTypeSelectorContainer>
                    {filter !== null && (
                      <>
                        <Filter
                          objectType="client_client"
                          filterType={type}
                          steps={steps}
                          next={ops.next}
                          set={ops.set}
                          onChange={ops.update}
                          errors={filterErrors[idx] ?? EMPTY_OBJECT}
                          modalLayer={modalLayer}
                        />
                        {Boolean(type) && idx === arr.length - 1 && (
                          <AddFilterButton onClick={onAddFilter} />
                        )}
                        {arr.length > 1 && idx === 0 && (
                          <FilterOperationSelector
                            value={operation}
                            onChange={onOperationChange}
                          />
                        )}
                      </>
                    )}
                  </FilterRow>
                );
              })}
            </div>
          )}
        </CustomFiltersRow>
        <IndentedRow verticalPadding={0}>
          <KizenTypography weight="bold">
            {t('Then, Block Settings Are')}
          </KizenTypography>
        </IndentedRow>
        <IndentedRow>{children}</IndentedRow>
      </RuleCard>
    );
  }
);
