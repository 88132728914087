import { useTruncationTooltip } from 'components/Kizen/Tooltip';
import { getTranslationKeyMapping } from 'pages/Account/pages/Toolbar/constants';
import { COLUMNS } from 'pages/Account/pages/Toolbar/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledEditableText, StyledKizenTypography } from '../../styles';
import CustomObjectItem from '../CustomObjectItem';
import URLItem from '../URLItem';
import RecentItem from '../RecentItem';

const getUIComponent = (item, editable, section) => {
  if (item.wasDynamic) {
    const [baseId] = item.id.split('--');

    if (baseId === 'new_custom_url') {
      return URLItem;
    }

    if (baseId === 'new_recent_records') {
      return RecentItem;
    }
  }

  if (item.isCustomObject && editable) {
    return CustomObjectItem;
  }

  return ({ translatedLabel }) => (
    <StyledKizenTypography
      isDynamic={item.isDynamic && section === COLUMNS.FIND_OPTIONS}
    >
      {editable
        ? translatedLabel ?? item.customLabel ?? item.label
        : translatedLabel ?? item.label}
    </StyledKizenTypography>
  );
};

const ToolbarBuilderChild = ({
  element,
  editable,
  section,
  index,
  childIndex,
  editValue,
  handleStartEditing,
  handleFinishEditing,
  removeItem,
  editOptions,
}) => {
  const { t } = useTranslation();
  const Child = getUIComponent(element, editable, section);

  const translationKeys = useMemo(() => {
    return getTranslationKeyMapping(t);
  }, [t]);

  const [tooltipProps, tooltip] = useTruncationTooltip({
    label: element.customLabel ?? translationKeys[element.id],
  });

  return element.type === 'category' &&
    editable &&
    !element.isLocked &&
    !element.dynamicallyPopulated &&
    !element.isTextLocked ? (
    <>
      <StyledEditableText
        placeholder={t('Category Name')}
        value={element.customLabel ?? translationKeys[element.id]}
        onChange={(value) => {
          editValue(index, childIndex, 'customLabel', value);
        }}
        {...tooltipProps}
      />
      {tooltip}
    </>
  ) : (
    <Child
      item={element}
      index={index}
      childIndex={childIndex}
      editValue={editValue}
      editable={editable}
      onOpenMenu={handleStartEditing}
      onCloseMenu={handleFinishEditing}
      removeItem={removeItem}
      editOptions={editOptions}
      translatedLabel={translationKeys[element.id]}
    />
  );
};

export default ToolbarBuilderChild;
