import { toastVariant, useToast } from 'components/ToastProvider';
import useDelayedMutation from 'hooks/useDelayedMutation';
import { DASHBOARD } from 'queries/query-keys';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import TeamMemberService from 'services/TeamMemberService';

const pageConfigKey = 'homepage_list';
const queryKey = [...DASHBOARD.DASHBOARD_CONFIG, pageConfigKey];
const oneDay = 1000 * 60 * 60 * 24;

const handleGetPageConfig = () =>
  TeamMemberService.getPageConfig(pageConfigKey);

export const useHomepageAdminNotice = (
  isAdmin = false,
  loading = false,
  pageConfig
) => {
  const { t } = useTranslation();
  const noticeShown = useRef(false);
  const [showToast, , clearToasts] = useToast();
  const queryClient = useQueryClient();

  const { isLoading: configLoading, data = {} } = useQuery(
    queryKey,
    handleGetPageConfig,
    {
      refetchOnWindowFocus: false,
      enabled: !pageConfig,
    }
  );

  const configMutation = useMutation(
    (result) => TeamMemberService.setPageConfig(pageConfigKey, result),
    {
      onMutate: async (updated) => {
        await queryClient.cancelQueries(queryKey);
        const previousData = queryClient.getQueryData(queryKey);

        queryClient.setQueryData(queryKey, updated);

        return { previousData };
      },
      onError: (_err, _updated, context) => {
        queryClient.setQueryData(queryKey, context.previousData);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  const lastShown = pageConfig
    ? pageConfig?.timeZoneNotice?.lastShown
    : data?.timeZoneNotice?.lastShown ?? 0;
  const elapsedMsSinceLastShown = Date.now() - lastShown;
  const showNotice = elapsedMsSinceLastShown > oneDay;

  const delayedMutation = useDelayedMutation(configMutation.mutate);

  const handleShowNotice = useCallback(
    async (message) => {
      if (!noticeShown.current) {
        noticeShown.current = true;
        showToast({
          message,
          variant: toastVariant.ALERT,
          autohide: false,
          fullWidth: true,
          yOffset: 108,
          zIndex: 1000,
        });

        const rest = pageConfig ? pageConfig : data;

        delayedMutation({
          ...rest,
          timeZoneNotice: {
            lastShown: Date.now(),
          },
        });
      }
    },
    [showToast, data, pageConfig, delayedMutation]
  );

  useEffect(() => {
    if (isAdmin && !loading && showNotice && !configLoading) {
      handleShowNotice(
        t(
          'You are a homepage administrator. Any changes you make to this homepage will immediately affect every team member who can view it.'
        )
      );
    }
  }, [handleShowNotice, isAdmin, t, loading, showNotice, configLoading]);

  return clearToasts;
};
