import styled from '@emotion/styled';
import { gutters } from 'app/spacing';
import { grayScale } from 'app/colors';
import { pageBuilderBackgroundStyle } from 'components/PageBuilder';
import {
  CustomName,
  FromName,
  InternalName,
  SubjectLine,
  IntegratedInbox,
} from './components';

const TWENTY_PX = `${gutters.spacing(4)}px`;

export const PageBuilderBodyHeader = styled.div`
  ${pageBuilderBackgroundStyle}
  padding: ${TWENTY_PX} ${TWENTY_PX} 0 ${TWENTY_PX};
`;

export const PageBuilderContentWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const PageBuilderCanvasWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 56px); // 56 = header height
  width: 100%;
`;

export const SenderInfoContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${TWENTY_PX};
  background-color: ${grayScale.white};
  padding: ${TWENTY_PX};
`;

export const StyledSubjectLine = styled(SubjectLine)`
  grid-column: 1 / 3;
`;

export const StyledInternalName = styled(InternalName)`
  grid-column: 3 / 5;
`;

export const StyledFromName = styled(FromName)`
  grid-column: 3;
`;

export const StyledIntegratedInbox2 = styled(IntegratedInbox)`
  grid-column: 2;
`;

export const StyledCustomName = styled(CustomName)`
  grid-column: 4;
`;
