import DraggableStepCard from '__pages/AutomationEngine/Engine/Steps/DraggableStepCard';
import {
  FlowLoadErrors,
  GenericOption,
  SmartConnectorFlowLoad,
  SmartConnectorStatus,
} from '__pages/SmartConnectors/types';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FlowBuilderContext } from '../../context';
import { useHistory } from 'react-router-dom';
import {
  SMART_CONNECTOR_STATUSES,
  SMARTCONNECTORS_PATHNAME,
} from '__pages/SmartConnectors/constants';
import SmartConnectorService from 'services/SmartConnectorService';
import useModal from '__components/Modals/useModal';
import ConfirmationModal from '__components/Modals/ConfirmationModal';
import { useToast } from '__components/ToastProvider';
import { toastVariant } from '__components/ToastProvider';
import { colorsButton } from '__app/colors';
import { FlowNode } from '../../types';
import { getIsSmartConnectorActive } from '__pages/SmartConnectors/helpers';

type CardProps = {
  step: FlowNode;
  color: string;
};

export default function UploadPrimaryObjectCard({
  step,
  color,
  ...others
}: CardProps) {
  const { t } = useTranslation();
  const history = useHistory<{ errors?: FlowLoadErrors }>();
  const [showToast] = useToast();
  const {
    smartConnector,
    customObject,
    customObjects,
    refetchSmartConnector,
    flowErrors,
    setFlowErrors,
    showDeactivationModalWithResult,
  } = useContext(FlowBuilderContext);

  const [uploadObject, menuOptions] = useMemo(() => {
    const uploadObject =
      customObjects.find(({ id }) => id === step.details.custom_object) || null;

    const menuOptions = [
      {
        label: t('Edit'),
        value: 'edit',
      },
      step.details.custom_object && {
        label: t('Delete'),
        value: 'delete',
      },
    ].filter(Boolean);
    return [uploadObject, menuOptions];
  }, [step.details.custom_object, customObjects, t]);

  const stepErrors = flowErrors.flow.loads?.[step.order] || {};
  const hasErrors = Object.values(stepErrors).length;

  const onDelete = async (status: SmartConnectorStatus) => {
    try {
      await SmartConnectorService.updateSmartConnector(
        smartConnector.id,
        {
          flow: {
            loads: smartConnector.flow.loads.reduce(
              (acc, { id, custom_object }) => {
                if (id !== step.details.id) {
                  // filter out load to be deleted. We don't want to touch other loads, but we need to pass them to update order,
                  // so we pass only id, custom_object and order for rest values to not to be touched and validated on BE
                  acc.loads.push({ id, custom_object, order: acc.count++ });
                }
                return acc;
              },
              { loads: [], count: 0 } as {
                loads: Partial<SmartConnectorFlowLoad>[];
                count: number;
              }
            ).loads,
          },
          status,
        },
        { skipErrorBoundary: true }
      );
      setFlowErrors((prev) => ({
        ...prev,
        flow: {
          ...prev.flow,
          loads: (prev.flow.loads || []).filter((_, i) => i !== step.order),
        },
      }));
      await refetchSmartConnector();
      return true;
    } catch (error) {
      showToast({
        message: t('Failed to delete upload step'),
        variant: toastVariant.FAILURE,
      });
      return false;
    }
  };

  const onConfirmDelete = () => {
    if (
      getIsSmartConnectorActive({
        status: smartConnector.status,
      })
    ) {
      return showDeactivationModalWithResult({
        handleSave: () => onDelete(SMART_CONNECTOR_STATUSES.inactive),
      });
    }
    return onDelete(smartConnector.status);
  };

  const [modalProps, , { show: showModal }] = useModal({
    handleSubmit: onConfirmDelete,
  });

  const onSelectMenu = ({ value }: GenericOption) => {
    if (value === 'edit') {
      history.push(
        `${SMARTCONNECTORS_PATHNAME}/${smartConnector.id}/builder/upload-object/${step.details.custom_object || smartConnector.custom_object}/${step.details.id || 'new'}`,
        { errors: stepErrors }
      );
    }
    if (value === 'delete') {
      showModal();
    }
  };

  return (
    <>
      <DraggableStepCard
        {...others}
        color={hasErrors ? colorsButton.red.hover : color}
        borderColor={hasErrors ? colorsButton.red.hover : null}
        showMovingPlaceholder={false}
        title={t('Upload {{objectName}}', {
          objectName: uploadObject?.objectName || customObject?.objectName,
        })}
        menuOptions={menuOptions}
        onSelectMenu={onSelectMenu}
      >
        {t('{{amount}} Mapped Fields', {
          amount: step.details.field_mapping_rules?.length || 0,
        })}
      </DraggableStepCard>
      {modalProps.show ? (
        <ConfirmationModal
          heading={t('Please Confirm Deletion')}
          {...modalProps}
        >
          {t(
            'This will delete the Load step, its configuration, and the associated upload execution variable.'
          )}
        </ConfirmationModal>
      ) : null}
    </>
  );
}
