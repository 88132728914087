import useExpandingVerticalScrollbar from 'hooks/useExpandingVericalScrollbar';
import { throttle } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { THROTTLE_INTERVAL } from './constants';
import useAutoScroll from './useAutoScroll';
import { getParentFieldDropzone } from './utils';
import { monitoringMessageHelper } from 'sentry/helpers';
import { flushSync } from 'react-dom';

const getDropZoneKey = (dz) => {
  return `${dz?.id}-${dz?.position}-${dz?.direction}-${dz?.sectionId}`;
};

const useMultiColumnBuilder = ({
  leftColumn,
  rightColumn,
  setDirtyStates,
  mutateOriginColumn,
  setLeftColumnItems,
  setRightColumnItems,
  mutateDestinationColumn,
  getDropId,
  handlePopup,
  leftColumnItems,
  rightColumnItems,
  ParentDropValidator,
  ChildDropValidator,
  selfDisabledDropZones,
  itemsOrdering,
  rollBackDrop,
  immutable,
  customFieldSupport,
}) => {
  const { t } = useTranslation();
  const [dropZone, setDropZone] = useState(null);
  const [childDropZone, setChildDropZone] = useState(null);
  const [dropFirstChild, setDropFirstChild] = useState(false);
  const [draggingItem, setDraggingItem] = useState(null);
  const [leftScrolled, setLeftScrolled] = useState(false);
  const [rightScrolled, setRightScrolled] = useState(false);
  const parentDropZoneKey = useRef('');
  const childDropZoneKey = useRef('');

  const finishDrag = useCallback(() => {
    setDraggingItem(null);
    setDropZone(null);
    setChildDropZone(null);
    setDropFirstChild(null);
  }, []);

  const endDrag = useCallback(
    (...args) => {
      if (!dropZone) {
        return finishDrag();
      }

      try {
        setDirtyStates(true);
        const { sectionId: toColumn, position } = dropZone;
        const { fromColumn } = draggingItem;

        if (
          toColumn === rightColumn &&
          fromColumn === leftColumn &&
          rollBackDrop
        ) {
          return finishDrag();
        }

        if (
          (position && !childDropZone) ||
          childDropZone?.position ||
          dropFirstChild
        ) {
          if (!(draggingItem.isDynamic && fromColumn === leftColumn)) {
            if (fromColumn === leftColumn && !draggingItem.isCustomObject) {
              mutateOriginColumn(
                setLeftColumnItems,
                draggingItem,
                immutable === leftColumn,
                customFieldSupport
              );
            } else if (fromColumn === rightColumn) {
              mutateOriginColumn(
                setRightColumnItems,
                draggingItem,
                immutable === rightColumn
              );
            }
          }

          if (dropFirstChild && toColumn !== rightColumn) {
            return finishDrag();
          }

          if (toColumn === rightColumn) {
            mutateDestinationColumn(
              setRightColumnItems,
              draggingItem,
              dropZone,
              childDropZone,
              () => false,
              (i, d) => getDropId(i, d),
              t,
              handlePopup,
              dropFirstChild,
              (existing) => existing,
              itemsOrdering,
              immutable === rightColumn,
              leftColumn,
              rightColumn
            );
          } else if (toColumn === leftColumn) {
            mutateDestinationColumn(
              setLeftColumnItems,
              draggingItem,
              dropZone,
              childDropZone,
              (i) => i.wasDynamic,
              (i) => i.id,
              t,
              undefined,
              undefined,
              (existing) => {
                const idx = existing.findIndex((e) => e.id === draggingItem.id);
                if (idx > -1) {
                  existing.splice(idx, 1);
                }

                return existing;
              },
              itemsOrdering,
              immutable === leftColumn,
              leftColumn,
              rightColumn
            );
          }
        }
      } catch (ex) {
        monitoringMessageHelper('Multi column builder drop error', {
          extra: { error: ex },
        });
      }

      // Add to destination column
      return finishDrag();
    },
    [
      draggingItem,
      dropZone,
      setLeftColumnItems,
      setRightColumnItems,
      childDropZone,
      t,
      finishDrag,
      dropFirstChild,
      setDirtyStates,
      getDropId,
      handlePopup,
      leftColumn,
      mutateDestinationColumn,
      mutateOriginColumn,
      rightColumn,
      itemsOrdering,
      rollBackDrop,
      immutable,
      customFieldSupport,
    ]
  );

  const swapItemColumn = useCallback(
    (index, fromColumn, justRemove = false) => {
      setDirtyStates(true);
      const removingFromRight = fromColumn === rightColumn;
      const originStateSetter = removingFromRight
        ? setRightColumnItems
        : setLeftColumnItems;
      const destinationStateSetter = removingFromRight
        ? setLeftColumnItems
        : setRightColumnItems;

      originStateSetter((existing) => {
        const res = [...existing];
        const [removed] = res.splice(index, 1);

        if (!justRemove) {
          destinationStateSetter((existing) => {
            const res = [...existing];

            if (itemsOrdering) {
              const lookUp = res.reduce(
                (acc, item) => ({ ...acc, [item.id]: item }),
                {}
              );
              return itemsOrdering.reduce((acc, id) => {
                if (lookUp[id]) {
                  acc.push(lookUp[id]);
                }
                if (id === removed.id) {
                  acc.push(removed);
                }
                return acc;
              }, []);
            } else {
              res.push(removed);
            }

            return res;
          });
        }

        return res;
      });
    },
    [
      rightColumn,
      setLeftColumnItems,
      setRightColumnItems,
      itemsOrdering,
      setDirtyStates,
    ]
  );

  const getFieldDropZone = useCallback(
    (toColumnId, event, buildClassName) => {
      if (draggingItem) {
        if (
          draggingItem.fromColumn !== toColumnId &&
          selfDisabledDropZones.includes(toColumnId)
        ) {
          return { position: 'overall', sectionId: toColumnId };
        }
        const res = getParentFieldDropzone(
          draggingItem,
          toColumnId,
          toColumnId === leftColumn
            ? leftColumnItems
            : toColumnId === rightColumn
              ? rightColumnItems
              : rightColumnItems.find((c) => c.id === toColumnId)?.children ??
                [],
          event,
          buildClassName,
          leftColumn
        );

        return {
          ...res,
          sectionId: toColumnId,
        };
      }
    },
    [
      draggingItem,
      leftColumnItems,
      rightColumnItems,
      leftColumn,
      rightColumn,
      selfDisabledDropZones,
    ]
  );

  const maybeSetParentDropZone = useCallback((dz) => {
    const key = getDropZoneKey(dz);
    if (key !== parentDropZoneKey.current) {
      parentDropZoneKey.current = key;
      setDropZone(dz);
    }
  }, []);

  const maybeSetChildDropZone = useCallback((dz) => {
    const key = getDropZoneKey(dz);
    if (key !== childDropZoneKey.current) {
      childDropZoneKey.current = key;
      setChildDropZone(dz);
    }
  }, []);

  // We need to throttle this handler or else we end up with really bad performance
  // while dragging
  const throttleGetFieldDropzone = useMemo(() => {
    return throttle((id, ev) => {
      if (ev?.currentTarget) {
        const dz = getFieldDropZone(id, ev);
        const validator = new ParentDropValidator(
          dz,
          draggingItem,
          selfDisabledDropZones,
          leftColumn,
          rightColumn
        );
        const resultDropZone = validator.getValidDropZone();
        maybeSetParentDropZone(resultDropZone);
      }
    }, THROTTLE_INTERVAL);
  }, [
    getFieldDropZone,
    draggingItem,
    maybeSetParentDropZone,
    ParentDropValidator,
    selfDisabledDropZones,
    leftColumn,
    rightColumn,
  ]);

  // We need to throttle this handler or else we end up with really bad performance
  // while dragging
  const throttleGetChildFieldDropzone = useMemo(() => {
    return throttle((id, ev, reset) => {
      if (
        draggingItem?.type === 'category' ||
        reset ||
        draggingItem?.id === 'new_category'
      ) {
        maybeSetChildDropZone(null);
      } else if (ev?.currentTarget) {
        const dz = getFieldDropZone(
          id,
          ev,
          () => `.MenuItemWrapper-Child-${id}`
        );
        if (dz?.sectionId && draggingItem) {
          const validator = new ChildDropValidator(
            dz,
            draggingItem,
            rightColumnItems
          );
          const childDropZone = validator.getValidDropZone();
          return maybeSetChildDropZone(childDropZone);
        }
        return maybeSetChildDropZone(dz);
      }
    }, THROTTLE_INTERVAL);
  }, [
    getFieldDropZone,
    draggingItem,
    rightColumnItems,
    maybeSetChildDropZone,
    ChildDropValidator,
  ]);

  const { manageAutoScroll, onMouseOut } = useAutoScroll({
    draggingItem,
    leftColumn,
    rightColumn,
  });

  const throttleSetLeftScrolled = useMemo(() => {
    return throttle((value) => {
      setLeftScrolled(value[0] === false);
    }, THROTTLE_INTERVAL);
  }, []);

  const throttleSetRightScrolled = useMemo(() => {
    return throttle((value) => {
      setRightScrolled(value[0] === false);
    }, THROTTLE_INTERVAL);
  }, []);

  const { containerProps: leftContainerProps } =
    useExpandingVerticalScrollbar();

  const { containerProps: rightContainerProps } =
    useExpandingVerticalScrollbar();

  const moveItemToBottom = useCallback(
    (parentIndex, childIndex) => {
      setDirtyStates(true);
      setRightColumnItems((existing) => {
        const res = [...existing];
        if (childIndex > -1) {
          res[parentIndex].children.splice(childIndex, 1);
        } else {
          res.splice(parentIndex, 1);
        }
        return res;
      });
    },
    [setRightColumnItems, setDirtyStates]
  );

  const removeItem = useCallback(
    (parentIndex, childIndex) => {
      setDirtyStates(true);

      setRightColumnItems((existing) => {
        const res = [...existing];
        if (childIndex > -1) {
          res[parentIndex].children.splice(childIndex, 1);
        } else {
          res.splice(parentIndex, 1);
        }
        return res;
      });
    },
    [setRightColumnItems, setDirtyStates]
  );

  const syncEndDrag = useCallback(
    (...args) => {
      flushSync(() => endDrag(...args));
    },
    [endDrag]
  );

  return {
    endDrag: syncEndDrag,
    moveItemToBottom,
    removeItem,
    swapItemColumn,
    throttleGetFieldDropzone,
    throttleGetChildFieldDropzone,
    leftColumnProps: {
      draggingItem,
      setDraggingItem,
      dropZone,
      setDropZone,
      manageAutoScroll,
      onMouseOut,
    },
    leftContainerProps: {
      ...leftContainerProps,
      onChangeScrolled: throttleSetLeftScrolled,
    },
    leftHeaderProps: {
      scrolled: leftScrolled,
    },
    rightColumnProps: {
      draggingItem,
      setDraggingItem,
      dropZone,
      setDropZone,
      setDropFirstChild,
      dropFirstChild,
      childDropZone,
      manageAutoScroll,
      onMouseOut,
    },
    rightContainerProps: {
      ...rightContainerProps,
      onChangeScrolled: throttleSetRightScrolled,
    },
    rightHeaderProps: {
      scrolled: rightScrolled,
      grow: true,
    },
  };
};

export default useMultiColumnBuilder;
