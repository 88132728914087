import styled from '@emotion/styled';
import { appBackground } from '__app/colors';
import { KizenTypography } from '__app/typography';

export const StiledNotice = styled(KizenTypography)`
  padding: 10px;
  background-color: ${appBackground};
  margin-bottom: 15px;
`;

export const StyledReferenceFields = styled.div`
  min-height: calc(100vh - 540px);
`;
