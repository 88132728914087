import { createAction } from '@reduxjs/toolkit';
import { recordsPageSlice } from './records.redux';
import { VIEW_VARIANTS } from 'components/ViewVariant/types';

export const getFullListOfCustomObjects = createAction<
  any,
  'recordsPage/getFullListOfCustomObjects'
>('recordsPage/getFullListOfCustomObjects');
export const getFullListOfCustomObjectsFail = createAction<
  any,
  'recordsPage/getFullListOfCustomObjectsFail'
>('recordsPage/getFullListOfCustomObjectsFail');

// records
export const getRecords = createAction(
  'recordsPage/GET_RECORDS',
  function prepare({
    updatePageConfig = true,
    triggerViewLoading = false,
    onlyUpdateCounts = false,
    updatePageConfigKey,
  } = {}) {
    return {
      payload: {
        updatePageConfig,
        triggerViewLoading,
        onlyUpdateCounts,
        updatePageConfigKey,
      },
    };
  }
);
export const getRecordsBySearch = createAction<
  any,
  'recordsPage/GET_RECORDS_BY_SEARCH'
>('recordsPage/GET_RECORDS_BY_SEARCH');

// setPageConfig
export const setPageConfigSuccess = createAction<
  any,
  'recordsPage/SET_PAGE_CONFIG_SUCCESS'
>('recordsPage/SET_PAGE_CONFIG_SUCCESS');
export const setPageConfigFail = createAction<
  any,
  'recordsPage/SET_PAGE_CONFIG_FAIL'
>('recordsPage/SET_PAGE_CONFIG_FAIL');
export const archiveRecord = createAction<any, 'recordsPage/ARCHIVE_RECORD'>(
  'recordsPage/ARCHIVE_RECORD'
);
export const archiveRecordFail = createAction<
  any,
  'recordsPage/ARCHIVE_RECORD_FAIL'
>('recordsPage/ARCHIVE_RECORD_FAIL');
export const updateRelatedRecordsFail = createAction<
  any,
  'recordsPage/UPDATE_RELATED_RECORDS_FAIL'
>('recordsPage/UPDATE_RELATED_RECORDS_FAIL');
export const getLeadSourceAdditionalData = createAction<
  any,
  'recordsPage/GET_LEAD_SOURCE_ADDITIONAL_DATA'
>('recordsPage/GET_LEAD_SOURCE_ADDITIONAL_DATA');
export const getLeadSourceAdditionalDataFail = createAction<
  any,
  'recordsPage/GET_LEAD_SOURCE_ADDITIONAL_DATA_FAIL'
>('recordsPage/GET_LEAD_SOURCE_ADDITIONAL_DATA_FAIL');
export const getLeadSourceEntities = createAction<
  any,
  'recordsPage/GET_LEAD_SOURCE_ENTITIES'
>('recordsPage/GET_LEAD_SOURCE_ENTITIES');
export const getLeadSourceEntitiesFail = createAction<
  any,
  'recordsPage/GET_LEAD_SOURCE_ENTITIES_FAIL'
>('recordsPage/GET_LEAD_SOURCE_ENTITIES_FAIL');

// boardData
export const getBoardData = createAction<any, 'recordsPage/GET_BOARD_DATA'>(
  'recordsPage/GET_BOARD_DATA'
);
export const getBoardDataBySearch = createAction<
  any,
  'recordsPage/GET_BOARD_DATA_BY_SEARCH'
>('recordsPage/GET_BOARD_DATA_BY_SEARCH');
export const getBoardDataFail = createAction<
  any,
  'recordsPage/GET_BOARD_DATA_FAIL'
>('recordsPage/GET_BOARD_DATA_FAIL');
export const getRecordsInStage = createAction<
  any,
  'recordsPage/GET_RECORDS_IN_STAGE'
>('recordsPage/GET_RECORDS_IN_STAGE');
export const getRecordsInStageFail = createAction<
  any,
  'recordsPage/GET_RECORDS_IN_STAGE_FAIL'
>('recordsPage/GET_RECORDS_IN_STAGE_FAIL');
export const moveRecord = createAction<any, 'recordsPage/MOVE_RECORD'>(
  'recordsPage/MOVE_RECORD'
);

// createOrUpdateGroup
export const createOrUpdateGroup = createAction<
  any,
  'recordsPage/CREATE_OR_UPDATE_GROUP'
>('recordsPage/CREATE_OR_UPDATE_GROUP');
export const createOrUpdateGroupFail = createAction<
  any,
  'recordsPage/CREATE_OR_UPDATE_GROUP_FAIL'
>('recordsPage/CREATE_OR_UPDATE_GROUP_FAIL');

// removeGroup
export const removeGroup = createAction<any, 'recordsPage/REMOVE_GROUP'>(
  'recordsPage/REMOVE_GROUP'
);
export const removeGroupFail = createAction<
  any,
  'recordsPage/REMOVE_GROUP_FAIL'
>('recordsPage/REMOVE_GROUP_FAIL');

// getCounts
export const getCounts = createAction<any, 'recordsPage/GET_COUNTS'>(
  'recordsPage/GET_COUNTS'
);
export const getCountsFail = createAction<any, 'recordsPage/GET_COUNTS_FAIL'>(
  'recordsPage/GET_COUNTS_FAIL'
);

// changeGroup
export const changeGroup = createAction<any, 'recordsPage/CHANGE_GROUP'>(
  'recordsPage/CHANGE_GROUP'
);

export const {
  buildPage,
  buildPageComplete,
  buildPageFinish,
  buildPageFail,
  getRecordsStart,
  getRecordsSuccess,
  getRecordsFail,
  updatePageConfig,
  updateRecord,
  addNewRecord,
  archiveRecordSuccess,
  setCheckedRecord,
  setCheckedSelection,
  updateColumnWidth,
  setGroup,
  setPageConfig,
  setToast,
  cleanToast,
  setLeadSourceAdditionalData,
  setLeadSourceDataByKey,
  getBoardDataStart,
  getBoardDataSuccess,
  getRecordsInStageSuccess,
  setStageSelection,
  setSelectionCard,
  resetFilterData,
  setFilterName,
  setNumberOfFilters,
  createOrUpdateGroupSuccess,
  removeGroupSuccess,
  resetFilterGroupSuccess,
  updateBoardSettings,
  moveRecordSuccess,
  updateFieldOptions,
  updateModel,
  getFullListOfCustomObjectsSuccess,
  moveRecordStart,
  moveRecordFail,
  getCountsSuccess,
  setCountsNeedToLoad,
  setBoardDataStagesChecked,
  showReasonDialog,
  showReasonDisqualifiedDialog,
  closeReasonDialog,
  closeReasonDisqualifiedDialog,
  updateCommerceValue,
  getRecordsFinish,
  getBoardDataFinish,
  updateRecords,
  archiveRecordFromBoard,
  updateBoardMetrics,
  clearFilterErrors,
  clearFilterSearch,
  setSavedFilter,
  setIsAllRecordsGroup,
  resetPage,
} = recordsPageSlice.actions;

export const defaultRecordsConfig = {
  columnOrder: null,
  columns: {}, // id -> { width, visible }
  recordsPerPage: 50,
  search: '',
  sort: 'name',
  viewVariant: VIEW_VARIANTS.TABLE,
};
