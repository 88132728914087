import { format as formatOld } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const cleanFiles = (files) => files.filter((f) => f !== null);

const DATE_TIME_FORMAT_PARSE_STRING = 'YYYY-MM-DDTHH:mm:ss.000Z';

export const dateTimeFormatParseString = (value) =>
  value && new Date(formatOld(value, DATE_TIME_FORMAT_PARSE_STRING));

export const getDataQAForInput = (id, type) => {
  return {
    'data-field-type': type || 'text',
    'data-field-id': id,
  };
};

export const getMaskedValue = (str = '') => {
  if (typeof str === 'string') {
    const truncatedStr = str.substring(0, 12);
    return truncatedStr.replace(/[\s\S]/g, '\u25CF');
  }
  return str;
};

export const isValidHttpUrl = (str) => {
  if (str.match(/\s/)) {
    return false;
  }
  try {
    const url = new URL(str);

    return (
      (url.protocol === 'http:' || url.protocol === 'https:') &&
      /^[a-z0-9]+(\.[a-z0-9]+)+$/.test(url.hostname)
    );
  } catch (err) {
    return false;
  }
};

export const getUrlFromString = (str) => {
  if (!str || typeof str !== 'string') {
    return null;
  }
  const maybeUrl = str.trim();
  return isValidHttpUrl(maybeUrl) ? maybeUrl : null;
};

export const openUrlOnClick = (url) => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.rel = 'noreferrer';
  a.click();
};

export const useUrlFromValue = (value, enabled) => {
  const { t } = useTranslation();
  const urlFromValue = useMemo(
    () => enabled && getUrlFromString(value),
    [enabled, value]
  );

  const onClickUrl = useCallback(() => {
    openUrlOnClick(urlFromValue);
  }, [urlFromValue]);

  return {
    onClickUrl,
    urlFromValue,
    urlFromValueLinkProps: {
      ...(urlFromValue
        ? {
            type: 'anchor',
            href: urlFromValue,
            target: '_blank',
            rel: 'noreferrer',
          }
        : {}),
    },
    linkButtonProps: {
      leftIcon: 'text-add-link',
      leftIconSettings: {
        size: 'xs',
        tooltipDescriptionOverride: t('Open in new tab'),
      },
      variant: 'text',
      color: 'secondary',
      onClick: onClickUrl,
      qa: {
        '': 'open-url',
      },
    },
  };
};
