import { isEqual } from 'lodash';
import { VisibilityRule } from './types';

export const deleteFieldFromVisibilityRules = (
  visibilityRules: VisibilityRule[],
  fieldId: string
) => {
  const result: VisibilityRule[] = [];

  for (const vr of visibilityRules) {
    const fields = vr.fields.filter((x) => x.id !== fieldId);
    const rule = {
      and: vr.rule.and,
      filters: vr.rule.filters.filter((x) => x.activity_field_id !== fieldId),
    };
    result.push({ fields, rule });
  }

  return result;
};

export const isVisibilityRuleInvalid = (vr: VisibilityRule) => {
  return vr.fields.length === 0 || vr.rule.filters.length === 0;
};

export const hasCyclicRules = (visibilityRules: VisibilityRule[]) => {
  const relationships = visibilityRules.reduce<Map<string, string[]>>(
    (acc, vr) => {
      for (const { activity_field_id } of vr.rule.filters) {
        const hidden = vr.fields.map((x) => x.id);
        if (acc.has(activity_field_id)) {
          acc.get(activity_field_id)!.push(...hidden);
        } else {
          acc.set(activity_field_id, hidden);
        }
      }
      return acc;
    },
    new Map()
  );

  const hasCycle = (testIds: string[], hiddenByTestId?: string[]): boolean => {
    if (!hiddenByTestId) {
      return false;
    }
    if (hiddenByTestId.some((x) => testIds.includes(x))) {
      return true;
    }
    return hiddenByTestId.some((x) => {
      return hasCycle(testIds.concat(x), relationships.get(x));
    });
  };

  for (const { id } of visibilityRules.flatMap((x) => x.fields)) {
    if (hasCycle([id], relationships.get(id))) {
      return true;
    }
  }

  return false;
};

export const visibilityRuleEq = (
  r1: VisibilityRule,
  r2: VisibilityRule,
  opts: { compareFields: boolean } = { compareFields: true }
) => {
  if (opts?.compareFields) {
    const f1 = new Set(r1.fields.map((x) => x.id));
    const f2 = new Set(r2.fields.map((x) => x.id));

    if (f1.size !== f2.size) {
      return false;
    }
  }

  if (r1.rule.and !== r2.rule.and) {
    return false;
  }

  if (r1.rule.filters.length !== r2.rule.filters.length) {
    return false;
  }

  for (let i = 0; i < r1.rule.filters.length; i++) {
    const { value: a_value, view_model: _vma, ...a } = r1.rule.filters[i];
    const { value: b_value, view_model: _vmb, ...b } = r2.rule.filters[i];

    if (Array.isArray(a_value) && Array.isArray(b_value)) {
      if (new Set(a_value).size !== new Set(b_value).size) {
        return false;
      }
    }

    if (!isEqual(a, b)) {
      return false;
    }
  }

  return true;
};
