import { createSelector } from '@reduxjs/toolkit';
import { VIEW_VARIANTS } from 'components/ViewVariant/types';
import { get } from 'lodash';
import { snakeToCamelCaseKeys } from 'services/helpers';
import FileService from 'services/FileService';

const aliases = {
  contacts: {
    create: 'can_create_new_contact',
    action: 'bulk_actions',
  },
};

export const bulkActionsAliases = {
  sendEmail: 'bulk_send_email',
  sendText: 'bulk_send_text',
  sendSurvey: 'bulk_send_survey',
  changeTags: 'bulk_change_tags',
  changeFieldValue: 'bulk_change_field_value',
  exportToCsv: 'bulk_export_to_csv',
  archive: 'bulk_archive',
  startAutomation: 'bulk_start_automation',
  manageAssociations: 'bulk_manage_associations',
  uploadContacts: 'bulk_data_upload',
};

/*
 * Allows us to ask rules about user access/permissions
 * in a slightly simpler way than working with the API's raw result.
 *
 * Example usage:
 *
 * can(state, {
 *   view: 'section',
 *   for: 'contacts'
 * });
 *
 * can(state, {
 *   create: 'contacts'
 * });
 */

export const can = ({ authentication }, { for: ruleFor, ...rule }) => {
  const { access } = authentication;
  if (!access) {
    return false;
  }
  const ruleEntries = Object.entries(rule);
  if (ruleEntries.length !== 1) {
    return false;
  }

  const [[permissionNameRaw, area]] = ruleEntries;
  if (!access[area]) {
    return false;
  }
  const permissionName =
    (aliases[area] && aliases[area][permissionNameRaw]) || permissionNameRaw;
  const actionName = bulkActionsAliases[ruleFor];
  const allowed = ruleFor
    ? actionName
      ? access[area][permissionName][actionName]
      : get(access[area], `${ruleFor}.${permissionName}`)
    : access[area][permissionName];
  // Checking for true protects from missing "for" argument,
  // when "allowed" may be an object containing the relevant permissions.
  return allowed === true;
};

export const selectCanEditContactObjects = ({ authentication }) => {
  return (
    authentication.access?.sections?.custom_objects_section?.contacts?.edit ??
    false
  );
};

const selectCanEditAllCustomObjects = ({ authentication }) => {
  return (
    authentication.access?.sections?.custom_objects_section?.all_records
      ?.edit ?? false
  );
};

const selectCanEditAssociatedCustomObjects = ({ authentication }) => {
  return (
    authentication.access?.sections?.custom_objects_section?.associated_records
      ?.edit ?? false
  );
};

export const selectCanEditSomeCustomObjects = createSelector(
  selectCanEditAllCustomObjects,
  selectCanEditAssociatedCustomObjects,
  (all, associated) => all || associated
);

export const getSettingsAccess = createSelector(
  (state) => state.authentication.access,
  selectCanEditContactObjects,
  selectCanEditSomeCustomObjects,
  (access, canEditContacts, canEditObjects) => {
    const {
      business_customization,
      business_information,
      business_holidays,
      team_member,
      permission_group,
      role,
      domain_and_tracking,
      subscription_list,
      privacy,
      integrated_inbox,
      manage_standard_api_keys,
      manage_oauth_authorizations,
      meta_export_import,
    } = access.sections.settings_section || {};
    const { custom_objects_section } = access.sections;
    const { customize_field } = custom_objects_section;

    return {
      business: {
        enabled:
          business_customization?.edit ||
          business_information?.edit ||
          business_holidays?.view ||
          meta_export_import?.edit,
        businessCustomization: business_customization,
        businessInformation: business_information,
        businessHolidays: business_holidays,
        metaExportImport: meta_export_import,
      },
      team: {
        enabled: team_member?.view || permission_group?.view || role?.view,
        teamMembers: team_member,
        permissionGroups: permission_group,
        roles: role,
      },
      customFields: {
        enabled: customize_field?.edit && (canEditContacts || canEditObjects),
        customizeFields: customize_field,
      },
      domainAndTracking: {
        enabled: domain_and_tracking?.view,
        domainAndTracking: domain_and_tracking,
      },
      privacySettings: {
        enabled:
          subscription_list?.edit || privacy?.edit || integrated_inbox?.edit,
        subscriptionLists: subscription_list,
        privacy,
        integratedInboxes: integrated_inbox,
      },
      manageConnections: {
        enabled:
          manage_standard_api_keys?.view || manage_oauth_authorizations?.view,
        manageStandardApiKeys: manage_standard_api_keys,
        manageOauthAuthorizations: manage_oauth_authorizations,
      },
    };
  }
);

export const getCustomObjectSettinsAccess = ({
  authentication: { access },
}) => {
  return access.sections.custom_objects_section;
};

export const getSettingsRoutesWithAccess = createSelector(
  getSettingsAccess,
  (_, routes) => routes,
  (settingsAccess, routes) => {
    const settingsRoutesWithAccess = Object.entries(routes).reduce(
      (collect, [key, value]) => ({
        ...collect,
        [key]: { ...value, access: settingsAccess[key] },
      }),
      {}
    );

    const accessibleSettingsRoutes = Object.entries(
      settingsRoutesWithAccess
    ).reduce(
      (collect, [key, value]) =>
        settingsAccess[key]?.enabled
          ? {
              ...collect,
              [key]: {
                ...value,
                access: settingsAccess[key],
              },
            }
          : collect,
      {}
    );

    const firstAccesssibleSettingsRoute = Object.values(
      accessibleSettingsRoutes
    )[0];

    return {
      settingsRoutesWithAccess,
      accessibleSettingsRoutes,
      firstAccesssibleSettingsRoute,
    };
  }
);

export const getBroadcastsAccess = createSelector(
  ({ authentication }) => authentication.access.sections.broadcasts_section,
  ({ view, enabled, ...access }) => {
    return {
      canView: view && enabled,
      canScheduleBroadcasts: Object.values(access).some(
        ({ edit, remove }) => edit || remove
      ),
      access,
    };
  }
);

export const getCustomObjectsWithBulkAccess = createSelector(
  ({ authentication }) => ({
    ...authentication.access.custom_objects.custom_object_entities,
    [authentication.chosenBusiness?.client_object.id]:
      authentication.access.contacts.bulk_actions,
  }),
  (_, bulkKey) => bulkKey,
  (objects, bulkKey) =>
    Object.entries(objects).reduce(
      (collect, [id, { [bulkKey]: bulkAccessValue }]) => ({
        ...collect,
        [id]: bulkAccessValue,
      }),
      {}
    )
);

const selectSheduledActivities = ({ authentication }) =>
  authentication.access.scheduled_activities || {};

const selectCustomObjectsEntities = ({ authentication }, clientObjectId) =>
  authentication.access.custom_objects.custom_object_entities?.[
    clientObjectId
  ] || {};

const selectContact = ({ authentication }) =>
  authentication.access.contacts || {};

const selectContactsAndScheduledActivities = (state) => ({
  entities: selectContact(state),
  scheduleActivitiesAccess: selectSheduledActivities(state),
});
const selectCustomObjectAndScheduledActivities = (state, clientObjectId) => ({
  entities: selectCustomObjectsEntities(state, clientObjectId),
  scheduleActivitiesAccess: selectSheduledActivities(state),
});

// make it more user friendly
const recordLayoutMapper = () => {
  console.log(
    'This permission has been deprecated and will be fully removed in a future sprint'
  );
  return {};
};

export const getCustomObjectsRecordLayoutAccess = createSelector(
  selectCustomObjectAndScheduledActivities,
  recordLayoutMapper
);

export const getContactRecordLayoutAccess = createSelector(
  selectContactsAndScheduledActivities,
  recordLayoutMapper
);

export const showDataDropdown = ({ authentication }, preReleaseFeatures) => {
  const { access } = authentication;
  if (!access) {
    return false;
  }

  const sections = snakeToCamelCaseKeys(access.sections);
  // Show main nav item if any of it's children has permission set to view: true
  return !!(
    sections.contactsSection.view ||
    // (preReleaseFeatures && sections.companiesSection.view) || TODO need to add when companies would be in the app
    // sections.pipelinesSection.view || TODO need to add when pipelines would be in the app
    sections.customObjectsSection.view ||
    (preReleaseFeatures && sections.ordersSection.view) ||
    (preReleaseFeatures && sections.productsSection.view) ||
    sections.marketingCostsSection.view
  );
};

export const showPlatformDropdown = (
  { authentication },
  preReleaseFeatures
) => {
  const { access } = authentication;

  if (!access) {
    return false;
  }

  const sections = snakeToCamelCaseKeys(access.sections);
  // Show main nav item if any of it's children has permission set to view: true
  return !!(
    (preReleaseFeatures && sections.broadcastsSection.view) ||
    sections.formsSection.view ||
    sections.adsSection.view ||
    sections.librarySection.view ||
    sections.surveysSection.view
  );
};

export const getChosenBusiness = ({ authentication }) => {
  return authentication?.chosenBusiness || null;
};

export const getChosenBusinessEntitlement = createSelector(
  getChosenBusiness,
  (_, entitlementKey) => entitlementKey,
  (chosenBusiness, entitlementKey) => {
    return chosenBusiness?.entitlements?.[entitlementKey] || null;
  }
);

export const getBusinessClientObject = createSelector(
  getChosenBusiness,
  (chosenBusiness) => {
    if (!chosenBusiness) {
      return {
        id: null,
        objectName: null,
        access: false,
      };
    }
    return {
      id: chosenBusiness.client_object.id,
      objectName: chosenBusiness.client_object.object_name,
      access: chosenBusiness.client_object.access,
    };
  }
);

export const getCustomObjectAccess = ({ authentication }) => {
  const { access } = authentication;
  return snakeToCamelCaseKeys(access.custom_objects);
};

export const getCustomObjectEntitiesAccess = createSelector(
  ({ authentication }) => {
    const { access } = authentication;
    return access.custom_objects.custom_object_entities;
  },
  snakeToCamelCaseKeys
);

export const getCustomObjectEntitiesAccessById = ({ authentication }, id) => {
  const { access } = authentication;
  return snakeToCamelCaseKeys(
    access.custom_objects.custom_object_entities?.[id]
  );
};

export const getContactSectionAccess = ({ authentication }) => {
  const { access } = authentication;
  return snakeToCamelCaseKeys(access.sections.contacts_section);
};

export const getCustomObjectsSettingsAccess = ({ authentication }) => {
  const { access } = authentication;
  return snakeToCamelCaseKeys(access.custom_objects.object_settings);
};

export const getContactsAccess = ({ authentication }) => {
  const { access } = authentication;
  return snakeToCamelCaseKeys(access.contacts);
};

export const getCreateEntityAccess = createSelector(
  getCustomObjectEntitiesAccess,
  getContactsAccess,
  getBusinessClientObject,
  (customObjectEntitiesAccess, contactsAccess, { id }) => {
    return {
      [id]: contactsAccess.canCreateNewContact,
      ...Object.entries(customObjectEntitiesAccess).reduce(
        (acc, [id, access]) => ({ ...acc, [id]: access.canCreate }),
        {}
      ),
    };
  }
);

const buildRecordOverviewAccess = ({
  record_overview_board_view,
  record_overview_chart_view,
  record_overview_quick_filters,
}) => {
  return {
    viewVariantsAccess: {
      [VIEW_VARIANTS.TABLE]: true,
      [VIEW_VARIANTS.BOARD]: !!record_overview_board_view,
      [VIEW_VARIANTS.CHARTS]: !!record_overview_chart_view,
    },
    quickFiltersAccess: !!record_overview_quick_filters,
  };
};

export const getRecordOverviewAccess = createSelector(
  ({ authentication }, objectId) => {
    if (objectId) {
      return (
        authentication?.access?.custom_objects?.custom_object_entities?.[
          objectId
        ]?.record_overview ?? {}
      );
    }
    return authentication?.access?.contacts?.record_overview ?? {};
  },
  buildRecordOverviewAccess
);

export const getAuthTeamMember = ({ authentication }) => {
  const { teamMember } = authentication;
  return snakeToCamelCaseKeys(teamMember);
};

export const getUserEmail = (s) => s.authentication?.user?.profile?.email ?? '';

export const getUserProfile = (state) => {
  const { authentication } = state;

  return authentication && authentication.user && authentication.user.profile;
};

export const getApiKeysAccess = ({ authentication: { access } }) => {
  return access.sections.api_connections_section.standard_api_keys;
};

export const getOAuthTokenAccess = ({ authentication: { access } }) => {
  return access.sections.api_connections_section.oauth_authorizations;
};

export const getKeyAndTokenAccess = createSelector(
  getApiKeysAccess,
  getOAuthTokenAccess,
  (accessApi, accessOauth) => {
    return accessApi || accessOauth;
  }
);

export const getMyProfileSection = ({ authentication: { access } }) => {
  return access.sections.my_profile_section.view;
};

export const getManageOauthAuthorizations = ({
  authentication: { access },
}) => {
  return access.sections.settings_section.manage_oauth_authorizations;
};

export const getManageStanadardApiKeys = ({ authentication: { access } }) => {
  return access.sections.settings_section.manage_standard_api_keys;
};

export const getGenerativeAIEntitlement = createSelector(
  (state) =>
    getChosenBusinessEntitlement(state, 'generative_ai_builder_enabled'),
  (enabled) => enabled
);

export const getBusinessTimeZone = (state) =>
  state.authentication.chosenBusiness.timezone.name;

export const canViewAutomationExecutionInsights = createSelector(
  (state) =>
    getChosenBusinessEntitlement(
      state,
      'automation_execution_insights_role_ids'
    ),
  getAuthTeamMember,
  (roleIds = [], { roles }) => {
    return !!roleIds && roles.some((roleId) => roleIds.includes(roleId));
  }
);

export const getSmartConnectorAccess = ({ authentication: { access } }) => {
  return access.smart_connectors;
};

export const getTeamMemberNameAndImage = createSelector(
  ({ authentication }) => authentication.teamMember,
  (teamMember) => ({
    userName: teamMember?.full_name,
    userImage: teamMember?.picture
      ? FileService.getThumbnailUrl(teamMember.picture.id)
      : null,
  })
);
