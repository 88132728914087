import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TopLayerSelect } from '__components/PageBuilder/components/TopLayerSelect';
import { NumberFormat } from 'libphonenumber-js';

import { SENDER_TYPE } from './SenderType';

type IntegratedInboxSenderTypeProps = {
  modalLayer: NumberFormat;
  errorMessage: string;
  showError: boolean;
  onChange: any;
  isBusinessSenderConfigured: boolean;
  value: string;
};

export const IntegratedInboxSenderType =
  React.memo<IntegratedInboxSenderTypeProps>(
    ({
      modalLayer,
      errorMessage,
      showError,
      isBusinessSenderConfigured,
      value,
      ...props
    }) => {
      const { t } = useTranslation();

      const options = useMemo(
        () => [
          { value: SENDER_TYPE.BUSINESS, label: t('Business Default') },

          {
            value: SENDER_TYPE.SPECIFIC_INTEGRATED_INBOX,
            label: t('Integrated Inbox'),
          },
        ],
        [t]
      );

      return (
        <TopLayerSelect
          modalLayer={modalLayer}
          variant="underline"
          labelInfo={
            !isBusinessSenderConfigured && value === SENDER_TYPE.BUSINESS
              ? t('Warning: Business has no default inbox configured.')
              : null
          }
          labelInfoPlacement="top"
          label={t('Sender Type')}
          options={options}
          validate={{ message: errorMessage, showMessage: showError }}
          value={value}
          {...props}
        />
      );
    }
  );
