import { getActivity, getActivityFields } from '@kizen/api/activities';
import { QueryClient, UseQueryOptions, useQuery } from 'react-query';
import { ACTIVITIES } from 'queries/query-keys';
import AxiosService from 'services/AxiosService';

type Activity = Awaited<ReturnType<typeof getActivity>>['data'];

type ActivityFields = Awaited<ReturnType<typeof getActivityFields>>['data'];

export const clearActivityQuery = (queryClient: QueryClient, activityId: string) => {
  queryClient.setQueryData(ACTIVITIES.ACTIVITY(activityId), undefined)
}

export const clearActivityFieldsQuery = (queryClient: QueryClient, activityId: string) => {
  queryClient.setQueryData(ACTIVITIES.ACTIVITY_FIELDS(activityId), undefined)
}

export const useActivityQuery = <TData = Activity>(
  activityId: string,
  options?: UseQueryOptions<Activity, unknown, TData>
) => {
  return useQuery({
    queryKey: ACTIVITIES.ACTIVITY(activityId),
    queryFn: async () => {
      const response = await getActivity(AxiosService, activityId, {
        skipErrorBoundary: true,
      });
      return response.data;
    },
    ...options,
  });
};

export const useActivityFieldsQuery = <TData = ActivityFields>(
  activityId: string,
  options?: UseQueryOptions<ActivityFields, unknown, TData>
) => {
  return useQuery({
    queryKey: ACTIVITIES.ACTIVITY_FIELDS(activityId),
    queryFn: async () => {
      const response = await getActivityFields(AxiosService, activityId);
      return response.data;
    },
    ...options,
  });
};
