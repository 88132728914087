import { useState, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useRelationshipSelect,
  useSelectTypeaheadWithScroll,
} from 'components/Inputs/Select/hooks';
import { getDataQAForInput } from 'components/Inputs/helpers';
import Select from 'components/Inputs/Select';
import ClearSelectButton from 'components/Inputs/Select/ClearButton';
import ApplySelectButton from 'components/Inputs/Select/ApplyButton';
import LinkedSelection from 'components/Inputs/Select/LinkedSelection';
import { TopMenuButton } from 'components/Kizen/Menu';
import { getUserLabel } from 'utility/TransformToSelectOptions';
import CreateEntityModalShort, {
  CONTACT,
} from 'components/Modals/CreateEntity/ShortForm';
import { useEntityCreationModal } from 'components/Modals/CreateEntity/hook';
import { useCustomSelectMenuProps } from 'hooks/keyboardEventHandler/useCustomSelectMenuProps';
import { useKeyBoardContext } from 'hooks/keyboardEventHandler/useKeyBoardContext';
import { RELATION_FIELD } from 'queries/query-keys';
import { useQueryClient } from 'react-query';

const ContactSelector = ({
  fieldId,
  value: valueProp,
  onSetClientOption,
  label,
  field: fieldProp,
  onFocus,
  disabled,
  onMenuOpen,
  onMenuClose,
  ...rest
}) => {
  const { t } = useTranslation();
  const { assignFieldHandle } = useKeyBoardContext();
  const selectRef = useRef(null);
  const queryClient = useQueryClient();

  // trans form the value so it works with useRelationSelect etc mainly value ==> id
  const field = { ...fieldProp, relation: { fetchUrl: fieldProp?.fetchUrl } };
  const value = useMemo(() => {
    return valueProp
      ? {
          label: valueProp.label,
          id: valueProp.id || valueProp.value,
          value: valueProp.value,
        }
      : valueProp;
  }, [valueProp]);

  const [showEntityCreateModal, setShowEntityCreateModal] = useState(false);

  const { initialValues, setInitialValues, parseTypeAhead } =
    useEntityCreationModal();

  const [{ displayValue: displayWithoutMeta, handleChange, relationship }] =
    useRelationshipSelect({
      value,
      onChange: onSetClientOption,
      field,
    });

  const displayValue = useMemo(
    () =>
      displayWithoutMeta && {
        ...displayWithoutMeta,
        // See LinkedSelection definition
        meta: {
          link: relationship.link && relationship.link(value),
        },
      },
    [relationship, displayWithoutMeta, value]
  );

  const [typeaheadProps, { loading }, { refetch }] =
    useSelectTypeaheadWithScroll({
      fetch: relationship.search,
      objectToOption: (object) => {
        if (object) {
          return { value: object.id, label: getUserLabel(object) };
        }
        return null;
      },
      onFocus,
      fieldId: field.id,
      onMenuOpen,
      onMenuClose,
      selectRef,
      relatedObjectId: field.id,
    });

  const contactCreatedHandler = useCallback(
    async (newContact) => {
      await refetch();
      // need to invalidate all the fields that are related to this object
      await queryClient.invalidateQueries({
        queryKey: [...RELATION_FIELD.RELATED_OBJECTS(field.id)],
      });
      onSetClientOption({
        value: newContact.id,
        label: newContact.displayName,
      });
      setInitialValues({});
    },
    [field.id, onSetClientOption, queryClient, refetch, setInitialValues]
  );

  const { setMenuIsOpen, ...customSelectMenuProps } = useCustomSelectMenuProps(
    selectRef,
    rest
  );
  assignFieldHandle(fieldId, {
    customFocus: () => {
      selectRef.current?.select?.focus();
      setMenuIsOpen(false);
      return selectRef.current?.select;
    },
    customEscape: () => {
      setMenuIsOpen(false);
    },
    customUp: () => {
      setMenuIsOpen(true);
    },
    customDown: () => {
      setMenuIsOpen(true);
    },
    disabled,
  });

  return (
    <>
      <Select
        ref={selectRef}
        label={label}
        placeholder={t('Find contact')}
        value={displayValue}
        onChange={handleChange}
        disabled={disabled}
        menuLeftButton={<ClearSelectButton />}
        menuRightButton={<ApplySelectButton />}
        components={{
          SingleValue: LinkedSelection,
        }}
        menuTopButton={
          <TopMenuButton
            onClick={() => {
              parseTypeAhead(typeaheadProps.inputValue || '', true);
              setShowEntityCreateModal(true);
            }}
          >
            {t('Add Contact')}
          </TopMenuButton>
        }
        {...rest}
        {...getDataQAForInput(label, 'relationship')}
        {...customSelectMenuProps}
        {...typeaheadProps}
        isLoading={loading}
      />

      {showEntityCreateModal ? (
        <CreateEntityModalShort
          objectType={CONTACT}
          show={showEntityCreateModal}
          onCreated={contactCreatedHandler}
          onCancel={() => {
            setShowEntityCreateModal(false);
            setInitialValues({});
          }}
          initialValues={initialValues}
        />
      ) : null}
    </>
  );
};

export default ContactSelector;
