import { StyledTwoColumnsRow } from './styles';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useContext, useMemo } from 'react';
import { SmartConnectorContext } from '../../context';
import { GenericOption } from 'pages/SmartConnectors/types';
import Switch from '__components/Kizen/Switch';
import MultiSelect from '__components/Inputs/MultiSelect';

export const DiffCheckingSettings = () => {
  const { t } = useTranslation();
  const { smartConnector, stepData, setStepData } = useContext(
    SmartConnectorContext
  );

  const variableOptions = useMemo(() => {
    return (smartConnector.execution_variables || []).map((variable) => ({
      value: variable.id,
      label: variable.name,
    }));
  }, [smartConnector.execution_variables]);

  const handleChangeDiffChecking = (ev: ChangeEvent<HTMLInputElement>) => {
    const enabled = ev.target.checked;
    setStepData((prev) => ({
      ...prev,
      is_diff_check_enabled: enabled,
    }));
  };

  const handleChangeVariablesToExclude = (values: GenericOption[]) => {
    setStepData((prev) => ({
      ...prev,
      variables_to_exclude_from_diffs: values.map((v) => v.value),
    }));
  };

  return (
    <StyledTwoColumnsRow>
      <Switch
        onChange={handleChangeDiffChecking}
        checked={stepData.is_diff_check_enabled || false}
        removeMargin
        label={t('Only Process Rows that Contain New Data?')}
        tooltip={t(
          'Kizen can optionally run a diff check and conditionally only process rows that contain new data since the last time this connector ran.'
        )}
        textPlacement="regular-top"
      />
      {stepData.is_diff_check_enabled ? (
        <MultiSelect
          label={t('Variables to Exclude from New Data Check')}
          placeholder={t('Find Variables')}
          noOptionsMessage={t('No Variables')}
          value={stepData.variables_to_exclude_from_diffs}
          options={variableOptions}
          onChange={handleChangeVariablesToExclude}
        />
      ) : null}
    </StyledTwoColumnsRow>
  );
};
