import React, {
  useCallback,
  useRef,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import useField from 'hooks/useField';
import { useQueries, useMutation, useQueryClient } from 'react-query';
import * as PropTypes from 'prop-types';
import { useToast, toastVariant } from 'components/ToastProvider';
import styled from '@emotion/styled';
import {
  NotesRichTextEditor,
  parseMentions,
} from 'components/Inputs/NotesRichTextEditor';
import { defaultFonts } from 'components/WYSIWYG';
import { useTranslation } from 'react-i18next';
import LoadingButton from 'components/Button/LoadingButton';
import ClientService from 'services/ClientService';
import ActivityService from 'services/ActivityService';
import { gutters } from 'app/spacing';
import { grayScale, shadowLight } from 'app/colors';
import { fontSizes, KizenTypography } from 'app/typography';
import { useTruncationTooltip } from 'components/Kizen/Tooltip';
import { TextEllipsis } from 'components/Kizen/Table';
import { useModalControl } from 'hooks/useModalControl';
import { NotesContext } from './notesContext';
import { ConfirmSendNotification } from './ScheduleActivityModal';
import { CLIENTS } from 'queries/query-keys';

const NBSP = '\xa0';
const NO_VALUE = '—';

const WRAPPER_HEIGHT = 197;

const Wrapper = styled.div`
  white-space: normal;
  display: flex;
  ${({ mobileView }) =>
    mobileView
      ? 'flex-direction: column;'
      : `
        margin: ${gutters.spacing(2, -2)}px 0 0 0;
        height: ${WRAPPER_HEIGHT}px;
        `}
  ${({ width }) => (width ? `width: ${width - 40}px` : '')}
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ minSize }) => (minSize ? `margin-right: 40px;` : ``)}
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`;

const TableActivityInfo = styled.table`
  width: 100%;

  tr {
    &:last-child {
      td {
        padding-bottom: 0;
      }
    }
  }

  td > a,
  td > p {
    max-width: 200px;
  }
  td {
    padding-bottom: 6px;

    &:first-child {
      min-width: calc(98px + 10px); /* width + padding */
      width: calc(98px + 10px); /* width + padding */
    }
  }
`;

const EditorWrapper = styled.div`
  && {
    flex: 1;
    ${shadowLight}
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    resize: none;
    outline: none;
    ${({ mobileView }) =>
      mobileView ? `margin-top: 17px; ` : `margin-top: 7px; `}
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    font-size: ${fontSizes.text};
    line-height: 115%;
    z-index: 1;
    `;

const SaveWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: right;
  height: 30px;
  padding: 10px;
`;

const SaveButton = styled(LoadingButton)`
  height: unset;
  min-width: 32px;
`;
const Card = styled.div`
  padding: ${gutters.standard};
  ${shadowLight}
  ${({ mobileView }) =>
    mobileView
      ? `margin: 10px 0;`
      : `
      margin-left: 40px;
      margin-right: 40px;
      padding-bottom: 0;`}
  background-color: ${grayScale.white};
  border-radius: 4px;
  z-index: 1;
  width: 100%;
`;

const CardItemWrapper = styled.div`
  display: inline-block;
  padding-bottom: calc(20px - 4px);
  &:nth-child(2n) {
    width: calc(100% - (105px + 20px));
    padding-left: 20px;
  }
  &:nth-child(2n + 1) {
    width: calc(105px + 20px);
    padding-right: 20px;
  }
  p + * {
    display: block;
    margin-top: 6px; // 10px between label and text. Also generalizes a bootstrap thing for <p> to anchors, etc.
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

function CardItem({ label, children, ...others }) {
  return (
    <CardItemWrapper {...others}>
      <KizenTypography>{label}</KizenTypography>
      {children}
    </CardItemWrapper>
  );
}

CardItem.propTypes = {
  label: PropTypes.string.isRequired,
};

function ClientCard({ clientId, contactAccess, mobileView, hide }) {
  const { t } = useTranslation();

  // tooltips
  const [mobilePhoneProps, mobilePhoneTooltip] = useTruncationTooltip();
  const [businessPhoneProps, businessPhoneTooltip] = useTruncationTooltip();
  const [emailProps, emailTooltip] = useTruncationTooltip();
  const [locationProps, locationTooltip] = useTruncationTooltip();

  const [clientQuery, addressQuery] = useQueries([
    {
      queryKey: CLIENTS.ENTITY(clientId),
      queryFn: async () => {
        const data = await ClientService.getById(clientId, {
          skipErrorBoundary: true,
        });
        return data;
      },
      enabled: !!clientId && contactAccess,
    },
    {
      queryKey: CLIENTS.ADDRESSES(clientId),
      queryFn: async () => ClientService.getAddressesById(clientId),
      enabled: !!clientId && contactAccess,
    },
  ]);

  const { client, loading, error } = useMemo(() => {
    return {
      client: {
        ...clientQuery.data,
        addresses: addressQuery.data,
      },
      loading: clientQuery.isLoading || addressQuery.isLoading,
      error: clientQuery.error || addressQuery.error,
    };
  }, [clientQuery, addressQuery]);

  if (error || !contactAccess || !clientId || hide) {
    return null;
  }

  if (loading) {
    return (
      <Card mobileView={mobileView}>
        <CardItem label={t('Title')}>
          <KizenTypography>{NBSP}</KizenTypography>
        </CardItem>
        <CardItem label={t('Location')}>
          <KizenTypography>{NBSP}</KizenTypography>
        </CardItem>
        <CardItem label={t('Mobile Phone')}>
          <KizenTypography>{NBSP}</KizenTypography>
        </CardItem>
        <CardItem label={t('Email')}>
          <KizenTypography>{NBSP}</KizenTypography>
        </CardItem>
        <CardItem label={t('Business Phone')}>
          <KizenTypography>{NBSP}</KizenTypography>
        </CardItem>
        <CardItem />
      </Card>
    );
  }

  const titles = client && client.titles;
  const mobilePhone = client && client.mobilePhone;
  const email = client && client.email;
  const businessPhone = client && client.businessPhone;
  const [address] = (client && client.addresses) || [];

  return mobileView ? (
    <Card mobileView={mobileView}>
      <TableActivityInfo>
        <tr>
          <td>
            <KizenTypography type="small" size="text">
              {t('Title')}
            </KizenTypography>
          </td>
          <td>
            <TextEllipsis type="small" size="text">
              {titles?.map(({ name }) => name)?.join(', ') || NO_VALUE}
            </TextEllipsis>
          </td>
        </tr>
        <tr>
          <td>
            <KizenTypography type="small" size="text">
              {t('Mobile Phone')}
            </KizenTypography>
          </td>
          <td>
            {mobilePhone ? (
              <>
                {mobilePhoneTooltip}
                <TextEllipsis
                  type="anchor"
                  size="text"
                  href={`tel:${mobilePhone}`}
                  {...mobilePhoneProps}
                >
                  {mobilePhone}
                </TextEllipsis>
              </>
            ) : (
              <KizenTypography type="small" size="text">
                {NO_VALUE}
              </KizenTypography>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <KizenTypography type="small" size="text">
              {t('Business Phone')}
            </KizenTypography>
          </td>
          <td>
            {businessPhone ? (
              <>
                {businessPhoneTooltip}
                <TextEllipsis
                  type="anchor"
                  size="text"
                  href={`tel:${businessPhone}`}
                  {...businessPhoneProps}
                >
                  {businessPhone}
                </TextEllipsis>
              </>
            ) : (
              <KizenTypography type="small" size="text">
                {NO_VALUE}
              </KizenTypography>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <KizenTypography type="small" size="text">
              {t('Email')}
            </KizenTypography>
          </td>
          <td>
            {email ? (
              <>
                {emailTooltip}
                <TextEllipsis
                  type="anchor"
                  size="text"
                  href={`mailto:${email}`}
                  {...emailProps}
                >
                  {email}
                </TextEllipsis>
              </>
            ) : (
              <KizenTypography type="small" size="text">
                {NO_VALUE}
              </KizenTypography>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <KizenTypography type="small" size="text">
              {t('Location')}
            </KizenTypography>
          </td>
          <td>
            {address ? (
              <>
                {locationTooltip}
                <TextEllipsis type="small" size="text" {...locationProps}>
                  {[address.city, address.state].filter(Boolean).join(', ')}
                </TextEllipsis>
              </>
            ) : (
              <KizenTypography type="small" size="text">
                {NO_VALUE}
              </KizenTypography>
            )}
          </td>
        </tr>
      </TableActivityInfo>
    </Card>
  ) : (
    <Card mobileView={mobileView}>
      <CardItem label={t('Title')}>
        {(!titles || !titles.length) && (
          <KizenTypography>{NO_VALUE}</KizenTypography>
        )}
        {titles && !!titles.length && (
          <KizenTypography weight="bold">
            {titles.map(({ name }) => name).join(', ')}
          </KizenTypography>
        )}
      </CardItem>
      <CardItem label={t('Location')}>
        {!address && <KizenTypography>{NO_VALUE}</KizenTypography>}
        {address && (
          <KizenTypography weight="bold">
            {[address.city, address.state].filter(Boolean).join(', ')}
          </KizenTypography>
        )}
      </CardItem>
      <CardItem label={t('Mobile Phone')}>
        {!mobilePhone && <KizenTypography>{NO_VALUE}</KizenTypography>}
        {mobilePhone && (
          <KizenTypography type="anchor" href={`tel:${mobilePhone}`}>
            {mobilePhone}
          </KizenTypography>
        )}
      </CardItem>
      <CardItem label={t('Email')}>
        {!email && <KizenTypography>{NO_VALUE}</KizenTypography>}
        {email && (
          <KizenTypography type="anchor" href={`mailto:${email}`}>
            {email}
          </KizenTypography>
        )}
      </CardItem>
      <CardItem label={t('Business Phone')}>
        {!businessPhone && <KizenTypography>{NO_VALUE}</KizenTypography>}
        {businessPhone && (
          <KizenTypography type="anchor" href={`tel:${businessPhone}`}>
            {businessPhone}
          </KizenTypography>
        )}
      </CardItem>
      <CardItem />
    </Card>
  );
}

ClientCard.propTypes = {
  clientId: PropTypes.string,
  mobileView: PropTypes.bool,
};

ClientCard.defaultProps = {
  clientId: null,
  mobileView: false,
};

export const BottomBarNode = ({
  loading,
  onClick,
  dataQA = 'save',
  ...other
}) => {
  const { t } = useTranslation();
  return (
    <SaveWrapper>
      <SaveButton
        loading={loading}
        color="blue"
        noSpace
        variant="text"
        onClick={onClick}
        loadingColor={grayScale.medium}
        data-qa-save-button={dataQA}
        {...other}
      >
        {t('Save')}
      </SaveButton>
    </SaveWrapper>
  );
};

export default function NotesPanel({
  index,
  activity,
  contactAccess,
  onNotesChange,
  updateNoteLoading,
  mobileView,
  scheduledActivityQueryKey,
  activityPageById,
  inModal,
  setCurrentNote,
  containerWidth,
  ...others
}) {
  const { t } = useTranslation();
  const editorRef = useRef(null);
  const [showToast] = useToast();
  const [localNote, setLocalNote] = useField(activity.note, [activity.note]);
  const [modalOpen, { showModal, hideModal }] = useModalControl();
  const queryClient = useQueryClient();

  const {
    setIsDirty: contextSetIsDirty,
    setOriginalNote: contextSetOriginalNote,
    originalNote: contextOriginalNote,
  } = useContext(NotesContext);

  const isDirty = useMemo(
    () => contextOriginalNote !== localNote,
    [contextOriginalNote, localNote]
  );

  const { mutate: saveNotes, isLoading: notesSaving } = useMutation({
    mutationFn: async (notifyMentioned = false) =>
      await ActivityService.v2UpdateScheduledActivities({
        id: activity.id,
        note: localNote,
        mentions: parseMentions(localNote),
        notifyMentioned,
      }),
    onSuccess: () => {
      contextSetOriginalNote(localNote);
      contextSetIsDirty(false);

      onNotesChange(false);
    },
    onError: ({ payload }) => {
      showToast({
        message:
          payload?.response?.data?.message ||
          t(
            'The Activity Notes were not saved. Please try again or contact Kizen support.'
          ),
        variant: toastVariant.FAILURE,
      });
    },
    onSettled: async (updatedActivities) => {
      const updatedActivity = updatedActivities[0];
      await queryClient.cancelQueries({ queryKey: scheduledActivityQueryKey });
      const results = queryClient.getQueryData(scheduledActivityQueryKey);

      const { pages: previousPages, ...rest } = results;

      const updatedPages = structuredClone(previousPages);
      const { pageIndex, resultsIndex } = activityPageById[updatedActivity.id];
      if (updatedPages[pageIndex] && updatedPages[pageIndex].results) {
        updatedPages[pageIndex].results[resultsIndex] = {
          ...updatedActivity,
          associatedFields:
            updatedPages[pageIndex].results[resultsIndex].associatedFields,
        };
      }

      queryClient.setQueryData(scheduledActivityQueryKey, {
        ...rest,
        pages: updatedPages,
      });

      return { ...rest, pages: updatedPages };
    },
  });

  const handleSaveNote = useCallback(async () => {
    if (parseMentions(localNote).length > 0) {
      showModal();
    } else {
      saveNotes();
    }
  }, [localNote, showModal, saveNotes]);

  const handleNoteChange = useCallback(() => {
    const note = editorRef.current.isEmpty()
      ? ''
      : editorRef.current.editor.getHTML();
    setLocalNote(note);
    setCurrentNote(note);
    contextSetIsDirty(true);
  }, [setLocalNote, contextSetIsDirty, setCurrentNote]);

  useEffect(() => {
    editorRef.current.editor?.commands.setContent(activity.note);
  }, [activity.note]);

  const minSize = containerWidth < 700;
  const hideClientCard =
    (minSize && !mobileView) || (!activity?.client && !activity?.client?.id);
  const rightSideWidth = hideClientCard
    ? containerWidth - 80 // without client card we maintain 40 + 40 margin
    : containerWidth / 2 - 60; // with client card we have 40 + 20 margin

  const handleBlur = useCallback(() => {
    if (isDirty) {
      onNotesChange(index, localNote, isDirty);
    }
  }, [localNote, onNotesChange, index, isDirty]);

  return (
    <Wrapper mobileView={mobileView} {...others} width={containerWidth}>
      <Section minSize={minSize} width={rightSideWidth}>
        <KizenTypography weight="bold" margin="none">
          {`${t('Notes')}:`}
        </KizenTypography>
        <EditorWrapper mobileView={mobileView} width={rightSideWidth}>
          <NotesRichTextEditor
            label={null}
            disabled={!activity.access.edit}
            value={localNote}
            onChange={handleNoteChange}
            ref={editorRef}
            initialHeight={98}
            enableResize={false}
            defaultFonts={defaultFonts}
            onBlur={handleBlur}
            bottomBarNode={
              <BottomBarNode
                disabled={!activity.access.edit}
                loading={notesSaving}
                onClick={handleSaveNote}
              />
            }
          />
        </EditorWrapper>
      </Section>
      <ClientCard
        as={Section}
        contactAccess={contactAccess}
        mobileView={mobileView}
        clientId={(activity.client && activity.client.id) || null}
        inModal={inModal}
        hide={hideClientCard}
      />
      {modalOpen && (
        <ConfirmSendNotification
          onCancel={hideModal}
          onDontSend={() => {
            hideModal();
            saveNotes(false);
          }}
          onSend={async () => {
            hideModal();
            saveNotes(true);
          }}
        />
      )}
    </Wrapper>
  );
}

NotesPanel.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    client: PropTypes.shape({ id: PropTypes.string.isRequired }),
    note: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onNotesChange: PropTypes.func.isRequired,
  mobileView: PropTypes.bool,
};

NotesPanel.defaultProps = {
  mobileView: false,
};
