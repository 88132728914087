import { emptyLongDash } from './constants';

export const getStatusName = (status, t) => {
  switch (status) {
    case 'initialize':
      return t('Queued');
    case 'in_progress':
      return t('In progress');
    case 'completed':
      return t('Completed');
    case 'failed':
      return t('Failed');
    default:
      return emptyLongDash;
  }
};
