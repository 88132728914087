import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  SmartConnectorContext,
  SmartConnectorErrors,
  defaultContext,
} from '../../context';
import {
  OnChangeVariableHandler,
  VariableSettings,
  VarsByHeaders,
} from './VariableSettings';
import SmartConnectorService from 'services/SmartConnectorService';
import {
  StyledAddVariableButton,
  StyledBottomButtons,
  StyledVariablesList,
} from './styles';
import {
  ExecutionVariable,
  GenericOption,
  SmartConnectorHeader,
  VariableDataType,
} from '__pages/SmartConnectors/types';
import { StyledLoader } from '../styles';
import { getSmartConnectorVariables, getUniqueName } from '../../helpers';
import { monitoringExceptionHelper } from 'sentry/helpers';
import { useToast } from '__components/ToastProvider';
import { toastVariant } from '__components/ToastProvider';
import { VARIABLE_TYPES } from '__pages/SmartConnectors/constants';

export const VariablesList = () => {
  const { t } = useTranslation();
  const [showToast] = useToast();
  const {
    smartConnector,
    stepData,
    setStepData,
    metaData,
    handleUpdate,
    loading,
    setErrors,
  } = useContext(SmartConnectorContext);

  const [loadingVars, setLoadingVars] = useState(false);
  const [autodetectingVars, setAutodetectingVars] = useState(false);
  const [autodetectedVarsByHeaders, setAtodetectedVarsByHeaders] =
    useState<VarsByHeaders>({});

  const hasVariablesRef = useRef<boolean>(
    !!smartConnector.execution_variables?.length
  );

  const smartConnectorHeadersRef = useRef<SmartConnectorHeader[]>(
    smartConnector.headers || []
  );
  smartConnectorHeadersRef.current = smartConnector.headers || [];

  const getExecutionVariables = useCallback(async () => {
    try {
      const { data } = await SmartConnectorService.generateExecutionVariables(
        smartConnector.id,
        { skipErrorBoundary: true }
      );

      const {
        data: { headers },
      } = await SmartConnectorService.getSmartConnector(smartConnector.id, {
        skipErrorBoundary: true,
      });

      smartConnectorHeadersRef.current = headers || [];

      await handleUpdate(
        Promise.resolve({ headers: smartConnectorHeadersRef.current })
      );

      return data;
    } catch (err) {
      showToast({
        message: t('Failed to get execution variables'),
        variant: toastVariant.FAILURE,
      });
      monitoringExceptionHelper(err, {
        extra: {
          smartConnectorId: smartConnector.id,
        },
      });
      return [];
    }
  }, [smartConnector.id, showToast, t, handleUpdate]);

  useEffect(() => {
    setLoadingVars(true);

    const errors: SmartConnectorErrors['execution_variables'] = [];

    getExecutionVariables()
      .then(async (data: ExecutionVariable[]) => {
        setAtodetectedVarsByHeaders(
          (data || []).reduce((acc, variable) => {
            if (variable.data_source) {
              acc[variable.data_source] = variable;
            }
            return acc;
          }, {} as VarsByHeaders)
        );

        if (!hasVariablesRef.current) {
          hasVariablesRef.current = true;
          const names = getSmartConnectorVariables({
            execution_variables: smartConnector.execution_variables!,
            flow: smartConnector.flow,
          }).map(({ name }) => name);

          setStepData((prev) => ({
            ...prev,
            execution_variables: (data || [])
              .sort(({ display_order: a }, { display_order: b }) => a - b)
              .map((variable) => {
                const uniqueName = getUniqueName(variable.name, names);

                names.push(uniqueName);

                return {
                  ...variable,
                  name: uniqueName,
                };
              }),
          }));
        } else {
          setStepData((prev) => ({
            ...prev,
            execution_variables: (prev.execution_variables || []).map(
              (variable, i) => {
                const dataSourceExistsAndValid =
                  variable.data_source &&
                  smartConnectorHeadersRef.current.some(
                    ({ name }) => name === variable.data_source
                  );
                errors[i] = dataSourceExistsAndValid
                  ? {}
                  : { data_source: [t('Data Source Column Not Found')] };
                return {
                  ...variable,
                  data_source: dataSourceExistsAndValid
                    ? variable.data_source
                    : null,
                };
              }
            ),
          }));

          setErrors((prev) => ({
            ...prev,
            execution_variables: errors,
          }));
        }
      })
      .finally(() => setLoadingVars(false));
  }, [
    getExecutionVariables,
    setStepData,
    setErrors,
    t,
    smartConnector.flow,
    smartConnector.execution_variables,
  ]);

  const sourceLabelsBySourceName = useMemo(() => {
    return (smartConnector.headers || []).reduce(
      (acc, { name, index }) => {
        acc[name] = `${name} (${index})`;
        return acc;
      },
      {} as Record<string, string>
    );
  }, [smartConnector.headers]);

  const dataSourceOptions = useMemo(() => {
    const mappedSources = new Set(
      (stepData.execution_variables || []).map(({ data_source }) => data_source)
    );
    return (smartConnector.headers || [])
      .reduce(
        (acc, { name, index }) => {
          if (mappedSources.has(name)) {
            acc[1].options.push({
              value: name,
              label: `${name} (${index})`,
            });
          } else {
            acc[0].options.push({
              value: name,
              label: `${name} (${index})`,
            });
          }
          return acc;
        },
        [
          { options: [], label: t('Unmapped') },
          { options: [], label: t('Mapped') },
        ] as { options: GenericOption[]; label: string }[]
      )
      .filter(({ options }) => options.length);
  }, [smartConnector.headers, stepData.execution_variables, t]);

  const onChangeVariableSettings: OnChangeVariableHandler = ({
    id,
    prop,
    value,
  }) => {
    let result = value;
    setStepData((prev) => ({
      ...prev,
      execution_variables: (prev.execution_variables || []).map((variable) => {
        if (variable.id === id) {
          // sets unique name with incrementation
          if (prop === 'name') {
            const names = getSmartConnectorVariables({
              execution_variables: [],
              flow: smartConnector.flow,
            }).map(({ name }) => name);
            const nextValue = getUniqueName(
              value as string,
              (prev.execution_variables || []).reduce((acc, variable) => {
                if (variable.id !== id && variable.name) {
                  acc.push(variable.name);
                }
                return acc;
              }, names)
            );

            result = nextValue;

            return {
              ...variable,
              name: nextValue,
            };
          }

          // sets default input/output format based on data type and resets is_array/delimiter
          if (prop === 'data_type') {
            const {
              meta: { default_output_format, default_input_format },
            } =
              metaData.execution_variables.variable_data_types.find(
                (dataType) => dataType.value === value
              ) ||
              defaultContext.metaData.execution_variables
                .variable_data_types[0];
            return {
              ...variable,
              data_type: value as VariableDataType,
              input_format: default_input_format,
              output_format: default_output_format,
              is_array: false,
              array_delimiter: null,
            };
          }

          // sets default delimiter if is_array is checked
          if (prop === 'is_array' && value && !variable.is_array) {
            return {
              ...variable,
              is_array: true,
              array_delimiter:
                metaData.execution_variables.default_array_delimiter,
            };
          }

          return {
            ...variable,
            [prop]: value,
          };
        }
        return variable;
      }),
    }));

    return result;
  };

  const onDeleteVariable = (id: string, index: number) => {
    setStepData((prev) => ({
      ...prev,
      execution_variables: (prev.execution_variables || []).filter(
        (variable) => variable.id !== id
      ),
    }));
    setErrors((prev) => ({
      ...prev,
      execution_variables: (prev.execution_variables || []).filter(
        (_, i) => index !== i
      ),
    }));
  };

  const onAddVariable = () => {
    setStepData((prev) => ({
      ...prev,
      execution_variables: [
        ...(prev.execution_variables || []),
        {
          id: `new_${Date.now()}`,
          name: '',
          data_type: null,
          data_source: null,
          is_array: false,
          required: false,
          input_format: null,
          output_format: null,
          array_delimiter: null,
          display_order: 0,
          type: VARIABLE_TYPES.data_source,
        },
      ],
    }));
  };

  const [variableIndexToScroll, setVariableIndexToScroll] = useState<
    number | null
  >(null);

  const handleAutodetectUnmappedColumns = async () => {
    setAutodetectingVars(true);
    const data = await getExecutionVariables();

    if (data.length) {
      setStepData((prev) => {
        let countAdded = 0;

        const mappedColumnsLookup = (prev.execution_variables || []).reduce(
          (acc, { data_source }) => {
            if (data_source) {
              acc[data_source] = true;
            }
            return acc;
          },
          {} as Record<string, boolean>
        );

        const names = getSmartConnectorVariables({
          execution_variables: smartConnector.execution_variables!,
          flow: smartConnector.flow,
        }).map(({ name }) => name);

        const unmappedVars = (data as ExecutionVariable[]).reduce(
          (acc, variable) => {
            if (
              variable.data_source &&
              !mappedColumnsLookup[variable.data_source]
            ) {
              const uniqueName = getUniqueName(variable.name, names);
              names.push(uniqueName);
              acc.push({
                ...variable,
                name: uniqueName,
              });
              countAdded++;
            }
            return acc;
          },
          [] as ExecutionVariable[]
        );

        const next = {
          ...prev,
          execution_variables: (prev.execution_variables || []).concat(
            unmappedVars
          ),
        };

        if (countAdded > 0) {
          setVariableIndexToScroll((prev.execution_variables || []).length);
        }

        showToast({
          message: t('Added {{countAdded}} new variables', { countAdded }),
          variant: toastVariant.SUCCESS,
        });

        return next;
      });
    }

    setAutodetectingVars(false);
  };

  return (
    <>
      <StyledLoader loading={loading || loadingVars}>
        <StyledVariablesList>
          {(stepData.execution_variables || []).map((variable, i) => {
            return (
              <VariableSettings
                key={variable.id}
                index={i}
                variable={variable}
                dataSourceOptions={dataSourceOptions}
                onChange={onChangeVariableSettings}
                onDelete={onDeleteVariable}
                indexToScroll={variableIndexToScroll}
                setIndexToScroll={setVariableIndexToScroll}
                autodetectedVarsByHeaders={autodetectedVarsByHeaders}
                sourceLabelsBySourceName={sourceLabelsBySourceName}
              />
            );
          })}
        </StyledVariablesList>
        <StyledLoader loading={autodetectingVars} paddingTop="10px" />
        <StyledBottomButtons>
          <StyledAddVariableButton variant="text" onClick={onAddVariable}>
            {t('+ Add Variable')}
          </StyledAddVariableButton>
          <StyledAddVariableButton
            variant="text"
            color="blue"
            onClick={handleAutodetectUnmappedColumns}
          >
            {t('Autodetect Unmapped Columns')}
          </StyledAddVariableButton>
        </StyledBottomButtons>
      </StyledLoader>
    </>
  );
};
