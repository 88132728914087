import { useMemo, useCallback, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';

import {
  useScheduledActivitiesBuilder,
  CUSTOM_FIELD_ITEM_ID,
} from 'components/Wizards/Dashlet/hooks/useScheduledActivitiesBuilder';
import useMetadataKey from 'components/Wizards/shared/hooks/useMetadataKey';

import BaseOption from 'components/MultiColumnBuilder/components/BaseOption';
import IconButtonMenu from 'components/Kizen/IconButtonMenu';
import Icon from 'components/Kizen/Icon';
import MultiColumnBuilder from 'components/MultiColumnBuilder';
import InputControl from 'components/Inputs/InputControl';
import EditableText from 'components/Kizen/EditableText';
import { TextEllipsisWithTooltip } from 'components/Kizen/Table';

import { KizenTypography } from '__app/typography';
import { colorsButton } from 'app/colors';
import { fontWeights } from 'app/typography';

import { RIGHT_ID } from 'components/MultiColumnBuilder/useBasicBuilder';
import { ColumnsHeaderPreview } from 'components/Wizards/shared/components/TableBuilder/components/ColumnsHeaderPreview';
import { CustomFieldModal } from 'pages/Common/components/ColumnModal/CustomFieldModal';

export const MultiColumnBuilderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 420px;
  p {
    margin-bottom: 0 !important;
  }
`;

export const ColumnWrapper = styled.div`
  width: 1020px;
  min-height: 420px:
`;

export const StyledInputControl = styled(InputControl)`
  margin-top: 15px;
  p {
    margin-bottom: 0 !important;
  }
`;

export const TextWrapper = styled.div`
  max-width: ${({ showDropdown }) => (showDropdown ? '290' : '400')}px;
`;

export const EditableField = styled(EditableText)`
  max-width: 100%;
  padding-right: 10px;
`;

export const StyledTextEllipsisWithTooltip = styled(TextEllipsisWithTooltip)`
  flex-grow: 1;
  margin-right: 20px;
  width: 0;

  ${({ isDynamic }) =>
    isDynamic
      ? css`
          color: ${colorsButton.green.default};
        `
      : ''}
`;

const getFalse = () => false;

export const ScheduledActivitiesColumnBuilder = ({
  scrollActvitiyBuilderIntoView,
  columns,
  defaultColumns,
  scheduledActivitiesConfig,
  displayHeader,
  onChange,
  displayDefaultColumns,
}) => {
  const { t } = useTranslation();

  const { builderProps, showModal, setShowModal, customFieldItem } =
    useScheduledActivitiesBuilder({
      settings: columns,
      defaultColumns: defaultColumns,
      displayDefaultColumns,
      t,
    });

  const memoizedScheduledActivitiesConfig = useMemo(
    () => ({
      ...scheduledActivitiesConfig,
      columns: builderProps.rightColumnItems.map((item) => {
        if (item.localizedLabel) {
          delete item.localizedLabel;
        }
        return item;
      }),
    }),
    [scheduledActivitiesConfig, builderProps.rightColumnItems]
  );

  const memoizedScheduledActivitiesConfigRef = useRef(
    memoizedScheduledActivitiesConfig
  );
  useEffect(() => {
    if (
      onChange &&
      !isEqual(
        memoizedScheduledActivitiesConfigRef.current,
        memoizedScheduledActivitiesConfig
      )
    ) {
      onChange(memoizedScheduledActivitiesConfig);
      memoizedScheduledActivitiesConfigRef.current =
        memoizedScheduledActivitiesConfig;
    }
  }, [memoizedScheduledActivitiesConfig, onChange]);

  useMetadataKey(
    'scheduledActivitiesConfig',
    memoizedScheduledActivitiesConfig
  );

  const leftColumnProps = useMemo(
    () => ({
      ...builderProps.leftColumnProps,
      options: [customFieldItem, ...builderProps.leftColumnProps.options]
        .filter(({ fieldId }) => {
          return !fieldId || fieldId === CUSTOM_FIELD_ITEM_ID;
        })
        .map((option) => ({ ...option, label: option.displayName })),
    }),
    [customFieldItem, builderProps.leftColumnProps]
  );

  const { setOptionsSearchTerm, rightColumnProps } = useMemo(
    () => builderProps,
    [builderProps]
  );

  const customFieldMenuOptions = useMemo(
    () => [
      {
        value: 'remove',
        label: t('Remove Column'),
      },
      {
        value: 'edit',
        label: t('Edit Options'),
      },
    ],
    [t]
  );

  const onSelectOption = useCallback(
    ({ value }, swapItemColumn, element, index) => {
      if (value === 'remove') {
        swapItemColumn(index, RIGHT_ID);
      } else if (value === 'edit') {
        setShowModal({
          index,
          ...element,
        });
      }
    },
    [setShowModal]
  );

  const additionalActions = useCallback(
    ({ swapItemColumn, index, element, editValue, childIndex, section }) => {
      const showEdit = section === 'right-column' && element?.fieldId;
      const showDelete =
        section === 'right-column' && !showEdit && !element?.disabled;
      return (
        <>
          {!element?.disabled &&
            swapItemColumn &&
            !element.restricted &&
            showDelete && (
              <div>
                <Icon
                  className="DeleteAction"
                  icon="delete"
                  onClick={() => {
                    editValue(index, childIndex, 'label', element.displayName);
                    swapItemColumn(index, section);
                  }}
                />
              </div>
            )}
          {showEdit && (
            <IconButtonMenu
              title={t('Edit Settings')}
              position="left"
              sizing="dense"
              color={colorsButton.iconGray}
              options={customFieldMenuOptions}
              onChange={(selected) => {
                onSelectOption(
                  { value: selected.value },
                  swapItemColumn,
                  element,
                  index
                );
              }}
              restrictCloseHandler
              overlay
            >
              <Icon icon="three-dot" />
            </IconButtonMenu>
          )}
        </>
      );
    },
    [customFieldMenuOptions, onSelectOption, t]
  );

  const handleCustomFieldModalConfirm = useCallback(
    async ({ index, label, fieldId, objectId, displayName }) => {
      const { editValue } = rightColumnProps;
      editValue(index, undefined, 'id', uuidv4());
      editValue(index, undefined, 'label', label);
      editValue(index, undefined, 'objectId', objectId);
      editValue(index, undefined, 'fieldId', fieldId);
      editValue(index, undefined, 'displayName', displayName);
    },
    [rightColumnProps]
  );

  const onHideModal = useCallback(
    ({ reset = false } = {}) => {
      if (reset) {
        rightColumnProps.removeItem(showModal.index);
      }
      setShowModal(null);
    },
    [rightColumnProps, setShowModal, showModal]
  );

  const scrollColumnEditorToView = useCallback(
    (el) => {
      setTimeout(() => {
        scrollActvitiyBuilderIntoView &&
          el?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    },
    [scrollActvitiyBuilderIntoView]
  );

  return (
    <>
      <ColumnWrapper ref={scrollColumnEditorToView}>
        {displayHeader ? (
          <KizenTypography type="subheader">
            {t('Choose Columns to Display')}
          </KizenTypography>
        ) : null}
        <StyledInputControl label={t('Column Preview')}>
          <ColumnsHeaderPreview columns={rightColumnProps.options} />
        </StyledInputControl>
        <MultiColumnBuilderWrapper>
          <MultiColumnBuilder
            {...builderProps}
            searchable
            testId="edit-columns-builder"
            weight={fontWeights.regular}
            leftColumnProps={leftColumnProps}
            leftHeaderText={t('Available Columns (Drag to Add)')}
            rightHeaderText={t('Active Table Columns')}
            searchPlaceholder={t('Find Options')}
            onChangeSearchTerm={setOptionsSearchTerm}
            disabledDropZoneText={false}
            rollBackDrop={false}
            renderRightEmpty={{
              onDropLabel: t('Place Here'),
              noItems: t(
                'No Columns Currently Selected (Drag and Drop to Add)'
              ),
            }}
            renderLeftEmpty={{
              onDropLabel: t('Drop to Remove'),
              noItems: t('No Options Found'),
            }}
            renderOption={({ element, section, ...providedProps }) => {
              return (
                <BaseOption
                  data-qa={element?.label}
                  iconVisible={getFalse}
                  iconEditable={getFalse}
                  colorEditable={getFalse}
                  element={{
                    ...element,
                    isLocked: element.disabled,
                  }}
                  section={section}
                  additionalActions={additionalActions}
                  setShowModal={setShowModal}
                  {...providedProps}
                >
                  {({ element, editValue, index, childIndex }) => {
                    const isDynamic = element?.fieldId === CUSTOM_FIELD_ITEM_ID;
                    const canEdit = section === 'right-column';
                    return canEdit ? (
                      <TextWrapper>
                        <EditableField
                          value={element.label}
                          onChange={(v) => {
                            editValue(index, childIndex, 'label', v);
                          }}
                          placeholder={t('Enter Display Name')}
                          onBlur={(e) => {
                            if (!e.target.value) {
                              editValue(
                                index,
                                childIndex,
                                'label',
                                element.displayName
                              );
                            }
                          }}
                        />
                      </TextWrapper>
                    ) : (
                      <StyledTextEllipsisWithTooltip isDynamic={isDynamic}>
                        {canEdit ? element.label : element.displayName}
                      </StyledTextEllipsisWithTooltip>
                    );
                  }}
                </BaseOption>
              );
            }}
          />
        </MultiColumnBuilderWrapper>
      </ColumnWrapper>
      {showModal ? (
        <CustomFieldModal
          {...showModal}
          onConfirm={handleCustomFieldModalConfirm}
          onHide={onHideModal}
          editValue={rightColumnProps.editValue}
        />
      ) : null}
    </>
  );
};
