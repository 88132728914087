import styled from '@emotion/styled';
import BasicModal from '__components/Modals/presets/BasicModal';

export const StyledBasicModal = styled(BasicModal)`
  .modal-body {
    overflow: unset;
  }
`;

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
