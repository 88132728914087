import { useCallback, useMemo } from 'react';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import styled from '@emotion/styled';
import Loader from 'components/Kizen/Loader';
import { useScrollIntoView } from 'hooks/use-scroll-into-view';
import { useTranslation } from 'react-i18next';
import RangeInput, { FORMAT_TYPES } from 'components/Kizen/RangeInput';
import { gutters } from 'app/spacing';
import { DndColumns } from './DnDColumns';
import { CONTEXTS } from 'components/Wizards/utils';
import {
  GROUP_IDS,
  LEFT_ID,
} from 'components/MultiColumnBuilder/useBasicBuilder';
import css from '@emotion/css';
import { v4 as uuidv4 } from 'uuid';

export const scrollOptions = {
  behavior: 'smooth',
  block: 'end',
};

const SLIDER_PARAMS = {
  MIN: 300,
  MAX: 600,
  STEP: 1,
};

const WRAPPER_HEGHT = 500; // default height of the text

const StyledRangeInput = styled(RangeInput)`
  padding-top: ${gutters.spacing(3, 4)}px;
  && {
    padding-bottom: ${gutters.spacing(2)}px;
    & > span {
      margin-bottom: ${gutters.spacing(3, 2)}px;
    }
    [data-reach-slider-input][data-orientation='horizontal'] {
      width: 100%;
      min-width: 100%;
      padding-left: ${gutters.spacing(10, 1)}px;
      padding-right: ${gutters.spacing(7, 1)}px;
    }
    [data-reach-slider-marker][data-value='400'] > span {
      right: 20px;
      left: 10px;
    }
  }
`;

const Wrapper = styled.div`
  min-height: ${WRAPPER_HEGHT}px;
  display: flex;
  flex-direction: column;
  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
          opacity: 0.5;
          transition: opacity 250ms linear 500ms;
        `
      : ''}
`;

export const Component = function RelatedObjectFields({
  values,
  onChange,
  allObjects,
  loading,
  dirtyRef,
  relatedObjects,
  disabled,
  onDeletionConfirmed,
  headerPreviewComponent,
  ...rest
}) {
  const { t } = useTranslation();
  const { scrollNext, componentRef: wrapperRef } =
    useScrollIntoView(scrollOptions);

  const objectsById = useMemo(() => {
    return allObjects?.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  }, [allObjects]);

  const relatedIds = useMemo(
    () => relatedObjects.map(({ relatedObject }) => relatedObject),
    [relatedObjects]
  );

  const filteredAllObjects = useMemo(() => {
    return allObjects
      .reduce(
        (acc, item) => {
          acc[Number(!relatedIds.includes(item.id))].items.push({
            ...item,
            id: uuidv4(),
            originalId: item.id,
          });
          return acc;
        },
        [
          {
            label: t('Related Objects'),
            id: GROUP_IDS[0],
            isGroupedItem: true,
            items: [],
          },
          {
            label: t('Other Objects'),
            id: GROUP_IDS[1],
            isGroupedItem: true,
            items: [],
          },
        ]
      )
      .flatMap((group) => {
        return [{ ...group }, ...group.items];
      });
  }, [allObjects, relatedIds, t]);

  const itemsOrdering = useMemo(
    () =>
      allObjects
        .reduce(
          (acc, { id }) => {
            acc[Number(!relatedIds.includes(id))].push(id);
            return acc;
          },
          [[GROUP_IDS[0]], [GROUP_IDS[1]]]
        )
        .flat(),
    [allObjects, relatedIds]
  );

  useUpdateEffect(() => {
    if (!loading) {
      setTimeout(() => {
        scrollNext();
      }, 1);
    }
  }, [loading, scrollNext]);

  const onHandleCollapse = useCallback(
    (values) => {
      onChange((prev) => ({ ...prev, collapse: values }));
    },
    [onChange]
  );

  const onHandleChange = useCallback(
    (values) => {
      onChange((prev) => ({ ...prev, objects: values }));
    },
    [onChange]
  );

  const objectMap = useMemo(
    () =>
      values.objects
        ?.map((item) => ({
          ...item,
          restricted: true,
          originalId: item.originalId || item.id,
        }))
        .filter(({ originalId }) => objectsById[originalId]) || [],
    [values.objects, objectsById]
  );

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <Wrapper ref={wrapperRef} disabled={disabled} {...rest}>
      <DndColumns
        data-qa="related-object-builder"
        key={`${CONTEXTS.objects}`}
        initialLeftColumnItems={filteredAllObjects}
        initialRightColumnItems={objectMap}
        tableHeight={values?.tableHeight}
        editable={true}
        previewLabel={t('Table Preview')}
        searchPlaceholder={t('Find Objects')}
        leftHeaderText={t('Available Objects (Drag to Add)')}
        rightHeaderText={t('Objects/Tabs on Related Table')}
        renderRightEmpty={{
          onDropLabel: t('Place Here'),
          noItems: t('No Objects Currently Selected (Drag and Drop to Add)'),
        }}
        renderLeftEmpty={{
          onDropLabel: t('Drop to Remove'),
          noItems: t('No Options Found'),
        }}
        onChange={onHandleChange}
        onChangeCollapse={onHandleCollapse}
        context={CONTEXTS.objects}
        dirtyRef={dirtyRef}
        grouped
        itemsOrdering={itemsOrdering}
        headerPreviewComponent={headerPreviewComponent}
        onDeletionConfirmed={onDeletionConfirmed}
        immutable={LEFT_ID}
        groupedItems
        {...values}
      />
      <StyledRangeInput
        label={t('Table Height (About 1 Row Every 50px)')}
        value={values?.tableHeight}
        min={SLIDER_PARAMS.MIN}
        max={SLIDER_PARAMS.MAX}
        step={SLIDER_PARAMS.STEP}
        onChange={(data) => {
          onChange({
            ...values,
            tableHeight: data,
          });
          dirtyRef.current = true;
        }}
        tooltipValue={values.tableHeight}
        onTooltipChange={(data) =>
          onChange({
            ...values,
            tableHeight: data,
          })
        }
        formatMarker={FORMAT_TYPES.PIXELS}
      />
    </Wrapper>
  );
};

export const validate = ({ objects }) => {
  return objects?.length && !objects.find(({ label }) => label.trim() === '');
};
