import { useNode } from '@craftjs/core';
import { useLoadNonWebFont } from '@kizen/page-builder/hooks/useLoadNonWebFont';
import { useSanitizedHtml } from '@kizen/page-builder/hooks/useSanitizedHtml';
import {
  ButtonContainer,
  TextContainer,
} from '@kizen/page-builder/nodes/containers';
import {
  ButtonActions,
  SharedViewBuilderButton,
} from '@kizen/page-builder/nodes/Button';
import {
  BaseImage,
  LinkWrapper,
  NoImageIcon,
  ViewImageContainer,
} from '@kizen/page-builder/nodes/Image';
import {
  TextBase,
  useFontFamiliesFromText,
} from '@kizen/page-builder/nodes/Text';
import { useViewerContext } from '@kizen/page-builder/viewer';
import {
  Fragment,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRecordDetailCustomScript } from 'ts-components/ScriptRunner/hooks/useRecordDetailCustomScript';
import { StaticBlockRoot } from '@kizen/page-builder/nodes/Root';
import { SectionContainer } from '@kizen/page-builder/nodes/containers';
import { Cell } from '@kizen/page-builder/nodes/Cell';
import { HomepageRow } from '@kizen/page-builder/nodes/Row';
import { Content } from '@kizen/page-builder/nodes/Content';
import { ViewHTMLBlock } from '@kizen/page-builder/nodes/HTMLBlock';
import { ViewDivider } from '@kizen/page-builder/nodes/Divider';
import { PluginContext } from 'ts-components/Plugins/PluginContext';

const Button = (props) => {
  const { action, scriptId, activityId, automationId, ...rest } = props;
  const {
    javascriptActions,
    entityId,
    objectId,
    handleScriptError,
    onLogActivityButtonClick,
    onStartAutomationButtonClick,
  } = useViewerContext();

  const { plugins } = useContext(PluginContext);

  const [executeScript, internalState] = useRecordDetailCustomScript({
    entityId,
    objectId,
    onError: handleScriptError,
  });

  const { pending } = internalState;

  const handleClick = () => {
    if (action === ButtonActions.SCRIPT_EXECUTION) {
      const jsAction = javascriptActions.find((act) => act.id === scriptId);

      if (jsAction.script) {
        const pluginApp = jsAction.pluginApp?.apiName;
        const config = plugins[pluginApp]?.business_config ?? {};
        executeScript(
          jsAction.script,
          { ...config, pluginId: jsAction.pluginApp?.id },
          { plugin_api_name: pluginApp, script_id: jsAction.id }
        );
      }
    } else if (action === ButtonActions.LOG_ACTIVITY) {
      onLogActivityButtonClick(activityId);
    } else if (action === ButtonActions.START_AUTOMATION) {
      onStartAutomationButtonClick(automationId);
    }
  };

  return (
    <ButtonContainer {...rest}>
      <SharedViewBuilderButton
        onClick={handleClick}
        action={action}
        {...rest}
        disabled={pending}
      />
    </ButtonContainer>
  );
};

const Image = forwardRef((props, ref) => {
  const {
    nodeId,
    src,
    alt,
    link,
    unit,
    display,
    position,
    height,
    width,
    minHeight,
    onNoImageClick,
    clickable = false,
    size,
    naturalHeight,
    naturalWidth,
    action,
    scriptId,
    ...rest
  } = props;

  const isClickable =
    clickable ||
    (action === ButtonActions.URL && Boolean(link)) ||
    (action === ButtonActions.SCRIPT_EXECUTION && Boolean(scriptId));

  const viewerCtx = useViewerContext();
  const { javascriptActions, entityId, objectId, handleScriptError } =
    viewerCtx;

  const [imageError, setImageError] = useState(false);

  const { plugins } = useContext(PluginContext);

  const [executeScript, internalState] = useRecordDetailCustomScript({
    entityId,
    objectId,
    onError: handleScriptError,
  });

  const { pending } = internalState;

  const ImageWrapper = link?.length
    ? ({ children }) => (
        <LinkWrapper
          href={link}
          position={position}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </LinkWrapper>
      )
    : Fragment;

  const handleClick = () => {
    const jsAction = javascriptActions.find((act) => act.id === scriptId);

    if (jsAction?.script) {
      const pluginApp = jsAction.pluginApp?.apiName;
      const config = plugins[pluginApp]?.business_config ?? {};
      executeScript(
        jsAction.script,
        { ...config },
        { plugin_api_name: pluginApp, script_id: jsAction.id }
      );
    }
  };

  useEffect(() => {
    setImageError(false);
  }, [src]);

  return (
    <ViewImageContainer
      ref={ref}
      display={display}
      position={position}
      minHeight={minHeight}
      {...rest}
    >
      <ImageWrapper>
        {imageError ? (
          <NoImageIcon
            isViewerCtx={!Array.isArray(viewerCtx)}
            height={naturalHeight}
            alt={alt}
            onClick={onNoImageClick}
          />
        ) : (
          <BaseImage
            onError={() => setImageError(true)}
            onClick={pending ? undefined : handleClick}
            src={src}
            alt={alt}
            unit={unit}
            height={height}
            width={width}
            size={size}
            naturalHeight={naturalHeight}
            naturalWidth={naturalWidth}
            clickable={isClickable && !pending}
          />
        )}
      </ImageWrapper>
    </ViewImageContainer>
  );
});

const Text = (props) => {
  const {
    rootNode,
    textTransform,
    javascriptActions,
    entityId,
    objectId,
    handleScriptError,
  } = useViewerContext() ?? {};

  const clickHandlersRef = useRef(new Map());
  const rootNodeProps = rootNode?.data?.props ?? {};
  const { editorText } = useNode((node) => {
    const editorText =
      typeof textTransform === 'function'
        ? textTransform(node.data.custom?.text)
        : node.data.custom?.text;
    return { editorText };
  });
  const sanitized = useSanitizedHtml(editorText, { ADD_ATTR: ['target'] });
  const fontFamilies = useFontFamiliesFromText(editorText);
  useLoadNonWebFont(fontFamilies);

  const { plugins } = useContext(PluginContext);

  const [executeScript, internalState] = useRecordDetailCustomScript({
    entityId,
    objectId,
    onError: handleScriptError,
  });

  const { pending } = internalState;

  const refCallback = useCallback(
    (node) => {
      if (node) {
        for (const [elem, handler] of clickHandlersRef.current.entries()) {
          elem.removeEventListener('click', handler);
        }

        clickHandlersRef.current.clear();

        for (const link of node.querySelectorAll('[data-script-id]')) {
          const scriptId = link.getAttribute('data-script-id');
          const action = javascriptActions.find((act) => act.id === scriptId);

          if (action) {
            const handler = () => {
              if (action.script && !pending) {
                const pluginApp = action.pluginApp?.apiName;
                const config = plugins[pluginApp]?.business_config ?? {};
                executeScript(
                  action.script,
                  { ...config },
                  { plugin_api_name: pluginApp, script_id: action.id }
                );
              }
            };
            link.addEventListener('click', handler);
            clickHandlersRef.current.set(link, handler);
          }
        }
      }
    },
    [javascriptActions, executeScript, pending, plugins]
  );

  useEffect(() => {
    const handlers = clickHandlersRef.current;

    return () => {
      for (const [elem, handler] of handlers.entries()) {
        elem.removeEventListener('click', handler);
      }
      handlers.clear();
    };
  }, []);

  return (
    <TextContainer linkColor={rootNodeProps.linkColor} {...props}>
      <TextBase
        ref={refCallback}
        {...props}
        rootNodeFontSize={rootNodeProps.fontSize}
        dangerouslySetInnerHTML={{ __html: sanitized }}
      ></TextBase>
    </TextContainer>
  );
};

export default {
  Button,
  Cell,
  Content,
  Divider: ViewDivider,
  HTMLBlock: ViewHTMLBlock,
  Image,
  Root: StaticBlockRoot,
  Row: HomepageRow,
  Section: SectionContainer,
  Text,
};
