import { useTranslation } from 'react-i18next';
import ConfirmationModal from '__components/Modals/ConfirmationModal';
import Button from '__components/Button';

export type DeactivationModalProps = {
  show: boolean;
  onConfirm: () => Promise<void>;
  onHide: () => void;
  defaultLeftBtnHandler: () => void;
};

export const DeactivationModal = ({
  show,
  onHide,
  onConfirm,
  defaultLeftBtnHandler,
}: DeactivationModalProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      heading={t('Disable SmartConnector To Edit')}
      buttonText={t('Disable and Save')}
      leftBtn={<Button variant="text" color="red" />}
      defaultLeftBtnText={t('Discard Changes')}
      defaultLeftBtnHandler={defaultLeftBtnHandler}
      show={show}
      onHide={onHide}
      onConfirm={onConfirm}
    >
      {t(
        'This SmartConnector is currently active. To make changes, it must be disabled. Are you sure?'
      )}
    </ConfirmationModal>
  );
};
