import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '__components/Inputs/TextInput';
import type {
  Automation,
  Folder,
  FolderPayload,
} from 'store/automationsPage/automations.redux';
import { FolderSelect } from './FolderSelect';
import { ModalContentContainer, StyledBasicModal } from './styles';
import { useFolderNameLimit } from './hooks/useFolderNameLimit';

type EditFolderPayload = {
  id: string;
  name?: string;
  parentFolderId?: string;
};

type EditFolderModalProps = {
  currentDirectory: Folder;
  folder: Folder;
  folders: Folder[];
  rootFolderId: string;
  onConfirm(data: EditFolderPayload): Promise<void>;
  onHide(): void;
  onAddFolder?(data: FolderPayload): Promise<Folder>;
};

type EditAutomationFolderModalProps = {
  automation: Automation;
  currentDirectory: Folder;
  folders: Folder[];
  validParentFolders: Folder[];
  rootFolderId: string;
  onConfirm(data: { folderId: string }): Promise<void>;
  onHide(): void;
  onAddFolder?(data: FolderPayload): Promise<Folder>;
};

type NewFolderModalProps = {
  currentDirectory: Folder;
  folders: Folder[];
  onHide(): void;
  onConfirm(data: {
    name: string;
    parentFolderId: string | null;
  }): Promise<void>;
  onAddFolder?(data: FolderPayload): Promise<Folder>;
};

export const EditFolderModal = ({
  currentDirectory,
  folder: folderProp,
  folders,
  rootFolderId,
  onConfirm,
  onAddFolder,
  onHide,
}: EditFolderModalProps) => {
  const { t } = useTranslation();
  const { inputRef, name, handleChangeName } = useFolderNameLimit(
    folderProp.name
  );
  const [folder, setFolder] = useState<Folder | null>(() => {
    if (!currentDirectory.parentFolderId) {
      return null;
    }
    return folders.find((x) => x.id === currentDirectory.id) ?? null;
  });

  const selectableFolders = useMemo(() => {
    return folders.filter((fldr) => fldr.id !== folderProp.id);
  }, [folders, folderProp.id]);

  return (
    <StyledBasicModal
      show
      heading={t('Edit Folder')}
      disabled={
        !name ||
        (name === folderProp.name && folder?.id === currentDirectory.id)
      }
      onHide={onHide}
      onConfirm={async () => {
        const payload: EditFolderPayload = { id: folderProp.id };
        const parentFolderId = folder ? folder.id : rootFolderId;

        if (folderProp.parentFolderId !== parentFolderId) {
          payload.parentFolderId = parentFolderId;
        }

        // There's a bug in the PATCH endpoint - need to always send name
        // if (folderProp.name !== name) {
        //   payload.name = name;
        // }
        payload.name = name;

        await onConfirm(payload);
      }}
      forceFocus={false}
    >
      <ModalContentContainer>
        <TextInput
          inputRef={inputRef}
          label={t('Folder Name')}
          placeholder={t('Enter folder name')}
          value={name}
          onChange={handleChangeName}
        />
        <FolderSelect
          label={t('Parent Folder')}
          chosenFolder={folder}
          validParentFolders={folders}
          folders={selectableFolders}
          onChange={setFolder}
          onAddFolder={onAddFolder}
        />
      </ModalContentContainer>
    </StyledBasicModal>
  );
};

export const EditAutomationFolderModal = ({
  automation,
  currentDirectory,
  folders,
  validParentFolders,
  rootFolderId,
  onConfirm,
  onHide,
  onAddFolder,
}: EditAutomationFolderModalProps) => {
  const { t } = useTranslation();
  const [folder, setFolder] = useState<Folder | null>(currentDirectory);

  return (
    <StyledBasicModal
      show
      heading={t('Edit {{name}} Folder', { name: automation.name })}
      onHide={onHide}
      onConfirm={async () => {
        const folderId = folder ? folder.id : rootFolderId;
        await onConfirm({ folderId });
      }}
      forceFocus={false}
      disabled={folder?.id === currentDirectory?.id}
    >
      <ModalContentContainer>
        <FolderSelect
          chosenFolder={folder}
          folders={folders}
          validParentFolders={validParentFolders}
          onChange={setFolder}
          onAddFolder={onAddFolder}
        />
      </ModalContentContainer>
    </StyledBasicModal>
  );
};

export const NewFolderModal = ({
  currentDirectory,
  folders,
  onConfirm,
  onHide,
  onAddFolder,
}: NewFolderModalProps) => {
  const { t } = useTranslation();
  const { inputRef, name, handleChangeName } = useFolderNameLimit('');
  const [folder, setFolder] = useState<Folder | null>(() => {
    if (!currentDirectory.parentFolderId) {
      return null;
    }
    return folders.find((x) => x.id === currentDirectory.id) ?? null;
  });

  return (
    <StyledBasicModal
      show
      heading={t('Add Folder')}
      disabled={!name}
      onHide={onHide}
      onConfirm={async () => {
        await onConfirm({ name, parentFolderId: folder?.id ?? null });
      }}
      forceFocus={false}
    >
      <ModalContentContainer>
        <TextInput
          inputRef={inputRef}
          label={t('Folder Name')}
          placeholder={t('Enter folder name')}
          value={name}
          onChange={handleChangeName}
        />
        <FolderSelect
          label={t('Parent Folder')}
          chosenFolder={folder}
          folders={folders}
          validParentFolders={folders}
          onChange={setFolder}
          onAddFolder={onAddFolder}
        />
      </ModalContentContainer>
    </StyledBasicModal>
  );
};
