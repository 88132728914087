import { useCallback, useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { runScript } from '../run';
import { getBusinessClientObject } from 'store/authentication/selectors';
import { WorkerContextArgs } from '../types';
import { useHistory } from 'react-router-dom';
import {
  FloatingFrame,
  PluginContext,
} from 'ts-components/Plugins/PluginContext';
import { useToast } from '__components/ToastProvider';
import { flushSync } from 'react-dom';

interface UseFloatingFrameCustomScriptProps {
  onError: WorkerContextArgs['onError'];
  scriptUIRef?: WorkerContextArgs['scriptUIRef'];
  plugin?: FloatingFrame;
  onChangeMinimized?: (minimized: boolean) => void;
}

export const useFloatingFrameCustomScript = ({
  onError,
  scriptUIRef,
  plugin,
  onChangeMinimized,
}: UseFloatingFrameCustomScriptProps): [
  (...args: any[]) => any,
  {
    pending: boolean;
    hidden: boolean;
    indicator: string;
  },
] => {
  const user = useSelector((s: any) => s.authentication.user);
  const business = useSelector((s: any) => s.authentication.chosenBusiness);
  const clientObject = useSelector(getBusinessClientObject);
  const history = useHistory();
  const [showToast, , clearToasts] = useToast();
  const { terminators, sessionData, setSessionData } =
    useContext(PluginContext);

  const [pending, setPending] = useState(false);
  const [runnerState, setRunnerState] = useState({
    indicator: 'none',
    hidden: false,
  });

  const pendingOnceLock = useRef(false);
  const [pendingLocked, setPendingLocked] = useState(false);
  if (pending && !pendingOnceLock.current) {
    pendingOnceLock.current = true;
  }

  if (!pending && pendingOnceLock.current && !pendingLocked) {
    setPendingLocked(true);
  }

  const executeScript = useCallback(
    (scriptBody: string, args?: Record<string, unknown>) => {
      let stringArgs = '';
      try {
        stringArgs = JSON.stringify(args);
      } catch (e) {
        onError?.({ message: 'Arguments passed to the script are invalid' });
      }

      return runScript({
        user,
        business,
        onError,
        setLoadingState: (...args) => flushSync(() => setPending(...args)),
        scriptBody,
        clientObject,
        scriptUIRef,
        onStateChange: (state) => {
          const { minimized, ...rest } = state ?? {};
          if (typeof minimized === 'boolean') {
            onChangeMinimized?.(minimized);
          }
          setRunnerState((s) => {
            return { ...s, ...rest };
          });
        },
        workerName: 'floatingFramePlugin',
        args: stringArgs,
        history,
        plugin,
        onShowToast: showToast,
        onClearToasts: clearToasts,
        terminators,
        sessionData,
        setSessionData,
      });
    },
    [
      user,
      business,
      onError,
      clientObject,
      scriptUIRef,
      history,
      plugin,
      onChangeMinimized,
      showToast,
      clearToasts,
      terminators,
      sessionData,
      setSessionData,
    ]
  );

  return [
    executeScript,
    {
      ...runnerState,
      pending: pendingLocked ? false : pending,
    },
  ];
};
