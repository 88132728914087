import { BusinessCalendar } from './subpages/BusinessCalendar';
import { BusinessSettings } from './subpages/BusinessSettings';
import {
  BASE_BUSINESS_SETTINGS_PATH,
  BUSINESS_CALENDAR_PATH,
  BUSINESS_EXPORT_IMPORT_PATH,
} from './constants';
import { BusinessExportImport } from './subpages/BusinessExportImport';

export const subroutes = {
  [BASE_BUSINESS_SETTINGS_PATH]: {
    path: BASE_BUSINESS_SETTINGS_PATH,
    label: (t) => t('Settings'),
    component: BusinessSettings,
    exact: true,
    accessPaths: {
      businessInformation: 'edit',
      businessCustomization: 'edit',
    },
  },
  [BUSINESS_CALENDAR_PATH]: {
    path: BUSINESS_CALENDAR_PATH,
    label: (t) => t('Calendar'),
    component: BusinessCalendar,
    exact: true,
    accessPaths: { businessHolidays: 'view' },
  },
  [BUSINESS_EXPORT_IMPORT_PATH]: {
    path: BUSINESS_EXPORT_IMPORT_PATH,
    label: (t) => t('Export/Import'),
    component: BusinessExportImport,
    exact: true,
    accessPaths: { metaExportImport: 'edit' },
  },
};
