import { ReactNode } from 'react';
import styled from '@emotion/styled';

import { Text } from 'app/typography';
import { colorsPrimary } from 'app/colors';
import { EmailStatus } from './types';

const colorMyStatus = (statusCode: EmailStatus): string => {
  const badColor = colorsPrimary.orange.dark;
  const goodColor = colorsPrimary.green.dark;

  const mappings = {
    ['error_reading_updates']: badColor,
    ['error_downloading_history']: badColor,
    ['not_setup']: badColor,
    ['subscribed_to_updates']: goodColor,
    ['downloading_history']: goodColor,
  };

  return mappings[statusCode] || goodColor;
};

type StatusCellProps = {
  children: ReactNode;
  statusCode: EmailStatus;
};

export const StatusCell = styled(
  ({ children, statusCode, ...props }: StatusCellProps) => (
    <Text color={colorMyStatus(statusCode)} {...props}>
      {children}
    </Text>
  )
)`
  line-height: 100%;
`;

export const FlexDiv = styled.div<{ gap?: string }>`
  ${({ gap }) => (gap ? `gap: ${gap};` : '')}
  display: flex;
`;
