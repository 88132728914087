import { ACTIONS, COMMUNICATIONS } from './constants';

const freshWorksPattern = /https:\/\/.*widget\.freshworks\.com\/.*\.js/;

export const ALLOWED_INTEGRATIONS = {
  FRESHWORKS: 'freshworks',
} as const;

/*
    Third party scripts must match an approved regex, and arbitrary scripts cannot be
    loaded by a plugin if they do not.
*/
export const getScriptIntegrationType = (scriptUrl: string) => {
  if (freshWorksPattern.test(scriptUrl)) {
    return ALLOWED_INTEGRATIONS.FRESHWORKS;
  }
};

/* 
    The global name of the third-party integration. This is the variable where the
    integration code can be called from. For example, if the third-party integration
    is 'Freshworks', function calls will be made like window.FreshworksWidget(...args).
*/
export const thirdPartyGlobalNames = {
  [ALLOWED_INTEGRATIONS.FRESHWORKS]: 'FreshworksWidget',
};

/*
    For a predefined third-party integration, the setup script will be run
    BEFORE the third party javascript has been loaded.
*/
export const thirdPartySetupScripts = {
  [ALLOWED_INTEGRATIONS.FRESHWORKS]: (args: Record<string, any>) => {
    (window as any).fwSettings = {
      widget_id: args.widgetId,
    };

    // Function adapted from https://developers.freshdesk.com/widget-api/#introduction
    (function (...rest) {
      if (
        'function' !=
        typeof (window as any)[
          thirdPartyGlobalNames[ALLOWED_INTEGRATIONS.FRESHWORKS]
        ]
      ) {
        const n: any = function () {
          n.q.push(...rest);
        };

        n.__kizen_pending = true;

        (n.q = []),
          ((window as any)[
            thirdPartyGlobalNames[ALLOWED_INTEGRATIONS.FRESHWORKS]
          ] = n);
      }
    })();
  },
};

/*
    For a predefined third-party integration, the ready predicate will be run
    AFTER the third party javascript has been loaded, but before the worker has been
    notified that it is ready. This is optional, but gives a chance to wait for an arbitrary
    condition to be met before considering the third-party code initialized successfully.
*/
export const thirdPartyReadyPredicates = {
  [ALLOWED_INTEGRATIONS.FRESHWORKS]: () => {
    return (
      (window as any)[thirdPartyGlobalNames[ALLOWED_INTEGRATIONS.FRESHWORKS]]
        ?.__kizen_pending !== true
    );
  },
};

export class ThirdPartyScript {
  private instance: any;
  private scriptUrl: string;
  private integration: (typeof ALLOWED_INTEGRATIONS)[keyof typeof ALLOWED_INTEGRATIONS];

  constructor(instance: any, scriptUrl: string) {
    this.instance = instance;
    this.scriptUrl = scriptUrl;
    const type = getScriptIntegrationType(scriptUrl);

    if (type) {
      this.integration = type;
    } else {
      throw new Error(`Error: Disallowed script url: ${scriptUrl}`);
    }
  }

  get type() {
    return this.integration;
  }

  public call(...params: any[]) {
    this.instance.postMessage(
      JSON.stringify({
        action: ACTIONS.COMMUNICATE,
        type: COMMUNICATIONS.CALL_THIRD_PARTY_SCRIPT,
        eventName: `thirdParty:${COMMUNICATIONS.CALL_THIRD_PARTY_SCRIPT}`,
        recipient: {
          script: this.scriptUrl,
          type: this.type,
        },
        params,
      })
    );
  }
}
