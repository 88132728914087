import { useMemo } from 'react';
import {
  SizedCell,
  SortableHeadCell,
  TextEllipsisWithTooltip,
} from 'components/Kizen/Table';
import styled from '@emotion/styled';
import ActionCell from 'components/Tables/ActionCell';
import ValidatedField from 'components/Kizen/ValidatedField';
import DateTimeInputInline from 'components/Inputs/inline/DateTimeInput';
import {
  getCustomObjectLinkProps,
  getQueryFilterLinkProps,
} from 'pages/Common/helpers/formsAndSurveys';

const emptyLongDash = '—';

export const SURVEY_ACTIONS = (t) => ({
  Edit: { value: 'edit', label: t('Edit') },
  Preview: { value: 'preview', label: t('Preview') },
  Duplicate: { value: 'duplicate', label: t('Duplicate') },
  Delete: { value: 'delete', label: t('Delete') },
});

const SurveysActionCell = ({ data, createEntityAccess, t, ...others }) => {
  const options = useMemo(() => {
    const surveyActions = SURVEY_ACTIONS(t);
    return createEntityAccess[data.relatedObject?.id]
      ? [
          surveyActions.Edit,
          surveyActions.Preview,
          surveyActions.Duplicate,
          surveyActions.Delete,
        ]
      : [surveyActions.Preview];
  }, [data.relatedObject?.id, createEntityAccess, t]);

  return (
    <ActionCell
      title={t('Edit Survey')}
      data={data}
      options={options}
      {...others}
    />
  );
};

const ActionHeadCell = styled(({ t, ...other }) => (
  <SizedCell {...other}>
    <TextEllipsisWithTooltip as="span" weight="bold">
      {t('Actions')}
    </TextEllipsisWithTooltip>
  </SizedCell>
))`
  text-align: right;
`;

const getSurveysColumns = ({
  createEntityAccess,
  onUpdateField,
  onSelectAction,
  t,
}) => [
  {
    id: 'spacer',
    headCell: <SizedCell width="20px" />,
    cell: <SizedCell width="20px" />,
  },
  {
    id: 'name',
    headCell: (
      <SortableHeadCell
        by="name"
        label={t('Survey Name')}
        minWidth="302px"
        padding="60px"
      />
    ),
    cell: <SizedCell minWidth="302px" padding="60px" data-qa="Survey Name" />,
    format: (name, survey) => {
      const { id } = survey;
      return createEntityAccess[survey.relatedObject?.id] ? (
        <ValidatedField
          type="link"
          to={`/surveys/${id}`}
          value={name}
          onSubmit={(value) => {
            if (!value) {
              throw new Error('Survey name cannot be blank.');
            }
            if (survey.name === value) {
              return null;
            }
            return onUpdateField(survey, { name: value });
          }}
        />
      ) : (
        <TextEllipsisWithTooltip>{name}</TextEllipsisWithTooltip>
      );
    },
  },
  {
    id: 'objectName',
    headCell: (
      <SortableHeadCell
        by="related_object__object_name"
        label={t('Survey Object')}
        minWidth="200px"
        padding="30px"
      />
    ),
    cell: <SizedCell minWidth="200px" padding="30px" data-qa="Survey Object" />,
    format: (_, { relatedObject }) => {
      const linkProps = getCustomObjectLinkProps(relatedObject);
      return (
        <TextEllipsisWithTooltip {...linkProps} data-qa="survey-object">
          {relatedObject?.objectName || emptyLongDash}
        </TextEllipsisWithTooltip>
      );
    },
  },
  {
    id: 'numberSubmissions',
    headCell: (
      <SortableHeadCell
        numeric
        by="number_submissions"
        label={`# ${t('Submissions')}`}
        minWidth="125px"
        padding="250px"
      />
    ),
    cell: (
      <SizedCell minWidth="125px" padding="250px" data-qa="# Submissions" />
    ),
    format: (value, survey) => (
      <TextEllipsisWithTooltip
        {...getQueryFilterLinkProps(survey)}
        data-qa="number-submissions"
      >
        {value || 0}
      </TextEllipsisWithTooltip>
    ),
  },
  {
    id: 'created',
    headCell: (
      <SortableHeadCell
        numeric
        by="created"
        label={t('Date Created')}
        width="150px"
        padding="60px"
      />
    ),
    cell: <SizedCell minWidth="150px" padding="60px" data-qa="Date Created" />,
    format: (created) => (
      <DateTimeInputInline readOnly value={created} showDateTooltip />
    ),
  },
  {
    id: 'actions',
    headCell: <ActionHeadCell width="90px" padding="20px" t={t} />,
    cell: (
      <SurveysActionCell
        width="47px"
        padding="20px"
        onSelectAction={onSelectAction}
        menuType="absolute"
        t={t}
        data-qa="Actions"
        createEntityAccess={createEntityAccess}
      />
    ),
  },
];

export default getSurveysColumns;
