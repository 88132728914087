import { useState, useMemo, useCallback, useEffect, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { KizenTypography } from '__app/typography';
import useField from 'hooks/useField';
import useSearchParam, { setSearchParams } from 'hooks/useSearchParam';
import useDebounce from 'react-use/lib/useDebounce';

import useModal from '__components/Modals/useModal';
import ConfirmDeletionModal from '__components/Modals/presets/ConfirmDeletion';

import { SharingAccessModal } from '__components/Modals/SharingAccessModal';
import TableScrollContainer from '__components/Tables/ScrollContainer';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import { DEFAULT_INPUT_DELAY } from 'utility/config';

import CardToolbar, {
  CardToolbarTitle,
  CardToolbarSection,
  CardToolbarSearch,
} from '__components/Layout/CardToolbar';
import SmallTableCard from '__components/Layout/SmallTableCard';
import { TRow } from '__components/Kizen/Table';
import BasicTable from '__components/Tables/Basic';
import { getColumns } from './columns';

import { DELETE_OUTGOING } from '../consts';
import UUID from 'utility/UUID';
import IntegratedEmailControl from '__components/Modals/ThirdPartyAuth';
import {
  ScrollContainerBlocker,
  ScrollContainerBlockerWrapper,
} from 'components/Tables/ScrollContainerStyles';
import { IconWithTooltip } from '__pages/Common/components/LinkWithIconTooltip/IconWithTooltip';
import { ACTION_VALUES } from './helpers';
import { useSharingAccessModal } from './hooks/useSharingAccessModal';
import { FlexDiv } from './styles';

interface iSetOrderingSearchParams {
  (
    param: string,
    history: any,
    ordering: { column: string; direction: string }
  ): void;
}

const setOrderingSearchParams: iSetOrderingSearchParams = (
  param,
  history,
  { column, direction }
) => {
  setSearchParams(history, {
    [param]: getOrderingParam({ column, direction }),
  });
};

const SORT_PARAM = 'sort_integrated_emails';

interface iSaveBlockedEmails {
  (updatedblockedEmails: any[], errorMessage: string): void;
}

interface iOnAccountDelete {
  (id: string): void;
}

interface iOnUpdateExternalAccounts {
  ({
    ordering,
    search,
    include_sharing,
  }: {
    ordering: string;
    search: string;
    include_sharing: boolean;
  }): void;
}

type DeletedEmails = {
  updatedblockedEmails?: { blockOutgoingEmailsFrom?: any };
  errorMessage: string;
};

interface iIntegratedEmail {
  externalAccounts: any[];
  saveBlockedEmails: iSaveBlockedEmails;
  blockedEmails: {
    blockIncomingEmailsFrom: any[];
    blockOutgoingEmailsFrom: any[];
    enableInternalEmailSync: boolean;
  };
  access: any;
  onAccountDelete: iOnAccountDelete;
  businessId: string;
  onUpdateExternalAccounts: iOnUpdateExternalAccounts;
  externalAccountsLoading: boolean;
}

const IntegratedEmail: FC<iIntegratedEmail> = ({
  externalAccounts,
  saveBlockedEmails,
  blockedEmails,
  access,
  onAccountDelete,
  businessId,
  onUpdateExternalAccounts,
  externalAccountsLoading,
}) => {
  const { t } = useTranslation();

  const [stagedBlockedEmails, setStagedBlockedEmails] = useField(
    blockedEmails,
    [blockedEmails]
  );

  const history = useHistory();
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const {
    sharingAccessModalProps,
    onShowSharingAccessModal,
    currentlyEdititingSharingSettings,
    setEditId,
    integratedAccountLoading,
    handleShowEdit,
  } = useSharingAccessModal();

  const ordering = useSearchParam(SORT_PARAM) || 'email';
  const sort = useMemo(() => getSortValue(ordering), [ordering]);

  const search = useSearchParam('q') || '';

  const handleChangeSearch = useCallback(
    (q: string) => {
      setSearchParams(history, { q }, { method: 'replace' });
    },
    [history]
  );

  const [debouncedSearch, setDebouncedSearch] = useState<string>(search);
  useDebounce(() => setDebouncedSearch(search), DEFAULT_INPUT_DELAY, [search]);

  useEffect(() => {
    onUpdateExternalAccounts({
      ordering,
      search: debouncedSearch,
      include_sharing: true,
    });
  }, [onUpdateExternalAccounts, ordering, debouncedSearch]);

  const handleSort = useCallback(
    (order: any) => {
      setOrderingSearchParams(SORT_PARAM, history, order);
    },
    [history]
  );

  const handleUpdate = useCallback(
    async (value: any, errorMessage: string) => {
      const updatedblockedEmails = { ...stagedBlockedEmails, ...value };
      // optimistaclly update the stage values, after saving the current values
      const saved = stagedBlockedEmails;
      setStagedBlockedEmails(updatedblockedEmails);
      try {
        await saveBlockedEmails(updatedblockedEmails, errorMessage);
      } catch (error) {
        // undo the optimistic update
        setStagedBlockedEmails(saved);
      }
    },
    [stagedBlockedEmails, setStagedBlockedEmails, saveBlockedEmails]
  );

  const [deleteValues, setDeleteValues] = useState<DeletedEmails | null>(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [deleteModalProps, , deleteModal] = useModal({
    handleSubmit: () => {
      const { updatedblockedEmails, errorMessage } = deleteValues!;
      handleUpdate(updatedblockedEmails, errorMessage); // handleUpdate is dealing with any errors so we don't need to worry about it here
    },
  });

  const blockedExternalAccounts = useMemo(() => {
    const blockedIds = stagedBlockedEmails.blockOutgoingEmailsFrom.reduce(
      (collect: any, o: any) => ({ ...collect, [o.id]: true }),
      {}
    );
    return externalAccounts.map((account) => ({
      ...account,
      blocked: !!blockedIds[account.id],
    }));
  }, [externalAccounts, stagedBlockedEmails]);

  const [confirmDeletionModalProps, , confirmDeletionModal] = useModal({
    handleSubmit: () => onAccountDelete(deleteId!),
  });

  const columns = useMemo(() => {
    return getColumns({
      onSelectAction: (
        { value: action }: { value: string },
        integratedEmail: any
      ) => {
        const { id, email } = integratedEmail;

        if (action === ACTION_VALUES.delete) {
          setDeleteId(id);
          confirmDeletionModal.show();
        }

        if (action === ACTION_VALUES.edit) {
          setEditId(id);
          onShowSharingAccessModal();
        } else if (action === ACTION_VALUES.block) {
          const newState = {
            blockOutgoingEmailsFrom: [
              ...stagedBlockedEmails.blockOutgoingEmailsFrom,
              { id, email },
            ],
          };

          handleUpdate(
            newState,
            t('The outgoing emails were not successfully updated.')
          );
        } else if (action === ACTION_VALUES.unblock) {
          const newState = {
            blockOutgoingEmailsFrom:
              stagedBlockedEmails.blockOutgoingEmailsFrom.filter(
                (outgoingEmail: any) => outgoingEmail.id !== id
              ),
          };

          setDeleteMessage(DELETE_OUTGOING(t));
          setDeleteValues({
            updatedblockedEmails: newState,
            errorMessage: t(
              'The outgoing emails were not successfully updated.'
            ),
          });
          deleteModal.show();
        }
      },
      t,
      access,
      showEdit: handleShowEdit,
    });
  }, [
    t,
    access,
    handleShowEdit,
    confirmDeletionModal,
    setEditId,
    onShowSharingAccessModal,
    stagedBlockedEmails.blockOutgoingEmailsFrom,
    handleUpdate,
    deleteModal,
  ]);

  const headData = useMemo(
    () => ({
      meta: {
        sort,
        onSort: (column: string, direction: string) =>
          handleSort({ column, direction }),
      },
    }),
    [sort, handleSort]
  );

  if (!externalAccounts) {
    return null;
  }

  return (
    <>
      <SmallTableCard>
        <CardToolbar tall>
          <FlexDiv gap="5px">
            <CardToolbarTitle>{t('Integrated Inboxes')}</CardToolbarTitle>
            <IconWithTooltip
              label={t(
                'You will be able to edit sharing settings only for integrated inboxes you have Admin access to.'
              )}
              placement="top"
              icon="info-circle"
            />
          </FlexDiv>
          <CardToolbarSection>
            <CardToolbarSearch
              placeholder={t('Find Email')}
              value={search}
              onChange={handleChangeSearch}
              loading={externalAccountsLoading}
            />

            <IntegratedEmailControl
              businessId={businessId}
              useCurrentTeamMember
            />
          </CardToolbarSection>
        </CardToolbar>
        <ScrollContainerBlockerWrapper className="scroll-container-blocker-wrapper">
          <ScrollContainerBlocker />
        </ScrollContainerBlockerWrapper>
        <TableScrollContainer bottom refresh={[blockedExternalAccounts]}>
          <BasicTable
            stickyHeader
            height={0}
            head={<TRow head columns={columns} data={headData} />}
          >
            {(blockedExternalAccounts || []).map((integratedEmail) => (
              <TRow
                key={`${integratedEmail.id} ${UUID.generate()}`}
                columns={columns}
                data={integratedEmail}
              />
            ))}
          </BasicTable>
        </TableScrollContainer>
      </SmallTableCard>
      <ConfirmDeletionModal {...confirmDeletionModalProps}>
        <KizenTypography>
          {t(
            'This will permanently delete the integrated email and will remove associated emails on the timeline.'
          )}
        </KizenTypography>
      </ConfirmDeletionModal>
      <ConfirmDeletionModal {...deleteModalProps}>
        {deleteMessage}
      </ConfirmDeletionModal>
      {sharingAccessModalProps.show ? (
        <SharingAccessModal
          {...sharingAccessModalProps}
          existing={currentlyEdititingSharingSettings}
          isEditing={Boolean(currentlyEdititingSharingSettings?.id)}
          loading={integratedAccountLoading}
        />
      ) : null}
    </>
  );
};

export default IntegratedEmail;
