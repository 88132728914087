import { UrlConfig } from './types';
import { Filter } from './filter';
import { Metadata } from './meta-data';
import { isUrlConfig, isUrlConfigArray } from './checks';
import { mergeEntries } from './utils';
import { FilterSet } from './filter-sets';

export type FilterConfig = {
  and: boolean;
  query: { and: boolean; filters: any[]; id: string }[];
};

export const getFilterTypeStepDataFromSave = ([step, { filter_type }]: [
  string,
  { filter_type: string },
]): [string, any] => {
  return [step, filter_type];
};

/**
 * A simplified version of `loadSavedFilter` that does not load nested filters.
 *
 * @param fetchUrlConfig
 * @param view_model
 * @returns
 */
export const loadSteps = async (
  fetchUrlConfig: (config: UrlConfig) => Promise<any>,
  entries: [string, any][]
) => {
  const result: [string, any][] = [];

  for await (const [key, value] of entries) {
    if (isUrlConfig(value)) {
      try {
        const loaded = await fetchUrlConfig(value);
        result.push([key, loaded]);
      } catch (error) {
        const status = (error as any).response?.status;
        const v = {
          error: true,
          error_message: value.error_message?.[status] ?? value?.error_message,
          ...value.error_response, // the backend may return `error: false` to remove the default error state for this step
        };
        result.push([key, v]);
      }
    } else if (isUrlConfigArray(value)) {
      const v = [];
      for (const config of value) {
        try {
          v.push(await fetchUrlConfig(config));
        } catch (error) {
          if (config.error_response) {
            v.push(config.error_response);
          }
        }
      }
      result.push([key, v]);
    } else {
      result.push([key, value]);
    }
  }

  return result;
};

export const loadSavedFilter = async (
  fetchUrlConfig: (config: UrlConfig) => Promise<any>,
  metadata: Metadata,
  entries: [string, any][],
  variables?: [string, any][]
) => {
  const result: [string, any][] = [];

  for await (const [key, value] of entries) {
    if (value?.is_filter) {
      const { filter_type, step_data, vars } = value;
      const mergedVars = variables ? mergeEntries(variables, vars) : vars;
      // Note: nested filters don't save the filter_type as an object - just a string value
      const loaded = await loadSavedFilter(
        fetchUrlConfig,
        metadata,
        step_data,
        mergedVars
      );
      const filter = new Filter(metadata.filters[filter_type], metadata, {
        init: loaded,
        vars: mergedVars,
      });
      result.push([key, filter]);
    } else if (isUrlConfig(value)) {
      try {
        result.push([key, await fetchUrlConfig(value)]);
      } catch (error) {
        const status = (error as any).response?.status;
        const v = {
          error: true,
          error_message: value.error_message?.[status] ?? value?.error_message,
          ...value.error_response, // the backend may return `error: false` to remove the default error state for this step
        };
        result.push([key, v]);
      }
    } else if (isUrlConfigArray(value)) {
      const v = [];
      for (const config of value) {
        try {
          v.push(await fetchUrlConfig(config));
        } catch (error) {
          if (config.error_response) {
            v.push(config.error_response);
          }
        }
      }
      result.push([key, v]);
    } else {
      result.push([key, value]);
    }
  }

  return result;
};

export const loadFilterConfig = async (
  fetchUrlConfig: (config: UrlConfig) => Promise<any>,
  config: FilterConfig,
  metadata: Metadata,
  variables: [string, any][]
): Promise<FilterSet | FilterSet[]> => {
  const result = Array(config.query.length);
  let index = 0;

  for await (const set of config.query) {
    result[index] = [];

    for await (const filter of set.filters) {
      const loaded = await loadSavedFilter(
        fetchUrlConfig,
        metadata,
        filter.view_model,
        variables
      );
      const { filter_type, vars } = loaded[0][1];
      const init = [
        getFilterTypeStepDataFromSave(loaded[0]),
        ...loaded.slice(1),
      ];
      result[index].push(
        new Filter(metadata.filters[filter_type], metadata, {
          init,
          vars: mergeEntries(variables, vars),
        })
      );
    }

    index++;
  }

  return result.length === 1
    ? { and: config.query[0].and, filters: result[0] }
    : result.map((filters, idx) => ({ and: config.query[idx].and, filters }));
};
