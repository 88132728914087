import BasicModal from '__components/Modals/presets/BasicModal';
import { useTranslation } from 'react-i18next';
import { StiledNotice, StyledReferenceFields } from './styles';
import { useAsync } from 'react-use';
import Loader from '__components/Kizen/Loader';
import MultiSelect from '__components/Inputs/MultiSelect';
import ClearSelectButton from '__components/Inputs/Select/ClearButton';
import Cols from '__components/Layout/Cols';
import Select from '__components/Inputs/Select';
import AxiosService from 'services/AxiosService';
import {
  CustomObjectMinimal,
  FilterGroup,
  getAllCustomObjects,
  getFilterGroups,
} from '@kizen/api/custom-object';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  FieldLight,
  GenericOption,
  SmartConnectorDataSeed,
  SmartConnectorDataSeedDTO,
} from '__pages/SmartConnectors/types';
import { ReferenceFields } from './ReferenceFields';
import { SmartConnectorContext } from '__pages/SmartConnectors/SmartConnector/context';
import { FilterGroupSelect } from './FilterGroupSelect';
import ConfirmationModal from '__components/Modals/ConfirmationModal';
import useModal from '__components/Modals/useModal';
import { getIsSmartConnectorActive } from '__pages/SmartConnectors/helpers';
import Button from '__components/Button';

type ReferenceDataModalProps = {
  show: boolean;
  onConfirm: (
    dataSeeds: SmartConnectorDataSeedDTO[]
  ) => Promise<{ success: boolean }>;
  onHide: () => void;
};

export const ReferenceDataModal = ({
  show,
  onConfirm,
  onHide,
}: ReferenceDataModalProps) => {
  const { t } = useTranslation();

  const { smartConnector, showDeactivationModalWithResult } = useContext(
    SmartConnectorContext
  );
  const [dataSeeds, setDataSeeds] = useState<SmartConnectorDataSeed[]>(
    smartConnector.kizen_data_seeds || []
  );
  const [submiting, setSubmiting] = useState(false);

  const isDirty = useMemo(() => {
    return (
      dataSeeds.length !== smartConnector.kizen_data_seeds?.length ||
      dataSeeds.some((seed, index) => {
        const smartConnectorSeed = smartConnector.kizen_data_seeds?.[index];
        return (
          !smartConnectorSeed ||
          seed.custom_object.id !== smartConnectorSeed.custom_object.id ||
          seed.fields.length !== smartConnectorSeed.fields.length ||
          seed.group?.id !== smartConnectorSeed.group?.id ||
          seed.fields.some((field, fieldIndex) => {
            const smartConnectorField = smartConnectorSeed.fields[fieldIndex];
            return !smartConnectorField || field.id !== smartConnectorField.id;
          })
        );
      })
    );
  }, [dataSeeds, smartConnector.kizen_data_seeds]);

  const {
    value: { customObjects = [], customObjectsByIds = {} } = {},
    loading,
  } = useAsync(async () => {
    const { data } = await getAllCustomObjects(AxiosService);
    const customObjectsByIds = data.reduce<Record<string, CustomObjectMinimal>>(
      (acc, obj) => {
        acc[obj.id] = obj;
        return acc;
      },
      {}
    );

    return { customObjects: data, customObjectsByIds };
  }, []);

  const [objectsToInclude, seedsByObjectId] = useMemo(() => {
    const objectsToInclude = dataSeeds.map(({ custom_object }) => ({
      value: custom_object.id,
      label: custom_object.object_name,
    }));
    const seedsByObjectId = dataSeeds.reduce<
      Record<string, SmartConnectorDataSeed>
    >((acc, seed) => {
      acc[seed.custom_object.id] = seed;
      return acc;
    }, {});

    return [objectsToInclude, seedsByObjectId];
  }, [dataSeeds]);

  const [objectToConfigure, setObjectToConfigure] =
    useState<GenericOption | null>(objectsToInclude[0] || null);

  const groupsByCustomObjectId = useRef<Map<string, FilterGroup[]>>(
    new Map()
  ).current;

  const { loading: loadingGroups } = useAsync(async () => {
    if (
      !objectToConfigure ||
      groupsByCustomObjectId.has(objectToConfigure.value)
    ) {
      return;
    }
    const { data: filterGroups } = await getFilterGroups(
      AxiosService,
      objectToConfigure.value
    );
    groupsByCustomObjectId.set(objectToConfigure.value, filterGroups);
  }, [objectToConfigure?.value]);

  const customObjectOptions = useMemo<GenericOption[]>(() => {
    return customObjects.map(({ object_name, id }) => ({
      value: id,
      label: object_name,
    }));
  }, [customObjects]);

  const hasObjectToConfigure = !!objectToConfigure;

  useEffect(() => {
    if (objectsToInclude.length && !hasObjectToConfigure) {
      setObjectToConfigure(objectsToInclude[0]);
    }
  }, [customObjectOptions, hasObjectToConfigure, objectsToInclude]);

  const onSelectObjectsToInclude = useCallback(
    (values: GenericOption[]) => {
      setDataSeeds(
        values.map((v) => {
          const seed = seedsByObjectId[v.value];
          if (seed) {
            return seed;
          }
          return {
            id: `new-${v.value}`,
            group: null,
            fields: [],
            custom_object: customObjectsByIds[v.value],
          };
        })
      );
      setObjectToConfigure((prev) => {
        if (
          !values.length ||
          (prev && !values.find((v) => v.value === prev.value))
        ) {
          return null;
        }
        return prev || values[0];
      });
    },
    [customObjectsByIds, seedsByObjectId]
  );

  const onChangeFilterGroup = useCallback(
    (value: FilterGroup | null) => {
      setDataSeeds((prev) => {
        const seed = prev.find(
          (s) => s.custom_object.id === objectToConfigure?.value
        );
        if (!seed || !objectToConfigure?.value) {
          return prev;
        }
        return prev.map((s) => {
          if (s.custom_object.id === objectToConfigure.value) {
            return { ...s, group: value };
          }
          return s;
        });
      });
    },
    [objectToConfigure?.value]
  );

  const onChangeFields = useCallback(
    (fields: FieldLight[]) => {
      if (objectToConfigure?.value) {
        setDataSeeds((prev) => {
          return prev.map((s) => {
            if (s.custom_object.id === objectToConfigure?.value) {
              return { ...s, fields };
            }
            return s;
          });
        });
      }
    },
    [objectToConfigure?.value]
  );

  const handleSubmit = useCallback(async () => {
    const handleSave = async () => {
      const result = await onConfirm(
        dataSeeds.map(({ id, custom_object, fields, group }) => {
          return {
            id,
            custom_object_id: custom_object.id,
            fields_ids: fields.map(({ id }) => id),
            group_id: group?.id || null,
          };
        })
      );
      setSubmiting(false);
      if (result.success) {
        onHide();
      }
      return result.success;
    };

    setSubmiting(true);

    if (getIsSmartConnectorActive(smartConnector)) {
      const updated = await showDeactivationModalWithResult({
        handleSave,
        handleDiscard: onHide,
      });

      if (!updated) {
        setSubmiting(false);
        throw new Error('Deactivation failed');
      }
    } else {
      await handleSave();
    }
  }, [
    dataSeeds,
    onConfirm,
    showDeactivationModalWithResult,
    smartConnector,
    onHide,
  ]);

  const [confirmationModalProps, , { show: showConfirmationModal }] = useModal({
    handleSubmit: onHide,
  });

  const onHideConfirmed = useCallback(() => {
    if (!isDirty) {
      onHide();
    } else {
      showConfirmationModal();
    }
  }, [onHide, isDirty, showConfirmationModal]);

  return (
    <>
      <BasicModal
        size="large"
        show={show}
        onConfirm={handleSubmit}
        onHide={onHideConfirmed}
        heading={t('Reference Data Settings')}
        disabled={!isDirty}
      >
        <StiledNotice>
          {t(
            'Records in the selected objects will be populated in a table in the SQL script with the fields selected as columns (in addition to the Kizen ID). This data will be refreshed when the SmartConnector runs.'
          )}
        </StiledNotice>
        <Loader loading={loading}>
          <MultiSelect
            value={objectsToInclude}
            onChange={onSelectObjectsToInclude}
            label={t('Objects to Include')}
            placeholder={t('Find Objects')}
            options={customObjectOptions}
            menuLeftButton={<ClearSelectButton />}
            margin
            inModal
          />
          <Cols columns={2} gutter="20px">
            <Select
              value={objectToConfigure}
              onChange={setObjectToConfigure}
              label={t('Choose Object to Configure')}
              placeholder={t('Choose Object')}
              options={objectsToInclude}
              inModal
              margin
            />
            <FilterGroupSelect
              key={objectToConfigure?.value}
              filterGroups={
                objectToConfigure?.value
                  ? groupsByCustomObjectId.get(objectToConfigure?.value)
                  : undefined
              }
              filterGroup={
                objectToConfigure?.value
                  ? seedsByObjectId[objectToConfigure?.value || ''].group
                  : null
              }
              onChange={onChangeFilterGroup}
              isLoading={loadingGroups}
            />
          </Cols>
          <StyledReferenceFields>
            <ReferenceFields
              customObjectId={objectToConfigure?.value}
              dataSeed={
                objectToConfigure?.value
                  ? seedsByObjectId[objectToConfigure.value]
                  : undefined
              }
              onChange={onChangeFields}
            />
          </StyledReferenceFields>
        </Loader>
      </BasicModal>
      <ConfirmationModal
        heading={t('You Have Unsaved Changes')}
        buttonText={t('Discard Changes')}
        defaultLeftBtnText={t('Save Changes')}
        actionBtnColor="red"
        leftBtn={<Button disabled={submiting} />}
        defaultLeftBtnHandler={handleSubmit}
        {...confirmationModalProps}
      >
        {t(
          'You have unsaved changes. Would you like to save them or discard them before continuing?'
        )}
      </ConfirmationModal>
    </>
  );
};
