import useMultiColumnBuilder from './useMultiColumnBuilder';
import { v4 as uuidv4 } from 'uuid';
import { useCallback, useState, useEffect } from 'react';
import { colorsPrimary } from 'app/colors';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';

const DEFAULT_ICONS = {};
export const LEFT_ID = 'left-column';
export const RIGHT_ID = 'right-column';
export const GROUP_IDS = ['related-objects', 'other-objects'];

const doesSearchMatch = (item, term) => {
  if (!term) {
    return true;
  }

  if (item.isDynamic) {
    return true;
  }
  //always show group title even if it's empty
  if (
    item.label.toLowerCase().includes(term.toLowerCase()) ||
    item?.isGroupedItem
  ) {
    return true;
  }
};

const removeEmptyGroups = (showCategoryOnSearch) => (item, i, acc) => {
  if (!showCategoryOnSearch && item.isGroupedItem) {
    if (acc[i + 1]) {
      return !acc[i + 1].isGroupedItem;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

class ParentDropValidator {
  constructor(
    dropZone,
    draggingItem,
    selfDisabledDropZones,
    leftColumnId = LEFT_ID,
    rightColumnId = RIGHT_ID
  ) {
    this.dropZone = dropZone;
    this.draggingItem = draggingItem;
    this.leftColumnId = leftColumnId;
    this.rightColumnId = rightColumnId;
    this.selfDisabledDropZones = selfDisabledDropZones;
  }

  isDraggingItemRestricted() {
    return (
      this.draggingItem?.restricted ||
      this.draggingItem?.fromColumn === this.rightColumnId
    );
  }

  getValidDropZone() {
    if (
      this.draggingItem?.disabled &&
      this.dropZone?.sectionId !== this.draggingItem?.fromColumn
    ) {
      return null;
    }
    if (this.isDraggingItemRestricted()) {
      return this.dropZone.sectionId === this.rightColumnId ||
        this.dropZone.position === 'overall'
        ? this.dropZone
        : null;
    }
    // if we passed sectionId with selfDisabledDropZones then we want to disable drop zone for this id
    if (
      this.draggingItem?.fromColumn === this.dropZone?.sectionId &&
      this.selfDisabledDropZones.includes(this.dropZone?.sectionId)
    ) {
      return null;
    }
    return this.dropZone;
  }
}

const mutateOriginColumn = (
  mutator,
  item,
  immutable = false,
  skipRemoval = false
) => {
  const { fromParent } = item;
  return mutator((data) => {
    const res = [...data];

    if (fromParent) {
      const parentIdx = res.findIndex((d) => d.id === fromParent);
      const childIdx = res[parentIdx]?.children?.findIndex(
        (c) => c.id === item.id
      );
      if (childIdx > -1) {
        res[parentIdx].children.splice(childIdx, 1);
      }
    } else {
      const idx = res.findIndex((d) => d.id === item.id);
      if (immutable) {
        res[idx].id = uuidv4();
      } else if (item.fieldId !== 'custom_field' || !skipRemoval) {
        res.splice(idx, 1);
      }
    }
    return res;
  });
};

const mutateItem = (
  mutator,
  parentIndex,
  childIndex,
  field,
  value,
  lookupId,
  removeItem = false
) => {
  if (parentIndex > -1) {
    mutator((data) => {
      const res = [...data];
      if (childIndex > -1) {
        res[parentIndex].children[childIndex][field] = value;
      } else {
        res[parentIndex] = {
          ...res[parentIndex],
          // handle delete shouldBeDisabledInLeftColumn
          disabled: removeItem
            ? res[parentIndex].shouldBeDisabledInLeftColumn ||
              res[parentIndex].disabled
            : res[parentIndex].disabled,
          [field]: value,
        };
      }

      return res;
    });
  } else {
    mutator((data) => {
      const res = [...data];
      const parentIndex = data.findIndex((d) => d.id === lookupId);
      if (parentIndex > -1) {
        res[parentIndex][field] = value;
      }

      return res;
    });
  }
};

// Some parameters here (those beginning with _) are not used in this use-case
// because they support adding sub-items to the list instead of a flat list.
const mutateDestinationColumn = (
  mutator,
  item,
  dropZone,
  _childDropZone,
  skip,
  _generateId,
  _t,
  handlePopup,
  _dropFirstChild,
  beforeMutation,
  itemsOrdering,
  immutable = false,
  leftColumn // left column id
) => {
  const { position } = dropZone;

  return mutator((data) => {
    const res = beforeMutation([...data]);
    if ((skip?.(item) ?? false) || immutable) {
      return res;
    }

    const labelProp =
      'label' in item && item.defaultLabel
        ? { label: item.label.trim?.() ? item.label : item.defaultLabel }
        : {};

    const payload = {
      ...item,
      ...labelProp,
      color: item.color ?? colorsPrimary.blue.dark,
      fromParent: undefined,
      widthPercent: 100,
      disabled:
        dropZone.sectionId === leftColumn
          ? !!item?.shouldBeDisabledInLeftColumn || item.disabled
          : item.disabled,
    };

    if (DEFAULT_ICONS[payload.id]) {
      payload.icon = DEFAULT_ICONS[payload.id];
    }

    if (position === 'first') {
      res.unshift(payload);
    } else if (position === 'before' || position === 'after') {
      const idx = res.findIndex((d) => d.id === dropZone.id);
      if (position === 'before') {
        res.splice(idx, 0, payload);
      } else {
        res.splice(idx + 1, 0, payload);
      }
    }

    if (position === 'overall') {
      if (itemsOrdering) {
        const lookUp = res.reduce(
          (acc, item) => ({ ...acc, [item.id]: item }),
          {}
        );
        return itemsOrdering.reduce((acc, id) => {
          if (lookUp[id]) {
            acc.push(lookUp[id]);
          }
          if (id === item.id) {
            acc.push(payload);
          }
          return acc;
        }, []);
      }
      res.unshift(payload);
    }

    return res;
  });
};

const useBasicBuilder = ({
  initialLeftColumnItems = EMPTY_ARRAY,
  initialRightColumnItems = EMPTY_ARRAY,
  leftColumnId = LEFT_ID,
  rightColumnId = RIGHT_ID,
  handlePopup,
  editable = false,
  selfDisabledDropZones = EMPTY_ARRAY,
  showCategoryOnSearch = true,
  itemsOrdering,
  rollBackDrop = false,
  immutable,
  rightColumnItemsWithContentForceDirty = true,
  preventParentIndexDirty = false,
  customFieldSupport = false,
} = {}) => {
  const [isDirty, setIsDirty] = useState(false);
  const [leftColumnItems, setLeftColumnItems] = useState(
    initialLeftColumnItems
  );
  const [rightColumnItems, setRightColumnItems] = useState(
    initialRightColumnItems
  );

  useEffect(() => {
    if (
      rightColumnItemsWithContentForceDirty &&
      !isDirty &&
      initialRightColumnItems.length > 0
    ) {
      setRightColumnItems(initialRightColumnItems);
      setIsDirty(true);
    }
  }, [
    initialRightColumnItems,
    setRightColumnItems,
    isDirty,
    rightColumnItemsWithContentForceDirty,
  ]);

  const [optionsSearchTerm, setOptionsSearchTerm] = useState('');
  const [editingItem, setEditingItem] = useState(null);

  const {
    endDrag,
    throttleGetFieldDropzone,
    throttleGetChildFieldDropzone,
    leftColumnProps,
    rightColumnProps,
    leftContainerProps,
    leftHeaderProps,
    rightContainerProps,
    rightHeaderProps,
    moveItemToBottom,
    removeItem,
    swapItemColumn,
  } = useMultiColumnBuilder({
    leftColumn: leftColumnId,
    rightColumn: rightColumnId,
    setDirtyStates: setIsDirty,
    mutateOriginColumn,
    setLeftColumnItems,
    setRightColumnItems,
    mutateDestinationColumn,
    getDropId: () => uuidv4(),
    handlePopup,
    leftColumnItems,
    rightColumnItems,
    ParentDropValidator,
    selfDisabledDropZones,
    itemsOrdering,
    rollBackDrop,
    immutable,
    customFieldSupport,
  });

  const editLeftColumnTempValue = useCallback(
    (index, childIndex, field, value, lookupId) => {
      return mutateItem(
        setLeftColumnItems,
        index,
        childIndex,
        field,
        value,
        lookupId
      );
    },
    [setLeftColumnItems]
  );

  const editValue = useCallback(
    (parentIndex, childIndex, field, value, removeItem) => {
      if (parentIndex > -1) {
        // if the parentIndex is set internally i.e. not because of a drag and Drop
        if (!preventParentIndexDirty) {
          setIsDirty(true);
        }

        return mutateItem(
          setRightColumnItems,
          parentIndex,
          childIndex,
          field,
          value,
          undefined,
          removeItem
        );
      }
    },
    [setRightColumnItems, preventParentIndexDirty]
  );

  return {
    dirty: isDirty,
    rightColumnItems,
    optionsSearchTerm,
    setOptionsSearchTerm,
    leftColumnBodyProps: leftContainerProps,
    leftColumnHeaderProps: leftHeaderProps,
    leftColumnProps: {
      id: leftColumnId,
      options: leftColumnItems
        .filter((s) => doesSearchMatch(s, optionsSearchTerm))
        .filter(removeEmptyGroups(showCategoryOnSearch)),
      endDrag,
      getFieldDropZone: throttleGetFieldDropzone,
      editValue: editLeftColumnTempValue,
      editingItem,
      setEditingItem,
      swapItemColumn,
      ...leftColumnProps,
    },
    rightColumnBodyProps: rightContainerProps,
    rightColumnHeaderProps: rightHeaderProps,
    rightColumnProps: {
      id: rightColumnId,
      options: rightColumnItems,
      endDrag,
      getFieldDropZone: throttleGetFieldDropzone,
      getChildDropZone: throttleGetChildFieldDropzone,
      editValue,
      editingItem,
      setEditingItem,
      editable,
      removeItem,
      swapItemColumn,
      collapsedItems: [],
      canBeDeleted: true,
      moveItemToBottom,
      ...rightColumnProps,
    },
  };
};

export default useBasicBuilder;
