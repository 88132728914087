import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation, TFunction } from 'react-i18next';
import useModal from '__components/Modals/useModal';
import { useFlashTransition } from 'hooks/useFlashState';

import { ASSOCIATION_SOURCE_TYPES } from '../../constants';

export type AssociationSourceType = keyof typeof ASSOCIATION_SOURCE_TYPES;

type relatedObject = {
  assocationSource: AssociationSourceType;
  relatedObject: string;
  fieldId: string;
  field: {
    id: string;
    canonicalDisplayName: string;
  };
};

interface UseAssociatedSource {
  values: any;
  relatedObjects: relatedObject[];
  updateStepField: (field: string, value: any) => void;
  handleInputChange: (field: string, value: any) => void;
  modelId: string;
  setValidationState: (state: any) => void;
  firstFieldWithErrorRef: any;
}

type MenuOption = {
  error: unknown;
  isSelfError: boolean;
  value: AssociationSourceType;
  label: string;
};

type FieldOption = { id: string; option: MenuOption };

type ObjectsAndFieldType = {
  objectOptions: MenuOption[];
  fieldOptions: FieldOption[];
};

const getAssociatedSourceOptions = (t: TFunction) => [
  { value: ASSOCIATION_SOURCE_TYPES.direct, label: t('Direct') },
  {
    value: ASSOCIATION_SOURCE_TYPES.direct_and_related,
    label: t('Direct and Related'),
  },
  { value: ASSOCIATION_SOURCE_TYPES.related, label: t('Related') },
];

const getAssociatedWarnings = (t: TFunction) => ({
  [ASSOCIATION_SOURCE_TYPES.direct]: t(
    'Only users with associations to this object will be able to access records on this object. Are you sure?'
  ),
  [ASSOCIATION_SOURCE_TYPES.related]: t(
    'All users with My Records permission on an associated related record, as chosen in the objects/fields selector, will immediately gain access to those records. Additionally, all direct associations (including Owner) to this object will be ignored. Are you sure?'
  ),
  [ASSOCIATION_SOURCE_TYPES.direct_and_related]: t(
    'All users with My Records permission on an associated related record, as chosen in the objects/fields selector, will immediately gain access to those records. Additionally, all direct associations (including Owner) to this object will be used for calculating access. Are you sure?'
  ),
});

export const useAssociatedSource = ({
  values,
  relatedObjects,
  updateStepField,
  handleInputChange,
  modelId,
  setValidationState,
  firstFieldWithErrorRef,
}: UseAssociatedSource) => {
  const { t } = useTranslation();

  const [fieldErrorsByIds, setFieldErrorsByIds] = useState<
    Record<string, boolean>
  >({});

  const relatedObjectsRef = useRef(relatedObjects);
  const associatedKeyRef = useRef(Date.now());

  if (
    relatedObjectsRef.current?.length !== undefined &&
    relatedObjectsRef.current?.length !== relatedObjects?.length &&
    values.associationSourceObjects?.length
  ) {
    // remove any select related objects when they are deleted
    const relatedObjectIds = (relatedObjects || []).map(
      ({ relatedObject }) => relatedObject
    );

    const associationSourceObjects = values.associationSourceObjects.filter(
      ({ value }: MenuOption) => relatedObjectIds.includes(value)
    );

    handleInputChange('associationSourceObjects', associationSourceObjects);
    updateStepField('associationSourceObjects', associationSourceObjects);

    relatedObjectsRef.current = relatedObjects;
  }

  // handle changing the assocaited source adding modal etc
  const [nextAssociationSource, setNextAssociationSource] =
    useState<MenuOption | null>(null);

  const onConfirmChange = useCallback(
    (associationSource: MenuOption) => {
      handleInputChange('associationSource', associationSource.value);
      updateStepField('associationSource', associationSource.value);

      if (associationSource.value === ASSOCIATION_SOURCE_TYPES.direct) {
        handleInputChange('associationSourceObjects', []);
        updateStepField('associationSourceObjects', []);

        handleInputChange('associationSourceFields', []);
        updateStepField('associationSourceFields', []);
      }
    },
    [handleInputChange, updateStepField]
  );

  const [confirmChangeModalProps, , { setShow: setShowChangeModal }] = useModal(
    {
      handleSubmit: async () => {
        onConfirmChange(nextAssociationSource as unknown as MenuOption);
      },
      handleHide: () => {
        // update the key to force a re-render
        associatedKeyRef.current = Date.now();
        setNextAssociationSource(null);
      },
    }
  );

  const handleChangeAssociatedSource = useCallback(
    (associationSource: MenuOption) => {
      if (values.associationSource === associationSource.value) {
        return;
      }
      setNextAssociationSource(associationSource);
      setTimeout(() => {
        setShowChangeModal(true), 0;
      });
    },
    [setShowChangeModal, values.associationSource]
  );

  // options for the multiselects
  const { objectOptions, fieldOptions } = useMemo<ObjectsAndFieldType>(() => {
    return (relatedObjects || []).reduce(
      (prev: any, ro: any) => {
        const { objectOptions, fieldOptions } = prev;

        const isSelf =
          ro.relatedObject === modelId &&
          values.associationSource === ASSOCIATION_SOURCE_TYPES.related;
        if (
          !objectOptions.find((o: MenuOption) => ro.relatedObject === o.value)
        ) {
          objectOptions.push({
            value: ro.relatedObject,
            label: ro.objectName,
            isSelf,
          });
        }

        if (!ro?.field?.isSuppressed) {
          fieldOptions.push({
            id: ro.relatedObject,
            option: {
              value: ro?.field?.id,
              label: ro?.field?.canonicalDisplayName,
              isSelfError: isSelf,
              error: isSelf,
            },
          });
        }
        return { objectOptions, fieldOptions };
      },
      { objectOptions: [], fieldOptions: [] } as ObjectsAndFieldType
    );
  }, [relatedObjects, modelId, values.associationSource]);

  const associationSourceOptionsIds = useMemo(
    () => values.associationSourceObjects.map((o: MenuOption) => o.value),
    [values.associationSourceObjects]
  );

  const handleChangeAssociatedSourceObjects = useCallback(
    (associationSourceObjects: MenuOption[]) => {
      handleInputChange('associationSourceObjects', associationSourceObjects);
      updateStepField('associationSourceObjects', associationSourceObjects);

      const nextAssociationSourceOptionsIds: string[] =
        associationSourceObjects.map((o: MenuOption) => o.value);

      const nextAssociationFieldOptionsIds: string[] = fieldOptions
        .filter((field: FieldOption) =>
          nextAssociationSourceOptionsIds.includes(field.id)
        )
        .map((field: FieldOption) => field.option.value);

      const nextAssociationSourceFields = values.associationSourceFields.filter(
        (value: string) => nextAssociationFieldOptionsIds.includes(value)
      );

      setFieldErrorsByIds((prev) => {
        return nextAssociationFieldOptionsIds.reduce(
          (acc, id) => {
            if (prev[id]) {
              acc[id] = prev[id];
            }
            return acc;
          },
          {} as Record<string, boolean>
        );
      });

      handleInputChange('associationSourceFields', nextAssociationSourceFields);
      updateStepField('associationSourceFields', nextAssociationSourceFields);
    },
    [
      fieldOptions,
      handleInputChange,
      updateStepField,
      values.associationSourceFields,
    ]
  );

  // filter field options based on selected objects
  const associatedSourceObjectsFieldOptions = useMemo<MenuOption[]>(() => {
    return fieldOptions.reduce((acc, field) => {
      const { id, option } = field;
      if (associationSourceOptionsIds.includes(id)) {
        acc.push(
          fieldErrorsByIds[option.value] ? { ...option, error: true } : option
        );
      }
      return acc;
    }, [] as MenuOption[]);
  }, [associationSourceOptionsIds, fieldOptions, fieldErrorsByIds]);

  const handleChangeAssociatedSourceFields = useCallback(
    (associationSourceFields: MenuOption[]) => {
      const values = associationSourceFields.map(
        ({ value }: MenuOption) => value
      );
      handleInputChange('associationSourceFields', values);
      updateStepField('associationSourceFields', values);
    },
    [handleInputChange, updateStepField]
  );

  const associatedWarnings = useMemo(
    () =>
      nextAssociationSource
        ? getAssociatedWarnings(t)[nextAssociationSource.value]
        : '',
    [nextAssociationSource, t]
  );

  const [associationSourceFieldsValue, isSelfReferenceError] = useMemo(() => {
    let selfInvalid = false;
    const value: MenuOption[] = [];
    associatedSourceObjectsFieldOptions.forEach((option) => {
      if (values.associationSourceFields.includes(option.value)) {
        if (option.isSelfError) {
          selfInvalid = true;
        }
        value.push({
          ...option,
          error: Boolean(
            option.error || option.isSelfError || fieldErrorsByIds[option.value]
          ),
        });
      }
    });

    return [value, selfInvalid];
  }, [
    values.associationSourceFields,
    associatedSourceObjectsFieldOptions,
    fieldErrorsByIds,
  ]);

  const [message, showMessage, flash] = useFlashTransition({ stay: 3000 });

  useEffect(() => {
    if (isSelfReferenceError) {
      flash(
        t(
          'Self referential fields are not allowed when Team Assocaitions is set to Related.'
        )
      );
    }
  }, [isSelfReferenceError, flash, t]);

  const validateAssociationFields = useCallback(() => {
    if (isSelfReferenceError) {
      firstFieldWithErrorRef.current = 'associationSourceFields';
      setValidationState({
        associationSourceFields: t(
          'Self referential fields are not allowed when Team Assocaitions is set to Related.'
        ),
      });
      return null;
    }
    return true;
  }, [isSelfReferenceError, firstFieldWithErrorRef, setValidationState, t]);

  const handleAssociationFieldsErrors = useCallback(
    (errors: Record<'id', string[]>[]) => {
      const firstFieldErrorIndex = errors.findIndex((error) => error?.id);

      const firstFieldError = errors[firstFieldErrorIndex]?.id;

      if (firstFieldError) {
        firstFieldWithErrorRef.current = 'associationSourceFields';
        setValidationState({
          associationSourceFields: Array.isArray(firstFieldError)
            ? firstFieldError[0]
            : firstFieldError,
        });

        setFieldErrorsByIds((prev) => {
          const next = { ...prev };
          errors.forEach((error, i) => {
            if (error?.id) {
              next[values.associationSourceFields[i]] = true;
            }
          });

          return next;
        });
        return null;
      }
    },
    [setValidationState, values.associationSourceFields, firstFieldWithErrorRef]
  );

  return {
    handleChangeAssociatedSource,
    associatedSourceOptions: getAssociatedSourceOptions(t),
    associatedSourceObjectsOptions: objectOptions,
    handleChangeAssociatedSourceObjects,
    associatedSourceObjectsFieldOptions,
    handleChangeAssociatedSourceFields,
    associatedWarnings,
    confirmChangeModalProps,
    associationSourceFieldsValue,
    associationSourceFieldsError: { message, showMessage },
    validateAssociationFields,
    associatedKey: associatedKeyRef.current,
    handleAssociationFieldsErrors,
  };
};
