import { call, takeLatest, put, all, select } from 'redux-saga/effects';
import { isEmpty } from 'lodash';

import { buildFilterSets } from '@kizen/filters/filter-sets';
import { setSearchParams } from 'hooks/useSearchParam';
import FieldService from 'services/FieldService';
import ClientService from 'services/ClientService';
import FilterGroupsService from 'services/FilterGroupsService';
import CustomObjectsService from 'services/CustomObjectsService';
import TeamMemberService, {
  RECORD_LIST_CONFIG_KEYS,
  collectRecordListPageConfig,
} from 'services/TeamMemberService';
import { camelToSnakeCaseKeys } from 'services/helpers';
import { FIELD_TYPES } from 'utility/constants';
import { generatePageConfig } from './contacts.redux';
import {
  buildPage as buildPageAction,
  getCounts as getCountsAction,
  getContacts as getContactsAction,
  createOrUpdateGroup as createOrUpdateGroupAction,
  changeGroup as changeGroupAction,
  removeGroup as removeGroupAction,
  setPageConfig as setPageConfigAction,
  setSavedFilter,
  buildPageComplete,
  buildPageFinish,
  buildPageFail,
  getContactsSuccess,
  getContactsStart,
  getContactsFail,
  getCountsSuccess,
  updateContact,
  addNewContact,
  createOrUpdateGroupSuccess,
  removeGroupSuccess,
  resetFilterGroupSuccess,
  createOrUpdateGroupFail,
  removeGroupFail,
  getCountsFail,
  updatePageConfigBySearch,
  setPageConfigSuccess,
  setPageConfigFail,
  setGroup,
  getContactsFinish,
  updatePageConfig,
  clearFilterSearch,
} from './actions';
import {
  enrichAndRepairQuickFilterSettings,
  getCurrentCustomFieldIds,
} from 'store/utilities';
import {
  buildPageFilterQuery,
  getFilterVariables,
  loadFilterFromUrlParam,
  loadSavedFilter,
} from 'ts-filters/utils';
import { anyFilterErrors, getFilterErrors } from 'hooks/useFilterErrorEffect';
import { selectFilterMetadataDefinition } from 'store/filterMetaData/selectors';
import { VIEW_VARIANTS } from 'components/ViewVariant/types';
import { GET_FILTER_GROUPS_PATHNAMES } from 'pages/FilterGroupsPage/constants';
import { TIMEZONE_OFFSET_HEADER } from 'services/constants';
import { getTimezoneOffset } from 'services/utils';
import { getBusinessClientObject } from 'store/authentication/selectors';

function* getContacts({ payload }) {
  let hasQuickFilters = false;
  let fetchedCriteria = null;

  try {
    yield put(getContactsStart(payload));

    const { onlyUpdateCounts } = payload;

    const pageConfig = yield select((s) => s.contactPage.pageConfig);
    const clientObject = yield select((s) => s.contactPage.clientObject);

    // grab the custom fieldIds
    const fields = yield select((s) => s.contactPage.fields);
    const allFields = yield select((s) => s.contactPage.allFields);
    const fieldIds = getCurrentCustomFieldIds(
      fields,
      pageConfig.columns,
      clientObject.meta?.defaultColumns
    );

    const { criteria, isComplexFilters } = buildPageFilterQuery(
      pageConfig.filterObject,
      pageConfig.quickFilters,
      allFields,
      clientObject,
      pageConfig.quickFilterSettings
    );

    fetchedCriteria = criteria;

    hasQuickFilters = isComplexFilters;

    const data = {
      values: {
        search: pageConfig.search,
        ordering: pageConfig.sort,
        groupId:
          !pageConfig.filterObject.query && pageConfig.group
            ? pageConfig.group
            : null,
        criteria,
        page: {
          number: onlyUpdateCounts ? 1 : pageConfig.page,
          size: onlyUpdateCounts ? 1 : pageConfig.size,
        },
        fieldIds: onlyUpdateCounts ? [] : fieldIds,
      },
      options: criteria
        ? {
            headers: {
              [TIMEZONE_OFFSET_HEADER]: getTimezoneOffset(),
            },
          }
        : {},
    };
    const [result] = yield all([
      call(ClientService.search, data.values, data.options),
      payload.updatePageConfig &&
        call(
          TeamMemberService.updateCustomObjectRecordsListPageConfig,
          clientObject.id,
          collectRecordListPageConfig(
            pageConfig,
            payload.updatePageConfigKey || RECORD_LIST_CONFIG_KEYS.LAYOUT
          )
        ),
    ]);

    const hasNonFilterErrors = Boolean(result?.errors?.length);

    yield put(
      getContactsSuccess({
        contacts: hasNonFilterErrors || onlyUpdateCounts ? [] : result.results,
        contactsCount: hasNonFilterErrors ? 0 : result.count,
        refreshPage: payload.refreshPage,
        errors: null,
        fetchedCriteria,
      })
    );
  } catch (error) {
    if (anyFilterErrors(error?.response, hasQuickFilters)) {
      const { count, results, errors } = getFilterErrors(
        error?.response?.data?.errors,
        hasQuickFilters
      );
      yield put(
        getContactsSuccess({
          contacts: results,
          contactsCount: count,
          refreshPage: payload.refreshPage,
          errors,
          fetchedCriteria,
        })
      );
    } else {
      yield put(getContactsFail(error));
    }
  } finally {
    yield put(getContactsFinish());
  }
}

function* getCounts() {
  try {
    const pageConfig = yield select((s) => s.contactPage.pageConfig);
    const [allCount, groupCount] = yield all([
      call(ClientService.count, {}),
      call(
        ClientService.count,
        { groupId: pageConfig.group },
        pageConfig.group
          ? {
              headers: {
                [TIMEZONE_OFFSET_HEADER]: getTimezoneOffset(),
              },
            }
          : {}
      ),
    ]);

    yield put(
      getCountsSuccess({
        allCount,
        groupCount: pageConfig.group ? groupCount : allCount,
      })
    );
  } catch (error) {
    yield put(getCountsFail(error));
  }
}

function* resetFilterGroup({ payload }) {
  const { groupId, history } = payload;

  const clientObject = yield select((s) => s.contactPage.clientObject);
  const pageConfig = yield select((s) => s.contactPage.pageConfig);

  setSearchParams(
    history,
    {
      group: null,
    },
    { method: 'replace' }
  );

  let reason;

  try {
    yield call(FilterGroupsService.getFilterGroup, clientObject.id, groupId);
    reason = 'hidden';
  } catch (error) {
    reason = error?.response?.status === 404 ? 'deleted' : 'not_allowed';
  }
  yield all([
    put(resetFilterGroupSuccess({ reason })),
    call(
      TeamMemberService.updateCustomObjectRecordsListPageConfig,
      clientObject.id,
      collectRecordListPageConfig(
        { ...pageConfig, group: null, filterObject: {} },
        RECORD_LIST_CONFIG_KEYS.LAYOUT
      )
    ),
  ]);
}

function* buildPage({ payload }) {
  try {
    const { page, history, clientObject: fetchedClientObject } = payload;

    const businessClientObject = yield select(getBusinessClientObject);
    let clientObject = fetchedClientObject;

    // If we already have the client object, we don't need to fetch it again
    if (!clientObject) {
      clientObject = yield call(FieldService.getModel, {
        id: businessClientObject.id,
      });
    }

    const [groupsResponse, { categorizedFields, fields }, pageResponse] =
      yield all([
        call(
          FilterGroupsService.getFilterGroups,
          clientObject.id,
          GET_FILTER_GROUPS_PATHNAMES.VISIBLE
        ), // /filter-groups/visible
        call(CustomObjectsService.getCategorizedModelFields, clientObject.id),
        call(
          TeamMemberService.getCustomObjectRecordsListPageConfig,
          clientObject.id,
          generatePageConfig()
        ),
      ]);

    const quickFilterSettings = yield call(
      enrichAndRepairQuickFilterSettings,
      pageResponse.quickFilterSettings,
      fields
    );

    yield put(
      buildPageComplete({
        clientObject,
        categorizedFields,
        fields,
        groupsResponse,
        pageResponse: {
          ...pageResponse,
          quickFilterSettings: quickFilterSettings.filter(Boolean),
        },
      })
    );

    const access = yield select((s) => s.authentication.access);
    const vars = Object.fromEntries(
      getFilterVariables(clientObject, { access })
    );

    const filterMetaData = yield select(selectFilterMetadataDefinition);

    if (page.queryFilter) {
      const data = yield call(
        loadFilterFromUrlParam,
        filterMetaData,
        page.queryFilter,
        vars
      );
      yield put(clearFilterSearch());
      yield put(setSavedFilter({ config: data, and: false }));
      yield put(
        updatePageConfig({
          filterObject: { and: false, query: buildFilterSets(data) },
        })
      );
    } else if (page.group) {
      const group = groupsResponse.find((g) => g.id === page.group);
      if (group) {
        const data = yield call(
          loadSavedFilter,
          filterMetaData,
          camelToSnakeCaseKeys(group.config),
          vars
        );
        yield put(updatePageConfig({ filterObject: group.config }));
        yield put(setSavedFilter({ config: data, and: group.config.and }));
      } else {
        // filter group can be deleted or hidden so we need to clear it from filter and page config
        yield resetFilterGroup({
          payload: { groupId: page.group, history },
        });
      }
    } else if (!isEmpty(pageResponse.filterObject)) {
      const group =
        pageResponse.group &&
        groupsResponse.find((g) => g.id === pageResponse.group);
      if ((pageResponse.group && group) || !pageResponse.group) {
        const data = yield call(
          loadSavedFilter,
          filterMetaData,
          camelToSnakeCaseKeys(pageResponse.filterObject),
          vars
        );
        yield put(
          setSavedFilter({ config: data, and: pageResponse.filterObject.and })
        );
      } else {
        // filter group can be deleted or hidden so we need to clear it from filter and page config
        yield resetFilterGroup({
          payload: { groupId: pageResponse.group, history },
        });
      }
    }
    yield put(buildPageFinish());
  } catch (error) {
    yield put(buildPageFail(error));
  }
}

function* createOrUpdateGroup({ payload }) {
  try {
    const { history, filterGroup, isPatch } = payload;
    const pageConfig = yield select((s) => s.contactPage.pageConfig);

    yield setSearchParams(history, {
      group: filterGroup.id,
      page: null,
    });

    yield put(
      createOrUpdateGroupSuccess({
        filterGroup,
        isPatch,
      })
    );

    yield put(
      updatePageConfig({
        page: 1,
        filterObject: filterGroup.config,
      })
    );

    yield getContacts({
      payload: {
        updatePageConfig: true,
        onlyUpdateCounts: pageConfig.viewVariant === VIEW_VARIANTS.CHARTS,
        updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
      },
    });
  } catch (error) {
    yield put(createOrUpdateGroupFail(error));
  }
}

function* removeGroup({ payload }) {
  try {
    const pageConfig = yield select((s) => s.contactPage.pageConfig);
    const clientObject = yield select((s) => s.contactPage.clientObject);
    yield call(
      FilterGroupsService.deleteFilterGroup,
      clientObject.id,
      pageConfig.group,
      pageConfig.group
    );
    yield setSearchParams(payload.history, {
      group: null,
      page: null,
    });
    yield put(removeGroupSuccess({ id: pageConfig.group }));

    yield getContacts({
      payload: {
        updatePageConfig: true,
        onlyUpdateCounts: pageConfig.viewVariant === VIEW_VARIANTS.CHARTS,
        updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
      },
    });
  } catch (error) {
    yield put(removeGroupFail(error));
  }
}

function* changeGroup({ payload }) {
  const { id } = payload;
  const groups = yield select((s) => s.contactPage.groups);
  const clientObject = yield select((s) => s.contactPage.clientObject);
  const pageConfig = yield select((s) => s.recordsPage.pageConfig);
  const group = groups.find((g) => g.id === id);

  yield put(setGroup(group ?? {}));
  yield put(updatePageConfig({ group: id, page: 1 }));
  yield put(getContactsStart());

  if (group && group.id) {
    const filterMetaData = yield select(selectFilterMetadataDefinition);
    const access = yield select((s) => s.authentication.access);
    const data = yield call(
      loadSavedFilter,
      filterMetaData,
      camelToSnakeCaseKeys(group.config),
      Object.fromEntries(getFilterVariables(clientObject, { access }))
    );
    yield put(setSavedFilter({ config: data, and: group.config.and }));
  } else {
    yield put(setSavedFilter({ config: null, and: false }));
    yield put(updatePageConfig({ filterObject: {} }));
  }

  yield getContacts({
    payload: {
      updatePageConfig: true,
      onlyUpdateCounts: pageConfig.viewVariant === VIEW_VARIANTS.CHARTS,
      updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
    },
  });
}

function* setPageConfig({ payload }) {
  try {
    const pageConfig = yield select((s) => s.contactPage.pageConfig);
    const clientObject = yield select((s) => s.contactPage.clientObject);
    yield call(
      TeamMemberService.updateCustomObjectRecordsListPageConfig,
      clientObject.id,
      collectRecordListPageConfig(
        pageConfig,
        payload?.updatePageConfigKey || RECORD_LIST_CONFIG_KEYS.LAYOUT
      )
    );
    yield put(setPageConfigSuccess());
  } catch (error) {
    yield put(setPageConfigFail(error));
  }
}

const updatesRelationshipField = ({ patch, fields }) => {
  return fields.some((f) => {
    return f.id in patch && f.fieldType === FIELD_TYPES.Relationship.type;
  });
};

function* maybeRefreshFromPatch({
  payload: { contact, patch: maybePatch, fields },
}) {
  const patch = maybePatch || FieldService.getFormValues(contact, fields);
  if (updatesRelationshipField({ patch, fields })) {
    // This is a special case: updating a relationship field may update other
    // records appearing in the table (i.e. on the other side of the relationship).
    // We refresh the data on the current page as a best effort to show up-to-date
    // information.
    yield call(getContacts, {
      payload: { refreshPage: true, updatePageConfig: false },
    });
  }
}

function* contactPage() {
  yield takeLatest(buildPageAction.type, buildPage);
  yield takeLatest(getCountsAction.type, getCounts);
  yield takeLatest(getContactsAction.type, getContacts);
  yield takeLatest(createOrUpdateGroupAction.type, createOrUpdateGroup);
  yield takeLatest(removeGroupAction.type, removeGroup);
  yield takeLatest(setPageConfigAction.type, setPageConfig);
  yield takeLatest(updatePageConfigBySearch.type, setPageConfig);
  yield takeLatest(updateContact.type, maybeRefreshFromPatch);
  yield takeLatest(addNewContact.type, maybeRefreshFromPatch);
  yield takeLatest(changeGroupAction.type, changeGroup);
}

export default contactPage;
