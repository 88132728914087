import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useToggle from 'react-use/lib/useToggle';
import useModal from 'components/Modals/useModal';
import { useTranslation } from 'react-i18next';
import {
  getAuthTeamMember,
  getCustomObjectsSettingsAccess,
  getRecordOverviewAccess,
} from 'store/authentication/selectors';
import { isMobile, useWindowSize } from 'app/spacing';
import { getSearchParam, setSearchParams } from 'hooks/useSearchParam';
import { getOrderingParam, getSortValue } from 'utility/SortingHelpers';
import FieldService from 'services/FieldService';
import PipelineService from 'services/PipelineService';
import {
  camelToSnakeCaseKeys,
  snakeToCamelCase,
  snakeToCamelCaseKeys,
} from 'services/helpers';
import Loader from 'components/Kizen/Loader';
import { isPipelineObject } from 'components/Modals/utilities';
import {
  CUSTOMIZE_LAYOUT_STEP_KEY,
  CUSTOMIZE_FIELDS_STEP_KEY,
} from 'components/Wizards/CustomObject/constants';
import { BULK_ACTION_TYPES } from 'pages/Common/bulkactions/model';
import ConfirmActionModal from './modals/ConfirmActionModal';
import AddReasonLostModal from './modals/AddReasonLostModal';
import {
  buildPage,
  updatePageConfig,
  getRecords,
  getRecordsBySearch,
  getBoardDataBySearch,
  setCheckedRecord,
  setCheckedSelection,
  addNewRecord,
  updateRecord,
  archiveRecord,
  cleanToast,
  clearFilterSearch,
  getBoardData,
  getRecordsInStage,
  setStageSelection,
  setSelectionCard,
  setPageConfig,
  moveRecord,
  resetFilterData,
  setFilterName,
  setNumberOfFilters,
  createOrUpdateGroup,
  removeGroup,
  changeGroup,
  setIsAllRecordsGroup,
  updateFieldOptions,
  getCounts,
  setCountsNeedToLoad,
  setBoardDataStagesChecked,
  showReasonDialog,
  showReasonDisqualifiedDialog,
  closeReasonDialog,
  closeReasonDisqualifiedDialog,
  updateCommerceValue,
  clearFilterErrors,
  resetPage,
} from 'store/customObjectsRecordsPage/actions';
import { STAGE_STATUSES } from 'utility/constants';
import {
  getFieldsForUI,
  ownerToOption,
  getValueForApi,
} from 'store/customObjectsRecordsPage/saga';
import { getCustomObjectEntitiesAccess } from 'store/authentication/selectors';
import ControlBar from './ControlBar';
import MobileLayout from './MobileLayout';
import DesktopLayout from './DesktopLayout';
import UploadRecordsModal from './UploadRecordsModal';
import { useToast } from 'components/ToastProvider';
import { PermissionsError } from 'services/errors';
import CreateEntityModalFull, {
  PIPELINE,
  CUSTOM_OBJECT,
} from 'components/Modals/CreateEntity/FullForm';
import { useSetTitleOnLoad } from 'hooks/useSetTitleOnLoad';
import { useDebounce } from 'react-use';
import { maybeTrackSearch } from 'utility/analytics';
import { DEFAULT_DELAY } from 'utility/config';
import { isReasonLostField, isReasonDisqualifiedField } from 'checks/fields';
import {
  getReasonsDialogData,
  getNeedToLoadCountsSelector,
  getReasonsDisqualifiedDialogData,
  getIsLoadingView,
  getRecordsPageReady,
  getFilterGroupReason,
  getHasFilterError,
} from 'store/customObjectsRecordsPage/selectors';
import { useAddReasonLostModal } from './modals/AddReasonLostModal/hooks';
import { VIEW_VARIANTS } from 'components/ViewVariant/types';
import { deferExecution } from 'utility/defer';
import { useMutation, useQuery } from 'react-query';
import useCustomObjectChartGroupConfig from './hooks/useCustomObjectChartGroupConfig';
import { DASHBOARD } from 'queries/query-keys';
import {
  createDashboardQuery,
  fetchDashboardsQuery,
} from 'components/AccessRequests/useDashboardList';
import { getSortedDashboards } from 'components/DashboardGrid/utils';
import AddReasonDisqualifiedModal from './modals/AddReasonDisqualifiedModal';
import { buildSharingSettingsForAPI } from 'utility/sharing';
import CreateLayoutModal from 'components/DashboardGrid/CreateLayoutModal';
import PerformActionModal from 'pages/Common/bulkactions/PerformActionModal';
import { getPerformActionOptions } from 'pages/Common/bulkactions/model';
import { FilterDropdownContext } from 'pages/Common/components/filterDropdownContext';
import {
  DATA_MANAGER_SESSION_ACTIONS,
  useDataManagerSession,
} from 'app/dataManagerSession';
import { buildPageFilterQuery } from 'ts-filters/utils';
import { useFilterErrorEffect } from 'hooks/useFilterErrorEffect';
import { useRecordsPageRouting } from 'hooks/useRecordsPageRouting';
import { RECORD_LIST_CONFIG_KEYS } from 'services/TeamMemberService';
import { CUSTOM_LAYOUT_TABS } from 'components/Wizards/CustomObject/utilities';
import { getColumns, shallowEqualList } from 'pages/Common/helpers/table';
import { GO_BACK_KEYS } from 'components/Wizards/CustomObject/constants';
import { useSyncDispatch } from 'ts-components/hooks/useSyncDispatch';
import { areFiltersEqual } from 'utility/validate';

const SupportedQueryFilters = ['automations', 'dynamictag', 'form', 'survey'];

export const newRecordOptions = (t, hasUploadButton) =>
  [
    {
      value: 'newRecord',
      label: t('New Record'),
    },
    hasUploadButton && {
      value: 'uploadRecords',
      label: t('Upload Records'),
    },
  ].filter(Boolean);

const defaultPageSettings = {
  pageNumber: 1,
  pageSize: 50,
};

const fallbackCustomizeFields = {
  customizeFields: {
    edit: false,
    remove: false,
    view: false,
  },
};

const getSettingOptions = (showBoardSettings, objectSettingsAccess, t) => {
  return [
    {
      value: 'objectSettings',
      label: t('Object Settings'),
    },
    showBoardSettings && {
      value: 'boardSettings',
      label: t('Board View Settings'),
    },
    objectSettingsAccess.customizeFields.edit && {
      value: 'customizeFields',
      label: t('Customize Fields'),
    },
  ].filter(Boolean);
};

const updateRecordHandler = async ({ data, state, allFields, stageId }) => {
  const { id, patch, model } = data;
  const { records, boardData } = state;

  const record = stageId
    ? boardData.stages.reduce((acc, stage) => {
        if (stage.id === stageId) {
          return stage.cards.find((c) => c.id === id);
        }

        return acc;
      }, {})
    : records.find((c) => c.id === id);

  if (patch.fields) {
    const [result] = patch.fields;
    if (!(result.addValues || result.removeValues)) {
      const field = allFields.find((item) => item.id === result.id);
      patch.fields = [
        {
          id: result.id,
          value: getValueForApi(result.value, field),
        },
      ];
    }
  }

  if (!record) {
    throw new Error('Unknown record to update');
  }

  let result;
  if (!isPipelineObject(model)) {
    result = await FieldService.patchCustomModelRecord(
      id,
      model.id,
      camelToSnakeCaseKeys(patch),
      { params: { return_all_fields: true } }
    );
    return {
      ...result,
      fields: getFieldsForUI(result.fields),
      owner: result.owner ? ownerToOption(result.owner) : null,
    };
  }
  result = await PipelineService.updatePipelineRecord(
    id,
    model.id,
    camelToSnakeCaseKeys(patch),
    { params: { return_all_fields: true } }
  );
  const responseFields = getFieldsForUI(result.fields);

  let { fields } = record;

  if (patch.fields) {
    if (!responseFields.length) {
      // if we remove value, we remove this field from record's fields list
      fields = fields.filter((item) => item.field !== patch.fields[0].id);
    } else {
      // update field value
      fields = responseFields;
    }
  }

  return {
    ...result,
    owner: result.owner ? ownerToOption(result.owner) : null,
    stage: result.stage
      ? {
          value: result.stage.id,
          label: result.stage.name,
        }
      : null,
    fields,
  };
};
export const setRecordTitle = (title, { objectName = '' }) =>
  objectName ? `${objectName} - ${title}` : title;

const CustomObjectRecordsPage = () => {
  const { t } = useTranslation();
  const model = useSelector((s) => s.recordsPage.model);
  const objectsSettingsAccess = useSelector(getCustomObjectsSettingsAccess);

  const lostStages = useMemo(
    () =>
      model?.pipeline?.stages?.filter(
        ({ status }) => status === STAGE_STATUSES.lost
      ),
    [model]
  );

  const disqualifiedStages = useMemo(
    () =>
      model?.pipeline?.stages?.filter(
        ({ status }) => status === STAGE_STATUSES.disqualified
      ),
    [model]
  );

  const { objectId, subpage, chartId } = useParams();

  const viewTitle =
    subpage === VIEW_VARIANTS.CHARTS ? t('Charts') : t('Records');
  useSetTitleOnLoad(
    model?.objectName ? `${model.objectName} - ${viewTitle}` : viewTitle
  );

  const history = useHistory();
  const hasGroupQueryParam = useRef(
    Boolean(getSearchParam(history.location, 'group'))
  );
  const [showToast] = useToast();
  const { width } = useWindowSize();
  const dispatch = useSyncDispatch();
  const groups = useSelector((s) => s.recordsPage.groups);
  const filters = useSelector((s) => s.recordsPage.filters);
  const hasFilterError = useSelector(getHasFilterError);
  const [isMenuOpen, toggleMenu] = useState(false);

  const pageConfig = useSelector((s) => s.recordsPage.pageConfig);

  const isLoadingView = useSelector(getIsLoadingView);
  const records = useSelector((s) => s.recordsPage.records);
  const recordsCount = useSelector((s) => s.recordsPage.recordsCount);
  const allCount = useSelector((s) => s.recordsPage.allCount);
  const groupCount = useSelector((s) => s.recordsPage.groupCount);
  const teamMember = useSelector(getAuthTeamMember);

  const selection = useSelector((s) => s.recordsPage.selection);
  const recordsCountAndNew = useSelector(
    (s) => s.recordsPage.recordsCountAndNew
  );
  const initialFetching = useSelector((s) => s.recordsPage.initialFetching);
  const categorizedFields = useSelector((s) => s.recordsPage.categorizedFields);
  const stages = useSelector((s) => s.recordsPage.stages);
  const isFetching = useSelector((s) => s.recordsPage.isFetching);
  const fields = useSelector((s) => s.recordsPage.fields);
  const allFields = useSelector((s) => s.recordsPage.allFields);
  const boardData = useSelector((s) => s.recordsPage.boardData);
  const toastData = useSelector((s) => s.recordsPage.toastData);
  const reasonsDialogData = useSelector(getReasonsDialogData);
  const reasonsDisqualifiedDialogData = useSelector(
    getReasonsDisqualifiedDialogData
  );

  const globalCOAccess = useSelector(getCustomObjectEntitiesAccess);

  const needToLoadCounts = useSelector(getNeedToLoadCountsSelector);
  const [showUploadRecordsModal, toggleUploadRecordsModal] = useToggle(false);
  const [addingId, setAddingId] = useState(null);
  const [showNewRecordModal, setShowNewRecordModal] = useState(false);
  const [newRecordStageId, setNewRecordStageId] = useState(null);
  const [userActionChoice, setUserActionChoice] = useState(null);
  const { dispatchSessionState } = useDataManagerSession();
  const [lastCheckedSelection, setLastCheckedSelection] = useState(null);

  const filterGroupReason = useSelector(getFilterGroupReason);
  const pageReady = useSelector(getRecordsPageReady);
  const { viewVariantsAccess, quickFiltersAccess } = useSelector((s) =>
    getRecordOverviewAccess(s, objectId)
  );

  const savedColumnSettings = useSelector(
    (s) => getColumns(s.recordsPage.pageConfig),
    shallowEqualList
  );

  const lastFetchedCriteria = useSelector((s) => s.recordsPage.fetchedCriteria);

  const { search, filterObject } = pageConfig;

  // Track debounced changes to the search term and send to google analytics
  useDebounce(
    () => maybeTrackSearch(search, 'custom_object_records'),
    DEFAULT_DELAY,
    [search]
  );
  const addingUniqueId = useRef(null);
  const notSearchChanged = useRef(false);

  useEffect(() => () => dispatch(resetPage()), [dispatch, objectId]);

  useEffect(() => {
    // track search changes to update the search params as setting it in the handler causes an extra render that appears to stall the search
    if (notSearchChanged.current) {
      setSearchParams(
        history,
        {
          q: search,
          page: null,
        },
        { method: 'replace' }
      );
    } else if (typeof search !== 'undefined') {
      // if search is undefined, it's the first render and we don't want to track that
      notSearchChanged.current = true;
    }
  }, [search, history]);

  useEffect(() => {
    if (toastData) {
      showToast(toastData);
      dispatch(cleanToast());
    }
  }, [toastData, showToast, dispatch]);

  const storeViewVariant = useCallback(
    (value) => {
      // LOADING 3 - this is called from an effect in useRecordsPageRouting
      dispatch(
        updatePageConfig({
          // if filter is applied on page load then default to table view
          viewVariant: value,
        })
      );
      if (!hasGroupQueryParam.current) {
        dispatch(
          setPageConfig({
            updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
          })
        );
      }
      if (value === VIEW_VARIANTS.TABLE) {
        dispatch(
          setBoardDataStagesChecked({
            isChecked: false,
          })
        );
      }
    },
    [dispatch]
  );

  const pageConfigQueryFilterParam = pageConfig?.queryFilter?.queryParam;
  const dynamicTagFilterApplied =
    pageConfigQueryFilterParam === 'dynamictag' ||
    pageConfigQueryFilterParam === 'titles' ||
    pageConfigQueryFilterParam === 'tag';

  const isSettled = objectId === model?.id && !isFetching;

  const { viewVariant, changeChartGroup } = useRecordsPageRouting(
    isSettled,
    storeViewVariant,
    dynamicTagFilterApplied ? VIEW_VARIANTS.TABLE : pageConfig?.viewVariant,
    {
      isContact: false,
      viewVariantsAccess,
      queryParams: { group: getSearchParam(history.location, 'group') },
    }
  );

  const {
    isLoading: chartGroupsLoading,
    data,
    refetch: refetchChartGroups,
  } = useQuery(
    [...DASHBOARD.DASHBOARDS, objectId, false],
    () => fetchDashboardsQuery(null, false, objectId, 'chart_group'),
    {
      enabled: viewVariant === VIEW_VARIANTS.CHARTS && Boolean(model?.id),
    }
  );

  const sortedGroups = useMemo(() => getSortedDashboards(data ?? []), [data]);

  const { loading: configLoading, ...chartGroupConfigProps } =
    useCustomObjectChartGroupConfig(
      sortedGroups,
      viewVariant !== VIEW_VARIANTS.CHARTS || Boolean(chartId) || !isSettled,
      objectId,
      viewVariant === VIEW_VARIANTS.CHARTS && Boolean(data),
      isSettled
    );

  const performActionOptionsWithPermission = useMemo(() => {
    const modelPermission = globalCOAccess[model?.id];
    if (!modelPermission) return [];

    return getPerformActionOptions(t, 'Records').filter(
      (option) =>
        modelPermission && modelPermission[snakeToCamelCase(option.value)]
    );
  }, [model?.id, globalCOAccess, t]);

  const hasUploadButton = useMemo(() => {
    const modelPermission = globalCOAccess[model?.id];
    return !modelPermission ? false : modelPermission.bulkDataUpload;
  }, [model?.id, globalCOAccess]);

  // filter handlers -----------------------------------------------

  const filterInfo = useMemo(
    () => ({
      ...filters,
      name: pageConfig.queryFilter
        ? pageConfig.queryFilter?.maybeName
        : filters.name,
    }),
    [filters, pageConfig.queryFilter]
  );

  const resetFilterToast = useFilterErrorEffect({
    toggleMenu,
    hasFilterError,
    enabled: filterInfo?.modelId === objectId,
    filterGroupReason,
  });

  const clearFilter = useCallback(() => {
    dispatchSessionState({
      type: DATA_MANAGER_SESSION_ACTIONS.RESET,
      parentId: objectId,
    });

    dispatch(resetFilterData());
    dispatch(
      setCheckedSelection({
        value: 'none',
      })
    );
    switch (viewVariant) {
      case VIEW_VARIANTS.TABLE:
        dispatch(
          getRecords({
            updatePageConfig: true,
            updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
          })
        );
        break;
      case VIEW_VARIANTS.BOARD:
        if (isPipelineObject(model)) {
          dispatch(
            getBoardData({
              id: model.id,
              updatePageConfig: true,
              updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
            })
          );
        }
        break;
      default:
        dispatch(
          getRecordsBySearch({
            updatePageConfig: true,
            updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
            onlyUpdateCounts: true,
          })
        );
    }
    deferExecution(() => {
      setSearchParams(history, {
        page: null,
        ...(pageConfig.group ? { group: null } : {}),
        ...(pageConfig.search ? { q: '' } : {}),
      });
    });
  }, [
    dispatch,
    history,
    model,
    pageConfig.group,
    pageConfig.search,
    viewVariant,
    dispatchSessionState,
    objectId,
  ]);

  const updateFilterName = useCallback(
    (name) => {
      dispatch(setFilterName({ name }));
    },
    [dispatch]
  );

  const updateFilterCount = useCallback(
    (count) => {
      dispatch(setNumberOfFilters(count));
    },
    [dispatch]
  );

  const applyFiltersAction = useCallback(
    (payload) => {
      dispatchSessionState({
        type: DATA_MANAGER_SESSION_ACTIONS.RESET,
        parentId: objectId,
      });

      dispatch(
        updatePageConfig({
          page: 1,
          filterObject: payload,
        })
      );

      dispatch(
        setCheckedSelection({
          value: 'none',
        })
      );
      switch (viewVariant) {
        case VIEW_VARIANTS.TABLE:
          dispatch(
            getRecords({
              updatePageConfig: true,
              updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
            })
          );
          break;
        case VIEW_VARIANTS.BOARD:
          if (isPipelineObject(model)) {
            dispatch(
              getBoardData({
                id: model.id,
                updatePageConfig: true,
                updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
              })
            );
          }
          break;
        default:
          dispatch(
            getRecordsBySearch({
              updatePageConfig: true,
              updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
              onlyUpdateCounts: true,
            })
          );
      }
    },
    [dispatch, model, viewVariant, dispatchSessionState, objectId]
  );

  const applyQuickFilters = useCallback(
    (payload) => {
      dispatchSessionState({
        type: DATA_MANAGER_SESSION_ACTIONS.RESET,
        parentId: objectId,
      });

      dispatch(
        updatePageConfig({
          page: 1,
          quickFilters: payload,
        })
      );
      dispatch(
        setCheckedSelection({
          value: 'none',
        })
      );
      switch (viewVariant) {
        case VIEW_VARIANTS.TABLE:
          dispatch(
            getRecords({
              updatePageConfig: true,
              updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
            })
          );
          break;
        case VIEW_VARIANTS.BOARD:
          if (isPipelineObject(model)) {
            dispatch(
              getBoardData({
                id: model.id,
                updatePageConfig: true,
                updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
              })
            );
          }
          break;
        default:
          dispatch(
            getRecordsBySearch({
              updatePageConfig: true,
              updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
              onlyUpdateCounts: true,
            })
          );
      }
    },
    [dispatch, model, viewVariant, dispatchSessionState, objectId]
  );

  const saveGroupAction = useCallback(
    ({ filterGroup, isPatch }) => {
      dispatchSessionState({
        type: DATA_MANAGER_SESSION_ACTIONS.RESET,
        parentId: objectId,
      });

      dispatch(
        createOrUpdateGroup({
          filterGroup,
          history,
          isPatch,
        })
      );
      dispatch(
        updatePageConfig({
          filterObject: filterGroup.config,
        })
      );
      dispatch(
        setCheckedSelection({
          value: 'none',
        })
      );
    },
    [dispatch, history, dispatchSessionState, objectId]
  );

  const handleChangeGroupId = useCallback(
    ({ value }) => {
      const nextGroup = value === 'none' ? null : value;
      resetFilterToast();
      dispatchSessionState({
        type: DATA_MANAGER_SESSION_ACTIONS.RESET,
        parentId: objectId,
      });

      dispatch(
        changeGroup({
          id: nextGroup,
          t,
        })
      );
      dispatch(setIsAllRecordsGroup(nextGroup === null));
      dispatch(
        setCheckedSelection({
          value: 'none',
        })
      );

      dispatch(setCountsNeedToLoad());
      deferExecution(() => {
        setSearchParams(history, {
          group: nextGroup,
          page: null,
        });
      });
    },
    [history, dispatch, t, dispatchSessionState, objectId, resetFilterToast]
  );

  const deleteGroupAction = useCallback(() => {
    dispatchSessionState({
      type: DATA_MANAGER_SESSION_ACTIONS.RESET,
      parentId: objectId,
    });

    dispatch(
      removeGroup({
        history,
        objectId,
      })
    );
    dispatch(
      setCheckedSelection({
        value: 'none',
      })
    );

    toggleMenu(false);
  }, [dispatch, history, objectId, dispatchSessionState]);

  // ---------------------------------------------------------------

  const handleGetModel = useCallback(() => {
    // LOADING 2 - just part of initial effect
    resetFilterToast();

    const sort = getSearchParam(history.location, 'sort');
    const search = getSearchParam(history.location, 'q');
    const page = getSearchParam(history.location, 'page');
    const size = getSearchParam(history.location, 'size');
    const group = getSearchParam(history.location, 'group');

    const queryFilter = SupportedQueryFilters.reduce((acc, el) => {
      try {
        const data = getSearchParam(history.location, el);
        if (!data) return acc;
        const filter = JSON.parse(data);
        return {
          ...filter,
          queryParam: el,
        };
      } catch (error) {
        return acc;
      }
    }, null);

    dispatch(
      buildPage({
        customObjectId: objectId,
        history,
        page: {
          search: search || '',
          sort,
          group,
          page: page && parseInt(page, 10),
          size: size && parseInt(size, 10),
          queryFilter,
        },
      })
    );

    if (queryFilter) {
      toggleMenu(true);
    }

    setSearchParams(
      history,
      SupportedQueryFilters.reduce((acc, el) => {
        return {
          ...acc,
          [el]: null,
        };
      }, {}),
      { method: 'replace' }
    );
  }, [dispatch, history, objectId, resetFilterToast]);

  useEffect(() => {
    // LOADING 1 - inital effect
    dispatch(
      setCheckedSelection({
        value: 'none',
      })
    );
    handleGetModel();
  }, [handleGetModel, dispatch]);

  useEffect(() => {
    // LOADING 4 - called once model is set in redux
    if (model?.id && pageReady) {
      if (hasGroupQueryParam.current) {
        dispatch(clearFilterSearch());
        dispatch(
          getRecords({
            triggerViewLoading: true,
            updatePageConfig: false,
          })
        );
        if (viewVariant === VIEW_VARIANTS.BOARD) {
          dispatch(
            getBoardData({
              id: model?.id,
              triggerViewLoading: true,
            })
          );
        }
        hasGroupQueryParam.current = false;
      } else {
        switch (viewVariant) {
          case VIEW_VARIANTS.TABLE:
            dispatch(
              getRecords({
                triggerViewLoading: true,
                updatePageConfig: false,
              })
            );
            break;
          case VIEW_VARIANTS.BOARD:
            dispatch(
              getBoardData({
                id: model?.id,
                triggerViewLoading: true,
              })
            );
            break;
          default:
            dispatch(
              getRecords({
                triggerViewLoading: true,
                updatePageConfig: false,
                onlyUpdateCounts: true,
              })
            );
        }
      }
    }
  }, [dispatch, viewVariant, model, pageReady]);

  const updateTable = useCallback(() => {
    dispatch(getRecords());
    dispatch(
      setCheckedSelection({
        label: 'None',
        value: 'none',
        numberContacts: 0,
      })
    );
    dispatch(setCountsNeedToLoad());
  }, [dispatch]);

  const [
    ,
    ,
    {
      show: performActionModalShow,
      hide: performActionModalHide,
      showing: performActionModalIsShowing,
    },
  ] = useModal();

  const [
    confirmActionModalProps,
    ,
    { show: confirmActionModalShow, hide: confirmActionModalHide },
  ] = useModal({
    handleSubmit: (selectedActionGroup) => {
      handleCheckedSelection(selectedActionGroup);
      performActionModalShow();
    },
    handleHide: () => {
      setUserActionChoice(null);
    },
    processHideOnSubmit: false, // no handleHide on handleSubmit
  });

  const openPerformModal = useCallback(() => {
    if (!selection.checkedCount && selection.value !== 'all') {
      confirmActionModalShow();
    } else {
      performActionModalShow();
    }
  }, [
    selection.checkedCount,
    selection.value,
    performActionModalShow,
    confirmActionModalShow,
  ]);

  useEffect(() => {
    if (userActionChoice) {
      openPerformModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActionChoice]);

  const choiceAction = useCallback(
    (options) => setUserActionChoice(options),
    []
  );
  const resetSelectedAction = useCallback(() => setUserActionChoice(null), []);

  const onResetSelection = useCallback(() => {
    dispatch(
      setCheckedSelection({
        value: 'none',
      })
    );
  }, [dispatch]);

  const [addReasonLostModalProps, addReasonLostModal] = useAddReasonLostModal({
    handleUpdate: ({ patch, recordId, stageId }) => {
      handleUpdateRecord(recordId, patch, stageId ? { stageId } : {});
      dispatch(closeReasonDialog());
    },
    handleHide: () => {
      dispatch(closeReasonDialog());
    },
  });

  const [addReasonDisqualifiedModalProps, addReasonDisqualifiedModal] =
    useAddReasonLostModal({
      handleUpdate: ({ patch, recordId, stageId }) => {
        handleUpdateRecord(recordId, patch, stageId ? { stageId } : {});
        dispatch(closeReasonDisqualifiedDialog());
      },
      handleHide: () => {
        dispatch(closeReasonDisqualifiedDialog());
      },
    });

  const moving = useRef(false);
  const setMoving = useCallback((isMoving) => (moving.current = isMoving), []);

  // we need to clear any errors in filters when we change customObject
  useEffect(() => {
    if (model?.id === objectId) {
      // we need it for init filters for particular customObject
      dispatch(clearFilterErrors());
    }
  }, [model]); // eslint-disable-line react-hooks/exhaustive-deps

  const sortValue = useMemo(
    () => getSortValue(pageConfig.sort),
    [pageConfig.sort]
  );

  const [pageConfigGroup, filterGroup] = useMemo(
    () => [
      pageConfig.queryFilter ? null : pageConfig.group,
      pageConfig.queryFilter
        ? undefined
        : (groups || []).find(({ id }) => id === pageConfig.group),
    ],
    [pageConfig.group, pageConfig.queryFilter, groups]
  );

  const allInGroupDisabled = useMemo(() => {
    const matches =
      !lastFetchedCriteria ||
      areFiltersEqual(
        // It seems a little silly to convert both to camel case, but depending on how the data is fetched, one or the other
        // might be snake case, so we just force them both camel case for consistency
        snakeToCamelCaseKeys(filterGroup?.config),
        snakeToCamelCaseKeys(lastFetchedCriteria)
      );

    return !matches;
  }, [filterGroup, lastFetchedCriteria]);

  const selectionOptions = useMemo(() => {
    return !needToLoadCounts
      ? [
          {
            label:
              records.length !== 0
                ? `${t('This Page')} (${Number(
                    records.length
                  ).toLocaleString()})`
                : t('This Page'),
            value: BULK_ACTION_TYPES.page,
            numberContacts: records.length,
          },
          {
            label:
              records.length !== 0
                ? `${t('All in Search')} (${Number(
                    recordsCountAndNew
                  ).toLocaleString()})`
                : t('All in Search'),
            value: BULK_ACTION_TYPES.search,
            numberContacts: recordsCountAndNew,
          },
          {
            label:
              groupCount !== 0 && !allInGroupDisabled
                ? `${t('All in Group')} (${Number(
                    pageConfigGroup ? groupCount : allCount
                  ).toLocaleString()})`
                : t('All in Group'),
            value: BULK_ACTION_TYPES.group,
            numberContacts: pageConfigGroup ? groupCount : allCount,
            disabled: allInGroupDisabled,
          },
          {
            label:
              allCount !== 0
                ? `${t('All Records')} (${Number(allCount).toLocaleString()})`
                : t('All Records'),
            value: BULK_ACTION_TYPES.all,
            numberContacts: allCount,
          },
          {
            label: t('None'),
            value: BULK_ACTION_TYPES.none,
            numberContacts: 0,
          },
        ]
      : [];
  }, [
    needToLoadCounts,
    t,
    records.length,
    recordsCountAndNew,
    pageConfigGroup,
    groupCount,
    allCount,
    allInGroupDisabled,
  ]);

  // handlers
  const handleUpdateFieldOption = useCallback(
    (data) => {
      dispatch(updateFieldOptions(data));
    },
    [dispatch]
  );

  const handleUpdateTableRecords = useCallback(() => {
    dispatch(
      getRecords({
        updatePageConfig: true,
        updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
      })
    );
  }, [dispatch]);

  const handleChangeSearch = useCallback(
    (value) => {
      dispatchSessionState({
        type: DATA_MANAGER_SESSION_ACTIONS.RESET,
        parentId: objectId,
      });

      dispatch(
        setPageConfig({
          search: value,
          page: defaultPageSettings.pageNumber,
          updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
        })
      );
      dispatch(
        setCheckedSelection({
          value: 'none',
        })
      );

      switch (viewVariant) {
        case VIEW_VARIANTS.TABLE:
          dispatch(
            getRecordsBySearch({
              updatePageConfig: false,
            })
          );
          break;
        case VIEW_VARIANTS.BOARD:
          if (isPipelineObject(model)) {
            dispatch(
              getBoardDataBySearch({
                id: model.id,
              })
            );
          }
          break;
        default:
          dispatch(
            getRecordsBySearch({
              updatePageConfig: false,
              onlyUpdateCounts: true,
            })
          );
      }
    },
    [dispatch, model, viewVariant, dispatchSessionState, objectId]
  );

  const handleSortTable = useCallback(
    ({ column, direction }) => {
      const value = getOrderingParam({
        column,
        direction,
      });

      dispatch(
        updatePageConfig({
          sort: value,
        })
      );
      dispatch(
        getRecords({
          updatePageConfig: true,
          updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
        })
      );
      deferExecution(() => {
        setSearchParams(history, {
          sort: value,
        });
      });
    },
    [dispatch, history]
  );

  const handleChangePageNumber = useCallback(
    (value) => {
      dispatch(
        updatePageConfig({
          page: value,
        })
      );
      dispatch(
        getRecords({
          updatePageConfig: true,
          updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
        })
      );
      deferExecution(() => {
        setSearchParams(history, {
          page: value,
        });
      });
    },
    [history, dispatch]
  );

  const handleChangePageSize = useCallback(
    (value) => {
      dispatch(
        updatePageConfig({
          size: value,
        })
      );
      dispatch(
        getRecords({
          updatePageConfig: true,
          updatePageConfigKey: RECORD_LIST_CONFIG_KEYS.LAYOUT,
        })
      );
      deferExecution(() => {
        setSearchParams(history, {
          size: value,
        });
      });
    },
    [history, dispatch]
  );

  const handleUpdateRecord = useCallback(
    async (recordId, patch, { created, stageId } = {}) => {
      if (created) {
        dispatch(
          addNewRecord({
            id: recordId,
            record: patch,
            fields,
          })
        );
        return true;
      } else {
        const record = await updateRecordHandler({
          data: {
            id: recordId,
            patch,
            model,
          },
          state: {
            records,
            boardData,
          },
          allFields: fields,
          stageId,
        });
        if (record) {
          // check for showing reasons dialog
          const oldRecord = stageId
            ? boardData.stages.reduce((acc, stage) => {
                if (stage.id === stageId) {
                  return stage.cards.find((c) => c.id === recordId);
                }

                return acc;
              }, {})
            : records.find((c) => c.id === recordId);
          if (
            lostStages?.length &&
            oldRecord?.stage?.value !== record?.stage?.value &&
            lostStages?.some((item) => item.id === record?.stage?.value)
          ) {
            dispatch(
              showReasonDialog({
                record,
              })
            );
          }
          if (
            disqualifiedStages?.length &&
            oldRecord?.stage?.value !== record?.stage?.value &&
            disqualifiedStages?.some((item) => item.id === record?.stage?.value)
          ) {
            dispatch(showReasonDisqualifiedDialog({ record }));
          }

          dispatch(
            updateRecord({
              record,
              patch,
              fields,
              stageId,
            })
          );
          if (patch?.entityValue && record?.stage?.value) {
            // need to get the new summary
            const data = {
              stageId: record?.stage?.value,
              modelId: model.id,
              params: {
                page: 1,
                search: search,
                skipErrorBoundary: true, // we can silently fail if we get a 404
              },
              body: {
                criteria: filterObject.query ? filterObject : null,
              },
            };
            const result = await PipelineService.getRecordsInStage(data);

            dispatch(
              updateCommerceValue({
                ...result,
              })
            );
          }
          return true;
        }
        return false;
      }
    },
    [
      dispatch,
      fields,
      model,
      records,
      boardData,
      lostStages,
      disqualifiedStages,
      search,
      filterObject,
    ]
  );

  useEffect(() => {
    if (
      lostStages?.length &&
      lostStages?.some((item) =>
        reasonsDialogData?.some(({ record }) => record?.stage.value === item.id)
      )
    ) {
      !moving.current && addReasonLostModal.show();
    }
    if (
      disqualifiedStages?.length &&
      disqualifiedStages?.some((item) =>
        reasonsDisqualifiedDialogData?.some(
          ({ record }) => record?.stage.value === item.id
        )
      )
    ) {
      !moving.current && addReasonDisqualifiedModal.show();
    }
  }, [
    reasonsDialogData,
    addReasonLostModal,
    lostStages,
    disqualifiedStages,
    reasonsDisqualifiedDialogData,
    addReasonDisqualifiedModal,
  ]);

  const handleCheckedSelection = useCallback(
    (value) => {
      setLastCheckedSelection(value.value);
      dispatch(setCheckedSelection(value));
    },
    [dispatch]
  );

  if (allInGroupDisabled && lastCheckedSelection === 'group') {
    handleCheckedSelection({ value: 'none' });
  }

  const handleChecked = useCallback(
    (data) => {
      dispatch(
        setCheckedRecord({
          id: data,
        })
      );
    },
    [dispatch]
  );

  const handleSelectViewRecord = useCallback(
    (recordId) => {
      history.push(`/custom-objects/${model.id}/${recordId}/details`);
    },
    [history, model]
  );

  const handleSelectArchiveRecord = useCallback(
    (data) => {
      dispatch(archiveRecord(data.id));
    },
    [dispatch]
  );

  const handleChoseSettings = ({ value }) => {
    if (value === 'objectSettings') {
      history.push({
        pathname: `/custom-objects/${objectId}/settings`,
        search: history.location.search,
        state: {
          from: history.location.pathname,
          fromKey: GO_BACK_KEYS.OVERVIEW,
        },
      });
    } else if (value === 'customizeFields') {
      history.push({
        pathname: `/custom-objects/${objectId}/settings`,
        search: history.location.search,
        state: {
          from: history.location.pathname,
          fromKey: GO_BACK_KEYS.OVERVIEW,
          focusStepKey: CUSTOMIZE_FIELDS_STEP_KEY,
        },
      });
    } else if (value === 'boardSettings') {
      history.push({
        pathname: `/custom-objects/${objectId}/settings`,
        search: history.location.search,
        state: {
          from: history.location.pathname,
          fromKey: GO_BACK_KEYS.OVERVIEW,
          focusStepKey: CUSTOMIZE_LAYOUT_STEP_KEY,
          customLayoutTabKey: CUSTOM_LAYOUT_TABS.BOARD_VIEW_LAYOUT,
        },
      });
    }
  };

  const handleChoseNewRecord = useCallback(
    ({ value, stageId }) => {
      if (value === 'newRecord') {
        setShowNewRecordModal(true);
      }

      if (value === 'newRecord' && stageId) {
        setNewRecordStageId(stageId);
      }

      if (value === 'uploadRecords') {
        toggleUploadRecordsModal();
      }
    },
    [toggleUploadRecordsModal]
  );

  const handleCreateNewRecord = useCallback(
    (newRecord, unArchive = false) => {
      setAddingId(null);
      // even if addingUniqueId already exists, we need to check if this is an unarchive action and
      // if so, we need to update the record
      //
      if (newRecord && (newRecord.id !== addingUniqueId.current || unArchive)) {
        addingUniqueId.current = newRecord.id;
        handleUpdateRecord(newRecord.id, newRecord, {
          created: true,
        });
      }
    },
    [handleUpdateRecord]
  );

  // board handlers
  const handleGetMoreCards = useCallback(
    (data) => {
      dispatch(getRecordsInStage(data));
    },
    [dispatch]
  );

  const handleSelectStageColumn = useCallback(
    (data) => {
      dispatch(setStageSelection(data));
    },
    [dispatch]
  );

  const handleSelectCard = useCallback(
    (data) => {
      dispatch(setSelectionCard(data));
    },
    [dispatch]
  );

  const handleMoveCard = useCallback(
    (data) => {
      dispatch(moveRecord(data));
    },
    [dispatch]
  );

  const handleChoseCardMenu = useCallback(
    (data, card, stageId) => {
      switch (data) {
        case 'select': {
          handleSelectCard({
            selection: true,
            cardId: card.id,
            stageId,
          });
          break;
        }
        case 'unselect': {
          handleSelectCard({
            selection: false,
            cardId: card.id,
            stageId,
          });
          break;
        }
        case 'moveToTop': {
          handleMoveCard({
            recordIds: [card.id],
            stageId,
            index: 0,
            card,
            moveToPosition: 'top',
          });
          break;
        }
        case 'moveToBottom': {
          handleMoveCard({
            recordIds: [card.id],
            stageId,
            card,
            moveToPosition: 'bottom',
          });
          break;
        }
        default: {
          // case 'archive'
          handleSelectArchiveRecord({
            id: card.id,
          });
        }
      }
    },
    [handleMoveCard, handleSelectArchiveRecord, handleSelectCard]
  );

  const handleChangeStage = useCallback(
    async (card, patch, { stageId: fromStageId }) => {
      dispatch(
        moveRecord({
          recordIds: [card.id],
          fromStageId,
          stageId: patch.stageId,
          card,
          changeStage: true,
        })
      );
    },
    [dispatch]
  );

  const reasonLostField = useMemo(
    () => fields.find(isReasonLostField),
    [fields]
  );

  const reasonDisqualifiedField = useMemo(
    () => fields.find(isReasonDisqualifiedField),
    [fields]
  );

  const handleOpenSelectionMenu = useCallback(() => {
    if (needToLoadCounts) {
      dispatch(getCounts());
    }
  }, [dispatch, needToLoadCounts]);

  const createDashboardMutation = useMutation(
    (result) => createDashboardQuery(result, objectId, 'chart_group'),
    {
      onSuccess: (data) => {
        chartGroupConfigProps.afterCreate(data.id);
        deferExecution(() => {
          history.push(`/custom-objects/${objectId}/charts/${data.id}`);
        });
      },
    }
  );

  const handleCreateDashboard = useCallback(
    ({ name, permissions, isPrivate }) => {
      createDashboardMutation.mutate({
        name,
        sharing_settings: {
          ...buildSharingSettingsForAPI(permissions).sharing_settings,
          private: isPrivate,
        },
      });
    },
    [createDashboardMutation]
  );

  const [modalProps, , { show: handleShowModal }] = useModal({
    handleSubmit: handleCreateDashboard,
  });

  const onHidePerformActionModal = useCallback(() => {
    setUserActionChoice(null);
    performActionModalHide();
    confirmActionModalHide();
  }, [performActionModalHide, confirmActionModalHide]);

  const handleClickAddChartGroup = useCallback(() => {
    handleShowModal();
  }, [handleShowModal]);

  const mobileChartGroupConfigProps = useMemo(
    () => ({
      ...chartGroupConfigProps,
      refetchChartGroups: refetchChartGroups,
      setCurrentDashboardId: changeChartGroup,
    }),
    [changeChartGroup, refetchChartGroups, chartGroupConfigProps]
  );

  const desktopChartGroupConfigProps = useMemo(
    () => ({
      ...chartGroupConfigProps,
      refetchChartGroups: refetchChartGroups,
      setCurrentDashboardId: changeChartGroup,
      handleClickAddDashboard: handleClickAddChartGroup,
    }),
    [
      changeChartGroup,
      refetchChartGroups,
      handleClickAddChartGroup,
      chartGroupConfigProps,
    ]
  );

  const isPipelineType = model && isPipelineObject(model);
  const isMobileView = isMobile(width);

  const settingOptions = useMemo(() => {
    if (model?.id && !objectsSettingsAccess[model.id]) {
      console.log(
        `%cobjectsSettingsAccess for ${model.id} is not defined, using fallback.`,
        'color: #008000; background: #99ff99;'
      );
    }
    return getSettingOptions(
      isPipelineType && viewVariant === VIEW_VARIANTS.BOARD,
      objectsSettingsAccess[model.id] || fallbackCustomizeFields,
      t
    );
  }, [objectsSettingsAccess, model.id, isPipelineType, t, viewVariant]);

  const fullFilterObject = useMemo(() => {
    const { criteria } = buildPageFilterQuery(
      pageConfig.filterObject,
      pageConfig.quickFilters,
      allFields,
      model,
      pageConfig.quickFilterSettings
    );
    return criteria;
  }, [
    pageConfig.filterObject,
    pageConfig.quickFilters,
    allFields,
    model,
    pageConfig.quickFilterSettings,
  ]);

  const quickFilterConfig = useMemo(
    () => ({
      quickFilteringFacets: pageConfig.quickFilteringFacets,
      quickFilterSettings: pageConfig.quickFilterSettings,
    }),
    [pageConfig.quickFilteringFacets, pageConfig.quickFilterSettings]
  );

  if (isFetching || initialFetching) {
    return <Loader loading />;
  }

  if (model.access && !model.access.view) {
    throw new PermissionsError();
  }

  const isLoadingViewProp =
    isLoadingView ||
    !viewVariant ||
    (viewVariant === VIEW_VARIANTS.CHARTS &&
      (chartGroupsLoading || configLoading));

  return (
    <>
      {isMobileView ? (
        <MobileLayout
          model={model}
          createAccess={globalCOAccess[model.id]?.canCreate}
          records={records}
          recordsCount={recordsCount}
          recordsCountAndNew={recordsCountAndNew}
          search={pageConfig.search}
          onChangeSearch={handleChangeSearch}
          sort={sortValue}
          onChangeSort={handleSortTable}
          pageNumber={pageConfig.page || defaultPageSettings.pageNumber}
          pageSize={pageConfig.size || defaultPageSettings.pageSize}
          onChangePageSize={handleChangePageSize}
          onChangePageNumber={handleChangePageNumber}
          onChecked={handleChecked}
          settingOptions={settingOptions}
          viewVariant={viewVariant}
          onChoseSettings={handleChoseSettings}
          onChoseNewRecord={handleChoseNewRecord}
          selectionOptions={selectionOptions}
          onCheckedSelection={handleCheckedSelection}
          selection={selection}
          boardData={boardData}
          getMoreCards={handleGetMoreCards}
          onSelectStageColumn={handleSelectStageColumn}
          onChoseCardMenu={handleChoseCardMenu}
          handleChangeGroup={handleChangeGroupId}
          groupId={pageConfigGroup}
          groups={groups}
          stages={stages}
          fields={fields}
          onSubmitRecord={handleUpdateRecord}
          onChangeStage={handleChangeStage}
          performActionOptions={performActionOptionsWithPermission}
          choiceAction={choiceAction}
          handleUpdateTableRecords={handleUpdateTableRecords}
          openPerformModal={openPerformModal}
          chartGroupConfigProps={mobileChartGroupConfigProps}
          fullFilterObject={fullFilterObject}
          isLoadingView={isLoadingViewProp}
        />
      ) : (
        <FilterDropdownContext
          key={filterInfo.isAllRecordsGroup || filterInfo.key}
          config={filterInfo.config}
          objectId={objectId}
        >
          <ControlBar
            model={model}
            viewVariant={viewVariant}
            settingOptions={settingOptions}
            onChoseSettings={handleChoseSettings}
            chartGroupConfigProps={desktopChartGroupConfigProps}
          />
          <DesktopLayout
            filterType="custom_objects"
            viewVariant={viewVariant}
            model={model}
            createAccess={globalCOAccess[model.id]?.canCreate}
            hasUploadButton={hasUploadButton}
            records={records}
            recordsCount={recordsCount}
            recordsCountAndNew={recordsCountAndNew}
            onSubmitRecord={handleUpdateRecord}
            fields={fields}
            allFields={allFields}
            categorizedFields={categorizedFields}
            search={pageConfig.search}
            onChangeSearch={handleChangeSearch}
            sort={sortValue}
            onChangeSort={handleSortTable}
            pageNumber={pageConfig.page || defaultPageSettings.pageNumber}
            pageSize={pageConfig.size || defaultPageSettings.pageSize}
            onChangePageSize={handleChangePageSize}
            onChangePageNumber={handleChangePageNumber}
            selection={selection}
            selectionOptions={selectionOptions}
            onChecked={handleChecked}
            onCheckedSelection={handleCheckedSelection}
            onSelectViewRecord={handleSelectViewRecord}
            onSelectArchiveRecord={handleSelectArchiveRecord}
            stages={stages}
            onChoseNewRecord={handleChoseNewRecord}
            onCreateNewRecord={handleCreateNewRecord}
            addingId={addingId}
            setAddingId={setAddingId}
            boardData={boardData}
            getMoreCards={handleGetMoreCards}
            onSelectStageColumn={handleSelectStageColumn}
            onChoseCardMenu={handleChoseCardMenu}
            onMoveCard={handleMoveCard}
            onChangeStage={handleChangeStage}
            groups={groups}
            openMenu={isMenuOpen}
            toggleMenu={toggleMenu}
            filterInfo={filterInfo}
            hasFilterError={hasFilterError}
            quickFilters={
              model?.quickFilteringEnabled &&
              quickFiltersAccess &&
              pageConfig.quickFilters
            }
            quickFilterConfig={quickFilterConfig}
            applyFiltersAction={applyFiltersAction}
            applyQuickFilters={applyQuickFilters}
            saveGroupAction={saveGroupAction}
            deleteGroupAction={deleteGroupAction}
            setFilterName={updateFilterName}
            clearFilter={clearFilter}
            handleUpdateFieldOption={handleUpdateFieldOption}
            handleChangeGroup={handleChangeGroupId}
            performActionOptions={performActionOptionsWithPermission}
            choiceAction={choiceAction}
            handleUpdateTableRecords={handleUpdateTableRecords}
            openPerformModal={openPerformModal}
            groupId={pageConfigGroup}
            filterGroup={filterGroup}
            data-qa={isLoadingView ? '' : 'custom-object-records-table'}
            setMoving={setMoving}
            onOpenSelectionMenu={handleOpenSelectionMenu}
            chartGroupConfigProps={desktopChartGroupConfigProps}
            fullFilterObject={fullFilterObject}
            updateFilterCount={updateFilterCount}
            errorsReturned={filterInfo.errors}
            isLoadingView={isLoadingViewProp}
          />
        </FilterDropdownContext>
      )}
      {showUploadRecordsModal && (
        <UploadRecordsModal
          entityName={model.entityName}
          show={showUploadRecordsModal}
          onHide={toggleUploadRecordsModal}
          model={model}
          categorizedFields={categorizedFields}
        />
      )}
      <ConfirmActionModal
        selectionOptions={selectionOptions.filter(
          (item) => item.value !== 'none'
        )}
        onMenuOpen={handleOpenSelectionMenu}
        {...confirmActionModalProps}
      />
      {performActionModalIsShowing && (
        <PerformActionModal
          allCount={allCount}
          selection={selection}
          search={pageConfig.search}
          groupId={pageConfigGroup}
          groupOptions={groups}
          onHide={onHidePerformActionModal}
          selectedAction={userActionChoice}
          resetSelectedAction={resetSelectedAction}
          show={performActionModalIsShowing}
          onSelect={choiceAction}
          performActionOptions={performActionOptionsWithPermission}
          filters={fullFilterObject}
          model={model}
          isMobile={isMobile(width)}
          updateTable={updateTable}
          // additional props
          recordsCount={recordsCountAndNew}
          records={records}
          savedColumnSettings={savedColumnSettings}
          onResetSelection={onResetSelection}
          fields={fields}
          // onUpdateClientsList added so that clients list can get refreshed on tag change
          // TODO: maybe add redux action which will trigger clients list refresh with the same params?
        />
      )}
      {showNewRecordModal ? (
        <CreateEntityModalFull
          show={true}
          objectType={isPipelineType ? PIPELINE : CUSTOM_OBJECT}
          onCancel={() => {
            setShowNewRecordModal(false);
            setNewRecordStageId(null);
          }}
          objectId={model.id}
          initialValues={
            newRecordStageId
              ? {
                  stage: newRecordStageId,
                }
              : {}
          }
          onCreated={(v) => {
            // we can to do everything what we want with new record
            handleCreateNewRecord({
              ...v,
              owner: ownerToOption(v.owner || teamMember),
              stage: v.stage
                ? {
                    value: v.stage.id,
                    label: v.stage.name,
                  }
                : null,
              fields: getFieldsForUI(v.fields),
            });
          }}
          handleUpdateFieldOption={handleUpdateFieldOption}
        />
      ) : null}

      {reasonsDialogData.length ? (
        <AddReasonLostModal
          model={model}
          reasonLostField={reasonLostField}
          reasonsDialogData={reasonsDialogData}
          {...addReasonLostModalProps}
          handleUpdateFieldOption={handleUpdateFieldOption}
          disabled={moving.current}
        />
      ) : null}
      {reasonsDisqualifiedDialogData.length ? (
        <AddReasonDisqualifiedModal
          model={model}
          reasonDisqualifiedField={reasonDisqualifiedField}
          reasonsDialogData={reasonsDisqualifiedDialogData}
          {...addReasonDisqualifiedModalProps}
          handleUpdateFieldOption={handleUpdateFieldOption}
          disabled={moving.current}
        />
      ) : null}
      <CreateLayoutModal
        {...modalProps}
        canUserEditPermissions
        entity={t('Chart Group')}
      />
    </>
  );
};

export default CustomObjectRecordsPage;
