import { useAsync } from 'react-use';
import CustomObjectsService from 'services/CustomObjectsService';
import {
  FieldLight,
  SmartConnectorDataSeed,
} from '__pages/SmartConnectors/types';
import { useCustomObjectFields } from './useCustomObjectFields';
import MultiSelect from '__components/Inputs/MultiSelect';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { CategorizedFields } from './helpers';

type ReferenceFieldsProps = {
  customObjectId?: string;
  dataSeed: SmartConnectorDataSeed | undefined;
  onChange: (fields: SmartConnectorDataSeed['fields']) => void;
};

type FieldSelectProps = {
  isLoading: boolean;
  dataSeed: SmartConnectorDataSeed | undefined;
  categorizedFields: CategorizedFields;
  fields: FieldLight[];
  onChange: (fields: SmartConnectorDataSeed['fields']) => void;
};

const EMPTY_ARRAY: any[] = [];

const FieldsSelect = ({
  isLoading,
  dataSeed,
  categorizedFields,
  fields,
  onChange,
}: FieldSelectProps) => {
  const { t } = useTranslation();
  const selectProps = useCustomObjectFields({
    dataSeed,
    categorizedFields,
    fields,
    onChange,
  });

  return (
    <MultiSelect
      {...selectProps}
      label={t('Included Fields')}
      placeholder={t('Find Fields')}
      isLoading={isLoading}
      menuInline
    />
  );
};

export const ReferenceFields = ({
  customObjectId,
  dataSeed,
  onChange,
}: ReferenceFieldsProps) => {
  const fieldsByCustomObjectId = useRef<
    Map<string, { fields: FieldLight[]; categorizedFields: CategorizedFields }>
  >(new Map()).current;
  const { loading } = useAsync(async () => {
    if (!customObjectId) {
      return;
    }
    if (fieldsByCustomObjectId.has(customObjectId)) {
      return fieldsByCustomObjectId.get(customObjectId);
    }
    const { fields, categorizedFields } =
      await CustomObjectsService.getCategorizedModelFields(customObjectId, {
        settingsSearch: true,
        transformToCamelCase: false,
      });

    const result = {
      fields,
      categorizedFields: categorizedFields.map(
        ({
          id,
          fields,
          name,
        }: {
          id: string;
          fields: FieldLight[];
          name: string;
        }) => ({ id, fields, label: name })
      ),
    };

    fieldsByCustomObjectId.set(customObjectId, result);

    return result;
  }, [customObjectId]);

  const { fields = EMPTY_ARRAY, categorizedFields = EMPTY_ARRAY } =
    (customObjectId && fieldsByCustomObjectId.get(customObjectId)) || {};

  return (
    <FieldsSelect
      key={customObjectId}
      isLoading={loading}
      fields={fields}
      categorizedFields={categorizedFields}
      dataSeed={dataSeed}
      onChange={onChange}
    />
  );
};
