import { getAutomationFolders } from '@kizen/api/automation';
import {
  all,
  takeLatest,
  put,
  call,
  select,
  debounce,
} from 'redux-saga/effects';
import { DEFAULT_DELAY, SET_CONFIG_DELAY } from 'utility/config';
import { setSearchParams } from 'hooks/useSearchParam';
import AxiosService from 'services/AxiosService';
import Automation2Service, {
  getAutomationObjectName,
} from 'services/Automation2Service';
import TeamMemberService from 'services/TeamMemberService';
import CustomObjectsService from 'services/CustomObjectsService';
import { allowedRelationshipDropdownOptions } from 'xforms/api/customObjects';
import { sortByStringField } from 'xforms/sorting';
import {
  buildPage as buildPageAction,
  buildPageFail,
  buildPageComplete,
  changeGroupAction,
  getAutomations as getAutomationsAction,
  getAutomationsSuccess,
  deleteAutomation as deleteAutomationAction,
  deleteAutomationFail,
  duplicateAutomation as duplicateAutomationAction,
  setToast,
  setPageConfig as setPageConfigAction,
  updatePageConfig,
  updatePageConfigBySearch,
  setPageConfigSuccess,
  setPageConfigFail,
  createOrUpdateGroup as createOrUpdateGroupAction,
  setCreateOrUpdateGroupFail as setCreateOrUpdateGroupFailAction,
  changeDirectoryAction,
  removeGroup as removeGroupAction,
  createOrUpdateGroupSuccess,
  removeGroupFail,
  removeGroupSuccess,
  getAutomationsStart,
  getAutomationsFinish,
  resetFilters,
  setFilters,
  setFilterErrors,
  setFilterName,
  setGroupId,
  changeDirectorySuccess,
  deleteFolderAction,
  getFoldersSuccess,
  selectCurrentDirectory,
  selectShouldLoadFolders,
  deleteAutomationSuccess,
  deleteFolderSuccess,
  duplicateAutomationSuccess,
  applySearchAction,
} from './automations.redux';
import { toastVariant } from 'components/ToastProvider';
import { anyFilterErrors, getFilterErrors } from 'hooks/useFilterErrorEffect';
import { FORCE_ALL_RECORDS_SIZE, snakeToCamelCaseKeys } from 'services/helpers';
import { selectFilterMetadataDefinition } from 'store/filterMetaData/selectors';
import { loadSavedFilter } from 'ts-filters/utils';

const PAGE_CONFIG_KEY = 'automation_list';

function* getFolders(folderId) {
  const folders = yield call(getAutomationFolders, AxiosService);

  yield put(
    getFoldersSuccess({ folders: snakeToCamelCaseKeys(folders.data), folderId })
  );
}

function* getAutomations({ payload } = { payload: {} }) {
  try {
    yield put(getAutomationsStart());
    const pageConfig = yield select((s) => s.automationPage.pageConfig);
    const currentDirectory = yield select(selectCurrentDirectory);
    const includeEmptyFolders =
      !pageConfig.search &&
      !pageConfig.filterObject?.query &&
      !pageConfig.group;
    const data = {
      values: {
        search: pageConfig.search,
        ordering: pageConfig.sort,
        groupId:
          !pageConfig.filterObject?.query && pageConfig.group
            ? pageConfig.group
            : null,
        criteria: !pageConfig.filterObject?.query
          ? null
          : pageConfig.filterObject,
        page: {
          number: pageConfig.page,
          size: pageConfig.size,
        },
        folderId:
          'folderId' in payload ? payload.folderId : currentDirectory.id,
        includeEmptyFolders,
      },
    };

    const [{ results, subfolders, count }] = yield all([
      call(Automation2Service.search, data.values),
      payload.updatePageConfig &&
        call(TeamMemberService.setPageConfig, PAGE_CONFIG_KEY, pageConfig),
    ]);

    yield put(
      getAutomationsSuccess({
        automations: results.map((item) => ({
          ...item,
          customObjectName: getAutomationObjectName(item),
          status: item.active,
        })),
        subfolders,
        automationsCount: count,
      })
    );
  } catch (error) {
    if (anyFilterErrors(error?.response)) {
      const { errors } = getFilterErrors(error.response.data?.errors);
      yield put(setFilterErrors(errors));
    }
  } finally {
    yield put(getAutomationsFinish());
  }
}

function* deleteAutomation({ payload }) {
  const { id, t } = payload;
  try {
    yield call(Automation2Service.delete, id);
    yield put(
      setToast({
        variant: toastVariant.SUCCESS,
        message: t('The automation has been successfully deleted.'),
      })
    );
    yield put(deleteAutomationSuccess({ automationId: id }));
  } catch (error) {
    yield put(
      setToast({
        variant: toastVariant.FAILURE,
        message: t('The automation has not been successfully deleted.'),
      })
    );
    yield put(deleteAutomationFail(error));
  }
}

function* duplicateAutomation({ payload: automationId }) {
  try {
    const automation = yield call(Automation2Service.duplicate, automationId);

    // This automation model does not have createdBy and updatedBy like the rest of the automations
    const newAutomation = {
      id: automation.id,
      name: automation.name,
      apiName: automation.apiName,
      created: automation.created,
      active: automation.active,
      revision: automation.revision,
      numberActive: automation.numberActive,
      numberPaused: automation.numberPaused,
      numberCompleted: automation.numberCompleted,
      customObject: automation.customObject,
      folder: automation.folder,
      customObjectName: getAutomationObjectName(automation), // same mapping as getAutomations
      status: automation.active, // same mapping as getAutomations
    };

    yield put(
      duplicateAutomationSuccess({
        duplicateId: automationId,
        newAutomation,
      })
    );
  } catch (error) {
    yield put(deleteAutomationFail(error));
  }
}

function* buildPage({ payload }) {
  try {
    const { groups, folderId, t } = payload;

    const shouldLoadFolders = yield select(selectShouldLoadFolders);

    const [groupsResponse, { results }, pageResponse] = yield all(
      [
        call(Automation2Service.getGroups, groups),
        call(CustomObjectsService.getCustomObjectList, {
          size: FORCE_ALL_RECORDS_SIZE,
          customOnly: false,
        }),
        call(TeamMemberService.getPageConfig, PAGE_CONFIG_KEY),
        shouldLoadFolders && call(getFolders, folderId),
      ].filter(Boolean)
    );

    let config = null;
    if (pageResponse.group) {
      const group = groupsResponse.find((g) => g.id === pageResponse.group);
      if (group) {
        config = group.config;
        yield put(setGroupId(group.id));
        yield put(setFilterName({ name: group.name }));
      }
    } else if (Object.keys(pageResponse.filterObject || {}).length) {
      config = pageResponse.filterObject;
    }

    let data = null;
    if (config) {
      const metadata = yield select(selectFilterMetadataDefinition);
      data = yield call(loadSavedFilter, metadata, config, []);
    }

    yield put(
      buildPageComplete({
        t,
        groupsResponse,
        models: results
          .map((item) => ({
            ...allowedRelationshipDropdownOptions(item),
            data: item,
          }))
          .sort(sortByStringField((x) => x.label)),
        pageResponse,
        filters: data,
      })
    );

    const currentDirectory = yield select(selectCurrentDirectory);

    yield getAutomations({
      payload: { updatePageConfig: false, folderId: currentDirectory.id },
    });
  } catch (error) {
    yield put(buildPageFail(error));
  }
}

function* setPageConfig({ payload }) {
  try {
    const { fetchContacts = false } = payload;
    const pageConfig = yield select((s) => s.automationPage.pageConfig);

    yield call(TeamMemberService.setPageConfig, PAGE_CONFIG_KEY, pageConfig);

    if (fetchContacts) {
      yield call(getAutomations, { payload: { updatePageConfig: false } });
    }

    yield put(setPageConfigSuccess());
  } catch (error) {
    yield put(setPageConfigFail(error));
  }
}

function* createOrUpdateGroup({ payload }) {
  try {
    const { history, ...rest } = payload;
    const pageConfig = yield select((s) => s.automationPage.pageConfig);

    let result;
    if (pageConfig.group) {
      result = yield call(
        Automation2Service.updateGroup,
        rest,
        pageConfig.group
      );
    } else {
      result = yield call(Automation2Service.createGroup, rest);
      yield setSearchParams(history, {
        group: result.id,
        page: null,
      });
    }
    yield put(createOrUpdateGroupSuccess(result));
    yield getAutomations({ payload: { updatePageConfig: true } });
  } catch (error) {
    yield put(setCreateOrUpdateGroupFailAction(error));
  }
}

function* removeGroup({ payload }) {
  try {
    const pageConfig = yield select((s) => s.automationPage.pageConfig);
    yield call(Automation2Service.deleteGroup, pageConfig.group);
    yield setSearchParams(payload.history, {
      group: null,
      page: null,
    });
    yield put(removeGroupSuccess({ id: pageConfig.group }));
    yield getAutomations({ payload: { updatePageConfig: true } });
  } catch (error) {
    yield put(removeGroupFail(error));
  }
}

function* changeGroup({ payload }) {
  const { id } = payload;

  if (id === 'none') {
    yield put(resetFilters());
    return;
  }

  const groups = yield select((s) => s.automationPage.groups);
  const group = groups.find((g) => g.id === id);

  if (group) {
    const metadata = yield select(selectFilterMetadataDefinition);
    const data = yield call(loadSavedFilter, metadata, group.config, []);

    yield put(setFilters(data));
    yield put(setFilterName({ name: group.name }));
    yield put(setGroupId(id));
    yield put(updatePageConfig({ page: 1 }));
    yield getAutomations({ payload: { updatePageConfig: true } });
  }
}

function* applySearch({ payload }) {
  const { history, search } = payload;

  yield put(updatePageConfigBySearch({ search, page: 1 }));

  yield* getAutomations();

  yield setSearchParams(
    history,
    {
      q: search,
      page: null,
    },
    { method: 'replace' }
  );
}

function* changeDirectory({ payload }) {
  const { folder } = payload;
  const folderId = folder.parentFolderId === null ? undefined : folder.id;

  const page = yield select((s) => s.automationPage.pageConfig);

  yield call(getAutomations, { payload: { folderId, page } });
  yield put(changeDirectorySuccess({ folder }));
}

function* deleteFolder({ payload }) {
  const { id } = payload;

  yield put(deleteFolderSuccess({ folderId: id }));

  yield call(getAutomations, {
    payload: { updatePageConfig: false },
  });
}

function* automationPage() {
  yield takeLatest(buildPageAction.type, buildPage);
  yield debounce(DEFAULT_DELAY, getAutomationsAction.type, getAutomations);
  yield takeLatest(deleteAutomationAction.type, deleteAutomation);
  yield takeLatest(duplicateAutomationAction.type, duplicateAutomation);
  yield takeLatest(setPageConfigAction.type, setPageConfig);
  yield debounce(
    SET_CONFIG_DELAY,
    updatePageConfigBySearch.type,
    setPageConfig
  );
  yield takeLatest(createOrUpdateGroupAction.type, createOrUpdateGroup);
  yield takeLatest(applySearchAction.type, applySearch);
  yield takeLatest(removeGroupAction.type, removeGroup);
  yield takeLatest(changeGroupAction.type, changeGroup);
  yield takeLatest(changeDirectoryAction.type, changeDirectory);
  yield takeLatest(deleteFolderAction.type, deleteFolder);
}

export default automationPage;
