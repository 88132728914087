import { RefObject } from 'react';
import AxiosService from 'services/AxiosService';
import FileService from 'services/FileService';
import { ACTIONS, RESPONSES, COMMUNICATIONS } from './constants';
import { OnShowToastFn, ShowToastOptions, StateChangePayload } from './types';
import { History } from 'history';
import {
  DataAdornment,
  FloatingFrame,
  RoutablePage,
} from 'ts-components/Plugins/PluginContext';
import { toastVariant } from 'components/ToastProvider';
import { invalidate } from '__queries/invalidate';
import { FIELD_VALUE_FILE_UPLOAD_SOURCE } from '__hooks/uploadFiles/useUploadFile';
import { unsafeRootQueryClient } from '__queries/unsafe-query-client';
import {
  ALLOWED_INTEGRATIONS,
  getScriptIntegrationType,
  thirdPartyGlobalNames,
  thirdPartyReadyPredicates,
  thirdPartySetupScripts,
} from './ThirdPartyScript';

const isRelative = (url: string) => {
  return url.startsWith('/');
};

const allowedAllowValues = [
  'microphone',
  'speaker-selection',
  'autoplay',
  'camera',
  'display-capture',
  'hid',
];

const allowedSandboxValues = [
  'allow-popups',
  'allow-scripts',
  'allow-same-origin',
];

export class WorkerManager {
  private worker: Worker;
  private scriptUIRef?: RefObject<HTMLDivElement>;
  private onStateChange?: (state: StateChangePayload) => void;
  private done: (preserve: boolean, result?: any) => void;
  private onError?: (error: any) => void;
  private waitForFrame = false;
  private history?: History;
  private plugin?: FloatingFrame | RoutablePage;
  private executionPlugin?: DataAdornment;
  private frameId?: string;
  private onShowToast?: OnShowToastFn;
  private onClearToasts?: () => void;
  private sessionData: Record<string, any> = {};
  private setSessionData: (state: Record<string, any>) => void;
  private pluginComponentId: string;

  constructor(args: {
    worker: Worker;
    done: (preserve: boolean, result?: any) => void;
    scriptUIRef?: RefObject<HTMLDivElement>;
    onStateChange?: (state: StateChangePayload) => void;
    onError?: (error: any) => void;
    history?: History;
    plugin?: FloatingFrame | RoutablePage;
    executionPlugin?: DataAdornment;
    onShowToast?: OnShowToastFn;
    onClearToasts?: () => void;
    sessionData: Record<string, Record<string, any>>;
    setSessionData: (pluginId: string, state: Record<string, any>) => void;
    pluginComponentId: string;
  }) {
    this.scriptUIRef = args.scriptUIRef;
    this.onStateChange = args.onStateChange;
    this.done = args.done;
    this.onError = args.onError;
    this.worker = args.worker;
    this.worker.onmessage = this.handleMessage;
    this.history = args.history;
    this.plugin = args.plugin;
    this.executionPlugin = args.executionPlugin;
    this.onShowToast = args.onShowToast;
    this.onClearToasts = args.onClearToasts;
    this.pluginComponentId = args.pluginComponentId;

    if (this.plugin) {
      this.frameId = `kzn-integration-frame-${this.plugin.plugin_api_name}-${this.plugin.api_name}`;
    }

    const pluginApiName =
      this.executionPlugin?.plugin_api_name || this.plugin?.plugin_api_name;

    if (pluginApiName) {
      this.sessionData = args.sessionData?.[pluginApiName] || {};
      this.setSessionData = (state: Record<string, any>) =>
        args.setSessionData?.(pluginApiName, state);
    } else {
      this.setSessionData = () => {
        this.onError?.({
          message:
            'Script must be associated with a plugin to use session data',
        });
      };
    }
  }

  private handleMessage = (rawEvent: MessageEvent) => {
    const event = JSON.parse(rawEvent.data);
    const {
      action,
      id,
      method,
      url,
      payload,
      options,
      createNewTab,
      state,
      target,
      features,
      markup,
      error,
      type,
      recipient,
      args,
      allow,
      sandbox,
      message,
      toastOptions,
      entityId,
      result,
      preserve,
      update,
      params,
    } = event;

    switch (action) {
      case ACTIONS.QUERY_REQUEST:
        return this.handleQueryRequest(id, method, url, payload, options);
      case ACTIONS.UI_OUTPUT:
        return this.handleUIOutput(markup);
      case ACTIONS.IFRAME_OUTPUT:
        return this.handleIframeOutput(url, allow, sandbox, preserve);
      case ACTIONS.POSTFORMDATA_REQUEST:
        return this.handleFormPostRequest(id, url, payload, createNewTab);
      case ACTIONS.UPLOADFILE_REQUEST:
        return this.handleUploadFileRequest(id, payload);
      case ACTIONS.SETSTATE:
        return this.onStateChange?.(state);
      case ACTIONS.DONE:
        return this.handleDone(preserve, result);
      case RESPONSES.ERROR:
        return this.onError?.({ message: error });
      case ACTIONS.OPEN_WINDOW:
        return this.handleOpenWindow(url, target, features);
      case ACTIONS.COMMUNICATE:
        return this.handleCommunication(type, recipient, args, params);
      case ACTIONS.HIDE:
        return this.onStateChange?.({ hidden: true });
      case ACTIONS.SHOW:
        return this.onStateChange?.({ hidden: false });
      case ACTIONS.EXPAND:
        return this.onStateChange?.({ minimized: false });
      case ACTIONS.COLLAPSE:
        return this.onStateChange?.({ minimized: true });
      case ACTIONS.SHOW_TOAST:
        return this.showToast?.(message, toastOptions);
      case ACTIONS.CLEAR_TOASTS:
        return this.clearToasts?.();
      case ACTIONS.REFRESH_TIMELINE:
        return this.handleRefreshTimeline(entityId);
      case ACTIONS.REFRESH_ENTITY:
        return this.handleRefreshEntity(entityId);
      case ACTIONS.UPDATE_SESSION_DATA:
        return this.handleSetSessionData(update);
      case ACTIONS.INSTALL_THIRD_PARTY_SCRIPT_REQUEST:
        return this.handleInstallThirdPartyScriptRequest(id, url, args);
      default:
        return;
      // TODO (keegandonley): Handle the setting/retrieving of state now that it's available here
    }
  };

  private handleCommunication = (
    type: string,
    recipient: {
      frame?: string;
      script?: string;
      type?: (typeof ALLOWED_INTEGRATIONS)[keyof typeof ALLOWED_INTEGRATIONS];
    },
    args?: Record<string, string | number>,
    params?: any[]
  ) => {
    if (type === COMMUNICATIONS.SEND_MESSAGE_TO_FRAME) {
      if (this.frameId) {
        const target = document.getElementById(
          this.frameId
        ) as HTMLIFrameElement;

        if (target) {
          target.contentWindow?.postMessage(
            args?.payload ?? {},
            String(args?.path ?? '*')
          );
        }
      }
    } else if (type === COMMUNICATIONS.CALL_THIRD_PARTY_SCRIPT) {
      this.handleCallThirdPartyScript(recipient.type, params);
    } else {
      const pluginApiName =
        this.executionPlugin?.plugin_api_name || this.plugin?.plugin_api_name;
      const event = new CustomEvent(`integration:${type}`, {
        detail: {
          recipient: {
            ...recipient,
            plugin: pluginApiName,
          },
          args,
        },
      });
      window.dispatchEvent(event);
    }
  };

  private handleDone = (preserve: boolean, result?: any) => {
    if (!this.waitForFrame) {
      return this.done(preserve, result);
    }
  };

  private postMessage = (action: string, data: any) => {
    this.worker.postMessage(
      JSON.stringify({
        action,
        ...data,
      })
    );
  };

  private handleSetSessionData = (state: Record<string, any>) => {
    this.setSessionData(state);
  };

  private handleQueryRequest = async (
    id: string,
    method: 'get' | 'post' | 'patch',
    url: string,
    payload: any,
    options?: any
  ) => {
    const data = await AxiosService[method]?.(url, payload, options);
    this.postMessage(RESPONSES.QUERY_RESPONSE, { data, id });
  };

  private handleUIOutput = (markup: string) => {
    if (this.scriptUIRef?.current) {
      this.scriptUIRef.current.innerHTML = markup;
    }
  };

  private onLoad = (payload: {
    iframe: HTMLIFrameElement;
    preserve: boolean;
  }) => {
    if (payload.iframe) {
      this.waitForFrame = false;
      this.handleDone(payload.preserve);
    }
  };

  private handleIframeOutput = (
    url: string,
    allow: Array<string> = [],
    sandbox: Array<string> = [],
    preserve = false
  ) => {
    if (this.scriptUIRef?.current) {
      const parsedAllowList = allow.filter((a) =>
        allowedAllowValues.includes(a)
      );
      const parsedSandboxList = sandbox.filter((s) =>
        allowedSandboxValues.includes(s)
      );
      this.waitForFrame = true;
      const element = document.createElement('iframe');
      element.src = url;
      element.allow = parsedAllowList.join('; ');
      parsedSandboxList.forEach((s) => {
        element.sandbox.add(s);
      });
      element.style.border = 'none';
      element.style.width = '100%';
      element.style.height = '100%';
      element.onload = this.onLoad.bind(this, { iframe: element, preserve });
      if (this.frameId) {
        element.id = this.frameId;
      }
      this.scriptUIRef.current.replaceChildren(element);
    }
  };

  private showToast = (message: string, options: ShowToastOptions = {}) => {
    this.onShowToast?.({
      message,
      variant: options.variant ?? toastVariant.SUCCESS,
      autohide: options.autohide ?? true,
    });
  };

  private clearToasts = () => {
    this.onClearToasts?.();
  };

  private handleRefreshTimeline = (entityId: string) => {
    invalidate.TIMELINE.RECORD(entityId);
  };

  private handleRefreshEntity = (entityId: string) => {
    unsafeRootQueryClient.invalidateQueries({
      predicate: (q) => q.queryKey.includes(entityId),
    });
  };

  private handleFormPostRequest = (
    id: string,
    url: string,
    payload: any,
    createNewTab: boolean
  ) => {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = url;
    if (createNewTab) {
      form.target = '_blank';
    }

    for (const key in payload) {
      const field = document.createElement('input');
      field.type = 'hidden';
      field.name = key;
      field.value = payload[key];
      form.appendChild(field);
    }

    document.body.appendChild(form);

    form.submit();

    if (createNewTab) {
      document.body.removeChild(form);
    }

    this.postMessage(RESPONSES.POSTFORMDATA_RESPONSE, { success: true, id });
  };

  private handleUploadFileRequest = async (id: string, payload: any) => {
    const { file: encodedFile, isPublic = false, fileName } = payload;

    const decodedFile = await fetch(encodedFile);
    const fileBlob = await decodedFile.blob();

    const file = new File(
      [fileBlob],
      fileName ?? `upload-${new Date().toISOString()}`,
      {
        type: fileBlob.type,
      }
    ) as any;
    file.$id = FileService.createId();

    const result = await FileService.upload({
      file,
      id: file.$id,
      publicFile: isPublic,
      source: FIELD_VALUE_FILE_UPLOAD_SOURCE,
      handleProgress: (p: any) => {
        this.postMessage(RESPONSES.UPLOADFILE_PROGRESS, {
          fileId: p.id,
          progress: p.progress,
        });
      },
      businessId: undefined,
    });

    this.postMessage(RESPONSES.UPLOADFILE_RESPONSE, { data: result, id });
  };

  private waitForReadyState = (
    predicate?: () => boolean,
    cb?: (matched: boolean) => void,
    iterations = 0
  ) => {
    if (!predicate) {
      cb?.(true);
    }

    if (predicate?.()) {
      cb?.(true);
    } else if (iterations < 20) {
      setTimeout(() => {
        this.waitForReadyState(predicate, cb, iterations + 1);
      }, iterations * 50);
    } else {
      cb?.(false);
    }
  };

  private handleCallThirdPartyScript = (
    scriptType?: (typeof ALLOWED_INTEGRATIONS)[keyof typeof ALLOWED_INTEGRATIONS],
    args: any[] = []
  ) => {
    if (scriptType && thirdPartyGlobalNames[scriptType]) {
      (window as any)[thirdPartyGlobalNames[scriptType]]?.(...args);
    }
  };

  private handleInstallThirdPartyScriptRequest = async (
    id: string,
    url: string,
    args: Record<string, any> = {}
  ) => {
    const type = getScriptIntegrationType(url);

    if (!type) {
      this.postMessage(RESPONSES.INSTALL_THIRD_PARTY_SCRIPT_RESPONSE, {
        data: { success: false, url },
        id,
      });
      return;
    }

    const setupFn = thirdPartySetupScripts[type];

    if (setupFn) {
      setupFn(args);
    }

    const script = document.createElement('script');
    script.onload = () => {
      this.waitForReadyState(
        thirdPartyReadyPredicates[type],
        (matched: boolean) => {
          this.postMessage(RESPONSES.INSTALL_THIRD_PARTY_SCRIPT_RESPONSE, {
            data: { success: true, url, matched },
            id,
          });
        }
      );
    };
    script.onerror = () => {
      this.postMessage(RESPONSES.INSTALL_THIRD_PARTY_SCRIPT_RESPONSE, {
        data: { success: false, url },
        id,
      });
    };

    script.src = url;
    script.setAttribute('data-script-url', url);

    const exists =
      document.querySelectorAll('[data-script-url="' + url + '"]').length > 0;

    if (!exists) {
      document.documentElement.firstChild?.appendChild(script);
    } else {
      this.waitForReadyState(
        thirdPartyReadyPredicates[type],
        (matched: boolean) => {
          this.postMessage(RESPONSES.INSTALL_THIRD_PARTY_SCRIPT_RESPONSE, {
            data: { success: true, url, reused: true, matched },
            id,
          });
        }
      );
    }
  };

  private handleOpenWindow = (
    url: string,
    target: string,
    features: string
  ) => {
    if (!isRelative(url) || target === '_blank' || !this.history) {
      window.open(url, target, features);
    } else {
      this.history.push(url);
    }
  };

  public run = async (
    scriptBody: string,
    setup: {
      user: any;
      business: any;
      entityId?: string;
      objectId?: string;
      clientObject: any;
      isDebug: boolean;
    },
    args?: string
  ) => {
    this.postMessage(ACTIONS.RUN, {
      script: scriptBody,
      setup,
      args,
      sessionData: this.sessionData,
      pluginComponentId: this.pluginComponentId,
    });
  };
}
