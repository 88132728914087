import { useCallback, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Kizen/Icon';
import KizenTypography from 'app/kizentypo';
import Notice from 'components/Kizen/Notice';
import { ContentWidth } from 'components/Layout/PageContentWidth';
import {
  ButtonsContainer,
  StyledAnchor,
  ActionsIcon,
  ProfileCard as Card,
  ActionsIconContainer,
} from './styles';
import { FlexDiv } from 'pages/Settings/pages/PrivacySettings/IntegratedEmail/styles';

import ProfileTable from './ProfileTable';
import {
  HeaderSizedCell,
  SizedCell,
  TextCell,
  TableTitleWrapper,
  TableBoxesWrapper,
} from './tableStyles';
import SharingSettingsCell from '__components/AccessRequests/Tables/Cells/SharingSettingsCell';

import { StatusCell } from 'pages/Settings/pages/PrivacySettings/IntegratedEmail/styles';
import { IconWithTooltip } from 'pages/Common/components/LinkWithIconTooltip/IconWithTooltip';

import useThirdPartyAuth, {
  PROVIDERS,
} from 'components/Modals/ThirdPartyAuth/useThirdPartyAuth';
import { CardToolbarButton } from 'components/Layout/CardToolbar';
import IntegratedEmailModal from 'components/Modals/ThirdPartyAuth/modal';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import useModal from 'components/Modals/useModal';
import ConfirmDeletionModal from 'components/Modals/presets/ConfirmDeletion';
import { getEmailStatus } from 'pages/Settings/pages/PrivacySettings/IntegratedEmail/helpers';

import { useSharingAccessModal } from 'pages/Settings/pages/PrivacySettings/IntegratedEmail/hooks/useSharingAccessModal.ts';
import { SharingAccessModal } from 'components/Modals/SharingAccessModal';

const StyledCard = styled(Card)`
  max-width: 100%;
`;

export const ProviderTypeCodes = {
  GMAIL: 1,
  OUTLOOK: 2,
};

export const StatusCodeTypes = {
  ERROR_READING_UPDATES: 'error_reading_updates',
  ERROR_DOWNLOADING_HISTORY: 'error_downloading_history',
  NOT_SETUP: 'not_setup',
  SUBSCRIBED_TO_UPDATES: 'subscribed_to_updates',
  DOWNLOADING_HISTORY: 'downloading_history',
};

const badCodes = [
  StatusCodeTypes.ERROR_READING_UPDATES,
  StatusCodeTypes.ERROR_DOWNLOADING_HISTORY,
  StatusCodeTypes.NOT_SETUP,
];

export const isErrorStatusCode = (status) => badCodes.includes(status);

export const getInvalidLabel = (status, label, t) =>
  isErrorStatusCode(status)
    ? { label: `${label} ${t('[Invalid]')}`, error: true }
    : { label, error: false };

const columnSizings = {
  spacer: { width: '20px' },
  email: { width: '140px', padding: '20px' },
  provider: { width: '84px', padding: '20px' },
  email_status: { width: '80px', padding: '20px' },
  sharing_stats: { width: '150px', padding: '20px' },
  actions: { width: '101px', padding: '20px' },
};

const createColumns = (onAccountDelete, t, handleRefresh, showEdit) => {
  const columns = [
    {
      id: 'spacer',
      headCell: <SizedCell {...columnSizings.spacer} />,
      cell: <SizedCell {...columnSizings.spacer} />,
    },
    {
      id: 'email',
      headCell: (
        <HeaderSizedCell label={t('Email Address')} {...columnSizings.email} />
      ),
      cell: <SizedCell />,
      format: (value) => <TextCell>{value}</TextCell>,
    },
    {
      id: 'provider',
      headCell: (
        <HeaderSizedCell label={t('Provider')} {...columnSizings.provider} />
      ),
      cell: <SizedCell />,
      format: (value) => (
        <TextCell>
          {value === PROVIDERS.GOOGLE ? t('Gmail') : t('Outlook')}
        </TextCell>
      ),
    },
    {
      id: 'email_status',
      headCell: (
        <HeaderSizedCell label={t('Status')} {...columnSizings.email_status} />
      ),
      cell: <SizedCell />,
      format: (_, { email_status }) => (
        <StatusCell statusCode={email_status}>
          {getEmailStatus(email_status, t)}
        </StatusCell>
      ),
    },
    {
      id: 'sharing_stats',
      headCell: (
        <HeaderSizedCell
          by="sharing_stats"
          label={t('Sharing Settings')}
          {...columnSizings.sharing_stats}
        />
      ),
      cell: <SizedCell {...columnSizings.sharing_stats} />,
      format: (_x, data) => {
        return (
          <FlexDiv>
            <SharingSettingsCell
              onClickEdit={() => showEdit(data.id)}
              access={data.employee_access}
              data={data}
              hideRoles={['Edit']}
            />
          </FlexDiv>
        );
      },
    },
    {
      id: 'actions',
      headCell: (
        <HeaderSizedCell
          label={t('Actions')}
          {...columnSizings.actions}
          style={{ textAlign: 'right' }}
        />
      ),
      cell: <SizedCell padding="20px" />,
      format: (_, { id, email_status, provider, employee_access, ...rest }) => {
        return (
          <ActionsIconContainer>
            {isErrorStatusCode(email_status) ? (
              <StyledAnchor onClick={() => handleRefresh(provider)}>
                <ActionsIcon>
                  <Icon size="1x" icon="sync" />
                </ActionsIcon>
              </StyledAnchor>
            ) : null}
            <ActionsIcon>
              <Icon
                size="1x"
                icon="delete"
                onClick={() => onAccountDelete(id)}
              />
            </ActionsIcon>
          </ActionsIconContainer>
        );
      },
    },
  ];

  return [...columns];
};

const EmailIntegrationNotice = styled(Notice)`
  margin-top: 5px;
  margin-bottom: 20px;
`;

const EmailIntegration = ({
  businessId,
  externalAccounts = EMPTY_ARRAY,
  onAccountDelete,
}) => {
  const { t } = useTranslation();

  const {
    triggerProps,
    modalProps,
    setProviderReadOnly,
    setProvider,
    openModal,
  } = useThirdPartyAuth({ businessId, useCurrentTeamMember: true });

  const handleRefresh = useCallback(
    (provider) => {
      if (provider === PROVIDERS.GOOGLE) {
        setProvider(PROVIDERS.GOOGLE);
      } else if (provider === PROVIDERS.OUTLOOK) {
        setProvider(PROVIDERS.OUTLOOK);
      }
      setProviderReadOnly(true);
      openModal();
    },
    [setProvider, setProviderReadOnly, openModal]
  );

  const deletingIdRef = useRef(null);

  const [confirmationModalProps, , { show: showConfirmationModal }] = useModal({
    handleSubmit: () => onAccountDelete(deletingIdRef.current),
  });

  const handleDelete = useCallback(
    (id) => {
      showConfirmationModal();
      deletingIdRef.current = id;
    },
    [showConfirmationModal]
  );

  const {
    sharingAccessModalProps,
    currentlyEdititingSharingSettings,
    integratedAccountLoading,
    handleShowEdit,
  } = useSharingAccessModal();

  const columns = useMemo(
    () => createColumns(handleDelete, t, handleRefresh, handleShowEdit),
    [handleDelete, t, handleRefresh, handleShowEdit]
  );

  return (
    <>
      <StyledCard as={ContentWidth}>
        <TableTitleWrapper>
          <FlexDiv gap="5px">
            <KizenTypography as="h3" type="subheader">
              {t('Integrated Inboxes')}
            </KizenTypography>
            <IconWithTooltip
              label={t(
                'You will be able to edit sharing settings only for integrated inboxes you have Admin access to.'
              )}
              placement="top"
              icon="info-circle"
            />
          </FlexDiv>
          <ButtonsContainer>
            <CardToolbarButton {...triggerProps}>
              {`+ ${t('Add Integrated Email')}`}
            </CardToolbarButton>
            <IntegratedEmailModal {...modalProps} />
          </ButtonsContainer>
        </TableTitleWrapper>
        {externalAccounts && !!externalAccounts.length ? (
          <ProfileTable columns={columns} data={externalAccounts} />
        ) : (
          <TableBoxesWrapper>
            <EmailIntegrationNotice>
              {t(
                'You don’t yet have any integrated emails. Please add an integrated email using the buttons above.'
              )}
            </EmailIntegrationNotice>
          </TableBoxesWrapper>
        )}
      </StyledCard>
      <ConfirmDeletionModal {...confirmationModalProps}>
        <KizenTypography>
          {t(
            'This will permanently delete the integrated email and will remove associated emails on the timeline.'
          )}
        </KizenTypography>
      </ConfirmDeletionModal>
      {sharingAccessModalProps.show ? (
        <SharingAccessModal
          {...sharingAccessModalProps}
          existing={currentlyEdititingSharingSettings}
          isEditing={Boolean(currentlyEdititingSharingSettings?.id)}
          loading={integratedAccountLoading}
        />
      ) : null}
    </>
  );
};

export default EmailIntegration;
