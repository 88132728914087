import { useCallback, useMemo, useState } from 'react';
import KizenTypography from 'app/kizentypo';
import Select from 'components/Inputs/Select';
import { useTranslation } from 'react-i18next';

import { useInfiniteQuery } from 'react-query';
import { TOOLBAR_TEMPLATES } from 'queries/query-keys';

import { StyledWizardField } from 'components/Wizards/shared/styles';
import ToolbarService from 'services/ToolbarService';
import { StyledModal } from './styles';
import { getSortValue } from 'utility/SortingHelpers';
import { EMPTY_ARRAY } from 'utility/fieldHelpers';
import { getCanView } from 'components/AccessRequests/utils';

import {
  useMenuList,
  LOAD_THRESHOLD,
} from 'ts-components/filters/hooks/useMenuList';

export const DEFAULT_TEMPLATE_ID = 'default';
const TOP_PADDING = 12;
const PAGE_SIZE = 20;

const useChooseTemplateModal = ({ props }) => {
  const {
    pageSize = PAGE_SIZE,
    serviceToUse,
    fetchProps,
    hasDefaultTemplate,
    reactQueryKey,
  } = props;
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');

  const fetchTemplates = useCallback(
    async ({ page, search = '' }) => {
      return serviceToUse.listTemplates(
        page,
        pageSize,
        getSortValue('name'),
        search,
        ...fetchProps
      );
    },
    [serviceToUse, pageSize, fetchProps]
  );

  const listQueryKey = useMemo(
    () => [...reactQueryKey, { pageSize, search: searchTerm }],
    [searchTerm, pageSize, reactQueryKey]
  );

  const infiniteQuery = useInfiniteQuery({
    queryKey: listQueryKey,
    queryFn: async ({ pageParam = 1 }) => {
      const params = {
        search: searchTerm,
        page: pageParam,
      };
      const data = await fetchTemplates(params);
      return data;
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.next
        ? new URL(lastPage.next).searchParams.get('page')
        : undefined;
    },
    getPreviousPageParam: (previousPage) => {
      return previousPage?.prev
        ? new URL(previousPage.prev).searchParams.get('page')
        : undefined;
    },
    // Disable the built-in refetching mechanism because we don't want unsaved data being errased
    refetchOnWindowFocus: false,
  });

  const templateData = useMemo(() => {
    const options = (infiniteQuery?.data?.pages || [])
      .flatMap((page) => page.results)
      .filter(getCanView)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((template) => ({
        value: template.id,
        label: template.name,
        ...template,
      }));
    const defaultName = t('Default Template');
    if (
      hasDefaultTemplate &&
      (!searchTerm ||
        defaultName.toLowerCase().includes(searchTerm.toLowerCase()))
    ) {
      options.splice(0, 0, {
        value: DEFAULT_TEMPLATE_ID,
        label: defaultName,
      });
    }

    return options;
  }, [hasDefaultTemplate, infiniteQuery?.data?.pages, searchTerm, t]);

  if (
    !infiniteQuery.isFetchingNextPage &&
    infiniteQuery.hasNextPage &&
    !infiniteQuery.isFetching &&
    !infiniteQuery.isError &&
    templateData.length < pageSize
  ) {
    // make sure we fetch the next page if we don't have enough data to show
    infiniteQuery.fetchNextPage();
  }

  return {
    searchTerm,
    setSearchTerm,
    infiniteQuery,
    templateData,
    templateDataIsLoading: infiniteQuery.isLoading,
  };
};

export const ChooseTemplateModal = ({
  onConfirm,
  disabled,
  serviceToUse = ToolbarService,
  fetchProps = EMPTY_ARRAY,
  hasDefaultTemplate = true,
  reactQueryKey = [TOOLBAR_TEMPLATES.LIST],
  ...others
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState();
  const { t } = useTranslation();

  const {
    infiniteQuery,
    templateData,
    templateDataIsLoading,
    searchTerm,
    setSearchTerm,
  } = useChooseTemplateModal({
    props: { serviceToUse, fetchProps, hasDefaultTemplate, reactQueryKey },
  });

  const handleMenuOpen = () => {
    setTimeout(() => setMenuList(ref.current?.select.menuListRef), 50);
  };

  const handleConfirm = useCallback(() => {
    onConfirm?.(selectedTemplate?.value);
  }, [onConfirm, selectedTemplate]);

  const { setMenuList, ref } = useMenuList(
    infiniteQuery.isFetchingNextPage,
    infiniteQuery.fetchNextPage,
    LOAD_THRESHOLD,
    infiniteQuery.hasNextPage
  );

  return (
    <StyledModal
      {...others}
      onConfirm={handleConfirm}
      heading={t('Choose Template to Apply')}
      disabled={!selectedTemplate?.value || disabled}
      leftBtn={false}
      buttonText={t('Apply')}
    >
      <StyledWizardField top={0} flex>
        <KizenTypography>{t('Template Name')}</KizenTypography>
        <StyledWizardField top={TOP_PADDING} flex>
          <Select
            ref={ref}
            value={selectedTemplate}
            onChange={setSelectedTemplate}
            placeholder={t('Choose Template')}
            onMenuOpen={handleMenuOpen}
            options={templateData}
            onInputChange={setSearchTerm}
            inputValue={searchTerm}
            isLoading={templateDataIsLoading}
            inModal
            menuInline
            fullWidth
          />
        </StyledWizardField>
      </StyledWizardField>
    </StyledModal>
  );
};
