import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Panel } from '@kizen/kds/Tile';
import DashletError from 'pages/Dashboard/components/DashletError';
import { useTranslation } from 'react-i18next';
import { toastVariant, useToast } from 'components/ToastProvider';
import {
  updatePageConfig,
  getCustomObjectConfig,
} from 'store/recordDetailPage/record.redux';

import FieldService from 'services/FieldService';

import useAsync from 'react-use/lib/useAsync';
import UUID from 'utility/UUID';

import KizenTypography from 'app/kizentypo';
import { useBreakpoint } from 'app/spacing';
import { PageSearchInput } from 'components/Layout/PageToolbar';
import useCustomObjectDetails from 'components/Wizards/shared/hooks/useCustomObjectDetails';

import { head } from 'utility/snippets';

import { Table } from './Table';
import { TableCard } from './TableCard';
import { RelatedObjectButtonSelector } from './RelatedObjectButtonSelector';
import { HiddenWidth } from './HiddenWidth';
import { RelatedMenu } from './Menu';

import { Header, RelatedObjectSelect, TableWrapper } from './styles';

import { getCustomRecordsColumns } from './custom/columns';
import { getContactRecordsColumns } from './contact/columns';

import { useErrorByKey } from 'hooks/useErrors';
import { useListRelatedObjects } from 'queries/models/useListRelatedObjects';
import { useWindowSize } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';

const DEFAULT_TABLE_HEIGHT = 294; // 5 rows plus header
const OBJECT_SELECTOR_WIDTH_THRESOLD = 250;

const isContactObject = (fetchUrl) => fetchUrl === 'client';

const defaultRelatedObjects = {
  filterLookup: null,
  relatedObjectOptions: [],
  models: [],
};

const toOptions = ({ id, displayName, icon, color, name, objectType }) => {
  return {
    value: id,
    label: displayName,
    icon,
    color,
    name,
    objectType,
  };
};

const getCustom = async (id, objectType, uniqueId) => {
  const [model, fields] = await Promise.all([
    FieldService.getModel({ id }, { skipErrorBoundary: true }),
    FieldService.getFields({
      for: { id, objectType, objectClass: 'custom_objects' },
      skipErrorBoundary: true,
    }),
  ]);
  return { model: { ...model, uniqueId }, fields };
};

const getClient = async (id, uniqueId) => {
  const [model, fields] = await Promise.all([
    FieldService.getModel({ id }, { skipErrorBoundary: true }),
    FieldService.getFields({
      for: 'contacts',
      skipErrorBoundary: true,
    }),
  ]);
  return { model: { ...model, uniqueId }, fields };
};

const getFilterLookup = async (
  objectModel,
  objects,
  entityId,
  meta,
  modelIdLookup
) => {
  const objectId = objectModel.id;
  const modelAndFieldRequests = [];

  // look up of relations that are active on the current object
  const currentRelations = (objectModel.relatedObjects || []).reduce(
    (accumulatedRelations, { relatedObject }) => ({
      ...accumulatedRelations,
      [relatedObject]: true,
    }),
    {}
  );

  for (const { id, objectType, fetchUrl } of objects) {
    const modelId = modelIdLookup(id);

    if (isContactObject(fetchUrl)) {
      modelAndFieldRequests.push(
        getClient(modelId, id).then(
          (result) => ({ success: true, result }),
          (error) => ({ success: false, error })
        )
      );
    } else {
      modelAndFieldRequests.push(
        getCustom(modelId, objectType, id).then(
          (result) => ({ success: true, result }),
          (error) => ({ success: false, error })
        )
      );
    }
  }

  const modelsWithStatus = await Promise.all(modelAndFieldRequests);

  // Filter out failed requests
  const successfulModels = modelsWithStatus
    .filter(({ success }) => success)
    .map(({ result }) => result);

  const filterLookup = successfulModels.reduce(
    (accumulated, { model = {}, fields = {} } = {}) => {
      const { id, relatedObjects = [], isCustom, uniqueId } = model ?? {};
      const relationshipFields = meta?.relationshipFields?.[uniqueId] ?? [];

      const filters = relatedObjects
        // filter out any relations that are not active on the current object
        // or are not in the list of included relation to display
        // See apps/react-app/src/components/Wizards/CustomObject/steps/CustomLayout/dialogs/LayoutComponentWizard/subsections/RelatedObjectFields/Fields.js:143
        .filter(({ fieldId }) =>
          relationshipFields.length
            ? relationshipFields.some(
                ({ relation }) => fieldId === relation?.relatedField
              )
            : true
        )
        .reduce((accumulatedfilters, { relatedObject = {}, fieldId } = {}) => {
          if (relatedObject === objectId) {
            accumulatedfilters = [
              ...accumulatedfilters,
              {
                type: 'fields_v2',
                subtype: 'custom',
                value: [entityId],
                field: `custom::${fieldId}`,
                condition: 'contains',
              },
            ];
          }

          return accumulatedfilters;
        }, []);

      const fieldIds = (meta?.fields?.[uniqueId] ?? meta?.fields?.[id] ?? [])
        ?.filter(({ id }) => UUID.validate(id))
        .map(({ id }) => id);

      return {
        ...accumulated,
        [uniqueId]: {
          filters,
          isCustom,
          model,
          fields,
          activeRealtionship: currentRelations[id] || false,
          fieldIds,
        },
      };
    },
    {}
  );

  return { models: successfulModels, filterLookup };
};

const resetProps = {
  page: 1,
  search: '',
};

// look up names for default fields
const fixupNameForDefaultFields = (filterLookup, key, id, modelIdLookup) => {
  if (!filterLookup) {
    return id;
  }
  const field = filterLookup[key]?.fields.find((f) => f.id === id);

  return field ? (field.isDefault ? field.name : id) : id;
};

const useRelatedObjectFields = ({
  objectModel,
  entityId,
  t,
  meta,
  modelIdLookup,
}) => {
  const [showToast] = useToast();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    search: '',
    relatedObject: null,
    relatedObjectModel: null,
    relatedObjectFields: null,
    relatedObjectFieldIds: [],
    page: 1,
    pageSize: 100, // relationship sorting query is expensive so we want to limit the amount of calls.
  });

  const {
    search,
    relatedObject,
    pageSize,
    relatedObjectModel,
    relatedObjectFields,
    relatedObjectFieldIds,
  } = state;

  const {
    objects = null,
    fields,
    collapse,
    tableHeight = DEFAULT_TABLE_HEIGHT,
  } = meta;

  const objectAccess = useSelector((s) => {
    return (
      s.authentication.access?.custom_objects?.custom_object_entities ?? {}
    );
  });
  const { detailPageConfig = {} } = useSelector(
    getCustomObjectConfig(objectModel.id)
  );
  // filter out any objects we don't have access to
  const { relatedObjects, fieldAccess } = useMemo(() => {
    const filteredObjects = (objects ?? []).filter((o) => {
      const modelId = modelIdLookup(o.id);
      return objectAccess[modelId]?.enabled || isContactObject(o.fetchUrl);
    });
    const fieldAccess = filteredObjects.reduce(
      (acc, { id }) => ({ ...acc, [id]: { enabled: true } }),
      {}
    );
    return { relatedObjects: filteredObjects, fieldAccess };
  }, [objects, objectAccess, modelIdLookup]);

  const selectedRelatedObject = useMemo(
    () => relatedObjects.find(({ id }) => relatedObject?.value === id),
    [relatedObjects, relatedObject]
  );

  // use this to calculate the filter options and load the filterloook up
  const {
    value: {
      filterLookup,
      relatedObjectOptions,
      models,
    } = defaultRelatedObjects,
    loading: modelsLoading,
  } = useAsync(async () => {
    if (relatedObjects && objectModel.id && entityId) {
      try {
        const { filterLookup, models } = await getFilterLookup(
          objectModel,
          relatedObjects,
          entityId,
          meta,
          modelIdLookup
        );

        const availableModelsById = models?.reduce((acc, { model } = {}) => {
          acc[model.id] = true;
          return acc;
        }, {});

        const options = relatedObjects
          .filter(({ originalId }) => {
            return availableModelsById[originalId];
          })
          .map(toOptions);

        if (options.length != relatedObjects.length) {
          showToast({
            message: `${t('There was an error loading some of the Related Objects.')}`,
            variant: toastVariant.FAILURE,
          });
        }

        return { filterLookup, relatedObjectOptions: options, models };
      } catch (error) {
        if (error?.response?.status !== 403) {
          showToast({
            message: `${t('There was an error loading some of the Related Objects.')}`,
            variant: toastVariant.FAILURE,
          });
          return { filterLookup: {}, options: [], models: {} };
        }
      }
    }

    return defaultRelatedObjects;
  }, [relatedObjects, objectModel.id, entityId]);

  const { data: relatedObjectDetails, isLoading: relatedObjectDetailsLoading } =
    useCustomObjectDetails({
      objectId: relatedObjectModel?.id,
      enabled: true,
    });

  // used to filter out deleted fields or fields we don't have access to
  const existingRelationFieldsById = useMemo(
    () =>
      relatedObjectDetails?.fields?.reduce((acc, field) => {
        if (field.name === 'first_name') {
          acc['firstName'] = field;
        } else if (field.name === 'last_name') {
          acc['lastName'] = field;
        }
        return { ...acc, [field.id]: field };
      }, {}) ?? null,
    [relatedObjectDetails]
  );

  // we want to get rid of object fields we don't have access to also
  const relatedObjectsColumnSettings = useMemo(
    () =>
      Object.entries(fields).reduce((acc, [key, fields]) => {
        return fieldAccess[key]?.enabled
          ? {
              ...acc,
              [key]: fields.map(
                (
                  { id, displayName, label, width, enableLink = true },
                  order
                ) => ({
                  order,
                  id: fixupNameForDefaultFields(filterLookup, key, id),
                  label: label || displayName,
                  width,
                  enableLink,
                })
              ),
            }
          : acc;
      }, {}),
    [filterLookup, fields, fieldAccess]
  );

  // SET STATE
  //====================================================
  const setSearch = useCallback((value) => {
    setState((lastState) => ({
      ...lastState,
      search: value,
    }));
  }, []);

  // used to remember on reload
  const relatedObjectRef = useRef(relatedObject);

  // once the options have loaded this is safe
  const setRelatedObject = useCallback(
    (relatedObject) => {
      if (relatedObject && filterLookup) {
        relatedObjectRef.current = relatedObject;
        const lookupId = relatedObject.value;

        const relatedObjectModel = filterLookup[lookupId].model;
        const relatedObjectFields = filterLookup[lookupId].fields;
        const relatedObjectFieldIds = filterLookup[lookupId].fieldIds;

        setState((lastState) => ({
          ...lastState,
          relatedObject,
          relatedObjectModel,
          relatedObjectFields,
          relatedObjectFieldIds,
          ...resetProps,
        }));
      }
    },
    [filterLookup]
  );

  const setOrdering = useCallback(
    (updatedOrdering) => {
      const blockDetails = detailPageConfig?.[entityId] ?? {};
      dispatch(
        updatePageConfig({
          customObjectId: objectModel.id,
          blockId: entityId,
          detailPageConfig: {
            ...detailPageConfig,
            [entityId]: {
              ...blockDetails,
              ordering: {
                ...blockDetails.ordering,
                [relatedObject?.value]: updatedOrdering,
              },
            },
          },
        })
      );
    },
    [entityId, relatedObject, objectModel, detailPageConfig, dispatch]
  );

  // handle setting default related option once the models have loaded
  useEffect(() => {
    if (models && relatedObjectOptions) {
      // default options to first in the list
      if (relatedObjectRef.current) {
        setRelatedObject(relatedObjectRef.current);
      } else {
        setRelatedObject(head(relatedObjectOptions));
      }
    }
  }, [models, relatedObjectOptions, setRelatedObject]);

  // once everything is fetched figure out the columns
  const columns = useMemo(() => {
    if (
      relatedObjectModel &&
      relatedObjectFields &&
      relatedObjectsColumnSettings &&
      relatedObject
    ) {
      return relatedObjectModel.isCustom
        ? getCustomRecordsColumns({
            model: relatedObjectModel,
            fields: relatedObjectFields,
            columnSettings:
              relatedObjectsColumnSettings[relatedObject?.value] || [],
            onSelectAction: () => {},
            onSubmitRecord: () => {},
            handleUpdateFieldOption: () => {},
            handleUpdateTableRecords: () => {},
            preReleaseFeatures: true,
            t,
          })
        : getContactRecordsColumns({
            model: relatedObjectModel,
            fields: relatedObjectFields,
            columnSettings:
              relatedObjectsColumnSettings[relatedObject?.value] || [],
            onSubmitContact: () => {},
            categorizedFields: [],
            handleUpdateFieldOption: () => {},
            handleUpdateTableRecords: () => {},
            serviceToUse: FieldService,
            t,
          });
    }
    return [];
  }, [
    relatedObjectModel,
    relatedObjectFields,
    relatedObjectsColumnSettings,
    relatedObject,
    t,
  ]);

  const filters = useMemo(() => {
    return meta?.filters?.[relatedObject?.value];
  }, [meta, relatedObject]);

  const ordering =
    detailPageConfig[entityId]?.ordering?.[relatedObject?.value] ||
    (isContactObject(selectedRelatedObject?.fetchUrl) ? 'full_name' : 'name');

  return {
    search,
    setSearch,
    relatedObject,
    relatedObjectModel,
    relatedObjectFields,
    relatedObjectFieldIds,
    existingRelationFieldsById,
    relatedObjectDetailsLoading,
    setRelatedObject,
    relatedObjectOptions,
    relatedObjectsColumnSettings,
    models,
    filterLookup,
    filters,
    modelsLoading,
    columns,
    ordering,
    pageSize,
    setOrdering,
    collapse,
    tableHeight,
  };
};

export const RelatedObjectFields = ({
  objectModel,
  entityId,
  meta,
  controller,
  frameless,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const objectSelectorRef = useRef();
  const refWidthFinder = useRef();
  const [buttonWidth, setButtonWidth] = useState(0);
  const isMobile = useBreakpoint();

  const modelIdLookup = useCallback(
    (key) => (meta?.idDictionary ? meta.idDictionary[key] : key),
    [meta.idDictionary]
  );

  const [collapseOveride, setCollapseOveride] = useState(false);
  const {
    search,
    setSearch,
    relatedObject,
    setRelatedObject,
    relatedObjectOptions,
    existingRelationFieldsById,
    relatedObjectDetailsLoading,
    modelsLoading,
    filterLookup,
    filters,
    columns,
    setOrdering,
    ordering,
    pageSize,
    tableHeight = DEFAULT_TABLE_HEIGHT,
  } = useRelatedObjectFields({
    objectModel,
    entityId,
    meta,
    t,
    modelIdLookup,
  });

  const relatedObjectFields = isMobile
    ? []
    : meta?.fields?.[relatedObject?.value];

  const searchWithinFieldIds = relatedObjectFields
    ?.filter(
      ({ id }) => Boolean(existingRelationFieldsById?.[id]) || id === 'fullName'
    )
    ?.reduce((acc, { id }) => {
      if (id === 'fullName') {
        const firstName = existingRelationFieldsById?.firstName;
        const lastName = existingRelationFieldsById?.lastName;
        if (firstName && lastName) {
          acc.push(firstName.id, lastName.id);
        }
        return acc;
      }
      acc.push(id);
      return acc;
    }, []);

  const relationshipFields =
    meta.relationshipFields[relatedObject?.value] ?? [];

  const missingAllRelationshipFields =
    relationshipFields.length > 0 &&
    relationshipFields.every(
      ({ relation }) => !existingRelationFieldsById[relation?.relatedField]
    );

  const infiniteQuery = useListRelatedObjects(
    objectModel,
    entityId,
    relatedObject,
    filterLookup,
    search,
    filters,
    ordering,
    pageSize,
    searchWithinFieldIds,
    !relatedObjectDetailsLoading
  );

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    status,
    isLoading,
    error,
  } = infiniteQuery;

  const filterErrors = useErrorByKey(error, 'filters');

  const forceLoading = Boolean(data?.pages?.[0]?.initial);

  if (
    refWidthFinder?.current?.scrollWidth &&
    refWidthFinder?.current?.scrollWidth !== buttonWidth
  ) {
    setButtonWidth(refWidthFinder?.current?.scrollWidth);
  }

  useEffect(() => {
    setCollapseOveride(() =>
      objectSelectorRef?.current &&
      objectSelectorRef?.current?.scrollWidth < OBJECT_SELECTOR_WIDTH_THRESOLD
        ? true
        : relatedObjectOptions?.length > 4 &&
          buttonWidth > objectSelectorRef?.current?.scrollWidth
    );
  }, [relatedObjectOptions, width, buttonWidth]);

  const controllerElement = useMemo(() => {
    return controller?.({
      options: relatedObjectOptions,
      value: relatedObject,
      onChange: setRelatedObject,
      search,
      setSearch,
      loading: isFetchingNextPage || isFetching,
    });
  }, [
    controller,
    relatedObjectOptions,
    relatedObject,
    setRelatedObject,
    search,
    setSearch,
    isFetchingNextPage,
    isFetching,
  ]);

  const errorMessage = useMemo(() => {
    if (isLoading) {
      return false;
    } else if (filterErrors?.length > 0) {
      return t(
        'This tab contains an invalid filter. Please contact your administrator.'
      );
    } else if (missingAllRelationshipFields) {
      return t(
        'This tab contains a configuration error. Please contact your administrator.'
      );
    }
    return false;
  }, [filterErrors, missingAllRelationshipFields, isLoading, t]);

  const blockError = errorMessage ? (
    <TableWrapper frameless={frameless}>
      <Panel padding={[0, 20, 0, 20]}>
        <DashletError override={errorMessage} center />
      </Panel>
    </TableWrapper>
  ) : null;

  const header = controllerElement ? (
    controllerElement
  ) : (
    <>
      <HiddenWidth ref={refWidthFinder} showContent={buttonWidth === 0}>
        <RelatedObjectButtonSelector
          options={relatedObjectOptions}
          value={relatedObject}
          onChange={setRelatedObject}
          noOptionsMessage={t('No Related Objects')}
        />
      </HiddenWidth>
      <Header>
        <div className="rof--related">
          <KizenTypography as="h4" type="subheader">
            {t('Related')}
          </KizenTypography>
        </div>
        <div
          className={`rof--object-selector${
            !collapseOveride ? ' no-overflow' : ''
          }`}
          ref={objectSelectorRef}
        >
          {collapseOveride ? (
            <RelatedObjectSelect
              label={null}
              placeholder={t('Choose Object')}
              options={relatedObjectOptions}
              value={relatedObject}
              onChange={setRelatedObject}
              noOptionsMessage={t('No Related Objects')}
              components={{ Menu: RelatedMenu }}
              dataQa="related-object-select"
            />
          ) : (
            <RelatedObjectButtonSelector
              options={relatedObjectOptions}
              value={relatedObject}
              onChange={setRelatedObject}
              noOptionsMessage={t('No Related Objects')}
            />
          )}
        </div>
        <div className="rof--search">
          <PageSearchInput
            placeholder={t('Search')}
            value={search}
            onChange={setSearch}
            loading={isFetchingNextPage || isFetching}
          />
        </div>
      </Header>
    </>
  );

  const hasNoPermission = !modelsLoading && forceLoading && !relatedObject;
  const isInitialFetching = isFetching && !isFetchingNextPage;
  const additonalLoadingState =
    (modelsLoading ||
      isLoading ||
      forceLoading ||
      isInitialFetching ||
      relatedObjectDetailsLoading) &&
    !hasNoPermission;

  const body = (
    <>
      {header}
      {blockError ? (
        blockError
      ) : (
        <TableWrapper frameless={frameless}>
          <Table
            key={`${relatedObject?.value}`}
            columns={columns}
            ordering={ordering}
            setOrdering={setOrdering}
            dataForTheTable={data}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            status={status}
            isFetching={isFetching}
            tableHeight={tableHeight}
            frameless={frameless}
            loading={additonalLoadingState}
            centerLoader
          />
        </TableWrapper>
      )}
    </>
  );

  return frameless ? (
    body
  ) : (
    <TableCard tableHeight={tableHeight}>{body}</TableCard>
  );
};
