import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';

import Icon, { IconSizing } from 'components/Kizen/Icon';
import { gutters } from 'app/spacing';
import { ElementInputs } from './ElementInputs';
import { Element } from './Element';
import { LabelWithTooltip } from 'components/ToolTipHelpers/LabelWithToolTip';
import { BuilderTargetField } from './BuilderTargetField';

import { SIMPLE_SUB_TYPES, SIMPLE_BUILDER_OPERATION_TYPES } from '../constants';

import { ELEMENT_WIDTH, scrollOptions } from './constants';
import { BuilderAddElementDropdown } from './BuilderAddElementDropdown';

import { ELEMENT_HEIGHT } from './constants';

import {
  IconToggleButton,
  ICON_TOGGLE_BUTTON_SIZINGS,
  useIconToggleButton,
  COLORS_ICON_TOGGLE_BUTTON,
} from 'components/Kizen/ToggleButton/presets/IconToggleButton';

import { useScrollIntoView } from 'hooks/use-scroll-into-view';

const AdditionSubtractionContainer = styled.div`
  margin-top: ${gutters.spacing(4, -2)}px;
  display: grid;
  column-gap: ${gutters.spacing(4)}px;
  grid-template-columns: ${ELEMENT_WIDTH + 10}px 25px auto;
  grid-template-rows: 25px auto;

  > .equals {
    height: ${ELEMENT_HEIGHT}px;
    align-self: start;
    padding-top: calc((${ELEMENT_HEIGHT}px - 25px) / 2);
  }
  > .elements {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: ${gutters.spacing(3)}px ${gutters.spacing(2)}px;
  }
`;

const ElementToggleButton = styled(IconToggleButton)`
  margin-right: 8px;
  margin-top 4px;

`;

const toggleOptions = [
  {
    value: SIMPLE_BUILDER_OPERATION_TYPES.add.value,
    icon: 'plus-light',
  },
  {
    value: SIMPLE_BUILDER_OPERATION_TYPES.sub.value,
    icon: 'minus-light',
  },
];
const ElementPrefix = ({ element, onSetOperator, getViewOptions }) => {
  return (
    <ElementToggleButton
      data-qa={'element-toggle'}
      options={getViewOptions(element.operationType)}
      value={element.operationType}
      onChange={onSetOperator}
      color="blue"
      sizing={ICON_TOGGLE_BUTTON_SIZINGS.compact}
    />
  );
};

const elementFocus = (ref) => {
  if (ref.current) {
    setTimeout(() => {
      ref.current?.scrollIntoView?.(scrollOptions);
    }, 0);
  }
};

export const AddSubtractBuilder = ({
  data,
  onUpdateState,
  fieldOptions,
  addFieldOptions,
  label,
  tooltipText,
  clickNotify,
}) => {
  const { t } = useTranslation();
  const { getViewOptions } = useIconToggleButton(
    toggleOptions,
    COLORS_ICON_TOGGLE_BUTTON.blue,
    false
  );

  const divAnchorRef = useRef();
  const { scrollNext, componentRef } = useScrollIntoView(scrollOptions);
  return (
    <AdditionSubtractionContainer>
      <div>
        <LabelWithTooltip label={label} tooltipText={tooltipText} />
      </div>
      <div></div>
      <div></div>
      <div
        className="builder-target-field"
        data-qa="builder-target-field"
        ref={componentRef}
        onClick={scrollNext}
      >
        <BuilderTargetField
          data={data}
          fieldOptions={fieldOptions}
          onUpdateState={onUpdateState}
        />
      </div>
      <div className="equals">
        <IconSizing size="25px">
          <Icon icon="equals" />
        </IconSizing>
      </div>
      <div className="elements">
        {(data?.elements || []).map((element, index) => {
          const prefix = ElementPrefix({
            element,
            onSetOperator: ({ value }) =>
              onUpdateState({
                subType: SIMPLE_SUB_TYPES.setOperator,
                elementId: element.id,
                value,
              }),
            getViewOptions,
          });

          return (
            <Element
              index={index}
              isFirst={index === 0}
              element={element}
              key={element.id}
              onDeleteElement={() =>
                onUpdateState({
                  subType: SIMPLE_SUB_TYPES.deleteElement,
                  elementId: element.id,
                })
              }
              prefix={prefix}
            >
              <ElementInputs
                element={element}
                onUpdateElement={(value) => {
                  onUpdateState({
                    subType: SIMPLE_SUB_TYPES.updateElement,
                    elementId: element.id,
                    value,
                  });
                }}
                fieldOptions={fieldOptions}
              />
            </Element>
          );
        })}

        <BuilderAddElementDropdown
          variant="text"
          options={addFieldOptions}
          clickNotify={() => elementFocus(divAnchorRef)}
          onChange={(value) => {
            onUpdateState({
              subType: SIMPLE_SUB_TYPES.addElement,
              value: value,
            });
          }}
        >
          {t('+ Add Element')}
        </BuilderAddElementDropdown>
        <div className="divAnchorRef" ref={divAnchorRef} />
      </div>
    </AdditionSubtractionContainer>
  );
};
