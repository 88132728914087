import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { gutters } from 'app/spacing';
import styled from '@emotion/styled';

import { grayScale } from 'app/colors';
import { fontSizes } from 'app/typography';

import Icon, { IconSizing } from 'components/Kizen/Icon';

import { ElementInputs } from './ElementInputs';
import { Element } from './Element';
import { LabelWithTooltip } from 'components/ToolTipHelpers/LabelWithToolTip';
import { BuilderTargetField } from './BuilderTargetField';
import {
  CenterVerticalElement,
  CenterVerticalElementMargin,
} from './CenterVerticalElement';

import { SIMPLE_SUB_TYPES } from '../constants';
import { ELEMENT_WIDTH, scrollOptions } from './constants';
import { BuilderAddElementDropdown } from './BuilderAddElementDropdown';

import { useScrollIntoView } from 'hooks/use-scroll-into-view';

const MultDivideContainer = styled.div`
  margin-top: ${gutters.spacing(4, -2)}px;
  display: grid;
  column-gap: ${gutters.spacing(4)}px;
  grid-template-columns: ${ELEMENT_WIDTH + 10}px 25px auto;
  grid-template-rows: auto 41px auto;
  > .target-field {
    align-self: center;
  }
  > .equals {
    align-self: center;
  }
  > .divider {
    align-self: center;
    background-color: green;
  }
  > .numerator-elements {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    gap: ${gutters.spacing(3)}px ${gutters.spacing(4)}px;
  }
  > .denominator-elements {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: ${gutters.spacing(3)}px ${gutters.spacing(4)}px;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${grayScale.dark};
`;

const One = styled.div`
  font-size: 32px;
  max-width: 8px;
`;

const ElementPrefix = () => (
  <CenterVerticalElement margin={CenterVerticalElementMargin.large}>
    <IconSizing size="15px">
      <Icon color={grayScale.dark} icon="close-cross" />
    </IconSizing>
  </CenterVerticalElement>
);

const elementFocus = (ref) => {
  if (ref.current) {
    setTimeout(() => {
      ref.current?.scrollIntoView?.(scrollOptions);
    }, 0);
  }
};

export const MultDivideBuilder = ({
  data,
  onUpdateState,
  fieldOptions,
  addFieldOptions,
  label,
  tooltipText,
  clickNotify,
}) => {
  const { t } = useTranslation();
  const multPrefix = ElementPrefix();
  const divAnchorRef = useRef();
  const numAnchorRef = useRef();
  const { scrollNext, componentRef } = useScrollIntoView(scrollOptions);

  return (
    <MultDivideContainer>
      <div>
        <LabelWithTooltip label={label} tooltipText={tooltipText} />
      </div>
      <div></div>
      <div className="numerator-elements">
        <CenterVerticalElement>
          <One type={fontSizes.hero}>1</One>
        </CenterVerticalElement>
        <CenterVerticalElement margin={CenterVerticalElementMargin.small}>
          <IconSizing size="15px">
            <Icon color={grayScale.dark} icon="close-cross" />
          </IconSizing>
        </CenterVerticalElement>
        {(data?.numeratorElements || []).map((element, index) => {
          return (
            <Element
              isFirst={index === 0}
              index={index}
              element={element}
              key={element.id}
              prefix={multPrefix}
              onDeleteElement={() =>
                onUpdateState({
                  subType: SIMPLE_SUB_TYPES.deleteNumerator,
                  elementId: element.id,
                })
              }
            >
              <ElementInputs
                element={element}
                onUpdateElement={(value) => {
                  onUpdateState({
                    subType: SIMPLE_SUB_TYPES.updateNumerator,
                    elementId: element.id,
                    value,
                  });
                }}
                fieldOptions={fieldOptions}
              />
            </Element>
          );
        })}
        <BuilderAddElementDropdown
          variant="text"
          options={addFieldOptions}
          clickNotify={() => elementFocus(numAnchorRef)}
          onChange={(value) => {
            onUpdateState({
              subType: SIMPLE_SUB_TYPES.addNumerator,
              value: value,
            });
          }}
        >
          {t('+ Add Element')}
        </BuilderAddElementDropdown>
        <div className="numAnchorRef" ref={numAnchorRef} />
      </div>
      <div
        className="builder-target-field"
        data-qa="builder-target-field"
        ref={componentRef}
        onClick={scrollNext}
      >
        <BuilderTargetField
          data={data}
          fieldOptions={fieldOptions}
          onUpdateState={onUpdateState}
        />
      </div>
      <div className="equals">
        <IconSizing size="25px">
          <Icon icon="equals" />
        </IconSizing>
      </div>
      <div className="divider">
        <Divider />
      </div>
      <div></div>
      <div></div>
      <div className="denominator-elements">
        <CenterVerticalElement>
          <One type={fontSizes.hero}>1</One>
        </CenterVerticalElement>
        <CenterVerticalElement>
          <IconSizing size="15px">
            <Icon color={grayScale.dark} icon="close-cross" />
          </IconSizing>
        </CenterVerticalElement>
        {(data?.denominatorElements || []).map((element, index) => {
          return (
            <Element
              index={index}
              isFirst={index === 0}
              element={element}
              key={element.id}
              prefix={multPrefix}
              onDeleteElement={() =>
                onUpdateState({
                  subType: SIMPLE_SUB_TYPES.deleteDenominator,
                  elementId: element.id,
                })
              }
            >
              <ElementInputs
                element={element}
                onUpdateElement={(value) => {
                  onUpdateState({
                    subType: SIMPLE_SUB_TYPES.updateDenominator,
                    elementId: element.id,
                    value,
                  });
                }}
                fieldOptions={fieldOptions}
              />
            </Element>
          );
        })}
        <BuilderAddElementDropdown
          variant="text"
          options={addFieldOptions}
          clickNotify={() => elementFocus(divAnchorRef)}
          onChange={(value) => {
            onUpdateState({
              subType: SIMPLE_SUB_TYPES.addDenominator,
              value: value,
            });
          }}
        >
          {t('+ Add Element')}
        </BuilderAddElementDropdown>
        <div className="divAnchorRef" ref={divAnchorRef} />
      </div>
    </MultDivideContainer>
  );
};
