import {
  specificTeamMemberToOptionDeleted,
  emailToOptionDeleted,
} from 'services/helpers';
import {
  RECEIVING_INBOX,
  checkDeleted,
  displayNameDescriptor,
} from 'pages/AutomationEngine/helpers';
import stepConfig from 'pages/AutomationEngine/steps';

const forApi = (orgDetails) => {
  const { receivingInbox, selectedIntegratedInboxes, ...others } = orgDetails;

  const stepGoal = {
    ...others,
    triggers: [
      {
        type: stepConfig.emailReceived.type,
        triggerEmailReceivedFromContact: {
          receivingInbox,

          ...(selectedIntegratedInboxes && {
            selectedIntegratedInboxes: selectedIntegratedInboxes.map(
              (inbox) => inbox.value
            ),
          }),
        },
      },
    ],
  };

  delete orgDetails.inboxCriteria;

  delete orgDetails.specificTeamMember;

  delete orgDetails.type;
  delete orgDetails.field;
  return {
    stepGoal,
    details: orgDetails,
  };
};

const forFlow = (firstTrigger, addErrorMessage, messageDictionary) => {
  const { receivingInbox, specificTeamMember, selectedIntegratedInboxes } =
    firstTrigger.triggerEmailReceivedFromContact;

  const flow = {
    type: stepConfig.emailReceivedGoal.type,
    receivingInbox,
  };

  if (selectedIntegratedInboxes) {
    flow.selectedIntegratedInboxes = selectedIntegratedInboxes.map((inbox) =>
      emailToOptionDeleted(inbox)
    );

    if (
      flow.selectedIntegratedInboxes.length === 0 &&
      receivingInbox === RECEIVING_INBOX.selectedIntegratedInboxes
    ) {
      addErrorMessage(
        messageDictionary.thereWasanErrorLoadingTheAssociatedInbox
      );
    }
  }

  if (specificTeamMember) {
    checkDeleted(
      messageDictionary,
      addErrorMessage,
      specificTeamMember,
      displayNameDescriptor
    );

    flow.specificTeamMember =
      specificTeamMemberToOptionDeleted(specificTeamMember);
  }

  return flow;
};

export const emailReceivedGoalDTO = {
  forApi,
  forFlow,
};
