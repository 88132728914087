import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'components/Card/Card';
import PermissionSettings from 'components/Sharing/PermissionSettings';
import { useActivitySharingContext } from 'ts-activities/ActivitySharingContext';
import { camelToSnakeCaseKeys } from 'services/helpers';

const BigCard = styled(Card)`
  padding: 19px 29px;
  margin: 15px auto 20px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  > div > div {
    padding-top: 19px;
  }
  > div:last-of-type {
    > div:first-of-type {
      padding-top: 0;
    }
    flex: 1;
  }
`;
const TeamSharingSettings = () => {
  const { activity, setSharingSettings, isLoading } =
    useActivitySharingContext();

  const { t } = useTranslation();

  const existing = useMemo(() => {
    return camelToSnakeCaseKeys(activity?.loggableSharingSettings);
  }, [activity]);

  return (
    <BigCard>
      <PermissionSettings
        title={t('Scheduling/Logging Sharing Settings')}
        infoBubble={t(
          'The sharing level granted to a team member will dictate what actions the team member is allowed to take with this activity type, as long as it abides by their permission group settings.'
        )}
        labels={{
          all: {
            view: t('LOG/SCHEDULE'),
            edit: t('Edit Scheduled'),
            admin: t('ADMIN/DELETE SCHEDULED'),
          },
          specific: {
            view: t('Log/Schedule'),
            edit: t('Edit Scheduled'),
            admin: t('Admin/Delete Scheduled'),
          },
        }}
        onChange={setSharingSettings}
        existing={existing}
        loading={isLoading}
        owner={null}
        isDuplication={false}
      />
    </BigCard>
  );
};
export default TeamSharingSettings;
