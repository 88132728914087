import { createAction, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { isFilterSet, isFilterSetArray } from '@kizen/filters/filter-sets';
import { v4 as uuidv4 } from 'uuid';
import { setOpenMenuAbove } from 'components/Tables/helpers';
import { doFilterSetsHaveError } from 'ts-filters/utils';
import { initialStore } from '../initialStore';

type Payload<T> = { payload: T };

export type Automation = {
  active: boolean;
  apiName: string;
  created: string;
  createdBy: {
    displayName: string;
    id: string;
  };
  customObject: {
    defaultOnActivities: boolean;
    deleted: boolean;
    description: string;
    entityName: string;
    id: string;
    name: string;
    objectClass: string;
    objectName: string;
    objectType: string;
  };
  folder: {
    id: string;
    name: string;
  };
  id: string;
  name: string;
  numberActive: number;
  numberCompleted: number;
  numberPaused: number;
  revision: number;
  updatedBy: {
    displayName: string;
    id: string;
  };
};

type NonRootFolder = {
  id: string;
  name: string;
  parentFolderId: string;
  automationsCount: number;
  created: string;
  updated: string;
};

type RootFolder = Omit<NonRootFolder, 'parentFolderId'> & {
  parentFolderId: null;
};

export type Folder = NonRootFolder | RootFolder;

type FolderLocation = [RootFolder, ...NonRootFolder[]];

export type FolderPayload = {
  name: string;
  parentFolderId: string | null;
};

type Group = {
  id: string;
  name: string;
  config: any;
  value: string;
  label: string;
};

type AutomationPageState = {
  automations: Automation[];
  automationsCount: number;
  directoryLocation: FolderLocation;
  filters: {
    config: any;
    name: string;
    numberOfFilters: number;
    errors: null | {
      timestamp: number;
      parsedErrors: boolean[];
      quickFilterErrors: any[];
    };
    key: null | string;
  };
  folders: Folder[];
  groups: [{ value: 'none'; label: string; id: null }, ...Group[]];
  groupCount: number;
  groupErrors: any[] | null;
  isFetching: boolean;
  isFiltering: boolean;
  models: { value: string; label: string; data: any }[];
  pageConfig: {
    filterObject: any;
    group: string | null;
    page: number;
    search: string;
    size: number;
    sort:
      | 'name'
      | 'custom_object_name'
      | 'status'
      | 'number_active'
      | 'number_paused'
      | 'number_completed'
      | 'type'
      | 'created';
  };
  subfolders: NonRootFolder[];
  toastData: null | {
    variant: 'success' | 'alert' | 'failure';
    message: string;
  };
};

type State = {
  automationPage: AutomationPageState;
};

type PageConfig = AutomationPageState['pageConfig'];

const isRootFolder = (folder: Folder): folder is RootFolder => {
  return folder.parentFolderId === null;
};

const rmNegativeValues = (object: Record<string, any>) => {
  return Object.keys(object).reduce(
    (acc, el) => (object[el] ? { ...acc, [el]: object[el] } : acc),
    {}
  );
};

const countFilters = (filters: { filters: any[] }) => {
  if (isFilterSetArray(filters)) {
    return filters.flatMap((q) => q.filters).length;
  }
  if (isFilterSet(filters)) {
    return filters.filters.length;
  }
  return 0;
};

// automations
export const getAutomations = createAction(
  'automationsPage/GET_AUTOMATIONS',
  function prepare({ updatePageConfig = true } = {}) {
    return {
      payload: {
        updatePageConfig,
      },
    };
  }
);
export const deleteAutomation = createAction(
  'automationPage/DELETE_AUTOMATION'
);
export const deleteAutomationFail = createAction(
  'automationPage/DELETE_AUTOMATION_FAIL'
);
export const duplicateAutomation = createAction(
  'automationPage/DUPLICATE_AUTOMATION'
);
export const duplicateAutomationFail = createAction(
  'automationPage/DUPLICATE_AUTOMATION_FAIL'
);
// createOrUpdateGroup
export const createOrUpdateGroup = createAction(
  'automationPage/CREATE_OR_UPDATE_GROUP'
);
export const createOrUpdateGroupFail = createAction(
  'automationPage/CREATE_OR_UPDATE_GROUP_FAIL'
);
// removeGroup
export const removeGroup = createAction('automationPage/REMOVE_GROUP');
export const removeGroupFail = createAction('automationPage/REMOVE_GROUP_FAIL');
// setPageConfig
export const setPageConfig = createAction('automationPage/SET_PAGE_CONFIG');
export const setPageConfigSuccess = createAction(
  'automationPage/SET_PAGE_CONFIG_SUCCESS'
);
export const setPageConfigFail = createAction(
  'automationPage/SET_PAGE_CONFIG_FAIL'
);

export const changeGroupAction = createAction('automationPage/CHANGE_GROUP');

export const changeDirectoryAction = createAction(
  'automationPage/CHANGE_DIRECTORY'
);

export const deleteFolderAction = createAction('automationPage/DELETE_FOLDER');

export const applySearchAction = createAction('automationPage/APPLY_SEARCH');

const generatePageConfig = (
  fromApi: Partial<PageConfig>,
  fromState: Partial<PageConfig>
) => {
  const defaultPageConfigWhichMustBe: PageConfig = {
    search: '',
    sort: 'created',
    group: null,
    page: 1,
    size: 50,
    filterObject: {},
  };

  const properties = Object.keys(
    defaultPageConfigWhichMustBe
  ) as (keyof PageConfig)[];

  return properties.reduce<PageConfig>((acc, el: keyof PageConfig) => {
    const compare = (property: keyof PageConfig) => {
      const propertyWhichShouldBeAlwaysFromBackEnd = ['size', 'sort', 'search'];

      // highest priority from url string
      if (fromState[property]) return fromState[property];

      // check extra fields
      if (propertyWhichShouldBeAlwaysFromBackEnd.includes(property)) {
        return fromApi[property] || defaultPageConfigWhichMustBe[property];
      }

      return defaultPageConfigWhichMustBe[property];
    };

    return { ...acc, [el]: compare(el) };
  }, {} as PageConfig);
};

// Slices
const defaultState =
  initialStore.automationPage as unknown as AutomationPageState;

export const automationPageSlice = createSlice({
  name: 'automationPage',
  initialState: defaultState,
  reducers: {
    buildPage: (state, action) => {
      const { page } = action.payload;
      state.isFetching = true;
      state.pageConfig = {
        ...state.pageConfig,
        ...rmNegativeValues(page),
      };
    },
    buildPageComplete: (state, { payload }: Payload<any>) => {
      const { models, groupsResponse, pageResponse, filters, t } = payload;
      const groups = groupsResponse
        .map((el: Group) => ({ ...el, value: el.id, label: el.name }))
        .sort((a: Group, b: Group) => a.label.localeCompare(b.label));

      const pageConfig = generatePageConfig(pageResponse, state.pageConfig);
      const groupName =
        groups.find((g: Group) => g.id === pageConfig.group)?.name ?? '';

      state.groups = [
        { value: 'none', label: t('All Automations'), id: null },
        ...groups,
      ];
      state.models = models;
      state.pageConfig = pageResponse;
      state.isFetching = false;
      state.filters = {
        ...state.filters,
        config: filters,
        numberOfFilters: countFilters(filters),
        name: groupName,
      };
    },
    buildPageFail: (state) => {
      state.isFetching = false;
    },
    getAutomationsSuccess: (state, { payload }) => {
      const { automationsCount, subfolders, automations } = payload;

      state.automationsCount = automationsCount;
      state.automations = automations;
      state.subfolders = subfolders ?? [];
      state.filters.errors = null;
    },
    getFoldersSuccess: (
      state,
      { payload }: Payload<{ folders: Folder[]; folderId?: string | null }>
    ) => {
      const { folders, folderId } = payload;

      const rootFolder = folders.find(isRootFolder)!;

      state.folders = folders;

      if (folderId) {
        const foldersByIds = folders.reduce<Record<string, NonRootFolder>>(
          (acc, f) => {
            if (!isRootFolder(f)) {
              acc[f.id] = f;
            }
            return acc;
          },
          {}
        );

        const location: FolderLocation = [rootFolder];
        let folder: NonRootFolder | undefined = foldersByIds[folderId];

        while (folder) {
          location.splice(1, 0, folder);
          folder = foldersByIds[folder.parentFolderId];
        }

        state.directoryLocation = location;
      } else {
        state.directoryLocation = [rootFolder];
      }
    },
    updatePageConfig: (state, { payload }) => {
      state.pageConfig = { ...state.pageConfig, ...payload };
    },
    updatePageConfigBySearch: (state, { payload }) => {
      state.pageConfig = { ...state.pageConfig, ...payload };
    },
    setToast: (state, { payload }) => {
      const { variant, message } = payload;
      state.toastData = {
        variant,
        message,
      };
    },
    cleanToast: (state) => {
      state.toastData = null;
    },
    updateAutomation: (state, { payload }) => {
      state.automations = state.automations.map((item) =>
        item.id === payload.id ? { ...item, ...payload } : item
      );
    },
    createOrUpdateGroupSuccess: (
      state,
      { payload }: Payload<{ id: string; name: string; config: any }>
    ) => {
      if (state.pageConfig.group) {
        state.groups = state.groups.map((el) => {
          return el.id === payload.id
            ? { ...payload, value: payload.id, label: payload.name }
            : el;
        }) as AutomationPageState['groups'];
        return;
      }

      const [allOption, ...rest] = state.groups;

      const groups = rest
        .concat({ ...payload, value: payload.id, label: payload.name })
        .sort((a, b) => a.name.localeCompare(b.name));

      state.groups = [allOption, ...groups];
      state.pageConfig.group = payload.id;
      state.pageConfig.page = 1;
      state.pageConfig.filterObject = {};
      state.groupCount = state.automationsCount;
    },
    removeGroupSuccess: (state, { payload }: Payload<{ id: string }>) => {
      const { id } = payload;
      state.groups = state.groups.filter(
        (el) => el.id !== id
      ) as AutomationPageState['groups'];
      state.filters = {
        ...initialStore.automationPage.filters,
        key: uuidv4(),
      };
      state.pageConfig.group = null;
      state.pageConfig.page = 1;
      state.pageConfig.filterObject = {};
    },
    setCreateOrUpdateGroupFail: (state, { payload }) => {
      state.groupErrors = payload;
    },
    clearGroupError: (state) => {
      state.groupErrors = null;
    },
    setFilterName: (state, { payload }) => {
      const { name } = payload;
      state.filters.name = name;
    },
    getAutomationsStart: (state) => {
      state.isFiltering = true;
    },
    getAutomationsFinish: (state) => {
      state.isFiltering = false;
    },
    setNumberOfFilters: (state, { payload: numberOfFilters }) => {
      state.filters.numberOfFilters = numberOfFilters;
    },
    resetFilters: (state) => {
      state.pageConfig.group = null;
      state.pageConfig.filterObject = {};
      state.filters = {
        ...initialStore.automationPage.filters,
        key: uuidv4(),
      };
    },
    setFilters: (state, { payload: filters }) => {
      state.filters.config = filters;
      state.filters.key = uuidv4();
      state.filters.numberOfFilters = countFilters(filters);
    },
    setFilterErrors: (state, { payload: errors }) => {
      state.filters.errors = errors;
    },
    setGroupId: (state, { payload: id }) => {
      state.pageConfig.group = id;
    },
    changeDirectorySuccess: (state, { payload }) => {
      const { folder } = payload;
      const location = state.directoryLocation;

      const nextDirectoryIndex = state.directoryLocation.findIndex(
        (loc) => loc.id === folder.id
      );

      if (nextDirectoryIndex === -1) {
        state.directoryLocation = [...location, folder];
      } else {
        state.directoryLocation.length = nextDirectoryIndex + 1;
        return state;
      }
    },
    createFolderSuccess: (state, { payload }) => {
      const { folder } = payload;

      const [currentDirectory] = state.directoryLocation.slice(-1);

      if (currentDirectory.id === folder.parentFolderId) {
        state.subfolders = [folder, ...state.subfolders];
      }

      state.folders = [...state.folders, folder];
    },
    deleteAutomationSuccess: (state, { payload }) => {
      const { automationId } = payload;

      state.automations = state.automations.filter(
        (automation) => automation.id !== automationId
      );
    },
    deleteFolderSuccess: (state, { payload }) => {
      const { folderId } = payload;

      state.folders = state.folders.filter((folder) => folder.id !== folderId);
    },
    duplicateAutomationSuccess: (state, { payload }) => {
      const { duplicateId, newAutomation } = payload;

      const duplicatedIndex = state.automations.findIndex(
        (automation) => automation.id === duplicateId
      );

      if (duplicatedIndex >= 0) {
        state.automations = [
          ...state.automations.slice(0, duplicatedIndex + 1),
          newAutomation,
          ...state.automations.slice(duplicatedIndex + 1),
        ];
      }
    },
    editFolderSuccess: (
      state,
      {
        payload,
      }: Payload<{
        folder: Folder;
        edits: { parentFolderId?: string; name?: string };
      }>
    ) => {
      const { folder, edits } = payload;

      const replaceWithNewFolder = <F extends Folder>(acc: F[], fldr: F) => {
        if (folder.id === fldr.id) {
          acc.push(folder as F);
        } else {
          acc.push(fldr);
        }

        return acc;
      };

      state.folders = state.folders.reduce(replaceWithNewFolder<Folder>, []);

      if (edits.parentFolderId) {
        state.subfolders = state.subfolders.filter(
          (fldr) => fldr.id !== folder.id
        );
      } else if (edits.name) {
        state.subfolders = state.subfolders.reduce(
          replaceWithNewFolder<NonRootFolder>,
          []
        );
      }
    },
    editAutomationFolderSuccess: (state, { payload }) => {
      const { automation } = payload;

      state.automations = state.automations.filter(
        (auto) => auto.id !== automation.id
      );
    },
  },
});

export const {
  buildPage,
  buildPageComplete,
  buildPageFail,
  getAutomationsSuccess,
  updatePageConfig,
  updatePageConfigBySearch,
  setToast,
  cleanToast,
  updateAutomation,
  createOrUpdateGroupSuccess,
  setCreateOrUpdateGroupFail,
  removeGroupSuccess,
  setFilterName,
  getAutomationsStart,
  getAutomationsFinish,
  setNumberOfFilters,
  resetFilters,
  setFilters,
  setFilterErrors,
  setGroupId,
  clearGroupError,
  createFolderSuccess,
  editFolderSuccess,
  changeDirectorySuccess,
  getFoldersSuccess,
  editAutomationFolderSuccess,
  deleteAutomationSuccess,
  deleteFolderSuccess,
  duplicateAutomationSuccess,
} = automationPageSlice.actions;

export const { reducer } = automationPageSlice;

// Selectors
export const selectAutomationsIsFiltering = (state: State) => {
  return state.automationPage.isFiltering;
};

export const selectHasFilterError = (state: State) => {
  const filters = state.automationPage.filters.config;
  const errors = state.automationPage.filters.errors;

  if (!filters || filters.query) {
    return false;
  }

  const errorsWhenLoadingSteps = doFilterSetsHaveError(filters);
  const errorsWhenPerformingSearch = Boolean(
    errors?.parsedErrors.some(Boolean)
  );

  return errorsWhenLoadingSteps || errorsWhenPerformingSearch;
};

export const selectAutomationsWithCustomObjectAccessData = createSelector(
  (s: State) => s.automationPage.automations,
  (s: State) => s.automationPage.models,
  (automations, models) => {
    if (!automations) {
      return [];
    }

    const modelsById = models.reduce<
      Record<string, AutomationPageState['models'][number]>
    >((acc, model) => {
      acc[model.value] = model;
      return acc;
    }, {});

    return automations.map((automation, index, arr) => {
      const model = modelsById[automation.customObject.id];

      const access = model
        ? model.data.access
        : { view: false, edit: false, remove: false };

      const customObject = { ...automation.customObject, access };

      return setOpenMenuAbove({ ...automation, customObject }, index, arr);
    });
  }
);

export const selectAutomationRowData = createSelector(
  selectAutomationsWithCustomObjectAccessData,
  (s: State) => s.automationPage.subfolders,
  (s: State) => s.automationPage.directoryLocation,
  (automations, subfolders, location) => {
    if (location.length === 1) {
      return [...subfolders, ...automations];
    }

    const parentFolder = location[location.length - 2];

    return [parentFolder, ...subfolders, ...automations];
  }
);

export const selectCurrentDirectory = (s: State) => {
  return s.automationPage.directoryLocation.slice(-1).pop();
};

export const selectAllNonRootFolders = (s: State) => {
  return s.automationPage.folders.filter(
    (x) => x.parentFolderId !== null
  ) as NonRootFolder[];
};

export const selectRootFolder = (s: State) => {
  return s.automationPage.directoryLocation[0];
};

export const selectDirectoryLocation = (s: State) => {
  return s.automationPage.directoryLocation;
};

export const selectShouldLoadFolders = (s: State) => {
  return s.automationPage.directoryLocation[0].id === '';
};

export const selectFolderRelationships = createSelector(
  selectAllNonRootFolders,
  (folders) => {
    const relationships = folders.reduce<Map<string, Folder[]>>(
      (acc, folder) => {
        if (acc.has(folder.parentFolderId)) {
          acc.get(folder.parentFolderId)!.push(folder);
        } else {
          acc.set(folder.parentFolderId, [folder]);
        }

        return acc;
      },
      new Map()
    );

    return relationships;
  }
);

// Note: the root folder is a valid parent folder (obviously) but is not included
// here because the UI uses the blank/null Select option to indicate the root.
export const selectValidParentFolders = createSelector(
  selectFolderRelationships,
  selectRootFolder,
  (relationships, root) => {
    const rootLevelFolders = root.id ? relationships.get(root.id) ?? [] : [];

    return rootLevelFolders;
  }
);
