import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toastVariant, useToast } from '__components/ToastProvider';

import {
  useActivityQuery,
  useActivityFieldsQuery,
} from '__queries/models/activities-ts';
import ActivityService from 'services/ActivityService';
import { buildSharingSettingsForAPI } from '../utility/sharing';
import { useMutation, useQueryClient } from 'react-query';
import { ACTIVITIES } from '../queries/query-keys';
import { monitoringExceptionHelper } from 'sentry/helpers';
import { snakeToCamelCaseKeys } from '__services/helpers';

type Ctx = {
  activity: ActivityLike;
  handleSave(): Promise<any>;
  setSharingSettings: (settings: any, dirty: boolean) => void;
  isLoading: boolean;
  hasEdits: boolean;
};

const ctx = createContext<Ctx>({} as Ctx);

type ActivityContextProps = {
  activityId: string;
  setActivity: Dispatch<SetStateAction<ActivityLike>>;
};
type LoggableSharingSettings = {
  allTeamMembers: number;
  roles: {
    view: string[];
    edit: string[];
    admin: string[];
  };
  teamMembers: {
    view: string[];
    edit: string[];
    admin: string[];
  };
};
type ActivityLike = {
  id: string;
  loggableSharingSettings: LoggableSharingSettings;
};

export const useActivitySharingContext = () => useContext(ctx);

export const ActivitySharingContext = ({
  activityId,
  children,
  setActivity,
}: PropsWithChildren<ActivityContextProps>) => {
  const [showToast] = useToast();
  const [sharingSettings, setSharingSettings] = useState<any>();
  const [saving, setSaving] = useState<boolean>(false);
  const [hasEdits, setHasEdits] = useState<boolean>(false);
  const { t } = useTranslation();
  const { data: activity, isLoading } = useActivityQuery(activityId, {
    select: (res) => snakeToCamelCaseKeys(res),
  });
  const queryClient = useQueryClient();

  const { data: fields, isLoading: loadingFields } = useActivityFieldsQuery(
    activityId,
    {
      select: (res) => snakeToCamelCaseKeys(res),
    }
  );

  const { mutate } = useMutation(
    (result: { id: string; payload: any }) => {
      return ActivityService.v2UpdateActivity({
        id: result.id,
        ...result.payload,
      });
    },
    {
      onSuccess: (data) => {
        showToast({
          message: t('The activity rules have been saved successfully.'),
        });
        queryClient.setQueryData(ACTIVITIES.ACTIVITY(data?.id), {
          ...data,
          fields,
        });
        setActivity({ ...data, fields });
        setSaving(false);
        setHasEdits(false);
      },
      onError: (payload: { response: any }) => {
        showToast({
          message:
            payload?.response?.data?.message ||
            t(
              'There was an error saving the activity rules. Please try again.'
            ),
          variant: toastVariant.FAILURE,
        });
        monitoringExceptionHelper(payload?.response?.data?.[0]);
      },
      onMutate: (result) => {
        setSaving(true);
      },
    }
  );

  const handleSave = async () => {
    const loggableSharingSettings = buildSharingSettingsForAPI(
      sharingSettings
    ) as any;
    return mutate({
      id: activityId,
      payload: {
        loggableSharingSettings: loggableSharingSettings.sharing_settings || {},
      },
    });
  };

  const handleSetSharingSettings = useCallback(
    (settings: any, dirty: boolean) => {
      setSharingSettings(settings);
      setHasEdits(dirty);
    },
    [setSharingSettings, setHasEdits]
  );

  const value = {
    activity,
    handleSave,
    setSharingSettings: handleSetSharingSettings,
    isLoading: isLoading || loadingFields,
    hasEdits,
    saving,
  };

  return <ctx.Provider value={value}>{children}</ctx.Provider>;
};
