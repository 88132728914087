import { FIELD_TYPES } from 'utility/constants';

export const REFERENCE_FIELD_TYPES = [
  FIELD_TYPES.Checkboxes.type,
  FIELD_TYPES.Dropdown.type,
  FIELD_TYPES.DynamicTags.type,
  FIELD_TYPES.Radio.type,
  FIELD_TYPES.Relationship.type,
  FIELD_TYPES.Status.type,
  FIELD_TYPES.TeamSelector.type,
  FIELD_TYPES.YesNoMaybe.type,
  FIELD_TYPES.Rating.type,
  FIELD_TYPES.Integer.type,
  FIELD_TYPES.Decimal.type,
  FIELD_TYPES.Money.type,
  FIELD_TYPES.Date.type,
  FIELD_TYPES.DateTime.type,
  FIELD_TYPES.Text.type,
  FIELD_TYPES.Checkbox.type,
  FIELD_TYPES.Email.type,
];
